import React from 'react';
import ConfirmDialog from './ConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useTranslation } from 'react-i18next';
import { useCountdownTimer } from '../../utils/date';
import { remindMeLaterThunk, scheduleUpdateThunk } from '../app/appSlice';
/**
 * Displays dialog that prompts user to apply update. Displays auto update countdown and allows user to snooze the update
 * @constructor
 */
const UpdateDialog = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const updatePromptOpen = useSelector(
    (state: RootState) => state.app.updatePromptOpen
  );
  const autoUpdateTime = useSelector(
    (state: RootState) => state.app.autoUpdateTime
  );
  const countdown = useCountdownTimer(autoUpdateTime);
  return (
    <ConfirmDialog
      title={t('updateAvailable')}
      message={t('reloadApplicationToApplyUpdate', { countdown })}
      open={updatePromptOpen}
      onConfirm={() => dispatch(scheduleUpdateThunk())}
      onCancel={() => dispatch(remindMeLaterThunk())}
      cancelLabel={t('remindMeLater')}
      confirmLabel={t('reloadNow')}
    />
  );
};

export default UpdateDialog;
