import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setIsFullscreenAction } from '../features/app/appSlice';

/**
 * Hook for entering and exiting fullscreen mode
 */
export default function useFullscreen(): [boolean, (enter: boolean) => void] {
  const dispatch = useDispatch();
  const elRef = useRef(document.body);
  const isFullscreen = useSelector((s: RootState) => s.app.isFullscreen);

  const setFullscreen = (enter: boolean) => {
    if (enter) {
      if (elRef.current == null) return;

      elRef.current
        .requestFullscreen()
        .then(() => {
          dispatch(
            setIsFullscreenAction(
              // @ts-ignore
              document[getBrowserFullscreenElementProp()] != null
            )
          );
        })
        .catch(() => {
          dispatch(setIsFullscreenAction(false));
        });
    } else {
      document.exitFullscreen();
      dispatch(setIsFullscreenAction(false));
    }
  };

  React.useLayoutEffect(() => {
    document.onfullscreenchange = () =>
      dispatch(
        setIsFullscreenAction(
          // @ts-ignore
          document[getBrowserFullscreenElementProp()] != null
        )
      );

    // @ts-ignore
    return () => (document.onfullscreenchange = undefined);
  });

  return [isFullscreen, setFullscreen];
}

function getBrowserFullscreenElementProp() {
  if (typeof document.fullscreenElement !== 'undefined') {
    return 'fullscreenElement';
    // @ts-ignore
  } else if (typeof document.mozFullScreenElement !== 'undefined') {
    return 'mozFullScreenElement';
    // @ts-ignore
  } else if (typeof document.msFullscreenElement !== 'undefined') {
    return 'msFullscreenElement';
    // @ts-ignore
  } else if (typeof document.webkitFullscreenElement !== 'undefined') {
    return 'webkitFullscreenElement';
  } else {
    throw new Error('fullscreenElement is not supported by this browser');
  }
}
