import {
  green,
  lightBlue,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EMPTY_GUID, isEmptyOrSpaces } from '../../utils/string-utils';
import { AppDispatch } from '../../store';
import { useResizeObserver } from '../app/utils';
import {
  getCellBoardDataThunk,
  getCellBoardEnabledMCellsThunk,
  getMCellMessagesThunk,
  selectCellBoardData,
  selectEnabledMCellIds,
  selectLoadingCellBoardData,
  selectLoadingMCellMessages,
  selectMCellMessages,
} from './cellBoardSlice';
import { useInterval } from '../../utils/time';
import { Shift } from '../resourceTracking/types';
import {
  CellBoardConfig,
  CellBoardData,
  CellBoardMCellMessageDetailDto,
  CellBoardMCellTaskCompleteStatus,
  cellBoardMCellTaskCompleteStatuses,
  CellBoardMCellTaskDueStatus,
  cellBoardMCellTaskDueStatuses,
  CellBoardMessageDetailDto,
  CellBoardMessageInfo,
  CellBoardScanOffLineInstances,
  CellBoardSummaryDto,
  CellType,
  cellTypes,
  MCellMessageCountResult,
  ProductionMetricInterval,
  quadrants,
  QuadrantType,
  QualityQuadrantDisplayOption,
  ScanOffLineGroup,
  ScanOffLineType,
} from './types';
import { conditionalFormatColorToColor } from '../common/utils';
import { FormatColor } from '../common/types';
import {
  DATA_REFRESH_INTERVAL,
  formatIntervalTimeShort,
} from '../../utils/date';
import { styled } from '@mui/material/styles';
import { Alert } from '@mui/material';
import { Column } from '@devexpress/dx-react-grid';
import { TFunction } from 'i18next';
import moment from 'moment';
import { mCellShiftToStringKey } from '../resourceTracking/utils';

export const CELL_BOARD_MAX_FILE_SIZE_BYTES = 25000000; // 25 MB
export const CELL_BOARD_AXIS_LABEL_WORD_SIZE = 12;
export const LHPU_CHART_PADDING_LEFT = 85;
export const METRICS_AREA_WIDTH = 220;
const SCAN_OFF_LINE_TYPES = ['Originated', 'Remade'] as const;
const SCAN_OFF_LINE_GROUPS = ['Reason', 'Component'] as const;
const QUALITY_QUADRANT_DISPLAY_OPTIONS = ['FTP', 'Embedded'] as const;
export const CELL_BOARD_MESSAGE_COUNT = 'cellBoardMessageCount';

/**
 * Gets the Default Production Metric Interval Start Date.
 * @constructor
 */
export const GetDefaultProductionMetricIntervalStartDate = (): Date => {
  let defaultStartDate = moment().subtract(1, 'days').toDate();
  defaultStartDate.setMinutes(0, 0, 0);
  return defaultStartDate;
};

/**
 * Gets the Default Production Metric Interval End Date.
 * @constructor
 */
export const GetDefaultProductionMetricIntervalEndDate = (): Date => {
  let defaultEndDate = moment().toDate();
  defaultEndDate.setMinutes(0, 0, 0);
  return defaultEndDate;
};

/**
 * Gets the Default Cell Board Metric History Report Start Date
 * @constructor
 */
export const getDefaultCellBoardMetricHistoryReportStartDate = (): Date => {
  let defaultStartDate = moment().subtract(1, 'month').toDate();
  defaultStartDate.setMinutes(0, 0, 0);
  return defaultStartDate;
};

/**
 * Gets the Default Cell Board Metric History Report End Date
 * @constructor
 */
export const getDefaultCellBoardMetricHistoryReportEndDate = (): Date => {
  let defaultEndDate = moment().toDate();
  defaultEndDate.setMinutes(0, 0, 0);
  return defaultEndDate;
};

/**
 * Converts Variance Level status string to usable color format
 * @param status
 * @constructor
 */
export const varianceLevelToColor = (status: FormatColor): string => {
  switch (status) {
    case 'Yellow':
      return yellow[500];
    case 'Red':
      return red[500];
    case 'None':
      return '';
    case 'Green':
      return green[500];
  }
};

/**
 * Hook to poll for Cell Board Data and MCell Messages
 */
export const useCellBoardDataAndMCellMessagesPoll = (
  mCellId: string,
  shift: Shift,
) => {
  const dispatch = useDispatch<AppDispatch>();
  const loadingCellBoardData = useSelector(selectLoadingCellBoardData);
  const cellBoardData = useSelector(selectCellBoardData);
  const mCellMessages = useSelector(selectMCellMessages);
  const loadingMCellMessages = useSelector(selectLoadingMCellMessages);

  /**
   * Effect that triggers data fetch on page load and when mCellId or shift changes
   */
  useEffect(getData, [dispatch, mCellId, shift]);

  /**
   * Polls server for new data
   */
  useInterval(getData, DATA_REFRESH_INTERVAL);

  /**
   * Dispatches call to fetch MCell Messages and Cell Board Data
   */
  function getData() {
    if (mCellId !== EMPTY_GUID) {
      const info = { mCellId, shift };
      dispatch(getMCellMessagesThunk(info));
      dispatch(getCellBoardDataThunk(info));
    }
  }

  return {
    cellBoardData,
    loadingCellBoardData,
    loadingMCellMessages,
    mCellMessages,
  };
};

type MessageDisplayLogicProps = {
  startDate: Date | null;
  endDate: Date | null;
  data?: MCellMessageCountResult;
};

/**
 * Custom React Hook to manage the display logic for message count.
 *
 * @param {Object} props - The properties passed to the hook.
 * @param {Date|null} props.startDate - The start date for the message count calculation.
 * @param {Date|null} props.endDate - The end date for the message count calculation.
 * @param {MCellMessageCountResult} props.data - The data object containing the message count and threshold.
 *
 * @returns An object containing the display logic states.
 * @returns {boolean} .hasDates - A boolean indicating whether both start and end dates are provided.
 * @returns {boolean} .showWarning - A boolean indicating whether a warning should be shown (true if dates are provided and message count is 0).
 * @returns {boolean} .overThreshold - A boolean indicating whether the message count is over the threshold.
 * @returns {'info' | 'error' | 'warning'} .iconColor - The color of the icon to be displayed based on the message count status.
 * @returns {string|undefined} .messageCountString - A string representing the count of messages to be created, or undefined if the start or end date is not provided.
 */
export const useMessageAlertDisplayLogic = ({
  startDate,
  endDate,
  data,
}: MessageDisplayLogicProps) => {
  const { t } = useTranslation();
  const hasDates = !!(startDate && endDate);
  const showWarning = hasDates && data?.messageCount === 0;
  const overThreshold = !!data && data.messageCount >= data.messageThreshold;
  const iconColor: 'info' | 'error' | 'warning' = hasDates
    ? getIconColor({ showWarning, overThreshold })
    : 'info';
  const messageCountString = hasDates
    ? getMessageCountString({
        t,
        startDate,
        endDate,
        data,
        showWarning,
      })
    : undefined;

  return {
    hasDates,
    overThreshold,
    iconColor,
    messageCountString,
  };
};

/**
 * This function generates a string that represents the count of messages to be created.
 * It takes into account the start and end dates, the message count, and whether a warning should be shown.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Function} params.t - The translation function from react-i18next.
 * @param {Date|null} params.startDate - The start date for the message count calculation.
 * @param {Date|null} params.endDate - The end date for the message count calculation.
 * @param {MCellMessageCountResult} params.data - The data object containing the message count and threshold.
 * @param {boolean|null} params.showWarning - A flag indicating whether a warning should be shown.
 *
 * @returns {string|undefined} - A string representing the count of messages to be created, or undefined if the start or end date is not provided.
 */
export const getMessageCountString = ({
  t,
  startDate,
  endDate,
  data,
  showWarning,
}: {
  t: (key: string, options?: any) => string;
  startDate: Date | null;
  endDate: Date | null;
  data?: MCellMessageCountResult;
  showWarning: boolean | null;
}): string | undefined => {
  return startDate && endDate
    ? showWarning
      ? t('theRepeatOptionsConfiguredWillNotCreateAnyMessages')
      : t('countOfMessagesToBeCreatedX', { count: data?.messageCount ?? 0 })
    : undefined;
};

type IconColorProps = {
  showWarning: boolean | null;
  overThreshold: boolean;
};

/**
 * Function to determine the color of an icon based on the presence of a warning or an error.
 *
 * @param {Object} params - The parameters for the function.
 * @param {boolean|null} params.showWarning - A flag indicating whether a warning should be shown.
 * @param {boolean} params.overThreshold - A flag indicating whether the error icon color should be used.
 *
 * @returns {'info' | 'error' | 'warning'} - The color of the icon.
 */
export const getIconColor = ({
  showWarning,
  overThreshold,
}: IconColorProps): 'info' | 'error' | 'warning' => {
  if (showWarning) {
    return 'warning';
  } else if (overThreshold) {
    return 'error';
  } else {
    return 'info';
  }
};

/**
 * Custom Hook to return Cell Board Scan Off-Line Part Columns
 */
export const useScanOffLinePartColumns = () => {
  const { t } = useTranslation();
  const scanOffLinePartColumns: Column[] = [
    {
      title: t('workOrderNumber'),
      name: 'workOrderNumber',
    },
    {
      title: t('salesOrderNumber'),
      name: 'salesOrderNumber',
    },
    {
      title: t('salesOrderLine'),
      name: 'salesOrderLine',
    },
    {
      title: t('scannedBy'),
      name: 'scannedBy',
    },
    {
      title: t('unitTrackingNumber'),
      name: 'unitTrackingNumber',
    },
    {
      title: t('trackingNumberOnLine'),
      name: 'trackingNumberOnLine',
    },
    {
      title: t('scannedOffLineLocation'),
      name: 'scannedOffLineProducingLocation',
    },
    {
      title: t('station'),
      name: 'station',
    },
    {
      title: t('remakeLocation'),
      name: 'remakeProducingLocation',
    },
    {
      title: t('returnArea'),
      name: 'returnArea',
    },
    {
      title: t('component'),
      name: 'component',
    },
    {
      title: t('reason'),
      name: 'reason',
    },
    {
      title: t('rejectComment'),
      name: 'rejectComment',
    },
    {
      title: t('comments'),
      name: 'comments',
    },
    {
      title: t('creditReturnPosted'),
      name: 'creditReturnPosted',
    },
    {
      title: t('scannedOffLineDate'),
      name: 'scannedOffLineDate',
    },
    {
      title: t('dueBackOnLineDate'),
      name: 'dueBackOnLineDate',
    },
  ];

  return scanOffLinePartColumns;
};

/**
 * Get list of MCell Tasks across list of MCell Messages
 * @param mCellMessages
 */
export const getMCellTasksFromMCellMessages = (
  mCellMessages: CellBoardMCellMessageDetailDto[],
) =>
  mCellMessages
    .map((mcm) => mcm.mCellTasks)
    .reduce((acc, mct) => acc.concat(mct), [])
    .slice()
    .sort((a, b) => a.task.positionIndex - b.task.positionIndex);

/**
 * Converts a CellBoardMessage object to a CellBoardMessageInfo object for use with update calls
 * @param cellBoardMessage
 */
export const cellBoardMessageToCellBoardMessageInfo = (
  cellBoardMessage: CellBoardMessageDetailDto,
): CellBoardMessageInfo => {
  return {
    inventoryCenterId: cellBoardMessage.inventoryCenterId,
    mCellId: cellBoardMessage.mCellId,
    mCellCollectionId: cellBoardMessage.mCellCollectionId,
    quadrant: cellBoardMessage.quadrant,
    startDate: cellBoardMessage.startDate,
    endDate: cellBoardMessage.endDate,
    subject: cellBoardMessage.subject,
    body: cellBoardMessage.body,
    shifts: cellBoardMessage?.shifts?.map((s) => s.shift) ?? [],
    repeat: cellBoardMessage.repeat,
    repeatWeeklyDaysOfWeek: cellBoardMessage.repeatDaysOfWeek,
    repeatMonthlyDays: cellBoardMessage.repeatMonthlyDays,
    repeatDurationDays: cellBoardMessage.repeatDurationDays,
    monthlyInterval: cellBoardMessage.monthlyInterval,
    tasks: cellBoardMessage.tasks.map((t) => t.description),
    links: cellBoardMessage.links,
  };
};

/**
 * Gets the color of a Cell Board Quadrant
 * @param quadrant
 */
export const getQuadrantColor = (quadrant: QuadrantType) => {
  switch (quadrant) {
    case 'None':
      return '';
    case 'Safety':
      return '#0b5697';
    case 'Delivery':
      return '#fcdd05';
    case 'Quality':
      return '#1bae4c';
    case 'Production':
      return '#6b2b7e';
  }
};

/**
 * Gets the contrasting text color to a Cell Board Quadrant
 * @param quadrant
 */
export const getQuadrantContrastColor = (quadrant: QuadrantType) => {
  switch (quadrant) {
    case 'None':
    case 'Delivery':
      return 'rgba(0, 0, 0, 0.87)';
    default:
      return '#ffffff';
  }
};

export const actualColor = lightBlue[500];
export const goalColor = purple[500];
export const movingAverageColor = teal[500];
export const overheadDoorOpenColor = green[500];
export const overheadDoorCloseColor = red[500];

/**
 * Gets the string key of a Cell Board MCell Task Complete Status
 * @param mCellTaskCompleteStatus
 */
export const mCellTaskCompleteStatusToStringKey = (
  mCellTaskCompleteStatus: CellBoardMCellTaskCompleteStatus,
): string => {
  switch (mCellTaskCompleteStatus) {
    case 'All':
      return 'all';
    case 'Complete':
      return 'complete';
    case 'Incomplete':
      return 'incomplete';
  }
};

/**
 * Gets the Cell Board MCell Task Complete Status options for use in select components
 * @param t
 */
export const getMCellTaskCompleteStatusOptions = (
  t: TFunction,
): { label: string; value: CellBoardMCellTaskCompleteStatus }[] => {
  return cellBoardMCellTaskCompleteStatuses.map((x) => ({
    label: t(mCellTaskCompleteStatusToStringKey(x)),
    value: x,
  }));
};

/**
 * Gets the string key of a Cell Board MCell Task Due Status
 * @param mCellTaskDueStatus
 */
export const mCellTaskDueStatusToStringKey = (
  mCellTaskDueStatus: CellBoardMCellTaskDueStatus,
): string => {
  switch (mCellTaskDueStatus) {
    case 'All':
      return 'all';
    case 'Current':
      return 'current';
    case 'PastDue':
      return 'pastDue';
    case 'Upcoming':
      return 'upcoming';
  }
};

/**
 * Gets the Cell Board MCell Task Due Status options for use in select components
 * @param t
 */
export const getMCellTaskDueStatusOptions = (
  t: TFunction,
): { label: string; value: CellBoardMCellTaskDueStatus }[] => {
  return cellBoardMCellTaskDueStatuses.map((x) => ({
    label: t(mCellTaskDueStatusToStringKey(x)),
    value: x,
  }));
};

/**
 * Gets string key of cell type
 * @param cellType
 */
export const cellTypeToStringKey = (cellType: CellType): string => {
  switch (cellType) {
    case 'All':
      return 'allCellTypes';
    case 'WorkCellsOnly':
      return 'workCells';
    case 'RollupCellsOnly':
      return 'rollupCells';
  }
};

/**
 * Gets cell type options for use in select component
 * @param t
 */
export const getCellTypeOptions = (
  t: TFunction,
): { label: string; value: CellType }[] => {
  return cellTypes.map((r) => ({
    label: t(cellTypeToStringKey(r)),
    value: r,
  }));
};

/**
 * Gets the string key of a Cell Board Quadrant
 * @param quadrant
 */
export const quadrantToStringKey = (quadrant: QuadrantType): string => {
  switch (quadrant) {
    case 'None':
      return 'general';
    case 'Safety':
      return 'safety';
    case 'Delivery':
      return 'delivery';
    case 'Quality':
      return 'quality';
    case 'Production':
      return 'production';
  }
};

type QuadrantOption = {
  label: string;
  value: QuadrantType;
};

/**
 * Gets the select options for Cell Board Quadrants
 */
export const getQuadrantOptions = (t: TFunction): QuadrantOption[] => {
  return quadrants.map((q) => ({
    label: t(quadrantToStringKey(q)),
    value: q,
  }));
};

/**
 * Gets the string key of a ScanOffLineType
 * @param scanOffLineType
 */
export const getScanOffLineTypeStringKey = (
  scanOffLineType: ScanOffLineType,
): string => {
  switch (scanOffLineType) {
    case 'Originated':
      return 'originated';
    case 'Remade':
      return 'remade';
  }
};

/**
 * Gets the select options of a ScanOffLineType
 * @param t
 */
export const getScanOfflineTypeOptions = (
  t: TFunction,
): {
  label: string;
  value: ScanOffLineType;
}[] => {
  return SCAN_OFF_LINE_TYPES.map((s) => ({
    label: t(getScanOffLineTypeStringKey(s)),
    value: s,
  }));
};

/**
 * Gets the string key of a ScanOffLineGroup
 * @param scanOffLineGroup
 */
export const getScanOffLineGroupStringKey = (
  scanOffLineGroup: ScanOffLineGroup,
): string => {
  switch (scanOffLineGroup) {
    case 'Component':
      return 'component';
    case 'Reason':
      return 'reason';
  }
};

/**
 * Gets the select options of a ScanOffLineGroup
 * @param t
 */
export const getScanOffLineGroupOptions = (
  t: TFunction,
): { label: string; value: ScanOffLineGroup }[] => {
  return SCAN_OFF_LINE_GROUPS.map((s) => ({
    label: t(getScanOffLineGroupStringKey(s)),
    value: s,
  }));
};

/**
 * Gets the string key of a QualityQuadrantDisplayOption
 * @param qualityQuadrantDisplayOption
 */
export const getQualityQuadrantDisplayOptionStringKey = (
  qualityQuadrantDisplayOption: QualityQuadrantDisplayOption,
): string => {
  switch (qualityQuadrantDisplayOption) {
    case 'FTP':
      return 'ftp';
    case 'Embedded':
      return 'embeddedAnalytics';
  }
};

/**
 * Gets the select options of a QualityQuadrantDisplayOption
 * @param t
 */
export const getQualityQuadrantDisplayOptions = (
  t: TFunction,
): {
  label: string;
  value: QualityQuadrantDisplayOption;
}[] => {
  return QUALITY_QUADRANT_DISPLAY_OPTIONS.map((option) => ({
    label: t(getQualityQuadrantDisplayOptionStringKey(option)),
    value: option,
  }));
};

/**
 * Hook for checking if an MCell has been enabled for Cell Board
 * @param mCellId The ID of the MCell to check.
 * @param inventoryCenterId The ID of the Inventory Center to query for enabled MCells.
 * @returns A boolean indicating whether the specified MCell is enabled.
 */
export const useMCellEnabled = (
  mCellId: string | undefined,
  inventoryCenterId: string | undefined,
): boolean => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCellBoardEnabledMCellsThunk(inventoryCenterId ?? EMPTY_GUID));
  }, [dispatch, inventoryCenterId]);

  const activeMCellIds = useSelector(selectEnabledMCellIds);
  return activeMCellIds.includes(mCellId ?? '');
};

/**
 * Returns the string display value for a metric or '--' if empty
 * @param value
 */
export const getMetricDisplayValue = (
  value: string | number | undefined,
): string => {
  const valueDisplay =
    typeof value === 'number'
      ? value.toString()
      : isEmptyOrSpaces(value)
        ? '--'
        : value;
  return valueDisplay as string;
};

/**
 * Processes and extract data needed for Quality quadrant from the Cell Board data object
 * @param cellBoardData
 */
export const processQualityData = (
  cellBoardData: CellBoardData | undefined,
) => {
  const lastUpdatedDate = cellBoardData?.firstTimePassMetric
    ? moment(cellBoardData.firstTimePassMetric.updatedDate).toDate()
    : undefined;
  const firstTimePassPreviousPercent =
    cellBoardData?.firstTimePassMetric?.previousPercent;
  const firstTimePassPreviousGoalPercent =
    cellBoardData?.firstTimePassMetric?.previousGoalPercent;
  const firstTimePassCurrentPercent =
    cellBoardData?.firstTimePassMetric?.currentPercent;
  const firstTimePassCurrentGoalPercent =
    cellBoardData?.firstTimePassMetric?.currentGoalPercent;
  const firstTimePassPreviousColor = conditionalFormatColorToColor(
    cellBoardData?.firstTimePassPreviousColor,
  );
  const firstTimePassCurrentColor = conditionalFormatColorToColor(
    cellBoardData?.firstTimePassCurrentColor,
  );
  const qualityScanOffLineGroup = cellBoardData?.config?.scanOffLineGroup;
  const scanOffLineInstancesTop5 =
    cellBoardData?.scanOffLineInstances
      ?.slice()
      .reduce((acc, cv) => {
        // sum up duplicate Component or Reason
        const duplicateIndex = acc.findIndex((s) =>
          qualityScanOffLineGroup === 'Component'
            ? s.scanOffLineComponent.id === cv.scanOffLineComponent.id
            : s.scanOffLineReason.id === cv.scanOffLineReason.id,
        );
        if (duplicateIndex < 0) return [...acc, cv];
        return [
          ...acc.slice(0, duplicateIndex),
          {
            ...acc[duplicateIndex],
            instances: acc[duplicateIndex].instances + cv.instances,
          },
          ...acc.slice(duplicateIndex + 1),
        ];
      }, [] as CellBoardScanOffLineInstances[])
      .sort((a, b) => b.instances - a.instances)
      .filter((d, i) => i < 5)
      .map((s) => ({
        x:
          qualityScanOffLineGroup === 'Component'
            ? s.scanOffLineComponent.description
            : s.scanOffLineReason.description,
        y: s.instances,
      })) ?? [];
  const scanOffLineInstancesTop3Today =
    cellBoardData?.scanOffLineInstances
      ?.slice()
      .sort((a, b) => b.instances - a.instances)
      .filter((d) =>
        moment(d.shiftDate)?.startOf('day').isSame(moment().startOf('day')),
      )
      .filter((d, i) => i < 3)
      .map((s) => ({
        x:
          qualityScanOffLineGroup === 'Component'
            ? s.scanOffLineComponent.description
            : s.scanOffLineReason.description,
        y: s.instances,
      })) ?? [];
  const firstTimePassHistoryActualPercentData =
    cellBoardData?.firstTimePassHistory?.map((f) => ({
      x: moment(f.shiftDate).toDate(),
      y: f.actualPercent ?? 0,
    })) ?? [];
  const firstTimePassHistoryGoalPercentData =
    cellBoardData?.firstTimePassHistory?.map((f) => ({
      x: moment(f.shiftDate).toDate(),
      y: f.goalPercent ?? 0,
    })) ?? [];
  const firstTimePassHistoryMovingAveragePercentData =
    cellBoardData?.firstTimePassHistory?.map((f) => ({
      x: moment(f.shiftDate).toDate(),
      y: f.movingAveragePercent ?? 0,
    })) ?? [];
  const insufficientDataForMovingAverage =
    cellBoardData?.firstTimePassHistory?.every(
      (x) => x.insufficientDataForMovingAverage,
    );

  return {
    firstTimePassPreviousPercent,
    firstTimePassPreviousColor,
    firstTimePassPreviousGoalPercent,
    firstTimePassCurrentPercent,
    firstTimePassCurrentColor,
    firstTimePassCurrentGoalPercent,
    firstTimePassHistoryActualPercentData,
    firstTimePassHistoryGoalPercentData,
    firstTimePassHistoryMovingAveragePercentData,
    insufficientDataForMovingAverage,
    lastUpdatedDate,
    scanOffLineInstancesTop5,
    scanOffLineInstancesTop3Today,
    qualityScanOffLineGroup,
  };
};

/**
 * Processes and extract data needed for Delivery quadrant from the Cell Board data object
 * @param cellBoardData
 */
export const processDeliveryData = (
  cellBoardData: CellBoardData | undefined,
) => {
  const lastUpdatedDate = cellBoardData?.deliveryMetric
    ? moment(cellBoardData.deliveryMetric.updatedDate).toDate()
    : undefined;
  const loadingSummary = cellBoardData?.deliveryMetric?.loadingSummary;
  const pastDueSummary = cellBoardData?.deliveryMetric?.pastDueSummary;
  const todayTotalSummary = cellBoardData?.deliveryMetric?.todayTotalSummary;
  const departmentBacklogSummary =
    cellBoardData?.backlogMetric?.departmentBacklogSummary;
  const departmentBacklog =
    cellBoardData?.backlogMetric?.departmentBacklog ?? [];
  const detailMetrics = cellBoardData?.deliveryMetric?.detailMetrics ?? [];
  const deliveryHistoryActualPercentData =
    cellBoardData?.deliveryHistory?.map((d) => ({
      x: moment(d.shiftDate).toDate(),
      y: d.actualPercent ?? 0,
    })) ?? [];
  const deliveryHistoryGoalPercentData =
    cellBoardData?.deliveryHistory?.map((d) => ({
      x: moment(d.shiftDate).toDate(),
      y: d.goalPercent ?? 0,
    })) ?? [];
  const deliveryHistoryMovingAveragePercentData =
    cellBoardData?.deliveryHistory?.map((d) => ({
      x: moment(d.shiftDate).toDate(),
      y: d.movingAveragePercent ?? 0,
    })) ?? [];
  const insufficientDataForMovingAverage =
    cellBoardData?.deliveryHistory?.every(
      (x) => x.insufficientDataForMovingAverage,
    );
  return {
    deliveryHistoryActualPercentData,
    deliveryHistoryGoalPercentData,
    deliveryHistoryMovingAveragePercentData,
    insufficientDataForMovingAverage,
    departmentBacklog,
    departmentBacklogSummary,
    detailMetrics,
    lastUpdatedDate,
    loadingSummary,
    pastDueSummary,
    todayTotalSummary,
  };
};

/**
 * Processes and extract data needed for Production quadrant from the Cell Board data object
 * @param cellBoardData
 */
export const processProductionData = (
  cellBoardData: CellBoardData | undefined,
) => {
  const lastUpdatedDate = cellBoardData?.productionMetric
    ? moment(cellBoardData.productionMetric.updatedDate).toDate()
    : undefined;
  const laborHoursPerUnitCurrentActual =
    cellBoardData?.laborHoursPerUnitMetric?.currentActual;
  const laborHoursPerUnitCurrentGoal =
    cellBoardData?.laborHoursPerUnitMetric?.currentGoal;
  const laborHoursPerUnitPreviousActual =
    cellBoardData?.laborHoursPerUnitMetric?.previousActual;
  const laborHoursPerUnitPreviousGoal =
    cellBoardData?.laborHoursPerUnitMetric?.previousGoal;
  const productionCurrentActual =
    cellBoardData?.productionMetric?.currentActual;
  const productionCurrentGoal = cellBoardData?.productionMetric?.currentGoal;
  const productionCurrentTarget =
    cellBoardData?.productionMetric?.currentTarget;
  const productionPreviousActual =
    cellBoardData?.productionMetric?.previousActual;
  const productionPreviousGoal = cellBoardData?.productionMetric?.previousGoal;
  const currentCrewing = cellBoardData?.currentCrewing;
  const roster = cellBoardData?.roster ?? [];
  const productionCurrentColor = conditionalFormatColorToColor(
    cellBoardData?.productionCurrentColor,
  );
  const productionPreviousColor = conditionalFormatColorToColor(
    cellBoardData?.productionPreviousColor,
  );
  const laborHoursPerUnitHistoryActualData =
    cellBoardData?.laborHoursPerUnitHistory?.map((d) => ({
      x: moment(d.shiftDate).toDate(),
      y: d.actual ?? 0,
    })) ?? [];
  const laborHoursPerUnitHistoryGoalData =
    cellBoardData?.laborHoursPerUnitHistory?.map((d) => ({
      x: moment(d.shiftDate).toDate(),
      y: d.goal ?? 0,
    })) ?? [];
  const laborHoursPerUnitHistoryMovingAverageData =
    cellBoardData?.laborHoursPerUnitHistory?.map((d) => ({
      x: moment(d.shiftDate).toDate(),
      y: d.movingAverage ?? 0,
    })) ?? [];
  const insufficientDataForMovingAverage =
    cellBoardData?.laborHoursPerUnitHistory?.every(
      (x) => x.insufficientDataForMovingAverage,
    );
  return {
    currentCrewing,
    lastUpdatedDate,
    laborHoursPerUnitCurrentActual,
    laborHoursPerUnitCurrentGoal,
    laborHoursPerUnitHistoryActualData,
    laborHoursPerUnitHistoryGoalData,
    laborHoursPerUnitHistoryMovingAverageData,
    insufficientDataForMovingAverage,
    laborHoursPerUnitPreviousActual,
    laborHoursPerUnitPreviousGoal,
    productionCurrentActual,
    productionCurrentColor,
    productionCurrentGoal,
    productionCurrentTarget,
    productionPreviousActual,
    productionPreviousColor,
    productionPreviousGoal,
    roster,
  };
};

/**
 * Processes and extract data needed for Safety quadrant from Cell Board data object
 * @param cellBoardData
 */
export const processSafetyData = (cellBoardData: CellBoardData | undefined) => {
  const lastUpdatedDate = cellBoardData?.safetyMetric
    ? moment(cellBoardData?.safetyMetric?.updatedDate).toDate()
    : undefined;
  const daysSinceLastRecordable =
    cellBoardData?.safetyMetric?.daysSinceLastRecordable;
  const safetyMessage = cellBoardData?.safetyMessage;
  const safetyTopicUrl = cellBoardData?.safetyTopic?.url;
  return {
    safetyMessage,
    daysSinceLastRecordable,
    lastUpdatedDate,
    safetyTopicUrl,
  };
};

/**
 * Processes and extracts config details from config object
 * @param config
 */
export const getConfigDetails = (config?: CellBoardConfig) => {
  const id = config ? config.id : '';
  const maxPeriodDays = 90;
  const maxMovingAverageDays = 90;
  const scanOffLineGroup = config ? config.scanOffLineGroup : '';
  const scanOffLineType = config ? config.scanOffLineType : '';
  const qualityQuadrantDisplayOption = config
    ? config.qualityQuadrantDisplayOption
    : '';
  const scanOffLineInstancesPeriodDays = config
    ? config.scanOffLineInstancesPeriodDays
    : 0;
  const firstTimePassHistoryPeriodDays = config
    ? config.firstTimePassHistoryPeriodDays
    : 0;
  const firstTimePassHistoryMovingAverageDays = config
    ? config.firstTimePassHistoryMovingAverageDays
    : 0;
  const deliveryHistoryPeriodDays = config
    ? config.deliveryHistoryPeriodDays
    : 0;
  const deliveryHistoryMovingAverageDays = config
    ? config.deliveryHistoryMovingAverageDays
    : 0;
  const laborHoursPerUnitHistoryMovingAverageDays = config
    ? config.laborHoursPerUnitHistoryMovingAverageDays
    : 0;
  const laborHoursPerUnitHistoryPeriodDays = config
    ? config.laborHoursPerUnitHistoryPeriodDays
    : 0;
  const conditionalFormatCurrentProductionTargetPercent = config
    ? config.conditionalFormatCurrentProductionTargetPercent
    : 0;
  const conditionalFormatPreviousProductionTargetPercent = config
    ? config.conditionalFormatPreviousProductionTargetPercent
    : 0;
  const conditionalFormatFirstTimePassCurrentGoalOffset = config
    ? config.conditionalFormatFirstTimePassCurrentGoalOffset
    : 0;
  const conditionalFormatFirstTimePassPreviousOffset = config
    ? config.conditionalFormatFirstTimePassPreviousOffset
    : 0;
  const embeddedAnalyticsUrl = config ? config?.embeddedAnalyticsUrl : '';
  const compactEmbeddedAnalyticsUrl = config
    ? config.compactEmbeddedAnalyticsUrl
    : '';

  const slideshowInterval = config ? config.slideshowInterval : 1;
  return {
    conditionalFormatCurrentProductionTargetPercent,
    conditionalFormatFirstTimePassCurrentGoalOffset,
    conditionalFormatFirstTimePassPreviousOffset,
    conditionalFormatPreviousProductionTargetPercent,
    deliveryHistoryMovingAverageDays,
    deliveryHistoryPeriodDays,
    firstTimePassHistoryPeriodDays,
    firstTimePassHistoryMovingAverageDays,
    id,
    laborHoursPerUnitHistoryMovingAverageDays,
    laborHoursPerUnitHistoryPeriodDays,
    maxPeriodDays,
    maxMovingAverageDays,
    scanOffLineGroup,
    scanOffLineType,
    scanOffLineInstancesPeriodDays,
    embeddedAnalyticsUrl,
    compactEmbeddedAnalyticsUrl,
    qualityQuadrantDisplayOption,
    slideshowInterval,
  };
};

/**
 * Appends parameters to pdf url for display in a html object. Also appends a random number to trigger object reload
 * @param url
 */
export const appendObjectParams = (url: string) => {
  const random = Math.floor(Math.random() * 10000) + 1;
  return `${url}&random=${random}#toolbar=0`;
};

/**
 * Hack to reload html object when its size changes to properly display PDF
 * @param pdfUrl
 */
export const useResizableObjectHack = (pdfUrl: string | undefined) => {
  const objectContainerRef = useRef<HTMLDivElement | null>(null);
  const dimensions = useResizeObserver(objectContainerRef);
  const { width, height } = dimensions ? dimensions : { width: 0, height: 0 };
  const [objectUrl, setObjectUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (width && height && pdfUrl) {
      setObjectUrl(appendObjectParams(pdfUrl));
    }
  }, [width, height, pdfUrl]);
  return { objectContainerRef, objectUrl };
};

type ChartDataProps = {
  t: TFunction;
  data: ProductionMetricInterval[];
};

/**
 * Extracts and transforms chart data from production metric intervals.
 *
 * This function processes an array of production metric intervals, reversing the order for chronological display,
 * and maps each metric to a format suitable for charting. It generates two sets of data: `barData` for actual metrics
 * displayed as bars, and `lineData` for target metrics displayed as a line on the chart.
 *
 * Each bar in `barData` includes a tooltip that provides detailed information about the metric, including target,
 * actual, variance, shift, and optional notes or actions taken. The color of each bar is determined by the variance
 * color, which is converted to a usable format.
 *
 * `lineData` simply maps each metric to its target value, with the x-axis representing the formatted interval between
 * `fromDate` and `toDate`.
 *
 * @param {ChartDataProps} params - The parameters for the function.
 * @param {TFunction} params.t - The translation function from react-i18next, used for localizing labels.
 * @param {ProductionMetricInterval[]} params.data - An array of production metric intervals to be processed.
 *
 * @returns An object containing `barData` and `lineData` arrays for chart rendering.
 */
export const extractChartData = ({ t, data }: ChartDataProps) => {
  const barData = [...data].reverse().map((metric) => ({
    x: formatIntervalTimeShort(metric.fromDate, metric.toDate),
    y: metric.actual,
    tooltip:
      `${t('target')}: ${metric.target}\n${t('actual')}: ${metric.actual}\n` +
      `${t('variance')}: ${metric.variance}\n${t('shift')}: ${t(
        mCellShiftToStringKey(metric.shift),
      )}` +
      `${metric.note !== undefined ? `\n${t('note')}: ${metric.note}` : ''}` +
      `${
        metric.action !== undefined
          ? `\n${t('actionTaken')}: ${metric.action}`
          : ''
      }`,
    color: conditionalFormatColorToColor(metric.varianceColor),
  }));

  const lineData = [...data].reverse().map((metric) => ({
    x: formatIntervalTimeShort(metric.fromDate, metric.toDate),
    y: metric.target,
  }));

  return { barData, lineData };
};

/**
 * Styled Alert with increased Icon size
 */
export const StyledAlert = styled(Alert)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiAlert-icon': {
    fontSize: '3rem',
    marginRight: theme.spacing(1),
  },
}));

export const sampleCellBoardSummaryData: CellBoardSummaryDto[] = [
  {
    mCell: {
      id: '7676023f-3176-4342-b063-48ba10bb15d2',
      code: 'TRAPASSY',
      description: 'Traps Assembly',
      inventoryCenter: {
        id: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
        code: '08',
        description: 'GRAFTON PLANT',
        enabled: true,
      },
      inventoryCenterId: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
      isRollup: false,
      componentMCells: [],
      sortSequence: 0,
    },
    mCellId: '7676023f-3176-4342-b063-48ba10bb15d2',
    shift: 'Day',
    currentCrewing: 11,
    minimumCrewing: 4,
    optimumCrewing: 10,
    maximumCrewing: 14,
    productionActual: 325,
    productionTarget: 600,
    productionGoal: 600,
    firstTimePassActualPercent: 45,
    firstTimePassGoalPercent: 90,
    productionCellColor: 'Yellow',
    crewingCellColor: 'Green',
    firstTimePassCellColor: 'Red',
  },
  {
    mCell: {
      id: '7676023f-3176-4342-b063-48ba10bb15d2',
      code: 'TRAPASSY',
      description: 'Traps Assembly',
      inventoryCenter: {
        id: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
        code: '08',
        description: 'GRAFTON PLANT',
        enabled: true,
      },
      inventoryCenterId: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
      isRollup: false,
      componentMCells: [],
      sortSequence: 0,
    },
    mCellId: '7676023f-3176-4342-b063-48ba10bb15d2',
    shift: 'Day',
    currentCrewing: 10,
    minimumCrewing: 2,
    optimumCrewing: 3,
    maximumCrewing: 5,
    productionActual: 21168,
    productionTarget: 11806,
    productionGoal: 11807,
    firstTimePassActualPercent: 99,
    firstTimePassGoalPercent: 98,
    productionCellColor: 'Green',
    crewingCellColor: 'Yellow',
    firstTimePassCellColor: 'Green',
  },
];

/**
 * Sample data used fo dev testing.
 */
export const sampleCellBoardData: CellBoardData = {
  backlogMetric: {
    id: '',
    mCellId: '',
    departmentBacklogSummary: 428,
    departmentBacklog: [
      {
        id: '',
        producingLocationName: '182070',
        producingLocationBacklog: 265,
      },
      {
        id: '',
        producingLocationName: '186510',
        producingLocationBacklog: 197,
      },
    ],
  },
  config: {
    id: '',
    mCellId: '',
    shift: 'Day',
    conditionalFormatFirstTimePassCurrentGoalOffset: 10,
    conditionalFormatFirstTimePassPreviousOffset: 10,
    scanOffLineGroup: 'Reason',
    scanOffLineType: 'Originated',
    conditionalFormatCurrentProductionTargetPercent: 5,
    conditionalFormatPreviousProductionTargetPercent: 5,
    laborHoursPerUnitHistoryPeriodDays: 90,
    deliveryHistoryPeriodDays: 10,
    deliveryHistoryMovingAverageDays: 10,
    laborHoursPerUnitHistoryMovingAverageDays: 10,
    firstTimePassHistoryMovingAverageDays: 10,
    firstTimePassHistoryPeriodDays: 10,
    scanOffLineInstancesPeriodDays: 10,
    qualityQuadrantDisplayOption: 'FTP',
    slideshowInterval: 1,
  },
  safetyMessage: 'This is the work cell safety message',
  deliveryMetric: {
    id: '',
    mCellId: '',
    loadingSummary: 426,
    pastDueSummary: 185,
    todayTotalSummary: 783,
    detailMetrics: [
      {
        id: '',
        producingLocationName: '182070',
        productionLineName: '',
        workCenterName: '',
        loading: 86,
        pastDue: 32,
        todayTotal: 214,
      },
      {
        id: '',
        producingLocationName: '186510',
        productionLineName: '',
        workCenterName: '',
        loading: 0,
        pastDue: 0,
        todayTotal: 0,
      },
      {
        id: '',
        producingLocationName: '186520',
        productionLineName: '',
        workCenterName: '',
        loading: 135,
        pastDue: 94,
        todayTotal: 299,
      },
    ],
    createdDate: moment().subtract(1, 'hour').toISOString(),
    updatedDate: moment().subtract(1, 'hour').toISOString(),
  },
  deliveryHistory: [
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').toISOString(),
      actualPercent: 102,
      goalPercent: 100,
      movingAveragePercent: 105,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').subtract(1, 'day').toISOString(),
      actualPercent: 105,
      goalPercent: 100,
      movingAveragePercent: 105,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').subtract(2, 'day').toISOString(),
      actualPercent: 200,
      goalPercent: 100,
      movingAveragePercent: 107,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').subtract(3, 'day').toISOString(),
      actualPercent: 95,
      goalPercent: 100,
      movingAveragePercent: 106,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').subtract(4, 'day').toISOString(),
      actualPercent: 103,
      goalPercent: 100,
      movingAveragePercent: 107,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').subtract(5, 'day').toISOString(),
      actualPercent: 118,
      goalPercent: 100,
      movingAveragePercent: 105,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').subtract(6, 'day').toISOString(),
      actualPercent: 103,
      goalPercent: 100,
      movingAveragePercent: 105,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').subtract(7, 'day').toISOString(),
      actualPercent: 102,
      goalPercent: 100,
      movingAveragePercent: 107,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').subtract(8, 'day').toISOString(),
      actualPercent: 200,
      goalPercent: 100,
      movingAveragePercent: 108,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').subtract(9, 'day').toISOString(),
      actualPercent: 106,
      goalPercent: 100,
      movingAveragePercent: 108,
      insufficientDataForMovingAverage: false,
    },
  ],
  firstTimePassHistory: [
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().toISOString(),
      actualPercent: 87,
      goalPercent: 90,
      movingAveragePercent: 83,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().subtract(1, 'day').toISOString(),
      actualPercent: 83,
      goalPercent: 90,
      movingAveragePercent: 82,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().subtract(2, 'day').toISOString(),
      actualPercent: 72,
      goalPercent: 90,
      movingAveragePercent: 81,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().subtract(3, 'day').toISOString(),
      actualPercent: 83,
      goalPercent: 90,
      movingAveragePercent: 82,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().subtract(4, 'day').toISOString(),
      actualPercent: 86,
      goalPercent: 90,
      movingAveragePercent: 82,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().subtract(5, 'day').toISOString(),
      actualPercent: 84,
      goalPercent: 90,
      movingAveragePercent: 82,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().subtract(6, 'day').toISOString(),
      actualPercent: 76,
      goalPercent: 90,
      movingAveragePercent: 82,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().subtract(7, 'day').toISOString(),
      actualPercent: 91,
      goalPercent: 90,
      movingAveragePercent: 82,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().subtract(8, 'day').toISOString(),
      actualPercent: 86,
      goalPercent: 90,
      movingAveragePercent: 81,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().subtract(9, 'day').toISOString(),
      actualPercent: 83,
      goalPercent: 90,
      movingAveragePercent: 81,
      insufficientDataForMovingAverage: false,
    },
  ],
  firstTimePassMetric: {
    id: '',
    mCellId: '',
    shift: 'Day',
    currentPercent: 100,
    currentGoalPercent: 95,
    previousPercent: 58,
    previousGoalPercent: 95,
    createdDate: moment().subtract(7, 'day').toISOString(),
    updatedDate: moment().subtract(7, 'day').toISOString(),
  },
  currentCrewing: 12,
  laborHoursPerUnitHistory: [
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').toISOString(),
      actual: 0.6085,
      goal: 0.65,
      movingAverage: 0.51,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').subtract(1, 'day').toISOString(),
      actual: 0.5991,
      goal: 0.65,
      movingAverage: 0.55,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').subtract(2, 'day').toISOString(),
      actual: 0.5688,
      goal: 0.65,
      movingAverage: 0.54,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').subtract(3, 'day').toISOString(),
      actual: 0.3665,
      goal: 0.65,
      movingAverage: 0.51,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').subtract(4, 'day').toISOString(),
      actual: 0.5615,
      goal: 0.65,
      movingAverage: 0.52,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').subtract(5, 'day').toISOString(),
      actual: 0.6943,
      goal: 0.65,
      movingAverage: 0.53,
      insufficientDataForMovingAverage: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      shiftDate: moment().startOf('day').subtract(6, 'day').toISOString(),
      actual: 0.6295,
      goal: 0.65,
      movingAverage: 0.53,
      insufficientDataForMovingAverage: false,
    },
  ],
  laborHoursPerUnitMetric: {
    id: '',
    mCellId: '',
    shift: 'Day',
    currentActual: 0.534,
    currentGoal: 0.65,
    previousActual: 0.6332,
    previousGoal: 0.65,
  },
  scanOffLineInstances: [
    {
      id: '',
      shiftDate: moment().subtract(2, 'day').toISOString(),
      mCellId: '',
      shift: 'Day',
      scanOffLineType: 'Originated',
      scanOffLineComponentId: '',
      scanOffLineComponent: {
        id: '1',
        code: 'USWD',
        description: 'UCA SASH WOOD',
        createdDate: '',
        updatedDate: '',
      },
      scanOffLineReasonId: '',
      scanOffLineReason: {
        id: '1',
        code: 'WOOD',
        description: 'WOOD REASON DESCRIPTION',
        createdDate: '',
        updatedDate: '',
      },
      instances: 9,
      createdDate: '',
      updatedDate: '',
    },
    {
      id: '',
      shiftDate: moment().subtract(3, 'hour').toISOString(),
      mCellId: '',
      shift: 'Day',
      scanOffLineType: 'Originated',
      scanOffLineComponentId: '',
      scanOffLineComponent: {
        id: '2',
        code: 'USMT',
        description: 'UCA SASH METAL',
        createdDate: '',
        updatedDate: '',
      },
      scanOffLineReasonId: '',
      scanOffLineReason: {
        id: '2',
        code: 'G02',
        description: 'G02 REASON DESCRIPTION',
        createdDate: '',
        updatedDate: '',
      },
      instances: 7,
      createdDate: '',
      updatedDate: '',
    },
    {
      id: '',
      shiftDate: moment().subtract(3, 'day').toISOString(),
      mCellId: '',
      shift: 'Day',
      scanOffLineType: 'Originated',
      scanOffLineComponentId: '',
      scanOffLineComponent: {
        id: '3',
        code: 'GLS',
        description: 'GLASS',
        createdDate: '',
        updatedDate: '',
      },
      scanOffLineReasonId: '',
      scanOffLineReason: {
        id: '3',
        code: 'GDEF',
        description: 'WOOD SDL 7/8 OR 1 1/8 BAR',
        createdDate: '',
        updatedDate: '',
      },
      instances: 4,
      createdDate: '',
      updatedDate: '',
    },
    {
      id: '',
      shiftDate: moment().subtract(4, 'day').toISOString(),
      mCellId: '',
      shift: 'Day',
      scanOffLineType: 'Originated',
      scanOffLineComponentId: '',
      scanOffLineComponent: {
        id: '4',
        code: 'SAMP',
        description: 'SAMP COMPONENT',
        createdDate: '',
        updatedDate: '',
      },
      scanOffLineReasonId: '',
      scanOffLineReason: {
        id: '4',
        code: 'SAMP',
        description: 'FIVE WORD SAMP REASON UGH',
        createdDate: '',
        updatedDate: '',
      },
      instances: 2,
      createdDate: '',
      updatedDate: '',
    },
  ],
  productionMetric: {
    id: '',
    mCellId: '',
    shift: 'Day',
    currentActual: 9,
    currentTarget: 7,
    currentGoal: 29,
    previousActual: 28,
    previousGoal: 29,
    currentVariance: 2,
    currentVarianceColor: 'Red',
    createdDate: moment().subtract(3, 'hour').toISOString(),
    updatedDate: moment().subtract(3, 'hour').toISOString(),
  },
  roster: [
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      mCellEmployeeId: '',
      mCellEmployee: {
        id: '436abd40-a63b-489b-0bb2-08d7dc97ddde',
        employeeId: '17390',
        inventoryCenter: {
          id: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
          code: '08',
          description: 'GRAFTON PLANT',
          enabled: true,
        },
        inventoryCenterId: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
        department: {
          id: 'a9ea06cf-ce01-4a1a-b2ce-06accec586eb',
          code: '222',
          description: 'TRAPS',
          inventoryCenter: {
            id: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
            code: '08',
            description: 'GRAFTON PLANT',
            enabled: true,
          },
          isDepartmentOfInterest: true,
        },
        homeMCell: {
          id: '7676023f-3176-4342-b063-48ba10bb15d2',
          code: 'TRAPASSY',
          description: 'Traps Assembly',
          inventoryCenter: {
            id: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
            code: '08',
            description: 'GRAFTON PLANT',
            enabled: true,
          },
          inventoryCenterId: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
          isRollup: false,
          componentMCells: [],
          sortSequence: 0,
        },
        defaultMCellShift: 'Weekend',
        fullBadgeId: '11107653',
        firstName: 'Cody',
        lastName: 'Bak',
        user: {
          id: '55a1a5eb-2d32-416f-9d23-ce78de79c09e',
          name: 'CODY BAK',
          username: 'CODYBAK',
          email: 'codybak@marvin.com',
          employeeId: '',
          jiraAccountId: '',
        },
        supervisorName: 'Edward Liebersbach',
        role: {
          id: '966f0b15-4015-4d2c-854e-5c2ac1cdbff1',
          title: 'MATERIAL HANDLER II',
        },
        accountType: {
          id: 'ce5a4ec1-03d1-4e3b-9386-a719efc94107',
          accountTypeId: '7500',
          description: 'Direct Labor',
        },
        category: {
          id: '6c5a3c2b-98c9-4445-8610-770ff94b8030',
          categoryId: 'FR',
          description: 'Full Time Regular',
        },
      },
      currentMCell: {
        id: 'e9223c0a-a778-4f07-1423-08d945914128',
        code: 'G2A-COMP-D',
        description: 'UDH-G2 Line A Components',
        inventoryCenter: {
          id: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
          code: '08',
          description: 'GRAFTON PLANT',
          enabled: true,
        },
        inventoryCenterId: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
        isRollup: false,
        componentMCells: [],
        sortSequence: 0,
      },
      isExperienced: false,
      isPlanned: false,
      isLoggedIn: true,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      mCellEmployeeId: '',
      mCellEmployee: {
        id: '491b93ef-b613-4926-b359-02e2de97e744',
        employeeId: '23237',
        inventoryCenter: {
          id: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
          code: '08',
          description: 'GRAFTON PLANT',
          enabled: true,
        },
        inventoryCenterId: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
        department: {
          id: 'a9ea06cf-ce01-4a1a-b2ce-06accec586eb',
          code: '222',
          description: 'TRAPS',
          inventoryCenter: {
            id: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
            code: '08',
            description: 'GRAFTON PLANT',
            enabled: true,
          },
          isDepartmentOfInterest: true,
        },
        homeMCell: {
          id: '7676023f-3176-4342-b063-48ba10bb15d2',
          code: 'TRAPASSY',
          description: 'Traps Assembly',
          inventoryCenter: {
            id: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
            code: '08',
            description: 'GRAFTON PLANT',
            enabled: true,
          },
          inventoryCenterId: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
          isRollup: false,
          componentMCells: [],
          sortSequence: 0,
        },
        defaultMCellShift: 'Night',
        fullBadgeId: '11107610',
        firstName: 'Steve',
        lastName: 'Rinde',
        user: {
          id: '64f217b8-ad29-46eb-83e7-67bac1024fcb',
          name: 'Steve',
          username: 'G_STEVENR',
          email: 'g_stevenr@marvin.com',
          employeeId: '',
          jiraAccountId: '',
        },
        supervisorName: 'Chad Bjorneby',
        role: {
          id: '966f0b15-4015-4d2c-854e-5c2ac1cdbff1',
          title: 'MATERIAL HANDLER II',
        },
        accountType: {
          id: 'ce5a4ec1-03d1-4e3b-9386-a719efc94107',
          accountTypeId: '7500',
          description: 'Direct Labor',
        },
        category: {
          id: '6c5a3c2b-98c9-4445-8610-770ff94b8030',
          categoryId: 'FR',
          description: 'Full Time Regular',
        },
      },
      currentMCell: undefined,
      isExperienced: false,
      isPlanned: true,
      isLoggedIn: false,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      mCellEmployeeId: '',
      mCellEmployee: {
        id: 'e51ba9b5-eebf-4ffe-b35e-08d7dcb6990c',
        employeeId: '12343',
        inventoryCenter: {
          id: 'b55dcaad-749d-4877-98db-0f09f43626f3',
          code: '01',
          description: 'WARROAD',
          enabled: true,
        },
        inventoryCenterId: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
        department: {
          id: 'bb336e59-3f41-47b3-8c49-c695f5aa1b9b',
          code: '262',
          description: 'ULTIMATE CASEMASTER',
          inventoryCenter: {
            id: 'b55dcaad-749d-4877-98db-0f09f43626f3',
            code: '01',
            description: 'WARROAD',
            enabled: true,
          },
          isDepartmentOfInterest: true,
        },
        homeMCell: {
          id: 'fc54ede2-9992-48a6-87b5-4d549f2ab637',
          code: 'UCAFRAMECPAOCOV',
          description: 'Casement - Frame Component Covers',
          inventoryCenter: {
            id: 'b55dcaad-749d-4877-98db-0f09f43626f3',
            code: '01',
            description: 'WARROAD',
            enabled: true,
          },
          inventoryCenterId: 'b55dcaad-749d-4877-98db-0f09f43626f3',
          isRollup: false,
          componentMCells: [],
          sortSequence: 0,
        },
        defaultMCellShift: 'Night',
        fullBadgeId: '09007518',
        firstName: 'David',
        lastName: 'Buck',
        supervisorName: 'Clyde Holmgren',
        role: {
          id: '2da2345c-133e-4d73-bc9e-9f8bcf634bf8',
          title: 'OPERATOR I',
        },
        accountType: {
          id: 'ce5a4ec1-03d1-4e3b-9386-a719efc94107',
          accountTypeId: '7500',
          description: 'Direct Labor',
        },
        category: {
          id: 'b6e70831-1924-4f27-9e92-94e9624320e5',
          categoryId: 'PR',
          description: 'Part Time Regular',
        },
      },
      currentMCell: {
        id: 'e9223c0a-a778-4f07-1423-08d945914128',
        code: 'G2A-COMP-D',
        description: 'UDH-G2 Line A Components',
        inventoryCenter: {
          id: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
          code: '08',
          description: 'GRAFTON PLANT',
          enabled: true,
        },
        inventoryCenterId: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
        isRollup: false,
        componentMCells: [],
        sortSequence: 0,
      },
      isExperienced: true,
      isPlanned: true,
      isLoggedIn: true,
    },
    {
      id: '',
      mCellId: '',
      shift: 'Day',
      mCellEmployeeId: '',
      mCellEmployee: {
        id: 'e51ba9b5-eebf-4ffe-b35e-08d7dcb6990c',
        employeeId: '8675309',
        inventoryCenter: {
          id: 'b55dcaad-749d-4877-98db-0f09f43626f3',
          code: '01',
          description: 'WARROAD',
          enabled: true,
        },
        inventoryCenterId: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
        department: {
          id: 'bb336e59-3f41-47b3-8c49-c695f5aa1b9b',
          code: '262',
          description: 'ULTIMATE CASEMASTER',
          inventoryCenter: {
            id: 'b55dcaad-749d-4877-98db-0f09f43626f3',
            code: '01',
            description: 'WARROAD',
            enabled: true,
          },
          isDepartmentOfInterest: true,
        },
        homeMCell: {
          id: 'fc54ede2-9992-48a6-87b5-4d549f2ab637',
          code: 'UCAFRAMECPAOCOV',
          description: 'Casement - Frame Component Covers',
          inventoryCenter: {
            id: 'b55dcaad-749d-4877-98db-0f09f43626f3',
            code: '01',
            description: 'WARROAD',
            enabled: true,
          },
          inventoryCenterId: 'b55dcaad-749d-4877-98db-0f09f43626f3',
          isRollup: false,
          componentMCells: [],
          sortSequence: 0,
        },
        defaultMCellShift: 'Day',
        fullBadgeId: '09007171',
        firstName: 'Tom',
        lastName: 'Hemming',
        supervisorName: 'Clyde Holmgren',
        role: {
          id: '2da2345c-133e-4d73-bc9e-9f8bcf634bf8',
          title: 'OPERATOR I',
        },
        accountType: {
          id: 'ce5a4ec1-03d1-4e3b-9386-a719efc94107',
          accountTypeId: '7500',
          description: 'Direct Labor',
        },
        category: {
          id: 'b6e70831-1924-4f27-9e92-94e9624320e5',
          categoryId: 'PR',
          description: 'Part Time Regular',
        },
      },
      currentMCell: {
        id: '7676023f-3176-4342-b063-48ba10bb15d2',
        code: 'TRAPASSY',
        description: 'Traps Assembly',
        inventoryCenter: {
          id: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
          code: '08',
          description: 'GRAFTON PLANT',
          enabled: true,
        },
        inventoryCenterId: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
        isRollup: false,
        componentMCells: [],
        sortSequence: 0,
      },
      isExperienced: true,
      isPlanned: true,
      isLoggedIn: false,
    },
  ],
  safetyTopic: {
    id: '',
    inventoryCenterId: '',
    filename: '',
    url: 'https://ccc1ea0b7f3st.blob.core.windows.net/public-temp/WeeklySafetyMessageWithHeader.pdf',
  },
  safetyMetric: {
    id: '',
    mCellId: '',
    shift: 'Day',
    daysSinceLastRecordable: 712,
    createdDate: moment().subtract(3, 'minute').toISOString(),
    updatedDate: moment().subtract(3, 'minute').toISOString(),
  },
  mCell: {
    id: '7676023f-3176-4342-b063-48ba10bb15d2',
    code: 'TRAPASSY',
    description: 'Traps Assembly',
    inventoryCenter: {
      id: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
      code: '08',
      description: 'GRAFTON PLANT',
      enabled: true,
    },
    inventoryCenterId: '8d32731e-7a93-438c-8fe9-576a5cd1d73e',
    isRollup: false,
    componentMCells: [],
    sortSequence: 0,
  },
  shift: 'Day',
  productionCurrentColor: 'Green',
  productionPreviousColor: 'Green',
  firstTimePassCurrentColor: 'Green',
  firstTimePassPreviousColor: 'Green',
};
