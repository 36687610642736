// Scope of AAD app. Use the below configuration to use all the permissions provided in the AAD app through Azure portal.
// Refer https://aka.ms/PowerBIPermissions for complete list of Power BI scopes
export const powerConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID!,
    authority: process.env.REACT_APP_AZURE_AD_AUTHORITY!,
  },
  api: {
    scope: process.env.REACT_APP_REPORT_API_SCOPE!,
    scope2: process.env.REACT_APP_DASHBOARD_API_SCOPE!,
  },
};
