import { AnyAction, Dispatch } from 'redux';
import {
  AsyncThunk,
  AsyncThunkPayloadCreator,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { setAlertAction } from '../features/app/appSlice';

export type DataWithId = {
  id: string;
  [key: string]: any;
};

export type DataWithoutId = Omit<DataWithId, 'id'>;

/**
 * Generic thunk for fetching data from API and setting it in the redux state
 * @param params
 * @param fetcher
 * @param loadingAction
 * @param setDataAction
 * @param errorMessageKey
 * @param clearBeforeFetch
 */
export const fetchDataThunk = (
  params: Array<any>,
  fetcher: (...params: Array<any>) => Promise<any>,
  loadingAction: (loading: boolean) => AnyAction,
  setDataAction: (data: any) => any,
  errorMessageKey: string,
  clearBeforeFetch = false,
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingAction(true));
      if (clearBeforeFetch) {
        dispatch(setDataAction(undefined));
      }
      const data = await fetcher(...params);
      if (data === '') {
        dispatch(setDataAction(null));
      } else {
        dispatch(setDataAction(data));
      }
      dispatch(loadingAction(false));
    } catch (e) {
      dispatch(loadingAction(false));
      dispatch(setAlertAction({ alertMessageKey: errorMessageKey }));
    }
  };
};

/**
 * Generic thunk for calling an update API.
 * Optionally sets data optimistically if `updateAction` is specified
 * @param id
 * @param dataWithoutId
 * @param onComplete
 * @param apiUpdateCall
 * @param loadingAction
 * @param errorMessageKey
 * @param updateAction
 */
export const updateDataThunk = (
  id: string,
  dataWithoutId: DataWithoutId,
  onComplete: (success: boolean, result?: any) => void,
  apiUpdateCall: (id: string, dataWithoutId: any) => Promise<DataWithId>,
  loadingAction: (loading: boolean) => AnyAction,
  errorMessageKey: string,
  updateAction?: (dataWithId: any) => AnyAction,
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingAction(true));
      const dataWithId: DataWithId = { id, ...dataWithoutId };
      if (updateAction) {
        dispatch(updateAction(dataWithId));
      }
      const result = await apiUpdateCall(id, dataWithoutId);
      dispatch(loadingAction(false));
      onComplete(true, result);
    } catch (e) {
      dispatch(loadingAction(false));
      dispatch(setAlertAction({ alertMessageKey: errorMessageKey }));
      onComplete(false);
    }
  };
};

/**
 * Generic thunk for calling API.
 * Optionally sets data optimistically if `setDataAction` is specified.
 * @param params
 * @param onComplete
 * @param apiCall
 * @param loadingAction
 * @param errorMessageKey
 * @param preSetDataAction
 * @param postSetDataAction
 */
export const callApiThunk = (
  params: Array<any>,
  onComplete: (success: boolean, result?: any) => void,
  apiCall: (...params: Array<any>) => Promise<any>,
  loadingAction: (loading: boolean) => any,
  errorMessageKey: string,
  preSetDataAction?: (...params: Array<any>) => AnyAction,
  postSetDataAction?: (...params: Array<any>) => AnyAction,
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingAction(true));
      if (preSetDataAction) {
        dispatch(preSetDataAction(...params));
      }
      const result = await apiCall(...params);
      dispatch(loadingAction(false));
      if (postSetDataAction) {
        dispatch(postSetDataAction(result));
      }
      onComplete(true, result);
    } catch (e) {
      dispatch(loadingAction(false));
      dispatch(setAlertAction({ alertMessageKey: errorMessageKey }));
      onComplete(false);
    }
  };
};

/**
 * Wraps `createAsyncThunk` from redux-toolkit to show alert on error.
 * @param typePrefix
 * @param payloadCreator
 * @param errorStringKey
 */
export const createAsyncThunkWithError = <Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg>,
  errorStringKey: string,
): AsyncThunk<Returned, ThunkArg, any> => {
  return createAsyncThunk<Returned, ThunkArg, any>(
    typePrefix,
    async (arg, thunkAPI): Promise<any> => {
      try {
        return await payloadCreator(arg, thunkAPI);
      } catch (err) {
        thunkAPI.dispatch(setAlertAction({ alertMessageKey: errorStringKey }));
        return thunkAPI.rejectWithValue(null);
      }
    },
  );
};
