import {
  HttpTransportType,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import { Store } from 'redux';
import { CELL_BOARD_SHIFT_SELECTED } from './features/profile/types';
import {
  addDowntimeAction,
  deleteDowntimeAction,
  getDowntimesThunk,
  setCurrentDowntimeAction,
  updateDowntimeAction,
  addDowntimeReasonAction,
  updateDowntimeReasonAction,
  deleteDowntimeReasonAction,
  addDowntimeReasonSupportGroupMappingAction,
  updateDowntimeReasonSupportGroupMappingAction,
  deleteDowntimeReasonSupportGroupMappingAction,
} from './features/downtime/downtimeSlice';
import { getAccessToken, getAccount } from './auth';
import {
  addOrUpdateMCellCollectionAction,
  deleteMCellCollectionAction,
} from './features/manufacturingLocation/manufacturingLocationSlice';
import { isSameUser } from './features/app/utils';
import {
  Downtime,
  DowntimeReason,
  DowntimeReasonSupportGroupMapping,
} from './features/downtime/types';
import {
  addOrUpdateCellBoardMessageAction,
  deleteCellBoardMessageAction,
  deleteMCellMessageAction,
  addOrUpdateMCellMessageAction,
} from './features/cellBoard/cellBoardSlice';
import qs from 'qs';
import { RootState } from './store';
import {
  addOrUpdateSupportGroupAction,
  addOrUpdateSupportGroupDowntimeEscalationOverrideAction,
  addOrUpdateSupportGroupDowntimeEscalationPositionOverrideAction,
  addOrUpdateSupportHierarchyAction,
  deleteSupportGroupAction,
  deleteSupportGroupDowntimeEscalationOverrideAction,
  deleteSupportGroupDowntimeEscalationPositionOverrideAction,
  deleteSupportHierarchyAction,
} from './features/supportHierarchy/supportHierarchySlice';
import {
  setAlertAction,
  setConnectionStateAction,
  versionCheckThunk,
} from './features/app/appSlice';
import {
  addOrUpdateDashboardAction,
  deleteDashboardAction,
} from './features/dashboard/dashboardSlice';
import { Dashboard } from './features/dashboard/types';
import {
  CellBoardMCellMessageDetailDto,
  CellBoardMessageDetailDto,
} from './features/cellBoard/types';
import { MCellCollection } from './features/manufacturingLocation/types';
import { selectManufacturingLocation } from './features/profile/utils';
import { EMPTY_GUID } from './utils/string-utils';
import { updateRefreshRunningAction } from './features/schedulingTool/schedulingToolSlice';
import { updateEmployeeRefreshAction } from './features/mCellEmployee/mCellEmployeeSlice';
const SIGNAL_R_ROOT_PATH = '/hubs/ccc';

/**
 * Starts the signalR client. Handles reconnection logic and incoming messages
 * @param onConnect
 * @param store
 */
const startSignalR = async (onConnect: () => void, store: Store<RootState>) => {
  const { mCellId } = selectManufacturingLocation(store.getState());
  const path =
    SIGNAL_R_ROOT_PATH + qs.stringify({ mCellId }, { addQueryPrefix: true });
  // function for restarting signalR
  const restart = () => startSignalR(onConnect, store);
  const connection = new HubConnectionBuilder()
    .withUrl(path, {
      accessTokenFactory: async () => (await getAccessToken()) as any,
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
    })
    .withAutomaticReconnect([
      0,
      2000, // 2 secs
      2000,
      2000,
      10000, // 10 secs
      10000,
      10000,
      30000, // 30 secs
      30000,
      30000,
      60000, // 1 min
      60000,
      60000,
      3600000, // 1 hr
      3600000,
      3600000,
      86400000, // 24 hrs
    ])
    .configureLogging(LogLevel.Information)
    .build();

  // Watches for change in MCell setting and disconnects signalR so that it
  // will reconnect with the new MCell
  store.subscribe(() => {
    const { mCellId: newMCellId } = selectManufacturingLocation(
      store.getState()
    );
    if (
      connection &&
      connection.state === HubConnectionState.Connected &&
      mCellId !== newMCellId
    ) {
      connection.stop();
    }
  });

  const dispatch = store.dispatch;

  connection.onclose((e) => {
    dispatch(setConnectionStateAction('disconnected'));
    // restart when connection closes because sometimes it does not transition to reconnection state
    setTimeout(restart, 1000);
  });

  connection.onreconnecting((e) => {
    dispatch(setConnectionStateAction('disconnected'));
    dispatch(
      setAlertAction({ alertMessageKey: 'reconnecting', alertLevel: 'warn' })
    );
  });

  connection.onreconnected((cId) => {
    dispatch(setConnectionStateAction('connected'));
    dispatch(
      setAlertAction({ alertMessageKey: 'reconnected', alertLevel: 'success' })
    );
    // TODO RSTW-921: only fetch issues if the user is on the issues page
    dispatch(getDowntimesThunk(true) as any);
  });

  try {
    // Application wide related messages
    connection.on('Application', (messageType: string, payload: string) => {
      switch (messageType) {
        case 'Version':
          dispatch(versionCheckThunk(payload) as any);
          break;
        default:
          break;
      }
    });

    // Downtime related messages
    connection.on('Downtime', (messageType: string, payload: any) => {
      const state = store.getState() as RootState;
      const { mCellId, inventoryCenterId } = selectManufacturingLocation(state);

      switch (messageType) {
        case 'DowntimeCreated':
          // ignore messages not meant for this MCell or Inv Center
          if (mCellId !== EMPTY_GUID && mCellId !== payload.mCell.id) {
            return;
          }
          if (
            inventoryCenterId !== EMPTY_GUID &&
            inventoryCenterId !== payload.mCell.inventoryCenter.id
          ) {
            return;
          }
          const createIndex = state.downtime.downtimes.findIndex(
            (d) => d.id === payload.id
          );
          if (createIndex >= 0) {
            return;
          }
          dispatch(addDowntimeAction(payload));
          break;
        case 'DowntimeUpdated':
          const downtimeUpdated = payload as Downtime;
          dispatch(updateDowntimeAction(downtimeUpdated));
          if (
            state.downtime.downtimeDetail &&
            state.downtime.downtimeDetail.id === downtimeUpdated.id
          ) {
            dispatch(setCurrentDowntimeAction(downtimeUpdated));
          }
          break;
        case 'DowntimeDeleted':
          dispatch(deleteDowntimeAction(payload));
          break;
        default:
          break;
      }
    });

    // Downtime Reason related messages
    connection.on('DowntimeReason', (messageType: string, payload: any) => {
      switch (messageType) {
        case 'DowntimeReasonCreated':
          dispatch(addDowntimeReasonAction(payload));
          break;
        case 'DowntimeReasonUpdated':
          const downtimeReasonUpdated = payload as DowntimeReason;
          dispatch(updateDowntimeReasonAction(downtimeReasonUpdated));
          break;
        case 'DowntimeReasonDeleted':
          dispatch(deleteDowntimeReasonAction(payload));
          break;
        case 'DowntimeReasonSupportGroupMappingCreated':
          dispatch(addDowntimeReasonSupportGroupMappingAction(payload));
          break;
        case 'DowntimeReasonSupportGroupMappingUpdated':
          const downtimeReasonSupportGroupMappingUpdated = payload as DowntimeReasonSupportGroupMapping;
          dispatch(
            updateDowntimeReasonSupportGroupMappingAction(
              downtimeReasonSupportGroupMappingUpdated
            )
          );
          break;
        case 'DowntimeReasonSupportGroupMappingDeleted':
          dispatch(deleteDowntimeReasonSupportGroupMappingAction(payload));
          break;
        default:
          break;
      }
    });

    // manufacturing location related messages
    connection.on(
      'ManufacturingLocation',
      (messageType: string, payload: any) => {
        const account = getAccount();
        switch (messageType) {
          case 'MCellCollectionCreated':
            const mCellCollectionAdded = payload as MCellCollection;
            if (
              mCellCollectionAdded.isGlobal ||
              isSameUser(account, mCellCollectionAdded.createdBy)
            ) {
              dispatch(addOrUpdateMCellCollectionAction(mCellCollectionAdded));
            }
            break;
          case 'MCellCollectionUpdated':
            const mCellCollectionUpdated = payload as MCellCollection;
            if (
              mCellCollectionUpdated.isGlobal ||
              isSameUser(account, mCellCollectionUpdated.createdBy)
            ) {
              dispatch(
                addOrUpdateMCellCollectionAction(mCellCollectionUpdated)
              );
            } else {
              dispatch(deleteMCellCollectionAction(mCellCollectionUpdated.id));
            }
            break;
          case 'MCellCollectionDeleted':
            dispatch(deleteMCellCollectionAction(payload));
            break;
          default:
            break;
        }
      }
    );

    // Command Center related messages
    connection.on('CommandCenter', (messageType: string, payload: any) => {
      const account = getAccount();
      switch (messageType) {
        case 'DashboardCreated':
        case 'DashboardUpdated':
          const dashboardUpdated = payload as Dashboard;
          if (
            dashboardUpdated.isGlobal ||
            isSameUser(account, dashboardUpdated.owner) ||
            dashboardUpdated.dashboardSharedUsers.some((d) =>
              isSameUser(account, d.sharedUser)
            )
          ) {
            dispatch(addOrUpdateDashboardAction(payload));
          }
          break;
        case 'DashboardDeleted':
          dispatch(deleteDashboardAction(payload));
          break;
        default:
          break;
      }
    });

    // Cell Board related messages
    connection.on('CellBoard', (messageType: string, payload: any) => {
      const account = getAccount();
      const state = store.getState() as RootState;
      const { mCellId } = selectManufacturingLocation(state);
      const shift = JSON.parse(
        state.profile.userSetting[CELL_BOARD_SHIFT_SELECTED] ?? ''
      );
      switch (messageType) {
        case 'CellBoardMessageCreated':
          const messageCreated = payload as CellBoardMessageDetailDto;
          if (isSameUser(account, messageCreated.createdBy)) {
            dispatch(addOrUpdateCellBoardMessageAction(messageCreated));
          }
          break;
        case 'CellBoardMessageUpdated':
          const messageUpdated = payload as CellBoardMessageDetailDto;
          if (isSameUser(account, messageUpdated.createdBy)) {
            dispatch(addOrUpdateCellBoardMessageAction(messageUpdated));
          }
          break;
        case 'CellBoardMessageDeleted':
          dispatch(deleteCellBoardMessageAction(payload));
          break;
        case 'CellBoardMCellMessageCreated':
          const mCellMessageCreated = payload as CellBoardMCellMessageDetailDto;
          if (
            mCellId === mCellMessageCreated.mCellId &&
            shift === mCellMessageCreated.shift
          ) {
            dispatch(addOrUpdateMCellMessageAction(payload));
          }
          break;
        case 'CellBoardMCellMessageUpdated':
          const mCellMessageUpdated = payload as CellBoardMCellMessageDetailDto;
          if (
            mCellId === mCellMessageUpdated.mCellId &&
            shift === mCellMessageUpdated.shift
          ) {
            dispatch(addOrUpdateMCellMessageAction(mCellMessageUpdated));
          }
          break;
        case 'CellBoardMCellMessageDeleted':
          dispatch(deleteMCellMessageAction(payload));
          break;
        default:
          break;
      }
    });

    // Support Hierarchy related messages
    connection.on('SupportHierarchy', (messageType: string, payload: any) => {
      switch (messageType) {
        case 'SupportHierarchyCreated':
          dispatch(addOrUpdateSupportHierarchyAction(payload));
          break;
        case 'SupportHierarchyUpdated':
          dispatch(addOrUpdateSupportHierarchyAction(payload));
          break;
        case 'SupportHierarchyDeleted':
          dispatch(deleteSupportHierarchyAction(payload));
          break;
        case 'SupportGroupCreated':
          dispatch(addOrUpdateSupportGroupAction(payload));
          break;
        case 'SupportGroupUpdated':
          dispatch(addOrUpdateSupportGroupAction(payload));
          break;
        case 'SupportGroupDeleted':
          dispatch(deleteSupportGroupAction(payload));
          break;
        case 'SupportGroupDowntimeEscalationOverrideCreated':
          dispatch(
            addOrUpdateSupportGroupDowntimeEscalationOverrideAction(payload)
          );
          break;
        case 'SupportGroupDowntimeEscalationOverrideUpdated':
          dispatch(
            addOrUpdateSupportGroupDowntimeEscalationOverrideAction(payload)
          );
          break;
        case 'SupportGroupDowntimeEscalationOverrideDeleted':
          dispatch(deleteSupportGroupDowntimeEscalationOverrideAction(payload));
          break;
        case 'SupportGroupDowntimeEscalationPositionOverrideCreated':
          dispatch(
            addOrUpdateSupportGroupDowntimeEscalationPositionOverrideAction(
              payload
            )
          );
          break;
        case 'SupportGroupDowntimeEscalationPositionOverrideUpdated':
          dispatch(
            addOrUpdateSupportGroupDowntimeEscalationPositionOverrideAction(
              payload
            )
          );
          break;
        case 'SupportGroupDowntimeEscalationPositionOverrideDeleted':
          dispatch(
            deleteSupportGroupDowntimeEscalationPositionOverrideAction(payload)
          );
          break;
        default:
          break;
      }
    });

    // Scheduling Tool related messages
    connection.on('SchedulingTool', (messageType: string, signal: boolean) => {
      switch (messageType) {
        case 'RefreshInitiated':
          dispatch(updateRefreshRunningAction(signal));
          break;
        default:
          break;
      }
    });

    // Employee related messages
    connection.on('Employee', (messageType: string, signal: boolean) => {
      switch (messageType) {
        case 'RefreshEmployeesInitiated':
          dispatch(updateEmployeeRefreshAction(signal));
          break;
        default:
          break;
      }
    });

    await connection.start();
    dispatch(setConnectionStateAction('connected'));
    onConnect();
  } catch (e) {
    dispatch(
      setAlertAction({ alertMessageKey: 'disconnected', alertLevel: 'error' })
    );
    setTimeout(restart, 3000);
  }

  return connection;
};

export default startSignalR;
