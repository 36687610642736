import React from 'react';

type ErrorContextType = {
  contextValidationError: boolean;
  setContextValidationError: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Context for error handling in scheduling tool
 */
export const ErrorContext = React.createContext<ErrorContextType>({
  contextValidationError: false,
  setContextValidationError: () => {},
});
