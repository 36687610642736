import { useTranslation } from 'react-i18next';
import GridTableDefault, {
  ColumnWithValidationRules,
} from '../../common/GridTableDefault';
import { useSelector } from 'react-redux';
import {
  createHomeLinkGroupThunk,
  deleteHomeLinkGroupThunk,
  getHomeLinkGroupsThunk,
  selectHomeLinkGroups,
  updateHomeLinkGroupThunk,
} from '../homeSlice';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../store';
import { HomeLinkGroupCreate } from '../types';
import {
  HOME_LINK_CONFIG_GROUP_TABLE_COLUMN_ORDER,
  HOME_LINK_CONFIG_GROUP_TABLE_COLUMN_WIDTHS,
  HOME_LINK_CONFIG_GROUP_TABLE_HIDDEN_COLUMNS,
  HOME_LINK_CONFIG_GROUP_TABLE_SORTING,
} from '../../profile/types';
import { InputNumberProvider } from '../../common/GridDataFormatters';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { unwrapResult } from '@reduxjs/toolkit';
import ConfirmDialog from '../../common/ConfirmDialog';

type Props = {
  inventoryCenterId: string;
};

/**
 * Editable table of Home Link Groups
 * @param inventoryCenterId
 * @constructor
 */
export const HomeLinkGroupsConfigListTable = ({ inventoryCenterId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [deleteId, setDeleteId] = useState<string | undefined>();
  const homeLinkGroups = useSelector(selectHomeLinkGroups);
  const columns: ColumnWithValidationRules[] = [
    {
      title: t('name'),
      name: 'name',
      isRequired: true,
    },
    {
      title: t('sortSequence'),
      name: 'sortSequence',
      isRequired: true,
    },
  ];

  const defaultColumnWidths = columns.map((x) => ({
    columnName: x.name,
    width: '300',
  }));

  /**
   * Effect to load Home Link Groups
   */
  useEffect(() => {
    dispatch(getHomeLinkGroupsThunk([inventoryCenterId]));
  }, [dispatch, inventoryCenterId]);

  /**
   * Called when Home Link Groups table change is committed
   * Dispatches call to update record on server and optimistically saves changes
   * @param changeSet
   */
  function onCommitChanges(changeSet: ChangeSet) {
    const { added, changed, deleted } = changeSet;
    if (added) {
      added.forEach((row) => {
        const homeLinkGroupCreate: HomeLinkGroupCreate = {
          name: row.name,
          sortSequence: row.sortSequence,
          inventoryCenterId: inventoryCenterId,
        };
        dispatch(createHomeLinkGroupThunk(homeLinkGroupCreate))
          .then(unwrapResult)
          .then(() => dispatch(getHomeLinkGroupsThunk([inventoryCenterId])));
      });
    }
    if (changed) {
      Object.keys(changed).forEach((id: string) => {
        const prev = homeLinkGroups.find((s) => s.id === id);
        if (!prev) return;
        const next = { ...prev, ...changed[id] };
        dispatch(
          updateHomeLinkGroupThunk({
            id: next.id,
            info: { name: next.name, sortSequence: next.sortSequence },
          })
        )
          .then(unwrapResult)
          .then(() => dispatch(getHomeLinkGroupsThunk([inventoryCenterId])));
      });
    }
    if (deleted && deleted.length > 0) {
      const id = deleted[0] as string;
      const homeLinkGroup = homeLinkGroups.find((s) => s.id === id);
      if (!homeLinkGroup) return;
      setDeleteId(homeLinkGroup.id);
    }
  }

  /**
   * Called when delete is confirmed. Dispatches call to delete entry and closes confirmation dialog
   */
  function handleDeleteConfirm() {
    if (deleteId) {
      dispatch(deleteHomeLinkGroupThunk(deleteId)).then(() =>
        dispatch(getHomeLinkGroupsThunk([inventoryCenterId]))
      );
      setDeleteId(undefined);
    }
  }

  return (
    <>
      <GridTableDefault
        columns={columns}
        rows={homeLinkGroups}
        getRowId={(row) => row.id}
        editSettings={{
          onCommitChanges,
          editDisabledColumns: ['inventoryCenterId'],
          showAddCommand: true,
          showEditCommand: true,
          showDeleteCommand: true,
        }}
        providers={[
          <InputNumberProvider
            key={0}
            inputProps={{ min: 0, step: 1 }}
            for={['sortSequence']}
          />,
        ]}
        persistedSettings={{
          columnOrderSettingKey: HOME_LINK_CONFIG_GROUP_TABLE_COLUMN_ORDER,
          columnWidthsSettingKey: HOME_LINK_CONFIG_GROUP_TABLE_COLUMN_WIDTHS,
          sortingSettingKey: HOME_LINK_CONFIG_GROUP_TABLE_SORTING,
          hiddenColumnsSettingKey: HOME_LINK_CONFIG_GROUP_TABLE_HIDDEN_COLUMNS,
          defaultColumnWidths: defaultColumnWidths,
        }}
        enableExport={true}
        exportFileName={`${t('homeLinkGroups')}.csv`}
      />
      <ConfirmDialog
        title={t('deleteHomeLinkGroup')}
        message={t('doYouWantToDeleteHomeLinkGroup')}
        open={!!deleteId}
        onConfirm={handleDeleteConfirm}
        onCancel={() => {
          setDeleteId(undefined);
        }}
      />
    </>
  );
};

export default HomeLinkGroupsConfigListTable;
