import React from 'react';
import TextField from '@mui/material/TextField';
import { OptionValue } from './EnhancedSelect';
import {
  Autocomplete,
  autocompleteClasses,
  Chip,
  Popper,
  SxProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { styled } from '@mui/material/styles';

type SelectContextValue = {
  disabled?: boolean;
  tipText?: string;
  tipDisabled?: boolean;
};

export type ChangeReason =
  | 'createOption'
  | 'selectOption'
  | 'removeOption'
  | 'blur'
  | 'clear';

type Props = {
  label: string;
  data: Array<OptionValue>;
  value: any;
  isOptionEqualToValue?: (option: OptionValue, value: OptionValue) => boolean;
  multiple?: boolean;
  onChange: (value: any, reason: ChangeReason) => void;
  id?: string;
  placeholder?: string;
  containerClass?: string;
  disabled?: boolean;
  shouldVirtualize?: boolean;
  tipText?: string;
  tipDisabled?: boolean;
  sx?: SxProps;
};

const SelectContext = React.createContext<SelectContextValue>({});

/**
 * EnhancedMultiSelect is a component that wraps the Material-UI Autocomplete component
 * to provide a customized multi-select input with optional virtualization for handling
 * large datasets efficiently.
 *
 * The component is context-aware and supports additional features like displaying tooltips
 * and dynamically enabling virtualization based on the dataset size for improved performance.
 *
 * @param label - The label for the autocomplete input field.
 * @param data - The options to be displayed in the dropdown list. Each option should have a label and a value.
 * @param value - The currently selected value(s) in the autocomplete.
 * @param [function] isOptionEqualToValue - A custom equality function to compare options with values.
 * @param  multiple - If true, allows multiple values to be selected. Defaults to true.
 * @param onChange - Callback function that is called when the selected value changes. It receives the new value as an argument.
 * @param [id] - The unique identifier for the input field, used for accessibility purposes. Optional.
 * @param [placeholder] - Placeholder text for the input field when it is empty. Optional.
 * @param [containerClass] - Additional CSS class(es) for styling the container of the input field. Optional.
 * @param [disabled] - If true, disables the input field. Optional.
 * @param [shouldVirtualize] - If true, uses a virtualized list for displaying options. This improves performance with large datasets. Optional.
 * @param [tipText] - Text for a tooltip that can be displayed alongside the input field. Optional.
 * @param [tipDisabled] - If true, disables the display of the tooltip. Optional.
 * @param [sx] - Custom styles that can be applied to the Autocomplete component using Material-UIs' styling solution. Optional.
 */
const EnhancedMultiSelect = ({
  label,
  id,
  data,
  value,
  isOptionEqualToValue,
  multiple = true,
  onChange,
  placeholder,
  containerClass,
  disabled,
  shouldVirtualize = false,
  tipText,
  tipDisabled,
  sx,
}: Props) => {
  /**
   * Handles the change event when the selected value(s) change in the Autocomplete component.
   * @param _
   * @param newValue
   * @param reason
   */
  const handleChange = (
    _: React.SyntheticEvent,
    newValue: OptionValue | null,
    reason: ChangeReason,
  ) => {
    onChange(newValue, reason);
  };

  /**
   * Default equality function to compare options based on their values.
   * @param option
   * @param value
   */
  const defaultEqualityFunction = (option: OptionValue, value: OptionValue) =>
    option.value === value.value;

  return (
    <SelectContext.Provider value={{ disabled, tipText, tipDisabled }}>
      <Autocomplete
        sx={sx}
        multiple={multiple}
        id={id}
        disabled={disabled}
        PopperComponent={StyledPopper as any}
        ListboxComponent={shouldVirtualize ? ListboxComponent : undefined}
        options={data}
        value={value}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) =>
          isOptionEqualToValue
            ? isOptionEqualToValue(option, value)
            : defaultEqualityFunction(option, value)
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              label={option.label}
              size='small'
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            className={containerClass}
            {...params}
            label={label}
            placeholder={placeholder}
            variant={'standard'}
            InputLabelProps={{
              htmlFor: id,
              shrink: true,
            }}
          />
        )}
      />
    </SelectContext.Provider>
  );
};

/**
 * Optimizes the rendering of EnhancedMultiSelect by memoizing it. This prevents unnecessary re-renders
 * unless the `value` prop changes or the length of the `data` prop changes. It's important to note that
 * if closure issues arise — where the component does not re-render in response to updated state or props
 * not included in this comparison — the props comparison function here may need to be updated to
 * include additional props that affect rendering (i.e., such as `onChange`).
 */
export default React.memo(
  EnhancedMultiSelect,
  (p, n) => p.value === n.value && p.data.length === n.data.length,
);

// Listbox padding constant to ensure consistent spacing within the listbox component.
const LISTBOX_PADDING = 8;

/**
 * Renders an individual row within the virtualized list. This function is called by react-window.
 * It clones the original option element to apply additional inline styling for proper alignment.
 *
 * @param {ListChildComponentProps} props - The props passed by react-window for rendering a row.
 * @returns {React.ReactElement} A cloned element of the option with adjusted styling.
 */
const renderRow = (props: ListChildComponentProps): React.ReactElement => {
  const { data, index, style } = props;
  const option = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  return React.cloneElement(option, {
    style: inlineStyle,
  });
};

// Context to pass down additional props to the outer element of the VariableSizeList.
const OuterElementContext = React.createContext({});

/**
 * A forwardRef component that renders a div and applies any passed props.
 * This component is intended to be used as the outer container for the VariableSizeList.
 *
 * @param {React.HTMLAttributes<HTMLElement>} props - Props to be spread onto the div.
 * @param {React.Ref<HTMLDivElement>} ref - Ref forwarded to the div.
 * @returns {React.ReactElement} A div with applied props and ref.
 */
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

/**
 * Custom hook to reset the cache of the VariableSizeList when the data changes.
 * This ensures that the list correctly recalculates item sizes after data updates.
 *
 * @param {any} data - Dependency to trigger cache reset. Typically, the dataset or its length.
 * @returns {React.Ref<VariableSizeList>} A ref attached to the VariableSizeList instance.
 */
const useResetCache = (data: any): React.Ref<VariableSizeList> => {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

/**
 * Custom ListboxComponent for MUI Autocomplete, implemented with react-window for virtualization.
 * It renders a virtualized list of options, improving performance for large datasets.
 *
 * @param {React.HTMLAttributes<HTMLElement>} props - Standard HTML attributes for the component.
 * @param {React.Ref<HTMLDivElement>} ref - Ref forwarding for the component.
 * @returns {React.ReactElement} The virtualized list component integrated into MUI Autocomplete.
 */
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactElement[] = [];
  (children as React.ReactElement[]).forEach(
    (item: React.ReactElement & { children?: React.ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    },
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  // Determine the dynamic height of the list based on the number of items.
  const getHeight = () =>
    (itemCount > 8 ? 8 * itemSize : itemCount * itemSize) + 2 * LISTBOX_PADDING;

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

/**
 * Custom Popper component for MUI Autocomplete, implemented with styled-components for custom styling.
 *
 * Applies custom styles to the inner list (`ul`) within the listbox to remove default padding and margin.
 * This adjustment is crucial for:
 *
 * 1. Preventing an unwanted horizontal scrollbar caused by the default padding or margin settings.
 *    By setting both padding and margin to 0, we ensure the dropdown content fits within the designated
 *    area without overflowing, which could otherwise introduce a scrollbar.
 *
 * 2. Ensuring consistent box sizing across browsers and contexts. The 'box-sizing: border-box' style
 *    is applied to make sure the padding and border of the listbox are included in the total width and
 *    height. This is important for maintaining layout consistency and avoiding unexpected overflow
 *    that could lead to scrollbars or misalignment.
 */
const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});
