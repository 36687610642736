import React from 'react';
import { ErrorMessage } from '../app/utils';
import { Box, SxProps, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ErrorIcon } from './icons';

type Props = {
  errorMessage: ErrorMessage;
  color: string;
  sx?: SxProps;
};

/**
 * Displays an Error indicator with message
 * @param errorMessage
 * @param color
 * @param sx
 * @constructor
 */
const ErrorIndicator = ({ errorMessage, color, sx }: Props) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t(errorMessage.stringKey, errorMessage.props) as any}>
      <Box sx={sx} display={'flex'}>
        <ErrorIcon color={color} />
      </Box>
    </Tooltip>
  );
};

export default ErrorIndicator;
