import { Alert, Snackbar } from '@mui/material';
import { BannerLevel } from '../../dev/types';

type Props = {
  level: BannerLevel;
  message: string;
};

/**
 * Renders a banner with a message.
 * @param level
 * @param message
 * @constructor
 */
const Banner = ({ level, message }: Props) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={true}
    >
      <Alert severity={level}>{message}</Alert>
    </Snackbar>
  );
};

export default Banner;
