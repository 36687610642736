import {
  InventoryCenter,
  ManufacturingLocation,
  MCell,
  PlanningDepartment,
} from '../manufacturingLocation/types';
import { User } from '../profile/types';
import { JobFunction, JobFunctionTag } from '../mCellEmployee/types';

export type EmployeeInterest = {
  id: string;
  interest: string;
};

export type EmployeeInterestInfo = {
  interest: string;
};

export type WorkSchedule = 'PartTime' | 'FullTime' | 'Overtime';

export type InterestProfileInfo = {
  mCellEmployeeId: string;
  preferredWorkSchedule: WorkSchedule;
  interestIds: string[];
  crossTrainingDepartmentIds: string[];
};

type InterestProfileEmployeeInterest = {
  employeeInterest: EmployeeInterest;
};

type InterestProfilePlanningDepartment = {
  planningDepartment: PlanningDepartment;
};

export type InterestProfile = {
  id: string;
  mCellEmployee: MCellEmployee;
  preferredWorkSchedule: WorkSchedule;
  interestProfileEmployeeInterest: InterestProfileEmployeeInterest[];
  interestProfilePlanningDepartment: InterestProfilePlanningDepartment[];
};

export type MCellEmployee = {
  id: string;
  employeeId: string;
  fullBadgeId: string;
  homeMCell?: MCell;
  homeMCellId?: string;
  firstName: string;
  lastName: string;
  user?: User;
  userId?: string;
  inventoryCenter: InventoryCenter;
  inventoryCenterId: string;
  supervisorName: string;
  accountType?: MCellEmployeeAccountType;
  accountTypeId?: string;
  category?: MCellEmployeeCategory;
  categoryId?: string;
  role?: MCellEmployeeRole;
  roleId?: string;
  defaultMCellShift: Shift;
  department?: PlanningDepartment;
  departmentId?: string;
};

export type MCellEmployeeInMCell = MCellEmployee & {
  isLoggedIn: boolean;
  isAssigned: boolean;
  isHomeMCell: boolean;
};

export type MCellEmployeeFilterValue = {
  nameContains?: string;
  employeeIdContains?: string;
  inventoryCenterId?: string;
  homeInventoryCenterId?: string;
  homeMCellId?: string;
  homeMCellCollectionId?: string;
  departmentIds?: string[];
  hasExperienceInMCellId?: string;
  hasExperienceInJobStationIds?: string[];
  hasExperienceInJobFunctionIds?: string[];
  hasExperienceInJobFunctionTagIds?: string[];
  defaultShifts?: Shift[];
  filterIsCurrentlyLoggedIn?: boolean;
  departmentOfInterestIds?: string[];
  preferredWorkSchedules?: WorkSchedule[];
  employeeInterestIds?: string[];
  mCellEmployeeAccountTypeIds?: string[];
  mCellEmployeeRoleIds?: string[];
  mCellEmployeeCategoryIds?: string[];
};

export type Shift =
  | 'None'
  | 'Day'
  | 'Night'
  | 'Graveyard'
  | 'Weekend'
  | 'Unknown';
export type MCellStatusLevel = 'None' | 'Red' | 'Yellow' | 'Green';

export type ManufacturingLocationShift = ManufacturingLocation & {
  shift: Shift;
};

export type MCellShiftInfo = {
  mCellId: string;
  shift: Shift;
};

export type MCellCapacity = {
  id: string;
  mCell: MCell;
  minimum: number;
  maximum: number;
  optimum: number;
};

export type MCellStatus = {
  id: string;
  mCell: MCell;
  mCellId: string;
  operationsStatus: MCellStatusLevel;
  operationsStatusBy?: User;
  demandManagementStatus: MCellStatusLevel;
  demandManagementStatusBy?: User;
  demandManagementStatusByAuto: boolean;
};

export type MCellStatusInfo = {
  id: string;
  status: MCellStatusLevel;
};

export type MCellEmployeeMCellHistory = {
  mCellEmployeeId: string;
  mCell: MCell;
  timeMinutes: number;
  lastLoginDate: string;
};

export type MCellEmployeeJobStationHistory = {
  mCellEmployeeId: string;
  jobStation: JobStation;
  timeMinutes: number;
  lastLoginDate: string;
};

export type MCellEmployeeJobFunctionHistory = {
  mCellEmployeeId: string;
  jobFunction: JobFunction;
  timeMinutes: number;
  lastLoginDate: string;
};

export type MCellEmployeeJobFunctionTagHistory = {
  mCellEmployeeId: string;
  jobFunctionTag: JobFunctionTag;
  timeMinutes: number;
  lastLoginDate: string;
};

export type MCellCapacityInfo = {
  minimum: number;
  maximum: number;
  optimum: number;
};

export type MCellCurrentCapacity = {
  currentEmployeeCount: number;
  percentFromOptimum: number;
  percentFromMinimum: number;
  percentFromMaximum: number;
  mCellCapacity: MCellCapacity;
  mCellStatus: MCellStatus;
};

export type MCellCurrentCapacitiesSummary = {
  currentEmployeeCountSum: number;
  minimumSum: number;
  optimumSum: number;
  maximumSum: number;
  fromMinimumSum: number;
  fromOptimumSum: number;
  fromMaximumSum: number;
  percentFromOptimum: number;
  percentFromMinimum: number;
  percentFromMaximum: number;
};

export type MCellEmployeeAccountType = {
  id: string;
  accountTypeId: string;
  description: string;
};

export type MCellEmployeeRole = {
  id: string;
  title: string;
};

export type MCellEmployeeCategory = {
  id: string;
  categoryId: string;
  description: string;
};

export type DemandManagementStatusInventoryCenterConfig = {
  id: string;
  inventoryCenterId: string;
  redStatusThresholdPercent: number;
  yellowStatusThresholdPercent: number;
};

export type DemandManagementStatusInventoryCenterConfigInfo = {
  inventoryCenterId: string;
  redStatusThresholdPercent: number;
  yellowStatusThresholdPercent: number;
};

export type DemandManagementStatusAutomationMode =
  | 'Disabled'
  | 'EnabledAuto'
  | 'EnabledGreen'
  | 'EnabledYellow'
  | 'EnabledRed';

export type DemandManagementStatusMCellConfig = {
  id: string;
  mCell: MCell;
  mCellId: string;
  automationMode: DemandManagementStatusAutomationMode;
  isCellBoardEnabled: boolean;
  locations: DeliveryMetricLocation[];
};

export type DeliveryMetricLocation = {
  producingLocation?: string;
  productionLine?: string;
  workCenter?: string;
};

export type DemandManagementStatusMCellConfigInfo = {
  mCell: MCell;
  mCellId: string;
  automationMode: DemandManagementStatusAutomationMode;
};

export type DangerousEquipment = {
  id: string;
  description: string;
  inventoryCenter?: InventoryCenter;
  inventoryCenterId?: string;
};

export type PersonalProtectiveEquipment = {
  id: string;
  description: string;
  inventoryCenter?: InventoryCenter;
  inventoryCenterId?: string;
};

export type DangerousEquipmentInfo = {
  id?: string;
  description: string;
  inventoryCenterId: string;
};
export type PersonalProtectiveEquipmentInfo = {
  id?: string;
  description: string;
  inventoryCenterId: string;
};

export const ageRestrictions = ['None', 'Over18'] as const;

export type AgeRestriction = (typeof ageRestrictions)[number];

export const weightRestrictions = [
  'None',
  'Light',
  'Medium',
  'Heavy',
  'VeryHeavy',
] as const;

export type WeightRestriction = (typeof weightRestrictions)[number];

export type JobStationPersonalProtectiveEquipment = {
  jobStationId?: string;
  jobStation?: JobStation;
  personalProtectiveEquipmentId: string;
  personalProtectiveEquipment: PersonalProtectiveEquipment;
};

export type JobStationPersonalProtectiveEquipmentInfo = {
  jobStationId: string;
  personalProtectiveEquipmentId: string;
};

export type JobStationDangerousEquipment = {
  jobStationId?: string;
  jobStation?: JobStation;
  dangerousEquipmentId: string;
  dangerousEquipment: DangerousEquipment;
};

export type JobStationDangerousEquipmentInfo = {
  jobStationId: string;
  dangerousEquipmentId: string;
};

export type JobStation = {
  id: string;
  title: string;
  description?: string;
  mCellId: string;
  mCell?: MCell;
  jobFunction?: JobFunction;
  jobFunctionId: string;
  stationId: string;
  stationSequence: string;
  positionCount: number;
  crewingRateMaximum: number;
  ageRestriction: AgeRestriction;
  weightRestriction: WeightRestriction;
  jobStationPersonalProtectiveEquipments: JobStationPersonalProtectiveEquipment[];
  jobStationDangerousEquipments: JobStationDangerousEquipment[];
  mCellEmployeeRoles: MCellEmployeeRole[];
  mCellEmployeeRoleIds: string[];
};

export type JobStationDto = {
  id: string;
  title: string;
  description?: string;
  mCellId: string;
  jobFunctionId: string;
  stationId: string;
  stationSequence: string;
  positionCount: number;
  crewingRateMaximum: number;
  ageRestriction: AgeRestriction;
  weightRestriction: WeightRestriction;
  mCellEmployeeRoles: MCellEmployeeRole[];
};

export type JobStationInfo = {
  id: string;
  title: string;
  description: string;
  mCellId: string;
  jobFunctionId: string;
  stationId: string;
  stationSequence: string;
  positionCount: number;
  crewingRateMaximum: number;
  ageRestriction: AgeRestriction;
  weightRestriction: WeightRestriction;
  mCellEmployeeRoleIds: string[];
};

export type JobStationAssignmentDto = {
  mCellId: string;
  mCellEmployeeId: string;
  jobStationId: string;
  shift: Shift;
  assignTime: string;
  unassignTime?: string;
  ratePercent: number;
};

export type JobStationAssignmentToSet = {
  mCellEmployeeId: string;
  jobStationId: string;
};

export type JobStationAssignmentsSetter = {
  mCellId: string;
  shift: Shift;
  assignmentsToSet: JobStationAssignmentToSet[];
};
