import React, { ReactNode } from 'react';
import { Box, IconButton, SxProps, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EditIcon } from './icons';

export type Props = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  tooltip?: string;
  disabled?: boolean;
  icon?: ReactNode;
  className?: string;
  sx?: SxProps;
  id?: string;
};

/**
 * Renders an Edit Icon button with a tooltip
 * @param tooltip
 * @param onClick
 * @param disabled
 * @param icon
 * @param className
 * @param sx
 * @param id
 * @constructor
 */
const EditIconButton = ({
  tooltip,
  onClick,
  disabled,
  icon,
  className,
  sx,
  id,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Tooltip data-testid='Tooltip' title={tooltip || (t('edit') as any)}>
      <Box component='span'>
        <IconButton
          id={id}
          className={className}
          edge={'end'}
          onClick={onClick}
          disabled={disabled}
          sx={sx}
          size='large'
        >
          {icon ? icon : <EditIcon data-testid='EditIcon' />}
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default EditIconButton;
