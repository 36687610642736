import React, { useState, useEffect } from 'react';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import moment, { Moment } from 'moment';

type PickerProps = TimePickerProps<any> & {
  onChange?: (time: string | null) => void;
  value?: string;
};

/**
 * TimePickerWrapper is a wrapper component for the TimePicker component from the @mui/x-date-pickers package.
 *
 * @Note This wrapper component is used to abstract the tighter coupling of the Moment.js date library from parent components.
 *
 * @param value
 * @param {Object} props - The properties that define the behavior of the TimePickerWrapper component.
 * @param {(time: string | null) => void} props.onChange - A callback function that is called when the time value changes.
 * The new time value is passed as a parameter in the format "HH:mm:ss".
 *
 * @returns {JSX.Element} A TimePicker component with the provided props.
 */
export const TimePickerWrapper = ({
  onChange,
  value,
  ...props
}: PickerProps): JSX.Element => {
  const [selectedTime, setSelectedTime] = useState<Moment | null>(null);

  /**
   * Updates the selected time when the value prop changes.
   */
  useEffect(() => {
    if (value) {
      setSelectedTime(moment(value, 'HH:mm:ss'));
    }
  }, [value]);

  /**
   * Handles changes to the time value.
   * Updates the selected time and calls the onChange prop with the new time.
   *
   * @param newValue - The new time value.
   */
  const handleTimeChange = (newValue: Moment | null) => {
    setSelectedTime(newValue);
    if (onChange) {
      onChange(newValue ? newValue.format('HH:mm:ss') : null);
    }
  };

  return (
    <TimePicker
      className={props.className}
      value={selectedTime}
      onChange={handleTimeChange}
      minTime={props.minTime ? moment(props.minTime, 'HH:mm') : undefined}
      maxTime={props.maxTime ? moment(props.maxTime, 'HH:mm') : undefined}
      format={props.format ? props.format : 'hh:mm a'}
      {...props}
    />
  );
};
