import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Chip,
  InputBaseComponentProps,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import EnhancedSelect, { OptionValue } from './EnhancedSelect';
import { isEmptyOrSpaces } from '../../utils/string-utils';
import {
  CheckBoldIcon,
  ArrowDownwardIcon,
  ArrowUpwardIcon,
  ErrorIcon,
  EditIcon,
} from './icons';
import EditIconButton from './EditIconButton';
import TextInputDialog from './TextInputDialog';
import ImageCard from './ImageCard';
import { ErrorContext } from './ErrorContext';
import ErrorIndicator from './ErrorIndicator';
import { ErrorMessage } from '../app/utils';
import { TimePickerWrapper } from './TimePickerWrapper';
import EnhancedMultiSelect from './EnhancedMultiSelect';
import { OccurrenceValue } from '../lsw/types';
import { getOccurrenceSummaryString } from '../../utils/date';
import OccurrenceDialog from './OccurrenceDialog';
import { customToFixed } from '../../utils/number';

type ValueEditorProps = DataTypeProvider.ValueEditorProps;
type ValueFormatterProps = DataTypeProvider.ValueFormatterProps;

/**
 * Grid table DataTypeProvider that formats date and time in shorthand localized format.
 * E.g. Thurs, Sep 4, 1986 8:30 PM
 * @param props
 * @constructor
 */
export const DateTimeTypeProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={({ value }: ValueFormatterProps) => (
      <>{!isEmptyOrSpaces(value) ? moment(value).format('llll') : ''}</>
    )}
    {...props}
  />
);

type DateTypeProviderProps = {
  format?: string;
} & DataTypeProviderProps;

/**
 * Grid table DataTypeProvider that formats date in shorthand localized format.
 * E.g. Thurs, Sep 4, 1986
 * @param props
 * @constructor
 */
export const DateTypeProvider = ({
  format,
  ...restProps
}: DateTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={({ value }: ValueFormatterProps) => (
      <>
        {!isEmptyOrSpaces(value)
          ? moment(value).format(format || 'ddd, MMM Do, YYYY ')
          : ''}
      </>
    )}
    {...restProps}
  />
);

type DateFromNowProps = {
  value: any;
  withSuffix?: boolean;
  toDate?: string;
};
/**
 * Renders the humanized time and refreshes it every 15 secs
 * @param value
 * @param withSuffix
 * @param toDate
 * @constructor
 */
export const DateFromX = ({ value, withSuffix, toDate }: DateFromNowProps) => {
  const endDate = toDate ? toDate : moment();
  const [displayValue, setDisplayValue] = useState(
    moment(value).from(endDate, !withSuffix),
  );
  useEffect(() => {
    let intervalTimer: any = null;
    intervalTimer = setInterval(
      () => setDisplayValue(moment(value).from(endDate, !withSuffix)),
      15000,
    );
    return () => clearInterval(intervalTimer);
  }, [value, withSuffix, endDate]);
  return <>{displayValue}</>;
};

/**
 * Grid table DataTypeProvider that formats numbers as percentage change with
 * green/red color and up/down arrow.
 * @param props
 * @constructor
 */
export const PercentChangeTypeProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={PercentChangeFormatter} {...props} />
);

/**
 * Formats numbers as percentage change with
 * green/red color and up/down arrow.
 * @param value
 * @constructor
 */
export const PercentChangeFormatter = ({ value }: { value: number }) => {
  const isNegative = value < 0;
  return (
    <div style={{ color: isNegative ? red[500] : green[500], display: 'flex' }}>
      {isNegative ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
      <Typography>{Math.abs(value).toFixed(1)}%</Typography>
    </div>
  );
};

/**
 * Formats numbers with green/red color and up/down arrow.
 * @param value
 * @constructor
 */
export const NumberChangeFormatter = ({ value }: { value: number }) => {
  const isNegative = value < 0;
  return (
    <div style={{ color: isNegative ? red[500] : green[500], display: 'flex' }}>
      {isNegative ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
      <Typography>{Math.abs(value)}</Typography>
    </div>
  );
};

type HoursFormatterProps = {
  value: number;
};

/**
 * Formats hours to hours and minute format.
 * Eg. 2.25 --> 2h 15m
 * @param value
 * @constructor
 */
export const HoursFormatter = ({ value }: HoursFormatterProps) => {
  const hours = Math.trunc(value);
  const minutes = Math.round((value - hours) * 60);
  return value !== undefined ? (
    <>
      {hours}h {minutes}m
    </>
  ) : (
    <>--</>
  );
};

/**
 * Grid table DataTypeProvider that formats hours in hours and minute format.
 * Eg. 2.25 --> 2h 15m
 * @param props
 * @constructor
 */
export const HoursTypeProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={HoursFormatter} {...props} />
);

type BooleanFormatterProps = {
  value: boolean;
};

/**
 * Formats a boolean field into a yes/no chip
 * @param value
 * @constructor
 */
export const BooleanFormatter = ({ value }: BooleanFormatterProps) => {
  const { t } = useTranslation();
  return (
    <Chip
      sx={{ height: 20 }}
      label={value ? t('yes') : t('no')}
      color={value ? 'primary' : 'default'}
    />
  );
};

/**
 * Table edit component for boolean fields. Renders a yes/no dropdown
 * @param column
 * @param value
 * @param onValueChange
 * @constructor
 */
export const BooleanEditor = ({
  column,
  value,
  onValueChange,
}: ValueEditorProps) => {
  const { t } = useTranslation();
  return (
    <EnhancedSelect
      id={column.name}
      data={[
        { label: t('yes'), value: 'yes' },
        { label: t('no'), value: 'no' },
      ]}
      value={value === true ? 'yes' : value === false ? 'no' : ''}
      onChange={(v) =>
        v === 'yes' ? onValueChange(true) : onValueChange(false)
      }
    />
  );
};

/**
 * Grid table DataTypeProvider that formats booleans into yes/no chips
 * @param props
 * @constructor
 */
export const BooleanTypeProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={BooleanFormatter}
    editorComponent={BooleanEditor}
    {...props}
  />
);

type EnhancedSelectEditorProps = {
  datasource: OptionValue[];
  options?: OptionValue[];
  includeEmptyOption?: boolean;
} & ValueEditorProps;

/**
 * Table edit component for EnhancedSelect provider.
 * Renders a drop-down component with provided options
 * @param column
 * @param value
 * @param onValueChange
 * @param datasource
 * @param options
 * @param disabled
 * @param includeEmptyOption
 * @constructor
 */
export const EnhancedSelectEditor = ({
  column,
  value,
  onValueChange,
  datasource,
  options,
  disabled,
  includeEmptyOption = true,
}: EnhancedSelectEditorProps) => {
  const currentOption =
    (options && options.find((x) => x.value === value)) ||
    datasource.find((x) => x.value === value);
  const validOptions = options ?? datasource;
  const allOptions =
    currentOption && !validOptions.some((x) => x.value === currentOption.value)
      ? [currentOption, ...validOptions]
      : validOptions;

  if (includeEmptyOption && !currentOption) {
    allOptions.unshift({ label: '', value: '' });
  }
  const [cachedValue, setCachedValue] = useState(value ?? '');

  const onCachedValueChangeRef = useRef(onValueChange);

  const onValueChangeCallback = useCallback(
    (cachedValue: any) => onCachedValueChangeRef.current(cachedValue),
    [onCachedValueChangeRef],
  );

  useEffect(() => {
    onValueChangeCallback(cachedValue);
  }, [cachedValue, onValueChangeCallback]);

  return (
    <EnhancedSelect
      data={allOptions}
      value={cachedValue}
      onChange={setCachedValue}
      id={column.name}
      disabled={disabled && value !== undefined} // for some reason, disabled is coming back as true when in add mode.
    />
  );
};

type EnhancedSelectFormatterProps = {
  datasource: OptionValue[];
  value: any;
};

/**
 * Table format component for EnhancedSelect provider. Renders the
 * label of the supplied option value
 * @param value
 * @param datasource
 * @constructor
 */
export const EnhancedSelectFormatter = ({
  value,
  datasource,
}: EnhancedSelectFormatterProps) => {
  const label = datasource.find((x) => x.value === value)?.label ?? '';
  return <div>{label}</div>;
};

type EnhancedSelectProviderProps = {
  datasource: OptionValue[];
  options?: OptionValue[];
  includeEmptyOption?: boolean;
} & DataTypeProviderProps;

/**
 * Grid table DataTypeProvider for EnhancedSelect component. Renders a dropdown
 * with the provided options in edit mode. Renders the label of the supplied
 * value from the datasource in view mode.
 * @param datasource
 * @param options
 * @param includeEmptyOption
 * @param restProps
 * @constructor
 */
export const EnhancedSelectProvider = ({
  datasource,
  options,
  includeEmptyOption,
  ...restProps
}: EnhancedSelectProviderProps) => (
  <DataTypeProvider
    formatterComponent={(formatterProps) => (
      <EnhancedSelectFormatter datasource={datasource} {...formatterProps} />
    )}
    editorComponent={(editorProps) => (
      <EnhancedSelectEditor
        datasource={datasource}
        options={options}
        includeEmptyOption={includeEmptyOption}
        {...editorProps}
      />
    )}
    {...restProps}
  />
);

/**
 * Grid table DataTypeProvider that displays a custom checkmark column
 * @param props
 * @constructor
 */
export const CheckmarkTypeProvider = (props: DataTypeProviderProps) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        return value ? <CheckBoldIcon style={{ color: green[500] }} /> : <></>;
      }}
      {...props}
    />
  );
};

type InputTextDialogFormatterProps = {
  row: any;
  textKey: string;
  idKey: string;
  label: string;
  onConfirm: (id: string, text: string) => void;
  setIsEditing?: (isEditing: boolean) => void;
};

/**
 * Formatter for displaying edit icon button and input text dialog
 * @param row
 * @param textKey
 * @param idKey
 * @param label
 * @param onConfirm
 * @param setIsEditing
 * @constructor
 */
export const InputTextDialogFormatter = ({
  row,
  textKey,
  idKey,
  label,
  onConfirm,
  setIsEditing,
}: InputTextDialogFormatterProps) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  /**
   * Handles the confirmation of the edit dialog.
   * Closes the edit dialog, calls the onConfirm callback with the updated text, and optionally updates the editing state.
   *
   * @param {string} inputText - The text input from the dialog.
   */
  function handleEditConfirm(inputText: string) {
    setIsEditDialogOpen(false);
    onConfirm(row[idKey], inputText);
    setIsEditing && setIsEditing(false);
  }

  /**
   * Opens the edit dialog and optionally sets the editing state to true.
   */
  function handleEdit() {
    setIsEditDialogOpen(true);
    setIsEditing && setIsEditing(true);
  }

  /**
   * Cancels the edit operation.
   * Closes the edit dialog and optionally sets the editing state to false.
   */
  function handleCancel() {
    setIsEditDialogOpen(false);
    setIsEditing && setIsEditing(false);
  }

  return (
    <>
      <EditIconButton
        onClick={handleEdit}
        tooltip={label}
        sx={{ marginRight: 5 }}
      />
      {row[textKey]}
      {isEditDialogOpen && (
        <TextInputDialog
          title={label}
          textLabel={label}
          open={isEditDialogOpen}
          onConfirm={handleEditConfirm}
          onCancel={handleCancel}
          defaultText={row[textKey] ?? ''}
          allowEmpty={false}
        />
      )}
    </>
  );
};

type InputTextDialogProviderProps = {
  textKey: string;
  idKey: string;
  label: string;
  onConfirm: (id: string, text: string) => void;
  setIsEditing?: (isEditing: boolean) => void;
} & DataTypeProviderProps;

/**
 * Grid table DataTypeProvider for displaying edit icon button and input text dialog
 * @param props
 * @constructor
 */
export const InputTextDialogProvider = (
  props: InputTextDialogProviderProps,
) => {
  return (
    <DataTypeProvider
      formatterComponent={(formatterProps) => (
        <InputTextDialogFormatter
          row={formatterProps.row}
          textKey={props.textKey}
          idKey={props.idKey}
          label={props.label}
          onConfirm={props.onConfirm}
          setIsEditing={props.setIsEditing}
        />
      )}
      {...props}
    />
  );
};

type InputNumberEditorProps = {
  inputProps?: {
    min: number;
    step: number;
  };
} & ValueEditorProps;

/**
 * Table edit component for number field
 * @param column
 * @param value
 * @param onValueChange
 * @param inputProps
 * @constructor
 */
export const InputNumberEditor = ({
  column,
  value,
  onValueChange,
  inputProps,
}: InputNumberEditorProps) => {
  function handleValueChange(event: any) {
    const { value } = event.target;
    onValueChange(value);
  }
  return (
    <TextField
      type={'number'}
      id={column.name || ''}
      onChange={handleValueChange}
      value={value || 0}
      inputProps={inputProps}
      variant='standard'
      fullWidth
    />
  );
};

type InputNumberProviderProps = {
  inputProps?: {
    min: number;
    step: number;
  };
} & DataTypeProviderProps;

/**
 * Grid table DataTypeProvider for displaying a number text field
 * @param inputProps
 * @param restProps
 * @constructor
 */
export const InputNumberProvider = ({
  inputProps,
  ...restProps
}: InputNumberProviderProps) => (
  <DataTypeProvider
    editorComponent={(editorProps) => (
      <InputNumberEditor inputProps={inputProps} {...editorProps} />
    )}
    {...restProps}
  />
);

type InputTextEditorProps = {
  inputProps?: InputBaseComponentProps;
} & ValueEditorProps;

/**
 * Table edit component for text field with input props
 * @param column
 * @param value
 * @param onValueChange
 * @param inputProps
 * @constructor
 */
export const InputTextEditor = ({
  column,
  value,
  onValueChange,
  inputProps,
}: InputTextEditorProps) => {
  function handleValueChange(event: any) {
    const { value } = event.target;
    onValueChange(value);
  }
  return (
    <TextField
      id={column.name || ''}
      onChange={handleValueChange}
      value={value || ''}
      inputProps={inputProps}
      variant='standard'
      fullWidth
    />
  );
};

type InputTextProviderProps = {
  inputProps?: InputBaseComponentProps;
} & DataTypeProviderProps;

/**
 * Grid table DataTypeProvider for displaying a text field with input props
 * @param inputProps
 * @param restProps
 * @constructor
 */
export const InputTextProvider = ({
  inputProps,
  ...restProps
}: InputTextProviderProps) => (
  <DataTypeProvider
    editorComponent={(editorProps) => (
      <InputTextEditor inputProps={inputProps} {...editorProps} />
    )}
    {...restProps}
  />
);

type ImageTypeProviderProps = {
  width?: number | string;
  height?: number | string;
} & DataTypeProviderProps;

/**
 * Grid table DataTypeProvider for displaying an image
 * @param width
 * @param height
 * @param restProps
 * @constructor
 */
export const ImageTypeProvider = ({
  width,
  height,
  ...restProps
}: ImageTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={({ value }: ValueFormatterProps) => (
      <>
        <ImageCard src={value} width={width} height={height} />
      </>
    )}
    {...restProps}
  />
);

type AutocompleteEditorProps = EnhancedSelectEditorProps & {
  label?: string;
};

type AutocompleteProviderProps = EnhancedSelectProviderProps & {
  label?: string;
};

/**
 * Table edit component for Autocomplete provider. Renders the label of the supplied value
 * or Tooltip with an inactive value message if the value is not in the datasource
 * @param value
 * @param datasource
 * @constructor
 */
export const AutocompleteFormatter = ({
  value,
  datasource,
}: EnhancedSelectFormatterProps) => {
  const { t } = useTranslation();
  const item = datasource.find((x) => x.value === value);
  if (!item && datasource.length) {
    return (
      <Tooltip title={t('inactive')}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <span>{value}</span>
          <ErrorIcon color={'error'} style={{ marginLeft: '8px' }} />
        </div>
      </Tooltip>
    );
  }

  return <div>{item?.label}</div>;
};

/**
 * Table edit component for Autocomplete provider. Renders a text field
 * and drop-down (with search autocomplete features) with provided options
 * @param column
 * @param label
 * @param value
 * @param onValueChange
 * @param datasource
 * @param disabled
 * @constructor
 */
export const AutocompleteEditor = ({
  column,
  label,
  value,
  onValueChange,
  datasource,
  disabled,
}: AutocompleteEditorProps) => {
  const { t } = useTranslation();
  const emptyOption = { label: '', value: '' };
  const currentOption =
    datasource.find((x) => x.value === value) ?? emptyOption;
  const allOptions = [emptyOption, ...datasource];
  const [cachedValue, setCachedValue] = useState(currentOption);

  const onCachedValueChangeRef = useRef(onValueChange);

  const onValueChangeCallback = useCallback(
    (cachedValue: OptionValue) =>
      onCachedValueChangeRef.current(cachedValue.value),
    [],
  );

  useEffect(() => {
    onValueChangeCallback(cachedValue);
  }, [cachedValue, onValueChangeCallback]);

  return (
    <Autocomplete
      options={allOptions}
      value={cachedValue}
      onChange={(event, newValue) =>
        newValue ? setCachedValue(newValue) : null
      }
      id={column.name}
      disabled={disabled && value !== undefined}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField
          variant={'standard'}
          {...params}
          label={label ? t(label) : ''}
        />
      )}
    />
  );
};

/**
 * Grid table DataTypeProvider for EnhancedSelect component in view mode and MUI Autocomplete component in edit mode.
 * Renders a text field and drop-down (with search autocomplete features) with provided options in edit mode.
 * Renders the label of the supplied value from the datasource in view mode.
 * @param datasource
 * @param label
 * @param restProps
 * @constructor
 */
export const AutocompleteProvider = ({
  datasource,
  label,
  ...restProps
}: AutocompleteProviderProps) => (
  <DataTypeProvider
    formatterComponent={(formatterProps) => (
      <AutocompleteFormatter datasource={datasource} {...formatterProps} />
    )}
    editorComponent={(editorProps) => (
      <AutocompleteEditor
        datasource={datasource}
        label={label ?? ''}
        {...editorProps}
      />
    )}
    {...restProps}
  />
);

type UniqueInputTextEditorProps = {
  data: any[];
} & ValueEditorProps;

/**
 * Table edit component for text field that validates uniqueness
 * @param column
 * @param value
 * @param data
 * @param onValueChange
 * @constructor
 */
export const UniqueInputTextEditor = ({
  column,
  value,
  data,
  onValueChange,
}: UniqueInputTextEditorProps) => {
  const { t } = useTranslation();
  const { contextValidationError, setContextValidationError } =
    useContext(ErrorContext);

  /**
   * Validates that the input value is unique
   * @param event
   */
  function handleValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const columnName = column.name;
    const isUnique = data.every((row) => row[columnName] !== value);
    if (isUnique) {
      setContextValidationError(!isUnique);
    } else {
      setContextValidationError(true);
    }
    onValueChange(value);
  }

  return (
    <Tooltip
      title={t('duplicateValuesNotAllowed')}
      open={contextValidationError}
      arrow={true}
    >
      <TextField
        id={column.name || ''}
        onChange={handleValueChange}
        value={value || ''}
        variant='standard'
        error={contextValidationError}
        fullWidth
        autoComplete={'off'}
      />
    </Tooltip>
  );
};

type UniqueInputTextProviderProps = {
  data: any[];
} & DataTypeProviderProps;

/**
 * Grid table DataTypeProvider for text field that validates uniqueness
 * @param data
 * @param restProps
 * @constructor
 */
export const UniqueInputTextProvider = ({
  data,
  ...restProps
}: UniqueInputTextProviderProps) => (
  <DataTypeProvider
    editorComponent={(editorProps) => (
      <UniqueInputTextEditor data={data} {...editorProps} />
    )}
    {...restProps}
  />
);

type PlanningCodesEditorProps = {
  value: string;
};

const formatPlanningCode = ({ value }: PlanningCodesEditorProps) => {
  return value.split(';').map((item, idx) => {
    const [icAndPlanningCode, unitCount, laborHours] = item
      .replace('(', '')
      .replace(')', '')
      .split(',');

    return (
      <div key={idx}>
        {icAndPlanningCode} | {unitCount} | {laborHours};
      </div>
    );
  });
};

/**
 * Grid table formatter for planning code | unit count | labor hours
 * @param value
 * @constructor
 */
export const PlanningCodesFormatter = ({ value }: PlanningCodesEditorProps) => {
  if (!value) return null;
  const formattedContentMap = formatPlanningCode({ value });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {formattedContentMap.map((content, idx, arr) => {
        return (
          <div
            key={idx}
            style={{
              marginRight: idx !== arr.length - 1 ? '16px' : '0',
            }}
          >
            <Tooltip title={formattedContentMap} key={idx}>
              <div>{content}</div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

type PlanningCodesProviderProps = DataTypeProviderProps;

/**
 * Grid table Provider for planning code | unit count | labor hours
 * @param props
 * @constructor
 */
export const PlanningCodesProvider = (props: PlanningCodesProviderProps) => {
  return (
    <DataTypeProvider formatterComponent={PlanningCodesFormatter} {...props} />
  );
};

type DataOrChipFormatterProps = {
  value: string | null | undefined;
  chipLabel: string;
};

/**
 * Formats a text field into a chip with a specific label if the value is null, undefined, or empty
 * @param value
 * @param chipLabel
 * @constructor
 */
export const DataOrChipFormatter = ({
  value,
  chipLabel,
}: DataOrChipFormatterProps) => {
  const { t } = useTranslation();
  return (
    <>
      {!value ? <Chip label={t(chipLabel)} color={'primary'} /> : <>{value}</>}
    </>
  );
};

type DataOrChipTypeProviderProps = {
  chipLabel: string;
} & DataTypeProviderProps;

/**
 * Grid table DataTypeProvider that formats a text field into a chip with a specific label if the value is null, undefined, or empty
 * @param chipLabel
 * @param props
 * @constructor
 */
export const DataOrChipTypeProvider = ({
  chipLabel,
  ...props
}: DataOrChipTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={(props) => (
      <DataOrChipFormatter {...props} chipLabel={chipLabel} />
    )}
    {...props}
  />
);

type IconWithDataValidationFormatterProps<T> = {
  value: string | null | undefined;
  icon: React.ReactNode;
  validationData: T[];
  validationFunc: (data: T) => ErrorMessage | null;
  propertyName: keyof T;
};

/**
 * Formats a text field by attaching an icon next to it
 * also validates the given data with the provided validation function and displays an error icon if invalid
 * @param value
 * @param icon
 * @param validationData
 * @param validationFunc
 * @param propertyName
 * @constructor
 */
export const IconWithDataValidationFormatter = ({
  value,
  icon,
  validationData,
  validationFunc,
  propertyName,
}: IconWithDataValidationFormatterProps<any>) => {
  const valueToValidate = validationData.find((e) => e[propertyName] === value);
  const errorMessage = validationFunc(valueToValidate);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {errorMessage && (
        <ErrorIndicator
          errorMessage={errorMessage}
          color={'error'}
          sx={{ mr: 0.5 }}
        />
      )}
      {value} {icon}
    </div>
  );
};

type IconWithDataValidationProviderProps<T> = {
  icon: React.ReactNode;
  validationData: T[];
  validationFunc: (data: T) => ErrorMessage | null;
  propertyName: keyof T;
} & DataTypeProviderProps;

/**
 * Grid table DataTypeProvider that formats a text field by attaching an icon next to it.
 * Also takes a validationData prop that is used to validate the field and determine if an error icon should be displayed
 * @param icon
 * @param props
 * @param validationData
 * @param validationFunc
 * @param propertyName
 * @constructor
 */
export const IconWithDataValidationTypeProvider = ({
  icon,
  validationData,
  validationFunc,
  propertyName,
  ...props
}: IconWithDataValidationProviderProps<any>) => (
  <DataTypeProvider
    formatterComponent={(props) => (
      <IconWithDataValidationFormatter
        {...props}
        icon={icon}
        validationData={validationData}
        validationFunc={validationFunc}
        propertyName={propertyName}
      />
    )}
    {...props}
  />
);

/**
 * Grid table DataTypeProvider that formats time in localized format (E.g. 8:30 PM).
 * @param props
 * @constructor
 */
export const TimeOnlyTypeProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={({ value }: ValueFormatterProps) => (
      <>
        {!isEmptyOrSpaces(value) ? moment(value, 'HH:mm:ss').format('LT') : ''}
      </>
    )}
    editorComponent={TimeEditor}
    {...props}
  />
);

/**
 * Table edit component for time fields. Renders a time picker.
 * @param value
 * @param onValueChange
 * @constructor
 */
const TimeEditor = ({ value, onValueChange }: ValueEditorProps) => (
  <TimePickerWrapper
    onChange={(newValue) => onValueChange(newValue)}
    value={value}
  />
);

type EnhancedMultiSelectProviderProps = DataTypeProviderProps & {
  options: OptionValue[];
};

type EnhancedMultiSelectEditorProps = DataTypeProvider.ValueEditorProps & {
  options: OptionValue[];
};

type EnhancedMultiSelectFormatterProps =
  DataTypeProvider.ValueFormatterProps & {
    options: OptionValue[];
  };

/**
 * Table edit component for EnhancedMultiSelect provider. Renders a multi-select component with provided options
 * @param value
 * @param onValueChange
 * @param options
 * @constructor
 */
const EnhancedMultiSelectEditor = ({
  value,
  onValueChange,
  options,
}: EnhancedMultiSelectEditorProps) => {
  const optionSelections: OptionValue[] = options
    .filter((option) => value?.includes(option.value))
    .map((option) => ({ label: option.label, value: option.value }));

  return (
    <EnhancedMultiSelect
      data={options}
      id={'enhancedMultiSelect'}
      label={''}
      value={optionSelections}
      onChange={(newValue: any) => {
        onValueChange(newValue?.map((n: any) => n.value));
      }}
      tipDisabled={true}
    />
  );
};

/**
 * Table format component for EnhancedMultiSelect provider. Renders the label of the supplied option values
 * @param value
 * @param options
 * @constructor
 */
const EnhancedMultiSelectFormatter = ({
  value,
  options,
}: EnhancedMultiSelectFormatterProps) => {
  const display = options
    .filter((option) => value?.includes(option.value))
    .map((option) => option.label)
    .join(', ');

  return <>{display}</>;
};

/**
 * Grid table DataTypeProvider for EnhancedMultiSelect component. Renders a multi-select component with provided options in edit mode.
 * @param options
 * @param props
 * @constructor
 */
export const EnhancedMultiSelectProvider = ({
  options,
  ...props
}: EnhancedMultiSelectProviderProps) => {
  return (
    <DataTypeProvider
      formatterComponent={(formatterProps) => (
        <EnhancedMultiSelectFormatter {...formatterProps} options={options} />
      )}
      editorComponent={(editorProps) => (
        <EnhancedMultiSelectEditor {...editorProps} options={options} />
      )}
      {...props}
    />
  );
};

/**
 * Formats the occurrence object into a readable summary string
 * @param value
 * @constructor
 */
const OccurrenceFormatter = ({ value }: { value: OccurrenceValue }) => {
  const { t } = useTranslation();

  if (!value) {
    return <span>{''}</span>;
  }

  const summary = getOccurrenceSummaryString(
    t,
    value.occurrenceType,
    value.occurrenceDaysOfWeek,
    value.occurrenceDaysOfMonth,
    value.occurrenceTime,
  );

  return <span>{summary}</span>;
};

/**
 * Table Edit component for an occurrence object. Uses a dialog to edit the occurrence object.
 * @param value
 * @param onValueChange
 * @constructor
 */
const OccurrenceEditor = ({
  value,
  onValueChange,
}: {
  value: OccurrenceValue;
  onValueChange: (value: OccurrenceValue) => void;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [occurrenceData, setOccurrenceData] = useState(value);

  const handleOpen = () => setOpen(true);
  const handleClose = (updatedValue?: OccurrenceValue) => {
    setOpen(false);
    if (updatedValue) {
      setOccurrenceData(updatedValue);
      onValueChange(updatedValue);
    } else {
      onValueChange(value); // Restore the original value if dialog is canceled
    }
  };

  const summary = getOccurrenceSummaryString(
    t,
    occurrenceData.occurrenceType,
    occurrenceData.occurrenceDaysOfWeek,
    occurrenceData.occurrenceDaysOfMonth,
    occurrenceData.occurrenceTime,
  );

  return (
    <>
      <Chip
        size={'small'}
        color={'primary'}
        deleteIcon={<EditIcon />}
        label={summary}
        onClick={handleOpen}
        onDelete={handleOpen}
      />
      <OccurrenceDialog
        open={open}
        value={occurrenceData}
        onClose={handleClose}
      />
    </>
  );
};

/**
 * Provider for the occurrence object. Provides the formatter and editor components.
 * @param props
 * @constructor
 */
export const OccurrenceProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={OccurrenceFormatter}
    editorComponent={OccurrenceEditor}
    {...props}
  />
);

/**
 * Grid table DataTypeProvider that formats numbers as percentage with a `%` sign.
 * @param props
 * @constructor
 */
export const PercentageTypeProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={PercentageFormatter} {...props} />
);

/**
 * Formats numbers as percentage with a `%` sign.
 * @param value
 * @constructor
 */
export const PercentageFormatter = ({ value }: ValueFormatterProps) => (
  <Typography>
    {value !== undefined ? `${customToFixed(value, 2)}%` : ''}
  </Typography>
);

/**
 * BlankEditor component for a column.
 * Renders nothing in edit mode.
 * @constructor
 */
const BlankEditor = () => {
  return <span></span>;
};

/**
 * BlankEditTypeProvider for a column in the grid table.
 * Shows the column content in view mode and nothing in edit mode.
 * Useful for columns that should be blank when editing.
 * @param props - The properties for the DataTypeProvider.
 * @constructor
 */
export const BlankEditTypeProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider editorComponent={BlankEditor} {...props} />
);
