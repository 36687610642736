import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../api/app.types';
import { createAsyncThunkWithError } from '../../redux/utils';
import {
  deleteUserSetting,
  getUserSettings,
  setGlobalSettings,
  setUserSetting,
  setUserSettings,
} from './api';
import {
  UserSetting,
  UserSettingKey,
  SetUserSetting,
  Account,
  CELL_BOARD_M_CELL_ID,
  CELL_BOARD_INVENTORY_CENTER_ID,
  CELL_BOARD_M_CELL_COLLECTION_ID,
} from './types';
import { ManufacturingLocation } from '../manufacturingLocation/types';
import { RootState } from '../../store';
export type ProfileState = {
  profileStatus: LoadingStatus;
  userSetting: UserSetting;
  account: Account | null;
};

export const initialState: ProfileState = {
  profileStatus: 'idle',
  userSetting: {},
  account: null,
};

/**
 * Action to update All User Settings in the store
 */
export const setUserSettingsAction = createAction<UserSetting>(
  'profile/setUserSettingsAction',
);

/**
 * Action to set a particular user setting
 */
export const setUserSettingAction = createAction<SetUserSetting>(
  'profile/setUserSettingAction',
);

/**
 * Action to set account information
 */
export const setAccountAction = createAction<Account>(
  'profile/setAccountAction',
);

/**
 * Thunk that fetches, validates, and sets all user settings
 */
export const getUserSettingsThunk = createAsyncThunkWithError(
  'profile/getUserSettingsThunk',
  getUserSettings,
  'errorFetchingUserSettings',
);

/**
 * Thunk for saving Manufacturing Location settings
 */
export const setManufacturingLocationSettingsThunk = createAsyncThunkWithError(
  'profile/setManufacturingLocationSettingsThunk',
  async (
    manufacturingLocation: ManufacturingLocation,
    { dispatch },
  ): Promise<void> => {
    const userSetting: UserSetting = {
      [CELL_BOARD_M_CELL_ID]: JSON.stringify(manufacturingLocation.mCellId),
      [CELL_BOARD_INVENTORY_CENTER_ID]: JSON.stringify(
        manufacturingLocation.inventoryCenterId,
      ),
      [CELL_BOARD_M_CELL_COLLECTION_ID]: JSON.stringify(
        manufacturingLocation.mCellCollectionId,
      ),
    };
    dispatch(setUserSettingsAction(userSetting));
    await setUserSettings(userSetting);
  },
  'errorUpdatingUserSettings',
);

/**
 * Thunk for setting multiple global settings
 */
export const setGlobalSettingsThunk = createAsyncThunkWithError(
  'profile/setGlobalSettingsThunk',
  async (settings: UserSetting, { dispatch }): Promise<void> => {
    dispatch(setUserSettingsAction(settings));
    await setGlobalSettings(settings);
  },
  'errorUpdatingGlobalSettings',
);

/**
 * Thunk for saving a user setting locally and on the server
 */
export const setUserSettingThunk = createAsyncThunkWithError(
  'profile/setUserSettingThunk',
  async (
    { key, value }: { key: UserSettingKey; value: string | undefined },
    { dispatch },
  ): Promise<void> => {
    dispatch(setUserSettingAction({ key, value }));
    if (value) {
      await setUserSetting(key, value);
    } else {
      await deleteUserSetting(key);
    }
  },
  'errorUpdatingUserSetting',
);

export const profileSliceReducer = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUserSettingsAction: (state, action) => {
      state.userSetting = {
        ...state.userSetting,
        ...action.payload,
      };
    },
    setUserSettingAction: (state, action: PayloadAction<SetUserSetting>) => {
      state.userSetting[action.payload.key] = action.payload.value;
    },
    setAccountAction: (state, action) => {
      state.account = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserSettingsThunk.pending, (state) => {
        state.profileStatus = 'loading';
      })
      .addCase(getUserSettingsThunk.fulfilled, (state, { payload }) => {
        state.profileStatus = 'succeeded';
        state.userSetting = payload;
      })
      .addCase(getUserSettingsThunk.rejected, (state) => {
        state.profileStatus = 'failed';
      })
      .addCase(setManufacturingLocationSettingsThunk.pending, (state) => {
        state.profileStatus = 'loading';
      })
      .addCase(setManufacturingLocationSettingsThunk.fulfilled, (state) => {
        state.profileStatus = 'succeeded';
      })
      .addCase(setManufacturingLocationSettingsThunk.rejected, (state) => {
        state.profileStatus = 'failed';
      })
      .addCase(setGlobalSettingsThunk.pending, (state) => {
        state.profileStatus = 'loading';
      })
      .addCase(setGlobalSettingsThunk.fulfilled, (state) => {
        state.profileStatus = 'succeeded';
      })
      .addCase(setGlobalSettingsThunk.rejected, (state) => {
        state.profileStatus = 'failed';
      })
      .addCase(setUserSettingThunk.pending, (state) => {
        state.profileStatus = 'loading';
      })
      .addCase(setUserSettingThunk.fulfilled, (state) => {
        state.profileStatus = 'succeeded';
      })
      .addCase(setUserSettingThunk.rejected, (state) => {
        state.profileStatus = 'failed';
      });
  },
});

/**
 * Selector to retrieve the user settings from the profile state.
 * @param state - The entire Redux state tree.
 * @returns The user settings object from the profile state.
 */
export const selectUserSettings = (state: RootState) =>
  state.profile.userSetting;

/**
 * Selector to determine whether Profile is loading
 * @param state
 */
export const selectLoadingProfile = (state: RootState) =>
  state.profile.profileStatus === 'loading';

export default profileSliceReducer.reducer;
