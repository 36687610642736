import {
  addAttachmentsToCellBoardMessage,
  createCellBoardMessage,
  deleteAttachmentFromCellBoardMessage,
  deleteCellBoardMessage,
  getCellBoardData,
  getMCellMessages,
  markMCellMessageRead,
  updateCellBoardMessage,
  getCellBoardConfig,
  updateCellBoardConfig,
  deleteCellBoardSafetyMessage,
  getCellBoardSafetyTopic,
  setCellBoardSafetyTopic,
  deleteCellBoardSafetyTopic,
  getCellBoardEnabledMCellInfos,
  updateCellBoardEnabledMCellInfo,
  getMCellMessageById,
  getCellBoardRosterInventoryCenterConfigs,
  setCellBoardRosterInventoryCenterConfig,
  markMCellTaskComplete,
  markMCellTaskIncomplete,
  updateCellBoardMessageTasks,
  getCellBoardDefaultSafetyMessage,
  setCellBoardDefaultSafetyMessage,
  deleteCellBoardDefaultSafetyMessage,
  setNotesMCellTask,
  getProductionMetricIntervals,
  setProductionMetricIntervalNote,
  setProductionMetricIntervalAction,
  updateCellBoardMessageLink,
  addCellBoardMessageLink,
  deleteCellBoardMessageLink,
  setCellBoardSafetyMessages,
  getCellBoardSafetyMessages,
} from './api';
import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { createAsyncThunkWithError } from '../../redux/utils';
import {
  CellBoardConfig,
  CellBoardSafetyMessage,
  CellBoardDefaultSafetyMessage,
  CellBoardData,
  CellBoardEnabledMCellInfo,
  CellBoardEnabledMCellUpdate,
  CellBoardSafetyTopic,
  CellBoardRosterInventoryCenterConfig,
  ProductionMetricInterval,
  CellBoardMessageDto,
  CellBoardMCellMessageDetailDto,
  CellBoardMessageDetailDto,
} from './types';
import { LoadingStatus } from '../../api/app.types';
import { RootState } from '../../store';

export type CellBoardState = {
  cellBoardMessagesStatus: LoadingStatus;
  cellBoardMessages: CellBoardMessageDto[];
  MCellMessagesStatus: LoadingStatus;
  mCellMessages: CellBoardMCellMessageDetailDto[];
  cellBoardDataStatus: LoadingStatus;
  cellBoardData?: CellBoardData;
  currentMessageAttachmentsWithUrlStatus: LoadingStatus;
  configStatus: LoadingStatus;
  config?: CellBoardConfig;
  safetyMessageStatus: LoadingStatus;
  safetyMessages: CellBoardSafetyMessage[];
  defaultSafetyMessageStatus: LoadingStatus;
  defaultSafetyMessage?: CellBoardDefaultSafetyMessage;
  safetyTopicStatus: LoadingStatus;
  safetyTopic?: CellBoardSafetyTopic;
  enabledMCellInfos: CellBoardEnabledMCellInfo[];
  enabledCellInfosStatus: LoadingStatus;
  rosterInventoryCenterConfigsStatus: LoadingStatus;
  rosterInventoryCenterConfigs: CellBoardRosterInventoryCenterConfig[];
  productionMetricIntervals: ProductionMetricInterval[];
  productionMetricIntervalsStatus: LoadingStatus;
};
export const initialState: CellBoardState = {
  cellBoardMessagesStatus: 'idle',
  cellBoardMessages: [],
  MCellMessagesStatus: 'idle',
  mCellMessages: [],
  cellBoardDataStatus: 'idle',
  cellBoardData: undefined,
  currentMessageAttachmentsWithUrlStatus: 'idle',
  configStatus: 'idle',
  config: undefined,
  safetyMessageStatus: 'idle',
  safetyMessages: [],
  defaultSafetyMessageStatus: 'idle',
  defaultSafetyMessage: undefined,
  safetyTopicStatus: 'idle',
  safetyTopic: undefined,
  enabledMCellInfos: [],
  enabledCellInfosStatus: 'idle',
  rosterInventoryCenterConfigsStatus: 'idle',
  rosterInventoryCenterConfigs: [],
  productionMetricIntervals: [],
  productionMetricIntervalsStatus: 'idle',
};

/**
 * Action for adding or updating a Cell Board Message
 */
export const addOrUpdateCellBoardMessageAction =
  createAction<CellBoardMessageDetailDto>(
    'cellBoard/addOrUpdateCellBoardMessageAction',
  );

/**
 * Action for deleting a Cell Board Message
 */
export const deleteCellBoardMessageAction = createAction<string>(
  'cellBoard/deleteCellBoardMessageAction',
);

/**
 * Action for adding or updating a Cell Board MCell Message
 */
export const addOrUpdateMCellMessageAction =
  createAction<CellBoardMCellMessageDetailDto>(
    'cellBoard/addOrUpdateMCellMessageAction',
  );

/**
 * Action for deleting a Cell Board MCell Message
 */
export const deleteMCellMessageAction = createAction<string>(
  'cellBoard/deleteMCellMessageAction',
);

/**
 * Action for clearing Cell Board MCell Messages in store
 */
export const clearMCellMessagesAction = createAction(
  'cellBoard/clearMCellMessagesAction',
);

/**
 * Action to update CellBoard Enabled MCell
 */
export const updateCellBoardEnabledMCellAction =
  createAction<CellBoardEnabledMCellUpdate>(
    'cellBoard/updateCellBoardEnabledMCellAction',
  );

/**
 * Action to add or update a Cell Board Roster Inventory Center Config
 */
export const addOrUpdateCellBoardRosterInventoryCenterConfigAction =
  createAction<CellBoardRosterInventoryCenterConfig>(
    'cellBoard/addOrUpdateCellBoardRosterInventoryCenterConfigAction',
  );

/**
 * Thunk for creating a Cell Board Message from server and saving it to the store
 */
export const createCellBoardMessageThunk = createAsyncThunkWithError(
  'cellBoard/createCellBoardMessageThunk',
  createCellBoardMessage,
  'errorCreatingCellBoardMessage',
);

/**
 * Thunk for updating all Cell Board Message fields except attachments and tasks
 */
export const updateCellBoardMessageThunk = createAsyncThunkWithError(
  'cellBoard/updateCellBoardMessageThunk',
  updateCellBoardMessage,
  'errorUpdatingCellBoardMessage',
);

/**
 * Thunk for updating Cell Board Message tasks
 * @param params
 */
export const updateCellBoardMessageTasksThunk = createAsyncThunkWithError(
  'cellBoard/updateCellBoardMessageTasksThunk',
  updateCellBoardMessageTasks,
  'errorUpdatingCellBoardMessageTasks',
);

/**
 * Thunk for updating a Cell Board Message link
 * @param params
 */
export const updateCellBoardMessageLinkThunk = createAsyncThunkWithError(
  'cellBoard/updateCellBoardMessageLinkThunk',
  updateCellBoardMessageLink,
  'errorUpdatingCellBoardMessageLink',
);

/**
 * Thunk for adding a Cell Board Message link
 * @param params
 */
export const addCellBoardMessageLinkThunk = createAsyncThunkWithError(
  'cellBoard/addCellBoardMessageLinkThunk',
  addCellBoardMessageLink,
  'errorAddingCellBoardMessageLink',
);

/**
 * Thunk for deleting a Cell Board Message Link
 */
export const deleteCellBoardMessageLinkThunk = createAsyncThunkWithError(
  'cellBoard/deleteCellBoardMessageLinkThunk',
  deleteCellBoardMessageLink,
  'errorDeletingCellBoardMessageLink',
);

/**
 * Thunk for deleting a Cell Board Message
 */
export const deleteCellBoardMessageThunk = createAsyncThunkWithError(
  'cellBoard/deleteCellBoardMessageThunk',
  deleteCellBoardMessage,
  'errorDeletingCellBoardMessage',
);

/**
 * Thunk for adding attachments to a Cell Board Message
 */
export const addAttachmentsToCellBoardMessageThunk = createAsyncThunkWithError(
  'cellBoard/addAttachmentsToCellBoardMessageThunk',
  addAttachmentsToCellBoardMessage,
  'errorAddingAttachmentsToCellBoardMessage',
);

/**
 * Thunk for deleting an Attachment from a Cell Board Message
 */
export const deleteAttachmentFromCellBoardMessageThunk =
  createAsyncThunkWithError(
    'cellBoard/deleteAttachmentFromCellBoardMessageThunk',
    deleteAttachmentFromCellBoardMessage,
    'errorDeletingAttachmentFromCellBoardMessage',
  );

/**
 * Thunk for fetching MCell Messages from server and saving it to the store
 */
export const getMCellMessagesThunk = createAsyncThunkWithError(
  'cellBoard/getMCellMessagesThunk',
  getMCellMessages,
  'errorFetchingWorkCellMessages',
);

/**
 * Thunk for fetching MCell Message by id
 */
export const getMCellMessageByIdThunk = createAsyncThunkWithError(
  'cellBoard/getMCellMessageByIdThunk',
  getMCellMessageById,
  'errorFetchingWorkCellMessage',
);

/**
 * Thunk for fetching Cell Board Data from server and saving it to the
 * store
 */
export const getCellBoardDataThunk = createAsyncThunkWithError(
  'cellBoard/getCellBoardDataThunk',
  getCellBoardData,
  'errorFetchingCellBoardMetrics',
);

/**
 * Thunk for marking Cell Board MCell Message as read
 */
export const markMCellMessageReadThunk = createAsyncThunkWithError(
  'cellBoard/markMCellMessageReadThunk',
  markMCellMessageRead,
  'errorMarkingMCellMessageRead',
);

/**
 * Thunk for marking Cell Board MCell Task as complete
 * @param id
 */
export const markMCellTaskCompleteThunk = createAsyncThunkWithError(
  'cellBoard/markMCellTaskCompleteThunk',
  markMCellTaskComplete,
  'errorMarkingMCellTaskComplete',
);

/**
 * Thunk for marking Cell Board MCell Task as incomplete
 * @param id
 */
export const markMCellTaskIncompleteThunk = createAsyncThunkWithError(
  'cellBoard/markMCellTaskIncompleteThunk',
  markMCellTaskIncomplete,
  'errorMarkingMCellTaskIncomplete',
);

/**
 * Thunk for setting notes on Cell Board MCell Task
 * @param id
 */
export const setNotesMCellTaskThunk = createAsyncThunkWithError(
  'cellBoard/setNotesMCellTaskThunk',
  setNotesMCellTask,
  'errorSettingCellBoardTaskNotes',
);

/**
 * Thunk for fetching Cell Board Config from server and saving it to the store
 */
export const getCellBoardConfigThunk = createAsyncThunkWithError(
  'cellBoard/getCellBoardConfigThunk',
  getCellBoardConfig,
  'errorFetchingCellBoardSettings',
);

/**
 * Thunk for updating Cell Board Config on the server and saving result to the store
 */
export const updateCellBoardConfigThunk = createAsyncThunkWithError(
  'cellBoard/updateCellBoardConfigThunk',
  updateCellBoardConfig,
  'errorUpdatingCellBoardSettings',
);

/**
 * Thunk for fetching Cell Board Safety Messages from the server and saving them in the store
 */
export const getCellBoardSafetyMessagesThunk = createAsyncThunkWithError(
  'cellBoard/getCellBoardSafetyMessagesThunk',
  getCellBoardSafetyMessages,
  'errorFetchingCellBoardSafetyMessages',
);

/**
 * Thunk for setting Cell Board Safety Messages on the server and saving them to the store
 */
export const setCellBoardSafetyMessagesThunk = createAsyncThunkWithError(
  'cellBoard/setCellBoardSafetyMessagesThunk',
  setCellBoardSafetyMessages,
  'errorSettingCellBoardSafetyMessages',
);

/**
 * Thunk for deleting a Cell Board Safety Message
 */
export const deleteCellBoardSafetyMessageThunk = createAsyncThunkWithError(
  'cellBoard/deleteCellBoardSafetyMessageThunk',
  deleteCellBoardSafetyMessage,
  'errorDeletingCellBoardSafetyMessage',
);

/**
 * Thunk for fetching a Cell Board Default Safety Message from the server and saving it to the store
 */
export const getCellBoardDefaultSafetyMessageThunk = createAsyncThunkWithError(
  'cellBoard/getCellBoardDefaultSafetyMessageThunk',
  getCellBoardDefaultSafetyMessage,
  'errorFetchingCellBoardDefaultSafetyMessage',
);

/**
 * Thunk for setting a Cell Board Default Safety Message on the server and saving it to the store
 */
export const setCellBoardDefaultSafetyMessageThunk = createAsyncThunkWithError(
  'cellBoard/setCellBoardDefaultSafetyMessageThunk',
  setCellBoardDefaultSafetyMessage,
  'errorSettingCellBoardDefaultSafetyMessage',
);

/**
 * Thunk for deleting a Cell Board Default Safety Message
 */
export const deleteCellBoardDefaultSafetyMessageThunk =
  createAsyncThunkWithError(
    'cellBoard/deleteCellBoardDefaultSafetyMessageThunk',
    deleteCellBoardDefaultSafetyMessage,
    'errorDeletingCellBoardDefaultSafetyMessage',
  );

/**
 * Thunk for fetching a Cell Board Safety Topic from the server and saving it to the store
 */
export const getCellBoardSafetyTopicThunk = createAsyncThunkWithError(
  'cellBoard/getCellBoardSafetyTopicThunk',
  getCellBoardSafetyTopic,
  'errorFetchingCellBoardSafetyTopic',
);

/**
 * Thunk for setting a Cell Board Safety Topic on the server and saving it to the store
 */
export const setCellBoardSafetyTopicThunk = createAsyncThunkWithError(
  'cellBoard/setCellBoardSafetyTopicThunk',
  setCellBoardSafetyTopic,
  'errorSettingCellBoardSafetyTopic',
);

/**
 * Thunk for deleting a Cell Board Safety Topic
 */
export const deleteCellBoardSafetyTopicThunk = createAsyncThunkWithError(
  'cellBoard/deleteCellBoardSafetyTopicThunk',
  deleteCellBoardSafetyTopic,
  'errorDeletingCellBoardSafetyTopic',
);

/**
 * Thunk for fetching Cell Board Enabled MCell Infos
 */
export const getCellBoardEnabledMCellsThunk = createAsyncThunkWithError(
  'cellBoard/getCellBoardEnabledMCellsThunk',
  getCellBoardEnabledMCellInfos,
  'errorFetchingEnabledWorkCells',
);

/**
 * Thunk for Updating Cell Board Enabled MCell Info optimistically and on server.
 */
export const updateCellBoardEnabledMCellInfoThunk = createAsyncThunkWithError(
  'cellBoard/updateCellBoardEnabledMCellInfoThunk',
  async (
    cellBoardEnabledMCellUpdate: CellBoardEnabledMCellInfo,
    { dispatch },
  ) => {
    dispatch(updateCellBoardEnabledMCellAction(cellBoardEnabledMCellUpdate));
    await updateCellBoardEnabledMCellInfo(cellBoardEnabledMCellUpdate);
  },
  'errorUpdatingEnabledWorkCell',
);

/**
 * Thunk for fetching Cell Board Roster Inventory Center Configs from server and
 * saving it to the store
 */
export const getCellBoardRosterInventoryCenterConfigsThunk =
  createAsyncThunkWithError(
    'cellBoard/getCellBoardRosterInventoryCenterConfigsThunk',
    getCellBoardRosterInventoryCenterConfigs,
    'errorFetchingCellBoardRosterConfigurations',
  );

/**
 * Thunk for setting Cell Board Roster Inventory Center Config optimistically
 * and on server.
 */
export const setCellBoardRosterInventoryCenterConfigThunk =
  createAsyncThunkWithError(
    'cellBoard/setCellBoardRosterInventoryCenterConfigThunk',
    async (config: CellBoardRosterInventoryCenterConfig, { dispatch }) => {
      dispatch(addOrUpdateCellBoardRosterInventoryCenterConfigAction(config));
      await setCellBoardRosterInventoryCenterConfig(config);
    },
    'errorSettingCellBoardRosterConfiguration',
  );

/**
 * Thunk for setting a Production Metric Interval note.
 */
export const setProductionMetricIntervalNoteThunk = createAsyncThunkWithError(
  'cellBoard/setProductionMetricIntervalNoteThunk',
  setProductionMetricIntervalNote,
  'errorSettingProductionMetricIntervalNote',
);

/**
 * Thunk for setting a Production Metric Interval action.
 */
export const setProductionMetricIntervalActionThunk = createAsyncThunkWithError(
  'cellBoard/setProductionMetricIntervalActionThunk',
  setProductionMetricIntervalAction,
  'errorSettingProductionMetricIntervalAction',
);

/**
 * Thunk for fetching filtered Production Metric Intervals.
 */
export const getProductionMetricIntervalsThunk = createAsyncThunkWithError(
  'cellBoard/getProductionMetricIntervalsThunk',
  getProductionMetricIntervals,
  'errorFetchingProductionMetricIntervals',
);

export const cellBoardSlice = createSlice({
  name: 'cellBoard',
  initialState,
  reducers: {
    addOrUpdateCellBoardMessageAction: (state, action) => {
      addOrUpdateCellBoardMessages(state, action.payload);
    },
    deleteCellBoardMessageAction: (state, action) => {
      const deleteIndex = state.cellBoardMessages.findIndex(
        (d) => d.id === action.payload,
      );
      if (deleteIndex > -1) {
        state.cellBoardMessages.splice(deleteIndex, 1);
      }
    },
    addOrUpdateMCellMessageAction: (state, action) => {
      const updateIndex = state.mCellMessages.findIndex(
        (d) => d.id === action.payload.id,
      );
      if (updateIndex > -1) {
        state.mCellMessages[updateIndex] = action.payload;
      } else {
        state.mCellMessages.push(action.payload);
      }
    },
    deleteMCellMessageAction: (state, action) => {
      const deleteIndex = state.mCellMessages.findIndex(
        (d) => d.id === action.payload,
      );
      if (deleteIndex > -1) {
        state.mCellMessages.splice(deleteIndex, 1);
      }
    },
    clearMCellMessagesAction: (state) => {
      state.mCellMessages = [];
    },
    updateCellBoardEnabledMCellAction: (state, action) => {
      const updateIndex = state.enabledMCellInfos.findIndex(
        (d) => d.mCellId === action.payload.mCellId,
      );
      if (updateIndex > -1) {
        state.enabledMCellInfos[updateIndex] = action.payload;
      } else {
        state.enabledMCellInfos.push(action.payload);
      }
    },
    addOrUpdateCellBoardRosterInventoryCenterConfigAction: (state, action) => {
      const updateIndex = state.rosterInventoryCenterConfigs.findIndex(
        (d) => d.id === action.payload.id,
      );
      if (updateIndex > -1) {
        state.rosterInventoryCenterConfigs[updateIndex] = action.payload;
      } else {
        state.rosterInventoryCenterConfigs.push(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setProductionMetricIntervalNoteThunk.pending, (state) => {
        state.productionMetricIntervalsStatus = 'loading';
      })
      .addCase(setProductionMetricIntervalNoteThunk.fulfilled, (state) => {
        state.productionMetricIntervalsStatus = 'succeeded';
      })
      .addCase(setProductionMetricIntervalNoteThunk.rejected, (state) => {
        state.productionMetricIntervalsStatus = 'failed';
      })
      .addCase(getProductionMetricIntervalsThunk.pending, (state) => {
        state.productionMetricIntervalsStatus = 'loading';
      })
      .addCase(
        getProductionMetricIntervalsThunk.fulfilled,
        (state, { payload }) => {
          state.productionMetricIntervalsStatus = 'succeeded';
          state.productionMetricIntervals = payload;
        },
      )
      .addCase(getProductionMetricIntervalsThunk.rejected, (state) => {
        state.productionMetricIntervalsStatus = 'failed';
      })
      .addCase(createCellBoardMessageThunk.pending, (state) => {
        state.cellBoardMessagesStatus = 'loading';
      })
      .addCase(createCellBoardMessageThunk.fulfilled, (state, { payload }) => {
        state.cellBoardMessagesStatus = 'succeeded';
        state.cellBoardMessages.push(payload);
      })
      .addCase(createCellBoardMessageThunk.rejected, (state) => {
        state.cellBoardMessagesStatus = 'failed';
      })
      .addCase(updateCellBoardMessageThunk.pending, (state) => {
        state.cellBoardMessagesStatus = 'loading';
      })
      .addCase(updateCellBoardMessageThunk.fulfilled, (state, { payload }) => {
        state.cellBoardMessagesStatus = 'succeeded';
        addOrUpdateCellBoardMessages(state, payload);
      })
      .addCase(updateCellBoardMessageThunk.rejected, (state) => {
        state.cellBoardMessagesStatus = 'failed';
      })
      .addCase(updateCellBoardMessageTasksThunk.pending, (state) => {
        state.cellBoardMessagesStatus = 'loading';
      })
      .addCase(updateCellBoardMessageTasksThunk.fulfilled, (state) => {
        state.cellBoardMessagesStatus = 'succeeded';
      })
      .addCase(updateCellBoardMessageTasksThunk.rejected, (state) => {
        state.cellBoardMessagesStatus = 'failed';
      })
      .addCase(updateCellBoardMessageLinkThunk.pending, (state) => {
        state.cellBoardMessagesStatus = 'loading';
      })
      .addCase(
        updateCellBoardMessageLinkThunk.fulfilled,
        (state, { payload }) => {
          state.cellBoardMessagesStatus = 'succeeded';
          const index = state.cellBoardMessages.findIndex(
            (d) => d.id === payload.id,
          );
          if (index > -1) {
            state.cellBoardMessages[index].links.push(payload);
          }
        },
      )
      .addCase(updateCellBoardMessageLinkThunk.rejected, (state) => {
        state.cellBoardMessagesStatus = 'failed';
      })
      .addCase(addCellBoardMessageLinkThunk.pending, (state) => {
        state.cellBoardMessagesStatus = 'loading';
      })
      .addCase(addCellBoardMessageLinkThunk.fulfilled, (state, { payload }) => {
        state.cellBoardMessagesStatus = 'succeeded';
        const index = state.cellBoardMessages.findIndex(
          (d) => d.id === payload.id,
        );
        if (index > -1) {
          state.cellBoardMessages[index].links.push(payload);
        }
      })
      .addCase(addCellBoardMessageLinkThunk.rejected, (state) => {
        state.cellBoardMessagesStatus = 'failed';
      })
      .addCase(deleteCellBoardMessageThunk.pending, (state) => {
        state.cellBoardMessagesStatus = 'loading';
      })
      .addCase(deleteCellBoardMessageThunk.fulfilled, (state) => {
        state.cellBoardMessagesStatus = 'succeeded';
      })
      .addCase(deleteCellBoardMessageThunk.rejected, (state) => {
        state.cellBoardMessagesStatus = 'failed';
      })
      .addCase(addAttachmentsToCellBoardMessageThunk.pending, (state) => {
        state.cellBoardMessagesStatus = 'loading';
      })
      .addCase(
        addAttachmentsToCellBoardMessageThunk.fulfilled,
        (state, { payload }) => {
          state.cellBoardMessagesStatus = 'succeeded';
          const index = state.cellBoardMessages.findIndex(
            (d) => d.id === payload.id,
          );
          if (index > -1) {
            state.cellBoardMessages[index].attachments.push(payload);
          }
        },
      )
      .addCase(addAttachmentsToCellBoardMessageThunk.rejected, (state) => {
        state.cellBoardMessagesStatus = 'failed';
      })
      .addCase(deleteAttachmentFromCellBoardMessageThunk.pending, (state) => {
        state.cellBoardMessagesStatus = 'loading';
      })
      .addCase(deleteAttachmentFromCellBoardMessageThunk.fulfilled, (state) => {
        state.cellBoardMessagesStatus = 'succeeded';
      })
      .addCase(deleteAttachmentFromCellBoardMessageThunk.rejected, (state) => {
        state.cellBoardMessagesStatus = 'failed';
      })
      .addCase(getMCellMessagesThunk.pending, (state) => {
        state.MCellMessagesStatus = 'loading';
      })
      .addCase(getMCellMessagesThunk.fulfilled, (state, { payload }) => {
        state.MCellMessagesStatus = 'succeeded';
        state.mCellMessages = payload;
      })
      .addCase(getMCellMessagesThunk.rejected, (state) => {
        state.MCellMessagesStatus = 'failed';
      })
      .addCase(getMCellMessageByIdThunk.pending, (state) => {
        state.MCellMessagesStatus = 'loading';
      })
      .addCase(getMCellMessageByIdThunk.fulfilled, (state) => {
        state.MCellMessagesStatus = 'succeeded';
      })
      .addCase(getMCellMessageByIdThunk.rejected, (state) => {
        state.MCellMessagesStatus = 'failed';
      })
      .addCase(getCellBoardDataThunk.pending, (state) => {
        state.cellBoardDataStatus = 'loading';
      })
      .addCase(getCellBoardDataThunk.fulfilled, (state, { payload }) => {
        state.cellBoardDataStatus = 'succeeded';
        state.cellBoardData = payload;
      })
      .addCase(getCellBoardDataThunk.rejected, (state) => {
        state.cellBoardDataStatus = 'failed';
      })
      .addCase(markMCellMessageReadThunk.pending, (state) => {
        state.MCellMessagesStatus = 'loading';
      })
      .addCase(markMCellMessageReadThunk.fulfilled, (state, { payload }) => {
        state.MCellMessagesStatus = 'succeeded';
        const updateIndex = state.mCellMessages.findIndex(
          (d) => d.mCell === payload.mCell,
        );
        if (updateIndex > -1) {
          state.mCellMessages[updateIndex] = payload;
        }
      })
      .addCase(markMCellMessageReadThunk.rejected, (state) => {
        state.MCellMessagesStatus = 'failed';
      })
      .addCase(setNotesMCellTaskThunk.pending, (state) => {
        state.MCellMessagesStatus = 'loading';
      })
      .addCase(setNotesMCellTaskThunk.fulfilled, (state) => {
        state.MCellMessagesStatus = 'succeeded';
      })
      .addCase(setNotesMCellTaskThunk.rejected, (state) => {
        state.MCellMessagesStatus = 'failed';
      })
      .addCase(getCellBoardConfigThunk.pending, (state) => {
        state.configStatus = 'loading';
      })
      .addCase(getCellBoardConfigThunk.fulfilled, (state, { payload }) => {
        state.configStatus = 'succeeded';
        state.config = payload;
      })
      .addCase(getCellBoardConfigThunk.rejected, (state) => {
        state.configStatus = 'failed';
      })
      .addCase(updateCellBoardConfigThunk.pending, (state) => {
        state.configStatus = 'loading';
      })
      .addCase(updateCellBoardConfigThunk.fulfilled, (state, { payload }) => {
        state.configStatus = 'succeeded';
        state.config = payload;
      })
      .addCase(updateCellBoardConfigThunk.rejected, (state) => {
        state.configStatus = 'failed';
      })
      .addCase(getCellBoardSafetyMessagesThunk.pending, (state) => {
        state.safetyMessageStatus = 'loading';
      })
      .addCase(
        getCellBoardSafetyMessagesThunk.fulfilled,
        (state, { payload }) => {
          state.safetyMessageStatus = 'succeeded';
          state.safetyMessages = payload;
        },
      )
      .addCase(getCellBoardSafetyMessagesThunk.rejected, (state) => {
        state.safetyMessageStatus = 'failed';
      })
      .addCase(setCellBoardSafetyMessagesThunk.pending, (state) => {
        state.safetyMessageStatus = 'loading';
      })
      .addCase(
        setCellBoardSafetyMessagesThunk.fulfilled,
        (state, { payload }) => {
          state.safetyMessageStatus = 'succeeded';
          state.safetyMessages = payload;
        },
      )
      .addCase(setCellBoardSafetyMessagesThunk.rejected, (state) => {
        state.safetyMessageStatus = 'failed';
      })
      .addCase(deleteCellBoardSafetyMessageThunk.pending, (state) => {
        state.safetyMessageStatus = 'loading';
      })
      .addCase(deleteCellBoardSafetyMessageThunk.fulfilled, (state) => {
        state.safetyMessageStatus = 'succeeded';
      })
      .addCase(deleteCellBoardSafetyMessageThunk.rejected, (state) => {
        state.safetyMessageStatus = 'failed';
      })
      .addCase(getCellBoardDefaultSafetyMessageThunk.pending, (state) => {
        state.defaultSafetyMessageStatus = 'loading';
      })
      .addCase(
        getCellBoardDefaultSafetyMessageThunk.fulfilled,
        (state, { payload }) => {
          state.defaultSafetyMessageStatus = 'succeeded';
          state.defaultSafetyMessage = payload;
        },
      )
      .addCase(getCellBoardDefaultSafetyMessageThunk.rejected, (state) => {
        state.defaultSafetyMessageStatus = 'failed';
      })
      .addCase(setCellBoardDefaultSafetyMessageThunk.pending, (state) => {
        state.defaultSafetyMessageStatus = 'loading';
      })
      .addCase(
        setCellBoardDefaultSafetyMessageThunk.fulfilled,
        (state, { payload }) => {
          state.defaultSafetyMessageStatus = 'succeeded';
          state.defaultSafetyMessage = payload;
        },
      )
      .addCase(setCellBoardDefaultSafetyMessageThunk.rejected, (state) => {
        state.defaultSafetyMessageStatus = 'failed';
      })
      .addCase(deleteCellBoardDefaultSafetyMessageThunk.pending, (state) => {
        state.defaultSafetyMessageStatus = 'loading';
      })
      .addCase(deleteCellBoardDefaultSafetyMessageThunk.fulfilled, (state) => {
        state.defaultSafetyMessageStatus = 'succeeded';
        state.defaultSafetyMessage = undefined;
      })
      .addCase(deleteCellBoardDefaultSafetyMessageThunk.rejected, (state) => {
        state.defaultSafetyMessageStatus = 'failed';
      })
      .addCase(getCellBoardSafetyTopicThunk.pending, (state) => {
        state.safetyTopicStatus = 'loading';
      })
      .addCase(getCellBoardSafetyTopicThunk.fulfilled, (state, { payload }) => {
        state.safetyTopicStatus = 'succeeded';
        state.safetyTopic = payload;
      })
      .addCase(getCellBoardSafetyTopicThunk.rejected, (state) => {
        state.safetyTopicStatus = 'failed';
      })
      .addCase(setCellBoardSafetyTopicThunk.pending, (state) => {
        state.safetyTopicStatus = 'loading';
      })
      .addCase(setCellBoardSafetyTopicThunk.fulfilled, (state, { payload }) => {
        state.safetyTopicStatus = 'succeeded';
        state.safetyTopic = payload;
      })
      .addCase(setCellBoardSafetyTopicThunk.rejected, (state) => {
        state.safetyTopicStatus = 'failed';
      })
      .addCase(deleteCellBoardSafetyTopicThunk.pending, (state) => {
        state.safetyTopicStatus = 'loading';
      })
      .addCase(deleteCellBoardSafetyTopicThunk.fulfilled, (state) => {
        state.safetyTopicStatus = 'succeeded';
        state.safetyTopic = undefined;
      })
      .addCase(deleteCellBoardSafetyTopicThunk.rejected, (state) => {
        state.safetyTopicStatus = 'failed';
      })
      .addCase(getCellBoardEnabledMCellsThunk.pending, (state) => {
        state.enabledCellInfosStatus = 'loading';
      })
      .addCase(
        getCellBoardEnabledMCellsThunk.fulfilled,
        (state, { payload }) => {
          state.enabledCellInfosStatus = 'succeeded';
          state.enabledMCellInfos = payload;
        },
      )
      .addCase(getCellBoardEnabledMCellsThunk.rejected, (state) => {
        state.enabledCellInfosStatus = 'failed';
      })
      .addCase(updateCellBoardEnabledMCellInfoThunk.pending, (state) => {
        state.enabledCellInfosStatus = 'loading';
      })
      .addCase(updateCellBoardEnabledMCellInfoThunk.fulfilled, (state) => {
        state.enabledCellInfosStatus = 'succeeded';
      })
      .addCase(updateCellBoardEnabledMCellInfoThunk.rejected, (state) => {
        state.enabledCellInfosStatus = 'failed';
      })
      .addCase(
        getCellBoardRosterInventoryCenterConfigsThunk.pending,
        (state) => {
          state.rosterInventoryCenterConfigsStatus = 'loading';
        },
      )
      .addCase(
        getCellBoardRosterInventoryCenterConfigsThunk.fulfilled,
        (state, { payload }) => {
          state.rosterInventoryCenterConfigsStatus = 'succeeded';
          state.rosterInventoryCenterConfigs = payload;
        },
      )
      .addCase(
        getCellBoardRosterInventoryCenterConfigsThunk.rejected,
        (state) => {
          state.rosterInventoryCenterConfigsStatus = 'failed';
        },
      )
      .addCase(
        setCellBoardRosterInventoryCenterConfigThunk.pending,
        (state) => {
          state.rosterInventoryCenterConfigsStatus = 'loading';
        },
      )
      .addCase(
        setCellBoardRosterInventoryCenterConfigThunk.fulfilled,
        (state) => {
          state.rosterInventoryCenterConfigsStatus = 'succeeded';
        },
      )
      .addCase(
        setCellBoardRosterInventoryCenterConfigThunk.rejected,
        (state) => {
          state.rosterInventoryCenterConfigsStatus = 'failed';
        },
      );
  },
});

/**
 * Function to add or update Cell Board Messages in the store
 * @param state
 * @param cellBoardMessages
 */
export const addOrUpdateCellBoardMessages = (
  state: CellBoardState,
  cellBoardMessages: CellBoardMessageDetailDto,
) => {
  const updateIndex = state.cellBoardMessages.findIndex(
    (d) => d.id === cellBoardMessages.id,
  );
  if (updateIndex > -1) {
    state.cellBoardMessages[updateIndex] = cellBoardMessages;
  } else {
    state.cellBoardMessages.push(cellBoardMessages);
  }
};

/**
 * Selector to determine whether Production Metric Intervals are loading
 * @param state
 */
export const selectLoadingProductionMetricIntervals = (state: RootState) =>
  state.cellBoard.productionMetricIntervalsStatus === 'loading';

/**
 * Selector to select Production Metric Intervals
 * @param state
 */
export const selectProductionMetricIntervals = (state: RootState) =>
  state.cellBoard.productionMetricIntervals;

/**
 * Selector to determine whether Cell Board Messages are loading
 * @param state
 */
export const selectLoadingCellBoardMessages = (state: RootState) =>
  state.cellBoard.cellBoardMessagesStatus === 'loading';

/**
 * Selector to determine whether MCell Messages are loading
 * @param state
 */
export const selectLoadingMCellMessages = (state: RootState) =>
  state.cellBoard.MCellMessagesStatus === 'loading';

/**
 * Selector to select MCell Messages
 * @param state
 */
export const selectMCellMessages = (state: RootState) =>
  state.cellBoard.mCellMessages;

/**
 * Selector to determine whether Cell Board Data is loading
 * @param state
 */
export const selectLoadingCellBoardData = (state: RootState) =>
  state.cellBoard.cellBoardDataStatus === 'loading';

/**
 * Selector to select Cell Board Data
 * @param state
 */
export const selectCellBoardData = (state: RootState) =>
  state.cellBoard.cellBoardData;

/**
 * Selector to determine whether Config is loading
 * @param state
 */
export const selectLoadingConfig = (state: RootState) =>
  state.cellBoard.configStatus === 'loading';

/**
 * Selector to select Config
 * @param state
 */
export const selectConfig = (state: RootState) => state.cellBoard.config;

/**
 * Selector to determine whether the Safety Message is loading
 * @param state
 */
export const selectLoadingSafetyMessage = (state: RootState) =>
  state.cellBoard.safetyMessageStatus === 'loading';

/**
 * Selector to select Safety Messages
 * @param state
 */
export const selectSafetyMessages = (state: RootState) =>
  state.cellBoard.safetyMessages;

/**
 * Selector to determine whether the Default Safety Message is loading
 * @param state
 */
export const selectLoadingDefaultSafetyMessage = (state: RootState) =>
  state.cellBoard.defaultSafetyMessageStatus === 'loading';

/**
 * Selector to select the Default Safety Message
 * @param state
 */
export const selectDefaultSafetyMessage = (state: RootState) =>
  state.cellBoard.defaultSafetyMessage;

/**
 * Selector to determine whether the Safety Topic is loading
 * @param state
 */
export const selectLoadingSafetyTopic = (state: RootState) =>
  state.cellBoard.safetyTopicStatus === 'loading';

/**
 * Selector to determine whether Enabled Cell Infos are loading
 * @param state
 */
export const selectLoadingEnabledCellInfos = (state: RootState) =>
  state.cellBoard.enabledCellInfosStatus === 'loading';

/**
 * Selector to select Enabled Cell Infos
 * @param state
 */
export const selectEnabledMCellInfos = (state: RootState) =>
  state.cellBoard.enabledMCellInfos;

/**
 * Creates a memoized selector for retrieving a list of enabled MCell IDs from the state.
 * This selector is derived from `selectEnabledMCellInfos`, which should provide an array
 * of MCell info objects, each potentially including an 'enabled' status.
 *
 * The selector first filters out any MCell info objects where 'enabled' is not true,
 * and then maps over the filtered array to extract just the MCell ID from each object.
 * The result is an array of strings, where each string is the ID of an enabled MCell.
 *
 * @returns An array of MCell IDs that are currently marked as enabled. This
 * array is recalculated only when the underlying MCell info data changes, ensuring
 * optimal performance by minimizing unnecessary recalculations.
 */
export const selectEnabledMCellIds = createSelector(
  [selectEnabledMCellInfos],
  (enabledMCellInfos) =>
    enabledMCellInfos
      .filter((info) => info.enabled)
      .map((info) => info.mCellId),
);

/**
 * Selector to determine whether Cell Board Data or MCell Messages are loading
 * @param state
 */
export const selectLoadingCellBoardDataOrLoadingMCellMessages = (
  state: RootState,
) =>
  state.cellBoard.cellBoardDataStatus === 'loading' ||
  state.cellBoard.MCellMessagesStatus === 'loading';

/**
 * Selector to determine whether Roster Inventory Center Configs are loading
 * @param state
 */
export const selectLoadingRosterInventoryCenterConfig = (state: RootState) =>
  state.cellBoard.rosterInventoryCenterConfigsStatus === 'loading';

export default cellBoardSlice.reducer;
