import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, IconButton, Typography } from '@mui/material';
import EditIconButton from '../../common/EditIconButton';
import TextInputDialog from '../../common/TextInputDialog';
import { useTranslation } from 'react-i18next';
import LocationChip from '../../settings/components/LocationChip';
import ManufacturingLocationSelectionDialog from '../../manufacturingLocation/components/ManufacturingLocationSelectionDialog';
import { ManufacturingLocation } from '../../manufacturingLocation/types';
import { HomeLinkInfo, HomeLinkUpdate } from '../types';
import {
  createHomeLinkGroupOptions,
  getHomeLinkDetails,
  HOME_LINK_IMAGE_MAX_FILE_SIZE_BYTES,
  HOME_LINK_MAX_FILE_SIZE_BYTES,
  homeLinkOptionToStringKey,
} from '../utils';
import {
  deleteFileThunk,
  deleteHomeLinkThunk,
  deleteImageThunk,
  getHomeLinkGroupsThunk,
  getHomeLinkThunk,
  selectHomeLink,
  selectLoadingHomeLinks,
  updateFileThunk,
  updateHomeLinkThunk,
  updateImageThunk,
} from '../homeSlice';
import { RootState, useAppDispatch } from '../../../store';
import { DeleteIcon } from '../../common/icons';
import FileUploadDialog from '../../common/FileUploadDialog';
import EnhancedButton from '../../common/EnhancedButton';
import ConfirmDialog from '../../common/ConfirmDialog';
import Page from '../../common/Page';
import { useParams } from 'react-router-dom';
import { useNavigation } from '../../../utils/navigation';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import ImageCard from '../../common/ImageCard';
import EnhancedSelectDialog from '../../common/EnhancedSelectDialog';
import { getInventoryCenterIdsByManufacturingLocationThunk } from '../../manufacturingLocation/manufacturingLocationSlice';

/**
 * Component for editing Home Link details
 * @constructor
 */
const HomeLinkConfigDetailEdit = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const loadingHomeLinks = useSelector(selectLoadingHomeLinks);
  const { id } = useParams<{ id: string }>();
  const { navigateToHomeConfiguration } = useNavigation();
  const homeLink = useSelector(selectHomeLink(id));
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isIconImageAdd, setIsIconImageAdd] = useState(false);
  const [isFileAdd, setIsFileAdd] = useState(false);
  const [dialogOpenState, setDialogOpenState] = useState<DialogState>({});
  const [isDeleteIconImageOpen, setIsDeleteIconImageOpen] = useState(false);
  const [isDeleteFileOpen, setIsDeleteFileOpen] = useState(false);
  const [
    isManufacturingLocationDialogOpen,
    setIsManufacturingLocationDialogOpen,
  ] = useState(false);

  const inputProps = {
    min: 0,
    step: 1,
  };

  const {
    inventoryCenterId,
    mCellId,
    mCellCollectionId,
    title,
    sortSequence,
    homeLinkGroupId,
    groupName,
  } = getHomeLinkDetails(homeLink);

  const [groupValue, setGroupValue] = useState<string>(homeLinkGroupId ?? '');
  const homeLinkGroups = useSelector(
    (state: RootState) => state.home.homeLinkGroups
  );
  const homeLinkGroupOptions = createHomeLinkGroupOptions(homeLinkGroups, t);

  /**
   * Effect to fetch homeLink when id is selected
   */
  useEffect(() => {
    if (id) {
      dispatch(getHomeLinkThunk(id));
    }
  }, [dispatch, id]);

  /**
   * Fetches the selected Home Link and sets it in the store and local state
   */
  const getHomeLink = useCallback(() => {
    if (id) {
      dispatch(getHomeLinkThunk(id));
    }
  }, [dispatch, id]);

  /**
   * Effect to fetch homeLink if not in store
   */
  useEffect(() => {
    if (!homeLink) {
      getHomeLink();
    }
  }, [dispatch, homeLink, id, getHomeLink]);

  /**
   * Effect to fetch home link groups
   */
  useEffect(() => {
    const manufacturingLocation: ManufacturingLocation = {
      inventoryCenterId,
      mCellId,
      mCellCollectionId,
    };
    dispatch(
      getInventoryCenterIdsByManufacturingLocationThunk(manufacturingLocation)
    ).then((result) => {
      dispatch(getHomeLinkGroupsThunk(result.payload as string[]));
    });
  }, [dispatch, inventoryCenterId, mCellId, mCellCollectionId]);

  /**
   * Opens the dialog window for a Home Link property
   * @param key
   */
  function handleDialogOpen(key: keyof HomeLinkInfo) {
    setDialogOpenState({
      ...dialogOpenState,
      [key]: true,
    });
  }

  /**
   * Closes the dialog window for a Home Link property
   * @param key
   */
  function handleDialogClose(key: keyof HomeLinkInfo) {
    setDialogOpenState({
      ...dialogOpenState,
      [key]: false,
    });
  }

  /**
   * Opens Delete Home Link dialog
   */
  function handleDeleteHomeLinkClick() {
    setIsDeleteDialogOpen(true);
  }

  /**
   * Dispatches call to delete Home Link and navigates to
   * Home Link Configuration page on success
   */
  function handleDeleteHomeLinkConfirm() {
    if (id) {
      dispatch(deleteHomeLinkThunk(id))
        .then(unwrapResult)
        .then(navigateToHomeConfiguration);
    }
  }

  /**
   * Closes Delete Home Link dialog
   */
  function handleDeleteDialogClose() {
    setIsDeleteDialogOpen(false);
  }

  /**
   * Dispatches call to update a Home Link property on the server, and then fetches
   * the updated object on success and updates local state with those changes.
   * @param key
   * @param newValue
   */
  function handleConfirm(key: keyof HomeLinkUpdate, newValue: any) {
    if (id && homeLink) {
      if (key === 'homeLinkGroupId') {
        setGroupValue(newValue);
      }
      dispatch(
        updateHomeLinkThunk({
          id,
          info: {
            inventoryCenterId: homeLink.inventoryCenterId,
            mCellId: homeLink.mCellId,
            mCellCollectionId: homeLink.mCellCollectionId,
            title: homeLink.title,
            linkUrl: homeLink.linkUrl,
            sortSequence: homeLink.sortSequence,
            homeLinkGroupId: homeLink.homeLinkGroupId,
            [key]: newValue,
          },
        })
      )
        .then(unwrapResult)
        .then(() => {
          dispatch(getHomeLinkThunk(id));
          handleDialogClose(key);
        });
    }
  }

  /**
   * Opens delete Icon Image dialog
   */
  function handleIconImageDeleteClick() {
    setIsDeleteIconImageOpen(true);
  }

  /**
   * Dispatches call to delete Icon Image. Closes dialog and fetches updated
   * Home Link on success
   */
  function handleIconImageDeleteConfirm() {
    if (id && homeLink) {
      dispatch(deleteImageThunk(id))
        .then(unwrapResult)
        .then((h) => {
          if (h) {
            dispatch(getHomeLinkThunk(id));
            setIsDeleteIconImageOpen(false);
          }
        });
    }
  }

  /**
   * Closes delete Icon Image dialog
   */
  function handleIconImageDeleteClose() {
    setIsDeleteIconImageOpen(false);
  }

  /**
   * Dispatches call to delete File. Closes dialog and fetches updated
   * Home Link on success
   */
  function handleFileDeleteConfirm() {
    if (id && homeLink) {
      dispatch(deleteFileThunk(id))
        .then(unwrapResult)
        .then(() => {
          dispatch(getHomeLinkThunk(id));
          handleFileDeleteClose();
        });
    }
  }

  /**
   * Closes delete File dialog
   */
  function handleFileDeleteClose() {
    setIsDeleteFileOpen(false);
  }

  /**
   * Called when location chip is clicked
   */
  function handleLocationClick() {
    setIsManufacturingLocationDialogOpen(true);
  }

  /**
   * Opens the add Icon Image dialog
   */
  function handleIconImageAddClick() {
    setIsIconImageAdd(true);
  }

  /**
   * Opens the add File dialog
   */
  function handleFileAddClick() {
    setIsFileAdd(true);
  }

  /**
   * Dispatches call to add or update Image. Closes dialog and sets
   * updated HomeLink on success
   * @param image
   */
  function handleIconImageAddConfirm(image: File[]) {
    if (id && homeLink) {
      dispatch(
        updateImageThunk({
          id,
          image,
        })
      )
        .then(unwrapResult)
        .then(() => {
          dispatch(getHomeLinkThunk(id));
          setIsIconImageAdd(false);
        });
    }
  }

  /**
   * Dispatches call to add or update File. Closes dialog and sets
   * updated HomeLink on success
   * @param file
   */
  function handleFileAddConfirm(file: File[]) {
    if (id && homeLink) {
      dispatch(
        updateFileThunk({
          id,
          file,
        })
      )
        .then(unwrapResult)
        .then(() => {
          dispatch(getHomeLinkThunk(id));
          setIsFileAdd(false);
        });
    }
  }

  /**
   * Closes add Icon Image dialog
   */
  function handleIconImageAddClose() {
    setIsIconImageAdd(false);
  }

  /**
   * Closes add File dialog
   */
  function handleFileAddClose() {
    setIsFileAdd(false);
  }

  /**
   * Called when manufacturing location dialog cancel is clicked.
   * Closes the dialog
   */
  function handleLocationDialogCancel() {
    setIsManufacturingLocationDialogOpen(false);
  }

  /**
   * Dispatches call to update a Home Link's Manufacturing Location properties on the server, and fetches
   * the updated object on success, closes the Manufacturing Location selection window.
   * @param manufacturingLocation
   */
  function handleConfirmManufacturingLocation(
    manufacturingLocation: ManufacturingLocation
  ) {
    if (id && homeLink) {
      dispatch(
        updateHomeLinkThunk({
          id,
          info: {
            inventoryCenterId: manufacturingLocation.inventoryCenterId,
            mCellId: manufacturingLocation.mCellId,
            mCellCollectionId: manufacturingLocation.mCellCollectionId,
            title: homeLink.title,
            linkUrl: homeLink.linkUrl,
            sortSequence: homeLink.sortSequence,
            homeLinkGroupId: homeLink.homeLinkGroupId,
          },
        })
      );
    }
    setIsManufacturingLocationDialogOpen(false);
  }
  if (homeLink === undefined) {
    return <div>{t('homeLinkNotFound')}</div>;
  }

  return (
    <Page
      title={t('homeLinkDetails')}
      loading={loadingHomeLinks}
      actionComponent={
        <EnhancedButton onClick={handleDeleteHomeLinkClick}>
          {t('deleteHomeLink')}
        </EnhancedButton>
      }
    >
      <Grid sx={{ px: 3, pb: 3 }} container spacing={3}>
        <Grid item xs={12} md={6} container>
          <div style={{ flexGrow: 1, overflow: 'hidden' }}>
            <Typography variant={'caption'} color={'textSecondary'}>
              {t('location')}
            </Typography>
            <div style={{ marginTop: theme.spacing(0.5) }}>
              <LocationChip
                onClick={handleLocationClick}
                mCellId={mCellId}
                inventoryCenterId={inventoryCenterId}
                mCellCollectionId={mCellCollectionId}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} container>
          <div style={{ flexGrow: 1, overflow: 'hidden' }}>
            <Typography variant={'caption'} color={'textSecondary'}>
              {t('title')}
            </Typography>
            <Typography>{title}</Typography>
          </div>
          <div>
            <EditIconButton
              onClick={() => handleDialogOpen('title')}
              tooltip={t('editTitle')}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6} container>
          <div style={{ flexGrow: 1 }}>
            <Typography variant={'caption'} color={'textSecondary'}>
              {t('iconImage')}
            </Typography>
            {homeLink?.imageUrl && (
              <>
                <ImageCard src={homeLink?.imageUrl} height={60} width={60} />
              </>
            )}
          </div>
          <div>
            <EditIconButton
              onClick={handleIconImageAddClick}
              tooltip={t('iconImage')}
            />
            {homeLink?.imageUrl && (
              <IconButton onClick={handleIconImageDeleteClick} size='large'>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6} container>
          <div style={{ flexGrow: 1, overflow: 'hidden' }}>
            <Typography variant={'caption'} color={'textSecondary'}>
              {t(homeLinkOptionToStringKey(homeLink.linkType))}
            </Typography>
            {homeLink?.linkUrl && (
              <Typography
                noWrap
                sx={{
                  wordWrap: 'break-word',
                  width: '30vw',
                }}
              >
                {homeLink?.linkUrl}
              </Typography>
            )}
            {homeLink?.fileUrl && (
              <Typography
                noWrap
                sx={{
                  wordWrap: 'break-word',
                  width: '30vw',
                }}
              >
                {homeLink?.filename}
              </Typography>
            )}
          </div>
          <div>
            {homeLink?.linkUrl && (
              <>
                <EditIconButton
                  onClick={() => handleDialogOpen('linkUrl')}
                  tooltip={homeLink?.linkType}
                />
              </>
            )}
            {homeLink?.fileUrl && (
              <>
                <EditIconButton
                  onClick={handleFileAddClick}
                  tooltip={homeLink?.linkType}
                />
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6} container>
          <div style={{ flexGrow: 1, overflow: 'hidden' }}>
            <Typography variant={'caption'} color={'textSecondary'}>
              {t('sortSequence')}
            </Typography>
            <Typography>{sortSequence}</Typography>
          </div>
          <div>
            <EditIconButton
              onClick={() => handleDialogOpen('sortSequence')}
              tooltip={t('sortSequence')}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6} container>
          <div style={{ flexGrow: 1, overflow: 'hidden' }}>
            <Typography variant={'caption'} color={'textSecondary'}>
              {t('group')}
            </Typography>
            <Typography>{groupName}</Typography>
          </div>
          <div>
            <EditIconButton
              onClick={() => handleDialogOpen('homeLinkGroupId')}
              tooltip={t('editGroup')}
            />
          </div>
        </Grid>
      </Grid>
      <TextInputDialog
        title={t('editTitle')}
        textLabel={t('title')}
        open={!!dialogOpenState.title}
        onConfirm={(v) => handleConfirm('title', v)}
        onCancel={() => handleDialogClose('title')}
        defaultText={homeLink?.title ? homeLink.title : ``}
        confirmDisabled={loadingHomeLinks}
      />
      <FileUploadDialog
        title={t('iconImageUpload')}
        initialFiles={[]}
        open={isIconImageAdd}
        onConfirm={handleIconImageAddConfirm}
        single={true}
        onCancel={handleIconImageAddClose}
        maxFileSizeBytes={HOME_LINK_IMAGE_MAX_FILE_SIZE_BYTES}
        accept={'image/png, image/jpeg, image/svg'}
      />
      <EnhancedSelectDialog
        title={t('editGroup')}
        open={!!dialogOpenState.homeLinkGroupId}
        data={homeLinkGroupOptions}
        initialValue={groupValue || ''}
        onCancel={() => handleDialogClose('homeLinkGroupId')}
        onConfirm={(v) => handleConfirm('homeLinkGroupId', v)}
      />
      <ManufacturingLocationSelectionDialog
        initialManufacturingLocation={{
          mCellId: mCellId,
          inventoryCenterId: inventoryCenterId,
          mCellCollectionId: mCellCollectionId,
        }}
        open={isManufacturingLocationDialogOpen}
        onConfirm={handleConfirmManufacturingLocation}
        onCancel={handleLocationDialogCancel}
      />
      <TextInputDialog
        title={t('editLinkUrl')}
        textLabel={t('linkUrl')}
        open={!!dialogOpenState.linkUrl}
        onConfirm={(v) => handleConfirm('linkUrl', v)}
        onCancel={() => handleDialogClose('linkUrl')}
        defaultText={homeLink?.linkUrl ? homeLink.linkUrl : ``}
        confirmDisabled={loadingHomeLinks}
      />
      <TextInputDialog
        title={t('editSortSequence')}
        textLabel={t('sortSequence')}
        defaultText={
          homeLink?.sortSequence ? homeLink.sortSequence.toString() : '0'
        }
        open={!!dialogOpenState.sortSequence}
        onConfirm={(v) => handleConfirm('sortSequence', v)}
        onCancel={() => handleDialogClose('sortSequence')}
        allowEmpty
        type={'number'}
        inputProps={inputProps}
      />
      <FileUploadDialog
        title={t('fileUpload')}
        initialFiles={[]}
        open={isFileAdd}
        onConfirm={handleFileAddConfirm}
        onCancel={handleFileAddClose}
        single={true}
        maxFileSizeBytes={HOME_LINK_MAX_FILE_SIZE_BYTES}
      />
      <ConfirmDialog
        title={t('deleteIconImage')}
        message={t('deleteIconImage?', {
          messageImageName: homeLink?.imageName,
        })}
        open={isDeleteIconImageOpen}
        onConfirm={handleIconImageDeleteConfirm}
        onCancel={handleIconImageDeleteClose}
        confirmDisabled={loadingHomeLinks}
      />
      <ConfirmDialog
        title={t('deleteFile')}
        message={t('deleteFile?', { messageFilename: homeLink?.filename })}
        open={isDeleteFileOpen}
        onConfirm={handleFileDeleteConfirm}
        onCancel={handleFileDeleteClose}
        confirmDisabled={loadingHomeLinks}
      />
      <ConfirmDialog
        title={t('deleteHomeLink')}
        message={t('deleteHomeLink?', { messageTitle: homeLink?.title })}
        open={isDeleteDialogOpen}
        onConfirm={handleDeleteHomeLinkConfirm}
        onCancel={handleDeleteDialogClose}
        confirmDisabled={loadingHomeLinks}
      />
    </Page>
  );
};

type DialogState = Partial<Record<keyof HomeLinkInfo, boolean>>;

export default HomeLinkConfigDetailEdit;
