import React from 'react';
import { ButtonProps } from '@mui/material/Button';
import { Button, Tooltip } from '@mui/material';
import { isEmptyOrSpaces } from '../../utils/string-utils';

export type Props = ButtonProps & {
  tooltip?: string;
  target?: string;
};

/**
 * Renders the Material-UI Button with an optional tooltip
 * @param children
 * @param tooltip
 * @param restProps
 * @constructor
 */
const EnhancedButton = ({ children, tooltip, ...restProps }: Props) => {
  if (isEmptyOrSpaces(tooltip)) {
    return (
      <Button
        data-testid='Button'
        size='large'
        variant='contained'
        color='primary'
        {...restProps}
      >
        {children}
      </Button>
    );
  }
  return (
    <Tooltip data-testid='Tooltip' title={tooltip as any}>
      <span>
        <Button size='large' variant='contained' color='primary' {...restProps}>
          {children}
        </Button>
      </span>
    </Tooltip>
  );
};

export default EnhancedButton;
