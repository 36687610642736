import { grey } from '@mui/material/colors';
import {
  MCellAlertLevel,
  mCellAlertLevels,
  MCellAlertSort,
  mCellAlertSorts,
} from './types';
import { TFunction } from 'i18next';
import amber from '@mui/material/colors/amber';
import red from '@mui/material/colors/red';

export const IS_KEY_BACKGROUND_COLOR = grey[200];
export const IS_KEY_BORDER_COLOR = grey[500];
export const ELEVATED_COLOR = amber[500];
export const HIGH_COLOR = red[500];

/**
 * Gets the string key of an MCell Alert level
 * @param level
 */
export const mCellAlertLevelToStringKey = (level: MCellAlertLevel): string => {
  switch (level) {
    case 'Elevated':
      return 'elevated';
    case 'High':
      return 'high';
  }
};

/**
 * Gets the select options for MCell Alert levels
 * @param t
 */
export const getMCellAlertLevelOptions = (
  t: TFunction,
): { label: string; value: MCellAlertLevel }[] => {
  return mCellAlertLevels.map((l) => ({
    label: t(mCellAlertLevelToStringKey(l)),
    value: l,
  }));
};

/**
 * Gets the string key of an MCell Alert sort
 * @param sort
 */
export const mCellAlertSortToStringKey = (sort: MCellAlertSort): string => {
  switch (sort) {
    case 'Alphabetical':
      return 'alphabetical';
    case 'RankScore':
      return 'rankScore';
  }
};

/**
 * Gets the select options for MCell Alert sorts
 * @param t
 */
export const getMCellAlertSortOptions = (
  t: TFunction,
): { label: string; value: MCellAlertSort }[] => {
  return mCellAlertSorts.map((s) => ({
    label: t(mCellAlertSortToStringKey(s)),
    value: s,
  }));
};
