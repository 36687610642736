import { HomeLinkDto, HomeLinkGroupDto } from './types';
import { LoadingStatus } from '../../api/app.types';
import { createAction, createSlice } from '@reduxjs/toolkit';
import { createAsyncThunkWithError } from '../../redux/utils';
import {
  clearFile,
  clearImage,
  createHomeLink,
  createHomeLinkGroup,
  deleteHomeLink,
  deleteHomeLinkGroup,
  getHomeLink,
  getHomeLinkGroup,
  getHomeLinkGroups,
  getHomeLinks,
  updateFile,
  updateHomeLink,
  updateHomeLinkGroup,
  updateImage,
} from './api';
import { RootState } from '../../store';

export type HomeLinkState = {
  homeLinksStatus: LoadingStatus;
  homeLinks: HomeLinkDto[];
  homeLinkGroupsStatus: LoadingStatus;
  homeLinkGroups: HomeLinkGroupDto[];
};

export const initialState: HomeLinkState = {
  homeLinksStatus: 'idle',
  homeLinks: [],
  homeLinkGroupsStatus: 'idle',
  homeLinkGroups: [],
};

/**
 * Action to delete a  Home Link Group in the store
 */
export const deleteHomeLinkGroupAction = createAction<string>(
  'home/deleteHomeLinkGroupAction'
);

/**
 * Thunk for updating a Home Link Image
 */
export const updateImageThunk = createAsyncThunkWithError(
  'home/updateImageThunk',
  updateImage,
  'errorUpdatingImage'
);

/**
 * Thunk for updating a Home Link File
 */
export const updateFileThunk = createAsyncThunkWithError(
  'home/updateFileThunk',
  updateFile,
  'errorUpdatingFile'
);

/**
 * Thunk for Deleting a Home Link Image
 */
export const deleteImageThunk = createAsyncThunkWithError(
  'home/deleteImageThunk',
  clearImage,
  'errorDeletingImage'
);

/**
 * Thunk for Deleting a Home Link File
 */
export const deleteFileThunk = createAsyncThunkWithError(
  'home/deleteFileThunk',
  clearFile,
  'errorDeletingFile'
);

/**
 * Thunk for updating a Home Link
 */
export const updateHomeLinkThunk = createAsyncThunkWithError(
  'home/updateHomeLinkThunk',
  updateHomeLink,
  'errorUpdatingHomeLink'
);

/**
 * Thunk that calls API to get Home Links
 */
export const getHomeLinksThunk = createAsyncThunkWithError(
  'home/getHomeLinksThunk',
  getHomeLinks,
  'errorFetchingHomeLinks'
);

/**
 * Thunk for creating a Home Link from server and saving it to the store
 */
export const createHomeLinkThunk = createAsyncThunkWithError(
  'home/createHomeLinkThunk',
  createHomeLink,
  'errorCreatingHomeLink'
);

/**
 * Thunk for Deleting a Home Link from server and saving it to the store
 */
export const deleteHomeLinkThunk = createAsyncThunkWithError(
  'home/deleteHomeLinkThunk',
  deleteHomeLink,
  'errorDeletingHomeLink'
);

/**
 * Thunk that calls API to get a Home Link by id
 */
export const getHomeLinkThunk = createAsyncThunkWithError(
  'home/getHomeLinkThunk',
  getHomeLink,
  'errorFetchingHomeLink'
);

/**
 * Thunk that calls API to get a Home Link Group by id
 */
export const getHomeLinkGroupThunk = createAsyncThunkWithError(
  'home/getHomeLinkGroupThunk',
  getHomeLinkGroup,
  'errorFetchingHomeLinkGroup'
);

/**
 * Thunk that calls API to get Home Link Groups
 */
export const getHomeLinkGroupsThunk = createAsyncThunkWithError(
  'home/getHomeLinkGroupsThunk',
  getHomeLinkGroups,
  'errorFetchingHomeLinkGroups'
);

/**
 * Thunk for creating a Home Link Group from server and saving it to the store
 */
export const createHomeLinkGroupThunk = createAsyncThunkWithError(
  'home/createHomeLinkGroupThunk',
  createHomeLinkGroup,
  'errorCreatingHomeLinkGroup'
);

/**
 * Thunk for updating a Home Link Group from server and saving it to the store
 */
export const updateHomeLinkGroupThunk = createAsyncThunkWithError(
  'home/updateHomeLinkGroupThunk',
  updateHomeLinkGroup,
  'errorUpdatingHomeLinkGroup'
);

/**
 * Thunk for Deleting a Home Link Group from server and saving it to the store
 */
export const deleteHomeLinkGroupThunk = createAsyncThunkWithError(
  'home/deleteHomeLinkGroupsThunk',
  async (id: string, { dispatch }) => {
    dispatch(deleteHomeLinkGroupAction(id));
    return await deleteHomeLinkGroup(id);
  },
  'errorDeletingHomeLinkGroup'
);

export const homeSliceReducer = createSlice({
  name: 'home',
  initialState,
  reducers: {
    deleteHomeLinkGroupAction: (state, action) => {
      const id = action.payload;
      state.homeLinkGroups = state.homeLinkGroups.filter((s) => s.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteImageThunk.pending, (state) => {
        state.homeLinksStatus = 'loading';
      })
      .addCase(deleteImageThunk.fulfilled, (state, { payload }) => {
        state.homeLinksStatus = 'succeeded';
        addOrUpdateHomeLink(state, payload);
      })
      .addCase(deleteImageThunk.rejected, (state) => {
        state.homeLinksStatus = 'failed';
      })
      .addCase(deleteFileThunk.pending, (state) => {
        state.homeLinksStatus = 'loading';
      })
      .addCase(deleteFileThunk.fulfilled, (state, { payload }) => {
        state.homeLinksStatus = 'succeeded';
        addOrUpdateHomeLink(state, payload);
      })
      .addCase(deleteFileThunk.rejected, (state) => {
        state.homeLinksStatus = 'failed';
      })
      .addCase(getHomeLinksThunk.pending, (state) => {
        state.homeLinksStatus = 'loading';
      })
      .addCase(getHomeLinksThunk.fulfilled, (state, { payload }) => {
        state.homeLinksStatus = 'succeeded';
        state.homeLinks = payload;
      })
      .addCase(getHomeLinksThunk.rejected, (state) => {
        state.homeLinksStatus = 'failed';
      })
      .addCase(createHomeLinkThunk.pending, (state) => {
        state.homeLinksStatus = 'loading';
      })
      .addCase(createHomeLinkThunk.fulfilled, (state, { payload }) => {
        state.homeLinksStatus = 'succeeded';
        state.homeLinks.push(payload);
      })
      .addCase(createHomeLinkThunk.rejected, (state) => {
        state.homeLinksStatus = 'failed';
      })
      .addCase(deleteHomeLinkThunk.pending, (state) => {
        state.homeLinksStatus = 'loading';
      })
      .addCase(deleteHomeLinkThunk.fulfilled, (state, { payload }) => {
        state.homeLinksStatus = 'succeeded';
        const deleteIndex = state.homeLinks.findIndex((d) => d.id === payload);
        if (deleteIndex > -1) {
          state.homeLinks.splice(deleteIndex, 1);
        }
      })
      .addCase(deleteHomeLinkThunk.rejected, (state) => {
        state.homeLinksStatus = 'failed';
      })
      .addCase(getHomeLinkThunk.pending, (state) => {
        state.homeLinksStatus = 'loading';
      })
      .addCase(getHomeLinkThunk.fulfilled, (state, { payload }) => {
        state.homeLinksStatus = 'succeeded';
        addOrUpdateHomeLink(state, payload);
      })
      .addCase(getHomeLinkThunk.rejected, (state) => {
        state.homeLinksStatus = 'failed';
      })
      .addCase(updateHomeLinkThunk.pending, (state) => {
        state.homeLinksStatus = 'loading';
      })
      .addCase(updateHomeLinkThunk.fulfilled, (state, { payload }) => {
        state.homeLinksStatus = 'succeeded';
        addOrUpdateHomeLink(state, payload);
      })
      .addCase(updateHomeLinkThunk.rejected, (state) => {
        state.homeLinksStatus = 'failed';
      })
      .addCase(updateImageThunk.pending, (state) => {
        state.homeLinksStatus = 'loading';
      })
      .addCase(updateImageThunk.fulfilled, (state, { payload }) => {
        state.homeLinksStatus = 'succeeded';
        addOrUpdateHomeLink(state, payload);
      })
      .addCase(updateImageThunk.rejected, (state) => {
        state.homeLinksStatus = 'failed';
      })
      .addCase(updateFileThunk.pending, (state) => {
        state.homeLinksStatus = 'loading';
      })
      .addCase(updateFileThunk.fulfilled, (state, { payload }) => {
        state.homeLinksStatus = 'succeeded';
        addOrUpdateHomeLink(state, payload);
      })
      .addCase(updateFileThunk.rejected, (state) => {
        state.homeLinksStatus = 'failed';
      })
      .addCase(getHomeLinkGroupThunk.pending, (state) => {
        state.homeLinkGroupsStatus = 'loading';
      })
      .addCase(getHomeLinkGroupThunk.fulfilled, (state, { payload }) => {
        state.homeLinkGroupsStatus = 'succeeded';
        addOrUpdateHomeLinkGroup(state, payload);
      })
      .addCase(getHomeLinkGroupThunk.rejected, (state) => {
        state.homeLinkGroupsStatus = 'failed';
      })
      .addCase(getHomeLinkGroupsThunk.pending, (state) => {
        state.homeLinkGroupsStatus = 'loading';
      })
      .addCase(getHomeLinkGroupsThunk.fulfilled, (state, { payload }) => {
        state.homeLinkGroupsStatus = 'succeeded';
        state.homeLinkGroups = payload;
      })
      .addCase(getHomeLinkGroupsThunk.rejected, (state) => {
        state.homeLinkGroupsStatus = 'failed';
      })
      .addCase(createHomeLinkGroupThunk.pending, (state) => {
        state.homeLinkGroupsStatus = 'loading';
      })
      .addCase(createHomeLinkGroupThunk.fulfilled, (state, { payload }) => {
        state.homeLinkGroupsStatus = 'succeeded';
        state.homeLinkGroups.push(payload);
      })
      .addCase(createHomeLinkGroupThunk.rejected, (state) => {
        state.homeLinkGroupsStatus = 'failed';
      })
      .addCase(updateHomeLinkGroupThunk.pending, (state) => {
        state.homeLinkGroupsStatus = 'loading';
      })
      .addCase(updateHomeLinkGroupThunk.fulfilled, (state, { payload }) => {
        state.homeLinkGroupsStatus = 'succeeded';
        addOrUpdateHomeLinkGroup(state, payload);
      })
      .addCase(updateHomeLinkGroupThunk.rejected, (state) => {
        state.homeLinkGroupsStatus = 'failed';
      })
      .addCase(deleteHomeLinkGroupThunk.pending, (state) => {
        state.homeLinkGroupsStatus = 'loading';
      })
      .addCase(deleteHomeLinkGroupThunk.fulfilled, (state) => {
        state.homeLinkGroupsStatus = 'succeeded';
      })
      .addCase(deleteHomeLinkGroupThunk.rejected, (state) => {
        state.homeLinkGroupsStatus = 'failed';
      });
  },
});

/**
 * Function to add or update a Home Link in the store
 * @param state
 * @param homeLink
 */
export const addOrUpdateHomeLink = (
  state: HomeLinkState,
  homeLink: HomeLinkDto
) => {
  const index = state.homeLinks.findIndex((h) => h.id === homeLink.id);
  if (index >= 0) {
    state.homeLinks[index] = homeLink;
  } else {
    state.homeLinks.push(homeLink);
  }
};

/**
 * Function to add or update a Home Link Group in the store
 * @param state
 * @param homeLinkGroup
 */
export const addOrUpdateHomeLinkGroup = (
  state: HomeLinkState,
  homeLinkGroup: HomeLinkGroupDto
) => {
  const index = state.homeLinkGroups.findIndex(
    (h) => h.id === homeLinkGroup.id
  );
  if (index >= 0) {
    state.homeLinkGroups[index] = homeLinkGroup;
  } else {
    state.homeLinkGroups.push(homeLinkGroup);
  }
};

/**
 * Selector to retrieve Home Links
 */
export const selectHomeLinks = (state: RootState) => state.home.homeLinks;

/**
 * Selector to retrieve a Home Link by id
 * @param id
 */
export const selectHomeLink = (id: string | undefined) => (state: RootState) =>
  state.home.homeLinks.find((x) => x.id === id);

/**
 * Selector to determine whether Home Links are loading
 * @param state
 */
export const selectLoadingHomeLinks = (state: RootState) =>
  state.home.homeLinksStatus === 'loading';

/**
 * Selector to retrieve Home Link Groups
 * @param state
 */
export const selectHomeLinkGroups = (state: RootState) =>
  state.home.homeLinkGroups;

/**
 * Selector to retrieve a Home Link Group by id
 * @param id
 */
export const selectHomeLinkGroup = (id: string | undefined) => (
  state: RootState
) => state.home.homeLinkGroups.find((x) => x.id === id);

/**
 * Selector to determine whether Home Link Groups are loading
 * @param state
 */
export const selectLoadingHomeLinkGroups = (state: RootState) =>
  state.home.homeLinkGroupsStatus === 'loading';

export default homeSliceReducer.reducer;
