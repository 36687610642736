import React, { ReactNode, useState } from 'react';
import { EMPTY_GUID } from '../../../utils/string-utils';
import { Box, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ManufacturingLocationSelectionDialog from './ManufacturingLocationSelectionDialog';
import { ManufacturingLocation } from '../types';
import { useSelector } from 'react-redux';
import { selectLoadingProfile } from '../../profile/profileSlice';
import EnhancedButton from '../../common/EnhancedButton';
import cx from 'classnames';
import { useMCellSelector } from '../../profile/utils';

type Props = {
  manufacturingLocation: ManufacturingLocation;
  onManufacturingLocationChange: (
    manufacturingLocation: ManufacturingLocation
  ) => void;
  children: ReactNode;
  className?: string;
  sx?: SxProps;
  warningClassname?: string;
  allowRollupCells?: boolean;
  allowMCellCollection?: boolean;
  allowInventoryCenter?: boolean;
};

/**
 * Wrapper component for views that require a Manufacturing Location to be set.
 * By default, it will display a warning message and a button to set the Manufacturing
 * Location if a regular MCell is not set. Can be configured to allow Rollup Cells,
 * MCell Collections, and Inventory Centers.
 * Warns user if the following conditions are true:
 * 1. MCell is required and has not been set
 * 2. MCell is a rollup cell but rollup cells are not allowed
 * Provides control for setting the ML. Once set, it displays the children components.
 * @param manufacturingLocation
 * @param onManufacturingLocationChange
 * @param children
 * @param className
 * @param sx
 * @param warningClassname
 * @param includeRollupCells
 * @param allowMCellCollection
 * @param allowInventoryCenter
 * @constructor
 */
const ManufacturingLocationValidationContainer = ({
  manufacturingLocation,
  onManufacturingLocationChange,
  children,
  className,
  sx,
  warningClassname,
  allowRollupCells,
  allowMCellCollection,
  allowInventoryCenter,
}: Props) => {
  const { t } = useTranslation();
  const {
    inventoryCenterId,
    mCellId,
    mCellCollectionId,
  } = manufacturingLocation;
  const [
    isManufacturingLocationDialogOpen,
    setIsManufacturingLocationDialogOpen,
  ] = useState(false);
  const loadingProfile = useSelector(selectLoadingProfile);
  const mCell = useMCellSelector(mCellId);
  const mCellRequiredNotSet =
    mCell === undefined && !allowMCellCollection && !allowInventoryCenter;
  const rollupNotSupported =
    !allowRollupCells && mCell !== undefined && mCell.isRollup;
  const mCellCollectionSelected = mCellCollectionId !== EMPTY_GUID;
  const showChildren =
    (!mCellRequiredNotSet && !rollupNotSupported) ||
    (allowMCellCollection && mCellCollectionSelected && !rollupNotSupported) ||
    (allowInventoryCenter && !rollupNotSupported);

  /**
   * Notifies parent and closes dialog when user confirms Manufacturing Location
   * @param manufacturingLocation
   */
  function handleManufacturingLocationConfirm(
    manufacturingLocation: ManufacturingLocation
  ) {
    onManufacturingLocationChange(manufacturingLocation);
    setIsManufacturingLocationDialogOpen(false);
  }

  return (
    <>
      {mCellRequiredNotSet && (
        <Box className={cx(className, warningClassname)} sx={sx}>
          <Typography>{t('noWorkCellHasBeenSelected')}</Typography>
          <EnhancedButton
            sx={{ my: 2 }}
            onClick={() => setIsManufacturingLocationDialogOpen(true)}
          >
            {t('updateSettings')}
          </EnhancedButton>
        </Box>
      )}
      {rollupNotSupported && (
        <Box className={cx(className, warningClassname)} sx={sx}>
          <Typography>{t('rollUpWorkCellsAreNotSupportedHere')}</Typography>
          <EnhancedButton
            sx={{ my: 2 }}
            onClick={() => setIsManufacturingLocationDialogOpen(true)}
          >
            {t('updateSettings')}
          </EnhancedButton>
        </Box>
      )}
      {showChildren && <>{children}</>}
      <ManufacturingLocationSelectionDialog
        initialManufacturingLocation={{
          mCellId,
          inventoryCenterId,
          mCellCollectionId,
        }}
        open={isManufacturingLocationDialogOpen}
        onConfirm={handleManufacturingLocationConfirm}
        onCancel={() => setIsManufacturingLocationDialogOpen(false)}
        confirmDisabled={loadingProfile}
        disableMCellCollection={!allowMCellCollection}
        includeRollupCells={allowRollupCells}
      />
    </>
  );
};

export default ManufacturingLocationValidationContainer;
