import React from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, Snackbar, SnackbarContent } from '@mui/material';
import { amber, green, blue } from '@mui/material/colors';
import { AlertLevel } from '../app/types';
import cx from 'classnames';
import {
  CloseIcon,
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from './icons';

const PREFIX = 'AlertSnackbar';

const styles = {
  content: `${PREFIX}-content`,
  error: `${PREFIX}-error`,
  warn: `${PREFIX}-warn`,
  info: `${PREFIX}-info`,
  success: `${PREFIX}-success`,
  messageArea: `${PREFIX}-messageArea`,
  icon: `${PREFIX}-icon`,
};

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  [`& .${styles.content}`]: {
    background: theme.palette.error.dark,
  },

  [`& .${styles.error}`]: {
    background: theme.palette.error.dark,
  },

  [`& .${styles.warn}`]: {
    background: amber[700],
  },

  [`& .${styles.info}`]: {
    background: blue[500],
  },

  [`& .${styles.success}`]: {
    background: green[600],
  },

  [`& .${styles.messageArea}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${styles.icon}`]: {
    marginRight: theme.spacing(1),
  },
}));

export type Props = {
  open: boolean;
  onClose: () => any;
  message: string;
  alertLevel?: AlertLevel;
  autoHideDuration?: number;
  action?: React.ReactNode;
};

/**
 * AlertSnackbar is a functional component that displays a notification alert in a snackbar.
 *
 * @param {object} Props - The properties passed to the component.
 * @param {boolean} Props.open - A boolean indicating whether the snackbar is open.
 * @param {function} Props.onClose - A function to be called when the snackbar is closed.
 * @param {string} Props.message - The message to be displayed in the snackbar.
 * @param {string} [Props.alertLevel='error'] - The level of the alert. It can be 'error', 'warn', 'info', or 'success'.
 * @param {number} [Props.autoHideDuration=10000] - The duration in milliseconds for which the snackbar is displayed. Default is 10000.
 * @param {React.ReactNode} [Props.action] - The action to be displayed in the snackbar.
 *
 * @returns {JSX.Element} The AlertSnackbar component.
 */
const AlertSnackbar = ({
  open,
  onClose,
  message,
  alertLevel = 'error',
  autoHideDuration = 10000,
  action,
}: Props): JSX.Element => {
  return (
    <StyledSnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={(_, r) => (r === 'clickaway' ? '' : onClose())}
    >
      <SnackbarContent
        className={cx({
          [styles.error]: alertLevel === 'error',
          [styles.warn]: alertLevel === 'warn',
          [styles.info]: alertLevel === 'info',
          [styles.success]: alertLevel === 'success',
        })}
        message={
          <span className={styles.messageArea}>
            {alertLevel === 'error' && <ErrorIcon className={styles.icon} />}
            {alertLevel === 'warn' && (
              <WarningIcon data-testid='WarningIcon' className={styles.icon} />
            )}
            {alertLevel === 'info' && (
              <InfoIcon data-testid='InfoIcon' className={styles.icon} />
            )}
            {alertLevel === 'success' && (
              <SuccessIcon data-testid='SuccessIcon' className={styles.icon} />
            )}
            <span>{message}</span>
          </span>
        }
        action={
          <>
            {action}
            <IconButton onClick={onClose} color={'inherit'} size='large'>
              <CloseIcon />
            </IconButton>
          </>
        }
      />
    </StyledSnackbar>
  );
};

export default AlertSnackbar;
