/**
 * Downloads a blob to a file.
 * @param blob
 * @param filename
 */
export function saveBlobToFile(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
}
