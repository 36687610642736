import {
  CELL_BOARD_ENABLED,
  SCORECARD_ENABLED,
  COATINGS_ENABLED,
  CREWING_GUIDE_ENABLED,
  DOWNTIME_ESCALATION_ENABLED,
  JOB_STATION_ASSIGNMENT_ENABLED,
  JOB_STATIONS_ENABLED,
  REPORT_ENABLED,
  RESOURCE_TRACKING_ENABLED,
  SUPPORT_HIERARCHY_ENABLED,
  User,
  CELL_BOARD_M_CELL_COLLECTION_ID,
  CELL_BOARD_INVENTORY_CENTER_ID,
  CELL_BOARD_M_CELL_ID,
  SCHEDULING_TOOL_ENABLED,
  M_CELL_STATUS_ENABLED,
  BANNER_INFO,
  LSW_ENABLED,
  LMS_ENROLLMENT_ENABLED,
} from './types';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { EMPTY_GUID } from '../../utils/string-utils';
import { ManufacturingLocation, MCell } from '../manufacturingLocation/types';
import {
  selectUserSettings,
  setManufacturingLocationSettingsThunk,
} from './profileSlice';
import { createSelector } from '@reduxjs/toolkit';

/**
 * Returns name from User object
 */
export const formatUserName = (user: User | undefined) => {
  if (user?.name === undefined) {
    return;
  }
  return `${user?.name}`;
};

export const shortenName = (fullName: string) => {
  let result = '';
  const nameParts = fullName.split(' ');
  result = nameParts[0][0];
  if (nameParts.length > 1) {
    result += nameParts[nameParts.length - 1][0];
  }
  return result;
};

/**
 * Hook that returns the current selected manufacturing location
 */
export const useManufacturingLocation = () => {
  const { inventoryCenterId, mCellId, mCellCollectionId } = useSelector(
    selectManufacturingLocation,
  );
  const dispatch = useDispatch<AppDispatch>();
  const mCellCollections = useSelector(
    (state: RootState) => state.manufacturingLocation.mCellCollections,
  );
  const mCellCollection = mCellCollections.find(
    (mc) => mc.id === mCellCollectionId,
  );

  const inventoryCenters = useSelector(
    (state: RootState) => state.manufacturingLocation.inventoryCenters,
  );
  const inventoryCenter = inventoryCenters.find(
    (i) => i.id === inventoryCenterId,
  );

  const mCells = useSelector(
    (state: RootState) => state.manufacturingLocation.mCells,
  );
  const mCell = mCells.find((m) => m.id === mCellId);

  const setManufacturingLocation = (
    manufacturingLocation: ManufacturingLocation,
  ) => {
    dispatch(setManufacturingLocationSettingsThunk(manufacturingLocation));
  };

  return {
    inventoryCenter,
    mCell,
    mCellCollection,
    inventoryCenterId,
    mCellId: mCellId,
    mCellCollectionId,
    setManufacturingLocation,
  };
};

/**
 * Selector to extract the MCell collection ID from user settings.
 * Uses a fallback default value represented by `EMPTY_GUID` if not present.
 * @param settings - User settings object from which the MCell collection ID is extracted.
 * @returns The MCell collection ID parsed from the settings or a default GUID.
 */
const selectMCellCollectionId = createSelector(
  [selectUserSettings],
  (settings) => {
    const mCellCollectionIdSetting =
      settings[CELL_BOARD_M_CELL_COLLECTION_ID] || JSON.stringify(EMPTY_GUID);
    return JSON.parse(mCellCollectionIdSetting);
  },
);

/**
 * Selector to extract the inventory center ID from user settings.
 * Uses a fallback default value represented by `EMPTY_GUID` if not present.
 * @param settings - User settings object from which the inventory center ID is extracted.
 * @returns The inventory center ID parsed from the settings or a default GUID.
 */
const selectInventoryCenterId = createSelector(
  [selectUserSettings],
  (settings) => {
    const inventoryCenterIdSetting =
      settings[CELL_BOARD_INVENTORY_CENTER_ID] || JSON.stringify(EMPTY_GUID);
    return JSON.parse(inventoryCenterIdSetting);
  },
);

/**
 * Selector to extract the MCell ID from user settings.
 * Uses a fallback default value represented by `EMPTY_GUID` if not present.
 * @param settings - User settings object from which the MCell ID is extracted.
 * @returns The MCell ID parsed from the settings or a default GUID.
 */
const selectMCellId = createSelector([selectUserSettings], (settings) => {
  const mCellIdSetting =
    settings[CELL_BOARD_M_CELL_ID] || JSON.stringify(EMPTY_GUID);
  return JSON.parse(mCellIdSetting);
});

/**
 * Composite selector that aggregates MCell collection ID, inventory center ID,
 * and MCell ID into a single object.
 * @returns An object containing the MCell collection ID, inventory center ID,
 * and MCell ID.
 */
export const selectManufacturingLocation = createSelector(
  [selectMCellCollectionId, selectInventoryCenterId, selectMCellId],
  (mCellCollectionId, inventoryCenterId, mCellId) => ({
    mCellCollectionId,
    inventoryCenterId,
    mCellId,
  }),
);

/**
 * Hook that returns the MCell for a given mCellId. Returns undefined if not found.
 * @param mCellId
 */
export const useMCellSelector = (mCellId: string): MCell | undefined => {
  const mCells = useSelector(
    (state: RootState) => state.manufacturingLocation.mCells,
  );
  const mCell = mCells.find((m) => m.id === mCellId);
  return mCell!;
};

/**
 * Hook that returns the current state of the global settings
 */
export const useGlobalSettings = () => {
  const cellBoardEnabled = JSON.parse(
    useSelector(
      (state: RootState) => state.profile.userSetting[CELL_BOARD_ENABLED],
    ) || 'false',
  );
  const reportEnabled = JSON.parse(
    useSelector(
      (state: RootState) => state.profile.userSetting[REPORT_ENABLED],
    ) || 'false',
  );
  const resourceTrackingEnabled = JSON.parse(
    useSelector(
      (state: RootState) =>
        state.profile.userSetting[RESOURCE_TRACKING_ENABLED],
    ) || 'false',
  );
  const crewingGuideEnabled = JSON.parse(
    useSelector(
      (state: RootState) => state.profile.userSetting[CREWING_GUIDE_ENABLED],
    ) || 'false',
  );
  const supportHierarchyEnabled = JSON.parse(
    useSelector(
      (state: RootState) =>
        state.profile.userSetting[SUPPORT_HIERARCHY_ENABLED],
    ) || 'false',
  );
  const schedulingToolEnabled = JSON.parse(
    useSelector(
      (state: RootState) => state.profile.userSetting[SCHEDULING_TOOL_ENABLED],
    ) || 'false',
  );
  const lswEnabled = JSON.parse(
    useSelector((state: RootState) => state.profile.userSetting[LSW_ENABLED]) ||
      'false',
  );
  const coatingsEnabled = JSON.parse(
    useSelector(
      (state: RootState) => state.profile.userSetting[COATINGS_ENABLED],
    ) || 'false',
  );
  const downtimeEscalationEnabled = JSON.parse(
    useSelector(
      (state: RootState) =>
        state.profile.userSetting[DOWNTIME_ESCALATION_ENABLED],
    ) || 'false',
  );
  const jobStationEnabled = JSON.parse(
    useSelector(
      (state: RootState) => state.profile.userSetting[JOB_STATIONS_ENABLED],
    ) || 'false',
  );
  const jobStationAssignmentEnabled = JSON.parse(
    useSelector(
      (state: RootState) =>
        state.profile.userSetting[JOB_STATION_ASSIGNMENT_ENABLED],
    ) || 'false',
  );
  const scorecardEnabled = JSON.parse(
    useSelector(
      (state: RootState) => state.profile.userSetting[SCORECARD_ENABLED],
    ) || 'false',
  );
  const mCellStatusEnabled = JSON.parse(
    useSelector(
      (state: RootState) => state.profile.userSetting[M_CELL_STATUS_ENABLED],
    ) || 'false',
  );
  const bannerInfo = JSON.parse(
    useSelector((state: RootState) => state.profile.userSetting[BANNER_INFO]) ||
      '{}',
  );
  const lmsEnrollmentEnabled = JSON.parse(
    useSelector(
      (state: RootState) => state.profile.userSetting[LMS_ENROLLMENT_ENABLED],
    ) || 'false',
  );

  return {
    cellBoardEnabled,
    reportEnabled,
    resourceTrackingEnabled,
    crewingGuideEnabled,
    supportHierarchyEnabled,
    schedulingToolEnabled,
    lswEnabled,
    coatingsEnabled,
    downtimeEscalationEnabled,
    jobStationsEnabled: jobStationEnabled,
    jobStationAssignmentEnabled,
    scorecardEnabled,
    mCellStatusEnabled,
    bannerInfo,
    lmsEnrollmentEnabled,
  };
};
