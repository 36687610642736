import { TFunction } from 'i18next';
import qs from 'qs';
import { green, red, yellow } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import {
  InterestProfile,
  InterestProfileInfo,
  MCellEmployee,
  Shift,
  MCellStatusLevel,
  WorkSchedule,
  AgeRestriction,
  ageRestrictions,
  WeightRestriction,
  weightRestrictions,
  JobStation,
  MCellEmployeeInMCell,
} from './types';
import { ErrorMessage } from '../app/utils';
import { DemandManagementStatusAutomationMode } from './types';
import { JobFunction, JobFunctionTag } from '../mCellEmployee/types';
import { useSelector } from 'react-redux';
import {
  selectJobFunctions,
  selectJobFunctionTags,
} from '../mCellEmployee/mCellEmployeeSlice';
import { getAbsoluteNavigationPaths } from '../../utils/navigation';
import { RootState } from '../../store';
import { OptionValue } from '../common/EnhancedSelect';
import { SupportGroupMemberDto } from '../supportHierarchy/types';
import { selectFilteredJobStations } from './resourceTrackingSlice';
import moment from 'moment';
export const TOP_M_CELLS_COUNT = 5;

export const M_CELL_EMPLOYEE_LS_KEY = 'ccc.mCellEmployee';
export const M_CELL_LOGIN_TIME_LS_KEY = 'ccc.mCellLoginTime';
export const M_CELL_LOGIN_TIMEOUT_MS = 300000; // five minutes
export const M_CELL_LOGIN_LOGOUT_WARNING_MS = 60000; // one minute

/**
 * Updates MCell Employee login time to indicate activity
 */
export const mCellLoginUpdate = () => {
  const now = moment().toISOString();
  localStorage.setItem(M_CELL_LOGIN_TIME_LS_KEY, now);
};

/**
 * Saves logged in MCell Employee info in localstorage
 * @param mCellEmployee
 */
export const mCellLogin = (mCellEmployee: MCellEmployee) => {
  const now = moment().toISOString();
  localStorage.setItem(M_CELL_EMPLOYEE_LS_KEY, JSON.stringify(mCellEmployee));
  localStorage.setItem(M_CELL_LOGIN_TIME_LS_KEY, now);
};

/**
 * Removes logged in MCell Employee info from localstorage
 */
export const mCellLogout = () => {
  localStorage.removeItem(M_CELL_EMPLOYEE_LS_KEY);
  localStorage.removeItem(M_CELL_LOGIN_TIME_LS_KEY);
};

/**
 * Hook that returns the currently logged in MCell Employee and time (ms) remaining till logout.
 * Additionally, auto logs out user after the timeout period
 */
export const useMCellLogin = (): [
  MCellEmployee | undefined,
  number | undefined,
] => {
  const [trigger, setTrigger] = useState(false);
  /**
   * Effect to trigger the checking of logged in MCell employee every 15 secs
   */
  useEffect(() => {
    const timer = setTimeout(() => setTrigger(!trigger), 15000);
    return () => clearInterval(timer);
  }, [trigger]);

  const mCellEmployeeString = localStorage.getItem(M_CELL_EMPLOYEE_LS_KEY);
  if (!mCellEmployeeString) {
    return [undefined, undefined];
  }
  const timeString = localStorage.getItem(M_CELL_LOGIN_TIME_LS_KEY);
  if (!timeString) {
    return [undefined, undefined];
  }
  const now = moment();
  const loginTime = moment(timeString);
  const msSinceLogin = now.diff(loginTime);
  const msTillLogout = M_CELL_LOGIN_TIMEOUT_MS - msSinceLogin;
  if (msSinceLogin > M_CELL_LOGIN_TIMEOUT_MS) {
    mCellLogout();
    return [undefined, undefined];
  }

  return [JSON.parse(mCellEmployeeString), msTillLogout];
};

/**
 * Converts an InterestProfile object to an InterestProfileInfo object for
 * use in update calls.
 * @param interestProfile
 */
export const interestProfileToInterestProfileInfo = (
  interestProfile: InterestProfile,
): InterestProfileInfo => {
  return {
    preferredWorkSchedule: interestProfile.preferredWorkSchedule,
    interestIds: interestProfile.interestProfileEmployeeInterest.map(
      (ipe) => ipe.employeeInterest.id,
    ),
    crossTrainingDepartmentIds:
      interestProfile.interestProfilePlanningDepartment.map(
        (ipd) => ipd.planningDepartment.id,
      ),
    mCellEmployeeId: interestProfile.mCellEmployee.id,
  };
};

/**
 * Gets a work schedule to label key
 * @param workSchedule
 */
export const workScheduleToStringKey = (workSchedule: WorkSchedule): string => {
  switch (workSchedule) {
    case 'FullTime':
      return '40hrs';
    case 'Overtime':
      return 'greaterThan40';
    case 'PartTime':
      return 'lessThan40';
    default:
      return '';
  }
};

export type WorkScheduleOption = {
  label: string;
  value: WorkSchedule;
};

/**
 * Gets the work schedule select options
 * @param t
 */
export const getWorkScheduleOptions = (t: TFunction): WorkScheduleOption[] => {
  return [
    { label: t(workScheduleToStringKey('PartTime')), value: 'PartTime' },
    { label: t(workScheduleToStringKey('FullTime')), value: 'FullTime' },
    { label: t(workScheduleToStringKey('Overtime')), value: 'Overtime' },
  ];
};

/**
 * Generates the path to the Crewing Guide path
 * @param mCellId
 * @param inventoryCenterId
 */
export const getCrewingGuidePath = (
  mCellId: string,
  inventoryCenterId: string,
) => {
  const { crewingGuideXRefPath } = getAbsoluteNavigationPaths();
  const filter = {
    mCellId,
    inventoryCenterId,
  };
  const queryString = qs.stringify(filter, { addQueryPrefix: true });
  return crewingGuideXRefPath + queryString;
};

/**
 * Gets MCell Status Level label key
 * @param status
 */
export const mCellStatusLevelToStringKey = (
  status: MCellStatusLevel,
): string => {
  switch (status) {
    case 'Green':
      return 'green';
    case 'None':
      return 'none';
    case 'Red':
      return 'red';
    case 'Yellow':
      return 'yellow';
  }
};

/**
 * Gets the color associated with an MCell Status
 * @param status
 */
export const mCellStatusLevelToColor = (status: MCellStatusLevel): string => {
  switch (status) {
    case 'Yellow':
      return yellow[500];
    case 'Red':
      return red[500];
    case 'None':
      return '';
    case 'Green':
      return green[500];
  }
};

type StatusWeight = {
  [key in MCellStatusLevel]: number;
};
const statusWeights: StatusWeight = {
  None: 1,
  Green: 2,
  Yellow: 3,
  Red: 4,
};

/**
 * Sort compare function for MCell Status Level
 * @param a
 * @param b
 */
export const compareMCellStatusLevel = (
  a: MCellStatusLevel,
  b: MCellStatusLevel,
) => {
  return statusWeights[a] - statusWeights[b];
};

export const mCellStatuses: MCellStatusLevel[] = [
  'None',
  'Red',
  'Yellow',
  'Green',
];

/**
 * Converts an MCell Shift to its corresponding string key
 * @param shift
 */
export const mCellShiftToStringKey = (shift: Shift) => {
  switch (shift) {
    case 'None':
      return 'none';
    case 'Day':
      return 'day';
    case 'Night':
      return 'night';
    case 'Graveyard':
      return 'graveyard';
    case 'Weekend':
      return 'weekend';
    case 'Unknown':
      return 'unknown';
  }
};

export const mCellShifts: Shift[] = [
  'None',
  'Day',
  'Night',
  'Graveyard',
  'Weekend',
];

export type MCellShiftOption = {
  label: string;
  value: Shift;
};

/**
 * Gets select options for MCell Shifts
 * @param t
 */
export const getMCellShiftOptions = (t: TFunction): MCellShiftOption[] => {
  return mCellShifts.map((s) => ({
    label: t(mCellShiftToStringKey(s)),
    value: s,
  }));
};

/**
 * Combines parts of MCell Employee name and returns full name
 * @param mCellEmployee
 * @param surnameFirst
 * @param withEmpId
 */
export const formatMCellEmployeeName = (
  mCellEmployee: MCellEmployeeInMCell | MCellEmployee,
  surnameFirst = false,
  withEmpId = false,
) =>
  surnameFirst
    ? `${mCellEmployee.lastName}, ${mCellEmployee.firstName}${
        withEmpId ? ` (${mCellEmployee.employeeId})` : ''
      }`
    : `${mCellEmployee.firstName} ${mCellEmployee.lastName}${
        withEmpId ? ` (${mCellEmployee.employeeId})` : ''
      }`;

/**
 * Validates MCell Employee to ensure that it has an Active Directory account
 * @param mCellEmployee
 */
export const validateMCellEmployee = (
  mCellEmployee: MCellEmployee,
): ErrorMessage | null => {
  if (!mCellEmployee.user) {
    return {
      stringKey: 'employeeDoesNotHaveActiveDirectoryAccount',
      props: {
        employeeId: mCellEmployee.employeeId,
        name: `${mCellEmployee.firstName} ${mCellEmployee.lastName}`,
      },
    };
  }

  return null;
};

/**
 * Validates Support Group Member to ensure that it has an Active Directory account
 * @param supportGroupMember
 */
export const validateSupportGroupMember = (
  supportGroupMember?: SupportGroupMemberDto,
): ErrorMessage | null => {
  if (supportGroupMember && supportGroupMember.isNullUser) {
    const employeeName = supportGroupMember.mCellEmployeeNameWithId.replace(
      /\(.*\)/g,
      '',
    );
    const employeeId = supportGroupMember.mCellEmployeeNameWithId
      .split('(')[1]
      .replace(')', '');
    const firstName = employeeName.split(' ')[1];
    const lastName = employeeName.split(' ')[0];
    return {
      stringKey: 'employeeDoesNotHaveActiveDirectoryAccount',
      props: {
        employeeId: employeeId,
        name: `${firstName} ${lastName}`,
      },
    };
  }

  return null;
};

/**
 * Converts a Demand Management Status Automation Mode to its corresponding string key
 * @param mode
 */
export const demandMgmtStatusAutomationModeToStringKey = (
  mode: DemandManagementStatusAutomationMode,
) => {
  switch (mode) {
    case 'EnabledAuto':
      return 'auto';
    case 'EnabledGreen':
      return 'green';
    case 'EnabledYellow':
      return 'yellow';
    case 'EnabledRed':
      return 'red';
    case 'Disabled':
      return 'disabled';
  }
};

export const demandManagementStatusAutomationModes: DemandManagementStatusAutomationMode[] =
  ['EnabledAuto', 'EnabledGreen', 'EnabledYellow', 'EnabledRed', 'Disabled'];

/**
 * Gets the select options of an DemandManagementStatusAutomationMode
 * @param t
 */
export const getDemandManagementStatusAutomationModeOptions = (
  t: TFunction,
): { label: string; value: DemandManagementStatusAutomationMode }[] => {
  return demandManagementStatusAutomationModes.map((m) => ({
    label: t(demandMgmtStatusAutomationModeToStringKey(m)),
    value: m,
  }));
};

/**
 * Converts an ageRestriction to its corresponding string key
 * @param ageRestriction
 */
export const ageRestrictionToStringKey = (
  ageRestriction: AgeRestriction,
): string => {
  switch (ageRestriction) {
    case 'None':
      return 'none';
    case 'Over18':
      return 'over18';
  }
};

/**
 * Gets the select options of an ageRestrictions
 * @param t
 */
export const getAgeRestrictionOptions = (
  t: TFunction,
): { label: string; value: AgeRestriction }[] => {
  return ageRestrictions.map((x) => ({
    label: t(ageRestrictionToStringKey(x)),
    value: x,
  }));
};

/**
 * Converts a weightRestriction to its corresponding string key
 * @param weightRestriction
 */
export const weightRestrictionToStringKey = (
  weightRestriction: WeightRestriction,
): string => {
  switch (weightRestriction) {
    case 'None':
      return 'none';
    case 'Light':
      return 'light';
    case 'Medium':
      return 'medium';
    case 'Heavy':
      return 'heavy';
    case 'VeryHeavy':
      return 'veryHeavy';
  }
};

/**
 * Gets the select options of weightRestrictions
 * @param t
 */
export const getWeightRestrictionOptions = (
  t: TFunction,
): { label: string; value: WeightRestriction }[] => {
  return weightRestrictions.map((x) => ({
    label: t(weightRestrictionToStringKey(x)),
    value: x,
  }));
};

/**
 * Converts a list of Job Stations to a list of Job Station options for select
 * @param jobStations - list of Job Stations to convert
 */
export const getJobStationOptions = (
  jobStations: JobStation[],
): OptionValue[] =>
  jobStations.map((x) => ({
    label: x.title,
    value: x.id,
  }));

/**
 * Hook for getting Job Station options for select
 * @param mCellId - filters options by MCell
 */
export const useJobStationOptions = (mCellId?: string) => {
  const jobStations = useSelector((state: RootState) =>
    selectFilteredJobStations(state, mCellId),
  );

  return getJobStationOptions(jobStations);
};

/**
 * Converts list of Job Functions to a list of Job Function options for select
 */
export const getJobFunctionOptions = (jobFunctions: JobFunction[]) => {
  return jobFunctions.map((x) => ({ label: x.title, value: x.id }));
};

/**
 * Hook for getting Job Function options for select
 */
export const useJobFunctionOptions = () => {
  const jobFunctions = useSelector(selectJobFunctions);
  return getJobFunctionOptions(jobFunctions);
};

/**
 * Converts list of Job Function Tags to a list of unique Job Function Tag options for select
 */
export const getJobFunctionTagOptions = (jobFunctionTags: JobFunctionTag[]) => {
  return jobFunctionTags.map((x) => ({ label: x.title, value: x.id }));
};

/**
 * Hook for getting Job Function Tag options for select
 */
export const useJobFunctionTagOptions = () => {
  const jobFunctionTags = useSelector(selectJobFunctionTags);
  return getJobFunctionTagOptions(jobFunctionTags);
};
