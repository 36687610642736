import axiosInstance from '../../api';
import { ManufacturingLocation, MCell } from '../manufacturingLocation/types';
import {
  DangerousEquipment,
  DangerousEquipmentInfo,
  DemandManagementStatusInventoryCenterConfig,
  DemandManagementStatusInventoryCenterConfigInfo,
  DemandManagementStatusMCellConfig,
  DemandManagementStatusMCellConfigInfo,
  EmployeeInterest,
  EmployeeInterestInfo,
  InterestProfile,
  InterestProfileInfo,
  JobStation,
  JobStationAssignmentDto,
  JobStationAssignmentsSetter,
  JobStationDangerousEquipmentInfo,
  JobStationInfo,
  JobStationPersonalProtectiveEquipmentInfo,
  MCellCapacity,
  MCellCapacityInfo,
  MCellEmployee,
  MCellEmployeeAccountType,
  MCellEmployeeCategory,
  MCellEmployeeFilterValue,
  MCellEmployeeMCellHistory,
  MCellEmployeeRole,
  MCellShiftInfo,
  MCellStatus,
  MCellStatusInfo,
  MCellStatusLevel,
  PersonalProtectiveEquipment,
  PersonalProtectiveEquipmentInfo,
} from './types';

/**
 * API call to get list of Employee Interests
 */
export const getEmployeeInterests = async (): Promise<
  Array<EmployeeInterest>
> => {
  const response = await axiosInstance.get<Array<EmployeeInterest>>(
    '/api/ResourceTracking/employeeInterest',
  );
  return response.data;
};

/**
 * API call to create an Employee Interest
 * @param employeeInterestInfo
 */
export const createEmployeeInterest = async (
  employeeInterestInfo: EmployeeInterestInfo,
): Promise<EmployeeInterest> => {
  const response = await axiosInstance.post(
    '/api/ResourceTracking/employeeInterest',
    employeeInterestInfo,
  );
  return response.data;
};

/**
 * API call to update an Employee Interest
 * @param id
 * @param employeeInterestInfo
 */
export const updateEmployeeInterest = async ({
  id,
  employeeInterestInfo,
}: {
  id: string;
  employeeInterestInfo: EmployeeInterestInfo;
}): Promise<EmployeeInterest> => {
  const response = await axiosInstance.post(
    `/api/ResourceTracking/employeeInterest/${id}/update`,
    employeeInterestInfo,
  );
  return response.data;
};

/**
 * API call to delete an Employee Interest
 * @param id
 */
export const deleteEmployeeInterest = async (id: string) => {
  await axiosInstance.delete(`/api/ResourceTracking/employeeInterest/${id}`);
  return id;
};

/**
 * API call to create an interest profile
 * @param interestProfileCreate
 */
export const createInterestProfile = async (
  interestProfileCreate: InterestProfileInfo,
): Promise<InterestProfile> => {
  const response = await axiosInstance.post(
    '/api/ResourceTracking/interestProfile',
    interestProfileCreate,
  );
  return response.data;
};

/**
 * API call to get Interest Profile
 * @param employeeId
 */
export const getInterestProfile = async (
  employeeId: string,
): Promise<InterestProfile> => {
  const response = await axiosInstance.get(
    '/api/ResourceTracking/interestProfile',
    {
      params: {
        employeeId,
      },
    },
  );
  return response.data;
};

/**
 * API call to update an interest profile
 * @param id
 * @param interestProfileInfo
 */
export const updateInterestProfile = async ({
  id,
  interestProfileInfo,
}: {
  id: string;
  interestProfileInfo: InterestProfileInfo;
}): Promise<InterestProfile> => {
  const response = await axiosInstance.post(
    `/api/ResourceTracking/interestProfile/${id}`,
    interestProfileInfo,
  );
  return response.data;
};

/**
 * API call to get an employee's top MCells
 * @param mCellEmployeeId
 * @param topMCellCount
 */
export const getTopMCells = async ({
  mCellEmployeeId,
  topMCellCount,
}: {
  mCellEmployeeId: string;
  topMCellCount: number | undefined;
}): Promise<MCell[]> => {
  const response = await axiosInstance.get(`/api/ResourceTracking/topMCells`, {
    params: {
      mCellEmployeeId,
      topMCellCount,
    },
  });
  return response.data;
};

/**
 * API call to get MCell Capacities
 * @param inventoryCenterId
 */
export const getMCellCapacities = async (
  inventoryCenterId: string,
): Promise<MCellCapacity[]> => {
  const response = await axiosInstance.get(
    `/api/ResourceTracking/mCellCapacity`,
    {
      params: {
        inventoryCenterId,
      },
    },
  );
  return response.data;
};

/**
 * API call to update an MCell Capacity
 * @param id
 * @param mCellCapacityInfo
 */
export const updateMCellCapacity = async (
  id: string,
  mCellCapacityInfo: MCellCapacityInfo,
): Promise<MCellCapacity> => {
  const response = await axiosInstance.post(
    `/api/ResourceTracking/mCellCapacity/${id}/update`,
    mCellCapacityInfo,
  );
  return response.data;
};

/**
 * API call to get list of MCell Statuses
 * @param params
 */
export const getMCellStatuses = async (params: {
  mCellId: string;
  inventoryCenterId: string;
  mCellCollectionId: string;
}): Promise<MCellStatus[]> => {
  const response = await axiosInstance.get(
    `/api/ResourceTracking/mCellStatus`,
    {
      params,
    },
  );
  return response.data;
};

/**
 * API call to set MCell Status for the Operations team
 * @param id
 * @param status
 */
export const setMCellStatusOperations = async ({
  id,
  status,
}: {
  id: string;
  status: MCellStatusLevel;
}): Promise<MCellStatus> => {
  const response = await axiosInstance.post(
    `/api/ResourceTracking/mCellStatus/${id}/setOperations/${status}`,
  );
  return response.data;
};

/**
 * API call to set an MCell Status for the Demand Management team
 * @param id
 * @param status
 */
export const setMCellStatusDemandManagement = async ({
  id,
  status,
}: {
  id: string;
  status: MCellStatusLevel;
}): Promise<MCellStatus> => {
  const response = await axiosInstance.post(
    `/api/ResourceTracking/mCellStatus/${id}/setDemandManagement/${status}`,
  );
  return response.data;
};

/**
 * API call to set MCell Statuses for the Operations team in bulk
 * @param statusInfos
 */
export const setMCellStatusOperationsBulk = async (
  statusInfos: MCellStatusInfo[],
): Promise<void> => {
  const response = await axiosInstance.post(
    `/api/ResourceTracking/mCellStatusSetOperationsBulk`,
    statusInfos,
  );
  return response.data;
};

/**
 * API call to set MCell Statuses for the Demand Management team in bulk
 * @param statusInfos
 */
export const setMCellStatusDemandManagementBulk = async (
  statusInfos: MCellStatusInfo[],
): Promise<void> => {
  const response = await axiosInstance.post(
    `/api/ResourceTracking/mCellStatusSetDemandManagementBulk`,
    statusInfos,
  );
  return response.data;
};

/**
 * API call to get MCell Employees that match provided filter
 * @param filter
 */
export const getMCellEmployees = async (
  filter: MCellEmployeeFilterValue,
): Promise<MCellEmployee[]> => {
  const response = await axiosInstance.get(
    '/api/ResourceTracking/mCellEmployee',
    {
      params: filter,
    },
  );
  return response.data;
};

/**
 * API call to get an MCell Employee by Badge Id
 * @param badgeId
 */
export const getMCellEmployeeByBadgeId = async (
  badgeId: string,
): Promise<MCellEmployee> => {
  const response = await axiosInstance.get<MCellEmployee>(
    `/api/ResourceTracking/mCellEmployeeByBadgeId/${badgeId}`,
  );
  return response.data;
};

/**
 * API call to get an MCell Employee by their Employee ID
 * @param employeeId
 */
export const getMCellEmployeeByEmployeeId = async (
  employeeId: string,
): Promise<MCellEmployee> => {
  const response = await axiosInstance.get<MCellEmployee>(
    `/api/ResourceTracking/mCellEmployeeByEmployeeId/${employeeId}`,
  );
  return response.data;
};

/**
 * API call to get an MCell Employee by id
 * @param id
 */
export const getMCellEmployeeById = async (
  id: string,
): Promise<MCellEmployee> => {
  const response = await axiosInstance.get<MCellEmployee>(
    `/api/ResourceTracking/mCellEmployee/${id}`,
  );
  return response.data;
};

/**
 * API call to get the MCell an MCell Employee is currently logged into
 * @param id
 */
export const getMCellEmployeeCurrentMCell = async (
  id: string,
): Promise<MCell> => {
  const response = await axiosInstance.get<MCell>(
    `/api/ResourceTracking/mCellEmployee/${id}/currentMCell`,
  );
  return response.data;
};

/**
 * API call to get the MCell History for an MCell Employee
 * @param id
 */
export const getMCellEmployeeMCellHistory = async (
  id: string,
): Promise<MCellEmployeeMCellHistory[]> => {
  const response = await axiosInstance.get<MCellEmployeeMCellHistory[]>(
    `/api/ResourceTracking/mCellEmployee/${id}/mCellHistory`,
  );
  return response.data;
};

/**
 * API call to get list of MCell Employee Account Types
 */
export const getMCellEmployeeAccountTypes = async (): Promise<
  MCellEmployeeAccountType[]
> => {
  const response = await axiosInstance.get<MCellEmployeeAccountType[]>(
    '/api/ResourceTracking/mCellEmployeeAccountTypes',
  );
  return response.data;
};

/**
 * API call to get list of MCell Employee Roles
 */
export const getMCellEmployeeRoles = async (): Promise<MCellEmployeeRole[]> => {
  const response = await axiosInstance.get<MCellEmployeeRole[]>(
    '/api/ResourceTracking/mCellEmployeeRoles',
  );
  return response.data;
};

/**
 * API call to get list of MCell Employee Categories
 */
export const getMCellEmployeeCategories = async (): Promise<
  MCellEmployeeCategory[]
> => {
  const response = await axiosInstance.get<MCellEmployeeCategory[]>(
    '/api/ResourceTracking/mCellEmployeeCategories',
  );
  return response.data;
};

/**
 * API call to get list of Demand Management Status Inventory Center Configs
 */
export const getDemandManagementStatusInventoryCenterConfigs =
  async (): Promise<DemandManagementStatusInventoryCenterConfig[]> => {
    const response = await axiosInstance.get<
      DemandManagementStatusInventoryCenterConfig[]
    >('/api/ResourceTracking/demandManagementStatusInventoryCenterConfig');
    return response.data;
  };

/**
 * API call to set a Demand Management Status Inventory Center Config
 * @param info
 */
export const setDemandManagementStatusInventoryCenterConfig = async (
  info: DemandManagementStatusInventoryCenterConfigInfo,
): Promise<DemandManagementStatusInventoryCenterConfig> => {
  const response = await axiosInstance.post(
    `/api/ResourceTracking/demandManagementStatusInventoryCenterConfig`,
    info,
  );
  return response.data;
};

/**
 * API call to get list of Demand Management Status MCell Configs
 */
export const getDemandManagementStatusMCellConfigs = async (): Promise<
  DemandManagementStatusMCellConfig[]
> => {
  const response = await axiosInstance.get<DemandManagementStatusMCellConfig[]>(
    '/api/ResourceTracking/demandManagementStatusMCellConfig',
  );
  return response.data;
};

/**
 * API call to set a Demand Management Status MCell Config
 * @param info
 */
export const setDemandManagementStatusMCellConfig = async (
  info: DemandManagementStatusMCellConfigInfo,
): Promise<DemandManagementStatusMCellConfig> => {
  const response = await axiosInstance.post(
    `/api/ResourceTracking/demandManagementStatusMCellConfig`,
    info,
  );
  return response.data;
};

/**
 * API call to create Personal Protective Equipment
 * @param personalProtectiveEquipmentInfo
 */
export const createPersonalProtectiveEquipment = async (
  personalProtectiveEquipmentInfo: PersonalProtectiveEquipmentInfo,
): Promise<PersonalProtectiveEquipment> => {
  const response = await axiosInstance.post(
    '/api/ResourceTracking/personalProtectiveEquipment',
    personalProtectiveEquipmentInfo,
  );
  return response.data;
};

/**
 * API call to get a list of Personal Protective Equipment
 * @param inventoryCenterId
 */
export const getPersonalProtectiveEquipments = async (
  inventoryCenterId: string,
): Promise<PersonalProtectiveEquipment[]> => {
  const response = await axiosInstance.get(
    '/api/ResourceTracking/personalProtectiveEquipment',
    {
      params: { inventoryCenterId },
    },
  );
  return response.data;
};

/**
 * API call to update Personal Protective Equipment
 * @param info
 */
export const updatePersonalProtectiveEquipment = async (
  info: PersonalProtectiveEquipmentInfo,
): Promise<PersonalProtectiveEquipment> => {
  const response = await axiosInstance.post(
    `/api/ResourceTracking/personalProtectiveEquipment/${info.id}`,
    info,
  );
  return response.data;
};

/**
 * API call to delete some Personal Protective Equipment
 * @param id
 */
export const deletePersonalProtectiveEquipment = async (id: string) => {
  await axiosInstance.delete(
    `/api/ResourceTracking/personalProtectiveEquipment/${id}`,
  );
  return id;
};

/**
 * API call to create Dangerous Equipment
 * @param dangerousEquipmentInfo
 */
export const createDangerousEquipment = async (
  dangerousEquipmentInfo: DangerousEquipmentInfo,
): Promise<DangerousEquipment> => {
  const response = await axiosInstance.post(
    '/api/ResourceTracking/dangerousEquipment',
    dangerousEquipmentInfo,
  );
  return response.data;
};

/**
 * API call to get a list of Dangerous Equipment
 * @param inventoryCenterId
 */
export const getDangerousEquipments = async (
  inventoryCenterId: string,
): Promise<DangerousEquipment[]> => {
  const response = await axiosInstance.get(
    '/api/ResourceTracking/dangerousEquipment',
    {
      params: { inventoryCenterId },
    },
  );
  return response.data;
};

/**
 * API call to update Dangerous Equipment
 * @param info
 */
export const updateDangerousEquipment = async (
  info: DangerousEquipmentInfo,
): Promise<DangerousEquipment> => {
  const response = await axiosInstance.post(
    `/api/ResourceTracking/dangerousEquipment/${info.id}`,
    info,
  );
  return response.data;
};

/**
 * API call to delete some Dangerous Equipment
 * @param id
 */
export const deleteDangerousEquipment = async (id: string) => {
  await axiosInstance.delete(`/api/ResourceTracking/dangerousEquipment/${id}`);
  return id;
};

/**
 * API call to fetch a list of JobStations
 * @param manufacturingLocation
 */
export const getJobStations = async (
  manufacturingLocation: ManufacturingLocation,
): Promise<JobStation[]> => {
  const response = await axiosInstance.get('/api/ResourceTracking/jobStation', {
    params: manufacturingLocation,
  });
  return response.data;
};

/**
 * API call to create Job Station
 * @param jobStationInfo
 */
export const createJobStation = async (
  jobStationInfo: JobStationInfo,
): Promise<JobStation> => {
  const response = await axiosInstance.post(
    '/api/ResourceTracking/jobStation',
    jobStationInfo,
  );
  return response.data;
};

/**
 * API call to update a Job Station
 * @param jobStationInfo
 */
export const updateJobStation = async (
  jobStationInfo: JobStationInfo,
): Promise<JobStation> => {
  const response = await axiosInstance.post(
    `/api/ResourceTracking/jobStation/${jobStationInfo.id}`,
    jobStationInfo,
  );
  return response.data;
};

/**
 * API call to delete a JobStation
 * @param id
 */
export const deleteJobStation = async (id: string) => {
  await axiosInstance.delete(`/api/ResourceTracking/jobStation/${id}`);
  return id;
};

/**
 * API call to add a Personal Protective Equipment to a Job Station
 * @param info
 */
export const jobStationAddPersonalProtectiveEquipment = async (
  info: JobStationPersonalProtectiveEquipmentInfo,
): Promise<JobStation> => {
  const response = await axiosInstance.post(
    `/api/ResourceTracking/jobStation/${info.jobStationId}/personalProtectiveEquipment`,
    JSON.stringify(info.personalProtectiveEquipmentId),
  );
  return response.data;
};

/**
 * API call to remove a piece of Personal Protective Equipment from a Job Station
 * @param info
 */
export const jobStationRemovePersonalProtectiveEquipment = async (
  info: JobStationPersonalProtectiveEquipmentInfo,
): Promise<JobStation> => {
  const response = await axiosInstance.delete(
    `/api/ResourceTracking/jobStation/${info.jobStationId}/personalProtectiveEquipment/${info.personalProtectiveEquipmentId}`,
  );
  return response.data;
};

/**
 * API call to add a Dangerous Equipment to a Job Station
 * @param info
 */
export const jobStationAddDangerousEquipment = async (
  info: JobStationDangerousEquipmentInfo,
): Promise<JobStation> => {
  const response = await axiosInstance.post(
    `/api/ResourceTracking/jobStation/${info.jobStationId}/dangerousEquipment`,
    JSON.stringify(info.dangerousEquipmentId),
  );
  return response.data;
};

/**
 * API call to remove a piece of Dangerous Equipment from a Job Station
 * @param info
 */
export const jobStationRemoveDangerousEquipment = async (
  info: JobStationDangerousEquipmentInfo,
): Promise<JobStation> => {
  const response = await axiosInstance.delete(
    `/api/ResourceTracking/jobStation/${info.jobStationId}/dangerousEquipment/${info.dangerousEquipmentId}`,
  );
  return response.data;
};

/**
 * API call to fetch a list of current Job Station Assignments
 * @param params
 */
export const getCurrentJobStationAssignments = async (
  params: MCellShiftInfo,
): Promise<JobStationAssignmentDto[]> => {
  const response = await axiosInstance.get(
    '/api/ResourceTracking/currentJobStationAssignments',
    {
      params,
    },
  );
  return response.data;
};

/**
 * API call to set Job Station Assignments
 * @param info
 */
export const setJobStationAssignments = async (
  info: JobStationAssignmentsSetter,
): Promise<JobStationAssignmentDto[]> => {
  const response = await axiosInstance.post(
    `/api/ResourceTracking/jobStationAssignments`,
    info,
  );
  return response.data;
};

/**
 * API call to get the assessment status of a Job Station.
 *
 * This function sends a GET request to the server to retrieve the assessment status of a specific Job Station.
 * The status is determined based on the provided Job Station ID and User ID.
 *
 * @param {string} id - The ID of the Job Station.
 * @param {string} userId - The User ID being assigned to the Job Station.
 * @returns {Promise<boolean>} A promise that resolves to a boolean indicating the assessment status of the Job Station.
 */
export const getJobStationAssessmentStatus = async (
  id: string,
  userId: string,
): Promise<boolean> => {
  const response = await axiosInstance.get(
    `/api/ResourceTracking/jobStation/${id}/assessmentStatus`,
    {
      params: {
        userId,
      },
    },
  );
  return response.data;
};
