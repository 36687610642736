import React, { ErrorInfo } from 'react';
import { Translation } from 'react-i18next';
import { Typography } from '@mui/material';
import { appInsights } from '../../appInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-common';

type Props = {
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
};

/**
 * Wraps around other components to catch errors that occur within them
 */
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    appInsights.trackException({
      exception: error,
      properties: {
        componentStack: errorInfo.componentStack,
      },
      severityLevel: SeverityLevel.Error,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Translation>
          {(t) => <Typography>{t('somethingWentWrong')}</Typography>}
        </Translation>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
