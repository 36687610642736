import {
  AsyncThunk,
  AsyncThunkPayloadCreator,
  createAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { LoadingStatus } from '../../api/app.types';
import { ConnectionState } from './utils';
import { AlertLevel } from './types';
import { HelpLinkDto } from './types';
import {
  getHelpLinks,
  createHelpLink,
  updateHelpLink,
  deleteHelpLink,
} from './api';
import { RootState } from '../../store';
import moment from 'moment';

const UPDATE_SNOOZE_MS = 3600000; // 1 hour
export const AUTO_UPDATE_DELAY_MS = 60000; // 1 min

export type AppState = {
  updateStatus: LoadingStatus;
  version: string;
  updatePromptOpen: boolean;
  alertOpen: boolean;
  alertMessageKey: string;
  alertLevel: AlertLevel;
  connectionState: ConnectionState;
  ready: boolean;
  isFullscreen: boolean;
  autoUpdateTimeoutId?: number;
  autoUpdateTime?: Date;
  helpLinksStatus: LoadingStatus;
  helpLinks: HelpLinkDto[];
};

export const initialState: AppState = {
  updateStatus: 'idle',
  version: process.env.REACT_APP_CURRENT_VERSION || 'DEV',
  updatePromptOpen: false,
  alertOpen: false,
  alertMessageKey: '',
  alertLevel: 'error',
  connectionState: 'disconnected',
  ready: false,
  isFullscreen: false,
  helpLinksStatus: 'idle',
  helpLinks: [],
};

/**
 * Action to set alert toast
 */
export const setAlertAction = createAction<{
  alertMessageKey: string;
  alertLevel?: AlertLevel;
}>('app/setAlertAction');

/**
 * Action to clear alert toast
 */
export const clearAlertAction = createAction('app/clearAlertAction');

/**
 * Action to open or close the application update prompt
 */
export const setUpdatePromptOpenAction = createAction<boolean>(
  'app/setUpdatePromptOpenAction',
);

/**
 * Action to set the connection state in the store
 */
export const setConnectionStateAction = createAction<ConnectionState>(
  'app/setConnectionStateAction',
);

/**
 * Action to set Application is ready setting up
 */
export const setReadyAction = createAction('app/setReadyAction');

/**
 * Action to set is fullscreen state of the application
 */
export const setIsFullscreenAction = createAction<boolean>(
  'app/setIsFullscreenAction',
);

/**
 * Action to set auto updater timeout id
 */
export const setAutoUpdateTimeoutIdAction = createAction<number>(
  'app/setAutoUpdateTimeoutIdAction',
);
/**
 * Action to set auto update time
 */
export const setAutoUpdateTimeAction = createAction<Date>(
  'app/setAutoUpdateTimeAction',
);

/**
 * TODO: This is a temporary copy because test are failing from circular reference
 * Wraps `createAsyncThunk` from redux-toolkit to show alert on error.
 */
const createAsyncThunkWithError = <Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg>,
  errorStringKey: string,
): AsyncThunk<Returned, ThunkArg, any> => {
  return createAsyncThunk<Returned, ThunkArg, any>(
    typePrefix,
    async (arg, thunkAPI): Promise<any> => {
      try {
        return await payloadCreator(arg, thunkAPI);
      } catch (err) {
        thunkAPI.dispatch(setAlertAction({ alertMessageKey: errorStringKey }));
        return thunkAPI.rejectWithValue(null);
      }
    },
  );
};

/**
 * Thunk that closes the application update dialog and reopens it at a later time (1hr)
 */
export const remindMeLaterThunk = createAsyncThunkWithError(
  'app/remindMeLaterThunk',
  async (_, { dispatch, getState }): Promise<void> => {
    const { app } = getState() as { app: AppState };
    clearTimeout(app.autoUpdateTimeoutId);
    dispatch(setUpdatePromptOpenAction(false));
    setTimeout(() => {
      dispatch(setUpdatePromptOpenAction(true));
      dispatch(scheduleUpdateThunk(AUTO_UPDATE_DELAY_MS) as any);
    }, UPDATE_SNOOZE_MS);
  },
  'errorRemindMeLaterThunk',
);

/**
 * Thunk that schedules update reload
 */
export const scheduleUpdateThunk = createAsyncThunkWithError(
  'app/scheduleUpdateThunk',
  async (delay: number | undefined, { dispatch }): Promise<void> => {
    const now = moment();
    const timeoutId = setTimeout(() => {
      // sometimes MSAL gets confused when we update the package
      localStorage.clear();
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }, delay);
    dispatch(setAutoUpdateTimeoutIdAction(timeoutId as unknown as number));
    if (delay) {
      const autoUpdateTime = now.add(delay, 'ms');
      dispatch(setAutoUpdateTimeAction(autoUpdateTime.toDate()));
    }
  },
  'errorScheduleUpdateThunk',
);

/**
 * Thunk that updates a help link
 */
export const updateHelpLinkThunk = createAsyncThunkWithError(
  'helpLinks/updateHelpLinkThunk',
  updateHelpLink,
  'errorUpdatingHelpLink',
);

/**
 * Thunk that fetches help links
 */
export const getHelpLinksThunk = createAsyncThunkWithError(
  'helpLinks/getHelpLinksThunk',
  getHelpLinks,
  'errorFetchingHelpLinks',
);

/**
 * Thunk that creates a help link
 */
export const createHelpLinkThunk = createAsyncThunkWithError(
  'helpLinks/createHelpLinkThunk',
  createHelpLink,
  'errorCreatingHelpLink',
);

/**
 * Thunk that deletes a help link
 */
export const deleteHelpLinkThunk = createAsyncThunkWithError(
  'helpLinks/deleteHelpLinkThunk',
  deleteHelpLink,
  'errorDeletingHelpLink',
);

/**
 * Thunk that compares version from server against version embedded in js bundle
 * and performs the appropriate action
 */
export const versionCheckThunk = createAsyncThunkWithError(
  'app/versionCheckThunk',
  async (newVersion: string, { dispatch }): Promise<void> => {
    const currentVersion = process.env.REACT_APP_CURRENT_VERSION;
    // no version info, must be in dev
    if (!currentVersion) {
      return;
    }
    // already up to date, do nothing
    if (currentVersion === newVersion) {
      return;
    }
    // new version detected, prompt user for update
    dispatch(setUpdatePromptOpenAction(true));
    dispatch(scheduleUpdateThunk(AUTO_UPDATE_DELAY_MS) as any);
  },
  'errorVersionCheckThunk',
);

export const appSliceReducer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAlertAction: (state: AppState, action) => {
      state.alertOpen = true;
      state.alertMessageKey = action.payload.alertMessageKey;
      state.alertLevel = action.payload.alertLevel || 'error';
    },
    clearAlertAction: (state: AppState) => {
      state.alertOpen = false;
      state.alertMessageKey = '';
    },
    setUpdatePromptOpenAction: (state: AppState, action) => {
      state.updatePromptOpen = action.payload;
    },
    setConnectionStateAction: (state: AppState, action) => {
      state.connectionState = action.payload;
    },
    setReadyAction: (state: AppState) => {
      state.ready = true;
    },
    setIsFullscreenAction: (state: AppState, action) => {
      state.isFullscreen = action.payload;
    },
    setAutoUpdateTimeoutIdAction: (state: AppState, action) => {
      state.autoUpdateTimeoutId = action.payload;
    },
    setAutoUpdateTimeAction: (state: AppState, action) => {
      state.autoUpdateTime = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHelpLinksThunk.pending, (state) => {
        state.helpLinksStatus = 'loading';
      })
      .addCase(getHelpLinksThunk.fulfilled, (state, { payload }) => {
        state.helpLinksStatus = 'succeeded';
        state.helpLinks = payload;
      })
      .addCase(getHelpLinksThunk.rejected, (state) => {
        state.helpLinksStatus = 'failed';
      })

      .addCase(createHelpLinkThunk.pending, (state) => {
        state.helpLinksStatus = 'loading';
      })
      .addCase(createHelpLinkThunk.fulfilled, (state) => {
        state.helpLinksStatus = 'succeeded';
      })
      .addCase(createHelpLinkThunk.rejected, (state) => {
        state.helpLinksStatus = 'failed';
      })

      .addCase(updateHelpLinkThunk.pending, (state) => {
        state.helpLinksStatus = 'loading';
      })
      .addCase(updateHelpLinkThunk.fulfilled, (state) => {
        state.helpLinksStatus = 'succeeded';
      })
      .addCase(updateHelpLinkThunk.rejected, (state) => {
        state.helpLinksStatus = 'failed';
      })

      .addCase(deleteHelpLinkThunk.pending, (state) => {
        state.helpLinksStatus = 'loading';
      })
      .addCase(deleteHelpLinkThunk.fulfilled, (state, { payload }) => {
        state.helpLinksStatus = 'succeeded';
        const deleteIndex = state.helpLinks.findIndex((d) => d.id === payload);
        if (deleteIndex > -1) {
          state.helpLinks.splice(deleteIndex, 1);
        }
      })
      .addCase(deleteHelpLinkThunk.rejected, (state) => {
        state.helpLinksStatus = 'failed';
      })

      .addCase(remindMeLaterThunk.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(remindMeLaterThunk.fulfilled, (state) => {
        state.updateStatus = 'succeeded';
      })
      .addCase(remindMeLaterThunk.rejected, (state) => {
        state.updateStatus = 'failed';
      })
      .addCase(scheduleUpdateThunk.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(scheduleUpdateThunk.fulfilled, (state) => {
        state.updateStatus = 'succeeded';
      })
      .addCase(scheduleUpdateThunk.rejected, (state) => {
        state.updateStatus = 'failed';
      })
      .addCase(versionCheckThunk.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(versionCheckThunk.fulfilled, (state) => {
        state.updateStatus = 'succeeded';
      })
      .addCase(versionCheckThunk.rejected, (state) => {
        state.updateStatus = 'failed';
      });
  },
});

/**
 * Selector to get help links
 * @param state
 */
export const selectHelpLinks = (state: RootState) => state.app.helpLinks;

/**
 * Selector to check if help links are loading
 * @param state
 */
export const selectHelpLinksStatus = (state: RootState) =>
  state.app.helpLinksStatus === 'loading';

/*
 * export Reducer
 */
export default appSliceReducer.reducer;
