import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps, Tooltip } from '@mui/material';
import { Shift } from '../types';
import { getMCellShiftOptions } from '../utils';
import EnhancedSelect from '../../common/EnhancedSelect';

type Props = {
  value: Shift;
  onChange: (v: Shift) => void;
  disabled?: boolean;
  className?: string;
  sx?: SxProps;
  showLabel?: boolean;
  enableActiveShiftOption?: boolean;
  activeShift?: string;
};

/**
 * Render component for selecting current shift
 * @param value
 * @param onChange
 * @param className
 * @param sx
 * @param disabled
 * @param showLabel
 * @param enableActiveShiftOption - if true, will add an option for 'Active' shift
 * @param activeShift - if provided, will format value as Active(shift)
 * @constructor
 */
const ShiftSelect = ({
  value,
  onChange,
  className,
  sx,
  disabled,
  showLabel,
  enableActiveShiftOption,
  activeShift,
}: Props) => {
  const { t } = useTranslation();
  let shiftOptions = useMemo(() => {
    let options = getMCellShiftOptions(t).filter((s) => s.value !== 'None');
    if (enableActiveShiftOption) {
      options.unshift({
        value: 'Active' as Shift,
        label:
          activeShift && value === ('Active' as Shift)
            ? `${t('active')} (${activeShift})`
            : t('active'),
      });
    }
    return options;
  }, [t, enableActiveShiftOption, value, activeShift]);

  // Validate that the value is in the options
  const availableOptionValue = shiftOptions.some((o) => o.value === value)
    ? value
    : shiftOptions[0].value;

  return (
    <Tooltip title={t('shift') as any} placement={'left'}>
      <span>
        <EnhancedSelect
          label={showLabel ? t('shift') : undefined}
          id={'shift'}
          data={shiftOptions}
          value={availableOptionValue}
          onChange={onChange}
          containerClass={className}
          sx={sx}
          disabled={disabled}
        />
      </span>
    </Tooltip>
  );
};

export default ShiftSelect;
