import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  MCellEmployeeSelection,
  SupportGroup,
  SupportGroupDowntimeEscalationOverride,
  SupportGroupDowntimeEscalationOverrideInfo,
  SupportGroupDowntimeEscalationPositionOverride,
  SupportGroupDowntimeEscalationPositionOverrideInfo,
  SupportHierarchy,
  SupportHierarchyMCellDto,
  SupportGroupMemberDto,
  SupportGroupMemberCreate,
} from './types';
import { LoadingStatus } from '../../api/app.types';
import { createAsyncThunkWithError } from '../../redux/utils';
import {
  addSupportGroupMember,
  createPosition,
  createSupportGroup,
  createSupportGroupDowntimeEscalationOverride,
  createSupportGroupDowntimeEscalationPositionOverride,
  createSupportHierarchy,
  deletePosition,
  deleteSupportGroup,
  deleteSupportGroupDowntimeEscalationOverride,
  deleteSupportGroupDowntimeEscalationPositionOverride,
  deleteSupportGroupMember,
  deleteSupportGroupMembers,
  deleteSupportHierarchy,
  getMCellEmployeeSelections,
  getSupportGroupDowntimeEscalationOverrides,
  getSupportGroupDowntimeEscalationPositionOverrides,
  getSupportGroupMembers,
  getSupportGroups,
  getSupportHierarchies,
  getSupportHierarchyMCells,
  setMCellsOnMCellCollection,
  updateMCellCollectionName,
  updatePositionTitle,
  updateSupportGroup,
  updateSupportGroupDowntimeEscalationOverride,
  updateSupportGroupDowntimeEscalationPositionOverride,
  updateSupportGroupMembers,
} from './api';
import { RootState } from '../../store';
import { DowntimeReasonSupportGroupMappingSupportGroup } from '../downtime/types';

export type SupportHierarchyState = {
  supportHierarchies: SupportHierarchy[];
  supportHierarchiesStatus: LoadingStatus;
  supportGroups: SupportGroup[];
  supportGroupsStatus: LoadingStatus;
  mCellEmployeeSelections: MCellEmployeeSelection[];
  mCellEmployeeSelectionsStatus: LoadingStatus;
  supportGroupDowntimeEscalationOverrides: SupportGroupDowntimeEscalationOverride[];
  supportGroupDowntimeEscalationOverridesStatus: LoadingStatus;
  supportGroupDowntimeEscalationPositionOverrides: SupportGroupDowntimeEscalationPositionOverride[];
  supportGroupDowntimeEscalationPositionOverridesStatus: LoadingStatus;
  supportHierarchyMCells: SupportHierarchyMCellDto[];
  supportHierarchyMCellsStatus: LoadingStatus;
  supportGroupMembers: SupportGroupMemberDto[];
  supportGroupMembersStatus: LoadingStatus;
};

export const initialState: SupportHierarchyState = {
  supportHierarchies: [],
  supportHierarchiesStatus: 'idle',
  supportGroups: [],
  supportGroupsStatus: 'idle',
  mCellEmployeeSelections: [],
  mCellEmployeeSelectionsStatus: 'idle',
  supportGroupDowntimeEscalationOverrides: [],
  supportGroupDowntimeEscalationOverridesStatus: 'idle',
  supportGroupDowntimeEscalationPositionOverrides: [],
  supportGroupDowntimeEscalationPositionOverridesStatus: 'idle',
  supportHierarchyMCells: [],
  supportHierarchyMCellsStatus: 'idle',
  supportGroupMembers: [],
  supportGroupMembersStatus: 'idle',
};

/**
 * Action to add or update a Support Hierarchy to the store
 */
export const addOrUpdateSupportHierarchyAction = createAction<SupportGroup>(
  'supportHierarchy/addOrUpdateSupportHierarchyAction',
);

/**
 * Action to delete a Support Hierarchy from the store
 */
export const deleteSupportHierarchyAction = createAction<string>(
  'supportHierarchy/deleteSupportHierarchyAction',
);

/**
 * Action to add or update a Support Group to the store
 */
export const addOrUpdateSupportGroupAction = createAction<SupportGroup>(
  'supportHierarchy/addOrUpdateSupportGroupAction',
);

/**
 * Action to delete a Support Group from the store
 */
export const deleteSupportGroupAction = createAction<string>(
  'supportHierarchy/deleteSupportGroupAction',
);

/**
 * Action to add or update a Support Group Downtime Escalation Override to the store
 */
export const addOrUpdateSupportGroupDowntimeEscalationOverrideAction =
  createAction<SupportGroupDowntimeEscalationOverride>(
    'supportHierarchy/addOrUpdateSupportGroupDowntimeEscalationOverrideAction',
  );

/**
 * Action to delete a Support Group Downtime Escalation Override from the store
 */
export const deleteSupportGroupDowntimeEscalationOverrideAction =
  createAction<string>(
    'supportHierarchy/deleteSupportGroupDowntimeEscalationOverrideAction',
  );

/**
 * Action to add or update a Support Group Downtime Escalation Position Override to the store
 */
export const addOrUpdateSupportGroupDowntimeEscalationPositionOverrideAction =
  createAction<SupportGroupDowntimeEscalationPositionOverride>(
    'supportHierarchy/addOrUpdateSupportGroupDowntimeEscalationPositionOverrideAction',
  );

/**
 * Action to delete a Support Group Downtime Escalation Position Override from the store
 */
export const deleteSupportGroupDowntimeEscalationPositionOverrideAction =
  createAction<string>(
    'supportHierarchy/deleteSupportGroupDowntimeEscalationPositionOverrideAction',
  );

/**
 * Action to update Support Group Members in the store
 */
export const updateSupportGroupMembersAction = createAction<
  SupportGroupMemberCreate[]
>('supportHierarchy/updateSupportGroupMembersAction');

/**
 * Action to delete Support Group Members in the store
 */
export const deleteSupportGroupMembersAction = createAction<string[]>(
  'supportHierarchy/deleteSupportGroupMembersAction',
);

/**
 * Thunk for fetching Support Hierarchies
 */
export const getSupportHierarchiesThunk = createAsyncThunkWithError(
  'supportHierarchy/getSupportHierarchies',
  getSupportHierarchies,
  'errorFetchingSupportHierarchies',
);

/**
 * Thunk for creating a Support Hierarchy
 */
export const createSupportHierarchyThunk = createAsyncThunkWithError(
  'supportHierarchy/createSupportHierarchy',
  createSupportHierarchy,
  'errorCreatingSupportHierarchy',
);

/**
 * Thunk for deleting a Support Hierarchy
 */
export const deleteSupportHierarchyThunk = createAsyncThunkWithError(
  'supportHierarchy/deleteSupportHierarchy',
  deleteSupportHierarchy,
  'errorDeletingSupportHierarchy',
);

/**
 * Thunk for creating a Support Hierarchy Position
 */
export const createPositionThunk = createAsyncThunkWithError(
  'supportHierarchy/createPosition',
  createPosition,
  'errorCreatingSupportHierarchyPosition',
);

/**
 * Thunk for updating a Support Hierarchy Position title
 */
export const updatePositionTitleThunk = createAsyncThunkWithError(
  'supportHierarchy/updatePositionTitle',
  updatePositionTitle,
  'errorUpdatingSupportHierarchyPositionTitle',
);

/**
 * Thunk for deleting a Support Hierarchy Position
 */
export const deletePositionThunk = createAsyncThunkWithError(
  'supportHierarchy/deletePosition',
  deletePosition,
  'errorDeletingSupportHierarchyPosition',
);

/**
 * Thunk for fetching a Support Groups
 */
export const getSupportGroupsThunk = createAsyncThunkWithError(
  'supportHierarchy/getSupportGroups',
  getSupportGroups,
  'errorFetchingSupportGroups',
);

/**
 * Thunk for creating a Support Group
 */
export const createSupportGroupThunk = createAsyncThunkWithError(
  'supportHierarchy/createSupportGroup',
  createSupportGroup,
  'errorCreatingSupportGroup',
);

/**
 * Thunk for updating a Support Group on the server while optimistically updating the local store
 */
export const updateSupportGroupThunk = createAsyncThunkWithError(
  'supportHierarchy/updateSupportGroup',
  async (supportGroup: SupportGroup, { dispatch }): Promise<SupportGroup> => {
    dispatch(addOrUpdateSupportGroupAction(supportGroup));
    return await updateSupportGroup({
      id: supportGroup.id,
      supportGroupUpdate: { ...supportGroup },
    });
  },
  'errorUpdatingSupportGroup',
);

/**
 * Thunk for deleting a Support Group
 */
export const deleteSupportGroupThunk = createAsyncThunkWithError(
  'supportHierarchy/deleteSupportGroup',
  deleteSupportGroup,
  'errorDeletingSupportGroup',
);

/**
 * Thunk for adding a Support Group Member to a Support Hierarchy Position
 */
export const addSupportGroupMemberThunk = createAsyncThunkWithError(
  'supportHierarchy/addSupportGroupMember',
  addSupportGroupMember,
  'errorAddingMemberToSupportHierarchyPosition',
);

/**
 * Thunk for removing a Support Group Member from a Support Hierarchy Position
 */
export const deleteSupportGroupMemberThunk = createAsyncThunkWithError(
  'supportHierarchy/deleteSupportGroupMember',
  deleteSupportGroupMember,
  'errorDeletingMemberFromSupportHierarchyPosition',
);

/**
 * Thunk for setting MCells on a Support Hierarchy MCell Collection
 */
export const setMCellsOnMCellCollectionThunk = createAsyncThunkWithError(
  'supportHierarchy/setMCellsOnMCellCollection',
  setMCellsOnMCellCollection,
  'errorSettingWorkCellsOnWorkCellGroup',
);

/**
 * Thunk for updating a Support Hierarchy MCell Collection name
 */
export const updateMCellCollectionNameThunk = createAsyncThunkWithError(
  'supportHierarchy/updateMCellCollectionName',
  updateMCellCollectionName,
  'errorUpdatingWorkCellGroupName',
);

// TODO: Remove (and relocate to MCell Employee feature if not already done) when implementing RSTW-850
export const getMCellEmployeeSelectionsThunk = createAsyncThunkWithError(
  'supportHierarchy/getMCellEmployeeSelections',
  getMCellEmployeeSelections,
  'errorFetchingWorkCellEmployeeSelections',
);

/**
 * Thunk for fetching Support Group Downtime Escalation Overrides
 */
export const getSupportGroupDowntimeEscalationOverridesThunk =
  createAsyncThunkWithError(
    'supportHierarchy/getSupportGroupDowntimeEscalationOverrides',
    getSupportGroupDowntimeEscalationOverrides,
    'errorFetchingDowntimeEscalationOverrides',
  );

/**
 * Thunk for creating a Support Group Downtime Escalation Override
 */
export const createSupportGroupDowntimeEscalationOverrideThunk =
  createAsyncThunkWithError(
    'supportHierarchy/createSupportGroupDowntimeEscalationOverride',
    createSupportGroupDowntimeEscalationOverride,
    'errorCreatingDowntimeEscalationOverride',
  );

/**
 * Thunk for updating a Support Group Downtime Escalation Override on the server while optimistically
 * updating the local store
 */
export const updateSupportGroupDowntimeEscalationOverrideThunk =
  createAsyncThunkWithError(
    'supportHierarchy/updateSupportGroupDowntimeEscalationOverride',
    async (
      {
        id,
        overrideInfo,
      }: {
        id: string;
        overrideInfo: SupportGroupDowntimeEscalationOverrideInfo;
      },
      { dispatch },
    ): Promise<SupportGroupDowntimeEscalationOverride> => {
      dispatch(
        addOrUpdateSupportGroupDowntimeEscalationOverrideAction({
          id,
          ...overrideInfo,
        }),
      );
      return await updateSupportGroupDowntimeEscalationOverride(
        id,
        overrideInfo.timeMinutes,
      );
    },
    'errorUpdatingDowntimeEscalationOverride',
  );

/**
 * Thunk for deleting a Support Group Downtime Escalation Override
 */
export const deleteSupportGroupDowntimeEscalationOverrideThunk =
  createAsyncThunkWithError(
    'supportHierarchy/deleteSupportGroupDowntimeEscalationOverride',
    deleteSupportGroupDowntimeEscalationOverride,
    'errorDeletingDowntimeEscalationOverride',
  );

/**
 * Thunk for fetching a Support Group Downtime Escalation Position Override
 */
export const getSupportGroupDowntimeEscalationPositionOverridesThunk =
  createAsyncThunkWithError(
    'supportHierarchy/getSupportGroupDowntimeEscalationPositionOverrides',
    getSupportGroupDowntimeEscalationPositionOverrides,
    'errorFetchingDowntimeEscalationOverrides',
  );

/**
 * Thunk for creating a Support Group Downtime Escalation Position Override
 */
export const createSupportGroupDowntimeEscalationPositionOverrideThunk =
  createAsyncThunkWithError(
    'supportHierarchy/createSupportGroupDowntimeEscalationPositionOverride',
    createSupportGroupDowntimeEscalationPositionOverride,
    'errorCreatingDowntimeEscalationOverride',
  );

/**
 * Thunk for updating a Support Group Downtime Escalation Position Override on the server while optimistically
 * updating the local store
 */
export const updateSupportGroupDowntimeEscalationPositionOverrideThunk =
  createAsyncThunkWithError(
    'supportHierarchy/updateSupportGroupDowntimeEscalationPositionOverride',
    async (
      {
        id,
        overrideInfo,
      }: {
        id: string;
        overrideInfo: SupportGroupDowntimeEscalationPositionOverrideInfo;
      },
      { dispatch },
    ): Promise<SupportGroupDowntimeEscalationOverride> => {
      dispatch(
        addOrUpdateSupportGroupDowntimeEscalationPositionOverrideAction({
          id,
          ...overrideInfo,
        }),
      );
      return await updateSupportGroupDowntimeEscalationPositionOverride(
        id,
        overrideInfo.timeMinutes,
      );
    },
    'errorUpdatingDowntimeEscalationOverride',
  );

/**
 * Thunk for deleting a Support Group Downtime Escalation Position Override
 */
export const deleteSupportGroupDowntimeEscalationPositionOverrideThunk =
  createAsyncThunkWithError(
    'supportHierarchy/deleteSupportGroupDowntimeEscalationPositionOverride',
    deleteSupportGroupDowntimeEscalationPositionOverride,
    'errorDeletingDowntimeEscalationOverride',
  );

export const getSupportHierarchyMCellsThunk = createAsyncThunkWithError(
  'supportHierarchy/getSupportHierarchyMCellsThunk',
  getSupportHierarchyMCells,
  'errorFetchingSupportHierarchyWorkCells',
);

/**
 * Thunk for fetching Support Group Members
 */
export const getSupportGroupMembersThunk = createAsyncThunkWithError(
  'supportHierarchy/getSupportGroupMembers',
  getSupportGroupMembers,
  'errorFetchingSupportGroupMembers',
);

/**
 * Thunk for updating Support Group Members in the backend while optimistically updating the local store
 */
export const updateSupportGroupMembersThunk = createAsyncThunkWithError(
  'supportHierarchy/updateSupportGroupMembers',
  async (supportGroupMembers: SupportGroupMemberCreate[], { dispatch }) => {
    dispatch(updateSupportGroupMembersAction(supportGroupMembers));
    return await updateSupportGroupMembers(supportGroupMembers);
  },
  'errorUpdatingSupportGroupMembers',
);

/**
 * Thunk for deleting Support Group Members in the backend while optimistically updating the local store
 */
export const deleteSupportGroupMembersThunk = createAsyncThunkWithError(
  'supportHierarchy/deleteSupportGroupMembers',
  async (supportGroupMemberIds: string[], { dispatch }) => {
    dispatch(deleteSupportGroupMembersAction(supportGroupMemberIds));
    return await deleteSupportGroupMembers(supportGroupMemberIds);
  },
  'errorDeletingSupportGroupMembers',
);

export const supportHierarchySlice = createSlice({
  name: 'supportHierarchy',
  initialState,
  reducers: {
    addOrUpdateSupportHierarchyAction: (state, action) => {
      addOrUpdateSupportHierarchy(state, action.payload);
    },
    deleteSupportHierarchyAction: (state, action) => {
      const index = state.supportHierarchies.findIndex(
        (s) => s.id === action.payload,
      );
      if (index > -1) {
        state.supportHierarchies.splice(index, 1);
      }
    },
    addOrUpdateSupportGroupAction: (state, action) => {
      addOrUpdateSupportGroup(state, action.payload);
    },
    deleteSupportGroupAction: (state, action) => {
      const index = state.supportGroups.findIndex(
        (s) => s.id === action.payload,
      );
      if (index > -1) {
        state.supportGroups.splice(index, 1);
      }
    },
    addOrUpdateSupportGroupDowntimeEscalationOverrideAction: (
      state,
      action,
    ) => {
      addOrUpdateSupportGroupDowntimeEscalationOverrides(state, action.payload);
    },
    deleteSupportGroupDowntimeEscalationOverrideAction: (state, action) => {
      const index = state.supportGroupDowntimeEscalationOverrides.findIndex(
        (s) => s.id === action.payload,
      );
      if (index > -1) {
        state.supportGroupDowntimeEscalationOverrides.splice(index, 1);
      }
    },
    addOrUpdateSupportGroupDowntimeEscalationPositionOverrideAction: (
      state,
      action,
    ) => {
      addOrUpdateSupportGroupDowntimeEscalationPositionOverrides(
        state,
        action.payload,
      );
    },
    deleteSupportGroupDowntimeEscalationPositionOverrideAction: (
      state,
      action,
    ) => {
      const index =
        state.supportGroupDowntimeEscalationPositionOverrides.findIndex(
          (s) => s.id === action.payload,
        );
      if (index > -1) {
        state.supportGroupDowntimeEscalationPositionOverrides.splice(index, 1);
      }
    },
    updateSupportGroupMembersAction: (state, action) => {
      const mCellEmployeeId = action.payload[0].mCellEmployeeId;
      const mCellEmployeeSelection = state.mCellEmployeeSelections.find(
        (s) => s.id === mCellEmployeeId,
      );
      const mCellEmployeeNameWithId = `${mCellEmployeeSelection?.lastName}, ${mCellEmployeeSelection?.firstName} (${mCellEmployeeSelection?.employeeId})`;
      const payloadMap = new Map<string, SupportGroupMemberCreate>();
      for (const supportGroupMember of action.payload) {
        payloadMap.set(supportGroupMember.id, supportGroupMember);
      }
      for (const supportGroupMember of state.supportGroupMembers) {
        const matchingPayload = payloadMap.get(supportGroupMember.id);
        if (matchingPayload) {
          supportGroupMember.mCellEmployeeId = matchingPayload.mCellEmployeeId;
          supportGroupMember.mCellEmployeeNameWithId = mCellEmployeeNameWithId;
        }
      }
    },
    deleteSupportGroupMembersAction: (state, action) => {
      const ids = action.payload;
      state.supportGroupMembers = state.supportGroupMembers.filter(
        (s) => !ids.includes(s.id),
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSupportHierarchiesThunk.pending, (state) => {
        state.supportHierarchiesStatus = 'loading';
      })
      .addCase(getSupportHierarchiesThunk.fulfilled, (state, { payload }) => {
        state.supportHierarchiesStatus = 'succeeded';
        state.supportHierarchies = payload;
      })
      .addCase(getSupportHierarchiesThunk.rejected, (state) => {
        state.supportHierarchiesStatus = 'failed';
      })
      .addCase(createSupportHierarchyThunk.pending, (state) => {
        state.supportHierarchiesStatus = 'loading';
      })
      .addCase(createSupportHierarchyThunk.fulfilled, (state, { payload }) => {
        state.supportHierarchiesStatus = 'succeeded';
        addOrUpdateSupportHierarchy(state, payload);
      })
      .addCase(createSupportHierarchyThunk.rejected, (state) => {
        state.supportHierarchiesStatus = 'failed';
      })
      .addCase(deleteSupportHierarchyThunk.pending, (state) => {
        state.supportHierarchiesStatus = 'loading';
      })
      .addCase(deleteSupportHierarchyThunk.fulfilled, (state, { payload }) => {
        state.supportHierarchiesStatus = 'succeeded';
        const index = state.supportHierarchies.findIndex(
          (s) => s.id === payload,
        );
        if (index > -1) {
          state.supportHierarchies.splice(index, 1);
        }
      })
      .addCase(deleteSupportHierarchyThunk.rejected, (state) => {
        state.supportHierarchiesStatus = 'failed';
      })
      .addCase(createPositionThunk.pending, (state) => {
        state.supportHierarchiesStatus = 'loading';
      })
      .addCase(createPositionThunk.fulfilled, (state) => {
        state.supportHierarchiesStatus = 'succeeded';
      })
      .addCase(createPositionThunk.rejected, (state) => {
        state.supportHierarchiesStatus = 'failed';
      })
      .addCase(updatePositionTitleThunk.pending, (state) => {
        state.supportHierarchiesStatus = 'loading';
      })
      .addCase(updatePositionTitleThunk.fulfilled, (state) => {
        state.supportHierarchiesStatus = 'succeeded';
      })
      .addCase(updatePositionTitleThunk.rejected, (state) => {
        state.supportHierarchiesStatus = 'failed';
      })
      .addCase(deletePositionThunk.pending, (state) => {
        state.supportHierarchiesStatus = 'loading';
      })
      .addCase(deletePositionThunk.fulfilled, (state) => {
        state.supportHierarchiesStatus = 'succeeded';
      })
      .addCase(deletePositionThunk.rejected, (state) => {
        state.supportHierarchiesStatus = 'failed';
      })
      .addCase(getSupportGroupsThunk.pending, (state) => {
        state.supportGroupsStatus = 'loading';
      })
      .addCase(getSupportGroupsThunk.fulfilled, (state, { payload }) => {
        state.supportGroupsStatus = 'succeeded';
        state.supportGroups = payload;
      })
      .addCase(getSupportGroupsThunk.rejected, (state) => {
        state.supportGroupsStatus = 'failed';
      })
      .addCase(createSupportGroupThunk.pending, (state) => {
        state.supportGroupsStatus = 'loading';
      })
      .addCase(createSupportGroupThunk.fulfilled, (state, { payload }) => {
        state.supportGroupsStatus = 'succeeded';
        addOrUpdateSupportGroup(state, payload);
      })
      .addCase(createSupportGroupThunk.rejected, (state) => {
        state.supportGroupsStatus = 'failed';
      })
      .addCase(updateSupportGroupThunk.rejected, (state) => {
        state.supportGroupsStatus = 'failed';
      })
      .addCase(deleteSupportGroupThunk.pending, (state) => {
        state.supportGroupsStatus = 'loading';
      })
      .addCase(deleteSupportGroupThunk.fulfilled, (state, { payload }) => {
        state.supportGroupsStatus = 'succeeded';
        const index = state.supportGroups.findIndex((s) => s.id === payload);
        if (index > -1) {
          state.supportGroups.splice(index, 1);
        }
      })
      .addCase(deleteSupportGroupThunk.rejected, (state) => {
        state.supportGroupsStatus = 'failed';
      })
      .addCase(addSupportGroupMemberThunk.pending, (state) => {
        state.supportHierarchiesStatus = 'loading';
      })
      .addCase(addSupportGroupMemberThunk.fulfilled, (state) => {
        state.supportHierarchiesStatus = 'succeeded';
      })
      .addCase(addSupportGroupMemberThunk.rejected, (state) => {
        state.supportHierarchiesStatus = 'failed';
      })
      .addCase(deleteSupportGroupMemberThunk.pending, (state) => {
        state.supportHierarchiesStatus = 'loading';
      })
      .addCase(deleteSupportGroupMemberThunk.fulfilled, (state) => {
        state.supportHierarchiesStatus = 'succeeded';
      })
      .addCase(deleteSupportGroupMemberThunk.rejected, (state) => {
        state.supportHierarchiesStatus = 'failed';
      })
      .addCase(setMCellsOnMCellCollectionThunk.pending, (state) => {
        state.supportHierarchiesStatus = 'loading';
      })
      .addCase(setMCellsOnMCellCollectionThunk.fulfilled, (state) => {
        state.supportHierarchiesStatus = 'succeeded';
      })
      .addCase(setMCellsOnMCellCollectionThunk.rejected, (state) => {
        state.supportHierarchiesStatus = 'failed';
      })
      .addCase(updateMCellCollectionNameThunk.pending, (state) => {
        state.supportHierarchiesStatus = 'loading';
      })
      .addCase(updateMCellCollectionNameThunk.fulfilled, (state) => {
        state.supportHierarchiesStatus = 'succeeded';
      })
      .addCase(updateMCellCollectionNameThunk.rejected, (state) => {
        state.supportHierarchiesStatus = 'failed';
      })
      .addCase(getMCellEmployeeSelectionsThunk.pending, (state) => {
        state.mCellEmployeeSelectionsStatus = 'loading';
      })
      .addCase(
        getMCellEmployeeSelectionsThunk.fulfilled,
        (state, { payload }) => {
          state.mCellEmployeeSelectionsStatus = 'succeeded';
          state.mCellEmployeeSelections = payload;
        },
      )
      .addCase(getMCellEmployeeSelectionsThunk.rejected, (state) => {
        state.mCellEmployeeSelectionsStatus = 'failed';
      })
      .addCase(
        getSupportGroupDowntimeEscalationOverridesThunk.pending,
        (state) => {
          state.supportGroupDowntimeEscalationOverridesStatus = 'loading';
        },
      )
      .addCase(
        getSupportGroupDowntimeEscalationOverridesThunk.fulfilled,
        (state, { payload }) => {
          state.supportGroupDowntimeEscalationOverridesStatus = 'succeeded';
          state.supportGroupDowntimeEscalationOverrides = payload;
        },
      )
      .addCase(
        getSupportGroupDowntimeEscalationOverridesThunk.rejected,
        (state) => {
          state.supportGroupDowntimeEscalationOverridesStatus = 'failed';
        },
      )
      .addCase(
        createSupportGroupDowntimeEscalationOverrideThunk.pending,
        (state) => {
          state.supportGroupDowntimeEscalationOverridesStatus = 'loading';
        },
      )
      .addCase(
        createSupportGroupDowntimeEscalationOverrideThunk.fulfilled,
        (state, { payload }) => {
          state.supportGroupDowntimeEscalationOverridesStatus = 'succeeded';
          addOrUpdateSupportGroupDowntimeEscalationOverrides(state, payload);
        },
      )
      .addCase(
        createSupportGroupDowntimeEscalationOverrideThunk.rejected,
        (state) => {
          state.supportGroupDowntimeEscalationOverridesStatus = 'failed';
        },
      )
      .addCase(
        updateSupportGroupDowntimeEscalationOverrideThunk.rejected,
        (state) => {
          state.supportGroupDowntimeEscalationOverridesStatus = 'failed';
        },
      )
      .addCase(
        deleteSupportGroupDowntimeEscalationOverrideThunk.pending,
        (state) => {
          state.supportGroupDowntimeEscalationOverridesStatus = 'loading';
        },
      )
      .addCase(
        deleteSupportGroupDowntimeEscalationOverrideThunk.fulfilled,
        (state, { payload }) => {
          state.supportGroupDowntimeEscalationOverridesStatus = 'succeeded';
          const index = state.supportGroupDowntimeEscalationOverrides.findIndex(
            (s) => s.id === payload,
          );
          if (index > -1) {
            state.supportGroupDowntimeEscalationOverrides.splice(index, 1);
          }
        },
      )
      .addCase(
        deleteSupportGroupDowntimeEscalationOverrideThunk.rejected,
        (state) => {
          state.supportGroupDowntimeEscalationOverridesStatus = 'failed';
        },
      )
      .addCase(
        getSupportGroupDowntimeEscalationPositionOverridesThunk.pending,
        (state) => {
          state.supportGroupDowntimeEscalationPositionOverridesStatus =
            'loading';
        },
      )
      .addCase(
        getSupportGroupDowntimeEscalationPositionOverridesThunk.fulfilled,
        (state, { payload }) => {
          state.supportGroupDowntimeEscalationPositionOverridesStatus =
            'succeeded';
          state.supportGroupDowntimeEscalationPositionOverrides = payload;
        },
      )
      .addCase(
        getSupportGroupDowntimeEscalationPositionOverridesThunk.rejected,
        (state) => {
          state.supportGroupDowntimeEscalationPositionOverridesStatus =
            'failed';
        },
      )
      .addCase(
        createSupportGroupDowntimeEscalationPositionOverrideThunk.pending,
        (state) => {
          state.supportGroupDowntimeEscalationPositionOverridesStatus =
            'loading';
        },
      )
      .addCase(
        createSupportGroupDowntimeEscalationPositionOverrideThunk.fulfilled,
        (state, { payload }) => {
          state.supportGroupDowntimeEscalationPositionOverridesStatus =
            'succeeded';
          addOrUpdateSupportGroupDowntimeEscalationPositionOverrides(
            state,
            payload,
          );
        },
      )
      .addCase(
        createSupportGroupDowntimeEscalationPositionOverrideThunk.rejected,
        (state) => {
          state.supportGroupDowntimeEscalationPositionOverridesStatus =
            'failed';
        },
      )
      .addCase(
        updateSupportGroupDowntimeEscalationPositionOverrideThunk.rejected,
        (state) => {
          state.supportGroupDowntimeEscalationPositionOverridesStatus =
            'failed';
        },
      )
      .addCase(
        deleteSupportGroupDowntimeEscalationPositionOverrideThunk.pending,
        (state) => {
          state.supportGroupDowntimeEscalationPositionOverridesStatus =
            'loading';
        },
      )
      .addCase(
        deleteSupportGroupDowntimeEscalationPositionOverrideThunk.fulfilled,
        (state, { payload }) => {
          state.supportGroupDowntimeEscalationPositionOverridesStatus =
            'succeeded';
          const index =
            state.supportGroupDowntimeEscalationPositionOverrides.findIndex(
              (s) => s.id === payload,
            );
          if (index > -1) {
            state.supportGroupDowntimeEscalationPositionOverrides.splice(
              index,
              1,
            );
          }
        },
      )
      .addCase(
        deleteSupportGroupDowntimeEscalationPositionOverrideThunk.rejected,
        (state) => {
          state.supportGroupDowntimeEscalationPositionOverridesStatus =
            'failed';
        },
      )
      .addCase(getSupportHierarchyMCellsThunk.pending, (state) => {
        state.supportHierarchyMCellsStatus = 'loading';
      })
      .addCase(
        getSupportHierarchyMCellsThunk.fulfilled,
        (state, { payload }) => {
          state.supportHierarchyMCellsStatus = 'succeeded';
          state.supportHierarchyMCells = payload;
        },
      )
      .addCase(getSupportHierarchyMCellsThunk.rejected, (state) => {
        state.supportHierarchyMCellsStatus = 'failed';
      })
      .addCase(getSupportGroupMembersThunk.pending, (state) => {
        state.supportGroupMembersStatus = 'loading';
      })
      .addCase(getSupportGroupMembersThunk.fulfilled, (state, { payload }) => {
        state.supportGroupMembersStatus = 'succeeded';
        state.supportGroupMembers = payload;
      })
      .addCase(getSupportGroupMembersThunk.rejected, (state) => {
        state.supportGroupMembersStatus = 'failed';
      })
      .addCase(updateSupportGroupMembersThunk.pending, (state) => {
        state.supportGroupMembersStatus = 'loading';
      })
      .addCase(updateSupportGroupMembersThunk.fulfilled, (state) => {
        state.supportGroupMembersStatus = 'succeeded';
      })
      .addCase(updateSupportGroupMembersThunk.rejected, (state) => {
        state.supportGroupMembersStatus = 'failed';
      })
      .addCase(deleteSupportGroupMembersThunk.pending, (state) => {
        state.supportGroupMembersStatus = 'loading';
      })
      .addCase(deleteSupportGroupMembersThunk.fulfilled, (state) => {
        state.supportGroupMembersStatus = 'succeeded';
      })
      .addCase(deleteSupportGroupMembersThunk.rejected, (state) => {
        state.supportGroupMembersStatus = 'failed';
      });
  },
});

/**
 * Function to add or update SupportHierarchy
 * @param state
 * @param supportHierarchy
 */
export const addOrUpdateSupportHierarchy = (
  state: SupportHierarchyState,
  supportHierarchy: SupportHierarchy,
) => {
  const index = state.supportHierarchies.findIndex(
    (s) => s.id === supportHierarchy.id,
  );
  if (index !== -1) {
    state.supportHierarchies[index] = supportHierarchy;
  } else {
    state.supportHierarchies.push(supportHierarchy);
  }
};

/**
 * Function to add or update SupportGroups
 * @param state
 * @param supportGroups
 */
export const addOrUpdateSupportGroup = (
  state: SupportHierarchyState,
  supportGroups: SupportGroup,
) => {
  const index = state.supportGroups.findIndex((s) => s.id === supportGroups.id);
  if (index !== -1) {
    state.supportGroups[index] = supportGroups;
  } else {
    state.supportGroups.push(supportGroups);
  }
};

/**
 * Function to add or update supportGroupDowntimeEscalationPositionOverrides
 * @param state
 * @param supportGroupDowntimeEscalationPositionOverrides
 */
export const addOrUpdateSupportGroupDowntimeEscalationPositionOverrides = (
  state: SupportHierarchyState,
  supportGroupDowntimeEscalationPositionOverrides: SupportGroupDowntimeEscalationPositionOverride,
) => {
  const index = state.supportGroupDowntimeEscalationPositionOverrides.findIndex(
    (s) => s.id === supportGroupDowntimeEscalationPositionOverrides.id,
  );
  if (index !== -1) {
    state.supportGroupDowntimeEscalationPositionOverrides[index] =
      supportGroupDowntimeEscalationPositionOverrides;
  } else {
    state.supportGroupDowntimeEscalationPositionOverrides.push(
      supportGroupDowntimeEscalationPositionOverrides,
    );
  }
};

/**
 * Function to add or update SupportGroupDowntimeEscalationOverrides
 * @param state
 * @param supportGroupDowntimeEscalationOverride
 */
export const addOrUpdateSupportGroupDowntimeEscalationOverrides = (
  state: SupportHierarchyState,
  supportGroupDowntimeEscalationOverride: SupportGroupDowntimeEscalationOverride,
) => {
  const index = state.supportGroupDowntimeEscalationOverrides.findIndex(
    (s) => s.id === supportGroupDowntimeEscalationOverride.id,
  );
  if (index !== -1) {
    state.supportGroupDowntimeEscalationOverrides[index] =
      supportGroupDowntimeEscalationOverride;
  } else {
    state.supportGroupDowntimeEscalationOverrides.push(
      supportGroupDowntimeEscalationOverride,
    );
  }
};

/**
 * Selector to retrieve Support Hierarchies from the store
 * @param state
 */
export const selectSupportHierarchies = (state: RootState) =>
  state.supportHierarchy.supportHierarchies;

/**
 * Selector to determine whether Support Hierarchies are loading
 * @param state
 */
export const selectLoadingSupportHierarchies = (state: RootState) =>
  state.supportHierarchy.supportHierarchiesStatus === 'loading';

/**
 * Selector to retrieve Support Group Downtime Escalation Overrides from the store
 * @param state
 */
export const selectSupportGroupDowntimeEscalationOverrides = (
  state: RootState,
) => state.supportHierarchy.supportGroupDowntimeEscalationOverrides;

/**
 * A memoized selector that retrieves a filtered list of downtime escalation overrides for a specific inventory center.
 * This selector filters the overrides based on the inventory center ID, ensuring that only the overrides related
 * to the specified inventory center are returned. It is designed to be used where a refined list of overrides
 * based on their associated support group's inventory center is necessary.
 *
 * @param {RootState} state - The entire Redux state.
 * @param {string} inventoryCenterId - The ID of the inventory center to filter the overrides by.
 * @returns {SupportGroupDowntimeEscalationOverride[]} A filtered array of escalation overrides where each override's support group
 *          is associated with the specified inventory center.
 *
 * Usage:
 * const filteredOverrides = useSelector(state =>
 *   selectFilteredDowntimeEscalationOverrides(state, inventoryCenterId)
 * );
 */
export const selectFilteredDowntimeEscalationOverrides = createSelector(
  [
    selectSupportGroupDowntimeEscalationOverrides,
    (_, inventoryCenterId) => inventoryCenterId,
  ],
  (overrides, inventoryCenterId) =>
    overrides.filter(
      (override) =>
        override.supportGroup.inventoryCenterId === inventoryCenterId,
    ),
);

/**
 * Selector to retrieve Support Groups from the store
 * @param state
 */
export const selectSupportGroups = (state: RootState) =>
  state.supportHierarchy.supportGroups;

/**
 * A memoized selector that retrieves support groups that are not overridden for a specific inventory center.
 * This selector combines the full list of support groups with the list of filtered downtime escalation overrides
 * to determine which support groups are not currently overridden. It filters support groups by inventory center ID
 * and excludes those that have corresponding overrides.
 *
 * @param {RootState} state - The entire Redux state.
 * @param {string} inventoryCenterId - The ID of the inventory center. This is used both to filter support groups
 *        directly and to derive the filtered overrides that must be excluded from the result.
 * @returns {SupportGroup[]} A list of support groups for the specified inventory center
 *          and not currently overridden.
 *
 * Usage:
 * const nonOverriddenSupportGroups = useSelector(state =>
 *   selectNonOverriddenSupportGroups(state, inventoryCenterId)
 * );
 */
export const selectNonOverriddenSupportGroups = createSelector(
  [
    selectSupportGroups,
    selectFilteredDowntimeEscalationOverrides,
    (_, inventoryCenterId) => inventoryCenterId,
  ],
  (supportGroups, overrides, inventoryCenterId) => {
    const overrideIds = overrides.map((override) => override.supportGroup.id);
    return supportGroups.filter(
      (supportGroup) =>
        supportGroup.inventoryCenterId === inventoryCenterId &&
        !overrideIds.includes(supportGroup.id),
    );
  },
);

/**
 * Creates a memoized selector that returns a set of watcher support group IDs.
 * @param watcherSupportGroupJoins Array of support group joins from the mapping.
 * @returns A Set containing unique watcher support group IDs.
 */
export const selectWatcherSupportGroupIds = (
  watcherSupportGroupJoins: DowntimeReasonSupportGroupMappingSupportGroup[],
) =>
  createSelector(
    [() => watcherSupportGroupJoins],
    (joins) => new Set(joins?.map((join) => join.supportGroupId)),
  );

/**
 * Create a memoized selector to retrieve Support Groups for a specific Inventory Center ID.
 * This selector builds upon the base selectSupportGroups selector.
 * @param inventoryCenterId - The Inventory Center ID to filter Support Groups by
 * @returns A memoized selector that returns Support Groups for the specified Inventory Center ID
 */
export const selectSupportGroupsForInventoryCenter = (
  inventoryCenterId: string,
) =>
  createSelector([selectSupportGroups], (supportGroup) =>
    supportGroup.filter((s) => s.inventoryCenterId === inventoryCenterId),
  );

/**
 * Creates a memoized selector to retrieve support groups based on their association with selected watcher support group IDs.
 * This selector can conditionally include or exclude support groups based on whether their IDs match the selected watcher support group IDs.
 *
 * @param {string} inventoryCenterId - The ID of the inventory center to match support groups against.
 * @param {DowntimeReasonSupportGroupMappingSupportGroup[]} watcherSupportGroupJoins - An array of support group joins from which to derive watcher support group IDs.
 * @param {boolean} [excludeMatching=false] - A flag that determines the filtering behavior of the selector:
 *                                            - If `true`, the selector returns support groups that do NOT match the derived IDs.
 *                                            - If `false`, the selector returns support groups that DO match the derived IDs.
 * @returns A memoized selector that returns an array of filtered support groups based on the specified criteria.
 */
export const makeSelectWatcherSupportGroups = (
  inventoryCenterId: string,
  watcherSupportGroupJoins: DowntimeReasonSupportGroupMappingSupportGroup[],
) =>
  createSelector(
    [
      selectSupportGroups,
      selectWatcherSupportGroupIds(watcherSupportGroupJoins),
    ],
    (supportGroups, watcherIds) =>
      supportGroups.filter((s) => s.inventoryCenterId === inventoryCenterId),
  );

/**
 * Selector to determine whether Support Groups are loading
 * @param state
 */
export const selectLoadingSupportGroups = (state: RootState) =>
  state.supportHierarchy.supportGroupsStatus === 'loading';

/**
 * Selector to retrieve MCell Employee Selections from the store
 * @param state
 */
export const selectMCellEmployeeSelections = (state: RootState) =>
  state.supportHierarchy.mCellEmployeeSelections;

/**
 * Selector to determine whether MCell Employee Selections are loading
 * @param state
 */
export const selectLoadingMCellEmployeeSelections = (state: RootState) =>
  state.supportHierarchy.mCellEmployeeSelectionsStatus === 'loading';

/**
 * Selector to retrieve Support Group Downtime Escalation Position Overrides from the store
 * @param state
 */
export const selectSupportGroupDowntimeEscalationPositionOverrides = (
  state: RootState,
) => state.supportHierarchy.supportGroupDowntimeEscalationPositionOverrides;

/**
 * Creates a memoized selector to retrieve support group downtime escalation position overrides
 * filtered by an optional inventory center ID and/or an optional position ID. This selector efficiently filters the overrides,
 * ensuring that the computation is only performed when necessary — specifically, when the overrides
 * data changes or when different filtering IDs are used.
 *
 * @param {Object} params - Object containing optional filter parameters.
 * @param {string} [params.inventoryCenterId] - Optional inventory center ID to filter the overrides.
 * @param {string} [params.positionId] - Optional position ID to filter the overrides.
 * @returns An array of downtime escalation position overrides where
 *          each override matches the specified inventory center and/or position criteria.
 *
 * Usage in a component:
 * // For inventory center ID filtering
 * const downtimeEscalationOverrides = useSelector(
 *   selectSupportGroupDowntimeEscalationPositionOverridesById({ inventoryCenterId })
 * );
 *
 * // For position ID filtering
 * const overrides = useSelector(
 *   selectSupportGroupDowntimeEscalationPositionOverridesById({ positionId: position?.id })
 * );
 */
export const selectSupportGroupDowntimeEscalationPositionOverridesById =
  createSelector(
    [
      selectSupportGroupDowntimeEscalationPositionOverrides,
      (_, params) => params,
    ],
    (overrides, { inventoryCenterId = '', positionId = '' }) =>
      overrides.filter(
        (override) =>
          (!inventoryCenterId ||
            override.supportGroup.inventoryCenterId === inventoryCenterId) &&
          (!positionId || override.positionId === positionId),
      ),
  );

/**
 * Selector to determine whether Support Group Downtime Escalation Position Overrides are loading
 * @param state
 */
export const selectLoadingSupportGroupDowntimeEscalationPositionOverrides = (
  state: RootState,
) =>
  state.supportHierarchy
    .supportGroupDowntimeEscalationPositionOverridesStatus === 'loading';

/**
 * Selector to retrieve Support Hierarchies MCells from the store
 * @param state
 */
export const selectSupportHierarchyMCells = (state: RootState) =>
  state.supportHierarchy.supportHierarchyMCells;

/**
 * Selector to determine whether Support Hierarchies MCells are loading
 * @param state
 */
export const selectLoadingSupportHierarchyMCells = (state: RootState) =>
  state.supportHierarchy.supportHierarchyMCellsStatus === 'loading';

/**
 * Selector to retrieve Support Group Members from the store
 * @param state
 */
export const selectSupportGroupMembers = (state: RootState) =>
  state.supportHierarchy.supportGroupMembers;

/**
 * Selector to determine whether Support Group Members are loading
 * @param state
 */
export const selectLoadingSupportGroupMembers = (state: RootState) =>
  state.supportHierarchy.supportGroupMembersStatus === 'loading';

export default supportHierarchySlice.reducer;
