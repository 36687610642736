import React from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { isEmptyOrSpaces } from '../../utils/string-utils';

type Props = {
  label?: string;
  id?: string;
  data: Array<OptionValue>;
  value: any;
  onChange: (value: any) => void;
  helpText?: string;
  errors?: string[];
  containerClass?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  sx?: SxProps;
  required?: boolean;
  native?: boolean;
  toolTip?: string;
  toolTipPlacement?: TooltipProps['placement'];
};

export type OptionValue<T = any> = {
  label: string;
  value: T;
};

/**
 * Wraps the Material-UI select control for easier and consistent usage
 * @constructor
 */
const EnhancedSelect = ({ toolTip, toolTipPlacement, ...restProps }: Props) => {
  if (isEmptyOrSpaces(toolTip)) {
    return <SelectWithoutTooltip {...restProps} />;
  }
  return (
    <Tooltip title={toolTip as any} placement={toolTipPlacement}>
      <Box component={'span'}>
        <SelectWithoutTooltip {...restProps} />
      </Box>
    </Tooltip>
  );
};

const SelectWithoutTooltip = ({
  label,
  id,
  data,
  value,
  onChange,
  helpText,
  errors,
  containerClass,
  disabled,
  style,
  required,
  native,
  sx,
}: Props) => {
  return (
    <form className={containerClass} style={style}>
      <FormControl required={required} fullWidth variant={'standard'}>
        {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
        <Select
          sx={sx}
          id={id}
          value={value}
          onChange={(v) => onChange(v.target.value)}
          disabled={disabled}
          required={required}
          error={required && !value}
          native={native}
          variant='standard'
        >
          {native
            ? [
                <option key='' />,
                ...data.map((d, index) => (
                  <option key={index} value={d.value}>
                    {d.label}
                  </option>
                )),
              ]
            : data.map((d, index) => (
                <MenuItem key={index} value={d.value}>
                  {d.label}
                </MenuItem>
              ))}
        </Select>
        {helpText && <FormHelperText>{helpText}</FormHelperText>}
        {errors &&
          errors.map((e, i) => (
            <FormHelperText key={i} error>
              {e}
            </FormHelperText>
          ))}
      </FormControl>
    </form>
  );
};

export default EnhancedSelect;
