import React from 'react';
import { HomeLinkDto } from '../types';
import { Column } from '@devexpress/dx-react-grid';
import { useTranslation } from 'react-i18next';
import GridTableDefault from '../../common/GridTableDefault';
import {
  DateTimeTypeProvider,
  ImageTypeProvider,
} from '../../common/GridDataFormatters';
import {
  HOME_LINK_CONFIG_TABLE_COLUMN_ORDER,
  HOME_LINK_CONFIG_TABLE_COLUMN_WIDTHS,
  HOME_LINK_CONFIG_TABLE_HIDDEN_COLUMNS,
  HOME_LINK_CONFIG_TABLE_SORTING,
} from '../../profile/types';
import ManufacturingLocationString from '../../manufacturingLocation/components/ManufacturingLocationString';

type Props = {
  homeLinks: HomeLinkDto[];
  onRowClick: (row: HomeLinkDto) => void;
};

/**
 * Table for displaying and updating Home Links
 * @param homeLinks
 * @param onRowClick
 * @constructor
 */
const HomeLinksConfigListTable = ({ homeLinks, onRowClick }: Props) => {
  const { t } = useTranslation();
  const columns: Column[] = [
    {
      title: t('location'),
      name: 'inventoryCenterId',
      getCellValue: (row: HomeLinkDto) => (
        <ManufacturingLocationString
          location={{
            inventoryCenterId: row.inventoryCenterId,
            mCellId: row.mCellId,
            mCellCollectionId: row.mCellCollectionId,
          }}
        />
      ),
    },
    {
      title: t('title'),
      name: 'title',
    },
    {
      title: t('iconImage'),
      name: 'imageUrl',
    },
    {
      title: t('type'),
      name: 'linkType',
    },
    {
      title: t('updatedBy'),
      name: 'lastUpdatedByName',
    },
    {
      title: t('updatedDate'),
      name: 'updatedDate',
    },
    {
      title: t('sortSequence'),
      name: 'sortSequence',
    },
    {
      title: t('group'),
      name: 'groupName',
    },
  ];

  const defaultColumnWidths = [
    { columnName: 'inventoryCenterId', width: 250 },
    { columnName: 'title', width: 150 },
    { columnName: 'imageUrl', width: 275 },
    { columnName: 'linkType', width: 100 },
    { columnName: 'lastUpdatedByName', width: 150 },
    { columnName: 'updatedDate', width: 225 },
    { columnName: 'sortSequence', width: 150 },
    { columnName: 'groupName', width: 250 },
  ];

  return (
    <GridTableDefault
      rows={homeLinks}
      columns={columns}
      getRowId={(row) => row.id}
      onRowClick={onRowClick}
      persistedSettings={{
        columnOrderSettingKey: HOME_LINK_CONFIG_TABLE_COLUMN_ORDER,
        columnWidthsSettingKey: HOME_LINK_CONFIG_TABLE_COLUMN_WIDTHS,
        sortingSettingKey: HOME_LINK_CONFIG_TABLE_SORTING,
        hiddenColumnsSettingKey: HOME_LINK_CONFIG_TABLE_HIDDEN_COLUMNS,
        defaultColumnWidths: defaultColumnWidths,
      }}
      providers={[
        <ImageTypeProvider key={0} for={['imageUrl']} height={60} width={60} />,
        <DateTimeTypeProvider key={1} for={['updatedDate']} />,
      ]}
      enableExport={true}
      exportFileName={`${t('homeLinks')}.csv`}
    />
  );
};

export default HomeLinksConfigListTable;
