import React, { useEffect, useState } from 'react';
import GenericDialog from './GenericDialog';
import FileUpload from './FileUpload';

type Props = {
  title: string;
  open: boolean;
  onConfirm: (files: File[]) => void;
  onCancel: () => void;
  confirmDisabled?: boolean;
  initialFiles: File[];
  maxFileSizeBytes?: number;
  hint?: string;
  single?: boolean;
  accept?: string;
};

/**
 * Displays File Upload component in a dialog
 * @param title
 * @param open
 * @param onConfirm
 * @param onCancel
 * @param confirmDisabled
 * @param initialFiles
 * @param maxFileSizeBytes
 * @param hint
 * @param single
 * @param accept
 * @constructor
 */
const FileUploadDialog = ({
  title,
  open,
  onConfirm,
  onCancel,
  confirmDisabled,
  initialFiles,
  maxFileSizeBytes,
  hint,
  single,
  accept,
}: Props) => {
  const [files, setFiles] = useState(initialFiles);

  /**
   * Effect that updates internal state when external state changes
   */
  useEffect(() => {
    setFiles(initialFiles);
  }, [initialFiles]);

  /**
   * Updates the state with the new list of files.
   *
   * This function sets the new list of files in the state asynchronously.
   * The state update is deferred using setTimeout to ensure it occurs after
   * the current render cycle completes, preventing any "state update during render" warnings or errors.
   *
   * @param {File[]} newFiles - The new array of files to be set in the state.
   */
  function handleFileChange(newFiles: File[]) {
    setTimeout(() => {
      setFiles(newFiles);
    }, 0);
  }

  /**
   * Called when user confirms changes. Notifies parent of confirmation
   */
  function handleConfirm() {
    onConfirm(files);
  }

  return (
    <GenericDialog
      title={title}
      open={open}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      confirmDisabled={confirmDisabled}
    >
      <FileUpload
        initialFiles={files}
        value={files}
        onChange={handleFileChange}
        maxFileSizeBytes={maxFileSizeBytes}
        hint={hint}
        single={single}
        accept={accept}
      />
    </GenericDialog>
  );
};

export default FileUploadDialog;
