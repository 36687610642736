import {
  copyMCellCollectionToPrivate,
  createMCellCollection,
  createMCellCollectionGlobal,
  deleteMCellCollection,
  getInventoryCenterIdsByManufacturingLocation,
  getInventoryCenters,
  getKeyMCells,
  getMCellCollections,
  getMCells,
  getNotificationSystem,
  getOtherUsersPrivateMCellCollections,
  getPlanningDepartments,
  getProducingLocations,
  getWorkCenterProductionLines,
  renameMCellCollection,
  setInventoryCenterConfigOptions,
  setMCellCollectionGlobal,
  setMCellCollectionPrivate,
  setMCellsOnMCellCollection,
  setNotificationSystem,
  updateKeyMCell,
  updateMCellSortSequence,
  updatePlanningDepartment,
} from './api';
import {
  InventoryCenter,
  KeyMCell,
  KeyMCellUpdate,
  ManufacturingLocationNotificationSystem,
  MCell,
  MCellCollection,
  PlanningDepartment,
  PlanningDepartmentOfInterestUpdate,
  WorkCenterProductionLine,
  ProducingLocation,
} from './types';
import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../api/app.types';
import { createAsyncThunkWithError } from '../../redux/utils';
import { RootState } from '../../store';

export type ManufacturingLocationState = {
  manufacturingLocationsStatus: LoadingStatus;
  inventoryCenters: InventoryCenter[];
  mCells: MCell[];
  mCellSortInfos: MCell[];
  mCellSortInfosStatus: LoadingStatus;
  mCellCollectionsStatus: LoadingStatus;
  mCellCollections: MCellCollection[];
  otherUsersPrivateMCellCollectionsStatus: LoadingStatus;
  otherUsersPrivateMCellCollections: MCellCollection[];
  producingLocations: ProducingLocation[];
  producingLocationsStatus: LoadingStatus;
  planningDepartments: PlanningDepartment[];
  planningDepartmentsStatus: LoadingStatus;
  keyMCellInfos: KeyMCell[];
  keyMCellsStatus: LoadingStatus;
  notificationSystem?: ManufacturingLocationNotificationSystem;
  notificationSystemStatus: LoadingStatus;
  workCenterProductionLines: WorkCenterProductionLine[];
  workCenterProductionLineStatus: LoadingStatus;
  inventoryCenterIdsByManufacturingLocation: string[];
  inventoryCenterIdsByManufacturingLocationStatus: LoadingStatus;
};

export const initialState: ManufacturingLocationState = {
  manufacturingLocationsStatus: 'idle',
  inventoryCenters: [],
  mCells: [],
  mCellSortInfos: [],
  mCellSortInfosStatus: 'idle',
  mCellCollectionsStatus: 'idle',
  mCellCollections: [],
  otherUsersPrivateMCellCollectionsStatus: 'idle',
  otherUsersPrivateMCellCollections: [],
  producingLocations: [],
  producingLocationsStatus: 'idle',
  planningDepartments: [],
  planningDepartmentsStatus: 'idle',
  keyMCellInfos: [],
  keyMCellsStatus: 'idle',
  notificationSystem: undefined,
  notificationSystemStatus: 'idle',
  workCenterProductionLines: [],
  workCenterProductionLineStatus: 'idle',
  inventoryCenterIdsByManufacturingLocation: [],
  inventoryCenterIdsByManufacturingLocationStatus: 'idle',
};

/**
 * Action to set list of Inventory Centers
 */
export const setInventoryCentersAction = createAction<Array<InventoryCenter>>(
  'manufacturingLocation/setInventoryCentersAction',
);

/**
 * Action to set list of MCells
 */
export const setMCellsAction = createAction<Array<MCell>>(
  'manufacturingLocation/setMCellsAction',
);

/**
 * Action to set list of MCell Collections
 */
export const setMCellCollectionsAction = createAction<Array<MCellCollection>>(
  'manufacturingLocation/setMCellCollectionsAction',
);

/**
 * Action to add or update an MCell Collection in the list of MCell Collections
 */
export const addOrUpdateMCellCollectionAction = createAction<MCellCollection>(
  'manufacturingLocation/addOrUpdateMCellCollectionAction',
);

/**
 * Action to delete an MCell Collection from the list of MCell Collections
 */
export const deleteMCellCollectionAction = createAction<string>(
  'manufacturingLocation/deleteMCellCollectionAction',
);

/**
 * Action to update a planning department of Interest
 */
export const updatePlanningDepartmentOfInterestAction =
  createAction<PlanningDepartmentOfInterestUpdate>(
    'manufacturingLocation/updatePlanningDepartmentOfInterestAction',
  );

/**
 * Action to update a Key MCell
 */
export const updateKeyMCellAction = createAction<KeyMCellUpdate>(
  'manufacturingLocation/updateKeyMCellAction',
);

/**
 * Asynchronously fetches data required for manufacturing locations and updates the Redux state.
 * This thunk uses Promise.all to concurrently fetch data from multiple sources.
 * After fetching, it dispatches several actions to update the Redux state with new data.
 *
 * Actions dispatched:
 * - setInventoryCentersAction: Updates the list of inventory centers.
 * - setMCellsAction: Updates the list of MCells.
 * - setMCellCollectionsAction: Updates the list of MCell collections.
 *
 * @param {Object} { dispatch } - Destructured dispatch function to send actions to the Redux store.
 */
export const setManufacturingLocationsThunk = createAsyncThunkWithError(
  'manufacturingLocation/setManufacturingLocationsThunk',
  async (_, { dispatch }) => {
    const results = await Promise.all([
      getInventoryCenters({ includeDisabled: true }),
      getMCells(undefined),
      getMCellCollections(),
    ]);

    const [inventoryCenters, allMCells, mCellCollections] = results;

    dispatch(setInventoryCentersAction(inventoryCenters));
    dispatch(setMCellsAction(allMCells));
    dispatch(setMCellCollectionsAction(mCellCollections));
  },
  'errorFetchingManufacturingLocations',
);

/**
 * Thunk for setting an Inventory Center's Configuration Options
 */
export const setInventoryCenterConfigOptionsThunk = createAsyncThunkWithError(
  'manufacturingLocation/setInventoryCenterConfigOptionsThunk',
  setInventoryCenterConfigOptions,
  'errorSettingInventoryCenterConfigOptions',
);

/**
 * Thunk for fetching Work Center Production Lines
 */
export const getWorkCenterProductionLinesThunk = createAsyncThunkWithError(
  'manufacturingLocation/getWorkCenterProductionLinesThunk',
  getWorkCenterProductionLines,
  'errorFetchingWorkCenterProductionLines',
);

/**
 * Thunk for fetching MCell Collections
 */
export const getMCellCollectionsThunk = createAsyncThunkWithError(
  'manufacturingLocation/getMCellCollectionsThunk',
  getMCellCollections,
  'errorFetchingWorkCellGroups',
);

/**
 * Thunk for fetching other user's private MCell Collections
 */
export const getOtherUsersPrivateMCellCollectionsThunk =
  createAsyncThunkWithError(
    'manufacturingLocation/getOtherUsersPrivateMCellCollectionsThunk',
    getOtherUsersPrivateMCellCollections,
    'errorFetchingWorkCellGroups',
  );

/**
 * Thunk for creating MCell Collection
 */
export const createMCellCollectionThunk = createAsyncThunkWithError(
  'manufacturingLocation/createMCellCollectionThunk',
  createMCellCollection,
  'errorCreatingWorkCellGroup',
);

/**
 * Thunk for creating global MCell Collection
 */
export const createMCellCollectionGlobalThunk = createAsyncThunkWithError(
  'manufacturingLocation/createMCellCollectionGlobalThunk',
  createMCellCollectionGlobal,
  'errorCreatingWorkCellGroupGlobal',
);

/**
 * Thunk for setting an MCell Collection to global
 */
export const setMCellCollectionGlobalThunk = createAsyncThunkWithError(
  'manufacturingLocation/setMCellCollectionGlobalThunk',
  setMCellCollectionGlobal,
  'errorSettingWorkCellGroupGlobal',
);

/**
 * Thunk for setting an MCell Collection to private
 */
export const setMCellCollectionPrivateThunk = createAsyncThunkWithError(
  'manufacturingLocation/setMCellCollectionPrivateThunk',
  setMCellCollectionPrivate,
  'errorSettingWorkCellGroupPrivate',
);

/**
 * Thunk for renaming MCell Collection
 */
export const renameMCellCollectionThunk = createAsyncThunkWithError(
  'manufacturingLocation/renameMCellCollection',
  renameMCellCollection,
  'errorRenamingWorkCellGroup',
);

/**
 * Thunk for setting MCell on MCell Collection
 */
export const setMCellsOnMCellCollectionThunk = createAsyncThunkWithError(
  'manufacturingLocation/setMCellsOnMCellCollectionThunk',
  setMCellsOnMCellCollection,
  'errorSettingWorkCellsOnWorkCellGroup',
);

/**
 * Thunk for copying a global MCell Collection to a new private MCell Collection
 */
export const copyMCellCollectionToPrivateThunk = createAsyncThunkWithError(
  'manufacturingLocation/copyMCellCollectionToPrivateThunk',
  copyMCellCollectionToPrivate,
  'errorCopyingWorkCellGroupToPrivate',
);

/**
 * Thunk for deleting an MCell Collection
 */
export const deleteMCellCollectionThunk = createAsyncThunkWithError(
  'manufacturingLocation/deleteMCellCollectionThunk',
  deleteMCellCollection,
  'errorDeletingWorkCellGroup',
);

/**
 * Thunk for fetching Producing Locations from the backend and setting them in the store
 */
export const getProducingLocationsThunk = createAsyncThunkWithError(
  'manufacturingLocation/getProducingLocationsThunk',
  getProducingLocations,
  'errorFetchingProducingLocations',
);

/**
 * Thunk for fetching Planning Departments
 */
export const getPlanningDepartmentsThunk = createAsyncThunkWithError(
  'manufacturingLocation/getPlanningDepartmentsThunk',
  getPlanningDepartments,
  'errorFetchingPlanningDepartments',
);

/**
 * Thunk for updating Planning Department on server while optimistically
 * updating the local store
 */
export const updatePlanningDepartmentOfInterestThunk =
  createAsyncThunkWithError(
    'manufacturingLocation/updatePlanningDepartmentOfInterestThunk',
    async (
      planningDepartmentOfInterestUpdate: PlanningDepartmentOfInterestUpdate,
      { dispatch },
    ): Promise<PlanningDepartment> => {
      dispatch(
        updatePlanningDepartmentOfInterestAction(
          planningDepartmentOfInterestUpdate,
        ),
      );
      return await updatePlanningDepartment(planningDepartmentOfInterestUpdate);
    },
    'errorUpdatingPlanningDepartment',
  );

/**
 * Thunk for fetching MCells
 */
export const getMCellsThunk = createAsyncThunkWithError(
  'manufacturingLocation/getMCellsThunk',
  getMCells,
  'errorFetchingWorkCells',
);

/**
 * Thunk for updating MCell Sort Sequence optimistically and on server
 */
export const updateMCellSortSequenceThunk = createAsyncThunkWithError(
  'manufacturingLocation/updateMCellSortSequenceThunk',
  updateMCellSortSequence,
  'errorUpdatingWorkCellSortSequence',
);

/**
 * Thunk for fetching Key MCells
 */
export const getKeyMCellsThunk = createAsyncThunkWithError(
  'manufacturingLocation/getKeyMCellsThunk',
  getKeyMCells,
  'errorFetchingKeyWorkCells',
);

/**
 * Thunk for Updating Key MCell optimistically and on server.
 */
export const updateKeyMCellThunk = createAsyncThunkWithError(
  'manufacturingLocation/updateKeyMCellThunk',
  async (keyMCell: KeyMCell, { dispatch }): Promise<KeyMCell> => {
    dispatch(updateKeyMCellAction(keyMCell));
    return await updateKeyMCell(keyMCell);
  },
  'errorUpdatingKeyWorkCell',
);

/**
 * Thunk for fetching Manufacturing Location Notification System
 */
export const getNotificationSystemThunk = createAsyncThunkWithError(
  'manufacturingLocation/getNotificationSystemThunk',
  getNotificationSystem,
  'errorFetchingNotificationSystem',
);

/**
 * Thunk for setting Manufacturing Location Notification System
 */
export const setNotificationSystemThunk = createAsyncThunkWithError(
  'manufacturingLocation/setNotificationSystemThunk',
  setNotificationSystem,
  'errorUpdatingNotificationSystem',
);

/**
 * Thunk for fetching inventory center ids associated with a manufacturing location
 */
export const getInventoryCenterIdsByManufacturingLocationThunk =
  createAsyncThunkWithError(
    'manufacturingLocation/getInventoryCenterIdsByManufacturingLocationThunk',
    getInventoryCenterIdsByManufacturingLocation,
    'errorFetchingInventoryCenterIdsByManufacturingLocation',
  );

export const manufacturingLocationSliceReducer = createSlice({
  name: 'manufacturingLocation',
  initialState,
  reducers: {
    setInventoryCentersAction: (state, action) => {
      state.inventoryCenters = action.payload;
    },
    setMCellsAction: (state, action) => {
      state.mCells = action.payload;
    },
    loadingMCellsAction: (state, action) => {
      state.mCellSortInfosStatus = action.payload;
    },
    setMCellCollectionsAction: (state, action) => {
      state.mCellCollections = action.payload;
    },
    addOrUpdateMCellCollectionAction: (state, action) => {
      addOrUpdateMCellCollection(state, action.payload);
    },
    deleteMCellCollectionAction: (state, action) => {
      const deleteIndex = state.mCellCollections.findIndex(
        (d) => d.id === action.payload,
      );
      if (deleteIndex > -1) {
        state.mCellCollections.splice(deleteIndex, 1);
      }
    },
    setPlanningDepartmentsAction: (state, action) => {
      state.planningDepartments = action.payload;
    },
    updatePlanningDepartmentOfInterestAction: (state, action) => {
      const updateIndex = state.planningDepartments.findIndex(
        (r) => r.id === action.payload.id,
      );
      if (updateIndex > -1) {
        state.planningDepartments[updateIndex].isDepartmentOfInterest =
          action.payload.isDepartmentOfInterest;
      }
    },
    setKeyMCellsAction: (state, action) => {
      state.keyMCellInfos = action.payload;
    },
    loadingKeyMCellsAction: (state, action) => {
      state.keyMCellsStatus = action.payload;
    },
    updateKeyMCellAction: (state, action) => {
      const updateIndex = state.keyMCellInfos.findIndex(
        (r) => r.mCellId === action.payload.mCellId,
      );
      if (updateIndex > -1) {
        state.keyMCellInfos[updateIndex] = action.payload;
      } else {
        state.keyMCellInfos.push(action.payload);
      }
    },
    updateMCellSortSequenceAction: (state, action) => {
      const updateIndex = state.mCellSortInfos.findIndex(
        (x) => x.id === action.payload.id,
      );
      if (updateIndex > -1) {
        state.mCellSortInfos[updateIndex] = action.payload;
      } else {
        state.mCellSortInfos.push(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkCenterProductionLinesThunk.pending, (state) => {
        state.workCenterProductionLineStatus = 'loading';
      })
      .addCase(
        getWorkCenterProductionLinesThunk.fulfilled,
        (state, { payload }) => {
          state.workCenterProductionLineStatus = 'succeeded';
          state.workCenterProductionLines = payload;
        },
      )
      .addCase(getWorkCenterProductionLinesThunk.rejected, (state) => {
        state.workCenterProductionLineStatus = 'failed';
      })

      .addCase(setManufacturingLocationsThunk.pending, (state) => {
        state.manufacturingLocationsStatus = 'loading';
      })
      .addCase(setManufacturingLocationsThunk.fulfilled, (state) => {
        state.manufacturingLocationsStatus = 'succeeded';
      })
      .addCase(setManufacturingLocationsThunk.rejected, (state) => {
        state.manufacturingLocationsStatus = 'failed';
      })
      .addCase(setInventoryCenterConfigOptionsThunk.pending, (state) => {
        state.manufacturingLocationsStatus = 'loading';
      })
      .addCase(
        setInventoryCenterConfigOptionsThunk.fulfilled,
        (state, { payload }) => {
          state.manufacturingLocationsStatus = 'succeeded';
          const updateIndex = state.inventoryCenters.findIndex(
            (ic) => ic.id === payload.id,
          );
          if (updateIndex > -1) {
            state.inventoryCenters[updateIndex] = payload;
          } else {
            state.inventoryCenters.push(payload);
          }
        },
      )
      .addCase(setInventoryCenterConfigOptionsThunk.rejected, (state) => {
        state.manufacturingLocationsStatus = 'failed';
      })
      .addCase(getMCellCollectionsThunk.pending, (state) => {
        state.mCellCollectionsStatus = 'loading';
      })
      .addCase(getMCellCollectionsThunk.fulfilled, (state, { payload }) => {
        state.mCellCollectionsStatus = 'succeeded';
        state.mCellCollections = payload;
      })
      .addCase(getMCellCollectionsThunk.rejected, (state) => {
        state.mCellCollectionsStatus = 'failed';
      })
      .addCase(getOtherUsersPrivateMCellCollectionsThunk.pending, (state) => {
        state.otherUsersPrivateMCellCollectionsStatus = 'loading';
      })
      .addCase(
        getOtherUsersPrivateMCellCollectionsThunk.fulfilled,
        (state, { payload }) => {
          state.otherUsersPrivateMCellCollectionsStatus = 'succeeded';
          state.otherUsersPrivateMCellCollections = payload;
        },
      )
      .addCase(getOtherUsersPrivateMCellCollectionsThunk.rejected, (state) => {
        state.otherUsersPrivateMCellCollectionsStatus = 'failed';
      })
      .addCase(createMCellCollectionThunk.pending, (state) => {
        state.mCellCollectionsStatus = 'loading';
      })
      .addCase(createMCellCollectionThunk.fulfilled, (state, { payload }) => {
        state.mCellCollectionsStatus = 'succeeded';
        state.mCellCollections.push(payload);
      })
      .addCase(createMCellCollectionThunk.rejected, (state) => {
        state.mCellCollectionsStatus = 'failed';
      })
      .addCase(createMCellCollectionGlobalThunk.pending, (state) => {
        state.mCellCollectionsStatus = 'loading';
      })
      .addCase(
        createMCellCollectionGlobalThunk.fulfilled,
        (state, { payload }) => {
          state.mCellCollectionsStatus = 'succeeded';
          state.mCellCollections.push(payload);
        },
      )
      .addCase(createMCellCollectionGlobalThunk.rejected, (state) => {
        state.mCellCollectionsStatus = 'failed';
      })
      .addCase(setMCellCollectionGlobalThunk.pending, (state) => {
        state.mCellCollectionsStatus = 'loading';
      })
      .addCase(
        setMCellCollectionGlobalThunk.fulfilled,
        (state, { payload }) => {
          state.mCellCollectionsStatus = 'succeeded';
          addOrUpdateMCellCollection(state, payload);
        },
      )
      .addCase(setMCellCollectionGlobalThunk.rejected, (state) => {
        state.mCellCollectionsStatus = 'failed';
      })
      .addCase(setMCellCollectionPrivateThunk.pending, (state) => {
        state.mCellCollectionsStatus = 'loading';
      })
      .addCase(
        setMCellCollectionPrivateThunk.fulfilled,
        (state, { payload }) => {
          state.mCellCollectionsStatus = 'succeeded';
          addOrUpdateMCellCollection(state, payload);
        },
      )
      .addCase(setMCellCollectionPrivateThunk.rejected, (state) => {
        state.mCellCollectionsStatus = 'failed';
      })
      .addCase(renameMCellCollectionThunk.pending, (state) => {
        state.mCellCollectionsStatus = 'loading';
      })
      .addCase(renameMCellCollectionThunk.fulfilled, (state, { payload }) => {
        state.mCellCollectionsStatus = 'succeeded';
        addOrUpdateMCellCollection(state, payload);
      })
      .addCase(renameMCellCollectionThunk.rejected, (state) => {
        state.mCellCollectionsStatus = 'failed';
      })
      .addCase(setMCellsOnMCellCollectionThunk.pending, (state) => {
        state.mCellCollectionsStatus = 'loading';
      })
      .addCase(
        setMCellsOnMCellCollectionThunk.fulfilled,
        (state, { payload }) => {
          state.mCellCollectionsStatus = 'succeeded';
          addOrUpdateMCellCollection(state, payload);
        },
      )
      .addCase(setMCellsOnMCellCollectionThunk.rejected, (state) => {
        state.mCellCollectionsStatus = 'failed';
      })
      .addCase(copyMCellCollectionToPrivateThunk.pending, (state) => {
        state.mCellCollectionsStatus = 'loading';
      })
      .addCase(
        copyMCellCollectionToPrivateThunk.fulfilled,
        (state, { payload }) => {
          state.mCellCollectionsStatus = 'succeeded';
          addOrUpdateMCellCollection(state, payload);
        },
      )
      .addCase(copyMCellCollectionToPrivateThunk.rejected, (state) => {
        state.mCellCollectionsStatus = 'failed';
      })
      .addCase(deleteMCellCollectionThunk.pending, (state) => {
        state.mCellCollectionsStatus = 'loading';
      })
      .addCase(deleteMCellCollectionThunk.fulfilled, (state) => {
        state.mCellCollectionsStatus = 'succeeded';
      })
      .addCase(deleteMCellCollectionThunk.rejected, (state) => {
        state.mCellCollectionsStatus = 'failed';
      })
      .addCase(getProducingLocationsThunk.pending, (state) => {
        state.producingLocationsStatus = 'loading';
      })
      .addCase(getProducingLocationsThunk.fulfilled, (state, { payload }) => {
        state.producingLocationsStatus = 'succeeded';
        state.producingLocations = payload;
      })
      .addCase(getProducingLocationsThunk.rejected, (state) => {
        state.producingLocationsStatus = 'failed';
      })
      .addCase(getPlanningDepartmentsThunk.pending, (state) => {
        state.planningDepartmentsStatus = 'loading';
      })
      .addCase(getPlanningDepartmentsThunk.fulfilled, (state, { payload }) => {
        state.planningDepartmentsStatus = 'succeeded';
        state.planningDepartments = payload;
      })
      .addCase(getPlanningDepartmentsThunk.rejected, (state) => {
        state.planningDepartmentsStatus = 'failed';
      })
      .addCase(updatePlanningDepartmentOfInterestThunk.pending, (state) => {
        state.planningDepartmentsStatus = 'loading';
      })
      .addCase(
        updatePlanningDepartmentOfInterestThunk.fulfilled,
        (state, { payload }) => {
          state.planningDepartmentsStatus = 'succeeded';
          const updateIndex = state.planningDepartments.findIndex(
            (c) => c.id === payload.id,
          );
          if (updateIndex > -1) {
            state.planningDepartments[updateIndex] = payload;
          } else {
            state.planningDepartments.push(payload);
          }
        },
      )
      .addCase(updatePlanningDepartmentOfInterestThunk.rejected, (state) => {
        state.planningDepartmentsStatus = 'failed';
      })
      .addCase(getKeyMCellsThunk.pending, (state) => {
        state.keyMCellsStatus = 'loading';
      })
      .addCase(getKeyMCellsThunk.fulfilled, (state, { payload }) => {
        state.keyMCellsStatus = 'succeeded';
        state.keyMCellInfos = payload;
      })
      .addCase(getKeyMCellsThunk.rejected, (state) => {
        state.keyMCellsStatus = 'failed';
      })
      .addCase(updateKeyMCellThunk.pending, (state) => {
        state.keyMCellsStatus = 'loading';
      })
      .addCase(updateKeyMCellThunk.fulfilled, (state, { payload }) => {
        state.keyMCellsStatus = 'succeeded';
        const updateIndex = state.keyMCellInfos.findIndex(
          (c) => c.mCellId === payload.mCellId,
        );
        if (updateIndex > -1) {
          state.keyMCellInfos[updateIndex] = payload;
        } else {
          state.keyMCellInfos.push(payload);
        }
      })
      .addCase(updateKeyMCellThunk.rejected, (state) => {
        state.keyMCellsStatus = 'failed';
      })
      .addCase(getMCellsThunk.pending, (state) => {
        state.mCellSortInfosStatus = 'loading';
      })
      .addCase(getMCellsThunk.fulfilled, (state, { payload }) => {
        state.mCellSortInfosStatus = 'succeeded';
        state.mCells = payload;
      })
      .addCase(getMCellsThunk.rejected, (state) => {
        state.mCellSortInfosStatus = 'failed';
      })
      .addCase(updateMCellSortSequenceThunk.pending, (state) => {
        state.mCellSortInfosStatus = 'loading';
      })
      .addCase(updateMCellSortSequenceThunk.fulfilled, (state) => {
        state.mCellSortInfosStatus = 'succeeded';
      })
      .addCase(updateMCellSortSequenceThunk.rejected, (state) => {
        state.mCellSortInfosStatus = 'failed';
      })
      .addCase(getNotificationSystemThunk.pending, (state) => {
        state.notificationSystemStatus = 'loading';
      })
      .addCase(getNotificationSystemThunk.fulfilled, (state, { payload }) => {
        state.notificationSystemStatus = 'succeeded';
        state.notificationSystem = payload;
      })
      .addCase(getNotificationSystemThunk.rejected, (state) => {
        state.notificationSystemStatus = 'failed';
      })
      .addCase(setNotificationSystemThunk.pending, (state) => {
        state.notificationSystemStatus = 'loading';
      })
      .addCase(setNotificationSystemThunk.fulfilled, (state, { payload }) => {
        state.notificationSystemStatus = 'succeeded';
        state.notificationSystem = payload;
      })
      .addCase(setNotificationSystemThunk.rejected, (state) => {
        state.notificationSystemStatus = 'failed';
      })
      .addCase(
        getInventoryCenterIdsByManufacturingLocationThunk.pending,
        (state) => {
          state.inventoryCenterIdsByManufacturingLocationStatus = 'loading';
        },
      )
      .addCase(
        getInventoryCenterIdsByManufacturingLocationThunk.fulfilled,
        (state, { payload }) => {
          state.inventoryCenterIdsByManufacturingLocationStatus = 'succeeded';
          state.inventoryCenterIdsByManufacturingLocation = payload;
        },
      )
      .addCase(
        getInventoryCenterIdsByManufacturingLocationThunk.rejected,
        (state) => {
          state.inventoryCenterIdsByManufacturingLocationStatus = 'failed';
        },
      );
  },
});

/**
 * Function to add or update an MCell Collection in the store
 * @param state
 * @param mCellCollection
 */
export const addOrUpdateMCellCollection = (
  state: ManufacturingLocationState,
  mCellCollection: MCellCollection,
) => {
  const updateIndex = state.mCellCollections.findIndex(
    (c) => c.id === mCellCollection.id,
  );
  if (updateIndex > -1) {
    state.mCellCollections[updateIndex] = mCellCollection;
  } else {
    state.mCellCollections.push(mCellCollection);
  }
};

/**
 * A memoized selector that returns only enabled inventory centers.
 * @returns A memoized selector that returns a filtered array of inventory centers that are enabled.
 */
export const selectFilteredInventoryCenters = createSelector(
  [(state: RootState) => state.manufacturingLocation.inventoryCenters],
  (inventoryCenters) => inventoryCenters.filter((i) => i.enabled),
);

/**
 * Selector for Work Center Production Lines
 * @param state
 */
export const selectWorkCenterProductionLines = (state: RootState) =>
  state.manufacturingLocation.workCenterProductionLines;

/**
 * Selector for MCells
 * @param state
 */
export const selectMCells = (state: RootState) =>
  state.manufacturingLocation.mCells;

/**
 * A selector factory that returns a memoized selector to filter MCells based on the includeRollupCells flag.
 * @param includeRollupCells - Boolean indicating whether to include rollup cells in the results.
 * @param inventoryCenterId - Optional inventory center id to filter MCells by.
 * @returns A memoized selector that returns a filtered array of MCells.
 *
 * @Note This is particularly useful when multiple similar UI components need to derive different subsets of the data based on props
 */
export const selectFilteredMCells = (
  includeRollupCells: boolean,
  inventoryCenterId?: string,
) =>
  createSelector([selectMCells], (mCells) =>
    mCells.filter(
      (m) =>
        (inventoryCenterId === undefined ||
          m.inventoryCenterId === inventoryCenterId) &&
        (includeRollupCells || !m.isRollup),
    ),
  );

/**
 * Selector to determine whether MCells are loading
 * @param state
 */
export const selectLoadingMCellSortInfos = (state: RootState) =>
  state.manufacturingLocation.mCellSortInfosStatus === 'loading';

/**
 * Selector to determine whether Key MCells are loading
 * @param state
 */
export const selectLoadingKeyMCells = (state: RootState) =>
  state.manufacturingLocation.keyMCellsStatus === 'loading';

/**
 * Selector to determine whether MCell Collections are loading
 * @param state
 */
export const selectLoadingMCellCollections = (state: RootState) =>
  state.manufacturingLocation.mCellCollectionsStatus === 'loading';

/**
 * Selector for MCell Collections
 * @param state
 */
export const selectMCellCollections = (state: RootState) =>
  state.manufacturingLocation.mCellCollections;

/**
 * A selector factory that returns a memoized selector to get MCell collections,
 * optionally including private MCell collections of other users.
 * @param includeAllMCellCollections - Boolean indicating whether to include private MCell collections of other users.
 * @returns A memoized selector that returns an array of MCell Collections.
 *
 * @Note This is particularly useful when multiple similar UI components need to derive different subsets of the data based on props
 */
export const selectFilteredMCellCollections = (
  includeAllMCellCollections: boolean,
) =>
  createSelector(
    [selectMCellCollections, selectOtherUsersPrivateMCellCollections],
    (defaultMCellCollections, otherUsersPrivateMCellCollections) =>
      includeAllMCellCollections
        ? [...defaultMCellCollections, ...otherUsersPrivateMCellCollections]
        : defaultMCellCollections,
  );

/**
 * Selector to determine whether other user's private MCell Collections are
 * loading
 * @param state
 */
export const selectLoadingOtherUsersPrivateMCellCollections = (
  state: RootState,
) =>
  state.manufacturingLocation.otherUsersPrivateMCellCollectionsStatus ===
  'loading';

/**
 * Selector to determine whether other user's private MCell Collections have
 * not been fetched yet
 * @param state
 */
export const selectOtherUsersPrivateMCellCollectionsUnfetched = (
  state: RootState,
) =>
  state.manufacturingLocation.otherUsersPrivateMCellCollectionsStatus ===
  'idle';

/**
 * Selector for other user's private MCell Collections
 * @param state
 */
export const selectOtherUsersPrivateMCellCollections = (state: RootState) =>
  state.manufacturingLocation.otherUsersPrivateMCellCollections;

/**
 * Selector to determine whether notificationSystem is loading
 * @param state
 */
export const selectLoadingNotificationSystem = (state: RootState) =>
  state.manufacturingLocation.notificationSystemStatus === 'loading';

/**
 * Selector for Producing Locations
 * @param state
 */
export const selectProducingLocations = (state: RootState) =>
  state.manufacturingLocation.producingLocations;

/**
 * Selector to determine whether producingLocations are loading
 * @param state
 */
export const selectLoadingProducingLocations = (state: RootState) =>
  state.manufacturingLocation.producingLocationsStatus === 'loading';

/**
 * Selector for Planning Departments
 * @param state
 */
export const selectPlanningDepartments = (state: RootState) =>
  state.manufacturingLocation.planningDepartments;

/**
 * Selector to determine whether planningDepartments are loading
 * @param state
 */
export const selectLoadingPlanningDepartments = (state: RootState) =>
  state.manufacturingLocation.planningDepartmentsStatus === 'loading';

/**
 * Selector to determine whether Manufacturing Locations are loading
 * @param state
 */
export const selectLoadingManufacturingLocations = (state: RootState) =>
  state.manufacturingLocation.manufacturingLocationsStatus === 'loading';

export default manufacturingLocationSliceReducer.reducer;
