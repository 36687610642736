import {
  LswSheetDto,
  LswSheetInfo,
  LswTaskDto,
  LswTaskGroupDto,
  LswTaskInfo,
} from './types';
import {
  createLswSheet,
  createLswTask,
  createLswTaskGroup,
  deleteLswSheet,
  deleteLswTask,
  deleteLswTaskGroup,
  getLswSheetById,
  getLswSheets,
  getLswTaskById,
  getLswTaskGroupById,
  getLswTaskGroups,
  getLswTasks,
  updateLswSheet,
  updateLswTask,
  updateLswTaskGroup,
} from './api';
import { createAsyncThunkWithError } from '../../redux/utils';
import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../api/app.types';
import { RootState } from '../../store';
import { convertTimeOnlyFromUtc, convertTimeOnlyToUtc } from '../../utils/date';

export type LswState = {
  sheetsStatus: LoadingStatus;
  sheets: LswSheetDto[];
  taskGroupsStatus: LoadingStatus;
  taskGroups: LswTaskGroupDto[];
  tasksStatus: LoadingStatus;
  tasks: LswTaskDto[];
};

export const initialState: LswState = {
  sheetsStatus: 'idle',
  sheets: [],
  taskGroupsStatus: 'idle',
  taskGroups: [],
  tasksStatus: 'idle',
  tasks: [],
};

/**
 * Thunk for fetching a single LswSheet by its ID.
 */
export const getLswSheetByIdThunk = createAsyncThunkWithError(
  'lsw/getLswSheetByIdThunk',
  getLswSheetById,
  'errorFetchingLswSheet',
);

/**
 * Thunk for fetching all LswSheets.
 */
export const getLswSheetsThunk = createAsyncThunkWithError(
  'lsw/getLswSheets',
  getLswSheets,
  'errorFetchingLswSheets',
);

/**
 * Thunk for creating a new LswSheet.
 */
export const createLswSheetThunk = createAsyncThunkWithError(
  'home/createLswSheetThunk',
  async (lswSheetInfo: LswSheetInfo) => {
    const response = await createLswSheet({
      ...lswSheetInfo,
      notificationTime: convertTimeOnlyToUtc(lswSheetInfo.notificationTime),
    });
    return {
      ...response,
      notificationTime: convertTimeOnlyFromUtc(response.notificationTime),
    };
  },
  'errorCreatingLswSheet',
);

/**
 * Thunk for updating an existing LswSheet.
 */
export const updateLswSheetThunk = createAsyncThunkWithError(
  'home/updateLswSheetThunk',
  async ({ id, info }: { id: string; info: LswSheetInfo }) => {
    const response = await updateLswSheet({
      id,
      info: {
        ...info,
        notificationTime: convertTimeOnlyToUtc(info.notificationTime),
      },
    });
    return {
      ...response,
      notificationTime: convertTimeOnlyFromUtc(response.notificationTime),
    };
  },
  'errorUpdatingLswSheet',
);

/**
 * Thunk for deleting an existing LswSheet.
 */
export const deleteLswSheetThunk = createAsyncThunkWithError(
  'home/deleteLswSheetThunk',
  deleteLswSheet,
  'errorDeletingLswSheet',
);

/**
 * Thunk for fetching a single LswTaskGroup by its ID.
 */
export const getLswTaskGroupByIdThunk = createAsyncThunkWithError(
  'lsw/getLswTaskGroupByIdThunk',
  getLswTaskGroupById,
  'errorFetchingLswTaskGroup',
);

/**
 * Thunk for fetching all LswTaskGroups.
 */
export const getLswTaskGroupsThunk = createAsyncThunkWithError(
  'lsw/getLswTaskGroups',
  getLswTaskGroups,
  'errorFetchingLswTaskGroups',
);

/**
 * Thunk for creating a new LswTaskGroup.
 */
export const createLswTaskGroupThunk = createAsyncThunkWithError(
  'lsw/createLswTaskGroupThunk',
  createLswTaskGroup,
  'errorCreatingLswTaskGroup',
);

/**
 * Thunk for updating an existing LswTaskGroup.
 */
export const updateLswTaskGroupThunk = createAsyncThunkWithError(
  'lsw/updateLswTaskGroupThunk',
  updateLswTaskGroup,
  'errorUpdatingLswTaskGroup',
);

/**
 * Thunk for deleting an existing LswTaskGroup.
 */
export const deleteLswTaskGroupThunk = createAsyncThunkWithError(
  'lsw/deleteLswTaskGroupThunk',
  deleteLswTaskGroup,
  'errorDeletingLswTaskGroup',
);

/**
 * Thunk for fetching a single LswTask by its ID.
 */
export const getLswTaskByIdThunk = createAsyncThunkWithError(
  'lsw/getLswTaskByIdThunk',
  getLswTaskById,
  'errorFetchingLswTask',
);

/**
 * Thunk for fetching all LswTasks.
 */
export const getLswTasksThunk = createAsyncThunkWithError(
  'lsw/getLswTasks',
  getLswTasks,
  'errorFetchingLswTasks',
);

/**
 * Thunk for creating a new LswTask.
 */
export const createLswTaskThunk = createAsyncThunkWithError(
  'lsw/createLswTaskThunk',
  async (lswTaskInfo: LswTaskInfo) => {
    const response = await createLswTask({
      ...lswTaskInfo,
      occurrenceTime: convertTimeOnlyToUtc(lswTaskInfo.occurrenceTime),
    });
    return {
      ...response,
      occurrenceTime: convertTimeOnlyFromUtc(response.occurrenceTime),
    };
  },
  'errorCreatingLswTask',
);

/**
 * Thunk for updating an existing LswTask.
 */
export const updateLswTaskThunk = createAsyncThunkWithError(
  'lsw/updateLswTaskThunk',
  async ({ id, info }: { id: string; info: LswTaskInfo }) => {
    const response = await updateLswTask({
      id,
      info: {
        ...info,
        occurrenceTime: convertTimeOnlyToUtc(info.occurrenceTime),
      },
    });
    return {
      ...response,
      occurrenceTime: convertTimeOnlyFromUtc(response.occurrenceTime),
    };
  },
  'errorUpdatingLswTask',
);

/**
 * Thunk for deleting an existing LswTask.
 */
export const deleteLswTaskThunk = createAsyncThunkWithError(
  'lsw/deleteLswTaskThunk',
  deleteLswTask,
  'errorDeletingLswTask',
);

export const lswSlice = createSlice({
  name: 'lsw',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLswSheetByIdThunk.pending, (state) => {
        state.sheetsStatus = 'loading';
      })
      .addCase(getLswSheetByIdThunk.fulfilled, (state, { payload }) => {
        state.sheetsStatus = 'succeeded';
        state.sheets = [
          {
            ...payload,
            notificationTime: convertTimeOnlyFromUtc(payload.notificationTime),
          },
        ];
      })
      .addCase(getLswSheetByIdThunk.rejected, (state) => {
        state.sheetsStatus = 'failed';
      })
      .addCase(getLswSheetsThunk.pending, (state) => {
        state.sheetsStatus = 'loading';
      })
      .addCase(getLswSheetsThunk.fulfilled, (state, { payload }) => {
        state.sheetsStatus = 'succeeded';
        state.sheets = payload.map((sheet) => ({
          ...sheet,
          notificationTime: convertTimeOnlyFromUtc(sheet.notificationTime),
        }));
      })
      .addCase(getLswSheetsThunk.rejected, (state) => {
        state.sheetsStatus = 'failed';
      })
      .addCase(createLswSheetThunk.pending, (state) => {
        state.sheetsStatus = 'loading';
      })
      .addCase(createLswSheetThunk.fulfilled, (state, { payload }) => {
        state.sheetsStatus = 'succeeded';
        state.sheets.push(payload);
      })
      .addCase(createLswSheetThunk.rejected, (state) => {
        state.sheetsStatus = 'failed';
      })
      .addCase(updateLswSheetThunk.pending, (state) => {
        state.sheetsStatus = 'loading';
      })
      .addCase(updateLswSheetThunk.fulfilled, (state, { payload }) => {
        state.sheetsStatus = 'succeeded';
        const index = state.sheets.findIndex(
          (sheet) => sheet.id === payload.id,
        );
        state.sheets[index] = payload;
      })
      .addCase(updateLswSheetThunk.rejected, (state) => {
        state.sheetsStatus = 'failed';
      })
      .addCase(deleteLswSheetThunk.pending, (state) => {
        state.sheetsStatus = 'loading';
      })
      .addCase(deleteLswSheetThunk.fulfilled, (state) => {
        state.sheetsStatus = 'succeeded';
      })
      .addCase(deleteLswSheetThunk.rejected, (state) => {
        state.sheetsStatus = 'failed';
      })
      .addCase(getLswTaskGroupByIdThunk.pending, (state) => {
        state.taskGroupsStatus = 'loading';
      })
      .addCase(getLswTaskGroupByIdThunk.fulfilled, (state, { payload }) => {
        state.taskGroupsStatus = 'succeeded';
        state.taskGroups = [payload];
      })
      .addCase(getLswTaskGroupByIdThunk.rejected, (state) => {
        state.taskGroupsStatus = 'failed';
      })
      .addCase(getLswTaskGroupsThunk.pending, (state) => {
        state.taskGroupsStatus = 'loading';
      })
      .addCase(getLswTaskGroupsThunk.fulfilled, (state, { payload }) => {
        state.taskGroupsStatus = 'succeeded';
        state.taskGroups = payload;
      })
      .addCase(getLswTaskGroupsThunk.rejected, (state) => {
        state.taskGroupsStatus = 'failed';
      })
      .addCase(createLswTaskGroupThunk.pending, (state) => {
        state.taskGroupsStatus = 'loading';
      })
      .addCase(createLswTaskGroupThunk.fulfilled, (state, { payload }) => {
        state.taskGroupsStatus = 'succeeded';
        state.taskGroups.push(payload);
      })
      .addCase(createLswTaskGroupThunk.rejected, (state) => {
        state.taskGroupsStatus = 'failed';
      })
      .addCase(updateLswTaskGroupThunk.pending, (state) => {
        state.taskGroupsStatus = 'loading';
      })
      .addCase(updateLswTaskGroupThunk.fulfilled, (state, { payload }) => {
        state.taskGroupsStatus = 'succeeded';
        const index = state.taskGroups.findIndex(
          (taskGroup) => taskGroup.id === payload.id,
        );
        state.taskGroups[index] = payload;
      })
      .addCase(updateLswTaskGroupThunk.rejected, (state) => {
        state.taskGroupsStatus = 'failed';
      })
      .addCase(deleteLswTaskGroupThunk.pending, (state) => {
        state.taskGroupsStatus = 'loading';
      })
      .addCase(deleteLswTaskGroupThunk.fulfilled, (state) => {
        state.taskGroupsStatus = 'succeeded';
      })
      .addCase(deleteLswTaskGroupThunk.rejected, (state) => {
        state.taskGroupsStatus = 'failed';
      })
      .addCase(getLswTaskByIdThunk.pending, (state) => {
        state.tasksStatus = 'loading';
      })
      .addCase(getLswTaskByIdThunk.fulfilled, (state, { payload }) => {
        state.tasksStatus = 'succeeded';
        state.tasks = [
          {
            ...payload,
            occurrenceTime: convertTimeOnlyFromUtc(payload.occurrenceTime),
          },
        ];
      })
      .addCase(getLswTaskByIdThunk.rejected, (state) => {
        state.tasksStatus = 'failed';
      })
      .addCase(getLswTasksThunk.pending, (state) => {
        state.tasksStatus = 'loading';
      })
      .addCase(getLswTasksThunk.fulfilled, (state, { payload }) => {
        state.tasksStatus = 'succeeded';
        state.tasks = payload.map((task) => ({
          ...task,
          occurrenceTime: convertTimeOnlyFromUtc(task.occurrenceTime),
        }));
      })
      .addCase(getLswTasksThunk.rejected, (state) => {
        state.tasksStatus = 'failed';
      })
      .addCase(createLswTaskThunk.pending, (state) => {
        state.tasksStatus = 'loading';
      })
      .addCase(createLswTaskThunk.fulfilled, (state, { payload }) => {
        state.tasksStatus = 'succeeded';
        state.tasks.push(payload);
      })
      .addCase(createLswTaskThunk.rejected, (state) => {
        state.tasksStatus = 'failed';
      })
      .addCase(updateLswTaskThunk.pending, (state) => {
        state.tasksStatus = 'loading';
      })
      .addCase(updateLswTaskThunk.fulfilled, (state, { payload }) => {
        state.tasksStatus = 'succeeded';
        const index = state.tasks.findIndex((task) => task.id === payload.id);
        state.tasks[index] = payload;
      })
      .addCase(updateLswTaskThunk.rejected, (state) => {
        state.tasksStatus = 'failed';
      })
      .addCase(deleteLswTaskThunk.pending, (state) => {
        state.tasksStatus = 'loading';
      })
      .addCase(deleteLswTaskThunk.fulfilled, (state) => {
        state.tasksStatus = 'succeeded';
      })
      .addCase(deleteLswTaskThunk.rejected, (state) => {
        state.tasksStatus = 'failed';
      });
  },
});

/**
 * Selector to determine if the LswSheets are currently loading
 * @param state
 */
export const selectLswSheetsStatus = (state: RootState) =>
  state.lsw.sheetsStatus === 'loading';

/**
 * Selector to retrieve LswSheets from the store
 * @param state
 */
export const selectLswSheets = (state: RootState) => state.lsw.sheets;

/**
 * Selector to determine if the LswTaskGroups are currently loading
 * @param state
 */
export const selectLswTaskGroupsStatus = (state: RootState) =>
  state.lsw.taskGroupsStatus === 'loading';

/**
 * Selector to retrieve LswTaskGroups from the store
 * @param state
 */
export const selectLswTaskGroups = (state: RootState) => state.lsw.taskGroups;

/**
 * Selector to determine if the LswTasks are currently loading
 * @param state
 */
export const selectLswTasksStatus = (state: RootState) =>
  state.lsw.tasksStatus === 'loading';

/**
 * Selector to retrieve LswTasks from the store
 * @param state
 */
export const selectLswTasks = (state: RootState) => state.lsw.tasks;

export default lswSlice.reducer;
