import React from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardMedia, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PREFIX = 'ImageCard';

const styles = {
  imageArea: `${PREFIX}-imageArea`,
  image: `${PREFIX}-image`,
  noImage: `${PREFIX}-noImage`,
};

const StyledUrlProfileDiv = styled('div')(({ theme }) => ({
  [`& .${styles.imageArea}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${styles.image}`]: {
    cursor: 'pointer',
    maxHeight: '90%',
    maxWidth: '90%',
    objectFit: 'contain',
  },

  [`& .${styles.noImage}`]: {
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
}));

type Props = {
  src?: string;
  width?: number | string;
  height?: number | string;
};

/**
 * Renders an image in a card or NO IMAGE
 * @param src
 * @param height
 * @param width
 * @constructor
 */
const ImageCard = ({ src, height, width }: Props) => {
  const { t } = useTranslation();
  return (
    <StyledUrlProfileDiv>
      {src ? (
        <Card
          className={styles.imageArea}
          sx={{
            height: height || 175,
            width,
          }}
          elevation={2}
        >
          <CardMedia
            className={styles.image}
            component='img'
            image={src}
            alt={t('profileImage')}
          ></CardMedia>
        </Card>
      ) : (
        <Typography className={styles.noImage} variant={'h6'}>
          {t('noImage')}
        </Typography>
      )}
    </StyledUrlProfileDiv>
  );
};

export default ImageCard;
