import { User } from '../profile/types';
import { Shift } from '../resourceTracking/types';

export type InventoryCenter = {
  id: string;
  code: string;
  description: string;
  displayName?: string | null;
  enabled: boolean;
};

export type InventoryCenterConfigOptions = Pick<
  InventoryCenter,
  'displayName' | 'enabled'
>;

export type PlanningDepartment = {
  id: string;
  code: string;
  description: string;
  isDepartmentOfInterest: boolean;
  inventoryCenter?: InventoryCenter;
};

export type MCell = {
  id: string;
  code: string;
  description: string;
  inventoryCenter: InventoryCenter;
  inventoryCenterId: string;
  isRollup: boolean;
  componentMCells: Array<MCellRollupComponentJoin>;
  sortSequence: number;
};

export type MCellRollupComponentJoin = {
  rollupId: string;
  componentId: string;
  component: MCell;
};

export type MCellSortSequenceUpdate = {
  sortSequence: number;
};

export type MCellDto = {
  id: string;
  code: string;
  description: string;
  inventoryCenterId: string;
  isRollup: boolean;
};

export type MCellCollectionMCell = {
  mCellCollectionId: string;
  mCellId: string;
  mCell: MCell;
};

export type MCellCollection = {
  id: string;
  name: string;
  createdBy: User;
  mCellCollectionMCell: Array<MCellCollectionMCell>;
  isGlobal: boolean;
};

export type MCellCollectionCreateType = {
  name: string;
  mCellIds: Array<string>;
};

export type ManufacturingLocation = {
  mCellId: string;
  inventoryCenterId: string;
  mCellCollectionId: string;
};

export type WorkCenterProductionLineManufacturingLocation = {
  mCellId: string;
  inventoryCenterId: string;
  workCenterProductionLineId: string;
};

export type PlanningDepartmentOfInterestUpdate = {
  id: string;
  isDepartmentOfInterest: boolean;
};

export type KeyMCell = {
  mCell: MCell;
  mCellId: string;
  isKey: boolean;
};

export type KeyMCellUpdate = Omit<KeyMCell, 'mCell'>;

export const ticketSystems = ['Jira', 'Email'] as const;
export type TicketSystem = typeof ticketSystems[number];

export type ManufacturingLocationNotificationSystem = {
  id: string;
  inventoryCenter?: InventoryCenter;
  inventoryCenterId: string;
  ticketSystem: TicketSystem;
};

export type ManufacturingLocationNotificationSystemInfo = {
  inventoryCenterId: string;
  ticketSystem: TicketSystem;
};

export type ManufacturingLocationTimeRangeShiftFilter = {
  mCellId?: string;
  inventoryCenterId?: string;
  mCellCollectionId?: string;
  shifts?: Shift[];
  startDate?: Date | null;
  endDate?: Date | null;
};

export type WorkCenter = {
  id: string;
  icimKey: string;
  producingLocationId: string;
  code: string;
  description: string;
  active: boolean;
};

export type ProductionLine = {
  id: string;
  icimKey: string;
  producingLocationId: string;
  code: string;
  description: string;
  active: boolean;
};

export type WorkCenterProductionLine = {
  id: string;
  icimKey: string;
  workCenter?: WorkCenter;
  workCenterId: string;
  productionLine?: ProductionLine;
  productionLineId?: string;
  mCell?: MCell;
  mCellId?: string;
  active: boolean;
};

export type ProducingLocation = {
  id: string;
  icimKey: string;
  code: string;
  description: string;
  planningDepartment: PlanningDepartment;
  planningDepartmentId: string;
};
