import { config } from '../config';
import { powerConfig } from '../features/dashboard/powerConfig';
import { IPermission, IRole, permissionMap } from './permissions';
import { AccountInfo, PublicClientApplication } from '@azure/msal-browser';
import { useEffect, useState } from 'react';

let msalInstance: PublicClientApplication;
// Hack to ensure that MSAL is not instantiated during testing because
// `crypto` global object is not available in the JSDOM testing environment.
// Attempting to mock the crypto object did not work either because the instantiation
// occurs prior to mock getting injected.
if (process.env.NODE_ENV !== 'test') {
  msalInstance = new PublicClientApplication({
    auth: {
      clientId: config.auth.clientId,
      authority: config.auth.authority,
      redirectUri: window.location.origin,
    },
  });
  msalInstance
    .initialize()
    .catch((e) => console.error('MSAL initialization failed: ', e));
} else {
  msalInstance = {} as PublicClientApplication;
}

export const getAccessToken = async () => {
  try {
    await msalInstance.handleRedirectPromise();
    const response = await msalInstance.acquireTokenSilent({
      scopes: [config.api.scope],
    });
    return response.accessToken;
  } catch (e) {
    console.log('Error: ', e);
    await msalInstance.acquireTokenRedirect({ scopes: [config.api.scope] });
  }
};

export const getPowerBiAccessToken = async () => {
  try {
    await msalInstance.handleRedirectPromise();
    const response = await msalInstance.acquireTokenSilent({
      scopes: [powerConfig.api.scope, powerConfig.api.scope2],
    });
    return response.accessToken;
  } catch (e) {
    console.log('Error: ', e);
    await msalInstance.acquireTokenRedirect({
      scopes: [powerConfig.api.scope, powerConfig.api.scope2],
    });
  }
};

/**
 * Custom React hook that manages the authentication state using MSAL (Microsoft Authentication Library).
 * It initializes the account state, handles the authentication redirect response,
 * and initiates a login redirect if no user account is detected.
 *
 * @returns {AccountInfo|undefined} The current user's account object if authenticated, otherwise undefined.
 */
export const useAuth = (): AccountInfo | undefined => {
  const [account, setAccount] = useState(getAccount());
  useEffect(() => {
    if (!account) {
      msalInstance.handleRedirectPromise().then(() => {
        const currentAccount = msalInstance.getAllAccounts()[0] || undefined;
        setAccount(currentAccount);
        if (!currentAccount) {
          msalInstance
            .loginRedirect({ scopes: [config.api.scope] })
            .catch((e) => {
              console.error('Redirect for login failed: ', e);
            });
        }
      });
    }
  }, [account]);

  return account;
};

export const logout = () => {
  msalInstance.handleRedirectPromise().then(() => {
    msalInstance.logoutRedirect();
  });
};

export const getAccount = () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
    return accounts[0];
  }
};

/**
 * Returns true if currently logged in user is a developer. Otherwise returns false
 */
export const isDeveloper = () => {
  const account = getAccount();
  if (!account) {
    return false;
  }
  console.log('account: ', account);
  // @ts-ignore
  if (!account.idTokenClaims?.roles) {
    return false;
  }

  // @ts-ignore
  return account.idTokenClaims?.roles.includes('Dev');
};

/**
 * Gets list of roles for current user account
 */
export const getAccountRoles = (): IRole[] => {
  const account = getAccount();
  if (!account) {
    return [];
  }
  // @ts-ignore
  if (!account?.idTokenClaims?.roles) {
    return [];
  }
  // @ts-ignore
  return account.idTokenClaims.roles as any;
};

/**
 * Checks whether current user has at least one of the specified permissions.
 * @param permissions
 */
export const hasPermission = (permissions: IPermission[]): boolean => {
  const accountRoles: IRole[] = getAccountRoles();
  let validRoles: IRole[] = [];
  permissions.forEach((p) => validRoles.push(...permissionMap[p]));
  return accountRoles.filter((r) => validRoles.includes(r)).length > 0;
};
