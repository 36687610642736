import {
  addSharedUser,
  addWidgetsToDashboard,
  copyDashboardToPrivate,
  createDashboard,
  deleteDashboard,
  getDashboardById,
  getDashboards,
  getWidgetTypes,
  leaveShare,
  moveWidget,
  removeSharedUser,
  removeWidgetsFromDashboard,
  renameDashboard,
  renameWidget,
  setDashboardLayout,
  setDashboardToGlobal,
  setDashboardToPrivate,
  setWidgetMeta,
} from './api';
import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../api/app.types';
import { createAsyncThunkWithError } from '../../redux/utils';
import { reIndexWidget } from './utils';
import { CcWidgetMove, Dashboard, WidgetType } from './types';
import { RootState } from '../../store';

export type CommandCenterState = {
  dashboardsStatus: LoadingStatus;
  dashboards: Array<Dashboard>;
  widgetTypes: Array<WidgetType>;
};

export const initialState: CommandCenterState = {
  dashboardsStatus: 'idle',
  dashboards: [],
  widgetTypes: [],
};

/**
 * Action to add or update a Dashboard in the list of Dashboards
 */
export const addOrUpdateDashboardAction = createAction<Dashboard>(
  'dashboard/addOrUpdateDashboardAction',
);

/**
 * Action to delete Dashboard from the list of Dashboards
 */
export const deleteDashboardAction = createAction<string>(
  'dashboard/deleteDashboardAction',
);

/**
 * Action to optimistically move widget from one position to another within a dashboard
 */
export const moveWidgetAction = createAction<{
  dashboardId: string;
  widgetMove: CcWidgetMove;
}>('dashboard/moveWidgetAction');

/**
 * Action to optimistically set the layout of a dashboard
 */
export const setDashboardLayoutAction = createAction<{
  dashboardId: string;
  newLayout: string;
}>('dashboard/setDashboardLayoutAction');

/**
 * Action to list of widget types
 */
export const setWidgetTypesAction = createAction<WidgetType[]>(
  'dashboard/setWidgetTypesAction',
);

/**
 * Thunk for getting list of dashboards from the server
 */
export const getDashboardsThunk = createAsyncThunkWithError(
  'dashboard/getDashboardsThunk',
  getDashboards,
  'errorGetDashboardsThunk',
);

/**
 * Thunk for getting a dashboard by id from the server
 */
export const getDashBoardByIdThunk = createAsyncThunkWithError(
  'dashboard/getDashBoardByIdThunk',
  getDashboardById,
  'errorGetDashBoardByIdThunk',
);

/**
 * Thunk for creating a dashboard on the server
 */
export const createDashboardThunk = createAsyncThunkWithError(
  'dashboard/createDashboardThunk',
  createDashboard,
  'errorCreateDashboardThunk',
);

/**
 * Thunk to optimistically move widget from one position to another within
 * a dashboard and set it on the server as well
 */
export const moveWidgetThunk = createAsyncThunkWithError(
  'dashboard/moveWidgetThunk',
  async (
    params: { dashboardId: string; widgetMove: CcWidgetMove },
    { dispatch },
  ) => {
    dispatch(moveWidgetAction(params));
    await moveWidget(params.dashboardId, params.widgetMove);
  },
  'errorMoveWidgetThunk',
);

/**
 * Thunk to optimistically set the column layout of a dashboard and set it on the
 * server as well.
 */
export const setDashboardLayoutThunk = createAsyncThunkWithError(
  'dashboard/setDashboardLayoutThunk',
  async (params: { dashboardId: string; newLayout: string }, { dispatch }) => {
    dispatch(setDashboardLayoutAction(params));
    await setDashboardLayout(params.dashboardId, params.newLayout);
  },
  'errorSetDashboardLayoutThunk',
);

/**
 * Thunk to delete a dashboard on the server
 */
export const deleteDashboardThunk = createAsyncThunkWithError(
  'dashboard/deleteDashboardThunk',
  deleteDashboard,
  'errorDeleteDashboardThunk',
);

/**
 * Thunk to set the title of a dashboard on the server
 */
export const renameDashboardThunk = createAsyncThunkWithError(
  'dashboard/renameDashboardThunk',
  renameDashboard,
  'errorRenameDashboardThunk',
);

/**
 * Thunk for getting list of widgetTypes
 */
export const getWidgetTypesThunk = createAsyncThunkWithError(
  'dashboard/getWidgetTypesThunk',
  async (_: void, { dispatch }): Promise<void> => {
    const data = getWidgetTypes();
    dispatch(setWidgetTypesAction(await data));
  },
  'errorGetWidgetTypesThunk',
);

/**
 * Thunk to add widgets to a dashboard on the server
 */
export const addWidgetsToDashboardThunk = createAsyncThunkWithError(
  'dashboard/addWidgetsToDashboardThunk',
  addWidgetsToDashboard,
  'errorAddWidgetsToDashboardThunk',
);

/**
 * Thunk to remove widgets from a dashboard on the server
 */
export const removeWidgetsFromDashboardThunk = createAsyncThunkWithError(
  'dashboard/removeWidgetsFromDashboardThunk',
  removeWidgetsFromDashboard,
  'errorRemoveWidgetsFromDashboardThunk',
);

/**
 * Thunk to set widget title on the server
 */
export const renameWidgetThunk = createAsyncThunkWithError(
  'dashboard/renameWidgetThunk',
  renameWidget,
  'errorRenameWidgetThunk',
);

/**
 * Thunk to save widget meta data
 */
export const setWidgetMetaThunk = createAsyncThunkWithError(
  'dashboard/setWidgetMetaThunk',
  setWidgetMeta,
  'errorSetWidgetMetaThunk',
);

/**
 * Thunk to set Dashboard to private
 */
export const setDashboardToPrivateThunk = createAsyncThunkWithError(
  'dashboard/setDashboardToPrivateThunk',
  setDashboardToPrivate,
  'errorSetDashboardToPrivateThunk',
);

/**
 * Thunk to set Dashboard to global
 */
export const setDashboardToGlobalThunk = createAsyncThunkWithError(
  'dashboard/setDashboardToGlobalThunk',
  setDashboardToGlobal,
  'errorSetDashboardToGlobalThunk',
);

/**
 * Thunk to copy global Dashboard to private
 */
export const copyDashboardToPrivateThunk = createAsyncThunkWithError(
  'dashboard/copyDashboardToPrivateThunk',
  copyDashboardToPrivate,
  'errorCopyDashboardToPrivateThunk',
);

/**
 * Thunk to add shared User to Dashboard
 */
export const addSharedUserThunk = createAsyncThunkWithError(
  'dashboard/addSharedUserThunk',
  addSharedUser,
  'errorAddSharedUserThunk',
);

/**
 * Thunk to remove shared User from dashboard
 */
export const removeSharedUserThunk = createAsyncThunkWithError(
  'dashboard/removeSharedUserThunk',
  removeSharedUser,
  'errorRemoveSharedUserThunk',
);

/**
 * Thunk to leave a shared Dashboard
 */
export const leaveShareThunk = createAsyncThunkWithError(
  'dashboard/leaveShareThunk',
  leaveShare,
  'errorLeaveShareThunk',
);

/**
 * Redux slice for the dashboard feature
 */
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    addOrUpdateDashboardAction: (state, action) => {
      addOrUpdateDashboard(state, action.payload);
    },
    deleteDashboardAction: (state, action) => {
      const deleteIndex = state.dashboards.findIndex(
        (d) => d.id === action.payload,
      );
      if (deleteIndex > -1) {
        state.dashboards.splice(deleteIndex, 1);
      }
    },
    moveWidgetAction: (state, action) => {
      const index = state.dashboards.findIndex(
        (d) => d.id === action.payload.dashboardId,
      );
      if (index > -1) {
        const widgets = state.dashboards[index].widgets;
        state.dashboards[index].widgets = reIndexWidget(
          widgets,
          action.payload.widgetMove,
        );
      }
    },
    setDashboardLayoutAction: (state, action) => {
      addOrUpdateDashboard(state, action.payload);
    },
    setWidgetTypesAction: (state, action) => {
      state.widgetTypes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardsThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(getDashboardsThunk.fulfilled, (state, { payload }) => {
        state.dashboardsStatus = 'succeeded';
        state.dashboards = payload;
      })
      .addCase(getDashboardsThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(getDashBoardByIdThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(getDashBoardByIdThunk.fulfilled, (state) => {
        state.dashboardsStatus = 'succeeded';
      })
      .addCase(getDashBoardByIdThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(createDashboardThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(createDashboardThunk.fulfilled, (state) => {
        state.dashboardsStatus = 'succeeded';
      })
      .addCase(createDashboardThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(moveWidgetThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(moveWidgetThunk.fulfilled, (state) => {
        state.dashboardsStatus = 'succeeded';
      })
      .addCase(moveWidgetThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(setDashboardLayoutThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(setDashboardLayoutThunk.fulfilled, (state) => {
        state.dashboardsStatus = 'succeeded';
      })
      .addCase(setDashboardLayoutThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(deleteDashboardThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(deleteDashboardThunk.fulfilled, (state) => {
        state.dashboardsStatus = 'succeeded';
      })
      .addCase(deleteDashboardThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(renameDashboardThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(renameDashboardThunk.fulfilled, (state, { payload }) => {
        state.dashboardsStatus = 'succeeded';
        addOrUpdateDashboard(state, payload);
      })
      .addCase(renameDashboardThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(getWidgetTypesThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(getWidgetTypesThunk.fulfilled, (state) => {
        state.dashboardsStatus = 'succeeded';
      })
      .addCase(getWidgetTypesThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(addWidgetsToDashboardThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(addWidgetsToDashboardThunk.fulfilled, (state, { payload }) => {
        state.dashboardsStatus = 'succeeded';
        addOrUpdateDashboard(state, payload);
      })
      .addCase(addWidgetsToDashboardThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(removeWidgetsFromDashboardThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(
        removeWidgetsFromDashboardThunk.fulfilled,
        (state, { payload }) => {
          state.dashboardsStatus = 'succeeded';
          addOrUpdateDashboard(state, payload);
        },
      )
      .addCase(removeWidgetsFromDashboardThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(renameWidgetThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(renameWidgetThunk.fulfilled, (state, { payload }) => {
        state.dashboardsStatus = 'succeeded';
        addOrUpdateDashboard(state, payload);
      })
      .addCase(renameWidgetThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(setWidgetMetaThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(setWidgetMetaThunk.fulfilled, (state, { payload }) => {
        state.dashboardsStatus = 'succeeded';
        addOrUpdateDashboard(state, payload);
      })
      .addCase(setWidgetMetaThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(setDashboardToPrivateThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(setDashboardToPrivateThunk.fulfilled, (state, { payload }) => {
        state.dashboardsStatus = 'succeeded';
        addOrUpdateDashboard(state, payload);
      })
      .addCase(setDashboardToPrivateThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(setDashboardToGlobalThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(setDashboardToGlobalThunk.fulfilled, (state, { payload }) => {
        state.dashboardsStatus = 'succeeded';
        addOrUpdateDashboard(state, payload);
      })
      .addCase(setDashboardToGlobalThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(copyDashboardToPrivateThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(copyDashboardToPrivateThunk.fulfilled, (state, { payload }) => {
        state.dashboardsStatus = 'succeeded';
        addOrUpdateDashboard(state, payload);
      })
      .addCase(copyDashboardToPrivateThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(addSharedUserThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(addSharedUserThunk.fulfilled, (state, { payload }) => {
        state.dashboardsStatus = 'succeeded';
        addOrUpdateDashboard(state, payload);
      })
      .addCase(addSharedUserThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(removeSharedUserThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(removeSharedUserThunk.fulfilled, (state, { payload }) => {
        state.dashboardsStatus = 'succeeded';
        addOrUpdateDashboard(state, payload);
      })
      .addCase(removeSharedUserThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      })
      .addCase(leaveShareThunk.pending, (state) => {
        state.dashboardsStatus = 'loading';
      })
      .addCase(leaveShareThunk.fulfilled, (state, { payload }) => {
        state.dashboardsStatus = 'succeeded';
        addOrUpdateDashboard(state, payload);
      })
      .addCase(leaveShareThunk.rejected, (state) => {
        state.dashboardsStatus = 'failed';
      });
  },
});

/**
 * Function to add or update a Dashboard
 * @param state
 * @param dashboard
 */
export const addOrUpdateDashboard = (
  state: CommandCenterState,
  dashboard: Dashboard,
) => {
  const updateIndex = state.dashboards.findIndex((d) => d.id === dashboard.id);
  if (updateIndex > -1) {
    state.dashboards[updateIndex] = dashboard;
  } else {
    state.dashboards.push(dashboard);
  }
};

/**
 * Selector to select dashboards
 * @param state
 */
export const selectDashboards = (state: RootState) =>
  state.dashboard.dashboards;

/**
 * Selector to determine whether dashboard is loading
 * @param state
 */
export const selectLoadingDashboard = (state: RootState) =>
  state.dashboard.dashboardsStatus === 'loading';

/**
 * Base selector to select the dashboard widget types
 * @param state
 */
export const selectWidgetTypes = (state: RootState) =>
  state.dashboard.widgetTypes;

/**
 * Factory function to create a memoized selector that retrieves filtered widget types from the dashboard,
 * excluding any types associated with metrics that are disabled based on the crewingGuideEnabled flag.
 * This setup allows for the dynamic integration of runtime conditions into the selector.
 *
 * @param {boolean} crewingGuideEnabled - Dynamically determines if certain metrics should be disabled,
 * which affects which widget types are available in the dashboard.
 * @returns A selector that takes RootState as its argument and returns an array of
 * enabled widget types after filtering out the disabled metrics.
 */
export const makeSelectFilteredWidgetTypes = (crewingGuideEnabled: boolean) =>
  createSelector([selectWidgetTypes], (widgetTypes) => {
    const disabledWidgetTypeMetrics = [
      crewingGuideEnabled ? undefined : 'MCellCurrentCapacities',
    ].filter(Boolean); // This removes any undefined entries from the array

    return widgetTypes.filter(
      (w) => !disabledWidgetTypeMetrics.includes(w.metric),
    );
  });

export default dashboardSlice.reducer;
