import axiosInstance from '../../api';
import {
  HomeLinkDto,
  HomeLinkCreate,
  HomeLinkUpdate,
  HomeLinkGroupDto,
  HomeLinkGroupCreate,
  HomeLinkGroupUpdate,
} from './types';
import { ManufacturingLocation } from '../manufacturingLocation/types';

/**
 * API call to update a Home Link
 * @param params
 */
export const updateHomeLink = async (params: {
  id: string;
  info: HomeLinkUpdate;
}): Promise<HomeLinkDto> => {
  const response = await axiosInstance.post(
    `/api/Home/link/${params.id}`,
    params.info,
  );
  return response.data;
};

/**
 * API call to fetch Home Links by id
 * @param id
 */
export const getHomeLink = async (id: string): Promise<HomeLinkDto> => {
  const response = await axiosInstance.get<HomeLinkDto>(`/api/Home/link/${id}`);
  return response.data;
};

/**
 * API call to delete Home Link by id
 * @param homeLinkId
 */
export const deleteHomeLink = async (homeLinkId: string): Promise<string> => {
  const response = await axiosInstance.delete(`/api/Home/link/${homeLinkId}`);
  return response.data;
};

/**
 * API call to get Home Links by ManufacturingLocation
 * @param params
 */
export const getHomeLinks = async (
  params: ManufacturingLocation,
): Promise<Array<HomeLinkDto>> => {
  const response = await axiosInstance.get<Array<HomeLinkDto>>(
    `/api/Home/link/`,
    {
      params,
    },
  );
  return response.data;
};

/**
 * API call to create Home Link
 * @param homeLinkCreate
 */
export const createHomeLink = async (
  homeLinkCreate: HomeLinkCreate,
): Promise<HomeLinkDto> => {
  const formData = new FormData();
  formData.append('inventoryCenterId', homeLinkCreate.inventoryCenterId);
  formData.append('mCellId', homeLinkCreate.mCellId);
  formData.append('mCellCollectionId', homeLinkCreate.mCellCollectionId);
  formData.append('title', homeLinkCreate.title);
  formData.append('linkType', homeLinkCreate.linkType);
  formData.append('linkUrl', homeLinkCreate.linkUrl);
  formData.append('sortSequence', homeLinkCreate.sortSequence.toString());
  if (homeLinkCreate.homeLinkGroupId) {
    formData.append('homeLinkGroupId', homeLinkCreate.homeLinkGroupId);
  }
  if (homeLinkCreate.file) {
    formData.append('file', homeLinkCreate.file);
  }
  if (homeLinkCreate.image) {
    formData.append('image', homeLinkCreate.image);
  }
  const response = await axiosInstance.post('/api/Home/link', formData);
  return response.data;
};

/**
 * API call to update Home Link Image
 * @param params
 */
export const updateImage = async (params: {
  id: string;
  image: File[];
}): Promise<HomeLinkDto> => {
  const formData = new FormData();
  formData.append('image', params.image[0]);
  const response = await axiosInstance.post(
    `/api/Home/link/${params.id}/image`,
    formData,
  );
  return response.data;
};

/**
 * API call to clear Image from Home Link object
 * @param id
 */
export const clearImage = async (id: string): Promise<HomeLinkDto> => {
  const response = await axiosInstance.post(`/api/Home/link/${id}/deleteImage`);
  return response.data;
};

/**
 * API call to update Home Link File
 * @param params
 */
export const updateFile = async (params: {
  id: string;
  file: File[];
}): Promise<HomeLinkDto> => {
  const formData = new FormData();
  formData.append('file', params.file[0]);
  const response = await axiosInstance.post(
    `/api/Home/link/${params.id}/file`,
    formData,
  );

  return response.data;
};

/**
 * API call to clear File from Home Link object
 * @param id
 */
export const clearFile = async (id: string): Promise<HomeLinkDto> => {
  const response = await axiosInstance.post(`/api/Home/link/${id}/deleteFile`);
  return response.data;
};

/**
 * API call to fetch Home Link Groups by id
 * @param id
 */
export const getHomeLinkGroup = async (
  id: string,
): Promise<HomeLinkGroupDto> => {
  const response = await axiosInstance.get<HomeLinkGroupDto>(
    `/api/Home/linkGroup/${id}`,
  );
  return response.data;
};

/**
 * API call to fetch Home Link Groups by Inventory Center
 * @param inventoryCenterIds
 */
export const getHomeLinkGroups = async (
  inventoryCenterIds: string[],
): Promise<Array<HomeLinkGroupDto>> => {
  const response = await axiosInstance.get<Array<HomeLinkGroupDto>>(
    `/api/Home/linkGroup`,
    {
      params: { inventoryCenterIds },
    },
  );
  return response.data;
};

/**
 * API call to create Home Link Group
 * @param homeLingGroupCreate
 */
export const createHomeLinkGroup = async (
  homeLingGroupCreate: HomeLinkGroupCreate,
): Promise<HomeLinkGroupDto> => {
  const response = await axiosInstance.post<HomeLinkGroupDto>(
    '/api/Home/linkGroup',
    homeLingGroupCreate,
  );
  return response.data;
};

/**
 * API call to update Home Link Group
 * @param params
 */
export const updateHomeLinkGroup = async (params: {
  id: string;
  info: HomeLinkGroupUpdate;
}): Promise<HomeLinkGroupDto> => {
  const response = await axiosInstance.post(
    `/api/Home/linkGroup/${params.id}`,
    params.info,
  );
  return response.data;
};

/**
 * API call to delete Home Link Group by id
 * @param homeLinkGroupId
 */
export const deleteHomeLinkGroup = async (
  homeLinkGroupId: string,
): Promise<string> => {
  const response = await axiosInstance.delete(
    `/api/Home/linkGroup/${homeLinkGroupId}`,
  );
  return response.data;
};
