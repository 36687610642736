import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import React, { useCallback, useEffect, useState } from 'react';
import MCellShiftContainerPage from '../../manufacturingLocation/components/MCellShiftContainerPage';
import {
  CELL_BOARD_INVENTORY_CENTER_ID,
  CELL_BOARD_M_CELL_COLLECTION_ID,
  CELL_BOARD_M_CELL_ID,
  CELL_BOARD_SHIFT_SELECTED,
} from '../../profile/types';
import HomePage from './HomePage';
import { useSelector } from 'react-redux';
import {
  getHomeLinkGroupsThunk,
  getHomeLinksThunk,
  selectHomeLinkGroups,
  selectHomeLinks,
  selectLoadingHomeLinks,
} from '../homeSlice';
import MCellMessageMenu from '../../cellBoard/components/MCellMessageMenu';
import {
  CellBoardMCellMessageDto,
  CellBoardMCellTaskDto,
} from '../../cellBoard/types';
import { useNavigation } from '../../../utils/navigation';
import { ManufacturingLocationShift } from '../../resourceTracking/types';
import { EMPTY_GUID, isEmptyOrSpaces } from '../../../utils/string-utils';
import {
  clearMCellMessagesAction,
  getMCellMessagesThunk,
  markMCellTaskCompleteThunk,
  markMCellTaskIncompleteThunk,
  selectLoadingMCellMessages,
  selectMCellMessages,
} from '../../cellBoard/cellBoardSlice';
import { useAppDispatch } from '../../../store';
import { useIsRendered } from '../../app/utils';
import {
  clearMCellAlertsAction,
  getMCellAlertsThunk,
  selectLoadingMCellAlerts,
  selectMCellAlerts,
} from '../../mCellAlert/mCellAlertSlice';
import MCellAlerts from '../../mCellAlert/components/MCellAlerts';
import { getMCellTasksFromMCellMessages } from '../../cellBoard/utils';
import MCellTaskButtonMenu from '../../cellBoard/components/MCellTaskButtonMenu';
import { useInterval } from '../../../utils/time';
import { DATA_REFRESH_INTERVAL } from '../../../utils/date';
import { useMCellSelector } from '../../profile/utils';
import { getInventoryCenterIdsByManufacturingLocationThunk } from '../../manufacturingLocation/manufacturingLocationSlice';
import { Box } from '@mui/material';

/**
 * Renders Home page and container
 * @constructor
 */
const HomeContainerPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const loadingHomeLinks = useSelector(selectLoadingHomeLinks);
  const homeLinks = useSelector(selectHomeLinks);
  const homeLinkGroups = useSelector(selectHomeLinkGroups);
  const { navigateToMCellMessageDetail } = useNavigation();
  const [locationShift, setLocationShift] =
    useState<ManufacturingLocationShift>({
      mCellId: EMPTY_GUID,
      inventoryCenterId: EMPTY_GUID,
      mCellCollectionId: EMPTY_GUID,
      shift: 'None',
    });
  const mCellMessages = useSelector(selectMCellMessages);
  const loadingMCellMessages = useSelector(selectLoadingMCellMessages);
  const showMessages = mCellMessages.length > 0;
  const mCellTasks = getMCellTasksFromMCellMessages(mCellMessages);
  const showMCellTasks = mCellTasks.length > 0;
  const mCellAlerts = useSelector(selectMCellAlerts);
  const loadingMCellAlerts = useSelector(selectLoadingMCellAlerts);
  const mCellAlertData = mCellAlerts.find((_, i) => i === 0);
  const isRendered = useIsRendered();

  const { inventoryCenterId, mCellId, mCellCollectionId, shift } =
    locationShift;
  const loading =
    loadingHomeLinks || loadingMCellMessages || loadingMCellAlerts;
  const mCell = useMCellSelector(mCellId);
  const regularMCellSelected = mCell && !mCell.isRollup;

  /**
   * Dispatches call to get the appropriate pieces of data for the current location shift selection.
   */
  const getData = useCallback(async () => {
    const manufacturingLocation = {
      inventoryCenterId,
      mCellId,
      mCellCollectionId,
    };
    const results = await dispatch(
      getInventoryCenterIdsByManufacturingLocationThunk(manufacturingLocation),
    );
    await dispatch(getHomeLinkGroupsThunk(results.payload as string[]));
    await dispatch(getHomeLinksThunk(manufacturingLocation));

    if (regularMCellSelected) {
      dispatch(getMCellMessagesThunk({ mCellId, shift }));
      dispatch(
        getMCellAlertsThunk({
          ...manufacturingLocation,
          alertLevel: 'Elevated',
          includeEmptyMCells: true,
          sortBy: 'Alphabetical',
        }),
      );
    } else {
      dispatch(clearMCellMessagesAction());
      dispatch(clearMCellAlertsAction());
    }
  }, [
    inventoryCenterId,
    mCellId,
    mCellCollectionId,
    shift,
    regularMCellSelected,
    dispatch,
  ]);

  /**
   * Effect to trigger data fetcher when after first render and when location shift changes.
   * We are skipping the first render here to allow the container to return the current settings
   */
  useEffect(() => {
    if (isRendered) {
      getData();
    }
  }, [
    inventoryCenterId,
    mCellId,
    mCellCollectionId,
    shift,
    isRendered,
    getData,
  ]);

  /**
   * Triggers data fetcher at predefined intervals
   */
  useInterval(getData, DATA_REFRESH_INTERVAL);

  /**
   * Called when a message is clicked. Navigates to the message detail path.
   * @param mCellMessage
   */
  function handleMessageClick(mCellMessage: CellBoardMCellMessageDto) {
    navigateToMCellMessageDetail(mCellMessage.id);
  }

  /**
   * Called when a task is clicked. Navigates to the message detail path of the task.
   * @param mCellTask
   */
  function handleTaskClick(mCellTask: CellBoardMCellTaskDto) {
    navigateToMCellMessageDetail(mCellTask.mCellMessageId);
  }

  /**
   * Called when a task is marked complete/incomplete. Dispatches appropriate call
   * @param mCellTask
   */
  async function handleTaskMark(mCellTask: CellBoardMCellTaskDto) {
    if (isEmptyOrSpaces(mCellTask.completedDate)) {
      await dispatch(markMCellTaskCompleteThunk(mCellTask.id));
    } else {
      await dispatch(markMCellTaskIncompleteThunk(mCellTask.id));
    }
  }

  return (
    <MCellShiftContainerPage
      title={t('home')}
      loading={loading}
      shiftSettingKey={CELL_BOARD_SHIFT_SELECTED}
      mCellIdSettingKey={CELL_BOARD_M_CELL_ID}
      inventoryCenterIdSettingKey={CELL_BOARD_INVENTORY_CENTER_ID}
      mCellCollectionIdSettingKey={CELL_BOARD_M_CELL_COLLECTION_ID}
      allowMCellCollection
      allowInventoryCenter
      disableShift={mCellId === EMPTY_GUID}
      onChange={setLocationShift}
      secondaryActionComponent={
        <div style={{ marginRight: theme.spacing(2) }}>
          {mCellAlertData && (
            <MCellAlerts
              mCellAlertData={mCellAlertData}
              iconButtonSize={'medium'}
            />
          )}
          {showMCellTasks && (
            <MCellTaskButtonMenu
              mCellTasks={mCellTasks}
              onTaskClick={handleTaskClick}
              onTaskMark={handleTaskMark}
            />
          )}
          {showMessages && (
            <MCellMessageMenu
              mCellMessages={mCellMessages}
              onClick={handleMessageClick}
            />
          )}
        </div>
      }
    >
      {() => (
        <Box sx={{ mx: 3 }}>
          <HomePage homeLinks={homeLinks} groups={homeLinkGroups} />
        </Box>
      )}
    </MCellShiftContainerPage>
  );
};

export default HomeContainerPage;
