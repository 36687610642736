import MCellShiftContainerPage from '../../manufacturingLocation/components/MCellShiftContainerPage';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectLoadingHomeLinkGroups,
  selectLoadingHomeLinks,
} from '../homeSlice';
import HomeLinkConfigListPage from './HomeLinkConfigListPage';
import EnhancedButton from '../../common/EnhancedButton';
import { useNavigation } from '../../../utils/navigation';
import Paper from '@mui/material/Paper';
import { AppBar, Box, Grid, Tab, Tabs } from '@mui/material';
import HomeLinkGroupsConfigListTable from './HomeLinkGroupsConfigListTable';
import InventoryCenterFilter from '../../common/InventoryCenterFilter';
import { useFilterInventoryCenterId } from '../../manufacturingLocation/utils';
import Page from '../../common/Page';
import { TabConfig } from '../../common/types';
import { useTabManager } from '../../common/TabManager';
import {
  CELL_BOARD_SHIFT_SELECTED,
  CELL_BOARD_INVENTORY_CENTER_ID,
  CELL_BOARD_M_CELL_COLLECTION_ID,
  CELL_BOARD_M_CELL_ID,
} from '../../profile/types';

/**
 * Container Component for Home Link Config Page
 * @constructor
 */
const HomeLinkConfigListPageContainer = () => {
  const { t } = useTranslation();
  const loadingHomeLinks = useSelector(selectLoadingHomeLinks);
  const { navigateToHomeLinkCreate } = useNavigation();
  const [inventoryCenterId, setInventoryCenterId] =
    useFilterInventoryCenterId();
  const homeLinkGroupsLoading = useSelector(selectLoadingHomeLinkGroups);

  const tabConfigs: TabConfig[] = [
    {
      key: 'links',
      label: t('links'),
      component: (
        <MCellShiftContainerPage
          title={t('homeLinks')}
          loading={loadingHomeLinks}
          shiftSettingKey={CELL_BOARD_SHIFT_SELECTED}
          mCellIdSettingKey={CELL_BOARD_M_CELL_ID}
          inventoryCenterIdSettingKey={CELL_BOARD_INVENTORY_CENTER_ID}
          mCellCollectionIdSettingKey={CELL_BOARD_M_CELL_COLLECTION_ID}
          primaryActionComponent={
            <EnhancedButton sx={{ ml: 2 }} onClick={navigateToHomeLinkCreate}>
              {t('create')}
            </EnhancedButton>
          }
          allowMCellCollection
          allowInventoryCenter
          disableShift
        >
          {({ mCellId, inventoryCenterId, mCellCollectionId }) => (
            <HomeLinkConfigListPage
              inventoryCenterId={inventoryCenterId}
              mCellId={mCellId}
              mCellCollectionId={mCellCollectionId}
            />
          )}
        </MCellShiftContainerPage>
      ),
    },
    {
      key: 'groups',
      label: t('groups'),
      component: (
        <Page title={t('homeLinkGroups')} loading={homeLinkGroupsLoading}>
          <Box sx={{ mx: 3, mb: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <InventoryCenterFilter
                  inventoryCenterId={inventoryCenterId}
                  onChange={setInventoryCenterId}
                />
              </Grid>
            </Grid>
          </Box>
          <HomeLinkGroupsConfigListTable
            inventoryCenterId={inventoryCenterId}
          />
        </Page>
      ),
    },
  ];
  const { activeTabKey, handleTabChange, filteredTabs, renderTabContent } =
    useTabManager(tabConfigs);
  return (
    <Paper>
      <AppBar position='static'>
        <Tabs
          value={activeTabKey}
          onChange={handleTabChange}
          variant='scrollable'
          textColor='inherit'
          indicatorColor='secondary'
          scrollButtons
          allowScrollButtonsMobile
        >
          {filteredTabs.map((tab) => (
            <Tab key={tab.key} value={tab.key} label={tab.label} />
          ))}
        </Tabs>
      </AppBar>
      {renderTabContent()}
    </Paper>
  );
};

export default HomeLinkConfigListPageContainer;
