import React from 'react';
import { useAlert } from './AlertContext';
import AlertSnackbar from './AlertSnackbar';
import { useTranslation } from 'react-i18next';

/**
 * AlertNotification is a React functional component that displays an alert notification.
 * It uses the `useAlert` hook to access the current alert and the `clearAlert` function from the AlertContext.
 * If there is no alert, it returns null and does not render anything.
 * If there is an alert, it renders the AlertSnackbar component with the alert's message and level, and the `clearAlert` function as the onClose handler.
 *
 * @returns {JSX.Element | null} A JSX element representing the alert notification, or null if there is no alert.
 */
const AlertNotification = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { alert, clearAlert } = useAlert();

  if (!alert) return null;

  return (
    <AlertSnackbar
      open={!!alert}
      onClose={clearAlert}
      message={t(alert.message.stringKey, alert.message.props) as any}
      alertLevel={alert.level}
    />
  );
};

export default AlertNotification;
