import { useState, useEffect } from 'react';
import moment from 'moment';
import {
  DowntimeEscalationAssignmentType,
  Downtime,
  DowntimeComment,
  DowntimeReason,
} from './types';
import { useSelector } from 'react-redux';
import { green, red, yellow } from '@mui/material/colors';
import qs from 'qs';
import { RootState } from '../../store';
import { getAbsoluteNavigationPaths } from '../../utils/navigation';
import { useTranslation } from 'react-i18next';
import { Column } from '@devexpress/dx-react-grid';
import { selectDowntimeReasonIds } from './downtimeSlice';

export const TIMER_GRANULAR_THRESHOLD_MS = 1000 * 60 * 60 * 23;
export const ALL_ISSUES_REPORT_ID = 'ba0b0eb8-b3a3-4a68-b689-cdbc2c8aaff1';

/**
 * Custom hook that returns the columns for the issue report
 */
export const useIssueReportColumns = () => {
  const { t } = useTranslation();
  const columns: Column[] = [
    {
      title: t('workCell'),
      name: 'mCell',
      getCellValue: (row: Downtime) => (row.mCell ? row.mCell.code : ''),
    },
    {
      title: t('workCellDescription'),
      name: 'mCellDescription',
      getCellValue: (row: Downtime) => (row.mCell ? row.mCell.description : ''),
    },
    {
      title: t('reason'),
      name: 'reason',
      getCellValue: (row: Downtime) => row.reason.name,
    },
    {
      title: t('assetId'),
      name: 'assetId',
    },
    {
      title: t('description'),
      name: 'description',
      getCellValue: (row: Downtime) => row.description.split('\n--\n')[0],
    },
    {
      title: t('started'),
      name: 'startDate',
    },
    {
      title: t('duration'),
      name: 'duration',
      getCellValue: (row: Downtime) => row.duration,
    },
    {
      title: t('severity'),
      name: 'currentSeverity',
    },
    {
      title: t('status'),
      name: 'status',
      getCellValue: (row: Downtime) => {
        return formatStatus(row, t);
      },
    },
    {
      title: t('completed'),
      name: 'completedDate',
    },
    {
      title: t('totalLoss'),
      name: 'totalLoss',
    },
    {
      title: t('createdBy'),
      name: 'createdBy',
      getCellValue: (row: Downtime) => row.user.name,
    },
    {
      title: t('closedBy'),
      name: 'closedBy',
      getCellValue: (row: Downtime) => row.closedBy?.name ?? '...',
    },
    {
      title: t('helpRequested'),
      name: 'helpRequestDate',
    },
    {
      title: t('helpArrived'),
      name: 'helpArrivedDate',
    },
    {
      title: t('responseTime'),
      name: 'responseTime',
    },
    {
      title: t('ticketId'),
      name: 'ticketId',
    },
    {
      title: t('ticketAssignee'),
      name: 'ticketAssignee',
    },
  ];

  return columns;
};

/**
 * Custom hook that runs a timer and returns time elapsed in format HH:mm:ss
 * @param startTime
 * @param isActive
 */
export const useTimer = (
  startTime: string | Date,
  isActive: boolean,
): string => {
  const [time, setTime] = useState('00:00:00');

  useEffect(() => {
    function calculateAndSetTime() {
      const timeDiff = moment().diff(startTime);
      if (timeDiff > TIMER_GRANULAR_THRESHOLD_MS) {
        setTime(moment(startTime).fromNow(true));
      } else {
        const timeDiffFormatted = moment.utc(timeDiff).format('HH:mm:ss');
        setTime(timeDiffFormatted);
      }
    }
    let intervalTimer: any = null;
    if (isActive) {
      calculateAndSetTime();
      intervalTimer = setInterval(calculateAndSetTime, 300);
    } else {
      clearInterval(intervalTimer);
    }
    return () => clearInterval(intervalTimer);
  }, [time, isActive, startTime]);
  return time;
};

/**
 * Custom hook to find a downtime by id from the store
 * @param downtimeId
 */
export const useDowntime = (downtimeId: string): Downtime | null => {
  const allDowntimes = useSelector(
    (state: RootState) => state.downtime.downtimes,
  );
  const downtime = allDowntimes.find((d) => d.id === downtimeId);

  return downtime ? downtime : null;
};

/**
 * Computes color based on severity
 * @param severity
 */
export const severityToColor = (severity: number) => {
  if (severity < 10) return green[500];
  else if (severity < 90) return yellow[500];
  else return red[500];
};

/**
 * JS sort compare function for downtime comment array
 * @param a
 * @param b
 */
export const commentSortComparer = (
  a: DowntimeComment,
  b: DowntimeComment,
): number => {
  if (moment(a.timestamp) < moment(b.timestamp)) {
    return -1;
  }
  if (moment(a.timestamp) > moment(b.timestamp)) {
    return 1;
  }

  return 0;
};

/**
 * Returns the display format of the current downtime status
 * @param downtime
 * @param t
 */
export const formatStatus = (downtime: Downtime | null, t: any): string => {
  if (!downtime) {
    return '';
  }

  if (downtime.status === 'Assigned') {
    return `${t(downtime.status)} - ${downtime.ticketAssignee}`;
  }

  if (downtime.status === 'NeedHelp') {
    const idSection = downtime.ticketId ? ` - ${downtime.ticketId}` : '';
    return t(downtime.status) + idSection;
  }

  return t(downtime.status);
};

/**
 * Returns the display format of a downtime reason
 * @param reason
 * @param assetId
 */
export const formatReason = (
  reason: DowntimeReason | null,
  assetId: string,
) => {
  if (!reason) {
    return '';
  }
  if (reason.includeAssetId && assetId) {
    return `${reason.name} - ${assetId}`;
  }
  return reason.name;
};

/**
 * Generates the path to the Issue report with the specified MCell
 * @param mCellId
 * @param inventoryCenterId
 * @param isDowntimeCurrent
 */
export const getDowntimeReportPath = (
  mCellId: string,
  inventoryCenterId: string,
  isDowntimeCurrent: boolean,
) => {
  const { reportDetailPath } = getAbsoluteNavigationPaths();
  const reportPath = reportDetailPath.replace(/:id/, ALL_ISSUES_REPORT_ID);
  const filter = {
    mCellId,
    inventoryCenterId,
    isDowntimeCurrent,
  };
  const queryString = qs.stringify(filter, { addQueryPrefix: true });
  return reportPath + queryString;
};

/**
 * Converts assignment type to corresponding string key
 * @param type
 */
export const assignmentTypeStringKey = (
  type: DowntimeEscalationAssignmentType,
) => {
  switch (type) {
    case 'Owner':
      return 'owner';
    case 'Watcher':
      return 'watcher';
  }
};
/**
 * Converts list of DowntimeReasons to a list of reason options for select
 */

export const getDowntimeReasonOptions = (reasons: DowntimeReason[]) => {
  return reasons.map((x) => ({ label: x.name, value: x.id }));
};

/**
 * Hook for getting reason options for select
 */
export const useDowntimeReasonOptions = () => {
  const reasons = useSelector(selectDowntimeReasonIds);
  return getDowntimeReasonOptions(reasons);
};
