import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import {
  CircularProgress,
  Paper,
  SxProps,
  Toolbar,
  Typography,
} from '@mui/material';
import ErrorBoundary from './ErrorBoundary';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(1.5),
  },
}));

export type Props = {
  title: string;
  loading: boolean;
  children: ReactNode;
  actionComponent?: ReactNode;
  className?: string;
  sx?: SxProps;
};

/**
 * Renders a basic page for consistent styling
 * @param title
 * @param loading
 * @param children
 * @param actionComponent
 * @param className
 * @param sx
 * @constructor
 */
const Page = ({
  title,
  loading,
  children,
  actionComponent,
  className,
  sx,
}: Props) => {
  return (
    <Paper className={className} sx={sx}>
      <StyledToolbar data-testid={'Toolbar'}>
        <Typography variant='h6' sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        {loading && (
          <CircularProgress
            data-testid={'CircularProgress'}
            sx={{ mr: 2 }}
            color={'primary'}
          />
        )}
        {actionComponent}
      </StyledToolbar>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Paper>
  );
};

export default Page;
