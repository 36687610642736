import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isEmptyOrSpaces } from '../../../utils/string-utils';
import { useManufacturingLocationString } from '../../manufacturingLocation/utils';
import { ManufacturingLocation } from '../../manufacturingLocation/types';

type Props = {
  location: ManufacturingLocation;
  title: string;
  image?: File;
  file?: File;
  url: string;
  sortSequence: number;
  group: string | undefined;
};

/**
 * Renders the Home Link Config Create wizard summary
 * @param id
 * @param location
 * @param title
 * @param image
 * @param file
 * @param url
 * @param sortSequence
 * @param group
 */
const HomeLinkConfigCreateSummary = ({
  location,
  title,
  image,
  file,
  url,
  sortSequence,
  group,
}: Props) => {
  const { t } = useTranslation();
  const locationString = useManufacturingLocationString(location);
  const titleString = isEmptyOrSpaces(title) ? t('pleaseEnterTheTitle') : title;
  const imageString = image ? image.name : '...';
  const fileString = file ? file.name : '...';
  const groupString = group ? group : t('...');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Typography variant={'caption'} color={'textSecondary'}>
          {t('location')}
        </Typography>
        <Typography>{locationString}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant={'caption'} color={'textSecondary'}>
          {t('title')}
        </Typography>
        <Typography>{titleString}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant={'caption'} color={'textSecondary'}>
          {t('image')}
        </Typography>
        <Typography>{imageString}</Typography>
      </Grid>
      {file && (
        <Grid item xs={12} sm={6}>
          <Typography variant={'caption'} color={'textSecondary'}>
            {t('file')}
          </Typography>
          <Typography>{fileString}</Typography>
        </Grid>
      )}
      {url && (
        <Grid item xs={12}>
          <Typography variant={'caption'} color={'textSecondary'}>
            {t('url')}
          </Typography>
          <Typography>{url}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant={'caption'} color={'textSecondary'}>
          {t('sortSequence')}
        </Typography>
        <Typography>{sortSequence}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'caption'} color={'textSecondary'}>
          {t('group')}
        </Typography>
        <Typography>{groupString}</Typography>
      </Grid>
    </Grid>
  );
};

export default HomeLinkConfigCreateSummary;
