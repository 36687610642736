import { green, red } from '@mui/material/colors';
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Store } from 'redux';
import startSignalR from '../../signalr-client';
import { useAuth } from '../../auth';
import {
  getUserSettingsThunk,
  setAccountAction,
  setUserSettingThunk,
} from '../profile/profileSlice';
import LogRocket from 'logrocket';
import { getHelpLinksThunk, setReadyAction } from './appSlice';
import { User, UserSettingKey } from '../profile/types';
import { useSelector } from 'react-redux';
import { setManufacturingLocationsThunk } from '../manufacturingLocation/manufacturingLocationSlice';
import { RootState, useAppDispatch } from '../../store';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { AccountInfo } from '@azure/msal-browser';
import { appInsights } from '../../appInsights';
import { PageName, pageNames } from './types';
import { TFunction } from 'i18next';
import { clarity } from '../../clarity';

/**
 * Converts a PageName to its corresponding string key
 * @param page PageName
 */
export const helpLinkPageNameToStringKey = (page: PageName): string => {
  switch (page) {
    case 'IssueReport':
      return 'reportIssue';
    case 'IssueConfiguration':
      return 'issueConfiguration';
    case 'JobStationAssignments':
      return 'jobStationAssignments';
    case 'CellBoardMessage':
      return 'cellBoardMessage';
    case 'CrewingGuide':
      return 'crewingGuide';
    case 'CrewingEmployeeSearch':
      return 'crewingEmployeeSearch';
    case 'Scoreboard':
      return 'scoreboard';
    case 'Dashboard':
      return 'dashboard';
    case 'SupportHierarchy':
      return 'supportHierarchy';
    case 'SchedulingToolCapacityUtilization':
      return 'schedulingToolCapacityUtilization';
    case 'SchedulingToolSalesOrders':
      return 'schedulingToolSalesOrders';
    case 'SchedulingToolLaborWhatIf':
      return 'schedulingToolLaborWhatIf';
    case 'Lsw':
      return 'lsw';
  }
};

/**
 * Gets the select options of a PageName for help links
 * @param t TFunction
 */
export const getHelpLinkPageOptions = (
  t: TFunction,
): { label: string; value: PageName }[] => {
  return pageNames.map((m) => ({
    label: t(helpLinkPageNameToStringKey(m)),
    value: m,
  }));
};

export type ConnectionState = 'connected' | 'disconnected';

export type ErrorMessage = {
  stringKey: string;
  props: any;
};

/**
 * Converts connection state to appropriate color
 * @param connectionState
 */
export const connectionStateToColor = (connectionState: ConnectionState) => {
  if (connectionState === 'connected') return green[800];
  else return red[800];
};

/**
 * Util that automatically scrolls page to the top when page renders
 */
export const useScrollOnRender = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

/**
 * Hook that does initial setup of the application. Starts signalR, fetch settings,
 * and ensures that user is logged in.
 * @param store
 */
export const useInitialSetup = (store: Store<RootState>) => {
  const [settingsReady, setSettingsReady] = useState(false);
  const [accountReady, setAccountReady] = useState(false);
  useEffect(() => {
    const initializeSignalR = async () => {
      await Promise.all([
        store.dispatch(getUserSettingsThunk() as any),
        store.dispatch(setManufacturingLocationsThunk() as any),
        store.dispatch(getHelpLinksThunk() as any),
      ]);
      await startSignalR(async () => {
        setSettingsReady(true);
      }, store);
    };
    initializeSignalR();
  }, [store]);

  const account = useAuth();
  useEffect(() => {
    if (account) {
      const userInfo = { name: account.name ?? '', email: account.username };
      store.dispatch(setAccountAction(userInfo));
      setAccountReady(true);
    }
  }, [account, store]);

  useEffect(() => {
    if (settingsReady && accountReady) {
      store.dispatch(setReadyAction());
      if (account && process.env.NODE_ENV === 'production') {
        const userInfo = { name: account.name ?? '', email: account.username };

        // LogRocket
        if (userInfo.email !== 'CMDCTR@marvin.com') {
          // don't log for CMDCTR because the auto refresh uses up all the sessions
          LogRocket.init('8ycklx/marvin-ccc-qkyzv');
          LogRocket.identify(userInfo.email, userInfo);
        }

        // Application Insights
        appInsights.setAuthenticatedUserContext(
          userInfo.email,
          userInfo.email,
          true,
        );

        // Microsoft Clarity
        clarity.init('my7574kxen');
        clarity.identify(userInfo.email.toLowerCase(), userInfo); // does not show up but can be used as filter https://github.com/microsoft/clarity/issues/167
      }
    }
  }, [settingsReady, accountReady, store, account]);
};

/**
 * A custom React hook that manages state with persistence. It synchronizes the state with a user's setting
 * stored on a server, allowing the state to be restored across sessions. The state is automatically
 * persisted whenever it changes, based on the condition provided by `shouldPersist`.
 *
 * @template S The type of the state to be managed.
 * @param {UserSettingKey | undefined} settingKey The key under which the state is stored on the server.
 *        If undefined, the state is only managed locally and not persisted.
 * @param {S | (() => S) | undefined} defaultState The default state value or a function that returns the default state.
 *        This is used when no persisted state is found or when parsing the persisted state fails.
 * @param {boolean | ((state: S) => boolean)} [shouldPersist=true] A boolean or a function that determines whether
 *        the state should be persisted. If it's a function, it receives the current state as an argument and
 *        should return a boolean indicating whether the state should be persisted.
 *        - If `true` (or returns `true`), the state is persisted whenever it changes.
 *        - If `false` (or returns `false`), the state changes are not persisted.
 *
 * @returns {[S, Dispatch<SetStateAction<S>>]} An array containing the current state and a function to update it.
 *          The state is automatically persisted if `shouldPersist` conditions are met.
 *
 * @example
 * // Use the hook to manage a 'shift' state, not persisting the state if it's 'Active'
 *   const [shift, setShift] = usePersistedState<Shift>(
 *     CELL_BOARD_SHIFT_SELECTED,
 *     'Day',
 *     (state) => state !== ('Active' as Shift)
 *   );
 */
export function usePersistedState<S>(
  settingKey?: UserSettingKey,
  defaultState?: S | (() => S),
  shouldPersist: boolean | ((state: S) => boolean) = true,
): [S, Dispatch<SetStateAction<S>>] {
  const dispatch = useAppDispatch();
  const setting = useSelector((state: RootState) =>
    settingKey ? state.profile.userSetting[settingKey] : undefined,
  );
  const initialStateRef = useRef();
  let settingParsed;
  try {
    if (setting) {
      settingParsed = JSON.parse(setting);
    } else {
      settingParsed = defaultState;
    }
  } catch {
    settingParsed = defaultState;
  }
  initialStateRef.current = settingParsed;
  const [localState, setLocalState] = useState(initialStateRef.current);

  /**
   * Effect that persists local state to server
   */
  useEffect(() => {
    const stateSerialized = JSON.stringify(localState);
    const initialStateSerialized = JSON.stringify(initialStateRef.current);

    const persistCondition =
      typeof shouldPersist === 'function'
        ? shouldPersist(localState as unknown as S)
        : shouldPersist;

    const shouldPersistState =
      settingKey &&
      stateSerialized !== initialStateSerialized &&
      persistCondition;

    if (shouldPersistState) {
      const setUserSetting = {
        key: settingKey as UserSettingKey,
        value: stateSerialized,
      };
      dispatch(setUserSettingThunk(setUserSetting));
    }
  }, [dispatch, localState, settingKey, shouldPersist]);

  // @ts-ignore because typescript is weird about useRef()
  return [localState, setLocalState];
}

/**
 * Checks whether auth account is the same as backend user
 * @param account
 * @param user
 */
export function isSameUser(account: AccountInfo | undefined, user: User) {
  return account?.username.toLowerCase() === user.email.toLowerCase();
}

/**
 * Hook for debouncing a value
 * @param value
 * @param delay
 */
export default function useDebounce<T>(value: T, delay: number = 300): T {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
}

/**
 * Hook for determining the dimensions of an element and auto updating on resize
 * @param ref
 */
export const useResizeObserver = (
  ref: MutableRefObject<any>,
): DOMRectReadOnly | null => {
  const [dimensions, setDimensions] = useState(null);
  useEffect(() => {
    const observeTarget = ref.current;
    // @ts-ignore
    const resizeObserver = new ResizeObserver((entries: any[]) => {
      entries.forEach((entry) => {
        setDimensions(entry.contentRect);
      });
    });
    resizeObserver.observe(observeTarget);
    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);
  return dimensions;
};

/**
 * Hook for fetching data using an async function while managing the loading and error states
 * @param fetcher
 * @param immediate
 */
export function useDataFetcher<T>(
  fetcher: () => Promise<T>,
  immediate: boolean = false,
): {
  data: T | undefined;
  loading: boolean;
  error?: any;
  execute: () => Promise<void>;
  clearData: () => void;
} {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T | undefined>();
  const [error, setError] = useState();
  const isMountedRef = useRef(true);
  const lastRequestDateRef = useRef(Date.now());
  const clearData = () => {
    setData(undefined);
  };

  const getData = useCallback(async () => {
    lastRequestDateRef.current = Date.now();
    const lastRequestDate = lastRequestDateRef.current;

    if (!isMountedRef.current) {
      return;
    }

    try {
      setLoading(true);
      const result = await fetcher();
      if (
        lastRequestDateRef.current !== lastRequestDate ||
        !isMountedRef.current
      ) {
        return;
      }
      setData(result);
      setError(undefined);
      setLoading(false);
    } catch (e: any) {
      if (
        lastRequestDateRef.current !== lastRequestDate ||
        !isMountedRef.current
      ) {
        return;
      }
      setError(e);
      setLoading(false);
    }
  }, [fetcher]);

  useEffect(() => {
    isMountedRef.current = true;

    if (immediate) {
      getData();
    }

    return () => {
      isMountedRef.current = false;
    };
    // required to ensure that this effect only runs once even if the fetcher and param
    // (which are dependency on getData) change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, loading, error, execute: getData, clearData };
}

/**
 * Hook to get query params from current url location
 */
export function useQueryParams<T>() {
  const location = useLocation();
  return qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as unknown as T;
}

/**
 * Hook to allow update of query params
 */
export function useUpdateQueryParams<T>(): (newParams: Partial<T>) => void {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as unknown as T;

  return (newParams: Partial<T>) => {
    const updatedParams = { ...queryParams, ...newParams } as {
      [key: string]: unknown;
    };
    Object.keys(updatedParams).forEach(
      (key) => updatedParams[key] === undefined && delete updatedParams[key],
    );
    navigate({ search: qs.stringify(updatedParams) });
  };
}

/**
 * Hook that returns true when first render of a component is done and we are on subsequent renders
 */
export const useIsRendered = () => {
  const isRenderedRef = useRef(false);
  useEffect(() => {
    isRenderedRef.current = true;
  }, []);
  return isRenderedRef.current;
};

/**
 * Extracts a value from a filter or otherwise returns the default value
 * @param initialFilter
 * @param key
 * @param defaultValue
 */
export function getFilterValue<T, K extends keyof T>(
  initialFilter: T | null,
  key: K,
  defaultValue: T[K],
) {
  return initialFilter && initialFilter[key]
    ? initialFilter[key]
    : defaultValue;
}

/**
 * Custom React hook for tracking page views in a React application using Application Insights.
 *
 * This hook listens to changes in the current browser location using the `useLocation` hook from React Router.
 * Whenever the location changes (indicating a new page view in a single-page application), this hook
 * triggers Application Insights to log the page view event with the current document title and the full URL.
 *
 * @Note: It only logs page views when the application is running in a production environment.
 */
export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      appInsights.trackPageView({
        name: document.title,
        uri: location.pathname + location.search,
      });
    }
  }, [location]);
};
