export const config = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID!,
    authority: process.env.REACT_APP_AZURE_AD_AUTHORITY!,
  },
  api: {
    scope: process.env.REACT_APP_API_SCOPE!,
  },
  applicationInsights: {
    instrumentationKey: process.env.REACT_APP_AI_INSTRUMENTATION_KEY!,
  },
};
