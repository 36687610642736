import { SvgIcon } from '@mui/material';
import {
  mdiExitToApp,
  mdiFormatSize,
  mdiGoogleCirclesCommunities,
  mdiSquareCircle,
  mdiPencil,
  mdiWrench,
  mdiPaperRoll,
  mdiOfficeBuildingMarker,
  mdiEarth,
} from '@mdi/js';
import React from 'react';

/**
 * EditIcon
 * @param style
 * @param props
 * @constructor
 */
export const EditIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiPencil} />
    </SvgIcon>
  );
};

/**
 * FontSizeIcon
 * @param style
 * @param props
 * @constructor
 */
export const FontSizeIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiFormatSize} />
    </SvgIcon>
  );
};

/**
 * InventoryLocationIcon
 * @param style
 * @param props
 * @constructor
 */
export const GlobalLocationIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiEarth} />
    </SvgIcon>
  );
};

/**
 * InventoryLocationIcon
 * @param style
 * @param props
 * @constructor
 */
export const InventoryLocationIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiOfficeBuildingMarker} />
    </SvgIcon>
  );
};

/**
 * LogoutIcon
 * @param sx
 * @param props
 * @constructor
 */
export const LogoutIcon = ({ sx, ...props }: any) => {
  return (
    <SvgIcon {...props} sx={sx}>
      <path d={mdiExitToApp} />
    </SvgIcon>
  );
};

/**
 * MCellCollectionIcon
 * @param style
 * @param props
 * @constructor
 */
export const MCellCollectionIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiGoogleCirclesCommunities} />
    </SvgIcon>
  );
};

/**
 * MCellIcon
 * @param style
 * @param props
 * @constructor
 */
export const MCellIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiSquareCircle} />
    </SvgIcon>
  );
};

/**
 * RollUpIcon
 * @param style
 * @param props
 * @constructor
 */
export const RollUpIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiPaperRoll} />
    </SvgIcon>
  );
};

/**
 * VersionIcon
 * @param style
 * @param props
 * @constructor
 */
export const VersionIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiWrench} />
    </SvgIcon>
  );
};
