import { hasPermission } from '../../../auth';
import { groupBy } from '../../../utils/array';
import { MCellAlert, MCellAlertData, MCellAlertType } from '../types';
import { compareAlerts } from '../../dashboard/utils';
import green from '@mui/material/colors/green';
import { ELEVATED_COLOR, HIGH_COLOR } from '../utils';
import { getDowntimeReportPath } from '../../downtime/utils';
import { useNavigation } from '../../../utils/navigation';
import { Badge, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import {
  CrewingOverIcon,
  CrewingUnderIcon,
  DemandManagementIcon,
  OperationsIcon,
  IssueIcon,
} from '../../../utils/icons';
import { getCrewingGuidePath } from '../../resourceTracking/utils';
import { useTranslation } from 'react-i18next';
import { useGlobalSettings } from '../../profile/utils';

type MCellAlertsProps = {
  mCellAlertData: MCellAlertData;
  iconButtonSize: 'medium' | 'small';
};

/**
 * Renders individual MCell Alerts
 * @param mCellAlertData
 * @param iconButtonSize
 * @constructor
 */
export const MCellAlerts = ({
  mCellAlertData,
  iconButtonSize,
}: MCellAlertsProps) => {
  const { t } = useTranslation();
  const canReadReports = hasPermission(['Report.Read']);
  const { mCellStatusEnabled } = useGlobalSettings();
  const mCellStatusTypes: MCellAlertType[] = [
    'DemandManagementStatus',
    'OperationsStatus',
  ];
  const mCell = mCellAlertData.mCell;
  const alertsFiltered = mCellStatusEnabled
    ? mCellAlertData.alerts
    : mCellAlertData.alerts.filter(
        (alert) => !mCellStatusTypes.includes(alert.type)
      );
  const alertsGrouped = groupBy(alertsFiltered, 'type') as {
    [key in MCellAlertType]: MCellAlert[];
  };
  const { navigateTo, navigateToIssueDetail } = useNavigation();

  return (
    <>
      {Object.keys(alertsGrouped).map((x) => {
        const alertsSorted = [
          ...alertsGrouped[x as MCellAlertType].sort(compareAlerts),
        ];
        const highestAlert = alertsSorted[alertsSorted.length - 1];
        let color: string = green[500];
        if (highestAlert.level === 'Elevated') color = ELEVATED_COLOR;
        else if (highestAlert.level === 'High') color = HIGH_COLOR;
        let Icon = null;
        let toolTipMessage = '';
        let action = () => {};
        switch (highestAlert.type) {
          case 'CrewingOver':
            Icon = CrewingOverIcon;
            toolTipMessage = t('crewingOverMaximumCapacity');
            action = () => {
              if (canReadReports) {
                navigateTo(
                  getCrewingGuidePath(mCell.id, mCell.inventoryCenterId)
                );
              }
            };
            break;
          case 'CrewingUnder':
            Icon = CrewingUnderIcon;
            toolTipMessage =
              highestAlert.level === 'High'
                ? t('crewingUnderMinimumCapacity')
                : t('crewingUnderOptimumCapacity');
            action = () => {
              if (canReadReports) {
                navigateTo(
                  getCrewingGuidePath(mCell.id, mCell.inventoryCenterId)
                );
              }
            };
            break;
          case 'Downtime':
            Icon = IssueIcon;
            toolTipMessage =
              highestAlert.level === 'High'
                ? t('issueNeedsHelp')
                : t('issueActive');
            action = () => {
              if (alertsSorted.length > 1) {
                navigateTo(
                  getDowntimeReportPath(mCell.id, mCell.inventoryCenterId, true)
                );
              } else {
                navigateToIssueDetail(highestAlert.detail as string);
              }
            };
            break;
          case 'DemandManagementStatus':
            Icon = DemandManagementIcon;
            toolTipMessage =
              highestAlert.level === 'High'
                ? t('demandManagementStatusRedOrNone')
                : t('demandManagementStatusYellow');
            action = () => {};
            break;
          case 'OperationsStatus':
            Icon = OperationsIcon;
            toolTipMessage =
              highestAlert.level === 'High'
                ? t('operationsStatusRed')
                : t('operationsStatusYellow');
            action = () => {};
        }

        /**
         * Called when an alert icon is clicked. Calls the appropriate action
         * for that alert
         */
        function handleClick() {
          action();
        }

        return (
          Icon && (
            <Tooltip title={toolTipMessage} key={x}>
              <IconButton size={iconButtonSize} onClick={handleClick}>
                <Badge
                  overlap={'rectangular'}
                  badgeContent={alertsSorted.length}
                  color={'error'}
                  invisible={alertsSorted.length < 2}
                >
                  <Icon sx={{ color: color }} />
                </Badge>
              </IconButton>
            </Tooltip>
          )
        );
      })}
    </>
  );
};

export default MCellAlerts;
