import React, { useEffect, useState } from 'react';
import GenericDialog from './GenericDialog';
import { TextField } from '@mui/material';
import { isEmptyOrSpaces } from '../../utils/string-utils';

type Props = {
  title: string;
  textLabel: string;
  open: boolean;
  onConfirm: (inputText: string) => void;
  onCancel: () => void;
  defaultText: string;
  confirmDisabled?: boolean;
  multiline?: boolean;
  allowEmpty?: boolean;
  type?: string;
  inputProps?: {
    min: number;
    step: number;
  };
};

/**
 * Renders a dialog for inputting short text
 * @param title
 * @param textLabel
 * @param defaultText
 * @param open
 * @param onConfirm
 * @param onCancel
 * @param confirmDisabled
 * @param multiline
 * @param allowEmpty
 * @param type
 * @param inputProps
 * @constructor
 */
const TextInputDialog = ({
  title,
  textLabel,
  defaultText,
  open,
  onConfirm,
  onCancel,
  confirmDisabled,
  multiline,
  allowEmpty,
  type,
  inputProps,
}: Props) => {
  const [inputText, setInputText] = useState(defaultText);
  useEffect(() => {
    setInputText(defaultText);
  }, [defaultText]);
  return (
    <GenericDialog
      title={title}
      open={open}
      onConfirm={() => onConfirm(inputText)}
      onCancel={onCancel}
      confirmDisabled={
        confirmDisabled || (!allowEmpty && isEmptyOrSpaces(inputText))
      }
    >
      <TextField
        id={title}
        label={textLabel}
        autoFocus
        fullWidth
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        multiline={multiline}
        type={type ? type : 'text'}
        inputProps={inputProps}
        variant='standard'
      />
    </GenericDialog>
  );
};

export default TextInputDialog;
