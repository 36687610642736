import { TFunction } from 'i18next';
import {
  HomeLinkDto,
  HomeLinkGroupDto,
  HomeLinkType,
  homeLinkTypes,
} from './types';
import { OptionValue } from '../common/EnhancedSelect';
import { selectNoneOption } from '../../utils/string-utils';

export const HOME_LINK_IMAGE_MAX_FILE_SIZE_BYTES = 25000000; // 25 MB
export const HOME_LINK_MAX_FILE_SIZE_BYTES = 25000000; // 25 MB

/**
 * Converts a Home Link to its corresponding string key
 * @param homeLinkType
 */
export const homeLinkOptionToStringKey = (homeLinkType: HomeLinkType) => {
  switch (homeLinkType) {
    case 'Link':
      return 'link';
    case 'File':
      return 'file';
  }
};

export interface IHomeLinkOption {
  label: string;
  value: HomeLinkType;
}

/**
 * Gets select options for Home Links
 * @param t
 */
export const getHomeLinkOptions = (t: TFunction): IHomeLinkOption[] => {
  return homeLinkTypes.map((x) => ({
    label: t(homeLinkOptionToStringKey(x)),
    value: x,
  }));
};

/**
 * Gets info from a Home Link object or returns the default values
 * @param homeLink
 */
export const getHomeLinkDetails = (homeLink?: HomeLinkDto) => {
  const id = homeLink ? homeLink.id : '';
  const inventoryCenterId = homeLink ? homeLink.inventoryCenterId : '';
  const mCellId = homeLink ? homeLink.mCellId : '';
  const mCellCollectionId = homeLink ? homeLink.mCellCollectionId : '';
  const title = homeLink ? homeLink.title : 'None';
  const linkType = homeLink ? homeLink.linkType : false;
  const imageName = homeLink ? homeLink.imageName : false;
  const imageUrl = homeLink ? homeLink.imageUrl : false;
  const linkUrl = homeLink ? homeLink.linkUrl : 'None';
  const filename = homeLink ? homeLink.filename : '';
  const fileUrl = homeLink ? homeLink.fileUrl : '';
  const lastUpdatedByName = homeLink ? homeLink.lastUpdatedByName : '';
  const lastUpdatedById = homeLink ? homeLink.lastUpdatedById : 0;
  const updatedDate = homeLink ? homeLink.updatedDate : '';
  const sortSequence = homeLink ? homeLink.sortSequence : 0;
  const groupName = homeLink ? homeLink.groupName : '';
  const homeLinkGroupId = homeLink ? homeLink.homeLinkGroupId : '';
  return {
    id,
    inventoryCenterId,
    mCellId,
    mCellCollectionId,
    title,
    linkType,
    imageName,
    imageUrl,
    linkUrl,
    filename,
    fileUrl,
    lastUpdatedByName,
    lastUpdatedById,
    updatedDate,
    sortSequence,
    groupName,
    homeLinkGroupId,
  };
};

/**
 * Creates Home Link Group options for a select
 * @param homeLinkGroups
 * @param t
 */
export function createHomeLinkGroupOptions(
  homeLinkGroups: HomeLinkGroupDto[],
  t: TFunction
) {
  const homeLinkGroupOptions: OptionValue[] = homeLinkGroups.map((s) => ({
    label: s.name,
    value: s.id,
  }));
  homeLinkGroupOptions.unshift({ label: selectNoneOption(t), value: '' });
  homeLinkGroupOptions.sort((a, b) => a.label.localeCompare(b.label));
  return homeLinkGroupOptions;
}
