import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import EnhancedButton from './EnhancedButton';

type Props = {
  title: string;
  message: any;
  open: boolean;
  onConfirm: () => any;
  onCancel: () => any;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmDisabled?: boolean;
};

/**
 * Renders a confirmation dialog
 * @param title
 * @param message
 * @param open
 * @param onConfirm
 * @param onCancel
 * @param confirmLabel
 * @param cancelLabel
 * @param confirmDisabled
 * @constructor
 */
const ConfirmDialog = ({
  title,
  message,
  open,
  onConfirm,
  onCancel,
  confirmLabel,
  cancelLabel,
  confirmDisabled,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog open={open} onClose={onCancel} fullScreen={isSmall} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button size={'large'} color={'secondary'} onClick={onCancel}>
          {cancelLabel || t('no')}
        </Button>
        <EnhancedButton
          color={'primary'}
          onClick={onConfirm}
          disabled={confirmDisabled}
        >
          {confirmLabel || t('yes')}
        </EnhancedButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
