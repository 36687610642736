import EnhancedSelect from './EnhancedSelect';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  getInventoryCenterOptions,
  useManufacturingLocations,
} from '../manufacturingLocation/utils';
import { SxProps } from '@mui/material';
import { InventoryCenter } from '../manufacturingLocation/types';

type Props = {
  inventoryCenters?: InventoryCenter[];
  inventoryCenterId: string;
  onChange: (prop: any) => void;
  containerClass?: any;
  sx?: SxProps;
};

/**
 * Renders an Inventory Center filter
 * @param inventoryCenters
 * @param inventoryCenterId
 * @param onChange
 * @param containerClass
 * @param sx
 * @constructor
 */
const InventoryCenterFilter = ({
  inventoryCenters,
  inventoryCenterId,
  onChange,
  containerClass,
  sx,
}: Props) => {
  const { t } = useTranslation();
  const { inventoryCenters: allInventoryCenters } = useManufacturingLocations();
  const effectiveInventoryCenters = inventoryCenters || allInventoryCenters;
  const inventoryCenterOptions = getInventoryCenterOptions(
    effectiveInventoryCenters,
  );

  return (
    <EnhancedSelect
      sx={sx}
      label={t('inventoryCenter')}
      id={'inventoryCenter'}
      data={inventoryCenterOptions}
      value={inventoryCenterId}
      onChange={onChange}
      containerClass={containerClass}
    />
  );
};

export default InventoryCenterFilter;
