import {
  MCellAlertData,
  MCellAlertKeyMCellScore,
  MCellAlertPoints,
} from './types';
import { LoadingStatus } from '../../api/app.types';
import { createAction, createSlice } from '@reduxjs/toolkit';
import {
  getMCellAlertKeyMCellScore,
  getMCellAlertPoints,
  getMCellAlerts,
  setMCellAlertKeyMCellScore,
  setMCellAlertPoints,
} from './api';
import { RootState } from '../../store';
import { createAsyncThunkWithError } from '../../redux/utils';

export type MCellAlertState = {
  mCellAlerts: MCellAlertData[];
  mCellAlertsStatus: LoadingStatus;
  points: MCellAlertPoints[];
  pointsStatus: LoadingStatus;
  keyMCellScore: MCellAlertKeyMCellScore | undefined;
  keyMCellScoreStatus: LoadingStatus;
};

export const initialState: MCellAlertState = {
  mCellAlerts: [],
  mCellAlertsStatus: 'idle',
  points: [],
  pointsStatus: 'idle',
  keyMCellScore: undefined,
  keyMCellScoreStatus: 'idle',
};

/**
 * Action to clear MCell Alerts in store
 */
export const clearMCellAlertsAction = createAction(
  'mCellAlert/clearMCellAlertsAction'
);

/**
 * Thunk to get list of MCell Alerts matching filter criteria
 */
export const getMCellAlertsThunk = createAsyncThunkWithError(
  'mCellAlert/getMCellAlerts',
  getMCellAlerts,
  'errorFetchingWorkCellAlerts'
);

/**
 * Thunk to get list of MCell Alert Points for given inventory center
 */
export const getMCellAlertPointsThunk = createAsyncThunkWithError(
  'mCellAlert/getMCellAlertPoints',
  getMCellAlertPoints,
  'errorFetchingWorkCellAlertPoints'
);

/**
 * Thunk to set MCell Alert Points for a given inventory center, type, level combination
 */
export const setMCellAlertPointsThunk = createAsyncThunkWithError(
  'mCellAlert/setMCellAlertPoints',
  setMCellAlertPoints,
  'errorUpdatingWorkCellAlertPoints'
);

/**
 * Thunk to get MCell Alert Key MCells Score for given inventory center
 */
export const getMCellAlertKeyMCellScoreThunk = createAsyncThunkWithError(
  'mCellAlert/getMCellAlertKeyMCellScore',
  getMCellAlertKeyMCellScore,
  'errorFetchingKeyWorkCellScore'
);

/**
 * Thunk to set MCell Alert Key MCell Score points and factor in an inventory center
 */
export const setMCellAlertKeyMCellScoreThunk = createAsyncThunkWithError(
  'mCellAlert/setMCellAlertKeyMCellScore',
  setMCellAlertKeyMCellScore,
  'errorUpdatingKeyWorkCellScore'
);

export const mCellAlertSlice = createSlice({
  name: 'mCellAlert',
  initialState,
  reducers: {
    clearMCellAlertsAction: (state, action) => {
      state.mCellAlerts = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMCellAlertsThunk.pending, (state) => {
        state.mCellAlertsStatus = 'loading';
      })
      .addCase(getMCellAlertsThunk.fulfilled, (state, { payload }) => {
        state.mCellAlertsStatus = 'succeeded';
        state.mCellAlerts = payload;
      })
      .addCase(getMCellAlertsThunk.rejected, (state) => {
        state.mCellAlertsStatus = 'failed';
      })
      .addCase(getMCellAlertPointsThunk.pending, (state) => {
        state.pointsStatus = 'loading';
      })
      .addCase(getMCellAlertPointsThunk.fulfilled, (state, { payload }) => {
        state.pointsStatus = 'succeeded';
        state.points = payload;
      })
      .addCase(getMCellAlertPointsThunk.rejected, (state) => {
        state.pointsStatus = 'failed';
      })
      .addCase(setMCellAlertPointsThunk.pending, (state) => {
        state.pointsStatus = 'loading';
      })
      .addCase(setMCellAlertPointsThunk.fulfilled, (state, { payload }) => {
        state.pointsStatus = 'succeeded';
        const index = state.points.findIndex((s) => s.id === payload.id);
        if (index !== -1) state.points[index] = payload;
        else state.points.push(payload);
      })
      .addCase(setMCellAlertPointsThunk.rejected, (state) => {
        state.pointsStatus = 'failed';
      })
      .addCase(getMCellAlertKeyMCellScoreThunk.pending, (state) => {
        state.keyMCellScoreStatus = 'loading';
      })
      .addCase(
        getMCellAlertKeyMCellScoreThunk.fulfilled,
        (state, { payload }) => {
          state.keyMCellScoreStatus = 'succeeded';
          state.keyMCellScore = payload;
        }
      )
      .addCase(getMCellAlertKeyMCellScoreThunk.rejected, (state) => {
        state.keyMCellScoreStatus = 'failed';
      })
      .addCase(setMCellAlertKeyMCellScoreThunk.pending, (state) => {
        state.keyMCellScoreStatus = 'loading';
      })
      .addCase(
        setMCellAlertKeyMCellScoreThunk.fulfilled,
        (state, { payload }) => {
          state.keyMCellScoreStatus = 'succeeded';
          state.keyMCellScore = payload;
        }
      )
      .addCase(setMCellAlertKeyMCellScoreThunk.rejected, (state) => {
        state.keyMCellScoreStatus = 'failed';
      });
  },
});

/**
 * Selector to determine whether MCell Alert Points or Key MCell Score is loading
 * @param s
 */
export const selectLoadingMCellAlertPointOrKeyScore = (s: RootState) =>
  s.mCellAlert.pointsStatus === 'loading' ||
  s.mCellAlert.keyMCellScoreStatus === 'loading';

/**
 * Selects MCell Alerts
 * @param s
 */
export const selectMCellAlerts = (s: RootState) => s.mCellAlert.mCellAlerts;

/**
 * Selector to determine whether MCell Alerts is loading
 * @param s
 */
export const selectLoadingMCellAlerts = (s: RootState) =>
  s.mCellAlert.mCellAlertsStatus === 'loading';

export const mCellAlertReducer = mCellAlertSlice.reducer;
