export const dateTimeRepeats = ['None', 'Daily', 'Weekly', 'Monthly'] as const;
export const dateTimeRepeatStrings = [
  'none',
  'daily',
  'weekly',
  'monthly',
] as const;
export type DateTimeRepeat = (typeof dateTimeRepeats)[number];
export const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const;
export const dayOfWeekStrings = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const;
export type DayOfWeek = (typeof daysOfWeek)[number];
export const daysOfMonth = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
] as const;
export type DayOfMonth = (typeof daysOfMonth)[number];
export type RecurrenceInfo = {
  repeat: DateTimeRepeat;
  occurrenceDurationDays: number;
  daysOfWeek: DayOfWeek[];
  monthlyDays: DayOfMonth[];
  monthlyInterval: number;
};
