import { Option } from '../common/EnhancedRadio';

export const FONT_SIZE_KEY = 'CCC_FONT_SIZE';
const DEFAULT_FONT_SIZE_VALUE = 16;
const DEFAULT_FONT_SIZE_LABEL = 'default';
const BLIND_FONT_SIZE_VALUE = 22;
const BLIND_FONT_SIZE_LABEL = 'blind';

/**
 * Gets the currently set fontsize
 */
export const getFontSize = (): Option => {
  const resultString = localStorage.getItem(FONT_SIZE_KEY);
  if (resultString) {
    return JSON.parse(resultString);
  }
  return { label: DEFAULT_FONT_SIZE_LABEL, value: DEFAULT_FONT_SIZE_VALUE };
};

/**
 * Saves the font size for the current client in localstorage
 * @param value
 */
export const setFontSize = (value: number) => {
  const result: Option =
    value === BLIND_FONT_SIZE_VALUE
      ? { label: BLIND_FONT_SIZE_LABEL, value: BLIND_FONT_SIZE_VALUE }
      : { label: DEFAULT_FONT_SIZE_LABEL, value: DEFAULT_FONT_SIZE_VALUE };
  const resultString = JSON.stringify(result);
  localStorage.setItem(FONT_SIZE_KEY, resultString);
  // eslint-disable-next-line no-restricted-globals
  location.reload();
};

/**
 * Gets the valid options for font sizes
 */
export const getFontSizeOptions = (): Array<Option> => {
  return [
    {
      label: DEFAULT_FONT_SIZE_LABEL,
      value: DEFAULT_FONT_SIZE_VALUE,
    },
    {
      label: BLIND_FONT_SIZE_LABEL,
      value: BLIND_FONT_SIZE_VALUE,
    },
  ];
};

/**
 * Returns the column width of the ReactGrid edit column based on the currently
 * selected font size.
 */
export const useTableEditColumnWidth = (): number => {
  return getFontSize().value === BLIND_FONT_SIZE_VALUE ? 200 : 160;
};
