import {
  MCellAlertData,
  MCellAlertKeyMCellScore,
  MCellAlertKeyMCellScoreInfo,
  MCellAlertPoints,
  MCellAlertPointsInfo,
  MCellAlertsFilter,
} from './types';
import axiosInstance from '../../api';

/**
 * API call to get MCell Alerts matching filter criteria
 * @param filter
 */
export const getMCellAlerts = async (
  filter: MCellAlertsFilter
): Promise<MCellAlertData[]> => {
  const response = await axiosInstance.get('/api/MCellAlert', {
    params: filter,
  });
  return response.data;
};

/**
 * API call to get list of MCell Alert Points for given inventory center
 * @param inventoryCenterId
 */
export const getMCellAlertPoints = async (
  inventoryCenterId: string
): Promise<MCellAlertPoints[]> => {
  const response = await axiosInstance.get('/api/MCellAlert/points', {
    params: { inventoryCenterId },
  });
  return response.data;
};

/**
 * API call to set MCell Alert Points for a given inventory center, type, level combination
 * @param mCellAlertPointsInfo
 */
export const setMCellAlertPoints = async (
  mCellAlertPointsInfo: MCellAlertPointsInfo
): Promise<MCellAlertPoints> => {
  const response = await axiosInstance.post(
    '/api/MCellAlert/points',
    mCellAlertPointsInfo
  );
  return response.data;
};

/**
 * API call to get MCell Alert Key MCells Score for given inventory center
 * @param inventoryCenterId
 */
export const getMCellAlertKeyMCellScore = async (
  inventoryCenterId: string
): Promise<MCellAlertKeyMCellScore> => {
  const response = await axiosInstance.get('/api/MCellAlert/keyMCellScore', {
    params: { inventoryCenterId },
  });
  return response.data;
};

/**
 * API call to set MCell Alert Key MCell Score points and factor in an inventory center
 * @param mCellAlertKeyMCellScoreInfo
 */
export const setMCellAlertKeyMCellScore = async (
  mCellAlertKeyMCellScoreInfo: MCellAlertKeyMCellScoreInfo
): Promise<MCellAlertKeyMCellScore> => {
  const response = await axiosInstance.post(
    '/api/MCellAlert/keyMCellScore',
    mCellAlertKeyMCellScoreInfo
  );
  return response.data;
};
