import { createAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  CoatingsPaint,
  CoatingsPartMaterial,
  CoatingsProfile,
  CoatingsProfileFilter,
} from './types';
import {
  addOrUpdateProfileAlertNotes,
  addOrUpdateProfilePalletStackingNotes,
  addOrUpdateProfileBarcodeFirstPassIds,
  addOrUpdateProfileBarcodeSecondPassIds,
  addOrUpdateProfileAttachments,
  createOrUpdateProfile,
  deactivateProfile,
  deleteAttachmentFromProfile,
  getPaints,
  getProfileAttachmentUrls,
  getProfileById,
  getProfiles,
  updateProfile,
  addOrUpdateProfileAutoSetupBarcodeIds,
} from './api';
import { createAsyncThunkWithError } from '../../redux/utils';
import { LoadingStatus } from '../../api/app.types';

export type CoatingsProfileState = {
  filter: CoatingsProfileFilter | undefined;
  profiles: CoatingsProfile[];
  profilesStatus: LoadingStatus;
  paints: CoatingsPaint[];
  paintsStatus: LoadingStatus;
};

export const initialState: CoatingsProfileState = {
  filter: undefined,
  profiles: [],
  profilesStatus: 'idle',
  paints: [],
  paintsStatus: 'idle',
};

/**
 * Action to set Coatings Search information in the store
 */
export const setCoatingsSearchAction = createAction<string>(
  'coatings/setCoatingsSearchAction',
);

/**
 * Action to set Material Filter information in the store
 */
export const setMaterialFilterAction = createAction<CoatingsPartMaterial[]>(
  'coatings/setMaterialFilterAction',
);

/**
 * Action to set Product Category Filter information in the store
 */
export const setProductCategoryFilterAction = createAction<string>(
  'coatings/setProductCategoryFilterAction',
);

/**
 * Action to add or update Coatings Profile information in the store
 */
export const addOrUpdateCoatingsProfileAction = createAction<CoatingsProfile>(
  'coatings/addOrUpdateCoatingsProfileAction',
);

/**
 * Thunk for fetching Coatings Profiles that match filter criteria
 */
export const fetchCoatingsProfilesThunk = createAsyncThunkWithError(
  'coatings/fetchCoatingsProfilesThunk',
  getProfiles,
  'errorFetchingCoatingsProfiles',
);

/**
 * Thunk for fetching Coatings Profile
 */
export const fetchCoatingsProfileThunk = createAsyncThunkWithError(
  'coatings/fetchCoatingsProfileThunk',
  getProfileById,
  'errorFetchingCoatingsProfile',
);

/**
 * Thunk for fetching list of Coatings Paints
 */
export const fetchCoatingsPaintsThunk = createAsyncThunkWithError(
  'coatings/fetchCoatingsPaintsThunk',
  getPaints,
  'errorFetchingCoatingsPaints',
);

/**
 * Thunk for fetching Coatings Profile Attachments with urls
 */
export const fetchCoatingsProfileAttachmentUrlsThunk =
  createAsyncThunkWithError(
    'coatings/fetchCoatingsProfileAttachmentUrlsThunk',
    getProfileAttachmentUrls,
    'errorFetchingCoatingsProfileAttachmentUrls',
  );

/**
 * Thunk for creating or updating a Coatings Profile
 */
export const createOrUpdateCoatingsProfileThunk = createAsyncThunkWithError(
  'coatings/createOrUpdateCoatingsProfileThunk',
  createOrUpdateProfile,
  'errorCreatingOrUpdatingCoatingsProfile',
);

/**
 * Thunk for updating a Coatings Profile
 */
export const updateCoatingsProfileThunk = createAsyncThunkWithError(
  'coatings/updateCoatingsProfileThunk',
  updateProfile,
  'errorUpdatingCoatingsProfile',
);

/**
 * Thunk for deactivating a Coatings Profile
 */
export const deactivateCoatingsProfileThunk = createAsyncThunkWithError(
  'coatings/deactivateCoatingsProfileThunk',
  deactivateProfile,
  'errorDeletingCoatingsProfile',
);

/**
 * Thunk for adding or updating Coatings Profile attachments
 */
export const addOrUpdateCoatingsProfileAttachmentsThunk =
  createAsyncThunkWithError(
    'coatings/addOrUpdateCoatingsProfileAttachmentsThunk',
    addOrUpdateProfileAttachments,
    'errorAddingAttachmentsToCoatingsProfile',
  );

/**
 * Thunk for adding or updating Coatings Profile Alert notes
 */
export const addOrUpdateCoatingsProfileAlertNotesThunk =
  createAsyncThunkWithError(
    'coatings/addOrUpdateCoatingsProfileAlertNotesThunk',
    addOrUpdateProfileAlertNotes,
    'errorUpdatingCoatingsProfile',
  );

/**
 * Thunk for adding or updating Coatings Profile Alert notes
 */
export const addOrUpdateCoatingsProfilePalletStackingNotesThunk =
  createAsyncThunkWithError(
    'coatings/addOrUpdateCoatingsProfilePalletStackingNotesThunk',
    addOrUpdateProfilePalletStackingNotes,
    'errorUpdatingCoatingsProfile',
  );

/**
 * Thunk for adding or updating Coatings Profile Barcode First Pass IDs
 */
export const addOrUpdateCoatingsProfileBarcodeFirstPassIdsThunk =
  createAsyncThunkWithError(
    'coatings/addOrUpdateCoatingsProfileBarcodeFirstPassIdsThunk',
    addOrUpdateProfileBarcodeFirstPassIds,
    'errorUpdatingCoatingsProfile',
  );

/**
 * Thunk for adding or updating Coatings Profile Barcode Second Pass IDs
 */
export const addOrUpdateCoatingsProfileBarcodeSecondPassIdsThunk =
  createAsyncThunkWithError(
    'coatings/addOrUpdateCoatingsProfileBarcodeSecondPassIdsThunk',
    addOrUpdateProfileBarcodeSecondPassIds,
    'errorUpdatingCoatingsProfile',
  );

/**
 * Thunk for adding or updating Coatings Profile Auto Setup Barcode IDs
 */
export const addOrUpdateCoatingsProfileAutoSetupBarcodeIdsThunk =
  createAsyncThunkWithError(
    'coatings/addOrUpdateCoatingsProfileAutoSetupBarcodeIdsThunk',
    addOrUpdateProfileAutoSetupBarcodeIds,
    'errorUpdatingCoatingsProfile',
  );

/**
 * Thunk for deleting an attachment from a Coatings Profile
 */
export const deleteAttachmentFromCoatingsProfileThunk =
  createAsyncThunkWithError(
    'coatings/deleteAttachmentFromCoatingsProfileThunk',
    deleteAttachmentFromProfile,
    'errorDeletingAttachmentFromCoatingsProfile',
  );

/**
 * Redux slice for the Coatings feature
 */
export const coatingsSlice = createSlice({
  name: 'coatings',
  initialState,
  reducers: {
    setCoatingsSearchAction: (state, action) => {
      state.filter = { ...state.filter, search: action.payload };
    },
    setMaterialFilterAction: (state, action) => {
      state.filter = { ...state.filter, materials: action.payload };
    },
    setProductCategoryFilterAction: (state, action) => {
      state.filter = {
        ...state.filter,
        productCategoryContains: action.payload,
      };
    },
    addOrUpdateCoatingsProfileAction: (state, action) => {
      const index = state.profiles.findIndex((p) => p.id === action.payload.id);
      if (index >= 0) {
        state.profiles[index] = action.payload;
      } else {
        state.profiles.push(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCoatingsProfilesThunk.pending, (state) => {
        state.profilesStatus = 'loading';
      })
      .addCase(fetchCoatingsProfilesThunk.fulfilled, (state, action) => {
        state.profilesStatus = 'succeeded';
        state.profiles = action.payload;
      })
      .addCase(fetchCoatingsProfilesThunk.rejected, (state) => {
        state.profilesStatus = 'failed';
      })
      .addCase(fetchCoatingsProfileThunk.pending, (state) => {
        state.profilesStatus = 'loading';
      })
      .addCase(fetchCoatingsProfileThunk.fulfilled, (state) => {
        state.profilesStatus = 'succeeded';
      })
      .addCase(fetchCoatingsProfileThunk.rejected, (state) => {
        state.profilesStatus = 'failed';
      })
      .addCase(fetchCoatingsPaintsThunk.pending, (state) => {
        state.paintsStatus = 'loading';
      })
      .addCase(fetchCoatingsPaintsThunk.fulfilled, (state, action) => {
        state.paintsStatus = 'succeeded';
        state.paints = action.payload;
      })
      .addCase(fetchCoatingsPaintsThunk.rejected, (state) => {
        state.paintsStatus = 'failed';
      })
      .addCase(fetchCoatingsProfileAttachmentUrlsThunk.pending, (state) => {
        state.profilesStatus = 'loading';
      })
      .addCase(fetchCoatingsProfileAttachmentUrlsThunk.fulfilled, (state) => {
        state.profilesStatus = 'succeeded';
      })
      .addCase(fetchCoatingsProfileAttachmentUrlsThunk.rejected, (state) => {
        state.profilesStatus = 'failed';
      })
      .addCase(createOrUpdateCoatingsProfileThunk.pending, (state) => {
        state.profilesStatus = 'loading';
      })
      .addCase(createOrUpdateCoatingsProfileThunk.fulfilled, (state) => {
        state.profilesStatus = 'succeeded';
      })
      .addCase(createOrUpdateCoatingsProfileThunk.rejected, (state) => {
        state.profilesStatus = 'failed';
      })
      .addCase(updateCoatingsProfileThunk.pending, (state) => {
        state.profilesStatus = 'loading';
      })
      .addCase(updateCoatingsProfileThunk.fulfilled, (state) => {
        state.profilesStatus = 'succeeded';
      })
      .addCase(updateCoatingsProfileThunk.rejected, (state) => {
        state.profilesStatus = 'failed';
      })
      .addCase(deactivateCoatingsProfileThunk.pending, (state) => {
        state.profilesStatus = 'loading';
      })
      .addCase(deactivateCoatingsProfileThunk.fulfilled, (state) => {
        state.profilesStatus = 'succeeded';
      })
      .addCase(deactivateCoatingsProfileThunk.rejected, (state) => {
        state.profilesStatus = 'failed';
      })
      .addCase(addOrUpdateCoatingsProfileAttachmentsThunk.pending, (state) => {
        state.profilesStatus = 'loading';
      })
      .addCase(
        addOrUpdateCoatingsProfileAttachmentsThunk.fulfilled,
        (state) => {
          state.profilesStatus = 'succeeded';
        },
      )
      .addCase(addOrUpdateCoatingsProfileAttachmentsThunk.rejected, (state) => {
        state.profilesStatus = 'failed';
      })
      .addCase(addOrUpdateCoatingsProfileAlertNotesThunk.pending, (state) => {
        state.profilesStatus = 'loading';
      })
      .addCase(addOrUpdateCoatingsProfileAlertNotesThunk.fulfilled, (state) => {
        state.profilesStatus = 'succeeded';
      })
      .addCase(addOrUpdateCoatingsProfileAlertNotesThunk.rejected, (state) => {
        state.profilesStatus = 'failed';
      })

      .addCase(
        addOrUpdateCoatingsProfilePalletStackingNotesThunk.pending,
        (state) => {
          state.profilesStatus = 'loading';
        },
      )
      .addCase(
        addOrUpdateCoatingsProfilePalletStackingNotesThunk.fulfilled,
        (state) => {
          state.profilesStatus = 'succeeded';
        },
      )
      .addCase(
        addOrUpdateCoatingsProfilePalletStackingNotesThunk.rejected,
        (state) => {
          state.profilesStatus = 'failed';
        },
      )
      .addCase(
        addOrUpdateCoatingsProfileBarcodeFirstPassIdsThunk.pending,
        (state) => {
          state.profilesStatus = 'loading';
        },
      )
      .addCase(
        addOrUpdateCoatingsProfileBarcodeFirstPassIdsThunk.fulfilled,
        (state) => {
          state.profilesStatus = 'succeeded';
        },
      )
      .addCase(
        addOrUpdateCoatingsProfileBarcodeFirstPassIdsThunk.rejected,
        (state) => {
          state.profilesStatus = 'failed';
        },
      )
      .addCase(
        addOrUpdateCoatingsProfileBarcodeSecondPassIdsThunk.pending,
        (state) => {
          state.profilesStatus = 'loading';
        },
      )
      .addCase(
        addOrUpdateCoatingsProfileBarcodeSecondPassIdsThunk.fulfilled,
        (state) => {
          state.profilesStatus = 'succeeded';
        },
      )
      .addCase(
        addOrUpdateCoatingsProfileBarcodeSecondPassIdsThunk.rejected,
        (state) => {
          state.profilesStatus = 'failed';
        },
      )
      .addCase(
        addOrUpdateCoatingsProfileAutoSetupBarcodeIdsThunk.pending,
        (state) => {
          state.profilesStatus = 'loading';
        },
      )
      .addCase(
        addOrUpdateCoatingsProfileAutoSetupBarcodeIdsThunk.fulfilled,
        (state) => {
          state.profilesStatus = 'succeeded';
        },
      )
      .addCase(
        addOrUpdateCoatingsProfileAutoSetupBarcodeIdsThunk.rejected,
        (state) => {
          state.profilesStatus = 'failed';
        },
      )

      .addCase(deleteAttachmentFromCoatingsProfileThunk.pending, (state) => {
        state.profilesStatus = 'loading';
      })
      .addCase(deleteAttachmentFromCoatingsProfileThunk.fulfilled, (state) => {
        state.profilesStatus = 'succeeded';
      })
      .addCase(deleteAttachmentFromCoatingsProfileThunk.rejected, (state) => {
        state.profilesStatus = 'failed';
      });
  },
});

/**
 * Selector to select filter
 * @param state
 */
export const selectFilter = (state: RootState) => state.coatings.filter;

/**
 * Selector to select profiles
 * @param state
 */
export const selectCoatingsProfiles = (state: RootState) =>
  state.coatings.profiles;

/**
 * Selector to determine whether profiles are loading
 * @param state
 */
export const selectLoadingCoatingsProfiles = (state: RootState) =>
  state.coatings.profilesStatus === 'loading';

/**
 * Selector to select Paints
 * @param state
 */
export const selectCoatingsPaints = (state: RootState) => state.coatings.paints;

/**
 * Selector to determine whether Paints are loading
 * @param state
 */
export const selectLoadingCoatingsPaints = (state: RootState) =>
  state.coatings.paintsStatus === 'loading';

export default coatingsSlice.reducer;
