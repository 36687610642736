import axiosInstance from '../../api';
import { User, UserSetting } from './types';

/**
 * API call to get list of Users, optionally filtered by search
 * @param search
 */
export const getUsers = async (search: string): Promise<User[]> => {
  const response = await axiosInstance.get('/api/Profile/user', {
    params: { search },
  });
  return response.data;
};

/**
 * API call to get all user settings
 */
export const getUserSettings = async (): Promise<UserSetting> => {
  const response = await axiosInstance.get<UserSetting>('/api/profile/setting');
  return response.data;
};

/**
 * API call to set a user setting
 * @param key
 * @param value
 */
export const setUserSetting = async (key: string, value: string) => {
  await axiosInstance.post(
    `/api/profile/setting/${key}`,
    JSON.stringify(value)
  );
};

/**
 * API call to set multiple user settings
 * @param settings
 */
export const setUserSettings = async (settings: UserSetting) => {
  await axiosInstance.post(`/api/profile/setting`, settings);
};

/**
 * API call to delete user setting
 * @param key
 */
export const deleteUserSetting = async (key: string) => {
  await axiosInstance.delete(`/api/profile/setting/${key}`);
};

/**
 * API call to set multiple global settings
 * @param settings
 */
export const setGlobalSettings = async (settings: UserSetting) => {
  await axiosInstance.post(`/api/profile/globalSetting`, settings);
};
