import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import {
  DateTimeRepeat,
  dateTimeRepeats,
  dateTimeRepeatStrings,
  DayOfMonth,
  DayOfWeek,
  dayOfWeekStrings,
  daysOfMonth,
  daysOfWeek,
  RecurrenceInfo,
} from './date.types';
import { TFunction } from 'i18next';
import { OptionValue } from '../features/common/EnhancedSelect';

export const INPUT_DATE_TIME_FORMAT = 'MM/DD/YY hh:mm a';
export const CELL_BOARD_SUMMARY_INPUT_DATE_FORMAT = 'MM/DD/YYYY';
export const INPUT_DATE_FORMAT = 'MM/DD/YY';
export const SHIFT_DATE_HOURS_FORMAT = 'ddd, MMM Do YYYY, hh:mm a';
export const SHIFT_DATE_FORMAT = 'ddd, MMM Do YYYY';
export const PRODUCTION_DATE_FORMAT = 'LL';
export const DATA_REFRESH_INTERVAL = 300000; // 5 mins

/**
 * Converts time stamped variables to hour and min. format and combines them
 * with a dash to represent time interval.
 */
export const formatIntervalTime = (fromDate: string, toDate: string) => {
  const start = moment(fromDate).format('h:mm a');
  const finish = moment(toDate).format('h:mm a');
  return `${start + ' - ' + finish}`;
};

/**
 * Converts time stamped variables to hour and min. format and combines them
 * with a dash to represent time interval. Short format
 * @param fromDate
 * @param toDate
 */
export const formatIntervalTimeShort = (fromDate: string, toDate: string) => {
  const start = moment(fromDate).format('ha').replace('m', '');
  const finish = moment(toDate).format('ha').replace('m', '');
  return `${start + ' - ' + finish}`;
};

/**
 * Converts time stamped variable noteDate to correct format for display
 */
export const formatTime = (noteDate: string | undefined) => {
  const date = moment(noteDate).format('h:mm a');
  return `${date}`;
};

/**
 * Converts time stamped variable fromDate to correct format to display
 * shift date
 */
export const formatDate = (shiftDate: Date | string | undefined) => {
  const date = moment(shiftDate).format('ddd, MMM Do YYYY');
  return `${date}`;
};

/**
 * Formats datetime into short MM/DD/YYYY format
 * @param date
 */
export const formatDateShort = (date: Date | string | undefined) => {
  if (date) {
    return moment(date).format('MM/DD/YYYY');
  } else return '...';
};

/**
 * Formats datetime into month/day format
 * @param date
 */
export const formatMonthDayDate = (date: Date | string | undefined) => {
  if (date) {
    return moment(date).format('MM/DD');
  } else return '...';
};

/**
 * Formats a date into the default format `ddd MMM Do h:mm a` or the provided format if given.
 * Returns ellipses when date is null
 * @param date
 * @param expectedFormat
 */
export const formatDateTime = (
  date: Date | string | null,
  expectedFormat?: string,
) => {
  if (date) {
    const defaultFormat = 'ddd, MMM Do YYYY, h:mm a';
    return moment(date).format(expectedFormat ?? defaultFormat);
  } else return '...';
};

/**
 * Formats a date into from now format eg `10 minutes ago`
 * @param date
 * @param withoutSuffix
 */
export const formatDateFromNow = (
  date: Date | string | null | undefined,
  withoutSuffix = false,
) => {
  if (date) {
    return moment(date).fromNow(withoutSuffix);
  } else return '...';
};

/**
 * Formats a date into the ISO format.
 * Localizes the date to the user's timezone
 * @param date
 */
export const formatDateTimeIsoLocal = (
  date: Date | string | null | undefined,
) => {
  if (date) {
    return moment(date).format();
  } else return '...';
};

/**
 * Determines whether a date is not a Monday
 * @param date
 */
export const isNotMonday = (date: Moment) => {
  return date.day() !== 1;
};

/**
 * Gets the monday of the current week
 */
export const getMondayOfCurrentWeek = () => {
  return moment().startOf('week').add(1, 'days');
};

/**
 * Hook for creating a countdown timer in the format HH:mm:ss
 * @param endTime
 */
export const useCountdownTimer = (endTime?: string | Date): string => {
  const [time, setTime] = useState('00:00:00');

  useEffect(() => {
    function calculateAndSetTime() {
      const now = moment();
      const timeDiff = moment(endTime).diff(now);
      const timeDiffFormatted = moment.utc(timeDiff).format('HH:mm:ss');
      setTime(timeDiffFormatted);
    }
    let intervalTimer: any = null;
    if (endTime) {
      calculateAndSetTime();
      intervalTimer = setInterval(calculateAndSetTime, 250);
    } else {
      clearInterval(intervalTimer);
    }
    return () => clearInterval(intervalTimer);
  }, [time, endTime]);
  return time;
};

/**
 * Gets datetime repeat options for use with select components
 * @param t
 */
export const getDateTimeRepeatOptions = (
  t: TFunction,
): OptionValue<DateTimeRepeat>[] => {
  return dateTimeRepeats.map((d, i) => ({
    label: t(dateTimeRepeatStrings[i]),
    value: d,
  }));
};

/**
 * Gets datetime repeat options excluding 'Daily' for use with select components
 * @param t
 */
export const getDateTimeRepeatOptionsExcludingDaily = (
  t: TFunction,
): OptionValue<DateTimeRepeat>[] => {
  return dateTimeRepeats
    .filter((d) => d !== 'Daily')
    .map((d) => ({
      label: t(dateTimeRepeatStrings[dateTimeRepeats.indexOf(d)]),
      value: d,
    }));
};

/**
 * Gets the string key for a datetime repeat
 * @param repeat
 */
export const getDateTimeRepeatStringKey = (repeat: DateTimeRepeat): string => {
  return dateTimeRepeatStrings[dateTimeRepeats.findIndex((v) => v === repeat)];
};

/**
 * Gets day of week options for use with select components
 * @param t
 */
export const getDayOfWeekOptions = (t: TFunction): OptionValue<DayOfWeek>[] => {
  return daysOfWeek.map((d, i) => ({
    label: t(dayOfWeekStrings[i]),
    value: d,
  }));
};

/**
 * Gets the string key for a day of week
 * @param dayOfWeek
 */
export const getDayOfWeekStringKey = (dayOfWeek: DayOfWeek): string => {
  return dayOfWeekStrings[daysOfWeek.findIndex((v) => v === dayOfWeek)];
};

/**
 * Gets day of month options for use with select components
 */
export const getDayOfMonthOptions = (): OptionValue<DayOfMonth>[] => {
  return daysOfMonth.map((d) => ({
    label: d.toString(),
    value: d,
  }));
};

/**
 * Gets monthly interval options for use with select components
 */
export const getMonthlyIntervalOptions = (): OptionValue<number>[] => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((d) => ({
    label: d.toString(),
    value: d,
  }));
};

/**
 * Gets a summary string for a recurrence info
 * @param recurrenceInfo
 * @param startDate
 * @param endDate
 * @param t
 */
export const getRecurrenceSummaryString = (
  recurrenceInfo: RecurrenceInfo,
  startDate: Date | null,
  endDate: Date | null,
  t: TFunction,
): string => {
  const {
    repeat,
    daysOfWeek,
    monthlyDays,
    monthlyInterval,
    occurrenceDurationDays,
  } = recurrenceInfo;
  const repeatString = t(getDateTimeRepeatStringKey(repeat));
  const daysOfWeekString = daysOfWeek
    .map((d) => t(getDayOfWeekStringKey(d)))
    .join(', ');
  const daysOfMonthString = monthlyDays.join(', ');
  const startDateString = formatDateTime(startDate);
  const endDateString = formatDateTime(endDate);
  switch (repeat) {
    case 'Daily':
      return t('repeatsDailyForADaysEachStartingBEndingC', {
        a: occurrenceDurationDays,
        b: startDateString,
        c: endDateString,
      });
    case 'Weekly':
      return t('repeatsAonBForCDaysEachStartingDEndingE', {
        a: repeatString,
        b: daysOfWeekString,
        c: occurrenceDurationDays,
        d: startDateString,
        e: endDateString,
      });
    case 'Monthly':
      return t('repeatsAEveryBMonthsOnDayCForDDaysEachStartingEEndingF', {
        a: repeatString,
        b: monthlyInterval,
        c: daysOfMonthString,
        d: occurrenceDurationDays,
        e: startDateString,
        f: endDateString,
      });
    default:
      return '';
  }
};

/**
 * Gets a summary string for an occurrence
 * @param t
 * @param occurrenceType
 * @param daysOfWeek
 * @param daysOfMonth
 * @param occurrenceTime
 */
export const getOccurrenceSummaryString = (
  t: TFunction,
  occurrenceType?: DateTimeRepeat,
  daysOfWeek?: DayOfWeek[],
  daysOfMonth?: DayOfMonth[],
  occurrenceTime?: string,
): string => {
  const daysOfWeekString =
    daysOfWeek?.map((d) => t(d.toLowerCase())).join(', ') || '';
  const daysOfMonthString =
    daysOfMonth?.map((d) => moment().date(d).format('Do')).join(', ') || '';
  const timeString = occurrenceTime
    ? t(' at {{time}}', { time: formatTimeTo12Hour(occurrenceTime) })
    : '';

  switch (occurrenceType) {
    case 'Daily':
      return t('occursDaily{{timeString}}', { timeString });
    case 'Weekly':
      return t('occursWeeklyOn {{daysOfWeekString}}{{timeString}}', {
        daysOfWeekString,
        timeString,
      });
    case 'Monthly':
      return t('occursMonthlyOnThe {{daysOfMonthString}}{{timeString}}', {
        daysOfMonthString,
        timeString,
      });
    default:
      return '';
  }
};

/**
 * Formats a time string to 12-hour format
 * @param time
 */
export const formatTimeTo12Hour = (time: string): string => {
  return moment(time, 'HH:mm:ss').format('hh:mm A');
};

/**
 * Gets the start of a given date
 *  @param date - The date to get the start of. It can be either a Date object or a string representing a date.
 *  @param [strict=false] - Optional. If set to true, the date (if string representation) will be strictly parsed according to the provided format.
 *  @throws {Error} If an invalid date is provided or if strict mode is enabled and the date string does not match the format.
 *  @returns {Moment} The start of the given date as a Moment object with the time set to 00:00:00.
 */
export const getStartOfDay = (
  date: Date | string,
  strict: boolean = false,
): Moment => {
  if (!date) {
    throw new Error('Invalid date provided');
  }
  return moment(date, strict).startOf('day');
};

/**
 * Removes the Time from a Date object
 * @param date
 * @returns {string | null} - The date in ISO format
 */
export const stripTime = (date: Date | null): string | null => {
  if (!date) return null;
  const isoDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0),
  );
  return isoDate.toISOString();
};

/**
 * Converts a Moment object to a Date object
 * @param date
 * @returns {Date | null} - The date object
 */
export const convertMomentToDate = (
  date: Date | moment.Moment | null,
): Date | null => {
  if (!date) return null;
  if (moment.isMoment(date)) {
    return date.toDate();
  }
  return date;
};

/**
 * Converts a Date object to a Moment object and adds the given number of weeks
 * @param date
 * @param weeks
 * @returns {string} - The date in ISO format
 */
export const addWeeksToDate = (date: Date | string, weeks: number): string => {
  return moment(date).add(weeks, 'weeks').toISOString();
};

/**
 * Adds a specified number of years to a given date and returns the new date.
 *
 * @param {Date | string} date - The initial date to which years will be added. It can be either a Date object or a string representing a date.
 * @param {number} years - The number of years to add to the initial date.
 * @returns {Date} - The new date, after adding the specified number of years.
 */
export const addYearsToDate = (date: Date | string, years: number): Date => {
  return moment(date).add(years, 'years').toDate();
};

/**
 * Converts a given time to UTC format.
 * @param time - The time to be converted.
 * @returns The time in UTC format as a string.
 */
export const convertTimeOnlyToUtc = (time: string): string => {
  return moment(time, 'HH:mm:ss').utc().format('HH:mm:ss');
};

/**
 * Converts a given UTC time to local time format.
 * @param time - The UTC time to be converted.
 * @returns The local time as a string in the format "HH:mm:ss".
 */
export const convertTimeOnlyFromUtc = (time: string): string => {
  return moment.utc(time, 'HH:mm:ss').local().format('HH:mm:ss');
};

/**
 * Get the ISO day of the week from a date.
 * Returns ISO weekday number (1 = Monday, ... 7 = Sunday)
 * @param date
 */
export const getDayOfWeekIso = (date: string): number => {
  return moment(date).isoWeekday();
};

/**
 * Get the current ISO day of the week.
 * Returns the ISO weekday number (1 = Monday, ... 7 = Sunday)
 * @returns {number} Current day as an ISO weekday number.
 */
export const getCurrentDayIso = (): number => {
  return moment().isoWeekday();
};

/**
 * Get a map of day strings to their corresponding ISO weekday numbers.
 * The map is localized based on the provided translation function.
 * This function utilizes the DayOfWeek type to ensure type safety.
 * @param {TFunction} t - Translation function used to translate day strings.
 * @returns {Record<string, number>} Map of localized day strings to ISO weekday numbers.
 */
export const getDayStringToIsoMap = (t: TFunction): Record<string, number> => {
  // Adjust the day array to start from Monday
  const adjustedDayIndices = [1, 2, 3, 4, 5, 6, 0];
  let map: Record<string, number> = {};

  // Mapping each day of the week to its ISO weekday number
  adjustedDayIndices.forEach((dayIndex, index) => {
    const dayName = dayOfWeekStrings[dayIndex] as DayOfWeek;
    map[t(dayName)] = index + 1; // Start from Monday = 1, Sunday = 7
  });

  return map;
};
