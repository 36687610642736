import { InventoryCenter, MCell } from '../manufacturingLocation/types';

export const mCellAlertTypes = [
  'CrewingOver',
  'CrewingUnder',
  'Downtime',
  'DemandManagementStatus',
  'OperationsStatus',
] as const;
export const mCellAlertTypeStrings = [
  'crewingOver',
  'crewingUnder',
  'downtime',
  'demandManagementStatus',
  'operationsStatus',
] as const;
export type MCellAlertType = typeof mCellAlertTypes[number];

export const mCellAlertLevels = ['Elevated', 'High'] as const;
export type MCellAlertLevel = typeof mCellAlertLevels[number];

export const mCellAlertSorts = ['Alphabetical', 'RankScore'] as const;
export type MCellAlertSort = typeof mCellAlertSorts[number];

export type MCellAlert = {
  detail?: string;
  type: MCellAlertType;
  level: MCellAlertLevel;
};

export type MCellAlertData = {
  mCell: MCell;
  alerts: MCellAlert[];
  isKey: boolean;
};

export type MCellAlertsFilter = {
  inventoryCenterId: string;
  mCellId: string;
  mCellCollectionId: string;
  includeEmptyMCells: boolean;
  alertLevel: MCellAlertLevel;
  sortBy: MCellAlertSort;
};

export type MCellAlertPoints = {
  id: string;
  inventoryCenterId: string;
  inventoryCenter: InventoryCenter;
  alertType: MCellAlertType;
  alertLevel: MCellAlertLevel;
  points: number;
};

export type MCellAlertPointsInfo = Omit<
  MCellAlertPoints,
  'id' | 'inventoryCenter'
>;

export type MCellAlertKeyMCellScore = {
  id: string;
  inventoryCenterId: string;
  inventoryCenter: InventoryCenter;
  points: number;
  factorPercent: number;
};

export type MCellAlertKeyMCellScoreInfo = Omit<
  MCellAlertKeyMCellScore,
  'id' | 'inventoryCenter'
>;
