import { Filter, IntegratedFiltering } from '@devexpress/dx-react-grid';
import { getStartOfDay } from './date';
import { TFunction } from 'i18next';
import saveAs from 'file-saver';
import moment from 'moment';

// Default table page size
export const DEFAULT_PAGE_SIZE = 10;
// Default table page size options
export const DEFAULT_PAGE_SIZES = [10, 50, 100];

/**
 * Generates unique values for use as options in EnhancedMultiSelect.
 *
 * This function extracts unique values from a specified property within an array of objects.
 * Property values can optionally be split into multiple tokens using a separator, facilitating
 * granular extraction of unique elements. An optional custom processing function can be provided
 * to further manipulate each item after splitting.
 *
 * @param {any[]} data - The data to generate unique values from.
 * @param {string} property - The property in the data to generate unique values from.
 * @param {string} [separator] - Optional separator to split the property values into tokens. If not provided,
 *                               the property value is treated as a single entity.
 * @param {(item: string) => string} [processItem] - Optional function to further process each item after splitting.
 *                                                   This function should take a string as input and return a transformed string.
 *                                                   The default behavior is to return the input string as is.
 * @returns {string[]} An array of unique values derived from the specified property.
 */
export const getUniqueValues = (
  data: any[],
  property: string,
  separator?: string,
  processItem: (item: string) => string = (item) => item,
): string[] => {
  return Array.from(
    new Set(
      data
        .flatMap((item) =>
          typeof item[property] === 'string'
            ? separator
              ? item[property].split(separator).map(processItem)
              : [processItem(item[property])]
            : [],
        )
        .filter((item) => item !== ''),
    ),
  );
};

/**
 * Evaluates a multi-select filter condition on a given string value based on the specified filter criteria.
 * For 'planningCodesCountHours', the function extracts tuple values from the input string and uses these for comparison.
 * Otherwise, it splits the input string by '; ' to obtain individual values for comparison. If the last value ends with
 * a semicolon, it is removed to ensure accurate filtering
 *
 * @param {string} value The row to be evaluated by the filter condition
 * @param {Filter} filter An object representing the filter criteria
 * @returns {boolean} Returns true if the input value meets the filter criteria, false otherwise. The default
 *                    behavior for unspecified operations is to return true, indicating no filtering applied.
 */
export const multiSelectFilterPredicate = (
  value: string,
  filter: Filter,
): boolean => {
  let values = (value ?? '') // Used to prevent null or undefined values from breaking the following operations
    .trim()
    .split('; ')
    .filter((v) => v !== '');
  let tuples = null;

  // Checks if the last value ends with a semicolon and removes it if it exists
  if (values.length > 0 && values[values.length - 1].endsWith(';')) {
    values[values.length - 1] = values[values.length - 1].slice(0, -1).trim();
  }

  if (filter.columnName === 'planningCodesCountHours') {
    tuples = Array.from(value.matchAll(/\(([^,]+),[^)]+\)/g), (m) => m[1]);
    values = tuples;
  }

  const filterValues = Array.isArray(filter.value)
    ? filter.value.map((item) => item.value)
    : [];

  switch (filter.operation) {
    case 'contains':
      return values.some((v) => filterValues.includes(v));
    case 'notContains':
      return !values.some((v) => filterValues.includes(v));
    case 'onlyContains':
      return (
        values.length === filterValues.length &&
        values.every((v) => filterValues.includes(v))
      );
    case 'blank':
      return value === '' || value === null || value === undefined;
    case 'notBlank':
      return value !== '' && value !== null && value !== undefined;
    default:
      return true;
  }
};

/**
 * Evaluates whether a date cell value falls within a specified date range.
 * It specifically handles the 'dateRange' filter operation, checking if the cell value is between
 * the start and end date values defined in the filter.
 *
 * @param {string} value The cell value to be evaluated.
 * @param {Filter} filter The filter criteria object
 * @returns {boolean} Returns true if the cell value is within the specified range for 'dateRange' operations.
 *                    If 'startDate' or 'endDate' are not valid dates, it returns true, effectively skipping filtering.
 *                    This behavior ensures that filtering is applied only when valid date range criteria
 *                    are provided.
 */
export const dateRangeFilterPredicate = (
  value: string,
  filter: Filter,
): boolean => {
  if (filter && filter.operation === 'dateRange') {
    const { start, end } = filter.value || {};
    if (start && !isNaN(start.getTime()) && end && !isNaN(end.getTime())) {
      // Sets the values being compared to YYYY-MM-DDT00:00:00 format
      // Used to avoid disparities in data from server when filtering date range values
      const rowDate = value ? getStartOfDay(value) : moment();
      return start <= rowDate && rowDate <= end;
    }
  }
  return true;
};

/**
 * Evaluates whether a numeric cell value falls within a specified numerical range.
 * It specifically handles the 'withinRange' filter operation, checking if the cell value is between
 * the start and end values defined in the filter. For operations
 * other than 'withinRange', it delegates the decision to a default filtering predicate
 *
 * @param {string} value The cell value to be evaluated.
 * @param {Filter} filter The filter criteria object
 * @param {any} row The current row of data being evaluated. This parameter provides context for the default
 *                  predicate but is not directly used by the 'withinRange' operation.
 * @returns {boolean} Returns true if the cell value is within the specified range for 'withinRange' operations,
 *                    or defers to the IntegratedFiltering.defaultPredicate's return value for other operations.
 *                    If 'start' or 'end' are not valid numbers, it returns true, effectively skipping filtering.
 *                    This behavior ensures that filtering is applied only when valid numerical range criteria
 *                    are provided.
 */
export const numberRangeFilterPredicate = (
  value: string,
  filter: Filter,
  row: any,
): boolean => {
  if (filter.operation === 'withinRange') {
    const { start, end } = filter.value || {};

    if (typeof start === 'number' && typeof end === 'number') {
      const numericCellValue = Number(value);
      return numericCellValue >= start && numericCellValue <= end;
    }
    return true;
  } else {
    return IntegratedFiltering.defaultPredicate(value, filter, row);
  }
};

type CustomFilterColumnExtensionProps = {
  dateRangeFilterColumns?: string[];
  multiSelectFilterColumns?: string[];
  numberRangeFilterColumns?: string[];
};

/**
 * Custom Hook that returns integrated filtering column extensions for a data grid/table.
 * @param {CustomFilterColumnExtensionProps} props - The hook props.
 * @returns {IntegratedFiltering.ColumnExtension[]} An array of column extensions with custom filtering predicates.
 */
export const useColumnExtensions = ({
  dateRangeFilterColumns = [],
  multiSelectFilterColumns = [],
  numberRangeFilterColumns = [],
}: CustomFilterColumnExtensionProps): IntegratedFiltering.ColumnExtension[] => {
  const dateRangeColumnExtensions = dateRangeFilterColumns.map(
    (columnName) => ({
      columnName,
      predicate: (value: string, filter: Filter) => {
        return dateRangeFilterPredicate(value, filter);
      },
    }),
  );

  const multiSelectColumnExtensions = multiSelectFilterColumns.map(
    (columnName) => ({
      columnName,
      predicate: (value: string, filter: Filter) =>
        multiSelectFilterPredicate(value, filter),
    }),
  );

  const numberColumnExtensions = numberRangeFilterColumns.map((columnName) => ({
    columnName,
    predicate: (cellValue: string, filter: Filter, row: any) => {
      return numberRangeFilterPredicate(cellValue, filter, row);
    },
  }));

  return [
    ...dateRangeColumnExtensions,
    ...multiSelectColumnExtensions,
    ...numberColumnExtensions,
  ];
};

/**
 * Exports table data to a CSV file.
 * @param workbook The workbook object containing table data.
 * @param exportFileName The base name for the exported file.
 * @param t Function to localize the file name.
 */
export const onSaveExport = (
  workbook: any,
  exportFileName: string,
  t: TFunction,
) => {
  workbook.csv.writeBuffer().then((buffer: any) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${t(exportFileName)}.csv`,
    );
  });
};

/**
 * Determines the number of visible columns in the table based on the width of the container.
 * If the dimensions of the container are available, it calculates the number of visible columns
 * by dividing the width of the container by the column width and rounding down to the nearest whole number.
 * If the dimensions of the container are not available, it defaults to 0 visible columns.
 *
 * @param {DOMRectReadOnly | null} dimensions - The dimensions of the container.
 * @param {number} columnWidth - The width of a single column.
 * @returns {number} The number of visible columns in the table.
 */
export const calculateVisibleColumns = (
  dimensions: DOMRectReadOnly | null,
  columnWidth: number,
): number => {
  return dimensions ? Math.floor(dimensions.width / columnWidth) : 0;
};
