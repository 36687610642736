import axiosInstance from '../../api';
import {
  Downtime,
  DowntimeCreate,
  DowntimeEscalationAssignment,
  DowntimeFilterValue,
  DowntimeReason,
  DowntimeReasonCreate,
  DowntimeReasonDescriptionUpdate,
  DowntimeReasonSupportGroupMapping,
  DowntimeReasonSupportGroupMappingCreate,
  DowntimeReasonSupportGroupMappingUpdate,
  DowntimeReasonUpdate,
} from './types';

/**
 * API call to get Issues
 * @param mCellId - If not empty, filters issues by this MCell
 * @param inventoryCenterId - If not empty, filters issues by this Inventory Center
 * @param mCellCollectionId
 */
export const getDowntimes = async (
  mCellId: string,
  inventoryCenterId: string,
  mCellCollectionId: string,
): Promise<Array<Downtime>> => {
  const response = await axiosInstance.get<Array<Downtime>>('/api/downtime', {
    params: {
      mCellId,
      inventoryCenterId,
      mCellCollectionId,
    },
  });
  return response.data;
};

/**
 * API call to get current Issues
 * @param mCellId - If not empty, filters Issues by this MCell
 * @param inventoryCenterId - If not empty, filters Issues by this Inventory Center
 * @param mCellCollectionId
 */
export const getCurrentDowntimes = async (
  mCellId: string,
  inventoryCenterId: string,
  mCellCollectionId: string,
): Promise<Array<Downtime>> => {
  const response = await axiosInstance.get<Array<Downtime>>(
    '/api/downtime/current',
    {
      params: {
        mCellId,
        inventoryCenterId,
        mCellCollectionId,
      },
    },
  );
  return response.data;
};

/**
 * API call to get an Issue
 * @param id
 */
export const getDowntimeById = async (id: string): Promise<Downtime> => {
  const response = await axiosInstance.get<Downtime>(`/api/downtime/${id}`);
  return response.data;
};

/**
 * API call to create an Issue
 * @param downtimeCreate
 */
export const createDowntime = async (
  downtimeCreate: DowntimeCreate,
): Promise<Downtime> => {
  const response = await axiosInstance.post('/api/downtime', downtimeCreate);
  return response.data;
};

/**
 * API call to request help on an existing Issue
 * @param downtimeId
 */
export const requestHelp = async (downtimeId: string) => {
  await axiosInstance.post(`/api/downtime/${downtimeId}/help`);
};

/**
 * API call to mark an Issue as Help Arrived
 * @param downtimeId
 */
export const markHelpArrived = async (downtimeId: string) => {
  await axiosInstance.post(`/api/downtime/${downtimeId}/help-arrived`);
};

/**
 * API call to end an Issue
 * @param params
 */
export const endDowntime = async (params: {
  downtimeId: string;
  endDate: Date | null;
}) => {
  await axiosInstance.post(`/api/downtime/${params.downtimeId}/end`, {
    endDate: params.endDate ? params.endDate : undefined,
  });
};

/**
 * API call to change the severity of an Issue
 * @param params
 */
export const changeSeverity = async (params: {
  downtimeId: string;
  newSeverity: number;
}) => {
  await axiosInstance.post(
    `/api/downtime/${params.downtimeId}/change-percent-affected/${params.newSeverity}`,
  );
};

/**
 * API call to update the reason of an Issue. Additionally, queues
 * Start Escalation.
 * @param params
 */
export const updateDowntimeReason = async (
  params: {
    downtimeId: string;
  } & DowntimeReasonDescriptionUpdate,
): Promise<Downtime> => {
  const response = await axiosInstance.post(
    `/api/downtime/${params.downtimeId}/updateReason`,
    {
      reasonId: params.reasonId,
      description: params.description,
      assetId: params.assetId,
    },
  );
  return response.data;
};

/**
 * API call to add comment to an Issue
 * @param params
 */
export const addComment = async (params: {
  downtimeId: string;
  message: string;
}) => {
  await axiosInstance.post(
    `/api/downtime/${params.downtimeId}/comment`,
    JSON.stringify(params.message),
  );
};

/**
 * API call to delete an Issue
 * @param downtimeId
 */
export const deleteDowntime = async (downtimeId: string) => {
  await axiosInstance.delete(`/api/downtime/${downtimeId}`);
};

/**
 * API call to get list of Issue Reasons
 * @param inventoryCenterId
 */
export const getDowntimeReasons = async (
  inventoryCenterId: string,
): Promise<Array<DowntimeReason>> => {
  const response = await axiosInstance.get<Array<DowntimeReason>>(
    '/api/downtime/reason',
    { params: { inventoryCenterId } },
  );

  return response.data.map((reason) => {
    return {
      ...reason,
      extendedProperties:
        typeof reason.extendedProperties === 'string'
          ? JSON.parse(reason.extendedProperties as string)
          : [],
    };
  });
};

/**
 * API call to create an Issue Reason
 * @param downtimeReasonCreate
 */
export const createDowntimeReason = async (
  downtimeReasonCreate: DowntimeReasonCreate,
): Promise<DowntimeReason> => {
  const modifiedDowntimeReasonCreate = { ...downtimeReasonCreate };
  modifiedDowntimeReasonCreate.extendedProperties = JSON.stringify([]);
  const response = await axiosInstance.post<DowntimeReason>(
    '/api/downtime/reason',
    modifiedDowntimeReasonCreate,
  );

  return response.data;
};

/**
 * API call to update an Issue Reason
 * @param downtimeReasonUpdate
 */
export const updateDowntimeReasonById = async (
  downtimeReasonUpdate: DowntimeReasonUpdate,
): Promise<DowntimeReason> => {
  const modifiedDowntimeReasonUpdate = { ...downtimeReasonUpdate };
  modifiedDowntimeReasonUpdate.extendedProperties = JSON.stringify(
    modifiedDowntimeReasonUpdate.extendedProperties,
  );

  const response = await axiosInstance.post<DowntimeReason>(
    `/api/downtime/reason/${downtimeReasonUpdate.id}`,
    modifiedDowntimeReasonUpdate,
  );
  return response.data;
};

/**
 * API call to delete an Issue Reason
 * @param downtimeReasonId
 */
export const deleteDowntimeReason = async (downtimeReasonId: string) => {
  await axiosInstance.delete(`/api/downtime/reason/${downtimeReasonId}`);
};

/**
 * API call to get list of Issue Reason Support Group Mappings
 */
export const getDowntimeReasonSupportGroupMappings = async (): Promise<
  Array<DowntimeReasonSupportGroupMapping>
> => {
  const response = await axiosInstance.get<
    Array<DowntimeReasonSupportGroupMapping>
  >('/api/downtime/reasons/supportGroupMappings');
  return response.data;
};

/**
 * API call to create an Issue Reason Support Group Mapping
 * @param downtimeReasonSupportGroupMappingCreate
 */
export const createDowntimeReasonSupportGroupMapping = async (
  downtimeReasonSupportGroupMappingCreate: DowntimeReasonSupportGroupMappingCreate,
): Promise<DowntimeReasonSupportGroupMapping> => {
  const response = await axiosInstance.post<DowntimeReasonSupportGroupMapping>(
    '/api/downtime/reasons/supportGroupMappings',
    downtimeReasonSupportGroupMappingCreate,
  );
  return response.data;
};

/**
 * API call to update an Issue Reason Support Group Mapping
 * @param downtimeReasonSupportGroupMappingId
 * @param downtimeReasonSupportGroupMappingUpdate
 */
export const updateDowntimeReasonSupportGroupMapping = async (
  downtimeReasonSupportGroupMappingId: string,
  downtimeReasonSupportGroupMappingUpdate: DowntimeReasonSupportGroupMappingUpdate,
): Promise<DowntimeReasonSupportGroupMapping> => {
  const response = await axiosInstance.post<DowntimeReasonSupportGroupMapping>(
    `/api/downtime/reasons/supportGroupMappings/${downtimeReasonSupportGroupMappingId}`,
    downtimeReasonSupportGroupMappingUpdate,
  );
  return response.data;
};

/**
 * API call to delete an Issue Reason Support Group Mapping
 * @param downtimeReasonSupportGroupMappingId
 */
export const deleteDowntimeReasonSupportGroupMapping = async (
  downtimeReasonSupportGroupMappingId: string,
) => {
  await axiosInstance.delete(
    `/api/downtime/reasons/supportGroupMappings/${downtimeReasonSupportGroupMappingId}`,
  );
};
/**
 * API call to get EscalationAssignments
 * @param downtimeId
 */
export const getEscalationAssignments = async (
  downtimeId: string,
): Promise<DowntimeEscalationAssignment[]> => {
  const response = await axiosInstance.get(
    `/api/downtime/${downtimeId}/escalationAssignments`,
  );
  return response.data;
};

/**
 * API call to get Issues Report data
 * @param filter
 */
export const getDowntimesReportData = async (
  filter: DowntimeFilterValue,
): Promise<Downtime[]> => {
  const response = await axiosInstance.get('/api/downtime/downtimeReport', {
    params: filter,
  });
  return response.data;
};
