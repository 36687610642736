import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Asset, AssetInfoUpdate, AssetQuery } from './types';
import { createAsset, deleteAsset, getAssets, updateAsset } from './api';
import { createAsyncThunkWithError } from '../../redux/utils';
import { LoadingStatus } from '../../api/app.types';

export type MachineState = {
  filter: AssetQuery | undefined;
  assets: Asset[];
  assetsStatus: LoadingStatus;
};

export const initialState: MachineState = {
  filter: undefined,
  assets: [],
  assetsStatus: 'idle',
};

/**
 * Action to set Asset Search information in the store
 */
export const setAssetsSearchAction = createAction<string>(
  'machine/setAssetsSearchAction'
);

/**
 * Action to add or update an Asset in the store
 */
export const addOrUpdateAssetAction = createAction<AssetInfoUpdate>(
  'machine/addOrUpdateAssetAction'
);

/**
 * Thunk for creating an Asset and saving it to the store
 */
export const createAssetThunk = createAsyncThunkWithError(
  'machine/createAssetThunk',
  createAsset,
  'errorCreatingAsset'
);

/**
 * Thunk for updating all Asset fields (except assetId)
 */
export const updateAssetThunk = createAsyncThunkWithError(
  'machine/updateAssetThunk',
  async (info: AssetInfoUpdate, { dispatch }): Promise<Asset> => {
    dispatch(addOrUpdateAssetAction(info));
    return updateAsset(info);
  },
  'errorUpdatingAsset'
);

/**
 * Thunk for fetching Assets from the server and saving to the store
 */
export const fetchAssetsThunk = createAsyncThunkWithError(
  'machine/fetchAssetsThunk',
  getAssets,
  'errorFetchingAssets'
);

/**
 * Thunk for deleting an Asset
 */
export const deleteAssetThunk = createAsyncThunkWithError(
  'machine/deleteAssetThunk',
  deleteAsset,
  'errorDeletingAsset'
);

export const machineSlice = createSlice({
  name: 'machine',
  initialState,
  reducers: {
    setAssetsSearchAction: (state, action) => {
      state.filter = { ...state.filter, search: action.payload };
    },
    addOrUpdateAssetAction: (state, action: PayloadAction<AssetInfoUpdate>) => {
      const index = state.assets.findIndex((x) => x.id === action.payload.id);
      if (index !== -1) {
        const asset = state.assets[index];
        state.assets[index] = { ...asset, ...action.payload };
      } else {
        const asset: Asset = {
          ...action.payload,
        };
        state.assets.push(asset);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAssetThunk.pending, (state) => {
        state.assetsStatus = 'loading';
      })
      .addCase(createAssetThunk.fulfilled, (state, { payload }) => {
        state.assetsStatus = 'succeeded';
        state.assets.push(payload);
      })
      .addCase(createAssetThunk.rejected, (state) => {
        state.assetsStatus = 'failed';
      })
      .addCase(updateAssetThunk.pending, (state) => {
        state.assetsStatus = 'loading';
      })
      .addCase(updateAssetThunk.fulfilled, (state, { payload }) => {
        state.assetsStatus = 'succeeded';
        const index = state.assets.findIndex((x) => x.id === payload.id);
        if (index > -1) {
          state.assets[index] = payload;
        } else {
          state.assets.push(payload);
        }
      })
      .addCase(updateAssetThunk.rejected, (state) => {
        state.assetsStatus = 'failed';
      })
      .addCase(fetchAssetsThunk.pending, (state) => {
        state.assetsStatus = 'loading';
      })
      .addCase(fetchAssetsThunk.fulfilled, (state, action) => {
        state.assetsStatus = 'succeeded';
        state.assets = action.payload;
      })
      .addCase(fetchAssetsThunk.rejected, (state) => {
        state.assetsStatus = 'failed';
      })
      .addCase(deleteAssetThunk.pending, (state) => {
        state.assetsStatus = 'loading';
      })
      .addCase(deleteAssetThunk.fulfilled, (state, { payload }) => {
        state.assetsStatus = 'succeeded';
        const deleteIndex = state.assets.findIndex((x) => x.id === payload);
        if (deleteIndex > -1) {
          state.assets.splice(deleteIndex, 1);
        }
      })
      .addCase(deleteAssetThunk.rejected, (state) => {
        state.assetsStatus = 'failed';
      });
  },
});

/**
 * Selector to select Assets
 * @param state
 */
export const selectAssets = (state: RootState) => state.machine.assets;

/**
 * Selector to select filter
 * @param state
 */
export const selectFilter = (state: RootState) => state.machine.filter;

/**
 * Selector to determine whether Assets are loading
 * @param state
 */
export const selectLoadingAssets = (state: RootState) =>
  state.machine.assetsStatus === 'loading';

export default machineSlice.reducer;
