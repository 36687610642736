import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { getAccessToken } from '../auth';

// By default, Axios encodes arrays in "bracket" format, which is not compatible with our backend.
// by setting this null, we serialize arrays in the expected 'a=b&a=c' format.
axios.defaults.paramsSerializer = {
  indexes: null,
};

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (axiosConfig: AxiosRequestConfig) => {
    const token = await getAccessToken();

    if (token) {
      axiosConfig.headers = {
        ...axiosConfig.headers,
        Authorization: `Bearer ${token}`,
      };

      // Automatically set 'Content-Type' based on the data type
      // prevents JSON.Stringify used in api calls from having `undefined` in the Content-Type header
      if (!(axiosConfig.data instanceof FormData)) {
        axiosConfig.headers['Content-Type'] = 'application/json';
      }
    }
    return axiosConfig as InternalAxiosRequestConfig;
  },
);

export default axiosInstance;
