import React from 'react';
import { Chip, SxProps } from '@mui/material';
import {
  useManufacturingLocations,
  useManufacturingLocationString,
} from '../../manufacturingLocation/utils';
import {
  EditIcon,
  InventoryLocationIcon,
  MCellCollectionIcon,
  MCellIcon,
  RollUpIcon,
  GlobalLocationIcon,
} from '../icons';
import { EMPTY_GUID } from '../../../utils/string-utils';

type Props = {
  onClick: () => void;
  classname?: any;
  sx?: SxProps;
  mCellId: string;
  inventoryCenterId: string;
  mCellCollectionId: string;
  disabled?: boolean;
  includeRollupCells?: boolean;
  includeAllMCellCollections?: boolean;
};

/**
 * Displays the currently selected Manufacturing Location
 * @param onClick
 * @param classname
 * @param sx
 * @param mCellId
 * @param inventoryCenterId
 * @param mCellCollectionId
 * @param disabled
 * @param includeRollupCells
 * @param includeAllMCellCollections
 * @constructor
 */
const LocationChip = ({
  onClick,
  classname,
  sx,
  mCellId,
  inventoryCenterId,
  mCellCollectionId,
  disabled,
  includeRollupCells,
  includeAllMCellCollections,
}: Props) => {
  const locationString = useManufacturingLocationString(
    {
      inventoryCenterId,
      mCellId,
      mCellCollectionId,
    },
    includeRollupCells,
    includeAllMCellCollections
  );

  const { mCells } = useManufacturingLocations(includeRollupCells);
  const mCell = mCells.find((m) => m.id === mCellId);
  let icon = <InventoryLocationIcon />;
  if (mCell) {
    if (mCell.isRollup && mCellId !== EMPTY_GUID) {
      icon = <RollUpIcon />;
    } else if (mCellId !== EMPTY_GUID) {
      icon = <MCellIcon />;
    }
  }
  if (mCellCollectionId !== EMPTY_GUID) {
    icon = <MCellCollectionIcon />;
  }
  if (
    inventoryCenterId === EMPTY_GUID &&
    mCellId === EMPTY_GUID &&
    mCellCollectionId === EMPTY_GUID
  ) {
    icon = <GlobalLocationIcon />;
  }

  return (
    <Chip
      color={'primary'}
      icon={icon}
      label={locationString}
      deleteIcon={<EditIcon />}
      onDelete={disabled ? undefined : onClick}
      onClick={disabled ? undefined : onClick}
      className={classname}
      sx={sx}
    />
  );
};

export default LocationChip;
