import React, {
  ChangeEvent,
  DragEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AttachFileIcon, DeleteIcon, UploadIcon } from './icons';
import { useSnackbar } from 'notistack';

const PREFIX = 'FileUpload';

const styles = {
  dropArea: `${PREFIX}-dropArea`,
  fileInput: `${PREFIX}-fileInput`,
  fileListArea: `${PREFIX}-fileListArea`,
};

const StyledFileUploadDiv = styled('div')(({ theme }) => ({
  [`& .${styles.dropArea}`]: {
    border: `2px dashed ${theme.palette.text.secondary}`,
    borderRadius: 4,
    height: 180,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  [`& .${styles.fileInput}`]: {
    display: 'none',
  },

  [`& .${styles.fileListArea}`]: {
    marginTop: theme.spacing(1),
  },
}));

type Props = {
  initialFiles: File[];
  value?: File[];
  onChange: (files: File[]) => void;
  className?: string;
  disabled?: boolean;
  maxFileSizeBytes?: number;
  fileListRenderer?: (
    files: File[],
    onDelete: (f: File) => void,
  ) => React.ReactNode;
  hint?: string;
  single?: boolean;
  accept?: string;
};

/**
 * Component for uploading files by browsing or drag n drop.
 * @param initialFiles
 * @param value
 * @param onChange
 * @param className
 * @param disabled
 * @param maxFileSizeBytes
 * @param fileListRenderer
 * @param hint
 * @param single
 * @param accept
 * @constructor
 */
const FileUpload = ({
  initialFiles,
  value,
  onChange,
  className,
  disabled,
  maxFileSizeBytes,
  fileListRenderer,
  hint,
  single,
  accept,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const inputElRef = useRef<null | HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const maxFileSizeMB = maxFileSizeBytes ? maxFileSizeBytes / 1000000 : 0;
  const initialFilesRef = useRef(initialFiles);
  const multiple = single ? !single : true;
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Effect that sets the initial files passed in from parent
   */
  useEffect(() => {
    setFiles(initialFilesRef.current);
  }, []);

  /**
   * Effect that updates internal state when external state is set
   */
  useEffect(() => {
    if (value) {
      setFiles(value);
    }
  }, [value]);

  /**
   * Called when underlying file input files change. Updates the file list
   * @param event
   */
  function handleFilesChange(event: ChangeEvent<HTMLInputElement>) {
    const fileList = event.target.files;
    processFileList(fileList);
  }

  /**
   * Called when a user drags files over the drop area. Highlights the drop area
   * @param event
   */
  function handleDragOver(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    if (disabled) return;
    setIsDragOver(true);
  }

  /**
   * Called when user drags files away from the drop area. Removes highlight
   * @param event
   */
  function handleDragLeave(event: DragEvent<HTMLDivElement>) {
    setIsDragOver(false);
  }

  /**
   * Called when user drops files in the drop area. Updates the file list.
   * @param event
   */
  function handleDrop(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    if (disabled) return;
    setIsDragOver(false);
    const fileList = event.dataTransfer.files;
    processFileList(fileList);
  }

  /**
   * Called when user clicks on drop area. Opens the file browser
   */
  function handleDropAreaClick() {
    if (disabled) return;
    if (inputElRef.current) {
      inputElRef.current.click();
    }
  }

  /**
   * Removes a file from the list of files and triggers the onChange callback.
   *
   * This function finds the index of the specified file in the current list of files.
   * If the file is found, it creates a new array of files excluding the specified file,
   * updates the state with the new array, and triggers the onChange callback with the updated array.
   *
   * @param {File} file - The file to be removed from the list.
   */
  function handleFileRemove(file: File) {
    const removeIndex = files.findIndex((f) => f.name === file.name);
    if (removeIndex >= 0) {
      const newFiles = [
        ...files.slice(0, removeIndex),
        ...files.slice(removeIndex + 1),
      ];
      setFiles(newFiles);
      onChange(newFiles);
    }
  }

  /**
   * Process a FileList object. Updates the local state and calls onChange
   * prop with an array of File objects.
   * @param fileList
   */
  function processFileList(fileList: FileList | null) {
    if (!fileList) {
      return;
    }

    const newFiles = fileListToArray(fileList);
    const newFilesSizeFiltered = maxFileSizeBytes
      ? newFiles.filter((f) => f.size <= maxFileSizeBytes)
      : newFiles;

    let filesToAdd = newFilesSizeFiltered;

    if (accept) {
      // Add a validation check to prevent incorrect file types from being uploaded if accepted file type specified
      const validFiles = newFilesSizeFiltered.filter((file) =>
        isFileTypeAccepted(file.type),
      );
      if (newFiles.length !== validFiles.length) {
        enqueueSnackbar(t('fileTypeNotAccepted'), { variant: 'error' });
      }

      filesToAdd = validFiles;
    }
    if (newFiles.length !== newFilesSizeFiltered.length) {
      enqueueSnackbar(t('fileSizeMayNotExceedMB', { mb: maxFileSizeMB }), {
        variant: 'error',
      });
    }

    setFiles((prevFiles) => {
      const allFiles = [...prevFiles, ...filesToAdd];
      onChange(allFiles);
      return allFiles;
    });
  }

  /**
   * Used to determine if the file is in the list of accepted types
   * @param fileType
   */
  function isFileTypeAccepted(fileType: string): boolean {
    const acceptedTypes = accept?.split(',') || [];
    return acceptedTypes.some((type) =>
      fileType.toLowerCase().endsWith(type.trim()),
    );
  }

  /**
   * Converts a FileList object to an array of File objects.
   * @param fileList
   */
  function fileListToArray(fileList: FileList): File[] {
    const array: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      if (file) {
        array.push(file);
      }
    }
    return array;
  }

  return (
    <StyledFileUploadDiv className={className}>
      {(multiple || files.length === 0) && (
        <div
          className={styles.dropArea}
          style={{
            background: isDragOver
              ? theme.palette.primary.main
              : theme.palette.background.default,
          }}
          onClick={handleDropAreaClick}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            className={styles.fileInput}
            ref={inputElRef}
            type={'file'}
            multiple={multiple}
            onChange={handleFilesChange}
            accept={accept ?? ''}
          />
          {hint && <Typography>{hint}</Typography>}
          {!hint && multiple && (
            <Typography>{t('dragAndDropFilesHereOrClick')}</Typography>
          )}
          {!hint && !multiple && (
            <Typography>{t('dragAndDropFileHereOrClick')}</Typography>
          )}
          <UploadIcon />
        </div>
      )}
      {!fileListRenderer && files.length > 0 && (
        <div className={styles.fileListArea}>
          {files.map((f) => (
            <Grid container key={f.name} alignItems={'center'} spacing={1}>
              <Grid item>
                <AttachFileIcon />
              </Grid>
              <Grid item>
                <Typography>{f.name}</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => handleFileRemove(f)} size='large'>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </div>
      )}
      {fileListRenderer && fileListRenderer(files, handleFileRemove)}
    </StyledFileUploadDiv>
  );
};

export default FileUpload;
