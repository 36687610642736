import { IconButton, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { HelpCircleIcon } from '../../common/icons';
import { PageName } from '../types';
import { getAbsoluteNavigationPaths } from '../../../utils/navigation';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

/**
 * Renders a help link icon that opens a new tab to the help page.
 * @param pathname
 * @constructor
 */
const HelpLinkIcon = () => {
  const { t } = useTranslation();
  const pathname = useLocation().pathname;
  const {
    reportIssuePath,
    dashboardPath,
    cellBoardMessagePath,
    issueConfigurationPath,
    crewingJobStationAssignmentsPath,
    crewingGuidePath,
    crewingEmployeeSearchPath,
    cellboardSummaryPath,
    supportHierarchyPath,
    schedulingToolCapacityUtilizationPath,
    schedulingToolSalesOrderPath,
    schedulingToolLaborWhatIfPath,
    lswConfigurationsPath,
  } = getAbsoluteNavigationPaths();

  let currentPage: PageName | undefined;
  switch (pathname) {
    case dashboardPath:
      currentPage = 'Dashboard';
      break;
    case cellBoardMessagePath:
      currentPage = 'CellBoardMessage';
      break;
    case reportIssuePath:
      currentPage = 'IssueReport';
      break;
    case issueConfigurationPath:
      currentPage = 'IssueConfiguration';
      break;
    case crewingJobStationAssignmentsPath:
      currentPage = 'JobStationAssignments';
      break;
    case crewingGuidePath:
      currentPage = 'CrewingGuide';
      break;
    case crewingEmployeeSearchPath:
      currentPage = 'CrewingEmployeeSearch';
      break;
    case cellboardSummaryPath:
      currentPage = 'Scoreboard';
      break;
    case supportHierarchyPath:
      currentPage = 'SupportHierarchy';
      break;
    case schedulingToolCapacityUtilizationPath:
      currentPage = 'SchedulingToolCapacityUtilization';
      break;
    case schedulingToolSalesOrderPath:
      currentPage = 'SchedulingToolSalesOrders';
      break;
    case schedulingToolLaborWhatIfPath:
      currentPage = 'SchedulingToolLaborWhatIf';
      break;
    case lswConfigurationsPath:
      currentPage = 'Lsw';
      break;
  }

  const linkUrls = useSelector((state: RootState) => state.app.helpLinks);
  const linkUrl = linkUrls.find((x) => x.pageName === currentPage);
  if (!linkUrl) {
    return null;
  }
  return (
    <Tooltip title={t('help')}>
      <IconButton
        size='large'
        component='a'
        href={linkUrl.linkUrl}
        target='_blank'
        rel='noreferrer'
        sx={{ color: 'white' }}
      >
        <HelpCircleIcon />
      </IconButton>
    </Tooltip>
  );
};

export default HelpLinkIcon;
