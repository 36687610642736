export type AlertLevel = 'error' | 'warn' | 'info' | 'success';

export const pageNames = [
  'IssueReport',
  'IssueConfiguration',
  'JobStationAssignments',
  'CellBoardMessage',
  'CrewingGuide',
  'CrewingEmployeeSearch',
  'Scoreboard',
  'Dashboard',
  'SupportHierarchy',
  'SchedulingToolCapacityUtilization',
  'SchedulingToolSalesOrders',
  'SchedulingToolLaborWhatIf',
  'Lsw',
] as const;
export type PageName = (typeof pageNames)[number];

export type HelpLinkDto = {
  id: string;
  pageName: PageName;
  linkUrl: string;
};

export type HelpLinkCreate = {
  pageName: PageName;
  linkUrl: string;
};

export type HelpLinkUpdate = {
  linkUrl: string;
};
