import {
  LswMySheetDto,
  LswSheetDto,
  LswSheetInfo,
  LswSheetReportDto,
  LswTaskDto,
  LswTaskGroupDto,
  LswTaskGroupInfo,
  LswTaskInfo,
} from './types';
import axiosInstance from '../../api';

/**
 * API call to get LSW sheet by id
 * @param id
 */
export const getLswSheetById = async (id: string): Promise<LswSheetDto> => {
  const response = await axiosInstance.get<LswSheetDto>(`/api/lsw/sheet/${id}`);
  return response.data;
};

/**
 * API call to get LSW sheets
 * @param inventoryCenterId
 */
export const getLswSheets = async (
  inventoryCenterId: string,
): Promise<LswSheetDto[]> => {
  const response = await axiosInstance.get<LswSheetDto[]>('/api/lsw/sheets', {
    params: { inventoryCenterId },
  });
  return response.data;
};

/**
 * API call to create LSW sheet
 * @param lswSheetInfo
 */
export const createLswSheet = async (
  lswSheetInfo: LswSheetInfo,
): Promise<LswSheetDto> => {
  const response = await axiosInstance.post<LswSheetDto>(
    '/api/lsw/sheet',
    lswSheetInfo,
  );
  return response.data;
};

/**
 * API call to update LSW sheet
 * @param params
 */
export const updateLswSheet = async (params: {
  id: string;
  info: LswSheetInfo;
}): Promise<LswSheetDto> => {
  const response = await axiosInstance.post<LswSheetDto>(
    `/api/lsw/sheet/${params.id}`,
    params.info,
  );
  return response.data;
};

/**
 * API call to delete LSW sheet
 * @param id
 */
export const deleteLswSheet = async (id: string): Promise<void> => {
  await axiosInstance.delete(`/api/lsw/sheet/${id}`);
};

/**
 * API call to get LSW task group by id
 * @param id
 */
export const getLswTaskGroupById = async (
  id: string,
): Promise<LswTaskGroupDto> => {
  const response = await axiosInstance.get<LswTaskGroupDto>(
    `/api/lsw/taskGroup/${id}`,
  );
  return response.data;
};

/**
 * API call to get LSW task groups
 * @param inventoryCenterId
 */
export const getLswTaskGroups = async (
  inventoryCenterId: string,
): Promise<LswTaskGroupDto[]> => {
  const response = await axiosInstance.get<LswTaskGroupDto[]>(
    '/api/lsw/taskGroups',
    {
      params: { inventoryCenterId },
    },
  );
  return response.data;
};

/**
 * API call to create LSW task group
 * @param lswTaskGroupInfo
 */
export const createLswTaskGroup = async (
  lswTaskGroupInfo: LswTaskGroupInfo,
): Promise<LswTaskGroupDto> => {
  const response = await axiosInstance.post<LswTaskGroupDto>(
    '/api/lsw/taskGroup',
    lswTaskGroupInfo,
  );
  return response.data;
};

/**
 * API call to update LSW task group
 * @param params
 */
export const updateLswTaskGroup = async (params: {
  id: string;
  info: LswTaskGroupInfo;
}): Promise<LswTaskGroupDto> => {
  const response = await axiosInstance.post<LswTaskGroupDto>(
    `/api/lsw/taskGroup/${params.id}`,
    params.info,
  );
  return response.data;
};

/**
 * API call to delete LSW task group
 * @param id
 */
export const deleteLswTaskGroup = async (id: string): Promise<void> => {
  await axiosInstance.delete(`/api/lsw/taskGroup/${id}`);
};

/**
 * API call to get LSW task by id
 * @param id
 */
export const getLswTaskById = async (id: string): Promise<LswTaskDto> => {
  const response = await axiosInstance.get<LswTaskDto>(`/api/lsw/task/${id}`);
  return response.data;
};

/**
 * API call to get LSW tasks
 * @param sheetId
 */
export const getLswTasks = async (sheetId: string): Promise<LswTaskDto[]> => {
  const response = await axiosInstance.get<LswTaskDto[]>('/api/lsw/tasks', {
    params: { sheetId },
  });
  return response.data;
};

/**
 * API call to create LSW task
 * @param lswTaskInfo
 */
export const createLswTask = async (
  lswTaskInfo: LswTaskInfo,
): Promise<LswTaskDto> => {
  const response = await axiosInstance.post<LswTaskDto>(
    '/api/lsw/task',
    lswTaskInfo,
  );
  return response.data;
};

/**
 * API call to update LSW task
 * @param params
 */
export const updateLswTask = async (params: {
  id: string;
  info: LswTaskInfo;
}): Promise<LswTaskDto> => {
  const response = await axiosInstance.post<LswTaskDto>(
    `/api/lsw/task/${params.id}`,
    params.info,
  );
  return response.data;
};

/**
 * API call to delete LSW task
 * @param id
 */
export const deleteLswTask = async (id: string): Promise<void> => {
  await axiosInstance.delete(`/api/lsw/task/${id}`);
};

/**
 * API call to get list of task tracking for the current user
 */
export const getTaskTrackingByUserId = async (): Promise<LswMySheetDto[]> => {
  const response = await axiosInstance.get<LswMySheetDto[]>(
    '/api/lsw/taskTrackings',
  );
  return response.data;
};

/**
 * API call to update task tracking done date for a particular task tracking
 * @param params
 */
export const updateTaskTrackingDoneDate = async (params: {
  trackingId: string;
  isDone: boolean;
}): Promise<void> => {
  await axiosInstance.post(`/api/lsw/taskTrackings`, null, {
    params: { trackingId: params.trackingId, isDone: params.isDone },
  });
};

/**
 * API call to get task trackings report
 * @param params
 */
export const getTaskTrackingsReport = async (params: {
  inventoryCenterId: string;
  week: string | null;
}): Promise<LswSheetReportDto[]> => {
  const response = await axiosInstance.get<LswSheetReportDto[]>(
    '/api/lsw/taskTrackingsReport',
    {
      params: {
        inventoryCenterId: params.inventoryCenterId,
        week: params.week,
      },
    },
  );
  return response.data;
};

/**
 * API call to get LSW sheet preview
 * @param sheetId
 */
export const getSheetPreview = async (
  sheetId: string,
): Promise<LswMySheetDto> => {
  const response = await axiosInstance.get<LswMySheetDto>(
    `/api/lsw/sheetPreview/${sheetId}`,
  );
  return response.data;
};
