import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';
import EnhancedButton from './EnhancedButton';
import Logo from '../../images/marvin-logo.svg';

const PREFIX = 'NotFoundPage';

const styles = {
  image: `${PREFIX}-image`,
  notFound: `${PREFIX}-notFound`,
  item: `${PREFIX}-item`,
};

const Root = styled('div')({
  [`& .${styles.image}`]: {
    marginTop: -60,
    marginBottom: 10,
  },
  [`& .${styles.notFound}`]: {
    '& h2': {
      textAlign: 'center',
      marginTop: -75,
    },
  },
  [`& .${styles.item}`]: {
    marginTop: 20,
  },
});

const StyledDiv = styled('div')({
  marginTop: 45,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

/**
 * Renders page when path is not found
 * @constructor
 */

const NotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(false);
  const items = t('notFound').split('\n');
  const DURATION = 1000;

  /** Used so our animation always runs once */
  useEffect(() => {
    setChecked(true);
  }, []);

  return (
    <Root>
      <img className={styles.image} src={Logo} alt={t('marvinLogo')} />
      <Slide in={checked} {...(checked ? { timeout: DURATION } : {})}>
        <Box className={styles.notFound} letterSpacing={5}>
          <Typography variant='h2'>{t('404pageNotFound')}</Typography>
        </Box>
      </Slide>
      <Slide in={checked} {...(checked ? { timeout: DURATION } : {})}>
        <Box className={styles.notFound} textAlign='center'>
          {items.map((item) => (
            <Typography variant={'h5'} key={item} className={styles.item}>
              {item}
            </Typography>
          ))}
        </Box>
      </Slide>
      <StyledDiv>
        <EnhancedButton onClick={() => navigate('/')}>
          {t('takeMeHome')}
        </EnhancedButton>
      </StyledDiv>
    </Root>
  );
};

export default NotFoundPage;
