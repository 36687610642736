import React from 'react';
import { CellBoardMCellTaskDto } from '../types';
import { Badge, IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import { isEmptyOrSpaces } from '../../../utils/string-utils';
import {
  CheckBoxOutlineBlankIcon,
  CheckBoxOutlinedIcon,
  TaskIcon,
} from '../icons';

type Props = {
  mCellTasks: CellBoardMCellTaskDto[];
  className?: string;
  onTaskClick: (mCellTask: CellBoardMCellTaskDto) => void;
  onTaskMark: (mCellTask: CellBoardMCellTaskDto) => void;
};

/**
 * Renders an icon button with drop down menu of MCell Tasks
 * @param mCellTasks
 * @param className
 * @param onTaskClick
 * @param onTaskMark
 * @constructor
 */
const MCellTaskButtonMenu = ({
  mCellTasks,
  className,
  onTaskClick,
  onTaskMark,
}: Props) => {
  const unCompletedTasksCount = mCellTasks.filter((x) =>
    isEmptyOrSpaces(x.completedDate)
  ).length;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  /**
   * Opens the menu
   * @param event
   */
  function handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    if (mCellTasks.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  }

  /**
   * Closes the menu
   */
  function handleMenuClose(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    setAnchorEl(null);
  }

  /**
   * Notifies parent of task click
   * @param event
   * @param mCellTask
   */
  function handleTaskClick(
    event: React.MouseEvent<HTMLElement>,
    mCellTask: CellBoardMCellTaskDto
  ) {
    event.stopPropagation();
    onTaskClick(mCellTask);
  }

  /**
   * Notifies parent of task mark complete/incomplete
   * @param event
   * @param mCellTask
   */
  function handleTaskMark(
    event: React.MouseEvent<HTMLElement>,
    mCellTask: CellBoardMCellTaskDto
  ) {
    event.stopPropagation();
    onTaskMark(mCellTask);
  }

  return (
    <>
      <IconButton
        onClick={handleMenuOpen}
        className={className}
        disabled={mCellTasks.length === 0}
        size='large'
      >
        <Badge
          overlap={'rectangular'}
          badgeContent={unCompletedTasksCount}
          color={'error'}
          invisible={unCompletedTasksCount === 0}
        >
          <TaskIcon />
        </Badge>
      </IconButton>
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        sx={{ top: 50 }}
      >
        {mCellTasks.map((x) => (
          <MenuItem key={x.id} onClick={(e) => handleTaskClick(e, x)}>
            <IconButton
              edge='start'
              onClick={(e) => handleTaskMark(e, x)}
              size='large'
            >
              {x.completedDate ? (
                <CheckBoxOutlinedIcon />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
            </IconButton>
            <ListItemText>{x?.task?.description ?? '...'}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MCellTaskButtonMenu;
