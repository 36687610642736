import {
  DangerousEquipment,
  DangerousEquipmentInfo,
  DemandManagementStatusInventoryCenterConfig,
  DemandManagementStatusInventoryCenterConfigInfo,
  DemandManagementStatusMCellConfig,
  DemandManagementStatusMCellConfigInfo,
  EmployeeInterest,
  EmployeeInterestInfo,
  InterestProfile,
  JobStation,
  JobStationAssignmentDto,
  JobStationDangerousEquipmentInfo,
  JobStationInfo,
  JobStationPersonalProtectiveEquipmentInfo,
  MCellCapacity,
  MCellEmployee,
  MCellEmployeeAccountType,
  MCellEmployeeCategory,
  MCellEmployeeMCellHistory,
  MCellEmployeeRole,
  MCellStatus,
  MCellStatusInfo,
  MCellStatusLevel,
  PersonalProtectiveEquipment,
  PersonalProtectiveEquipmentInfo,
} from './types';
import { LoadingStatus } from '../../api/app.types';
import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { createAsyncThunkWithError } from '../../redux/utils';
import {
  createDangerousEquipment,
  createEmployeeInterest,
  createInterestProfile,
  createPersonalProtectiveEquipment,
  deleteDangerousEquipment,
  deleteEmployeeInterest,
  deletePersonalProtectiveEquipment,
  getDangerousEquipments,
  getDemandManagementStatusInventoryCenterConfigs,
  getDemandManagementStatusMCellConfigs,
  getEmployeeInterests,
  getInterestProfile,
  getMCellCapacities,
  getMCellEmployeeAccountTypes,
  getMCellEmployeeByBadgeId,
  getMCellEmployeeById,
  getMCellEmployeeCategories,
  getMCellEmployeeCurrentMCell,
  getMCellEmployeeMCellHistory,
  getMCellEmployeeRoles,
  getMCellEmployees,
  getMCellStatuses,
  getPersonalProtectiveEquipments,
  getTopMCells,
  updateDangerousEquipment,
  setDemandManagementStatusInventoryCenterConfig,
  setDemandManagementStatusMCellConfig,
  setMCellStatusDemandManagement,
  setMCellStatusDemandManagementBulk,
  setMCellStatusOperations,
  setMCellStatusOperationsBulk,
  updatePersonalProtectiveEquipment,
  updateEmployeeInterest,
  updateInterestProfile,
  updateMCellCapacity,
  getJobStations,
  createJobStation,
  jobStationAddPersonalProtectiveEquipment,
  jobStationAddDangerousEquipment,
  jobStationRemovePersonalProtectiveEquipment,
  jobStationRemoveDangerousEquipment,
  updateJobStation,
  deleteJobStation,
  getCurrentJobStationAssignments,
  setJobStationAssignments,
  getMCellEmployeeByEmployeeId,
} from './api';
import { RootState } from '../../store';
import { MCell } from '../manufacturingLocation/types';

export type ResourceTrackingState = {
  employeeInterests: EmployeeInterest[];
  employeeInterestsStatus: LoadingStatus;
  mCellEmployees: MCellEmployee[];
  mCellEmployeesStatus: LoadingStatus;
  interestProfile: InterestProfile | null;
  interestProfileStatus: LoadingStatus;
  topMCells: MCell[];
  topMCellsStatus: LoadingStatus;
  mCellEmployeeMCellHistory: MCellEmployeeMCellHistory[];
  mCellEmployeeMCellHistoryStatus: LoadingStatus;
  mCellCapacities: MCellCapacity[];
  mCellCapacitiesStatus: LoadingStatus;
  mCellStatuses: MCellStatus[];
  mCellStatusesStatus: LoadingStatus;
  employeeCurrentCell?: MCell;
  employeeCurrentCellStatus: LoadingStatus;
  currentEmployee?: MCellEmployee;
  currentEmployeeStatus: LoadingStatus;
  mCellEmployeeAccountTypes: MCellEmployeeAccountType[];
  mCellEmployeeAccountTypesStatus: LoadingStatus;
  mCellEmployeeRoles: MCellEmployeeRole[];
  mCellEmployeeRolesStatus: LoadingStatus;
  mCellEmployeeCategories: MCellEmployeeCategory[];
  mCellEmployeeCategoriesStatus: LoadingStatus;
  demandManagementStatusInventoryCenterConfigs: DemandManagementStatusInventoryCenterConfig[];
  demandManagementStatusInventoryCenterConfigsStatus: LoadingStatus;
  demandManagementStatusMCellConfigs: DemandManagementStatusMCellConfig[];
  demandManagementStatusMCellConfigsStatus: LoadingStatus;
  dangerousEquipment: DangerousEquipment[];
  dangerousEquipmentStatus: LoadingStatus;
  personalProtectiveEquipment: PersonalProtectiveEquipment[];
  personalProtectiveEquipmentStatus: LoadingStatus;
  jobStations: JobStation[];
  jobStationsStatus: LoadingStatus;
  jobStationAssignments: JobStationAssignmentDto[];
  jobStationAssignmentsStatus: LoadingStatus;
};

export const initialState: ResourceTrackingState = {
  employeeInterests: [],
  employeeInterestsStatus: 'idle',
  mCellEmployees: [],
  mCellEmployeesStatus: 'idle',
  interestProfile: null,
  interestProfileStatus: 'idle',
  topMCells: [],
  topMCellsStatus: 'idle',
  mCellEmployeeMCellHistory: [],
  mCellEmployeeMCellHistoryStatus: 'idle',
  mCellCapacities: [],
  mCellCapacitiesStatus: 'idle',
  mCellStatuses: [],
  mCellStatusesStatus: 'idle',
  employeeCurrentCell: undefined,
  employeeCurrentCellStatus: 'idle',
  currentEmployee: undefined,
  currentEmployeeStatus: 'idle',
  mCellEmployeeAccountTypes: [],
  mCellEmployeeAccountTypesStatus: 'idle',
  mCellEmployeeRoles: [],
  mCellEmployeeRolesStatus: 'idle',
  mCellEmployeeCategories: [],
  mCellEmployeeCategoriesStatus: 'idle',
  demandManagementStatusInventoryCenterConfigs: [],
  demandManagementStatusInventoryCenterConfigsStatus: 'idle',
  demandManagementStatusMCellConfigs: [],
  demandManagementStatusMCellConfigsStatus: 'idle',
  dangerousEquipment: [],
  dangerousEquipmentStatus: 'idle',
  personalProtectiveEquipment: [],
  personalProtectiveEquipmentStatus: 'idle',
  jobStations: [],
  jobStationsStatus: 'idle',
  jobStationAssignments: [],
  jobStationAssignmentsStatus: 'idle',
};

/**
 * Action to set MCell Status Operation in the store
 */
export const setMCellStatusOperationsAction = createAction<MCellStatusInfo>(
  'resourceTracking/setMCellStatusOperationsAction',
);

/**
 * Action to set MCell Status Demand Management in the store
 */
export const setMCellStatusDemandManagementAction =
  createAction<MCellStatusInfo>(
    'resourceTracking/setMCellStatusDemandManagementAction',
  );

/**
 * Action to delete Personal Protective Equipment in the store
 */
export const deletePersonalProtectiveEquipmentAction = createAction<string>(
  'resourceTracking/deletePersonalProtectiveEquipmentAction',
);

/**
 * Action to add or update Personal Protective Equipment in the store
 */
export const addOrUpdatePersonalProtectiveEquipmentAction =
  createAction<PersonalProtectiveEquipmentInfo>(
    'resourceTracking/addOrUpdatePersonalProtectiveEquipmentAction',
  );

/**
 * Action to delete Dangerous Equipment in the store
 */
export const deleteDangerousEquipmentAction = createAction<string>(
  'resourceTracking/deleteDangerousEquipmentAction',
);

/**
 * Action to add or update Dangerous Equipment in the store
 */
export const addOrUpdateDangerousEquipmentAction =
  createAction<DangerousEquipmentInfo>(
    'resourceTracking/addOrUpdateDangerousEquipmentAction',
  );

/**
 * Action to add or update an Employee Interest in the store
 */
export const addOrUpdateEmployeeInterestAction = createAction<EmployeeInterest>(
  'resourceTracking/addOrUpdateEmployeeInterestAction',
);

/**
 * Action to delete an Employee Interest in the store
 */
export const deleteEmployeeInterestAction = createAction<string>(
  'resourceTracking/deleteEmployeeInterestAction',
);

/**
 * Action to add or update an MCell Capacity in the store
 */
export const addOrUpdateMCellCapacityAction = createAction<MCellCapacity>(
  'resourceTracking/addOrUpdateMCellCapacityAction',
);

/**
 * Action to clear the Interest Profile from the store
 */
export const clearInterestProfileAction = createAction(
  'resourceTracking/clearInterestProfileAction',
);

/**
 * Action to add or update a Demand Management Status Inventory Center Config in the store
 */
export const addOrUpdateDemandManagementStatusInventoryCenterConfigAction =
  createAction<DemandManagementStatusInventoryCenterConfigInfo>(
    'resourceTracking/addOrUpdateDemandManagementStatusInventoryCenterConfigAction',
  );

/**
 * Action to add or update a Demand Management Status MCell Config in the store
 */
export const addOrUpdateDemandManagementStatusMCellConfigAction =
  createAction<DemandManagementStatusMCellConfigInfo>(
    'resourceTracking/addOrUpdateDemandManagementStatusMCellConfigAction',
  );

/**
 *  Action to update Dangerous Equipment for Job Station in the store
 */
export const jobStationAddDangerousEquipmentAction =
  createAction<JobStationDangerousEquipmentInfo>(
    'resourceTracking/jobStationAddDangerousEquipmentAction',
  );

/**
 * Action to delete a Job Station Dangerous Equipment in the store
 */
export const jobStationRemoveDangerousEquipmentAction =
  createAction<JobStationDangerousEquipmentInfo>(
    'resourceTracking/jobStationRemoveDangerousEquipmentAction',
  );

/**
 * Action to update Personal Protective Equipment for Job Station in the store
 */
export const jobStationAddPersonalProtectiveEquipmentAction =
  createAction<JobStationPersonalProtectiveEquipmentInfo>(
    'resourceTracking/jobStationAddPersonalProtectiveEquipmentAction',
  );

/**
 * Action to remove a piece of Personal Protective Equipment from a Job Station in the store
 */
export const jobStationRemovePersonalProtectiveEquipmentAction =
  createAction<JobStationPersonalProtectiveEquipmentInfo>(
    'resourceTracking/jobStationRemovePersonalProtectiveEquipmentAction',
  );

/**
 * Action to add or update a Job Station in the store
 */
export const addOrUpdateJobStationAction = createAction<JobStationInfo>(
  'resourceTracking/addOrUpdateJobStationAction',
);

/**
 * Action to delete a Job Station in the store
 */
export const deleteJobStationAction = createAction<string>(
  'resourceTracking/deleteJobStationAction',
);

/**
 * Thunk for fetching Employee Interests from the backend and setting in the store
 */
export const getEmployeeInterestsThunk = createAsyncThunkWithError(
  'resourceTracking/getEmployeeInterestsThunk',
  getEmployeeInterests,
  'errorFetchingEmployeeInterests',
);

/**
 * Thunk for creating an Employee Interest in the backend
 */
export const createEmployeeInterestThunk = createAsyncThunkWithError(
  'resourceTracking/createEmployeeInterestThunk',
  createEmployeeInterest,
  'errorCreatingHobby',
);

/**
 * Thunk for updating an Employee Interest in the backend while optimistically
 * updating the store
 */
export const updateEmployeeInterestThunk = createAsyncThunkWithError(
  'resourceTracking/updateEmployeeInterestThunk',
  async (
    {
      id,
      employeeInterestInfo,
    }: {
      id: string;
      employeeInterestInfo: EmployeeInterestInfo;
    },
    { dispatch },
  ): Promise<EmployeeInterest> => {
    dispatch(
      addOrUpdateEmployeeInterestAction({ id, ...employeeInterestInfo }),
    );
    return updateEmployeeInterest({
      id,
      employeeInterestInfo,
    });
  },
  'errorUpdatingHobby',
);

/**
 * Thunk for fetching MCell Employees from the backend and setting in the store
 */
export const getMCellEmployeesThunk = createAsyncThunkWithError(
  'resourceTracking/getMCellEmployeesThunk',
  getMCellEmployees,
  'errorFetchingWorkCellEmployees',
);

/**
 * Thunk for fetching an MCell Employee by Badge ID from the backend and setting in the store
 */
export const getMCellEmployeeByBadgeIdThunk = createAsyncThunkWithError(
  'resourceTracking/getMCellEmployeeByBadgeIdThunk',
  getMCellEmployeeByBadgeId,
  'errorFetchingWorkCellEmployee',
);

/**
 * Thunk for fetching an MCell Employee by their Employee ID from the backend and setting state in the store
 */
export const getMCellEmployeeByEmployeeIdThunk = createAsyncThunkWithError(
  'resourceTracking/getMCellEmployeeByEmployeeIdThunk',
  getMCellEmployeeByEmployeeId,
  'errorFetchingWorkCellEmployee',
);

/**
 * Thunk for deleting an Employee Interest in the backend while optimistically
 * updating the store
 */
export const deleteEmployeeInterestThunk = createAsyncThunkWithError(
  'resourceTracking/deleteEmployeeInterestThunk',
  async (id: string, { dispatch }): Promise<string> => {
    dispatch(deleteEmployeeInterestAction(id));
    return deleteEmployeeInterest(id);
  },
  'errorDeletingHobby',
);

/**
 * Thunk for creating an Interest Profile in the backend and setting in the store
 */
export const createInterestProfileThunk = createAsyncThunkWithError(
  'resourceTracking/createInterestProfileThunk',
  createInterestProfile,
  'errorCreatingInterestProfile',
);

/**
 * Thunk for fetching an Interest Profile from the backend and setting in the store
 */
export const getInterestProfileThunk = createAsyncThunkWithError(
  'resourceTracking/getInterestProfileThunk',
  getInterestProfile,
  'errorFetchingInterestProfile',
);

/**
 * Thunk for updating an Interest Profile in the backend and setting in the store
 */
export const updateInterestProfileThunk = createAsyncThunkWithError(
  'resourceTracking/updateInterestProfileThunk',
  updateInterestProfile,
  'errorUpdatingInterestProfile',
);

/**
 * Thunk for fetching an MCell Employee's top MCells from the backend and setting
 * in the store
 */
export const getTopMCellsThunk = createAsyncThunkWithError(
  'resourceTracking/getTopMCellsThunk',
  getTopMCells,
  'errorFetchingTopWorkCells',
);

/**
 * Thunk for fetching an MCell Employee's MCell History from the backend and
 * setting in the store
 */
export const getMCellEmployeeMCellHistoryThunk = createAsyncThunkWithError(
  'resourceTracking/getMCellEmployeeMCellHistoryThunk',
  getMCellEmployeeMCellHistory,
  'errorFetchingEmployeeWorkCellHistory',
);

/**
 * Thunk for fetching MCell Capacities from the backend and setting in the store
 */
export const getMCellCapacitiesThunk = createAsyncThunkWithError(
  'resourceTracking/getMCellCapacitiesThunk',
  getMCellCapacities,
  'errorFetchingWorkCellCapacities',
);

/**
 * Thunk for updating an MCell Capacity in the backend while optimistically setting
 * in the store
 */
export const updateMCellCapacityThunk = createAsyncThunkWithError(
  'resourceTracking/updateMCellCapacityThunk',
  async (
    mCellCapacity: MCellCapacity,
    { dispatch },
  ): Promise<MCellCapacity> => {
    dispatch(addOrUpdateMCellCapacityAction(mCellCapacity));
    const { id, mCell, ...mCellCapacityInfo } = mCellCapacity;
    return updateMCellCapacity(id, mCellCapacityInfo);
  },
  'errorUpdatingWorkCellCapacity',
);

/**
 * Thunk for fetching MCell Statuses from the backend and setting in the store
 */
export const getMCellStatusesThunk = createAsyncThunkWithError(
  'resourceTracking/getMCellStatusesThunk',
  getMCellStatuses,
  'errorFetchingWorkCellStatuses',
);

/**
 * Thunk for setting an MCell Operations Status in the backend
 * while optimistically updating the store
 */
export const setMCellStatusOperationsThunk = createAsyncThunkWithError(
  'resourceTracking/setMCellStatusOperationsThunk',
  async (
    {
      id,
      status,
    }: {
      id: string;
      status: MCellStatusLevel;
    },
    { dispatch },
  ): Promise<MCellStatus> => {
    dispatch(setMCellStatusOperationsAction({ id, status }));
    return setMCellStatusOperations({ id, status });
  },
  'errorSettingWorkCellStatusOperations',
);

/**
 * Thunk for setting an MCell Demand Management Status in the backend
 * while optimistically updating the store
 */
export const setMCellStatusDemandManagementThunk = createAsyncThunkWithError(
  'resourceTracking/setMCellStatusDemandManagementThunk',
  async (
    {
      id,
      status,
    }: {
      id: string;
      status: MCellStatusLevel;
    },
    { dispatch },
  ): Promise<MCellStatus> => {
    dispatch(setMCellStatusDemandManagementAction({ id, status }));
    return setMCellStatusDemandManagement({ id, status });
  },
  'errorSettingWorkCellStatusDemandManagement',
);

/**
 * Thunk for bulk setting MCell Operations Statuses in the backend and in the
 * store
 */
export const setMCellStatusOperationsBulkThunk = createAsyncThunkWithError(
  'resourceTracking/setMCellStatusOperationsBulkThunk',
  setMCellStatusOperationsBulk,
  'errorSettingWorkCellStatusOperationsBulk',
);

/**
 * Thunk for bulk setting MCell Demand Management Statuses in the backend and
 * in the store
 */
export const setMCellStatusDemandManagementBulkThunk =
  createAsyncThunkWithError(
    'resourceTracking/setMCellStatusDemandManagementBulkThunk',
    setMCellStatusDemandManagementBulk,
    'errorSettingWorkCellStatusDemandManagementBulk',
  );

/**
 * Thunk for fetching the current MCell Employee from the backend and setting
 * in the store
 */
export const getCurrentMCellEmployeeThunk = createAsyncThunkWithError(
  'resourceTracking/getCurrentMCellEmployeeThunk',
  getMCellEmployeeById,
  'errorFetchingEmployee',
);

/**
 * Thunk for fetching an MCell Employee's current MCell from the backend and
 * setting in the store
 */
export const getMCellEmployeeCurrentMCellThunk = createAsyncThunkWithError(
  'resourceTracking/getMCellEmployeeCurrentMCellThunk',
  getMCellEmployeeCurrentMCell,
  'errorFetchingEmployeeCurrentWorkCell',
);

/**
 * Thunk for fetching MCell Employee Account Types from the backend and setting
 * in the store
 */
export const getMCellEmployeeAccountTypesThunk = createAsyncThunkWithError(
  'resourceTracking/getMCellEmployeeAccountTypesThunk',
  getMCellEmployeeAccountTypes,
  'errorFetchingAccountTypes',
);

/**
 * Thunk for fetching MCell Employee Roles from the backend and setting in the
 * store
 */
export const getMCellEmployeeRolesThunk = createAsyncThunkWithError(
  'resourceTracking/getMCellEmployeeRolesThunk',
  getMCellEmployeeRoles,
  'errorFetchingRoles',
);

/**
 * Thunk for fetching MCell Employee Categories from the backend and setting in
 * the store
 */
export const getMCellEmployeeCategoriesThunk = createAsyncThunkWithError(
  'resourceTracking/getMCellEmployeeCategoriesThunk',
  getMCellEmployeeCategories,
  'errorFetchingCategories',
);

/**
 * Thunk for fetching Demand Management Status Inventory Center Configs from
 * the backend and setting in the store
 */
export const getDemandManagementStatusInventoryCenterConfigsThunk =
  createAsyncThunkWithError(
    'resourceTracking/getDemandManagementStatusInventoryCenterConfigsThunk',
    getDemandManagementStatusInventoryCenterConfigs,
    'errorFetchingDemandManagementConfigurations',
  );

/**
 * Thunk for updating a Demand Management Status Inventory Center Config in the
 * backend while optimistically updating the store
 */
export const setDemandManagementStatusInventoryCenterConfigThunk =
  createAsyncThunkWithError(
    'resourceTracking/setDemandManagementStatusInventoryCenterConfigThunk',
    async (
      info: DemandManagementStatusInventoryCenterConfigInfo,
      { dispatch },
    ): Promise<DemandManagementStatusInventoryCenterConfig> => {
      dispatch(
        addOrUpdateDemandManagementStatusInventoryCenterConfigAction(info),
      );
      return setDemandManagementStatusInventoryCenterConfig(info);
    },
    'errorSettingDemandManagementConfiguration',
  );

/**
 * Thunk for fetching Demand Management Status MCell Configs from the backend
 * and setting in the store
 */
export const getDemandManagementStatusMCellConfigsThunk =
  createAsyncThunkWithError(
    'resourceTracking/getDemandManagementStatusMCellConfigsThunk',
    getDemandManagementStatusMCellConfigs,
    'errorFetchingDemandManagementConfigurations',
  );

/**
 * Thunk for updating a Demand Management Status MCell Config in the backend
 * while optimistically updating the store
 */
export const setDemandManagementStatusMCellConfigThunk =
  createAsyncThunkWithError(
    'resourceTracking/setDemandManagementStatusMCellConfigThunk',
    async (
      info: DemandManagementStatusMCellConfigInfo,
      { dispatch },
    ): Promise<DemandManagementStatusMCellConfig> => {
      dispatch(addOrUpdateDemandManagementStatusMCellConfigAction(info));
      return setDemandManagementStatusMCellConfig(info);
    },
    'errorSettingDemandManagementConfiguration',
  );

/**
 * Thunk for fetching a list of Personal Protective Equipment from the backend and setting
 * in the store
 */
export const getPersonalProtectiveEquipmentsThunk = createAsyncThunkWithError(
  'resourceTracking/getPersonalProtectiveEquipmentsThunk',
  getPersonalProtectiveEquipments,
  'errorFetchingPersonalProtectiveEquipments',
);

/**
 * Thunk for creating Personal Protective Equipment in the backend while optimistically setting
 * in the store
 */
export const createPersonalProtectiveEquipmentThunk = createAsyncThunkWithError(
  'resourceTracking/createPersonalProtectiveEquipmentThunk',
  async (
    info: PersonalProtectiveEquipmentInfo,
    { dispatch },
  ): Promise<PersonalProtectiveEquipment> => {
    dispatch(addOrUpdatePersonalProtectiveEquipmentAction(info));
    return createPersonalProtectiveEquipment(info);
  },
  'errorCreatingPersonalProtectiveEquipment',
);

/**
 * Thunk for updating Personal Protective Equipment in the backend while optimistically setting
 * in the store
 */
export const updatePersonalProtectiveEquipmentThunk = createAsyncThunkWithError(
  'resourceTracking/updatePersonalProtectiveEquipmentThunk',
  async (
    info: PersonalProtectiveEquipmentInfo,
    { dispatch },
  ): Promise<PersonalProtectiveEquipment> => {
    dispatch(addOrUpdatePersonalProtectiveEquipmentAction(info));
    return updatePersonalProtectiveEquipment(info);
  },
  'errorUpdatingPersonalProtectiveEquipment',
);

/**
 * Thunk for deleting Personal Protective Equipment in the backend while optimistically
 * updating the store
 */
export const deletePersonalProtectiveEquipmentThunk = createAsyncThunkWithError(
  'resourceTracking/deletePersonalProtectiveEquipmentThunk',
  async (id: string, { dispatch }): Promise<string> => {
    dispatch(deletePersonalProtectiveEquipmentAction(id));
    return deletePersonalProtectiveEquipment(id);
  },
  'errorDeletingPersonalProtectiveEquipment',
);

/**
 * Thunk for fetching Dangerous Equipment from the backend and setting
 * in the store
 */
export const getDangerousEquipmentsThunk = createAsyncThunkWithError(
  'resourceTracking/getDangerousEquipmentsThunk',
  getDangerousEquipments,
  'errorFetchingDangerousEquipments',
);

/**
 * Thunk for creating Dangerous Equipment in the backend while optimistically setting
 * in the store
 */
export const createDangerousEquipmentThunk = createAsyncThunkWithError(
  'resourceTracking/createDangerousEquipmentThunk',
  async (
    info: DangerousEquipmentInfo,
    { dispatch },
  ): Promise<DangerousEquipment> => {
    dispatch(addOrUpdateDangerousEquipmentAction(info));
    return createDangerousEquipment(info);
  },
  'errorCreatingDangerousEquipment',
);

/**
 * Thunk for updating Dangerous Equipment in the backend while optimistically setting
 * in the store
 */
export const updateDangerousEquipmentThunk = createAsyncThunkWithError(
  'resourceTracking/updateDangerousEquipmentThunk',
  async (
    info: DangerousEquipmentInfo,
    { dispatch },
  ): Promise<DangerousEquipment> => {
    dispatch(addOrUpdateDangerousEquipmentAction(info));
    return updateDangerousEquipment(info);
  },
  'errorUpdatingDangerousEquipment',
);

/**
 * Thunk for deleting Dangerous Equipment in the backend while optimistically
 * updating the store
 */
export const deleteDangerousEquipmentThunk = createAsyncThunkWithError(
  'resourceTracking/deleteDangerousEquipmentThunk',
  async (id: string, { dispatch }): Promise<string> => {
    dispatch(deleteDangerousEquipmentAction(id));
    return deleteDangerousEquipment(id);
  },
  'errorDeletingDangerousEquipment',
);

/**
 * Thunk for fetching Job Stations from the backend and setting
 * them in the store
 */
export const getJobStationsThunk = createAsyncThunkWithError(
  'resourceTracking/getJobStationsThunk',
  getJobStations,
  'errorFetchingJobStations',
);

/**
 * Thunk for Creating a Job Station and setting
 * it in the store
 */
export const createJobStationThunk = createAsyncThunkWithError(
  'resourceTracking/createJobStationThunk',
  createJobStation,
  'errorCreatingJobStation',
);

/**
 * Thunk for updating a Job Station in the backend while optimistically setting
 * in the store
 */
export const updateJobStationThunk = createAsyncThunkWithError(
  'resourceTracking/updateJobStationThunk',
  async (info: JobStationInfo, { dispatch }): Promise<JobStation> => {
    dispatch(addOrUpdateJobStationAction(info));
    return updateJobStation(info);
  },
  'errorUpdatingJobStation',
);

/**
 * Thunk for deleting a Job Station in the backend while optimistically removing it from
 * in the store
 */
export const deleteJobStationThunk = createAsyncThunkWithError(
  'resourceTracking/deleteJobStationThunk',
  async (id: string, { dispatch }): Promise<string> => {
    dispatch(deleteJobStationAction(id));
    return deleteJobStation(id);
  },
  'errorDeletingJobStation',
);

/**
 * Thunk for updating a Job Station's Personal Protective Equipment in the
 * backend while optimistically reflecting the change in the store
 */
export const jobStationAddPersonalProtectiveEquipmentThunk =
  createAsyncThunkWithError(
    'resourceTracking/jobStationAddPersonalProtectiveEquipmentThunk',
    async (
      info: JobStationPersonalProtectiveEquipmentInfo,
      { dispatch },
    ): Promise<JobStation> => {
      dispatch(jobStationAddPersonalProtectiveEquipmentAction(info));
      return jobStationAddPersonalProtectiveEquipment(info);
    },
    'errorAddingJobStationPersonalProtectiveEquipment',
  );

/**
 * Thunk for removing an item of Personal Protective Equipment for a Job Station
 * in the backend and reflecting the change in the store optimistically
 */
export const jobStationRemovePersonalProtectiveEquipmentThunk =
  createAsyncThunkWithError(
    'resourceTracking/jobStationRemovePersonalProtectiveEquipmentThunk',
    async (
      info: JobStationPersonalProtectiveEquipmentInfo,
      { dispatch },
    ): Promise<JobStation> => {
      dispatch(jobStationRemovePersonalProtectiveEquipmentAction(info));
      return jobStationRemovePersonalProtectiveEquipment(info);
    },
    'errorRemovingJobStationPersonalProtectiveEquipment',
  );

/**
 * Thunk for updating Dangerous Equipment for a Job Station in the backend
 * and reflecting the change in the store optimistically
 */
export const jobStationAddDangerousEquipmentThunk = createAsyncThunkWithError(
  'resourceTracking/jobStationAddDangerousEquipmentThunk',
  async (
    info: JobStationDangerousEquipmentInfo,
    { dispatch },
  ): Promise<JobStation> => {
    dispatch(jobStationAddDangerousEquipmentAction(info));
    return jobStationAddDangerousEquipment(info);
  },
  'errorAddingJobStationDangerousEquipment',
);

/**
 * Thunk for removing an item of Dangerous Equipment for a Job Station
 * in the backend and reflecting the change in the store optimistically
 */
export const jobStationRemoveDangerousEquipmentThunk =
  createAsyncThunkWithError(
    'resourceTracking/JobStationRemoveDangerousEquipmentThunk',
    async (
      info: JobStationDangerousEquipmentInfo,
      { dispatch },
    ): Promise<JobStation> => {
      dispatch(jobStationRemoveDangerousEquipmentAction(info));
      return jobStationRemoveDangerousEquipment(info);
    },
    'errorRemovingJobStationDangerousEquipment',
  );

/**
 * Thunk for fetching current Job Station Assignments from the backend and setting
 * them in the store
 */
export const getCurrentJobStationAssignmentsThunk = createAsyncThunkWithError(
  'resourceTracking/getCurrentJobStationAssignmentsThunk',
  getCurrentJobStationAssignments,
  'errorFetchingCurrentJobStationAssignments',
);

/**
 * Thunk for setting Job Station Assignments
 */
export const setJobStationAssignmentsThunk = createAsyncThunkWithError(
  'resourceTracking/setJobStationAssignmentsThunk',
  setJobStationAssignments,
  'errorSettingJobStationAssignments',
);

export const resourceTrackingSlice = createSlice({
  name: 'resourceTracking',
  initialState,
  reducers: {
    setMCellStatusDemandManagementAction: (
      state,
      action: PayloadAction<MCellStatusInfo>,
    ) => {
      const index = state.mCellStatuses.findIndex(
        (s) => s.id === action.payload.id,
      );
      if (index !== -1) {
        const mCellStatus = state.mCellStatuses[index];
        state.mCellStatuses[index] = { ...mCellStatus, ...action.payload };
      }
    },
    addOrUpdateMCellStatusOperationsAction: (
      state,
      action: PayloadAction<MCellStatusInfo>,
    ) => {
      const index = state.mCellStatuses.findIndex(
        (s) => s.id === action.payload.id,
      );
      if (index !== -1) {
        const mCellStatus = state.mCellStatuses[index];
        state.mCellStatuses[index] = { ...mCellStatus, ...action.payload };
      }
    },
    jobStationRemoveDangerousEquipmentAction: (
      state,
      action: PayloadAction<JobStationDangerousEquipmentInfo>,
    ) => {
      const index = state.jobStations.findIndex(
        (s) => s.id === action.payload.jobStationId,
      );
      if (index !== -1) {
        const dangerousEquipments =
          state.jobStations[index].jobStationDangerousEquipments;
        const DEIndex = dangerousEquipments.findIndex(
          (s) => s.dangerousEquipmentId === action.payload.dangerousEquipmentId,
        );
        state.jobStations[index].jobStationDangerousEquipments.slice(
          DEIndex,
          1,
        );
      }
    },
    jobStationAddDangerousEquipmentAction: (
      state,
      action: PayloadAction<JobStationDangerousEquipmentInfo>,
    ) => {
      const index = state.jobStations.findIndex(
        (s) => s.id === action.payload.jobStationId,
      );
      if (index !== -1) {
        const jobStation = state.jobStations[index];
        state.jobStations[index] = { ...jobStation, ...action.payload };
      }
    },
    jobStationRemovePersonalProtectiveEquipmentAction: (
      state,
      action: PayloadAction<JobStationPersonalProtectiveEquipmentInfo>,
    ) => {
      const index = state.jobStations.findIndex(
        (s) => s.id === action.payload.jobStationId,
      );
      if (index !== -1) {
        const personalProtectiveEquipments =
          state.jobStations[index].jobStationPersonalProtectiveEquipments;
        const PPEIndex = personalProtectiveEquipments.findIndex(
          (s) =>
            s.personalProtectiveEquipmentId ===
            action.payload.personalProtectiveEquipmentId,
        );
        state.jobStations[index].jobStationPersonalProtectiveEquipments.slice(
          PPEIndex,
          1,
        );
      }
    },
    jobStationAddPersonalProtectiveEquipmentAction: (
      state,
      action: PayloadAction<JobStationPersonalProtectiveEquipmentInfo>,
    ) => {
      const index = state.jobStations.findIndex(
        (s) => s.id === action.payload.jobStationId,
      );
      if (index !== -1) {
        const jobStation = state.jobStations[index];
        state.jobStations[index] = { ...jobStation, ...action.payload };
      }
    },
    addOrUpdateJobStationAction: (
      state,
      action: PayloadAction<JobStationInfo>,
    ) => {
      const index = state.jobStations.findIndex(
        (s) => s.id === action.payload.id,
      );
      if (index !== -1) {
        const jobStation = state.jobStations[index];
        state.jobStations[index] = { ...jobStation, ...action.payload };
      } else {
        const jobStation: JobStation = {
          ...action.payload,
          jobStationDangerousEquipments: [],
          jobStationPersonalProtectiveEquipments: [],
          mCellEmployeeRoles: [],
          mCellEmployeeRoleIds: [],
        };
        state.jobStations.push(jobStation);
      }
    },
    deleteJobStationAction: (state, action) => {
      const index = state.jobStations.findIndex((e) => e.id === action.payload);
      if (index > -1) {
        state.jobStations.splice(index, 1);
      }
    },
    addOrUpdateEmployeeInterestAction: (state, action) => {
      const index = state.employeeInterests.findIndex(
        (s) => s.id === action.payload.id,
      );
      if (index !== -1) {
        state.employeeInterests[index] = action.payload;
      } else {
        state.employeeInterests.push(action.payload);
      }
    },
    deleteEmployeeInterestAction: (state, action) => {
      const index = state.employeeInterests.findIndex(
        (e) => e.id === action.payload,
      );
      if (index > -1) {
        state.employeeInterests.splice(index, 1);
      }
    },
    addOrUpdateMCellCapacityAction: (state, action) => {
      const index = state.mCellCapacities.findIndex(
        (s) => s.id === action.payload.id,
      );
      if (index !== -1) {
        state.mCellCapacities[index] = action.payload;
      } else {
        state.mCellCapacities.push(action.payload);
      }
    },
    deletePersonalProtectiveEquipmentAction: (state, action) => {
      const index = state.personalProtectiveEquipment.findIndex(
        (s) => s.id === action.payload,
      );
      if (index > -1) {
        state.personalProtectiveEquipment.splice(index, 1);
      }
    },
    addOrUpdatePersonalProtectiveEquipmentAction: (state, action) => {
      const index = state.personalProtectiveEquipment.findIndex(
        (s) => s.id === action.payload.id,
      );
      if (index !== -1) {
        state.personalProtectiveEquipment[index] = action.payload;
      } else {
        state.personalProtectiveEquipment.push(action.payload);
      }
    },
    deleteDangerousEquipmentAction: (state, action) => {
      const index = state.dangerousEquipment.findIndex(
        (s) => s.id === action.payload,
      );
      if (index > -1) {
        state.dangerousEquipment.splice(index, 1);
      }
    },
    addOrUpdateDangerousEquipmentAction: (state, action) => {
      const index = state.dangerousEquipment.findIndex(
        (s) => s.id === action.payload.id,
      );
      if (index !== -1) {
        state.dangerousEquipment[index] = action.payload;
      } else {
        state.dangerousEquipment.push(action.payload);
      }
    },
    clearInterestProfileAction: (state) => {
      state.interestProfile = null;
      state.interestProfileStatus = 'idle';
    },
    addOrUpdateDemandManagementStatusInventoryCenterConfigAction: (
      state,
      action,
    ) => {
      const index =
        state.demandManagementStatusInventoryCenterConfigs.findIndex(
          (c) => c.inventoryCenterId === action.payload.inventoryCenterId,
        );
      if (index !== -1) {
        state.demandManagementStatusInventoryCenterConfigs[index] =
          action.payload;
      } else {
        state.demandManagementStatusInventoryCenterConfigs.push(action.payload);
      }
    },
    addOrUpdateDemandManagementStatusMCellConfigAction: (state, action) => {
      const index = state.demandManagementStatusMCellConfigs.findIndex(
        (c) => c.mCellId === action.payload.mCellId,
      );
      if (index !== -1) {
        state.demandManagementStatusMCellConfigs[index] = action.payload;
      } else {
        state.demandManagementStatusMCellConfigs.push(action.payload);
      }
    },
    setJobStationAssignmentsAction: (state, action) => {
      state.jobStationAssignments = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(jobStationRemoveDangerousEquipmentThunk.pending, (state) => {
        state.jobStationsStatus = 'loading';
      })
      .addCase(jobStationRemoveDangerousEquipmentThunk.fulfilled, (state) => {
        state.jobStationsStatus = 'succeeded';
      })
      .addCase(jobStationRemoveDangerousEquipmentThunk.rejected, (state) => {
        state.jobStationsStatus = 'failed';
      })
      .addCase(jobStationAddDangerousEquipmentThunk.pending, (state) => {
        state.jobStationsStatus = 'loading';
      })
      .addCase(
        jobStationAddDangerousEquipmentThunk.fulfilled,
        (state, { payload }) => {
          state.jobStationsStatus = 'succeeded';
          const index = state.jobStations.findIndex((e) => e.id === payload.id);
          if (index > -1) {
            state.jobStations[index] = payload;
          } else {
            state.jobStations.push(payload);
          }
        },
      )
      .addCase(jobStationAddDangerousEquipmentThunk.rejected, (state) => {
        state.jobStationsStatus = 'failed';
      })
      .addCase(
        jobStationRemovePersonalProtectiveEquipmentThunk.pending,
        (state) => {
          state.jobStationsStatus = 'loading';
        },
      )
      .addCase(
        jobStationRemovePersonalProtectiveEquipmentThunk.fulfilled,
        (state) => {
          state.jobStationsStatus = 'succeeded';
        },
      )
      .addCase(
        jobStationRemovePersonalProtectiveEquipmentThunk.rejected,
        (state) => {
          state.jobStationsStatus = 'failed';
        },
      )
      .addCase(
        jobStationAddPersonalProtectiveEquipmentThunk.pending,
        (state) => {
          state.jobStationsStatus = 'loading';
        },
      )
      .addCase(
        jobStationAddPersonalProtectiveEquipmentThunk.fulfilled,
        (state, { payload }) => {
          state.jobStationsStatus = 'succeeded';
          const index = state.jobStations.findIndex((e) => e.id === payload.id);
          if (index > -1) {
            state.jobStations[index] = payload;
          } else {
            state.jobStations.push(payload);
          }
        },
      )
      .addCase(
        jobStationAddPersonalProtectiveEquipmentThunk.rejected,
        (state) => {
          state.jobStationsStatus = 'failed';
        },
      )
      .addCase(updateJobStationThunk.pending, (state) => {
        state.jobStationsStatus = 'loading';
      })
      .addCase(updateJobStationThunk.fulfilled, (state, { payload }) => {
        state.jobStationsStatus = 'succeeded';
        const index = state.jobStations.findIndex((e) => e.id === payload.id);
        if (index > -1) {
          state.jobStations[index] = payload;
        } else {
          state.jobStations.push(payload);
        }
      })
      .addCase(updateJobStationThunk.rejected, (state) => {
        state.jobStationsStatus = 'failed';
      })
      .addCase(deleteJobStationThunk.pending, (state) => {
        state.jobStationsStatus = 'loading';
      })
      .addCase(deleteJobStationThunk.fulfilled, (state, { payload }) => {
        state.jobStationsStatus = 'succeeded';
        const index = state.jobStations.findIndex((e) => e.id === payload);
        if (index > -1) {
          state.jobStations.splice(index, 1);
        }
      })
      .addCase(deleteJobStationThunk.rejected, (state) => {
        state.jobStationsStatus = 'failed';
      })
      .addCase(createJobStationThunk.pending, (state) => {
        state.jobStationsStatus = 'loading';
      })
      .addCase(createJobStationThunk.fulfilled, (state, { payload }) => {
        state.jobStationsStatus = 'succeeded';
        state.jobStations.push(payload);
      })
      .addCase(createJobStationThunk.rejected, (state) => {
        state.jobStationsStatus = 'failed';
      })
      .addCase(getJobStationsThunk.pending, (state) => {
        state.jobStationsStatus = 'loading';
      })
      .addCase(getJobStationsThunk.fulfilled, (state, { payload }) => {
        state.jobStationsStatus = 'succeeded';
        state.jobStations = payload;
      })
      .addCase(getJobStationsThunk.rejected, (state) => {
        state.jobStationsStatus = 'failed';
      })
      .addCase(getEmployeeInterestsThunk.pending, (state) => {
        state.employeeInterestsStatus = 'loading';
      })
      .addCase(getEmployeeInterestsThunk.fulfilled, (state, { payload }) => {
        state.employeeInterestsStatus = 'succeeded';
        state.employeeInterests = payload;
      })
      .addCase(getEmployeeInterestsThunk.rejected, (state) => {
        state.employeeInterestsStatus = 'failed';
      })
      .addCase(getMCellEmployeesThunk.pending, (state) => {
        state.mCellEmployeesStatus = 'loading';
      })
      .addCase(getMCellEmployeesThunk.fulfilled, (state, { payload }) => {
        state.mCellEmployeesStatus = 'succeeded';
        state.mCellEmployees = payload;
      })
      .addCase(getMCellEmployeesThunk.rejected, (state) => {
        state.mCellEmployeesStatus = 'failed';
      })
      .addCase(getMCellEmployeeByBadgeIdThunk.pending, (state) => {
        state.mCellEmployeesStatus = 'loading';
      })
      .addCase(
        getMCellEmployeeByBadgeIdThunk.fulfilled,
        (state, { payload }) => {
          state.mCellEmployeesStatus = 'succeeded';
          const index = state.mCellEmployees.findIndex(
            (m) => m.id === payload.id,
          );
          if (index > -1) {
            state.mCellEmployees[index] = payload;
          } else {
            state.mCellEmployees.push(payload);
          }
        },
      )
      .addCase(getMCellEmployeeByBadgeIdThunk.rejected, (state) => {
        state.mCellEmployeesStatus = 'failed';
      })
      .addCase(createEmployeeInterestThunk.pending, (state) => {
        state.employeeInterestsStatus = 'loading';
      })
      .addCase(createEmployeeInterestThunk.fulfilled, (state, { payload }) => {
        state.employeeInterestsStatus = 'succeeded';
        state.employeeInterests.push(payload);
      })
      .addCase(createEmployeeInterestThunk.rejected, (state) => {
        state.employeeInterestsStatus = 'failed';
      })
      .addCase(updateEmployeeInterestThunk.pending, (state) => {
        state.employeeInterestsStatus = 'loading';
      })
      .addCase(updateEmployeeInterestThunk.fulfilled, (state, { payload }) => {
        state.employeeInterestsStatus = 'succeeded';
        const index = state.employeeInterests.findIndex(
          (e) => e.id === payload.id,
        );
        if (index > -1) {
          state.employeeInterests[index] = payload;
        } else {
          state.employeeInterests.push(payload);
        }
      })
      .addCase(updateEmployeeInterestThunk.rejected, (state) => {
        state.employeeInterestsStatus = 'failed';
      })
      .addCase(deleteEmployeeInterestThunk.pending, (state) => {
        state.employeeInterestsStatus = 'loading';
      })
      .addCase(deleteEmployeeInterestThunk.fulfilled, (state, { payload }) => {
        state.employeeInterestsStatus = 'succeeded';
        const index = state.employeeInterests.findIndex(
          (e) => e.id === payload,
        );
        if (index > -1) {
          state.employeeInterests.splice(index, 1);
        }
      })
      .addCase(deleteEmployeeInterestThunk.rejected, (state) => {
        state.employeeInterestsStatus = 'failed';
      })
      .addCase(createInterestProfileThunk.pending, (state) => {
        state.interestProfileStatus = 'loading';
      })
      .addCase(createInterestProfileThunk.fulfilled, (state, { payload }) => {
        state.interestProfileStatus = 'succeeded';
        state.interestProfile = payload;
      })
      .addCase(createInterestProfileThunk.rejected, (state) => {
        state.interestProfileStatus = 'failed';
      })
      .addCase(getInterestProfileThunk.pending, (state) => {
        state.interestProfileStatus = 'loading';
      })
      .addCase(getInterestProfileThunk.fulfilled, (state, { payload }) => {
        state.interestProfileStatus = 'succeeded';
        state.interestProfile = payload;
      })
      .addCase(getInterestProfileThunk.rejected, (state) => {
        state.interestProfileStatus = 'failed';
      })
      .addCase(updateInterestProfileThunk.pending, (state) => {
        state.interestProfileStatus = 'loading';
      })
      .addCase(updateInterestProfileThunk.fulfilled, (state, { payload }) => {
        state.interestProfileStatus = 'succeeded';
        state.interestProfile = payload;
      })
      .addCase(updateInterestProfileThunk.rejected, (state) => {
        state.interestProfileStatus = 'failed';
      })
      .addCase(getTopMCellsThunk.pending, (state) => {
        state.topMCellsStatus = 'loading';
      })
      .addCase(getTopMCellsThunk.fulfilled, (state, { payload }) => {
        state.topMCellsStatus = 'succeeded';
        state.topMCells = payload;
      })
      .addCase(getTopMCellsThunk.rejected, (state) => {
        state.topMCellsStatus = 'failed';
      })
      .addCase(getMCellEmployeeMCellHistoryThunk.pending, (state) => {
        state.mCellEmployeeMCellHistoryStatus = 'loading';
      })
      .addCase(
        getMCellEmployeeMCellHistoryThunk.fulfilled,
        (state, { payload }) => {
          state.mCellEmployeeMCellHistoryStatus = 'succeeded';
          state.mCellEmployeeMCellHistory = payload;
        },
      )
      .addCase(getMCellEmployeeMCellHistoryThunk.rejected, (state) => {
        state.mCellEmployeeMCellHistoryStatus = 'failed';
      })
      .addCase(getMCellCapacitiesThunk.pending, (state) => {
        state.mCellCapacitiesStatus = 'loading';
      })
      .addCase(getMCellCapacitiesThunk.fulfilled, (state, { payload }) => {
        state.mCellCapacitiesStatus = 'succeeded';
        state.mCellCapacities = payload;
      })
      .addCase(getMCellCapacitiesThunk.rejected, (state) => {
        state.mCellCapacitiesStatus = 'failed';
      })
      .addCase(updateMCellCapacityThunk.pending, (state) => {
        state.mCellCapacitiesStatus = 'loading';
      })
      .addCase(updateMCellCapacityThunk.fulfilled, (state, { payload }) => {
        state.mCellCapacitiesStatus = 'succeeded';
        const index = state.mCellCapacities.findIndex(
          (e) => e.id === payload.id,
        );
        if (index > -1) {
          state.mCellCapacities[index] = payload;
        } else {
          state.mCellCapacities.push(payload);
        }
      })
      .addCase(updateMCellCapacityThunk.rejected, (state) => {
        state.mCellCapacitiesStatus = 'failed';
      })
      .addCase(getMCellStatusesThunk.pending, (state) => {
        state.mCellStatusesStatus = 'loading';
      })
      .addCase(getMCellStatusesThunk.fulfilled, (state, { payload }) => {
        state.mCellStatusesStatus = 'succeeded';
        state.mCellStatuses = payload;
      })
      .addCase(getMCellStatusesThunk.rejected, (state) => {
        state.mCellStatusesStatus = 'failed';
      })
      .addCase(setMCellStatusOperationsThunk.pending, (state) => {
        state.mCellStatusesStatus = 'loading';
      })
      .addCase(setMCellStatusOperationsThunk.fulfilled, (state) => {
        state.mCellStatusesStatus = 'succeeded';
      })
      .addCase(setMCellStatusOperationsThunk.rejected, (state) => {
        state.mCellStatusesStatus = 'failed';
      })
      .addCase(setMCellStatusDemandManagementThunk.pending, (state) => {
        state.mCellStatusesStatus = 'loading';
      })
      .addCase(setMCellStatusDemandManagementThunk.fulfilled, (state) => {
        state.mCellStatusesStatus = 'succeeded';
      })
      .addCase(setMCellStatusDemandManagementThunk.rejected, (state) => {
        state.mCellStatusesStatus = 'failed';
      })
      .addCase(setMCellStatusOperationsBulkThunk.pending, (state) => {
        state.mCellStatusesStatus = 'loading';
      })
      .addCase(setMCellStatusOperationsBulkThunk.fulfilled, (state) => {
        state.mCellStatusesStatus = 'succeeded';
      })
      .addCase(setMCellStatusOperationsBulkThunk.rejected, (state) => {
        state.mCellStatusesStatus = 'failed';
      })
      .addCase(setMCellStatusDemandManagementBulkThunk.pending, (state) => {
        state.mCellStatusesStatus = 'loading';
      })
      .addCase(setMCellStatusDemandManagementBulkThunk.fulfilled, (state) => {
        state.mCellStatusesStatus = 'succeeded';
      })
      .addCase(setMCellStatusDemandManagementBulkThunk.rejected, (state) => {
        state.mCellStatusesStatus = 'failed';
      })
      .addCase(getCurrentMCellEmployeeThunk.pending, (state) => {
        state.currentEmployeeStatus = 'loading';
        state.currentEmployee = undefined;
      })
      .addCase(getCurrentMCellEmployeeThunk.fulfilled, (state, { payload }) => {
        state.currentEmployeeStatus = 'succeeded';
        state.currentEmployee = payload;
      })
      .addCase(getCurrentMCellEmployeeThunk.rejected, (state) => {
        state.currentEmployeeStatus = 'failed';
      })
      .addCase(getMCellEmployeeCurrentMCellThunk.pending, (state) => {
        state.employeeCurrentCellStatus = 'loading';
        state.employeeCurrentCell = undefined;
      })
      .addCase(
        getMCellEmployeeCurrentMCellThunk.fulfilled,
        (state, { payload }) => {
          state.employeeCurrentCellStatus = 'succeeded';
          state.employeeCurrentCell = payload;
        },
      )
      .addCase(getMCellEmployeeCurrentMCellThunk.rejected, (state) => {
        state.employeeCurrentCellStatus = 'failed';
      })
      .addCase(getMCellEmployeeAccountTypesThunk.pending, (state) => {
        state.mCellEmployeeAccountTypesStatus = 'loading';
      })
      .addCase(
        getMCellEmployeeAccountTypesThunk.fulfilled,
        (state, { payload }) => {
          state.mCellEmployeeAccountTypesStatus = 'succeeded';
          state.mCellEmployeeAccountTypes = payload;
        },
      )
      .addCase(getMCellEmployeeAccountTypesThunk.rejected, (state) => {
        state.mCellEmployeeAccountTypesStatus = 'failed';
      })
      .addCase(getMCellEmployeeRolesThunk.pending, (state) => {
        state.mCellEmployeeRolesStatus = 'loading';
      })
      .addCase(getMCellEmployeeRolesThunk.fulfilled, (state, { payload }) => {
        state.mCellEmployeeRolesStatus = 'succeeded';
        state.mCellEmployeeRoles = payload;
      })
      .addCase(getMCellEmployeeRolesThunk.rejected, (state) => {
        state.mCellEmployeeRolesStatus = 'failed';
      })
      .addCase(getMCellEmployeeCategoriesThunk.pending, (state) => {
        state.mCellEmployeeCategoriesStatus = 'loading';
      })
      .addCase(
        getMCellEmployeeCategoriesThunk.fulfilled,
        (state, { payload }) => {
          state.mCellEmployeeCategoriesStatus = 'succeeded';
          state.mCellEmployeeCategories = payload;
        },
      )
      .addCase(getMCellEmployeeCategoriesThunk.rejected, (state) => {
        state.mCellEmployeeCategoriesStatus = 'failed';
      })
      .addCase(
        getDemandManagementStatusInventoryCenterConfigsThunk.pending,
        (state) => {
          state.demandManagementStatusInventoryCenterConfigsStatus = 'loading';
        },
      )
      .addCase(
        getDemandManagementStatusInventoryCenterConfigsThunk.fulfilled,
        (state, { payload }) => {
          state.demandManagementStatusInventoryCenterConfigsStatus =
            'succeeded';
          state.demandManagementStatusInventoryCenterConfigs = payload;
        },
      )
      .addCase(
        getDemandManagementStatusInventoryCenterConfigsThunk.rejected,
        (state) => {
          state.demandManagementStatusInventoryCenterConfigsStatus = 'failed';
        },
      )
      .addCase(
        setDemandManagementStatusInventoryCenterConfigThunk.pending,
        (state) => {
          state.demandManagementStatusInventoryCenterConfigsStatus = 'loading';
        },
      )
      .addCase(
        setDemandManagementStatusInventoryCenterConfigThunk.fulfilled,
        (state, { payload }) => {
          state.demandManagementStatusInventoryCenterConfigsStatus =
            'succeeded';
          const index =
            state.demandManagementStatusInventoryCenterConfigs.findIndex(
              (c) => c.inventoryCenterId === payload.inventoryCenterId,
            );
          if (index > -1) {
            state.demandManagementStatusInventoryCenterConfigs[index] = payload;
          } else {
            state.demandManagementStatusInventoryCenterConfigs.push(payload);
          }
        },
      )
      .addCase(
        setDemandManagementStatusInventoryCenterConfigThunk.rejected,
        (state) => {
          state.demandManagementStatusInventoryCenterConfigsStatus = 'failed';
        },
      )
      .addCase(getDemandManagementStatusMCellConfigsThunk.pending, (state) => {
        state.demandManagementStatusMCellConfigsStatus = 'loading';
      })
      .addCase(
        getDemandManagementStatusMCellConfigsThunk.fulfilled,
        (state, { payload }) => {
          state.demandManagementStatusMCellConfigsStatus = 'succeeded';
          state.demandManagementStatusMCellConfigs = payload;
        },
      )
      .addCase(getDemandManagementStatusMCellConfigsThunk.rejected, (state) => {
        state.demandManagementStatusMCellConfigsStatus = 'failed';
      })
      .addCase(setDemandManagementStatusMCellConfigThunk.pending, (state) => {
        state.demandManagementStatusMCellConfigsStatus = 'loading';
      })
      .addCase(
        setDemandManagementStatusMCellConfigThunk.fulfilled,
        (state, { payload }) => {
          state.demandManagementStatusMCellConfigsStatus = 'succeeded';
          const index = state.demandManagementStatusMCellConfigs.findIndex(
            (c) => c.mCellId === payload.mCellId,
          );
          if (index > -1) {
            state.demandManagementStatusMCellConfigs[index] = payload;
          } else {
            state.demandManagementStatusMCellConfigs.push(payload);
          }
        },
      )
      .addCase(setDemandManagementStatusMCellConfigThunk.rejected, (state) => {
        state.demandManagementStatusMCellConfigsStatus = 'failed';
      })
      .addCase(getPersonalProtectiveEquipmentsThunk.pending, (state) => {
        state.personalProtectiveEquipmentStatus = 'loading';
      })
      .addCase(
        getPersonalProtectiveEquipmentsThunk.fulfilled,
        (state, { payload }) => {
          state.personalProtectiveEquipmentStatus = 'succeeded';
          state.personalProtectiveEquipment = payload;
        },
      )
      .addCase(getPersonalProtectiveEquipmentsThunk.rejected, (state) => {
        state.personalProtectiveEquipmentStatus = 'failed';
      })
      .addCase(getDangerousEquipmentsThunk.pending, (state) => {
        state.dangerousEquipmentStatus = 'loading';
      })
      .addCase(getDangerousEquipmentsThunk.fulfilled, (state, { payload }) => {
        state.dangerousEquipment = payload;
        state.dangerousEquipmentStatus = 'succeeded';
      })
      .addCase(getDangerousEquipmentsThunk.rejected, (state) => {
        state.dangerousEquipmentStatus = 'failed';
      })
      .addCase(updatePersonalProtectiveEquipmentThunk.pending, (state) => {
        state.personalProtectiveEquipmentStatus = 'loading';
      })
      .addCase(
        updatePersonalProtectiveEquipmentThunk.fulfilled,
        (state, { payload }) => {
          const index = state.personalProtectiveEquipment.findIndex(
            (s) => s.id === payload.id,
          );
          if (index !== -1) {
            state.personalProtectiveEquipment[index] = payload;
          } else {
            state.personalProtectiveEquipment.push(payload);
          }
          state.personalProtectiveEquipmentStatus = 'succeeded';
        },
      )
      .addCase(updatePersonalProtectiveEquipmentThunk.rejected, (state) => {
        state.personalProtectiveEquipmentStatus = 'failed';
      })
      .addCase(updateDangerousEquipmentThunk.pending, (state) => {
        state.dangerousEquipmentStatus = 'loading';
      })
      .addCase(
        updateDangerousEquipmentThunk.fulfilled,
        (state, { payload }) => {
          const index = state.dangerousEquipment.findIndex(
            (s) => s.id === payload.id,
          );
          if (index !== -1) {
            state.dangerousEquipment[index] = payload;
          } else {
            state.dangerousEquipment.push(payload);
          }
          state.dangerousEquipmentStatus = 'succeeded';
        },
      )
      .addCase(updateDangerousEquipmentThunk.rejected, (state) => {
        state.dangerousEquipmentStatus = 'failed';
      })
      .addCase(createDangerousEquipmentThunk.pending, (state) => {
        state.dangerousEquipmentStatus = 'loading';
      })
      .addCase(createDangerousEquipmentThunk.fulfilled, (state) => {
        state.dangerousEquipmentStatus = 'succeeded';
      })
      .addCase(createDangerousEquipmentThunk.rejected, (state) => {
        state.dangerousEquipmentStatus = 'failed';
      })
      .addCase(createPersonalProtectiveEquipmentThunk.pending, (state) => {
        state.personalProtectiveEquipmentStatus = 'loading';
      })
      .addCase(createPersonalProtectiveEquipmentThunk.fulfilled, (state) => {
        state.personalProtectiveEquipmentStatus = 'succeeded';
      })
      .addCase(createPersonalProtectiveEquipmentThunk.rejected, (state) => {
        state.personalProtectiveEquipmentStatus = 'failed';
      })
      .addCase(deletePersonalProtectiveEquipmentThunk.pending, (state) => {
        state.personalProtectiveEquipmentStatus = 'loading';
      })
      .addCase(
        deletePersonalProtectiveEquipmentThunk.fulfilled,
        (state, { payload }) => {
          const index = state.personalProtectiveEquipment.findIndex(
            (s) => s.id === payload,
          );
          if (index > -1) {
            state.personalProtectiveEquipment.splice(index, 1);
          }
          state.personalProtectiveEquipmentStatus = 'succeeded';
        },
      )
      .addCase(deletePersonalProtectiveEquipmentThunk.rejected, (state) => {
        state.personalProtectiveEquipmentStatus = 'failed';
      })
      .addCase(deleteDangerousEquipmentThunk.pending, (state) => {
        state.dangerousEquipmentStatus = 'loading';
      })
      .addCase(
        deleteDangerousEquipmentThunk.fulfilled,
        (state, { payload }) => {
          const index = state.dangerousEquipment.findIndex(
            (s) => s.id === payload,
          );
          if (index > -1) {
            state.dangerousEquipment.splice(index, 1);
          }
          state.dangerousEquipmentStatus = 'succeeded';
        },
      )
      .addCase(deleteDangerousEquipmentThunk.rejected, (state) => {
        state.dangerousEquipmentStatus = 'failed';
      })
      .addCase(getCurrentJobStationAssignmentsThunk.pending, (state) => {
        state.jobStationAssignmentsStatus = 'loading';
        state.jobStationAssignments = [];
      })
      .addCase(
        getCurrentJobStationAssignmentsThunk.fulfilled,
        (state, { payload }) => {
          state.jobStationAssignmentsStatus = 'succeeded';
          state.jobStationAssignments = payload;
        },
      )
      .addCase(getCurrentJobStationAssignmentsThunk.rejected, (state) => {
        state.jobStationAssignmentsStatus = 'failed';
      })
      .addCase(setJobStationAssignmentsThunk.pending, (state) => {
        state.jobStationAssignmentsStatus = 'loading';
      })
      .addCase(
        setJobStationAssignmentsThunk.fulfilled,
        (state, { payload }) => {
          state.jobStationAssignmentsStatus = 'succeeded';
          state.jobStationAssignments = payload;
        },
      )
      .addCase(setJobStationAssignmentsThunk.rejected, (state) => {
        state.jobStationAssignmentsStatus = 'failed';
      });
  },
});

/**
 * Selector to retrieve Job Stations from the store
 * @param state
 */
export const selectJobStations = (state: RootState) =>
  state.resourceTracking.jobStations;

/**
 * Creates a memoized selector for retrieving job stations optionally filtered by MCell ID.
 * This selector ensures that the list is only recomputed when either the list of job stations
 * or the MCell ID changes.
 *
 * @param {RootState} state - The entire Redux state.
 * @param {string|undefined} mCellId - Optional MCell ID to filter the job stations.
 * @returns {JobStation[]} An array of job stations filtered by the given MCell ID if provided.
 */
export const selectFilteredJobStations = createSelector(
  [selectJobStations, (_: RootState, mCellId: string | undefined) => mCellId],
  (jobStations, mCellId) =>
    jobStations.filter(
      (jobStation) => mCellId === undefined || jobStation.mCellId === mCellId,
    ),
);

/**
 * Selector to determine whether Job Stations are loading
 * @param state
 */
export const selectLoadingJobStations = (state: RootState) =>
  state.resourceTracking.jobStationsStatus === 'loading';

/**
 * Selector to retrieve Employee Interests from the store
 * @param state
 */
export const selectEmployeeInterests = (state: RootState) =>
  state.resourceTracking.employeeInterests;

/**
 * Selector to determine whether Employee Interests are loading
 * @param state
 */
export const selectLoadingEmployeeInterests = (state: RootState) =>
  state.resourceTracking.employeeInterestsStatus === 'loading';

/**
 * Selector to retrieve MCell Employees from the store
 * @param state
 */
export const selectMCellEmployees = (state: RootState) =>
  state.resourceTracking.mCellEmployees;

/**
 * Selector to determine whether MCell Employees are loading
 * @param state
 */
export const selectLoadingMCellEmployees = (state: RootState) =>
  state.resourceTracking.mCellEmployeesStatus === 'loading';

/**
 * Selector to retrieve the Interest Profile from the store
 * @param state
 */
export const selectInterestProfile = (state: RootState) =>
  state.resourceTracking.interestProfile;

/**
 * Selector to determine whether the Interest Profile is loading
 * @param state
 */
export const selectLoadingInterestProfile = (state: RootState) =>
  state.resourceTracking.interestProfileStatus === 'loading';

/**
 * Selector to retrieve an employee's top MCells from the store
 * @param state
 */
export const selectTopMCells = (state: RootState) =>
  state.resourceTracking.topMCells;

/**
 * Selector to determine whether an employee's top MCells are loading
 * @param state
 */
export const selectLoadingTopMCells = (state: RootState) =>
  state.resourceTracking.topMCellsStatus === 'loading';

/**
 * Selector to retrieve an employee's MCell history from the store
 * @param state
 */
export const selectMCellEmployeeMCellHistory = (state: RootState) =>
  state.resourceTracking.mCellEmployeeMCellHistory;

/**
 * Selector to determine whether an employee's MCell history is loading
 * @param state
 */
export const selectLoadingMCellEmployeeMCellHistory = (state: RootState) =>
  state.resourceTracking.topMCellsStatus === 'loading';

/**
 * Selector to retrieve MCell Capacities from the store
 * @param state
 */
export const selectMCellCapacities = (state: RootState) =>
  state.resourceTracking.mCellCapacities;

/**
 * Selector to determine whether MCell Capacities are loading
 * @param state
 */
export const selectLoadingMCellCapacities = (state: RootState) =>
  state.resourceTracking.mCellCapacitiesStatus === 'loading';

/**
 * Selector to retrieve MCell Statuses from the store
 * @param state
 */
export const selectMCellStatuses = (state: RootState) =>
  state.resourceTracking.mCellStatuses;

/**
 * Selector to determine whether MCell Statuses are loading
 * @param state
 */
export const selectLoadingMCellStatuses = (state: RootState) =>
  state.resourceTracking.mCellStatusesStatus === 'loading';

/**
 * Selector to retrieve the current Employee from the store
 * @param state
 */
export const selectCurrentEmployee = (state: RootState) =>
  state.resourceTracking.currentEmployee;

/**
 * Selector to determine whether the current Employee is loading
 * @param state
 */
export const selectLoadingCurrentEmployee = (state: RootState) =>
  state.resourceTracking.currentEmployeeStatus === 'loading';

/**
 * Selector to retrieve the employee's current MCell from the store
 * @param state
 */
export const selectEmployeeCurrentCell = (state: RootState) =>
  state.resourceTracking.employeeCurrentCell;

/**
 * Selector to determine whether the employee's current MCell is loading
 * @param state
 */
export const selectLoadingEmployeeCurrentCell = (state: RootState) =>
  state.resourceTracking.employeeCurrentCellStatus === 'loading';

/**
 * Selector to retrieve Employee Account Types from the store
 * @param state
 */
export const selectEmployeeAccountTypes = (state: RootState) =>
  state.resourceTracking.mCellEmployeeAccountTypes;

/**
 * Selector to determine whether Employee Account Types are loading
 * @param state
 */
export const selectLoadingEmployeeAccountTypes = (state: RootState) =>
  state.resourceTracking.mCellEmployeeAccountTypesStatus === 'loading';

/**
 * Selector to retrieve Employee Roles from the store
 * @param state
 */
export const selectEmployeeRoles = (state: RootState) =>
  state.resourceTracking.mCellEmployeeRoles;

/**
 * Selector to retrieve Employee Categories from the store
 * @param state
 */
export const selectEmployeeCategories = (state: RootState) =>
  state.resourceTracking.mCellEmployeeCategories;

/**
 * Selector to determine whether Employee Categories are loading
 * @param state
 */
export const selectLoadingEmployeeCategories = (state: RootState) =>
  state.resourceTracking.mCellEmployeeCategoriesStatus === 'loading';

/**
 * Selector to retrieve Demand Management Status Inventory Center Configs from the store
 * @param state
 */
export const selectDemandManagementStatusInventoryCenterConfigs = (
  state: RootState,
) => state.resourceTracking.demandManagementStatusInventoryCenterConfigs;

/**
 * Selector to retrieve both a specific demand management status configuration and perform a check to see
 * if any configuration exists for an inventory center.
 * This selector is memoized, ensuring that it only recomputes when the list of configurations or the specified
 * inventory center ID changes, thereby improving performance by minimizing recalculations.
 *
 * @param {RootState} _ - The entire Redux state.
 * @param {string} inventoryCenterId - The ID of the inventory center to query.
 * @returns {{ config: DemandManagementStatusInventoryCenterConfig | undefined, hasConfig: boolean }}
 *          The configuration for the specified inventory center, or undefined if not found, and a boolean indicating
 *          if any configuration exists for the inventory center.
 */
export const selectInventoryCenterConfigDetailsById = createSelector(
  [
    selectDemandManagementStatusInventoryCenterConfigs,
    (_: RootState, inventoryCenterId: string) => inventoryCenterId,
  ],
  (configs, inventoryCenterId) => {
    const config = configs.find(
      (c) => c.inventoryCenterId === inventoryCenterId,
    );
    const hasConfig = configs.some(
      (c) => c.inventoryCenterId === inventoryCenterId,
    );
    return { config, hasConfig };
  },
);

/**
 * Selector to determine whether Demand Management Status Inventory Center Configs are loading
 * @param state
 */
export const selectLoadingDemandManagementStatusInventoryCenterConfigs = (
  state: RootState,
) =>
  state.resourceTracking.demandManagementStatusInventoryCenterConfigsStatus ===
  'loading';

/**
 * Selector to retrieve Demand Management Status MCell Configs from the store
 * @param state
 */
export const selectDemandManagementStatusMCellConfigs = (state: RootState) =>
  state.resourceTracking.demandManagementStatusMCellConfigs;

/**
 * Retrieves an array of MCell configurations that are associated with a given inventory center ID.
 * This selector is memoized, ensuring that it only recomputes when the list of MCell configurations
 * or the specified inventory center ID changes. This approach improves performance by minimizing
 * recalculations and ensures that the component using this selector only re-renders when necessary.
 *
 * @param {RootState} _ - The Redux state object.
 * @param {string} inventoryCenterId - The ID of the inventory center to filter the MCell configurations by.
 *                                     This ID is used to find all MCell configurations related to a specific
 *                                     inventory center.
 *
 * @returns {DemandManagementStatusMCellConfig[]} An array of MCell configurations where each configuration's
 *         `mCell.inventoryCenterId` matches the provided inventoryCenterId. This could be an empty array if
 *         no configurations match the given ID.
 */
export const selectMCellConfigById = createSelector(
  [
    selectDemandManagementStatusMCellConfigs,
    (_: RootState, inventoryCenterId: string) => inventoryCenterId,
  ],
  (configs, inventoryCenterId) =>
    configs.filter(
      (config) => config.mCell.inventoryCenterId === inventoryCenterId,
    ),
);

/**
 * Selector to determine whether Demand Management Status MCell Configs are loading
 * @param state
 */
export const selectLoadingDemandManagementStatusMCellConfigs = (
  state: RootState,
) =>
  state.resourceTracking.demandManagementStatusMCellConfigsStatus === 'loading';

/**
 * Selector to determine whether Personal Protective Equipment are loading
 * @param state
 */
export const selectLoadingPersonalProtectiveEquipments = (state: RootState) =>
  state.resourceTracking.personalProtectiveEquipmentStatus === 'loading';

/**
 * Selector to select Personal Protective Equipment
 * @param state
 */
export const selectPersonalProtectiveEquipments = (state: RootState) =>
  state.resourceTracking.personalProtectiveEquipment;

/**
 * Selector to determine whether Dangerous Equipment is loading
 * @param state
 */
export const selectLoadingDangerousEquipments = (state: RootState) =>
  state.resourceTracking.dangerousEquipmentStatus === 'loading';

/**
 * Selector to select Dangerous Equipment
 * @param state
 */
export const selectDangerousEquipments = (state: RootState) =>
  state.resourceTracking.dangerousEquipment;

/**
 * Selector to select Job Station Assignments
 * @param state
 */
export const selectJobStationAssignments = (state: RootState) =>
  state.resourceTracking.jobStationAssignments;

/**
 * Selector to determine whetherJob Station Assignments is loading
 * @param state
 */
export const selectLoadingJobStationAssignments = (state: RootState) =>
  state.resourceTracking.jobStationAssignmentsStatus === 'loading';

export default resourceTrackingSlice.reducer;
