import React from 'react';
import { Box, Typography } from '@mui/material';
import LinkTiles from './LinkTiles';
import { useTranslation } from 'react-i18next';
import { HomeLinkDto, HomeLinkGroupDto } from '../types';
import { isEmptyOrSpaces } from '../../../utils/string-utils';

type Props = {
  homeLinks: HomeLinkDto[];
  groups: HomeLinkGroupDto[];
};

/**
 * Renders Home Page contents
 * @param homeLinks
 * @param groups
 * @constructor
 */
export const HomePage = ({ homeLinks, groups }: Props) => {
  const groupedLinks: Record<string, HomeLinkDto[]> = {};
  const ungroupedLinks: HomeLinkDto[] = [];
  groups.forEach((group) => {
    groupedLinks[group.id] = [];
  });
  homeLinks.forEach((link) => {
    if (isEmptyOrSpaces(link.homeLinkGroupId)) {
      ungroupedLinks.push(link);
    } else {
      groupedLinks[link.homeLinkGroupId as string]?.push(link);
    }
  });

  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant={'subtitle2'}>{t('links')}</Typography>
      <Box sx={{ mt: 1 }}>
        <LinkTiles homeLinks={ungroupedLinks} />
      </Box>
      {Object.keys(groupedLinks).map((g) => (
        <Box key={g} sx={{ mt: 2 }}>
          <Typography variant={'subtitle2'}>
            {groups.find((grp) => grp.id === g)?.name}
          </Typography>
          <Box sx={{ mt: 1 }}>
            <LinkTiles homeLinks={groupedLinks[g]} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default HomePage;
