import React from 'react';
import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import { HelpCircleIcon } from './icons';
import { IconButton, Tooltip } from '@mui/material';
import { TABLE_HELP_URL } from './utils';

/**
 * Adds a help icon to GridTableDefault's toolbar and opens a help
 * document when selected
 * @constructor
 */
export const GridTableToolbarHelpPlugin = () => {
  const pluginDependencies = [{ name: 'Toolbar' }];

  return (
    <Plugin name='ToolbarHelpPlugin' dependencies={pluginDependencies}>
      <Template name='toolbarContent'>
        <TemplatePlaceholder />
        <Tooltip title={'Help'}>
          <IconButton size='large'>
            <a href={TABLE_HELP_URL} target={'_blank'} rel={'noreferrer'}>
              <HelpCircleIcon
                sx={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  mb: -0.2,
                }}
              />
            </a>
          </IconButton>
        </Tooltip>
      </Template>
    </Plugin>
  );
};
