import {
  STProducingLocationConfig,
  STProducingLocationConfigInfo,
  STMCellGuardrail,
  STMCellGuardrailInfo,
  STMCellMap,
  STMCellMapInfo,
  STSalesOrderFilter,
  STSetting,
  STSettingInfo,
  STChangeLogDto,
  STChangeLogInfo,
  STCapacityUtilizationFilter,
  ProducingLocationOptionDto,
  InventoryCenterShiftFilter,
  STCapacityUtilizationEmployeesCountDto,
  STCapacityUtilizationPtoHoursTotalDto,
  STCapacityUtilizationShiftHoursTotalDto,
  STSalesOrderDto,
  STSalesOrderLineItemDto,
  STUnscheduledSalesOrderDto,
  STUnscheduledSalesOrderLineItemDto,
  STHardHoldDto,
  STCapacityUtilizationsAggregateDto,
  StLaborWhatIfInputInfo,
  StLaborWhatIfInputDto,
  StLaborWhatIfInputAggregateDto,
  StLaborWhatIfOutputAggregateDto,
  SalesOrderMain,
  SalesOrderExtra,
  SalesOrderLineItemPreview,
  SalesOrderLineItemDetail,
  ReasonDto,
  ReasonCreate,
  ReasonUpdate,
  ReasonFilter,
  STChangeLogCommitInfo,
} from './types';
import axiosInstance from '../../api';

/**
 * API call to create a Scheduling Tool Producing Location Config
 * @param info
 */
export const createProducingLocationConfig = async (
  info: STProducingLocationConfigInfo,
): Promise<STProducingLocationConfig> => {
  const response = await axiosInstance.post<STProducingLocationConfig>(
    '/api/SchedulingTool/producingLocationConfig',
    info,
  );
  return response.data;
};

/**
 * API call to get all Scheduling Tool Producing Location Configs that match the given Inventory Center
 * @param inventoryCenterId
 */
export const getProducingLocationConfigs = async (
  inventoryCenterId: string,
): Promise<STProducingLocationConfig[]> => {
  const response = await axiosInstance.get(
    '/api/SchedulingTool/producingLocationConfig',
    {
      params: { inventoryCenterId },
    },
  );
  return response.data;
};

/**
 * Api call to get all Scheduling Tool Producing Location Options that match the given Inventory Center
 */
export const getProducingLocationOptions = async (
  inventoryCenterId: string,
): Promise<ProducingLocationOptionDto[]> => {
  const response = await axiosInstance.get(
    `/api/SchedulingTool/producingLocationOption`,
    {
      params: { inventoryCenterId },
    },
  );
  return response.data;
};

/**
 * API call to get a Scheduling Tool Producing Location Config by id
 * @param id
 */
export const getProducingLocationConfigById = async (
  id: string,
): Promise<STProducingLocationConfig> => {
  const response = await axiosInstance.get<STProducingLocationConfig>(
    `/api/SchedulingTool/producingLocationConfig/${id}`,
  );
  return response.data;
};

/**
 * API call to update a Scheduling Tool Producing Location Config
 * @param id
 * @param info
 */
export const updateProducingLocationConfig = async ({
  id,
  ...info
}: STProducingLocationConfigInfo & {
  id: string;
}): Promise<STProducingLocationConfig> => {
  const response = await axiosInstance.post(
    `/api/SchedulingTool/producingLocationConfig/${id}`,
    info,
  );
  return response.data;
};

/**
 * API call to delete a Scheduling Tool Producing Location Config
 * @param id
 */
export const deleteProducingLocationConfig = async (id: string) => {
  await axiosInstance.delete(
    `/api/SchedulingTool/producingLocationConfig/${id}`,
  );
};

/**
 * API call to create a Scheduling Tool MCell Map
 * @param info
 */
export const createMCellMap = async (
  info: STMCellMapInfo,
): Promise<STMCellMap> => {
  const response = await axiosInstance.post<STMCellMap>(
    '/api/SchedulingTool/mCellMap',
    info,
  );
  return response.data;
};

/**
 * API call to get all Scheduling Tool MCell Maps that match an optional Inventory Center
 * @param inventoryCenterId
 */
export const getMCellMaps = async (
  inventoryCenterId: string,
): Promise<STMCellMap[]> => {
  const response = await axiosInstance.get('/api/SchedulingTool/mCellMap', {
    params: { inventoryCenterId },
  });
  return response.data;
};

/**
 * API call to get a Scheduling Tool MCell Map by id
 * @param id
 */
export const getMCellMapById = async (id: string): Promise<STMCellMap> => {
  const response = await axiosInstance.get(
    `/api/SchedulingTool/mCellMap/${id}`,
  );
  return response.data;
};

/**
 * API call to update a Scheduling Tool MCell Map
 * @param id
 * @param info
 */
export const updateMCellMap = async ({
  id,
  ...info
}: STMCellMapInfo & {
  id: string;
}): Promise<STMCellMap> => {
  const response = await axiosInstance.post(
    `/api/SchedulingTool/mCellMap/${id}`,
    info,
  );
  return response.data;
};

/**
 * API call to delete a Scheduling Tool MCell Map
 * @param id
 */
export const deleteMCellMap = async (id: string) => {
  await axiosInstance.delete(`/api/SchedulingTool/mCellMap/${id}`);
};

/**
 * API call to create a Scheduling Tool MCell Guardrail
 * @param info
 */
export const createMCellGuardrail = async (
  info: STMCellGuardrailInfo,
): Promise<STMCellGuardrail> => {
  const response = await axiosInstance.post(
    '/api/SchedulingTool/mCellGuardrail',
    info,
  );
  return response.data;
};

/**
 * API call to get all Scheduling Tool MCell Guardrails that match an optional Inventory Center
 * @param inventoryCenterId
 */
export const getMCellGuardrails = async (
  inventoryCenterId: string,
): Promise<STMCellGuardrail[]> => {
  const response = await axiosInstance.get(
    '/api/SchedulingTool/mCellGuardrail',
    { params: { inventoryCenterId } },
  );
  return response.data;
};

/**
 * API call to get a Scheduling Tool MCell Guardrail by id
 * @param id
 */
export const getMCellGuardrailById = async (
  id: string,
): Promise<STMCellGuardrail> => {
  const response = await axiosInstance.get(
    `/api/SchedulingTool/mCellGuardrail/${id}`,
  );
  return response.data;
};

/**
 * API call to update a Scheduling Tool MCell Guardrail
 * @param id
 * @param info
 */
export const updateMCellGuardrail = async ({
  id,
  ...info
}: STMCellGuardrailInfo & {
  id: string;
}): Promise<STMCellGuardrail> => {
  const response = await axiosInstance.post(
    `/api/SchedulingTool/mCellGuardrail/${id}`,
    info,
  );
  return response.data;
};

/**
 * API call to delete Scheduling Tool MCell Guardrail
 * @param id
 */
export const deleteMCellGuardrail = async (id: string) => {
  await axiosInstance.delete(`/api/SchedulingTool/mCellGuardrail/${id}`);
};

/**
 * API call used to get Scheduling Tool Sales Orders that match the optionally given filter arguments
 * @param salesOrderFilter
 */
export const getSalesOrders = async (
  salesOrderFilter: STSalesOrderFilter,
): Promise<Array<STSalesOrderDto>> => {
  const response = await axiosInstance.get(
    '/api/SchedulingTool/scheduledSalesOrder',
    {
      params: salesOrderFilter,
    },
  );
  return response.data;
};

/**
 * API call used to get Scheduling Tool Sales Order Line Items that match optionally given filter arguments
 * @param salesOrderFilter
 */
export const getSalesOrderLineItems = async (
  salesOrderFilter: STSalesOrderFilter,
): Promise<Array<STSalesOrderLineItemDto>> => {
  const response = await axiosInstance.get(
    '/api/SchedulingTool/scheduledSalesOrderLineItem',
    {
      params: salesOrderFilter,
    },
  );
  return response.data;
};

/**
 * API call used to get Scheduling Tool Sales Orders that have not yet been scheduled
 * that match optionally given filter arguments
 * @param salesOrderFilter
 */
export const getUnscheduledSalesOrders = async (
  salesOrderFilter: STSalesOrderFilter,
): Promise<Array<STUnscheduledSalesOrderDto>> => {
  const response = await axiosInstance.get(
    '/api/SchedulingTool/unscheduledSalesOrder',
    {
      params: salesOrderFilter,
    },
  );
  return response.data;
};

/**
 * API call used to get Scheduling Tool Sales Order Line Items that have not yet been scheduled
 * that match optionally given filter arguments
 * @param salesOrderFilter
 */
export const getUnscheduledSalesOrderLineItems = async (
  salesOrderFilter: STSalesOrderFilter,
): Promise<Array<STUnscheduledSalesOrderLineItemDto>> => {
  const response = await axiosInstance.get(
    '/api/SchedulingTool/unscheduledSalesOrderLineItem',
    {
      params: salesOrderFilter,
    },
  );
  return response.data;
};

/**
 * API call to get Scheduling Tool Hard Holds that match optionally given filter arguments
 * @param salesOrderFilter
 */
export const getHardHolds = async (
  salesOrderFilter: STSalesOrderFilter,
): Promise<Array<STHardHoldDto>> => {
  const response = await axiosInstance.get('/api/SchedulingTool/hardHold', {
    params: salesOrderFilter,
  });
  return response.data;
};

/**
 * API call used to get Scheduling Tool Settings that match the given inventory center
 * @param inventoryCenterId
 */
export const getSettings = async (
  inventoryCenterId: string,
): Promise<Array<STSetting>> => {
  const response = await axiosInstance.get('/api/SchedulingTool/setting', {
    params: { inventoryCenterId },
  });
  return response.data;
};

/**
 * API call used to get Scheduling Tool Setting by inventory center and key
 * @param params
 */
export const getSetting = async (params: {
  inventoryCenterId: string;
  key: string;
}): Promise<STSetting> => {
  const response = await axiosInstance.get(
    `/api/SchedulingTool/setting/${params.inventoryCenterId}/${params.key}`,
  );
  return response.data;
};

/**
 * API call used to set a Scheduling Tool Setting
 * @param info
 */
export const setSetting = async (info: STSettingInfo): Promise<STSetting> => {
  const response = await axiosInstance.post(
    '/api/SchedulingTool/setting',
    info,
  );
  return response.data;
};

/**
 * API call used to reset a Scheduling Tool Setting to default value
 * @param params
 */
export const resetSettingToDefault = async (params: {
  inventoryCenterId: string;
  key: string;
}): Promise<STSetting> => {
  const response = await axiosInstance.delete(
    `/api/SchedulingTool/setting/${params.inventoryCenterId}/${params.key}`,
  );
  return response.data;
};

/**
 * API call to get a change log
 * @param id
 */
export const getChangeLog = async (id: string): Promise<STChangeLogDto> => {
  const response = await axiosInstance.get(
    `/api/SchedulingTool/changeLog/${id}`,
  );
  return response.data;
};

/**
 * API call to get change logs created by all users
 */
export const getAllChangeLogs = async (): Promise<STChangeLogDto[]> => {
  const response = await axiosInstance.get<STChangeLogDto[]>(
    '/api/SchedulingTool/changeLogGlobal',
    {},
  );
  return response.data;
};

/**
 * API call to get all change logs created by a user
 */
export const getChangeLogs = async (): Promise<STChangeLogDto[]> => {
  const response = await axiosInstance.get<STChangeLogDto[]>(
    '/api/SchedulingTool/changeLog',
    {},
  );
  return response.data;
};

/**
 * API call to add or update change logs
 * @param info
 */
export const addOrUpdateChangeLogs = async (
  info: STChangeLogInfo[],
): Promise<STChangeLogDto[]> => {
  const response = await axiosInstance.post(
    '/api/SchedulingTool/changeLog',
    info,
  );
  return response.data;
};

/**
 * API call to commit change logs to ICIM
 */
export const commitChangeLogsToICIM = async (
  payload: STChangeLogCommitInfo[],
) => {
  await axiosInstance.post('/api/SchedulingTool/changeLog/commit', payload);
};

/**
 * API call to clear all change logs for a user
 */
export const deleteChangeLogs = async () => {
  await axiosInstance.delete('/api/SchedulingTool/changeLog');
};

/**
 * API call to delete a change log
 * @param id
 */
export const deleteChangeLog = async (id: string) => {
  await axiosInstance.delete(`/api/SchedulingTool/changeLog/${id}`);
};

/**
 * API call to bulk delete change logs
 * @param payload
 */
export const deleteChangeLogBulk = async (payload: string[]) => {
  await axiosInstance.delete('/api/SchedulingTool/changeLogBulkDelete', {
    data: payload,
  });
};

/**
 * API call to trigger manual refresh of Scheduling Tool data
 */
export const triggerManualRefresh = async (inventoryCenterId: string) => {
  await axiosInstance.post(
    '/api/SchedulingTool/triggerFullRefresh',
    inventoryCenterId,
  );
};

/**
 * API call to determine whether a refresh is already in flight by inventory center
 * @param inventoryCenterId
 */
export const isRefreshRunning = async (
  inventoryCenterId: string,
): Promise<boolean> => {
  const response = await axiosInstance.get(
    `/api/SchedulingTool/isRefreshRunning/status/${inventoryCenterId}`,
  );
  return response.data;
};

/**
 * API call to get list of Capacity Utilization Employee Counts
 * @param filter
 */
export const getCapacityUtilizationEmployeeCounts = async (
  filter: InventoryCenterShiftFilter,
): Promise<STCapacityUtilizationEmployeesCountDto[]> => {
  const response = await axiosInstance.get(
    '/api/SchedulingTool/capacityUtilizationEmployeesCount',
    {
      params: filter,
    },
  );
  return response.data;
};

/**
 * API call to get list of Capacity Utilization Shift Hour Totals
 * @param filter
 */
export const getCapacityUtilizationShiftHoursTotals = async (
  filter: InventoryCenterShiftFilter,
): Promise<STCapacityUtilizationShiftHoursTotalDto[]> => {
  const response = await axiosInstance.get(
    '/api/SchedulingTool/capacityUtilizationShiftHoursTotal',
    {
      params: filter,
    },
  );
  return response.data;
};

/**
 * API call to get list of Capacity Utilization Total PTO Hours
 * @param filter
 */
export const getCapacityUtilizationPtoHoursTotals = async (
  filter: InventoryCenterShiftFilter,
): Promise<STCapacityUtilizationPtoHoursTotalDto[]> => {
  const response = await axiosInstance.get(
    '/api/SchedulingTool/capacityUtilizationPtoHoursTotal',
    {
      params: filter,
    },
  );
  return response.data;
};

/**
 * API call to get Capacity Utilizations Aggregate data
 * @param filter
 */
export const getCapacityUtilizationsAggregate = async (
  filter: STCapacityUtilizationFilter,
): Promise<STCapacityUtilizationsAggregateDto> => {
  const response = await axiosInstance.get(
    '/api/SchedulingTool/capacityUtilizationsAggregate',
    {
      params: filter,
    },
  );
  return response.data;
};

/**
 * API call to get MCell Map CSV data
 * @param inventoryCenterId
 */
export const downloadMCellMapData = async (
  inventoryCenterId: string,
): Promise<Blob> => {
  const response = await axiosInstance.get(`/api/SchedulingTool/mCellMapData`, {
    params: { inventoryCenterId },
  });
  return new Blob([response.data]);
};

/**
 * API call to get MCell Guardrail CSV data
 * @param inventoryCenterId
 */
export const downloadMCellGuardrailData = async (
  inventoryCenterId: string,
): Promise<Blob> => {
  const response = await axiosInstance.get(
    `/api/SchedulingTool/mCellGuardrailData`,
    {
      params: { inventoryCenterId },
    },
  );
  return new Blob([response.data]);
};

/**
 * API call to add Labor What If Shift Hours Per Employee Input
 * @param info
 */
export const addLaborWhatIfShiftHoursPerEmployeeInput = async (
  info: StLaborWhatIfInputInfo,
): Promise<StLaborWhatIfInputDto> => {
  const response = await axiosInstance.post(
    '/api/SchedulingTool/laborWhatIfShiftHoursPerEmployee',
    info,
  );
  return response.data;
};

/**
 * API call to add Labor What If Employee Count Input
 * @param info
 */
export const addLaborWhatIfEmployeeCountInput = async (
  info: StLaborWhatIfInputInfo,
): Promise<StLaborWhatIfInputDto> => {
  const response = await axiosInstance.post(
    '/api/SchedulingTool/laborWhatIfEmployeeCount',
    info,
  );
  return response.data;
};

/**
 * API call to add Labor What If PTO Hours Input
 * @param info
 */
export const addLaborWhatIfPtoHoursInput = async (
  info: StLaborWhatIfInputInfo,
): Promise<StLaborWhatIfInputDto> => {
  const response = await axiosInstance.post(
    '/api/SchedulingTool/laborWhatIfTotalPtoHours',
    info,
  );
  return response.data;
};

/**
 * API call to calculate Labor What Ifs
 */
export const calculateLaborWhatIfs = async () => {
  await axiosInstance.post('/api/SchedulingTool/laborWhatIfCalculate');
};

/**
 * API call to reset Labor What Ifs
 */
export const resetLaborWhatIfs = async () => {
  await axiosInstance.delete('/api/SchedulingTool/laborWhatIf');
};

/**
 * API call to get Labor What If Input Aggregate data
 * @param filter
 */
export const getLaborWhatIfInputAggregate = async (
  filter: InventoryCenterShiftFilter,
): Promise<StLaborWhatIfInputAggregateDto> => {
  const response = await axiosInstance.get(
    '/api/SchedulingTool/laborWhatIfInputAggregate',
    {
      params: filter,
    },
  );
  return response.data;
};

/**
 * API call to get Labor What If Output Aggregate data
 * @param filter
 */
export const getLaborWhatIfOutputAggregate = async (
  filter: STCapacityUtilizationFilter,
): Promise<StLaborWhatIfOutputAggregateDto> => {
  const response = await axiosInstance.get(
    '/api/SchedulingTool/laborWhatIfOutputAggregate',
    {
      params: filter,
    },
  );
  return response.data;
};

/**
 * API to search for an order, returns top 10 list
 * @param searchTerm
 */
export const searchOrderNumber = async (
  searchTerm: string,
): Promise<string[]> => {
  const response = await axiosInstance.get<string[]>(
    `/api/SchedulingTool/searchOrderNumber/${searchTerm}`,
  );
  return response.data;
};

/**
 * API to get main information about a sales order given its order number
 * @param orderNumber
 */
export const getSalesOrderMain = async (
  orderNumber: string,
): Promise<SalesOrderMain> => {
  const response = await axiosInstance.get<SalesOrderMain>(
    `/api/SchedulingTool/salesOrderMain/${orderNumber}`,
  );
  return response.data;
};

/**
 * API to get extra information about a sales order given its order number
 * @param orderNumber
 */
export const getSalesOrderExtra = async (
  orderNumber: string,
): Promise<SalesOrderExtra> => {
  const response = await axiosInstance.get<SalesOrderExtra>(
    `/api/SchedulingTool/salesOrderExtra/${orderNumber}`,
  );
  return response.data;
};

/**
 * API to get a preview of the sales order line items given the order number and hideProcessedOrders flag
 * @param params
 */
export const getSalesOrderLineItemsPreview = async (params: {
  orderNumber: string;
  hideProcessedOrders: boolean;
}): Promise<SalesOrderLineItemPreview[]> => {
  const response = await axiosInstance.get<SalesOrderLineItemPreview[]>(
    `/api/SchedulingTool/salesOrderLineItemsPreview/${params.orderNumber}?hideProcessedOrders=${params.hideProcessedOrders}`,
  );
  return response.data;
};

/**
 * API to get the details of the sales order line items given the order number and hideProcessedOrders flag
 * @param params
 */
export const getSalesOrderLineItemsDetail = async (params: {
  orderNumber: string;
  hideProcessedOrders: boolean;
}): Promise<SalesOrderLineItemDetail[]> => {
  const response = await axiosInstance.get<SalesOrderLineItemDetail[]>(
    `/api/SchedulingTool/salesOrderLineItemsDetail/${params.orderNumber}?hideProcessedOrders=${params.hideProcessedOrders}`,
  );
  return response.data;
};

/**
 * API to create a reason
 * @param reasonCreate
 */
export const createReason = async (
  reasonCreate: ReasonCreate,
): Promise<ReasonDto> => {
  const response = await axiosInstance.post<ReasonDto>(
    '/api/SchedulingTool/reason',
    reasonCreate,
  );
  return response.data;
};

/**
 * API to get a reason by Id
 * @param id
 */
export const getReasonById = async (id: string): Promise<ReasonDto> => {
  const response = await axiosInstance.get<ReasonDto>(
    `/api/SchedulingTool/reason/${id}`,
  );
  return response.data;
};

/**
 * API to update a reason
 * @param params
 */
export const updateReason = async (params: {
  id: string;
  reasonUpdate: ReasonUpdate;
}): Promise<ReasonDto> => {
  const response = await axiosInstance.post<ReasonDto>(
    `/api/SchedulingTool/reason/${params.id}`,
    params.reasonUpdate,
  );
  return response.data;
};

/**
 * API to get reasons matching query parameters
 * @param filter
 */
export const getReasons = async (
  filter: ReasonFilter,
): Promise<ReasonDto[]> => {
  const queryParams = new URLSearchParams(filter as any).toString();
  const response = await axiosInstance.get<ReasonDto[]>(
    `/api/SchedulingTool/reason?${queryParams}`,
  );
  return response.data;
};

/**
 * API to delete a reason
 * @param id
 */
export const deleteReason = async (id: string): Promise<void> => {
  await axiosInstance.delete(`/api/SchedulingTool/reason/${id}`);
};
