import {
  JobFunction,
  JobFunctionInfo,
  JobFunctionTag,
  JobFunctionTagInfo,
  MCellEmployeeInMCellFilter,
  MCellEmployeeJobStationExperienceLoggedIn,
  MCellEmployeeLoggedInFilter,
  MCellEmployeeLogin,
  MCellEmployeeLogout,
  MCellLoginDto,
} from './types';
import axiosInstance from '../../api';
import {
  MCellEmployee,
  MCellEmployeeFilterValue,
  MCellEmployeeInMCell,
  MCellEmployeeJobFunctionHistory,
  MCellEmployeeJobFunctionTagHistory,
  MCellEmployeeJobStationHistory,
  MCellEmployeeMCellHistory,
} from '../resourceTracking/types';
import { MCellLoginReport, MCellLoginReportFilter } from './types';

/**
 * API call to create a Job Function
 * @param jobFunctionInfo
 */
export const createJobFunction = async (
  jobFunctionInfo: JobFunctionInfo,
): Promise<JobFunction> => {
  const response = await axiosInstance.post(
    '/api/MCellEmployee/jobFunction',
    jobFunctionInfo,
  );
  return response.data;
};

/**
 * API call to get a list of Job Functions
 * @param inventoryCenterId
 */
export const getJobFunctions = async (
  inventoryCenterId: string,
): Promise<JobFunction[]> => {
  const response = await axiosInstance.get('/api/MCellEmployee/jobFunction', {
    params: { inventoryCenterId },
  });
  return response.data;
};

/**
 * API call to update a Job Function
 * @param info
 */
export const updateJobFunction = async (
  info: JobFunctionInfo,
): Promise<JobFunction> => {
  const response = await axiosInstance.post(
    `/api/MCellEmployee/jobFunction/${info.id}`,
    info,
  );
  return response.data;
};

/**
 * API call to delete a Job Function
 * @param id
 */
export const deleteJobFunction = async (id: string) => {
  await axiosInstance.delete(`/api/MCellEmployee/jobFunction/${id}`);
  return id;
};

/**
 * API call to get a logged in MCell Employees
 * @param filter
 */
export const getMCellEmployeesLoggedIn = async (
  filter: MCellEmployeeLoggedInFilter,
): Promise<MCellEmployee[]> => {
  const response = await axiosInstance.get(
    '/api/MCellEmployee/mCellEmployeesLoggedIn',
    {
      params: filter,
    },
  );
  return response.data;
};

/**
 * API call to get a MCell Employees in MCell. Logged in, assigned to station in cell, is home cell etc
 * @param filter
 */
export const getMCellEmployeesInMCell = async (
  filter: MCellEmployeeInMCellFilter,
): Promise<MCellEmployeeInMCell[]> => {
  const response = await axiosInstance.get(
    '/api/MCellEmployee/mCellEmployeesInMCell',
    {
      params: filter,
    },
  );
  return response.data;
};

/**
 * API call to get MCell Login Last Updated date
 */
export const getMCellLoginLastUpdatedDate = async (): Promise<string> => {
  const response = await axiosInstance.get(
    '/api/MCellEmployee/mCellLoginLastUpdatedDate',
  );
  return response.data;
};

/**
 * API call to log in an MCell Employee.
 * @param mCellEmployeeLogin - The login information of the MCell Employee.
 * @returns A Promise that resolves to an MCellLoginDto object containing the login details of the MCell Employee.
 */
export const mCellLogin = async (
  mCellEmployeeLogin: MCellEmployeeLogin,
): Promise<MCellLoginDto> => {
  const response = await axiosInstance.post(
    '/api/MCellEmployee/mCellLogin',
    mCellEmployeeLogin,
  );
  return response.data;
};

/**
 * API call to log out an MCell Employee.
 * @param mCellEmployeeLogout - The logout information of the MCell Employee.
 * @returns A Promise that resolves to an MCellLoginDto object containing the logout details of the MCell Employee.
 */
export const mCellLogout = async (
  mCellEmployeeLogout: MCellEmployeeLogout,
): Promise<MCellLoginDto> => {
  const response = await axiosInstance.post(
    '/api/MCellEmployee/mCellLogout',
    mCellEmployeeLogout,
  );
  return response.data;
};

/**
 * API call to get MCell Employees filtered by Inventory Center for use in select component
 * @param inventoryCenterId
 */
export const getMCellEmployeeSelection = async (
  inventoryCenterId: string,
): Promise<MCellEmployee[]> => {
  const response = await axiosInstance.get(
    '/api/MCellEmployee/mCellEmployeeSelection',
    {
      params: { inventoryCenterId },
    },
  );
  return response.data;
};

/**
 * API call to create a Job Function Tag
 * @param info
 */
export const createJobFunctionTag = async (
  info: JobFunctionTagInfo,
): Promise<JobFunctionTag> => {
  const response = await axiosInstance.post(
    '/api/MCellEmployee/jobFunctionTag',
    JSON.stringify(info.title),
  );
  return response.data;
};

/**
 * API call to get a list of Job Function Tags
 */
export const getJobFunctionTags = async (): Promise<JobFunctionTag[]> => {
  const response = await axiosInstance.get('/api/MCellEmployee/jobFunctionTag');
  return response.data;
};

/**
 * API call to update a Job Function Tag
 * @param info
 */
export const updateJobFunctionTag = async (
  info: JobFunctionTagInfo,
): Promise<JobFunctionTag> => {
  const response = await axiosInstance.post(
    `/api/MCellEmployee/jobFunctionTag/${info.id}`,
    JSON.stringify(info.title),
  );
  return response.data;
};

/**
 * API call to delete a Job Function Tag
 * @param id
 */
export const deleteJobFunctionTag = async (id: string) => {
  await axiosInstance.delete(`/api/MCellEmployee/jobFunctionTag/${id}`);
  return id;
};

/**
 * API call to get the Job Station History for an MCell Employee
 * @param employeeId
 */
export const getMCellEmployeeJobStationHistory = async (employeeId: string) => {
  const response = await axiosInstance.get<MCellEmployeeJobStationHistory[]>(
    `/api/MCellEmployee/${employeeId}/jobStationHistory`,
  );
  return response.data;
};
/**
 * API call to get the Job Function History for an MCell Employee
 * @param employeeId
 */
export const getMCellEmployeeJobFunctionHistory = async (
  employeeId: string,
) => {
  const response = await axiosInstance.get<MCellEmployeeJobFunctionHistory[]>(
    `/api/MCellEmployee/${employeeId}/jobFunctionHistory`,
  );
  return response.data;
};
/**
 * API call to get the Job Function Tag History for an MCell Employee
 * @param employeeId
 */
export const getMCellEmployeeJobFunctionTagHistory = async (
  employeeId: string,
) => {
  const response = await axiosInstance.get<
    MCellEmployeeJobFunctionTagHistory[]
  >(`/api/MCellEmployee/${employeeId}/jobFunctionTagHistory`);
  return response.data;
};

/**
 * API call to get the Work Cell History for an MCell Employee
 * @param employeeId
 */
export const getMCellEmployeeHistory = async (employeeId: string) => {
  const response = await axiosInstance.get<MCellEmployeeMCellHistory[]>(
    `/api/MCellEmployee/${employeeId}/mCellHistory`,
  );
  return response.data;
};

/**
 * API call to get MCell Login Report
 * @param filter
 */
export const getMCellLoginReport = async (
  filter: MCellLoginReportFilter,
): Promise<MCellLoginReport[]> => {
  const response = await axiosInstance.get(
    `/api/MCellEmployee/mCellLoginReport`,
    {
      params: filter,
    },
  );
  return response.data;
};

/**
 * API call to get MCell Employee Search Report data
 */
export const getMCellEmployeeSearchReportData = async (
  filter: MCellEmployeeFilterValue,
): Promise<MCellEmployee[]> => {
  const response = await axiosInstance.get(
    '/api/MCellEmployee/mCellEmployeeReport',
    {
      params: filter,
    },
  );
  return response.data;
};

/**
 * API call to get employee search report CSV data
 */
export const downloadEmployeeReportCsv = async (): Promise<Blob> => {
  const response = await axiosInstance.get(
    '/api/MCellEmployee/mCellEmployeeReportCsv',
  );
  return new Blob([response.data]);
};

/**
 * API call to get employees with experience in station currently logged into other stations
 * @param jobStationId
 */
export const getJobStationExperiencedEmployeesInOtherStations = async (
  jobStationId: string,
): Promise<MCellEmployeeJobStationExperienceLoggedIn[]> => {
  const response = await axiosInstance.get(
    `/api/MCellEmployee/jobStation/${jobStationId}/experiencedEmployeesInOtherStations`,
  );
  return response.data;
};

/**
 * API call to get employees with related experience in station currently logged into other stations
 * @param jobStationId
 */
export const getJobStationRelatedExperiencedEmployeesInOtherStations = async (
  jobStationId: string,
): Promise<MCellEmployeeJobStationExperienceLoggedIn[]> => {
  const response = await axiosInstance.get(
    `/api/MCellEmployee/jobStation/${jobStationId}/relatedExperiencedEmployeesInOtherStations`,
  );
  return response.data;
};

/**
 * API call to trigger a manual refresh of AD Users and MCell Employees
 */
export const triggerManualEmployeeRefresh = async () => {
  await axiosInstance.post('/api/MCellEmployee/triggerFullEmployeeRefresh');
};

/**
 * API call to check the status of the employee refresh
 */
export const checkEmployeeRefreshStatus = async (): Promise<{
  isLeased: boolean;
}> => {
  const response = await axiosInstance.get(
    '/api/MCellEmployee/checkEmployeeRefreshStatus',
  );
  return response.data;
};
