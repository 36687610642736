import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';

type Props = Table.DataRowProps & {
  onClick: (row: any) => any;
};

/**
 * Renders a table row with a click handler
 * @param row
 * @param onClick
 * @param restProps
 * @constructor
 */
const GridRow = ({ row, onClick, ...restProps }: Props) => {
  /**
   * Called when user clicks on the row
   * @param e
   */
  function handleClick(e: React.MouseEvent) {
    onClick(row);
  }

  return (
    <Table.Row
      {...restProps}
      row={row}
      onClick={handleClick}
      style={{ cursor: 'pointer' }}
    />
  );
};

export default GridRow;
