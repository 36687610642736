import { useEffect, useRef } from 'react';

export type Duration = 'Custom' | 'P1Y' | 'P1M' | 'P7D' | 'P1D'; //see https://www.w3.org/TR/xmlschema-2/#duration

export type DataTimeSpan = {
  span: Duration;
  from?: string;
  to?: string;
};

/**
 * Hook for doing a `setInterval`
 * @param callback
 * @param delay
 */
export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback as any;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      // @ts-ignore
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
