import axiosInstance from '../../api';
import { MCell } from '../manufacturingLocation/types';
import {
  MCellEmployeeSelection,
  SupportGroup,
  SupportGroupCreate,
  SupportGroupDowntimeEscalationOverride,
  SupportGroupDowntimeEscalationOverrideCreate,
  SupportGroupDowntimeEscalationPositionOverride,
  SupportGroupDowntimeEscalationPositionOverrideCreate,
  SupportGroupMember,
  SupportGroupMemberCreate,
  SupportGroupMemberDto,
  SupportGroupMemberInfo,
  SupportGroupUpdate,
  SupportHierarchy,
  SupportHierarchyCreate,
  SupportHierarchyMCellCollection,
  SupportHierarchyMCellDto,
  SupportHierarchyPosition,
  SupportHierarchyPositionInfo,
} from './types';

/**
 * API call to get list of Support Hierarchies
 */
export const getSupportHierarchies = async (): Promise<SupportHierarchy[]> => {
  const response = await axiosInstance.get('/api/supportHierarchy/hierarchies');
  return response.data;
};

/**
 * API call to create Support Hierarchy
 * @param supportHierarchyCreate
 */
export const createSupportHierarchy = async (
  supportHierarchyCreate: SupportHierarchyCreate,
): Promise<SupportHierarchy> => {
  const response = await axiosInstance.post<SupportHierarchy>(
    '/api/supportHierarchy/hierarchies',
    supportHierarchyCreate,
  );
  return response.data;
};

/**
 * API call to delete Support Hierarchy
 * @param id
 */
export const deleteSupportHierarchy = async (id: string) => {
  await axiosInstance.delete(`/api/SupportHierarchy/hierarchies/${id}`);
  return id;
};

/**
 * API call to create Support Hierarchy Position
 * @param positionInfo
 */
export const createPosition = async (
  positionInfo: SupportHierarchyPositionInfo,
): Promise<SupportHierarchyPosition> => {
  const response = await axiosInstance.post(
    '/api/SupportHierarchy/positions',
    positionInfo,
  );
  return response.data;
};

/**
 * API call to update Support Hierarchy Position title
 * @param params
 */
export const updatePositionTitle = async (params: {
  id: string;
  title: string;
}): Promise<SupportHierarchyPosition> => {
  const response = await axiosInstance.post(
    `/api/SupportHierarchy/positions/${params.id}/rename`,
    JSON.stringify(params.title),
  );
  return response.data;
};

/**
 * API call to delete Support Hierarchy Position
 * @param id
 */
export const deletePosition = async (id: string) => {
  await axiosInstance.delete(`/api/SupportHierarchy/positions/${id}`);
};

/**
 * API call to get list of Support Groups
 * @param inventoryCenterId
 */
export const getSupportGroups = async (
  inventoryCenterId: string,
): Promise<SupportGroup[]> => {
  const response = await axiosInstance.get(
    '/api/supportHierarchy/supportGroups',
    {
      params: { inventoryCenterId },
    },
  );
  return response.data;
};

/**
 * API call to create Support Group
 * @param supportGroupCreate
 */
export const createSupportGroup = async (
  supportGroupCreate: SupportGroupCreate,
): Promise<SupportGroup> => {
  const response = await axiosInstance.post(
    '/api/supportHierarchy/supportGroups',
    supportGroupCreate,
  );
  return response.data;
};

/**
 * API call to update Support Group
 * @param params
 */
export const updateSupportGroup = async (params: {
  id: string;
  supportGroupUpdate: SupportGroupUpdate;
}): Promise<SupportGroup> => {
  const response = await axiosInstance.post(
    `/api/supportHierarchy/supportGroups/${params.id}`,
    params.supportGroupUpdate,
  );
  return response.data;
};

/**
 * API call to delete Support Group
 * @param id
 */
export const deleteSupportGroup = async (id: string) => {
  await axiosInstance.delete(`/api/supportHierarchy/supportGroups/${id}`);
  return id;
};

/**
 * API call to add Support Group Member to a position
 * @param memberInfo
 */
export const addSupportGroupMember = async (
  memberInfo: SupportGroupMemberInfo,
): Promise<SupportGroupMember> => {
  const response = await axiosInstance.post(
    '/api/SupportHierarchy/supportGroupMembers',
    memberInfo,
  );
  return response.data;
};

/**
 * API call to remove Support Group Member from Position
 * @param id
 */
export const deleteSupportGroupMember = async (id: string) => {
  await axiosInstance.delete(`/api/SupportHierarchy/supportGroupMembers/${id}`);
};

/**
 * API call to get list of unmapped MCells in a Support Hierarchy
 * @param supportHierarchyId
 */
export const getUnmappedMCells = async (
  supportHierarchyId: string,
): Promise<MCell[]> => {
  const response = await axiosInstance.get(
    '/api/SupportHierarchy/mCells/unmapped',
    {
      params: { supportHierarchyId },
    },
  );
  return response.data;
};

/**
 * API call to set list of MCells on Support Hierarchy MCell Collection
 * @param params
 */
export const setMCellsOnMCellCollection = async (params: {
  id: string;
  mCellIds: string[];
}): Promise<SupportHierarchyMCellCollection> => {
  const response = await axiosInstance.post(
    `/api/SupportHierarchy/mCellCollections/${params.id}/setMCells`,
    params.mCellIds,
  );
  return response.data;
};

/**
 * API call to update Support Hierarchy MCell Collection name
 * @param params
 */
export const updateMCellCollectionName = async (params: {
  id: string;
  newName: string;
}): Promise<SupportHierarchyMCellCollection> => {
  const response = await axiosInstance.post(
    `/api/SupportHierarchy/mCellCollections/${params.id}/rename`,
    JSON.stringify(params.newName),
  );
  return response.data;
};

// TODO: Remove (and relocate to MCell Employee feature if not already done) when implementing RSTW-850
export const getMCellEmployeeSelections = async (): Promise<
  MCellEmployeeSelection[]
> => {
  const response = await axiosInstance.get(
    '/api/SupportHierarchy/mCellEmployeeSelections',
  );
  return response.data;
};

/**
 * API call to get list of Support Group Downtime Escalation Overrides
 * @param inventoryCenterId
 */
export const getSupportGroupDowntimeEscalationOverrides = async (
  inventoryCenterId: string,
): Promise<SupportGroupDowntimeEscalationOverride[]> => {
  const response = await axiosInstance.get(
    '/api/supportHierarchy/supportGroups/downtimeEscalationOverrides',
    {
      params: { inventoryCenterId },
    },
  );
  return response.data;
};

/**
 * API call to create Support Group Downtime Escalation Override
 * @param overrideCreate
 */
export const createSupportGroupDowntimeEscalationOverride = async (
  overrideCreate: SupportGroupDowntimeEscalationOverrideCreate,
): Promise<SupportGroupDowntimeEscalationOverride> => {
  const response = await axiosInstance.post(
    '/api/supportHierarchy/supportGroups/downtimeEscalationOverrides',
    overrideCreate,
  );
  return response.data;
};

/**
 * API call to update Support Group Downtime Escalation Override
 * @param id
 * @param timeMinutes
 */
export const updateSupportGroupDowntimeEscalationOverride = async (
  id: string,
  timeMinutes: number,
): Promise<SupportGroupDowntimeEscalationOverride> => {
  const response = await axiosInstance.post(
    `/api/supportHierarchy/supportGroups/downtimeEscalationOverrides/${id}`,
    JSON.stringify(timeMinutes),
  );
  return response.data;
};

/**
 * API call to delete Support Group Downtime Escalation Override
 * @param id
 */
export const deleteSupportGroupDowntimeEscalationOverride = async (
  id: string,
) => {
  await axiosInstance.delete(
    `/api/supportHierarchy/supportGroups/downtimeEscalationOverrides/${id}`,
  );
  return id;
};

/**
 * API call to get list of Support Group Downtime Escalation Position Overrides
 * @param positionId
 */
export const getSupportGroupDowntimeEscalationPositionOverrides = async (
  positionId: string,
): Promise<SupportGroupDowntimeEscalationPositionOverride[]> => {
  const response = await axiosInstance.get(
    '/api/supportHierarchy/supportGroups/downtimeEscalationPositionOverrides',
    {
      params: { positionId },
    },
  );
  return response.data;
};

/**
 * API call to create Support Group Downtime Escalation Position Override
 * @param overrideCreate
 */
export const createSupportGroupDowntimeEscalationPositionOverride = async (
  overrideCreate: SupportGroupDowntimeEscalationPositionOverrideCreate,
): Promise<SupportGroupDowntimeEscalationPositionOverride> => {
  const response = await axiosInstance.post(
    '/api/supportHierarchy/supportGroups/downtimeEscalationPositionOverrides',
    overrideCreate,
  );
  return response.data;
};

/**
 * API call to update Support Group Downtime Escalation Position Override
 * @param id
 * @param timeMinutes
 */
export const updateSupportGroupDowntimeEscalationPositionOverride = async (
  id: string,
  timeMinutes: number,
): Promise<SupportGroupDowntimeEscalationPositionOverride> => {
  const response = await axiosInstance.post(
    `/api/supportHierarchy/supportGroups/downtimeEscalationPositionOverrides/${id}`,
    JSON.stringify(timeMinutes),
  );
  return response.data;
};

/**
 * API call to delete Support Group Downtime Escalation Position Override
 * @param id
 */
export const deleteSupportGroupDowntimeEscalationPositionOverride = async (
  id: string,
) => {
  await axiosInstance.delete(
    `/api/supportHierarchy/supportGroups/downtimeEscalationPositionOverrides/${id}`,
  );
  return id;
};

/**
 * API call to get list of Support Hierarchy MCells
 * @param supportHierarchyId
 * @param supportGroupName
 */
export const getSupportHierarchyMCells = async ({
  supportHierarchyId,
  supportGroupName,
}: {
  supportHierarchyId: string;
  supportGroupName: string;
}): Promise<SupportHierarchyMCellDto[]> => {
  const response = await axiosInstance.get(
    '/api/SupportHierarchy/supportHierarchyMCell',
    {
      params: {
        supportHierarchyId,
        supportGroupName,
      },
    },
  );
  return response.data;
};

/**
 * API call to get all Support Group Members
 */
export const getSupportGroupMembers = async (): Promise<
  SupportGroupMemberDto[]
> => {
  const response = await axiosInstance.get(
    '/api/supportHierarchy/supportGroupMembers',
  );
  return response.data;
};

/**
 * API call to update multiple Support Group Members
 */
export const updateSupportGroupMembers = async (
  supportGroupMembers: SupportGroupMemberCreate[],
): Promise<SupportGroupMemberDto[]> => {
  const response = await axiosInstance.post(
    '/api/supportHierarchy/supportGroupMembersBulk',
    supportGroupMembers,
  );
  return response.data;
};

/**
 * API call to delete multiple Support Group Members
 */
export const deleteSupportGroupMembers = async (
  payload: string[],
): Promise<SupportGroupMemberDto[]> => {
  const response = await axiosInstance.delete(
    '/api/supportHierarchy/supportGroupMembersBulk',
    {
      data: payload,
    },
  );
  return response.data;
};
