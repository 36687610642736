import { configureStore, Middleware } from '@reduxjs/toolkit';
import coatingsReducer from './features/coatings/coatingsSlice';
import supportHierarchyReducer from './features/supportHierarchy/supportHierarchySlice';
import schedulingToolReducer from './features/schedulingTool/schedulingToolSlice';
import factoryAutomationReducer from './features/factoryAutomation/factoryAutomationSlice';
import resourceTrackingReducer from './features/resourceTracking/resourceTrackingSlice';
import profileReducer from './features/profile/profileSlice';
import appReducer from './features/app/appSlice';
import LogRocket from 'logrocket';
import { mCellAlertReducer } from './features/mCellAlert/mCellAlertSlice';
import { useDispatch } from 'react-redux';
import dashboardReducer from './features/dashboard/dashboardSlice';
import downtimeReducer from './features/downtime/downtimeSlice';
import cellBoardReducer from './features/cellBoard/cellBoardSlice';
import manufacturingLocationReducer from './features/manufacturingLocation/manufacturingLocationSlice';
import mCellEmployeeReducer from './features/mCellEmployee/mCellEmployeeSlice';
import homeReducer from './features/home/homeSlice';
import machineReducer from './features/machine/machineSlice';
import devReducer from './features/dev/devSlice';
import lswReducer from './features/lsw/lswSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    home: homeReducer,
    profile: profileReducer,
    cellBoard: cellBoardReducer,
    manufacturingLocation: manufacturingLocationReducer,
    mCellAlert: mCellAlertReducer,
    resourceTracking: resourceTrackingReducer,
    supportHierarchy: supportHierarchyReducer,
    schedulingTool: schedulingToolReducer,
    coatings: coatingsReducer,
    factoryAutomation: factoryAutomationReducer,
    dashboard: dashboardReducer,
    downtime: downtimeReducer,
    mCellEmployee: mCellEmployeeReducer,
    machine: machineReducer,
    dev: devReducer,
    lsw: lswReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(LogRocket.reduxMiddleware() as Middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
