import React, { useEffect, useState } from 'react';
import EnhancedSelect, { OptionValue } from './EnhancedSelect';
import GenericDialog from './GenericDialog';

export type Props = {
  title: string;
  open: boolean;
  label?: string;
  data: OptionValue[];
  initialValue: any;
  onConfirm: (value: any) => void;
  onCancel: () => void;
  helpText?: string;
  disabled?: boolean;
  confirmDisabled?: boolean;
};

/**
 * Displays a Select component in a dialog
 * @param title
 * @param open
 * @param label
 * @param data
 * @param initialValue
 * @param onCancel
 * @param onConfirm
 * @param confirmDisabled
 * @param disabled
 * @param helpText
 * @constructor
 */
const EnhancedSelectDialog = ({
  title,
  open,
  label,
  data,
  initialValue,
  onCancel,
  onConfirm,
  confirmDisabled,
  disabled,
  helpText,
}: Props) => {
  const [value, setValue] = useState(initialValue);

  /**
   * Effect that updates internal state when external state changes
   */
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  /**
   * Called when user confirms changes. Notifies parent of confirmation
   */
  function handleConfirm() {
    onConfirm(value);
  }

  return (
    <GenericDialog
      title={title}
      open={open}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      confirmDisabled={confirmDisabled}
    >
      <EnhancedSelect
        id={title}
        label={label}
        data={data}
        value={value}
        onChange={setValue}
        disabled={disabled}
        helpText={helpText}
      />
    </GenericDialog>
  );
};

export default EnhancedSelectDialog;
