import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, Tooltip, Typography } from '@mui/material';
import {
  formatInventoryCenterDisplayName,
  formatMCellDescription,
  getInventoryCenterOptionAll,
} from '../utils';
import { useTranslation } from 'react-i18next';
import { EMPTY_GUID } from '../../../utils/string-utils';
import {
  InventoryCenter,
  ManufacturingLocation,
  MCell,
  MCellCollection,
} from '../types';
import EnhancedSelect, { OptionValue } from '../../common/EnhancedSelect';
import { ListIcon } from '../icons';
import { useNavigation } from '../../../utils/navigation';
import EnhancedMultiSelect from '../../common/EnhancedMultiSelect';

const PREFIX = 'ManufacturingLocationSelection';

const styles = {
  mCellCollectionArea: `${PREFIX}-mCellCollectionArea`,
  mCellCollectionSelect: `${PREFIX}-mCellCollectionSelect`,
  manageCollectionButton: `${PREFIX}-manageCollectionButton`,
  orSeparator: `${PREFIX}-orSeparator`,
  select: `${PREFIX}-select`,
};

const StyledManufacturingLocationSelectionDiv = styled('div')(({ theme }) => ({
  [`& .${styles.mCellCollectionArea}`]: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },

  [`& .${styles.mCellCollectionSelect}`]: {
    flexGrow: 1,
  },

  [`& .${styles.manageCollectionButton}`]: {
    alignSelf: 'center',
  },

  [`& .${styles.orSeparator}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${styles.select}`]: {
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  mCells: MCell[];
  inventoryCenters: InventoryCenter[];
  mCellCollections?: MCellCollection[];
  initialManufacturingLocation: ManufacturingLocation;
  onChange: (
    manufacturingLocation: ManufacturingLocation,
    componentId: string,
  ) => void;
  removeInventoryCenterAllOption?: Boolean;
  removeMCellAllOption?: Boolean;
};

/**
 * Renders component for selecting a Manufacturing Location
 * @param mCells
 * @param inventoryCenters
 * @param mCellCollections
 * @param initialManufacturingLocation
 * @param onChange
 * @param removeInventoryCenterAllOption
 * @param removeMCellAllOption
 * @constructor
 */
const ManufacturingLocationSelection = ({
  mCells,
  inventoryCenters,
  mCellCollections,
  initialManufacturingLocation,
  onChange,
  removeInventoryCenterAllOption,
  removeMCellAllOption,
}: Props) => {
  const { t } = useTranslation();
  const { navigateToMCellCollection } = useNavigation();
  const [selectedMCellOption, setSelectedMCellOption] =
    useState<OptionValue | null>({
      label: formatMCellDescription(
        mCells.find((x) => x.id === initialManufacturingLocation.mCellId),
      ),
      value: initialManufacturingLocation.mCellId,
    });
  const [mCellCollectionOption, setMCellCollectionOption] =
    useState<OptionValue | null>({
      label:
        mCellCollections?.find(
          (mc) => mc.id === initialManufacturingLocation.mCellCollectionId,
        )?.name || '',
      value: initialManufacturingLocation.mCellCollectionId,
    });
  const mCellId = selectedMCellOption?.value ?? EMPTY_GUID;
  const [inventoryCenterId, setInventoryCenterId] = useState(
    initialManufacturingLocation.inventoryCenterId,
  );
  const filteredMCells = mCells.filter(
    (m) => m.inventoryCenterId === inventoryCenterId,
  );
  const mCell = mCells.find((x) => x.id === mCellId);
  const isRollUp = mCell ? mCell.isRollup : false;
  const filteredComponentMCells = mCell?.componentMCells
    ?.filter((x) => x.rollupId === mCellId)
    .map((y) => y.component);
  const [componentId, setComponentId] = useState(EMPTY_GUID);
  const onChangeRef = useRef(onChange);
  const mCellOptions = removeMCellAllOption
    ? [
        ...filteredMCells.map((m) => ({
          label: formatMCellDescription(m),
          value: m.id,
        })),
      ]
    : [
        { label: t('all'), value: EMPTY_GUID },
        ...filteredMCells.map((m) => ({
          label: formatMCellDescription(m),
          value: m.id,
        })),
      ];
  const componentMCellOptions = isRollUp
    ? [
        { label: t('all'), value: EMPTY_GUID },
        ...filteredComponentMCells!.map((x) => ({
          label: formatMCellDescription(x),
          value: x.id,
        })),
      ]
    : [];

  useEffect(() => {
    setSelectedMCellOption({
      label: formatMCellDescription(
        mCells.find((x) => x.id === initialManufacturingLocation.mCellId),
      ),
      value: initialManufacturingLocation.mCellId,
    });
    setInventoryCenterId(initialManufacturingLocation.inventoryCenterId);
    setMCellCollectionOption({
      label:
        mCellCollections?.find(
          (mc) => mc.id === initialManufacturingLocation.mCellCollectionId,
        )?.name || '',
      value: initialManufacturingLocation.mCellCollectionId,
    });
  }, [initialManufacturingLocation, mCells, mCellCollections]);

  function handleInventoryCenterChange(newInventoryCenterId: string) {
    setSelectedMCellOption({ label: '', value: EMPTY_GUID });
    setMCellCollectionOption({ label: '', value: EMPTY_GUID });
    setInventoryCenterId(newInventoryCenterId);
  }

  function handleMCellCollectionChange(selectedOption: OptionValue | null) {
    setSelectedMCellOption({ label: '', value: EMPTY_GUID });
    setInventoryCenterId(EMPTY_GUID);
    setMCellCollectionOption(selectedOption);
  }

  function handleComponentIdChange(newComponentId: string) {
    setComponentId(newComponentId);
  }

  function handleMCellChange(selectedOption: OptionValue | null) {
    setComponentId(EMPTY_GUID);
    setSelectedMCellOption(selectedOption);
    if (selectedOption?.value !== EMPTY_GUID) {
      setMCellCollectionOption({ label: '', value: EMPTY_GUID });
    }
  }

  useEffect(() => {
    onChangeRef.current(
      {
        mCellId,
        inventoryCenterId,
        mCellCollectionId: mCellCollectionOption?.value ?? EMPTY_GUID,
      },
      componentId,
    );
  }, [mCellId, inventoryCenterId, mCellCollectionOption, componentId]);

  return (
    <StyledManufacturingLocationSelectionDiv>
      <EnhancedSelect
        label={t('inventoryCenter')}
        id={'inventoryCenter'}
        data={
          removeInventoryCenterAllOption
            ? [
                ...inventoryCenters.map((i) => ({
                  label: formatInventoryCenterDisplayName(i),
                  value: i.id,
                })),
              ]
            : [
                getInventoryCenterOptionAll(t),
                ...inventoryCenters.map((i) => ({
                  label: formatInventoryCenterDisplayName(i),
                  value: i.id,
                })),
              ]
        }
        value={inventoryCenterId}
        onChange={handleInventoryCenterChange}
        containerClass={styles.select}
        sx={{ mb: 1 }}
      />
      <EnhancedMultiSelect
        id={'workCell'}
        label={t('workCell')}
        data={mCellOptions}
        value={selectedMCellOption}
        onChange={handleMCellChange}
        placeholder={t('all')}
        disabled={inventoryCenterId === EMPTY_GUID}
        containerClass={styles.select}
        multiple={false}
        sx={{ mb: 1 }}
      />
      {isRollUp && (
        <EnhancedSelect
          label={t('componentWorkCell')}
          id={'componentWorkCell'}
          data={componentMCellOptions}
          value={componentId}
          onChange={handleComponentIdChange}
          containerClass={styles.select}
        />
      )}
      {mCellCollections && (
        <>
          <Typography className={styles.orSeparator} align={'center'}>
            {t('or')}
          </Typography>
          <div className={styles.mCellCollectionArea}>
            <EnhancedMultiSelect
              label={t('workCellGroup')}
              id={'workCellGroup'}
              data={[
                { label: t('none'), value: EMPTY_GUID },
                ...mCellCollections.map((mc) => ({
                  label: mc.name,
                  value: mc.id,
                })),
              ]}
              value={mCellCollectionOption}
              onChange={handleMCellCollectionChange}
              placeholder={t('none')}
              containerClass={styles.mCellCollectionSelect}
              multiple={false}
              sx={{ width: '100%' }}
            />
            <div className={styles.manageCollectionButton}>
              <Tooltip title={t('manageWorkCellGroups') as any}>
                <IconButton
                  edge={'end'}
                  onClick={navigateToMCellCollection}
                  size='large'
                >
                  <ListIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </>
      )}
    </StyledManufacturingLocationSelectionDiv>
  );
};

export default ManufacturingLocationSelection;
