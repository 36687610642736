import { SvgIcon, SvgIconProps } from '@mui/material';
import {
  mdiCogs,
  mdiDesktopClassic,
  mdiDomain,
  mdiFactory,
  mdiGarageVariant,
  mdiGarageOpenVariant,
  mdiHelp,
  mdiRobotIndustrial,
  mdiCheckBold,
  mdiKeyOutline,
  mdiArrowUpThick,
  mdiArrowDownThick,
  mdiViewDashboardVariantOutline,
  mdiTimetable,
  mdiBell,
  mdiAlert,
  mdiFileImagePlus,
  mdiMessageText,
  mdiCodeTags,
  mdiAccountGroupOutline,
  mdiAccountMultipleOutline,
  mdiGoogleCirclesCommunities,
  mdiAccountOutline,
  mdiFileDocumentOutline,
  mdiFormatPaint,
  mdiEmail,
  mdiViewDashboardOutline,
  mdiRecord,
  mdiCogOutline,
  mdiScoreboard,
  mdiFamilyTree,
  mdiChevronRight,
  mdiClipboardAccountOutline,
  mdiCardMultiple,
  mdiHomeOutline,
  mdiPoll,
  mdiViewDashboardVariant,
  mdiAccountMultipleCheckOutline,
  mdiSortNumericVariant,
  mdiAccountClockOutline,
  mdiFormatListChecks,
  mdiCalendarArrowRight,
  mdiClipboardListOutline,
  mdiFolderArrowLeftRight,
  mdiAccountHardHatOutline,
  mdiAccountQuestionOutline,
  mdiRefresh,
  mdiTable,
  mdiTableOff,
  mdiCalendarRange,
  mdiFilterOff,
  mdiContain,
  mdiInbox,
  mdiMail,
  mdiApplicationEditOutline,
  mdiClipboardAlertOutline,
  mdiArrowExpandHorizontal,
  mdiMinus,
  mdiStoreSearch,
  mdiAccountSearch,
  mdiEmailOutline,
  mdiAccountClock,
  mdiBookCogOutline,
} from '@mdi/js';
import React from 'react';

type SvgIconComponentType = React.ComponentType<SvgIconProps>;

/**
 * A high-order function for applying custom `sx` styles to Material-UI `SvgIcon` components.
 *
 * This function enhances a given SVG icon component by wrapping it and applying a predefined set
 * of styles via the `sx` prop. The primary purpose is to ensure consistent sizing across various
 * SVG icons by setting a uniform width and height. Additionally, it supports merging any further
 * `sx` styles passed to the resulting component, allowing for flexible and extended customization
 * while maintaining the base styling.
 *
 * @param {SvgIconComponentType} IconComponent - The SVG icon component to be enhanced. It must be
 * a component type that accepts Material-UI's `SvgIconProps`.
 *
 * @returns {React.FC<SvgIconProps>} A functional component that renders the `IconComponent` with
 * the enhanced `sx` styling. This component accepts all standard `SvgIconProps`, including the
 * ability to pass additional `sx` properties for further styling adjustments.
 *
 * Usage:
 * To apply the custom `sx` styles to an SVG icon, wrap the icon component using `withCustomSx`
 * and then use the resulting component in your UI. For example:
 *
 * @example
 * const MyCustomIcon = withCustomSx(MySvgIcon);
 *
 * // In your component
 * <MyCustomIcon sx={{ color: 'primary.main' }} />
 * ```
 */
const withCustomSx = (
  IconComponent: SvgIconComponentType,
): React.FC<SvgIconProps> => {
  return (props: SvgIconProps) => (
    <IconComponent
      {...props}
      sx={{
        width: 22.85,
        height: 22.85,
        ...props.sx, // Allows for additional sx props to be merged
      }}
    />
  );
};

/**
 * Arrow Downward icon
 * @param style
 * @param props
 * @constructor
 */
export const ArrowDownwardIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiArrowDownThick} />
    </SvgIcon>
  );
};

/**
 * Arrow Right icon
 * @param style
 * @param props
 * @constructor
 */
export const ArrowRight = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiChevronRight} />
    </SvgIcon>
  );
};

/**
 * Arrow Upward icon
 * @param style
 * @param props
 * @constructor
 */
export const ArrowUpwardIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiArrowUpThick} />
    </SvgIcon>
  );
};

/**
 * CellBoard icon
 * @param style
 * @param props
 * @constructor
 */
export const CellBoardIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiViewDashboardOutline} />
    </SvgIcon>
  );
};

/**
 * Check bold icon
 * @param style
 * @param props
 * @constructor
 */
export const CheckBoldIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiCheckBold} />
    </SvgIcon>
  );
};

/**
 * Coatings icon
 * @param style
 * @param props
 * @constructor
 */
export const CoatingsIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiFormatPaint} />
    </SvgIcon>
  );
};

/**
 * Comment icon
 * @param style
 * @param props
 * @constructor
 */
export const CommentIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiMessageText} />
    </SvgIcon>
  );
};

/**
 * Configurations icon
 * @param props
 * @constructor
 */
export const ConfigurationsIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiCogs} />
    </SvgIcon>
  );
};

/**
 * Multiple people outlined icon
 * @param style
 * @param props
 * @constructor
 */
export const CrewingIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiAccountGroupOutline} />
    </SvgIcon>
  );
};

/**
 * Multiple people with checkmark outlined icon
 * @param style
 * @param props
 * @constructor
 */
export const CrewingGuideXRefIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiAccountMultipleCheckOutline} />
    </SvgIcon>
  );
};

/**
 * Crewing icon
 * @param style
 * @param props
 * @constructor
 */
export const CrewingGuideIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiAccountMultipleOutline} />
    </SvgIcon>
  );
};

/**
 * Crewing Over icon
 * @param style
 * @param props
 * @constructor
 */
export const CrewingOverIcon = ({ style, ...props }: any) => {
  return (
    <>
      <CrewingIcon {...props} style={style} />
      <ArrowUpwardIcon
        {...props}
        style={{
          ...style,
          fontSize: '0.75rem',
          position: 'absolute',
          transform: 'translate(18px, -2px)',
          alignSelf: 'start',
        }}
      />
    </>
  );
};

/**
 * Crewing Under icon
 * @param style
 * @param props
 * @constructor
 */
export const CrewingUnderIcon = ({ style, ...props }: any) => {
  return (
    <>
      <CrewingIcon {...props} style={style} />
      <ArrowDownwardIcon
        {...props}
        style={{
          ...style,
          fontSize: '0.75rem',
          position: 'absolute',
          transform: 'translate(18px, -2px)',
          alignSelf: 'start',
        }}
      />
    </>
  );
};

/**
 * Dashboard icon
 * @param style
 * @param props
 * @constructor
 */
export const DashboardIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiViewDashboardVariantOutline} />
    </SvgIcon>
  );
};

/**
 * Power Bi Dashboard icon
 * @param style
 * @param props
 * @constructor
 */
export const powerBiDashboardIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiViewDashboardVariant} />
    </SvgIcon>
  );
};

/**
 * Demand Management icon
 * @param props
 * @constructor
 */
export const DemandManagementIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiDomain} />
    </SvgIcon>
  );
};

/**
 * Job Station icon
 * @param style
 * @param props
 * @constructor
 */
export const JobStationIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiDesktopClassic} />
    </SvgIcon>
  );
};

/**
 * Job Station Assignment icon
 * @param style
 * @param props
 * @constructor
 */
export const JobStationAssignmentIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiClipboardAccountOutline} />
    </SvgIcon>
  );
};

/**
 * Dev icon
 * @param style
 * @param props
 * @constructor
 */
export const DevIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiCodeTags} />
    </SvgIcon>
  );
};

/**
 * Dot icon
 * @param style
 * @param props
 * @constructor
 */
export const DotIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiRecord} />
    </SvgIcon>
  );
};

/**
 * Issue icon
 * @param style
 * @param props
 * @constructor
 */
export const IssueIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiClipboardAlertOutline} />
    </SvgIcon>
  );
};

/**
 * Factory Automation icon
 * @param props
 * @constructor
 */
export const FactoryAutomationIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiRobotIndustrial} />
    </SvgIcon>
  );
};

/**
 * Format List Checks Icon
 * @param props
 * @constructor
 */
export const FormatListChecksIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiFormatListChecks} />
    </SvgIcon>
  );
};

/**
 * Help icon
 * @param props
 * @constructor
 */
export const HelpIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiHelp} />
    </SvgIcon>
  );
};

/**
 * Home Icon
 * @param props
 * @constructor
 */
export const HomeIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiHomeOutline} />
    </SvgIcon>
  );
};

/**
 * Inbox Icon
 * @param props
 * @constructor
 */
export const InboxIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiInbox} />
    </SvgIcon>
  );
};

/**
 * Key icon
 * @param props
 * @constructor
 */
export const KeyIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiKeyOutline} />
    </SvgIcon>
  );
};

/**
 * MCell Alert icon
 * @param style
 * @param props
 * @constructor
 */
export const MCellAlertIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiAlert} />
    </SvgIcon>
  );
};

/**
 * MCellCollection icon
 * @param style
 * @param props
 * @constructor
 */
export const MCellCollectionIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiGoogleCirclesCommunities} />
    </SvgIcon>
  );
};

/**
 * MCell Sort Sequence Icon
 * @param style
 * @param props
 * @constructor
 */
export const MCellSortSequenceIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiSortNumericVariant} />
    </SvgIcon>
  );
};

/**
 * Message icon
 * @param style
 * @param props
 * @constructor
 */
export const MessageIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiEmail} />
    </SvgIcon>
  );
};

/**
 * Notifications icon
 * @param style
 * @param props
 * @constructor
 */
export const NotificationsIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiBell} />
    </SvgIcon>
  );
};

/**
 * Operations icon
 * @param props
 * @constructor
 */
export const OperationsIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiFactory} />
    </SvgIcon>
  );
};

/**
 * Overhead Door Close icon
 * @param props
 * @constructor
 */
export const OverheadDoorCloseIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiGarageVariant} />
    </SvgIcon>
  );
};

/**
 * Overhead Door Open icon
 * @param props
 * @constructor
 */
export const OverheadDoorOpenIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiGarageOpenVariant} />
    </SvgIcon>
  );
};

/**
 * Image icon
 * @param style
 * @param props
 * @constructor
 */
export const ImageIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiFileImagePlus} />
    </SvgIcon>
  );
};

/**
 * PersonAndClockIcon
 * @param style
 * @param props
 * @constructor
 */
export const PersonAndClockIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiAccountClockOutline} />
    </SvgIcon>
  );
};

/**
 * ApplicationEditIcon
 * @param style
 * @param props
 * @constructor
 */
export const ApplicationEditIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiApplicationEditOutline} />
    </SvgIcon>
  );
};

/**
 * Mail icon
 * @param style
 * @param props
 * @constructor
 */
export const MailIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiMail} />
    </SvgIcon>
  );
};

/**
 * Email icon
 * @param style
 * @param props
 * @constructor
 */
export const EmailIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiEmailOutline} />
    </SvgIcon>
  );
};

/**
 * CellLoginIcon component
 * @param style - The styles to be applied to the SVG icon.
 * @param props - The properties to be passed to the SVG icon.
 */
export const CellLoginIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiAccountClock} />
    </SvgIcon>
  );
};

/**
 * Poll icon
 * @param style
 * @param props
 * @constructor
 */
export const PollIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiPoll} />
    </SvgIcon>
  );
};

/**
 * Report icon
 * @param style
 * @param props
 * @constructor
 */
export const ReportIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiFileDocumentOutline} />
    </SvgIcon>
  );
};

/**
 * Resource Tracking icon
 * @param style
 * @param props
 * @constructor
 */
export const ResourceTrackingIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiAccountOutline} />
    </SvgIcon>
  );
};

/**
 * Scoreboard icon
 * @param style
 * @param props
 * @constructor
 */
export const ScoreboardIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiScoreboard} />
    </SvgIcon>
  );
};

/**
 * Scorecard icon
 * @param style
 * @param props
 * @constructor
 */
export const ScorecardIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiCardMultiple} />
    </SvgIcon>
  );
};

/**
 * Settings icon
 * @param style
 * @param props
 * @constructor
 */
export const SettingsIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiCogOutline} />
    </SvgIcon>
  );
};

/**
 * Support Hierarchy icon
 * @param props
 * @constructor
 */
export const SupportHierarchyIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiFamilyTree} />
    </SvgIcon>
  );
};

/**
 * Scheduling Tool Icon
 * @param style
 * @param props
 * @constructor
 */
export const SchedulingToolIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiCalendarArrowRight} />
    </SvgIcon>
  );
};

/**
 * Time Table icon
 * @param props
 * @constructor
 */
export const TimeTableIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiTimetable} />
    </SvgIcon>
  );
};

/**
 * Work Order Icon
 * @param props
 * @constructor
 */
export const SalesOrderIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiClipboardListOutline} />
    </SvgIcon>
  );
};

/**
 * Change Log Icon
 * @param props
 * @constructor
 */
export const ChangeLogIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiFolderArrowLeftRight} />
    </SvgIcon>
  );
};

/**
 * Employees Icon
 * @param props
 * @constructor
 */
export const EmployeesIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiAccountHardHatOutline} />
    </SvgIcon>
  );
};

/**
 * Labor What If Icon
 * @param props
 * @constructor
 */
export const LaborWhatIfIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiAccountQuestionOutline} />
    </SvgIcon>
  );
};

/**
 * Refresh Icon
 * @constructor
 */
export const RefreshIcon = () => {
  return (
    <SvgIcon>
      <path d={mdiRefresh} />
    </SvgIcon>
  );
};

/**
 * Filter Blank Icon
 * @constructor
 */
export const FilterBlankIcon = withCustomSx((props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d={mdiTable} />
  </SvgIcon>
));

/**
 * Filter Not Blank Icon
 */
export const FilterNotBlankIcon = withCustomSx((props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d={mdiTableOff} />
  </SvgIcon>
));

/**
 * Calendar Icon
 * @constructor
 */
export const CalendarIcon = () => {
  return (
    <SvgIcon>
      <path d={mdiCalendarRange} />
    </SvgIcon>
  );
};

/**
 * Filter Clear Icon
 * @constructor
 */
export const FilterClearIcon = withCustomSx((props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d={mdiFilterOff} />
  </SvgIcon>
));

/**
 * Filter Contains Icon
 * @constructor
 */
export const FilterContainsIcon = withCustomSx((props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d={mdiContain} />
  </SvgIcon>
));

/**
 * Order Search Icon
 * @constructor
 */
export const OrderSearchIcon = () => {
  return (
    <SvgIcon>
      <path d={mdiStoreSearch} />
    </SvgIcon>
  );
};

/**
 * Filter Within Range Icon
 * @constructor
 */
export const FilterWithinRangeIcon = () => {
  return (
    <SvgIcon>
      <path d={mdiArrowExpandHorizontal} />
    </SvgIcon>
  );
};

/**
 * Minus Icon
 * @constructor
 */
export const MinusIcon = () => {
  return (
    <SvgIcon>
      <path d={mdiMinus} />
    </SvgIcon>
  );
};

/**
 * Employee Search Icon
 * @constructor
 */
export const EmployeeSearchIcon = () => {
  return (
    <SvgIcon>
      <path d={mdiAccountSearch} />
    </SvgIcon>
  );
};

/**
 * LSW Icon (Leader Standard Work)
 * @constructor
 */
export const LswIcon = () => {
  return (
    <SvgIcon>
      <path d={mdiClipboardListOutline} />
    </SvgIcon>
  );
};

/**
 * LMS Icon (Learning Management System)
 * @constructor
 */
export const LmsIcon = () => {
  return (
    <SvgIcon>
      <path d={mdiBookCogOutline} />
    </SvgIcon>
  );
};
