import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { logout } from '../../auth';
import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { connectionStateToColor } from '../app/utils';
import useFullscreen from '../../utils/fullscreen';
import { RootState } from '../../store';
import { MoreIcon } from './icons';
import { useNavigation } from '../../utils/navigation';

const MoreMenu = () => {
  const { t } = useTranslation();
  const [isFullscreen, setIsFullscreen] = useFullscreen();
  const connectionState = useSelector(
    (state: RootState) => state.app.connectionState,
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { navigateToSettings } = useNavigation();

  /**
   * Called when the menu button is clicked. Opens the Menu.
   * @param event
   */
  const handleMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  /**
   * Called when user clicks away from the menu. Closes the menu
   */
  const handleClose = () => setAnchorEl(null);

  /**
   * Called when the Logout menu item is clicked
   */
  const handleLogOut = () => {
    handleClose();
    logout();
  };

  /**
   * Called when the Setting menu item is clicked
   */
  const handleProfile = () => {
    handleClose();
    navigateToSettings();
  };

  /**
   * Called when the Fullscreen and Exit Fullscreen menu item is clicked.
   * Puts the application in fullscreen/normal mode
   */
  const handleFullscreen = () => {
    handleClose();
    if (isFullscreen) {
      setIsFullscreen(false);
    } else {
      setIsFullscreen(true);
    }
  };

  const connectionColor = connectionStateToColor(connectionState);

  return (
    <>
      <IconButton onClick={handleMenu} size='large'>
        <Avatar
          sx={{
            color: 'white',
            bgcolor: 'secondary.main',
            boxShadow: `0 0 0 3px ${connectionColor}`,
          }}
        >
          <MoreIcon />
        </Avatar>
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ top: 40 }}
      >
        <MenuItem onClick={handleFullscreen}>
          {isFullscreen ? t('exitFullscreen') : t('fullscreen')}
        </MenuItem>
        <MenuItem onClick={handleProfile}>{t('settings')}</MenuItem>
        <MenuItem onClick={handleLogOut}>{t('logOut')}</MenuItem>
      </Menu>
    </>
  );
};

export default MoreMenu;
