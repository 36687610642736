import axiosInstance from '../../api';
import { HelpLinkDto, HelpLinkCreate, HelpLinkUpdate } from './types';

/**
 * API call to delete an existing help link
 * @param id The ID of the help link to delete
 */
export const deleteHelpLink = async (id: string): Promise<string> => {
  const response = await axiosInstance.delete(`/api/App/helpLink/${id}`);
  return response.data;
};

/**
 * API call to create a new help link
 * @param helpLinkCreate The data for creating the help link
 */
export const createHelpLink = async (
  helpLinkCreate: HelpLinkCreate,
): Promise<HelpLinkDto> => {
  const response = await axiosInstance.post<HelpLinkDto>(
    '/api/App/helpLink',
    helpLinkCreate,
  );
  return response.data;
};

/**
 * API call to get help links
 */
export const getHelpLinks = async (): Promise<Array<HelpLinkDto>> => {
  const response =
    await axiosInstance.get<Array<HelpLinkDto>>('/api/App/helpLink');
  return response.data;
};

/**
 * API call to update an existing help link
 * @param params
 */
export const updateHelpLink = async (
  params: HelpLinkUpdate & { id: string },
): Promise<HelpLinkDto> => {
  const { id, ...info } = params;
  const response = await axiosInstance.post(`/api/App/helpLink/${id}`, info);
  return response.data;
};
