import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { usePersistedState, useQueryParams } from '../../app/utils';
import {
  CELL_BOARD_INVENTORY_CENTER_ID,
  CELL_BOARD_M_CELL_COLLECTION_ID,
  CELL_BOARD_M_CELL_ID,
  CELL_BOARD_SHIFT_SELECTED,
  UserSettingKey,
} from '../../profile/types';
import ShiftSelect from '../../resourceTracking/components/ShiftSelect';
import LocationChip from '../../settings/components/LocationChip';
import Page from '../../common/Page';
import { EMPTY_GUID, isEmptyOrSpaces } from '../../../utils/string-utils';
import {
  ManufacturingLocationShift,
  Shift,
} from '../../resourceTracking/types';
import { ManufacturingLocation } from '../types';
import ManufacturingLocationValidationContainer from './ManufacturingLocationValidationContainer';
import ManufacturingLocationSelectionDialog from './ManufacturingLocationSelectionDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

const PREFIX = 'MCellShiftContainerPage';

const styles = {
  shiftSelect: `${PREFIX}-shiftSelect`,
  mCellRequiredView: `${PREFIX}-mCellRequiredView`,
};

const StyledPage = styled(Page)(({ theme }) => ({
  flex: 1,

  [`& .${styles.shiftSelect}`]: {
    marginRight: theme.spacing(2),
    width: 150,
  },

  [`& .${styles.mCellRequiredView}`]: {
    padding: theme.spacing(3),
  },
}));

type Props = {
  title: string;
  loading: boolean;
  allowRollupCells?: boolean;
  allowMCellCollection?: boolean;
  allowInventoryCenter?: boolean;
  shiftSettingKey?: UserSettingKey;
  mCellIdSettingKey?: UserSettingKey;
  inventoryCenterIdSettingKey?: UserSettingKey;
  mCellCollectionIdSettingKey?: UserSettingKey;
  children: ({
    mCellId,
    inventoryCenterId,
    mCellCollectionId,
    shift,
  }: ManufacturingLocationShift) => ReactNode;
  className?: string;
  primaryActionComponent?: ReactNode;
  secondaryActionComponent?: ReactNode;
  disableShift?: boolean;
  enableActiveShiftOption?: boolean;
  currentlyActiveShift?: Shift;
  onChange?: (v: ManufacturingLocationShift) => void;
};

/**
 * Container that handles Shift and Location selection and renders content
 * @param title
 * @param loading
 * @param mCellIdSettingKey
 * @param shiftSettingKey
 * @param inventoryCenterIdSettingKey
 * @param mCellCollectionIdSettingKey
 * @param includeRollupCells
 * @param children
 * @param className
 * @param disableShift
 * @param enableActiveShiftOption
 * @param currentlyActiveShift
 * @param onChange
 * @constructor
 */
const MCellShiftContainerPage = ({
  title,
  loading,
  mCellIdSettingKey,
  shiftSettingKey,
  inventoryCenterIdSettingKey,
  mCellCollectionIdSettingKey,
  allowRollupCells,
  allowMCellCollection,
  allowInventoryCenter,
  children,
  className,
  primaryActionComponent,
  secondaryActionComponent,
  disableShift,
  enableActiveShiftOption,
  currentlyActiveShift,
  onChange,
}: Props) => {
  const {
    mCellId: mCellIdParam,
    inventoryCenterId: inventoryCenterIdParam,
    mCellCollectionId: mCellCollectionIdParam,
    shift: shiftParam,
  }: {
    mCellId?: string;
    inventoryCenterId?: string;
    mCellCollectionId?: string;
    shift?: Shift;
  } = useQueryParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [inventoryCenterIdSetting, setInventoryCenterIdSetting] =
    usePersistedState<string>(
      inventoryCenterIdSettingKey
        ? inventoryCenterIdSettingKey
        : CELL_BOARD_INVENTORY_CENTER_ID,
      EMPTY_GUID,
    );
  const [mCellIdSetting, setMCellIdSetting] = usePersistedState<string>(
    mCellIdSettingKey ? mCellIdSettingKey : CELL_BOARD_M_CELL_ID,
    EMPTY_GUID,
  );
  const [mCellCollectionIdSetting, setMCellCollectionIdSetting] =
    usePersistedState<string>(
      mCellCollectionIdSettingKey
        ? mCellCollectionIdSettingKey
        : CELL_BOARD_M_CELL_COLLECTION_ID,
      EMPTY_GUID,
    );
  const [shiftSetting, setShiftSetting] = usePersistedState<Shift>(
    shiftSettingKey ? shiftSettingKey : CELL_BOARD_SHIFT_SELECTED,
    'Day',
  );
  const mCellId = mCellIdParam ?? mCellIdSetting;
  const inventoryCenterId = inventoryCenterIdParam ?? inventoryCenterIdSetting;
  const mCellCollectionId = mCellCollectionIdParam ?? mCellCollectionIdSetting;
  const shift = shiftParam ?? shiftSetting;
  const [
    isManufacturingLocationDialogOpen,
    setIsManufacturingLocationDialogOpen,
  ] = useState(false);

  /**
   * Update the settings when user navigates with query params
   */
  useEffect(() => {
    if (
      !isEmptyOrSpaces(mCellIdParam) ||
      !isEmptyOrSpaces(inventoryCenterIdParam) ||
      !isEmptyOrSpaces(mCellCollectionIdParam) ||
      !isEmptyOrSpaces(shiftParam)
    ) {
      setInventoryCenterIdSetting(inventoryCenterIdParam ?? EMPTY_GUID);
      setMCellIdSetting(mCellIdParam ?? EMPTY_GUID);
      setMCellCollectionIdSetting(mCellCollectionIdParam ?? EMPTY_GUID);
      setShiftSetting(shiftParam ?? 'Day');
    }
  }, [
    mCellIdParam,
    inventoryCenterIdParam,
    mCellCollectionIdParam,
    shiftParam,
    setMCellIdSetting,
    setInventoryCenterIdSetting,
    setMCellCollectionIdSetting,
    setShiftSetting,
  ]);

  /**
   * Notifies parent when settings change
   */
  const changeCallback = useCallback(
    (v: ManufacturingLocationShift) => {
      if (onChange) {
        onChange(v);
      }
    },
    [onChange],
  );

  /**
   * Effect to trigger callback when settings change
   */
  useEffect(() => {
    changeCallback({ inventoryCenterId, mCellId, mCellCollectionId, shift });
  }, [inventoryCenterId, mCellId, mCellCollectionId, shift, changeCallback]);

  /**
   * Called when user confirms Manufacturing Location. Dispatches call to update
   * manufacturing location setting in the backend
   * @param manufacturingLocation
   */
  function handleConfirmManufacturingLocation(
    manufacturingLocation: ManufacturingLocation,
  ) {
    navigateWithQueryParams({
      mCellId: manufacturingLocation.mCellId,
      inventoryCenterId: manufacturingLocation.inventoryCenterId,
      mCellCollectionId: manufacturingLocation.mCellCollectionId,
      shift: shiftSetting,
    });
    setIsManufacturingLocationDialogOpen(false);
  }

  /**
   * Sets URL query parameters based on changes to
   * Manufacturing Location Shift
   * @param mCellId
   * @param inventoryCenterId
   * @param mCellCollectionId
   * @param shift
   */
  function navigateWithQueryParams({
    mCellId,
    inventoryCenterId,
    mCellCollectionId,
    shift,
  }: ManufacturingLocationShift) {
    navigate(
      location.pathname +
        qs.stringify(
          {
            mCellId,
            inventoryCenterId,
            mCellCollectionId,
            shift,
          },
          { addQueryPrefix: true },
        ),
    );
  }

  /**
   * Sets shift URL query parameter on changes to shift
   * @param newShift
   */
  function onChangeShift(newShift: Shift) {
    navigateWithQueryParams({
      mCellId,
      inventoryCenterId,
      mCellCollectionId,
      shift: newShift,
    });
  }

  return (
    <StyledPage
      title={title}
      loading={loading}
      className={className}
      actionComponent={
        <>
          {secondaryActionComponent}
          {!disableShift && (
            <ShiftSelect
              className={styles.shiftSelect}
              value={shift}
              onChange={onChangeShift}
              enableActiveShiftOption={enableActiveShiftOption}
              activeShift={currentlyActiveShift}
            />
          )}
          <LocationChip
            onClick={() => setIsManufacturingLocationDialogOpen(true)}
            inventoryCenterId={inventoryCenterId}
            mCellId={mCellId}
            mCellCollectionId={mCellCollectionId}
            includeRollupCells={allowRollupCells}
          />
          {primaryActionComponent}
        </>
      }
    >
      <ManufacturingLocationValidationContainer
        className={styles.mCellRequiredView}
        manufacturingLocation={{
          inventoryCenterId,
          mCellId,
          mCellCollectionId,
        }}
        onManufacturingLocationChange={handleConfirmManufacturingLocation}
        allowRollupCells={allowRollupCells}
        allowMCellCollection={allowMCellCollection}
        allowInventoryCenter={allowInventoryCenter}
      >
        {children({ mCellId, inventoryCenterId, shift, mCellCollectionId })}
      </ManufacturingLocationValidationContainer>
      <ManufacturingLocationSelectionDialog
        initialManufacturingLocation={{
          mCellId,
          inventoryCenterId,
          mCellCollectionId,
        }}
        open={isManufacturingLocationDialogOpen}
        onConfirm={handleConfirmManufacturingLocation}
        onCancel={() => setIsManufacturingLocationDialogOpen(false)}
        confirmDisabled={loading}
        disableMCellCollection={!allowMCellCollection}
        includeRollupCells={allowRollupCells}
      />
    </StyledPage>
  );
};

export default MCellShiftContainerPage;
