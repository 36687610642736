import React from 'react';
import { IPermission } from '../../auth/permissions';

export const formatColors = ['None', 'Green', 'Yellow', 'Red'] as const;

export type FormatColor = (typeof formatColors)[number];

export const badgeScanSelections = ['badgeId', 'employeeId'] as const;

export type BadgeScanSelection = (typeof badgeScanSelections)[number];

export type TabConfig = {
  key: string;
  label: string;
  permission?: IPermission | IPermission[];
  component: JSX.Element;
  extraCheck?: () => boolean;
};

export type UseTabManagerOutput = {
  activeTabKey: string | undefined;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
  filteredTabs: TabConfig[];
  renderTabContent: () => JSX.Element;
};

export type BarData = {
  x: string;
  y: number;
  tooltip: string;
  color?: string;
};

export type LineData = {
  x: string;
  y: number;
};
