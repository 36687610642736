import { mdiLinkVariant, mdiFileLinkOutline } from '@mdi/js';
import { SvgIcon } from '@mui/material';
import React from 'react';

/**
 * Link icon
 * @param style
 * @param props
 * @constructor
 */
export const LinkIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiLinkVariant} />
    </SvgIcon>
  );
};

/**
 * File Link icon
 * @param style
 * @param props
 * @constructor
 */
export const FileLinkIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiFileLinkOutline} />
    </SvgIcon>
  );
};
