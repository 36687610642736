import { SvgIcon } from '@mui/material';
import {
  mdiPaperclip,
  mdiPlus,
  mdiChevronDown,
  mdiDelete,
  mdiHelp,
  mdiPencil,
  mdiClipboardCheck,
  mdiCheckboxOutline,
  mdiCheckboxBlankOutline,
  mdiEmail,
  mdiCommentPlus,
  mdiChevronRight,
} from '@mdi/js';
import React from 'react';

/**
 * Add icon
 * @param style
 * @param props
 * @constructor
 */
export const AddIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiPlus} />
    </SvgIcon>
  );
};

/**
 * Add notes icon
 * @param style
 * @param props
 * @constructor
 */
export const AddNotesIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiCommentPlus} />
    </SvgIcon>
  );
};

/**
 * Arrow right icon
 * @param style
 * @param props
 * @constructor
 */
export const ArrowRight = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiChevronRight} />
    </SvgIcon>
  );
};

/**
 * Attach file icon
 * @param style
 * @param props
 * @constructor
 */
export const AttachFileIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiPaperclip} />
    </SvgIcon>
  );
};

/**
 * Checkbox checked icon
 * @param style
 * @param props
 * @constructor
 */
export const CheckBoxOutlinedIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiCheckboxOutline} />
    </SvgIcon>
  );
};

/**
 * Checkbox unchecked icon
 * @param style
 * @param props
 * @constructor
 */
export const CheckBoxOutlineBlankIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiCheckboxBlankOutline} />
    </SvgIcon>
  );
};

/**
 * Delete icon
 * @param style
 * @param props
 * @constructor
 */
export const DeleteIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiDelete} />
    </SvgIcon>
  );
};

/**
 * Edit icon
 * @param style
 * @param props
 * @constructor
 */
export const EditIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiPencil} />
    </SvgIcon>
  );
};

/**
 * Expand more icon
 * @param style
 * @param props
 * @constructor
 */
export const ExpandMoreIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiChevronDown} />
    </SvgIcon>
  );
};

/**
 * Help icon
 * @param props
 * @constructor
 */
export const HelpIcon = ({ ...props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d={mdiHelp} />
    </SvgIcon>
  );
};

/**
 * Message icon
 * @param style
 * @param props
 * @constructor
 */
export const MessageIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiEmail} />
    </SvgIcon>
  );
};

/**
 * Task icon
 * @param style
 * @param props
 * @constructor
 */
export const TaskIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiClipboardCheck} />
    </SvgIcon>
  );
};
