import {
  CoatingsProfileAttachment,
  CoatingsPaint,
  CoatingsPartMaterial,
  CoatingsProfile,
  CoatingsProfileAttachmentClassification,
  CoatingsProfileFilter,
  CoatingsProfileInfo,
  CoatingsProfileAlertNoteInfo,
  CoatingsProfilePalletStackingNoteInfo,
  CoatingsProfileBarcodeFirstPassIdInfo,
  CoatingsProfileBarcodeSecondPassIdInfo,
  CoatingsProfileAutoSetupBarcodeIdInfo,
} from './types';
import axiosInstance from '../../api';

/**
 * API call to get list of Coatings Profiles that match filter criteria
 * @param filter
 */
export const getProfiles = async (
  filter: CoatingsProfileFilter,
): Promise<CoatingsProfile[]> => {
  const response = await axiosInstance.get('/api/coatings/profiles', {
    params: filter,
  });
  return response.data;
};

/**
 * API call to get a Coatings Profile by id
 * @param id
 * @param inventoryCenterId
 */
export const getProfileById = async ({
  id,
  inventoryCenterId,
}: {
  id: string;
  inventoryCenterId?: string;
}): Promise<CoatingsProfile> => {
  const response = await axiosInstance.get(`/api/Coatings/profiles/${id}`, {
    params: { inventoryCenterId },
  });
  return response.data;
};

/**
 * API call to get list of Coatings Paints
 * @param material
 */
export const getPaints = async (
  material?: CoatingsPartMaterial,
): Promise<CoatingsPaint[]> => {
  const response = await axiosInstance.get('/api/coatings/paints', {
    params: { material },
  });
  return response.data;
};

/**
 * API call to get Coatings Profile Attachments with urls
 * @param ids
 */
export const getProfileAttachmentUrls = async (
  ids: string[],
): Promise<CoatingsProfileAttachment[]> => {
  const response = await axiosInstance.get(
    `/api/Coatings/profileAttachmentUrls`,
    {
      params: { ids },
    },
  );
  return response.data;
};

/**
 * API call to create or update a Coatings Profile
 * @param info
 */
export const createOrUpdateProfile = async (
  info: CoatingsProfileInfo,
): Promise<CoatingsProfile> => {
  const response = await axiosInstance.post(`/api/Coatings/profiles`, info);
  return response.data;
};

/**
 * API call to update Coatings Profile info
 * @param params
 */
export const updateProfile = async (params: {
  id: string;
  info: CoatingsProfileInfo;
}): Promise<CoatingsProfile> => {
  const response = await axiosInstance.post(
    `/api/Coatings/profiles/${params.id}`,
    params.info,
  );
  return response.data;
};
/**
 * API call to deactivate a Coatings Profile
 * @param id
 */
export const deactivateProfile = async (id: string) => {
  await axiosInstance.delete(`/api/Coatings/profiles/${id}`);
};

/**
 * API call to add or update Coatings Profile Attachments
 * @param params
 */
export const addOrUpdateProfileAttachments = async (params: {
  profileId: string;
  files: File[];
  classifications: CoatingsProfileAttachmentClassification[];
  inventoryCenterIds: string[];
}): Promise<CoatingsProfile> => {
  const formData = new FormData();
  params.files.forEach((f) => {
    formData.append('files', f);
  });
  const response = await axiosInstance.post(
    `/api/Coatings/profiles/${params.profileId}/attachments`,
    formData,
    {
      params: {
        classifications: params.classifications,
        inventoryCenterIds: params.inventoryCenterIds,
      },
    },
  );
  return response.data;
};

/**
 * API call to add or update Coatings Profile Alert Notes
 * @param params - Object containing profile ID and array of alert note info
 * @returns A promise that resolves to the updated CoatingsProfile object
 */
export const addOrUpdateProfileAlertNotes = async (params: {
  profileId: string;
  infos: CoatingsProfileAlertNoteInfo[];
}): Promise<CoatingsProfile> => {
  const { profileId, infos } = params;
  const response = await axiosInstance.post(
    `/api/coatings/profiles/${profileId}/alertnotes`,
    infos,
  );
  return response.data;
};

/**
 * API call to add or update Coatings Profile Pallet Stacking Notes
 * @param params - Object containing profile ID and array of pallet stacking note info
 * @returns A promise that resolves to the updated CoatingsProfile object
 */
export const addOrUpdateProfilePalletStackingNotes = async (params: {
  profileId: string;
  infos: CoatingsProfilePalletStackingNoteInfo[];
}): Promise<CoatingsProfile> => {
  const { profileId, infos } = params;
  const response = await axiosInstance.post(
    `/api/coatings/profiles/${profileId}/palletstackingnotes`,
    infos,
  );
  return response.data;
};

/**
 * API call to add or update Coatings Profile Barcode First Pass IDs
 * @param params - Object containing profile ID and array of barcode first pass ID info
 * @returns A promise that resolves to the updated CoatingsProfile object
 */
export const addOrUpdateProfileBarcodeFirstPassIds = async (params: {
  profileId: string;
  infos: CoatingsProfileBarcodeFirstPassIdInfo[];
}): Promise<CoatingsProfile> => {
  const { profileId, infos } = params;
  const response = await axiosInstance.post(
    `/api/coatings/profiles/${profileId}/barcodefirstpassids`,
    infos,
  );
  return response.data;
};

/**
 * API call to add or update Coatings Profile Barcode Second Pass IDs
 * @param params - Object containing profile ID and array of barcode second pass ID info
 * @returns A promise that resolves to the updated CoatingsProfile object
 */
export const addOrUpdateProfileBarcodeSecondPassIds = async (params: {
  profileId: string;
  infos: CoatingsProfileBarcodeSecondPassIdInfo[];
}): Promise<CoatingsProfile> => {
  const { profileId, infos } = params;
  const response = await axiosInstance.post(
    `/api/coatings/profiles/${profileId}/barcodesecondpassids`,
    infos,
  );
  return response.data;
};

/**
 * API call to add or update Coatings Profile Auto Setup Barcode IDs
 * @param params - Object containing profile ID and array of auto setup barcode ID info
 * @returns A promise that resolves to the updated CoatingsProfile object
 */
export const addOrUpdateProfileAutoSetupBarcodeIds = async (params: {
  profileId: string;
  infos: CoatingsProfileAutoSetupBarcodeIdInfo[];
}): Promise<CoatingsProfile> => {
  const { profileId, infos } = params;
  const response = await axiosInstance.post(
    `/api/coatings/profiles/${profileId}/autosetupbarcodeids`,
    infos,
  );
  return response.data;
};

/**
 * API call to delete an attachment from Coatings Profile
 * @param id
 */
export const deleteAttachmentFromProfile = async (id: string) => {
  await axiosInstance.delete(`/api/Coatings/profileAttachments/${id}`);
};
