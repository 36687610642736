import axiosInstance from '../../api';
import {
  CcWidgetCreate,
  CcWidgetDataResult,
  CcWidgetMeta,
  CcWidgetMove,
  Dashboard,
  DashboardCreate,
  WidgetType,
} from './types';

/**
 * API call to get list of dashboards
 * @param inventoryCenterId
 */
export const getDashboards = async (
  inventoryCenterId: string
): Promise<Array<Dashboard>> => {
  const response = await axiosInstance.get<Array<Dashboard>>(
    '/api/Dashboard/dashboard',
    {
      params: { inventoryCenterId },
    }
  );
  return response.data;
};

/**
 * API call to query widget data
 * @param query
 */
export const getWidgetData = async (
  query: CcWidgetMeta
): Promise<CcWidgetDataResult> => {
  const response = await axiosInstance.post<CcWidgetDataResult>(
    '/api/Dashboard/widgetData',
    query
  );
  return response.data;
};

/**
 * API call to create a new dashboard for the logged in user
 * @param dashboardCreate
 */
export const createDashboard = async (
  dashboardCreate: DashboardCreate
): Promise<Dashboard> => {
  const response = await axiosInstance.post<Dashboard>(
    '/api/Dashboard/dashboard',
    dashboardCreate
  );
  return response.data;
};

/**
 * API call to get a dashboard by id
 * @param dashboardId
 */
export const getDashboardById = async (
  dashboardId: string
): Promise<Dashboard> => {
  const response = await axiosInstance.get<Dashboard>(
    `/api/Dashboard/dashboard/${dashboardId}`
  );
  return response.data;
};

/**
 * API call to delete an existing dashboard
 * @param dashboardId
 */
export const deleteDashboard = async (dashboardId: string) => {
  await axiosInstance.delete(`/api/Dashboard/dashboard/${dashboardId}`);
};

/**
 * API call to add widgets to a dashboard
 * @param params
 */
export const addWidgetsToDashboard = async (params: {
  dashboardId: string;
  widgetCreates: Array<CcWidgetCreate>;
}): Promise<Dashboard> => {
  const response = await axiosInstance.post<Dashboard>(
    `/api/Dashboard/dashboard/${params.dashboardId}/addWidgets`,
    params.widgetCreates
  );
  return response.data;
};

/**
 * API call to remove widgets from a dashboard
 * @param params
 */
export const removeWidgetsFromDashboard = async (params: {
  dashboardId: string;
  widgetIds: Array<string>;
}): Promise<Dashboard> => {
  const response = await axiosInstance.post<Dashboard>(
    `/api/Dashboard/dashboard/${params.dashboardId}/removeWidgets`,
    params.widgetIds
  );
  return response.data;
};

/**
 * API call to set column layout of a dashboard. Valid options are:
 * `1`, `1-1`, `1-2`, `2-1`, `1-1-1`
 * @param dashboardId
 * @param newLayout
 */
export const setDashboardLayout = async (
  dashboardId: string,
  newLayout: string
): Promise<Dashboard> => {
  const response = await axiosInstance.post<Dashboard>(
    `/api/Dashboard/dashboard/${dashboardId}/setLayout`,
    JSON.stringify(newLayout)
  );
  return response.data;
};

/**
 * API call to move widget from one position to another within a dashboard
 * @param dashboardId
 * @param widgetMove
 */
export const moveWidget = async (
  dashboardId: string,
  widgetMove: CcWidgetMove
): Promise<Dashboard> => {
  const response = await axiosInstance.post<Dashboard>(
    `/api/Dashboard/dashboard/${dashboardId}/moveWidget`,
    widgetMove
  );
  return response.data;
};

/**
 * API call to set title of a dashboard
 * @param params
 */
export const renameDashboard = async (params: {
  dashboardId: string;
  newName: string;
}): Promise<Dashboard> => {
  const response = await axiosInstance.post<Dashboard>(
    `/api/Dashboard/dashboard/${params.dashboardId}/rename`,
    JSON.stringify(params.newName)
  );
  return response.data;
};

/**
 * API call to get list of widget types
 */
export const getWidgetTypes = async (): Promise<Array<WidgetType>> => {
  const response = await axiosInstance.get<Array<WidgetType>>(
    '/api/Dashboard/widgetType'
  );
  return response.data;
};

/**
 * API call to set title of a widget
 * @param params
 */
export const renameWidget = async (params: {
  dashboardId: string;
  widgetId: string;
  newName: string;
}): Promise<Dashboard> => {
  const response = await axiosInstance.post<Dashboard>(
    `/api/Dashboard/dashboard/${params.dashboardId}/widget/${params.widgetId}/rename`,
    JSON.stringify(params.newName)
  );
  return response.data;
};

/**
 * API call to save widget meta data
 * @param params
 */
export const setWidgetMeta = async (params: {
  dashboardId: string;
  widgetId: string;
  meta: CcWidgetMeta;
}): Promise<Dashboard> => {
  const response = await axiosInstance.post<Dashboard>(
    `/api/Dashboard/dashboard/${params.dashboardId}/widget/${params.widgetId}/meta`,
    params.meta
  );
  return response.data;
};

/**
 * API call to set Dashboard to private
 * @param id
 */
export const setDashboardToPrivate = async (id: string): Promise<Dashboard> => {
  const response = await axiosInstance.post(
    `/api/Dashboard/dashboard/${id}/setToPrivate`
  );
  return response.data;
};

/**
 * API call to set Dashboard to global
 * @param id
 */
export const setDashboardToGlobal = async (id: string): Promise<Dashboard> => {
  const response = await axiosInstance.post(
    `/api/Dashboard/dashboard/${id}/setToGlobal`
  );
  return response.data;
};

/**
 * API call to copy global Dashboard to private
 * @param id
 */
export const copyDashboardToPrivate = async (
  id: string
): Promise<Dashboard> => {
  const response = await axiosInstance.post(
    `/api/Dashboard/dashboard/${id}/copyToPrivate`
  );
  return response.data;
};

/**
 * API call to add a shared User to Dashboard
 * @param params
 */
export const addSharedUser = async (params: {
  id: string;
  userId: string;
}): Promise<Dashboard> => {
  const response = await axiosInstance.post(
    `/api/Dashboard/dashboard/${params.id}/addSharedUser`,
    JSON.stringify(params.userId)
  );
  return response.data;
};

/**
 * API call to remove shared User from Dashboard
 * @param params
 */
export const removeSharedUser = async (params: {
  id: string;
  userId: string;
}): Promise<Dashboard> => {
  const response = await axiosInstance.post(
    `/api/Dashboard/dashboard/${params.id}/removeSharedUser`,
    JSON.stringify(params.userId)
  );
  return response.data;
};

/**
 * API call to leave a shared Dashboard
 * @param id
 */
export const leaveShare = async (id: string): Promise<Dashboard> => {
  const response = await axiosInstance.post(
    `/api/Dashboard/dashboard/${id}/leaveShare`
  );
  return response.data;
};
