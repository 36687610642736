import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../api/app.types';
import { createAsyncThunkWithError } from '../../redux/utils';
import {
  DowntimeEscalationAssignment,
  Downtime,
  DowntimeReason,
  DowntimeReasonSupportGroupMapping,
  DowntimeReasonSupportGroupMappingInfo,
  DowntimeReasonSupportGroupMappingSupportGroup,
} from './types';
import { getUserSettingsThunk } from '../profile/profileSlice';
import {
  addComment,
  changeSeverity,
  createDowntime,
  createDowntimeReason,
  createDowntimeReasonSupportGroupMapping,
  deleteDowntime,
  deleteDowntimeReason,
  deleteDowntimeReasonSupportGroupMapping,
  endDowntime,
  getCurrentDowntimes,
  getDowntimeById,
  getDowntimeReasons,
  getDowntimeReasonSupportGroupMappings,
  getDowntimes,
  getEscalationAssignments,
  markHelpArrived,
  updateDowntimeReasonById,
  requestHelp,
  updateDowntimeReason,
  updateDowntimeReasonSupportGroupMapping,
} from './api';
import { RootState } from '../../store';
import { setManufacturingLocationsThunk } from '../manufacturingLocation/manufacturingLocationSlice';
import { selectManufacturingLocation } from '../profile/utils';

/**
 * Action to set whether Issues are currently being loaded
 */
export const downtimeStatusAction = createAction<LoadingStatus>(
  'downtime/downtimeStatusAction',
);

/**
 * Action to set whether current Issues are currently being loaded
 */
export const downtimeStatusCurrentAction = createAction<LoadingStatus>(
  'downtime/downtimeStatusCurrentAction',
);

/**
 * Action to set list of all Issues
 */
export const setAllDowntimesAction = createAction<Array<Downtime>>(
  'downtime/setAllDowntimesAction',
);

/**
 * Action to set current Issue being displayed
 */
export const setCurrentDowntimeAction = createAction<Downtime>(
  'downtime/setCurrentDowntimeAction',
);

/**
 * Action to add an Issue to the top of the list of Issues
 */
export const addDowntimeAction = createAction<Downtime>(
  'downtime/addDowntimeAction',
);

/**
 * Action to update an Issue in the list of Issues
 */
export const updateDowntimeAction = createAction<Downtime>(
  'downtime/updateDowntimeAction',
);

/**
 * Action to delete an Issue from the list of Issues
 */
export const deleteDowntimeAction = createAction<string>(
  'downtime/deleteDowntimeAction',
);

/**
 * Action to set list of Issue Reasons
 */
export const setDowntimeReasonsAction = createAction<Array<DowntimeReason>>(
  'downtime/setDowntimeReasonsAction',
);

/**
 * Action to set whether Issue Reasons are currently being loaded
 */
export const reasonsStatusAction = createAction<LoadingStatus>(
  'downtime/reasonsStatusAction',
);

/**
 * Action to add an Issue Reason to the list of Issue Reasons
 */
export const addDowntimeReasonAction = createAction<DowntimeReason>(
  'downtime/addDowntimeReasonAction',
);

/**
 * Action to update an Issue Reason in the list of Issue Reasons
 */
export const updateDowntimeReasonAction = createAction<DowntimeReason>(
  'downtime/updateDowntimeReasonAction',
);

/**
 * Action to delete an Issue Reason from the list of Issue Reasons
 */
export const deleteDowntimeReasonAction = createAction<string>(
  'downtime/deleteDowntimeReasonAction',
);

/**
 * Action to set list of Issue Reason Support Group Mappings
 */
export const setDowntimeReasonSupportGroupMappingsAction = createAction<
  Array<DowntimeReasonSupportGroupMapping>
>('downtime/setDowntimeReasonSupportGroupMappingsAction');

/**
 * Action to set whether Issue Reason Support Group Mappings are currently being loaded
 */
export const reasonSupportGroupMappingsStatusAction =
  createAction<LoadingStatus>(
    'downtime/reasonSupportGroupMappingsStatusAction',
  );

/**
 * Action to add an Issue Reason Support Group Mapping to the list of Issue Reason Support Group Mappings
 */
export const addDowntimeReasonSupportGroupMappingAction =
  createAction<DowntimeReasonSupportGroupMapping>(
    'downtime/addDowntimeReasonSupportGroupMappingAction',
  );

/**
 * Action to update an Issue Reason Support Group Mapping in the list of Issue Reason Support Group Mappings
 */
export const updateDowntimeReasonSupportGroupMappingAction =
  createAction<DowntimeReasonSupportGroupMapping>(
    'downtime/updateDowntimeReasonSupportGroupMappingAction',
  );

/**
 * Action to delete an Issue Reason Support Group Mapping from the list of Issue Reason Support Group Mappings
 */
export const deleteDowntimeReasonSupportGroupMappingAction =
  createAction<string>(
    'downtime/deleteDowntimeReasonSupportGroupMappingAction',
  );

/**
 * Action to set Issue Escalation Assignments
 */
export const setEscalationAssignmentsAction =
  createAction<DowntimeEscalationAssignment>(
    'downtime/setEscalationAssignmentsAction',
  );

/**
 * Action to set whether Issue Escalation Assignments are currently being loaded
 */
export const escalationAssignmentsStatusAction = createAction<LoadingStatus>(
  'downtime/escalationAssignmentsStatusAction',
);

/**
 * Thunk that fetches and sets all Issues
 */
export const getDowntimesThunk = createAsyncThunkWithError(
  'downtime/getDowntimesThunk',
  async (
    fetchSettings: boolean,
    { dispatch, getState },
  ): Promise<Downtime[]> => {
    const state = getState() as RootState;
    if (fetchSettings) {
      // get settings
      await dispatch(getUserSettingsThunk() as any);
      await dispatch(setManufacturingLocationsThunk() as any);
    }
    const { mCellCollectionId, inventoryCenterId, mCellId } =
      selectManufacturingLocation(state);
    const currentDowntimes = await getCurrentDowntimes(
      mCellId,
      inventoryCenterId,
      mCellCollectionId,
    );
    dispatch(setAllDowntimesAction(currentDowntimes));
    dispatch(downtimeStatusCurrentAction('succeeded'));
    return await getDowntimes(mCellId, inventoryCenterId, mCellCollectionId);
  },
  'errorFetchingIssues',
);

/**
 * Thunk to fetch current Issue and save it in the store
 */
export const getDowntimeDetailThunk = createAsyncThunkWithError(
  'downtime/getDowntimeDetailThunk',
  getDowntimeById,
  'errorFetchingIssueDetail',
);

/**
 * Thunk that calls API to create new Issue
 */
export const createDowntimeThunk = createAsyncThunkWithError(
  'downtime/createDowntimeThunk',
  createDowntime,
  'errorCreatingIssue',
);

/**
 * Thunk that calls API to request help on an Issue
 * (Currently not used as all issues are created with help requested)
 */
export const requestHelpThunk = createAsyncThunkWithError(
  'downtime/requestHelpThunk',
  requestHelp,
  'errorRequestingHelp',
);

/**
 * Thunk that calls API to mark Issue with help arrived.
 */
export const markHelpArrivedThunk = createAsyncThunkWithError(
  'downtime/markHelpArrivedThunk',
  markHelpArrived,
  'errorMarkingHelpArrived',
);

/**
 * Thunk that calls API to end an Issue
 */
export const endDowntimeThunk = createAsyncThunkWithError(
  'downtime/endDowntimeThunk',
  endDowntime,
  'errorEndingIssue',
);

/**
 * Thunk that calls API to delete an Issue
 */
export const deleteDowntimeThunk = createAsyncThunkWithError(
  'downtime/deleteDowntimeThunk',
  deleteDowntime,
  'errorDeletingIssue',
);

/**
 * Thunk that calls API to change severity of an Issue
 */
export const changeSeverityThunk = createAsyncThunkWithError(
  'downtime/changeSeverityThunk',
  changeSeverity,
  'errorChangingSeverity',
);

/**
 * Thunk that calls API to update reason on an Issue
 */
export const updateDowntimeReasonThunk = createAsyncThunkWithError(
  'downtime/updateDowntimeReasonThunk',
  updateDowntimeReason,
  'errorUpdatingIssueReason',
);

/**
 * Thunk that calls API to add a comment to an Issue
 */
export const addCommentThunk = createAsyncThunkWithError(
  'downtime/addCommentThunk',
  addComment,
  'errorAddingComment',
);

/**
 * Thunk for fetching Issue Reasons and saving them to the store
 */
export const getDowntimeReasonsThunk = createAsyncThunkWithError(
  'downtime/getDowntimeReasonsThunk',
  getDowntimeReasons,
  'errorFetchingIssueReasons',
);

/**
 * Thunk that calls API to create new Issue Reason
 */
export const createDowntimeReasonThunk = createAsyncThunkWithError(
  'downtime/createDowntimeReasonThunk',
  createDowntimeReason,
  'errorCreatingIssueReason',
);

/**
 * Thunk that calls API to rename an Issue Reason
 */
export const updateDowntimeReasonByIdThunk = createAsyncThunkWithError(
  'downtime/updateDowntimeReasonByIdThunk',
  updateDowntimeReasonById,
  'errorUpdatingIssueReason',
);

/**
 * Thunk that calls API to delete an Issue Reason
 */
export const deleteDowntimeReasonThunk = createAsyncThunkWithError(
  'downtime/deleteDowntimeReasonThunk',
  deleteDowntimeReason,
  'errorDeletingIssueReason',
);

/**
 * Thunk for fetching Issue Reason Support Group Mappings and saving them to the store
 */
export const getDowntimeReasonSupportGroupMappingsThunk =
  createAsyncThunkWithError(
    'downtime/getDowntimeReasonSupportGroupMappingsThunk',
    getDowntimeReasonSupportGroupMappings,
    'errorFetchingIssueReasonSupportGroupMappings',
  );

/**
 * Thunk that calls API to create new Issue Reason Support Group Mapping
 */
export const createDowntimeReasonSupportGroupMappingThunk =
  createAsyncThunkWithError(
    'downtime/createDowntimeReasonSupportGroupMappingThunk',
    async (
      downtimeReasonSupportGroupMappingInfo: DowntimeReasonSupportGroupMappingInfo,
    ) => {
      await createDowntimeReasonSupportGroupMapping({
        downtimeReasonId:
          downtimeReasonSupportGroupMappingInfo.downtimeReasonId,
        ownerSupportGroupId:
          downtimeReasonSupportGroupMappingInfo.ownerSupportGroupId,
        watcherSupportGroupIds:
          downtimeReasonSupportGroupMappingInfo.watcherSupportGroupJoins?.map(
            (w) => w.supportGroupId,
          ),
      });
    },
    'errorCreatingIssueReasonSupportGroupMapping',
  );

/**
 * Thunk for updating Issue Reason Support Group Mapping on server while optimistically
 * updating the local store
 */
export const updateDowntimeReasonSupportGroupMappingThunk =
  createAsyncThunkWithError(
    'downtime/updateDowntimeReasonSupportGroupMappingThunk',
    async (
      param: {
        id: string;
        downtimeReasonSupportGroupMappingInfo: DowntimeReasonSupportGroupMappingInfo;
      },
      { dispatch },
    ) => {
      dispatch(
        updateDowntimeReasonSupportGroupMappingAction({
          id: param.id,
          ...param.downtimeReasonSupportGroupMappingInfo,
        }),
      );
      await updateDowntimeReasonSupportGroupMapping(param.id, {
        ownerSupportGroupId:
          param.downtimeReasonSupportGroupMappingInfo.ownerSupportGroupId,
        watcherSupportGroupIds:
          param.downtimeReasonSupportGroupMappingInfo.watcherSupportGroupJoins?.map(
            (w: DowntimeReasonSupportGroupMappingSupportGroup) =>
              w.supportGroupId,
          ),
      });
    },
    'errorUpdatingIssueReasonSupportGroupMapping',
  );

/**
 * Thunk for deleting Issue Reason Support Group Mapping
 */
export const deleteDowntimeReasonSupportGroupMappingThunk =
  createAsyncThunkWithError(
    'downtime/deleteDowntimeReasonSupportGroupMappingThunk',
    deleteDowntimeReasonSupportGroupMapping,
    'errorDeletingIssueReasonSupportGroupMapping',
  );

/**
 * Thunk that calls API to get EscalationAssignments
 */
export const getEscalationAssignmentsThunk = createAsyncThunkWithError(
  'downtime/getEscalationAssignmentsThunk',
  getEscalationAssignments,
  'errorFetchingIssueEscalations',
);

export type DowntimeState = {
  downtimesStatus: LoadingStatus;
  downtimeStatusCurrent: LoadingStatus;
  downtimes: Array<Downtime>;
  downtimeDetail: Downtime | null;
  reasons: Array<DowntimeReason>;
  reasonsStatus: LoadingStatus;
  reasonSupportGroupMappings: Array<DowntimeReasonSupportGroupMapping>;
  reasonSupportGroupMappingsStatus: LoadingStatus;
  escalationAssignments: DowntimeEscalationAssignment[];
  escalationAssignmentsStatus: LoadingStatus;
};

export const initialState: DowntimeState = {
  downtimesStatus: 'idle',
  downtimeStatusCurrent: 'idle',
  downtimes: [],
  downtimeDetail: null,
  reasons: [],
  reasonsStatus: 'idle',
  reasonSupportGroupMappings: [],
  reasonSupportGroupMappingsStatus: 'idle',
  escalationAssignments: [],
  escalationAssignmentsStatus: 'idle',
};

export const downtimeSliceReducer = createSlice({
  name: 'downtime',
  initialState,
  reducers: {
    downtimeStatusAction: (state, action) => {
      state.downtimesStatus = action.payload;
    },
    downtimeStatusCurrentAction: (state, action) => {
      state.downtimeStatusCurrent = action.payload;
    },
    setAllDowntimesAction: (state, action) => {
      state.downtimes = action.payload;
    },
    setCurrentDowntimeAction: (state, action) => {
      state.downtimeDetail = action.payload;
    },
    addDowntimeAction: (state, action) => {
      state.downtimes.push(action.payload);
    },
    updateDowntimeAction: (state, action) => {
      const updateIndex = state.downtimes.findIndex(
        (d) => d.id === action.payload.id,
      );
      if (updateIndex > -1) {
        state.downtimes[updateIndex] = action.payload;
      } else {
        state.downtimes.push(action.payload);
      }
    },
    deleteDowntimeAction: (state, action) => {
      const deleteIndex = state.downtimes.findIndex(
        (d) => d.id === action.payload,
      );
      if (deleteIndex > -1) {
        state.downtimes.splice(deleteIndex, 1);
      }
    },
    setDowntimeReasonsAction: (state, action) => {
      state.reasons = action.payload;
    },
    reasonsStatusAction: (state, action) => {
      state.reasonsStatus = action.payload;
    },
    addDowntimeReasonAction: (state, action) => {
      state.reasons.push(action.payload);
    },
    updateDowntimeReasonAction: (state, action) => {
      const updateIndex = state.reasons.findIndex(
        (r) => r.id === action.payload.id,
      );
      if (updateIndex > -1) {
        state.reasons[updateIndex] = action.payload;
      } else {
        state.reasons.push(action.payload);
      }
    },
    deleteDowntimeReasonAction: (state, action) => {
      const deleteIndex = state.reasons.findIndex(
        (r) => r.id === action.payload,
      );
      if (deleteIndex > -1) {
        state.reasons.splice(deleteIndex, 1);
      }
    },
    setDowntimeReasonSupportGroupMappingsAction: (state, action) => {
      state.reasonSupportGroupMappings = action.payload;
    },
    reasonSupportGroupMappingStatusAction: (state, action) => {
      state.reasonSupportGroupMappingsStatus = action.payload;
    },
    addDowntimeReasonSupportGroupMappingAction: (state, action) => {
      state.reasonSupportGroupMappings.push(action.payload);
    },
    updateDowntimeReasonSupportGroupMappingAction: (state, action) => {
      const updateIndex = state.reasonSupportGroupMappings.findIndex(
        (r) => r.id === action.payload.id,
      );
      if (updateIndex > -1) {
        state.reasonSupportGroupMappings[updateIndex] = action.payload;
      } else {
        state.reasonSupportGroupMappings.push(action.payload);
      }
    },
    deleteDowntimeReasonSupportGroupMappingAction: (state, action) => {
      const deleteIndex = state.reasonSupportGroupMappings.findIndex(
        (r) => r.id === action.payload,
      );
      if (deleteIndex > -1) {
        state.reasonSupportGroupMappings.splice(deleteIndex, 1);
      }
    },
    setEscalationAssignmentsAction: (state, action) => {
      state.escalationAssignments = action.payload;
    },
    escalationAssignmentsStatusAction: (state, action) => {
      state.escalationAssignments = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDowntimesThunk.pending, (state) => {
        state.downtimesStatus = 'loading';
        state.downtimeStatusCurrent = 'loading';
      })
      .addCase(getDowntimesThunk.fulfilled, (state, { payload }) => {
        state.downtimesStatus = 'succeeded';
        state.downtimes = payload;
      })
      .addCase(getDowntimesThunk.rejected, (state) => {
        state.downtimesStatus = 'failed';
        state.downtimeStatusCurrent = 'failed';
      })
      .addCase(getDowntimeDetailThunk.pending, (state) => {
        state.downtimesStatus = 'loading';
      })
      .addCase(getDowntimeDetailThunk.fulfilled, (state, { payload }) => {
        state.downtimesStatus = 'succeeded';
        state.downtimeDetail = payload;
      })
      .addCase(getDowntimeDetailThunk.rejected, (state) => {
        state.downtimesStatus = 'failed';
      })
      .addCase(createDowntimeThunk.pending, (state) => {
        state.downtimesStatus = 'loading';
      })
      .addCase(createDowntimeThunk.fulfilled, (state) => {
        state.downtimesStatus = 'succeeded';
      })
      .addCase(createDowntimeThunk.rejected, (state) => {
        state.downtimesStatus = 'failed';
      })
      .addCase(requestHelpThunk.pending, (state) => {
        state.downtimesStatus = 'loading';
      })
      .addCase(requestHelpThunk.fulfilled, (state) => {
        state.downtimesStatus = 'succeeded';
      })
      .addCase(requestHelpThunk.rejected, (state) => {
        state.downtimesStatus = 'failed';
      })
      .addCase(markHelpArrivedThunk.pending, (state) => {
        state.downtimesStatus = 'loading';
      })
      .addCase(markHelpArrivedThunk.fulfilled, (state) => {
        state.downtimesStatus = 'succeeded';
      })
      .addCase(markHelpArrivedThunk.rejected, (state) => {
        state.downtimesStatus = 'failed';
      })
      .addCase(endDowntimeThunk.pending, (state) => {
        state.downtimesStatus = 'loading';
      })
      .addCase(endDowntimeThunk.fulfilled, (state) => {
        state.downtimesStatus = 'succeeded';
      })
      .addCase(endDowntimeThunk.rejected, (state) => {
        state.downtimesStatus = 'failed';
      })
      .addCase(deleteDowntimeThunk.pending, (state) => {
        state.downtimesStatus = 'loading';
      })
      .addCase(deleteDowntimeThunk.fulfilled, (state) => {
        state.downtimesStatus = 'succeeded';
      })
      .addCase(deleteDowntimeThunk.rejected, (state) => {
        state.downtimesStatus = 'failed';
      })
      .addCase(changeSeverityThunk.pending, (state) => {
        state.downtimesStatus = 'loading';
      })
      .addCase(changeSeverityThunk.fulfilled, (state) => {
        state.downtimesStatus = 'succeeded';
      })
      .addCase(changeSeverityThunk.rejected, (state) => {
        state.downtimesStatus = 'failed';
      })
      .addCase(updateDowntimeReasonThunk.pending, (state) => {
        state.reasonsStatus = 'loading';
      })
      .addCase(updateDowntimeReasonThunk.fulfilled, (state) => {
        state.reasonsStatus = 'succeeded';
      })
      .addCase(updateDowntimeReasonThunk.rejected, (state) => {
        state.reasonsStatus = 'failed';
      })
      .addCase(addCommentThunk.pending, (state) => {
        state.downtimesStatus = 'loading';
      })
      .addCase(addCommentThunk.fulfilled, (state) => {
        state.downtimesStatus = 'succeeded';
      })
      .addCase(addCommentThunk.rejected, (state) => {
        state.downtimesStatus = 'failed';
      })
      .addCase(getDowntimeReasonsThunk.pending, (state) => {
        state.reasonsStatus = 'loading';
      })
      .addCase(getDowntimeReasonsThunk.fulfilled, (state, { payload }) => {
        state.reasonsStatus = 'succeeded';
        state.reasons = payload;
      })
      .addCase(getDowntimeReasonsThunk.rejected, (state) => {
        state.reasonsStatus = 'failed';
      })
      .addCase(createDowntimeReasonThunk.pending, (state) => {
        state.reasonsStatus = 'loading';
      })
      .addCase(createDowntimeReasonThunk.fulfilled, (state) => {
        state.reasonsStatus = 'succeeded';
      })
      .addCase(createDowntimeReasonThunk.rejected, (state) => {
        state.reasonsStatus = 'failed';
      })
      .addCase(updateDowntimeReasonByIdThunk.pending, (state) => {
        state.reasonsStatus = 'loading';
      })
      .addCase(updateDowntimeReasonByIdThunk.fulfilled, (state) => {
        state.reasonsStatus = 'succeeded';
      })
      .addCase(updateDowntimeReasonByIdThunk.rejected, (state) => {
        state.reasonsStatus = 'failed';
      })
      .addCase(deleteDowntimeReasonThunk.pending, (state) => {
        state.reasonsStatus = 'loading';
      })
      .addCase(deleteDowntimeReasonThunk.fulfilled, (state) => {
        state.reasonsStatus = 'succeeded';
      })
      .addCase(deleteDowntimeReasonThunk.rejected, (state) => {
        state.reasonsStatus = 'failed';
      })
      .addCase(getDowntimeReasonSupportGroupMappingsThunk.pending, (state) => {
        state.reasonSupportGroupMappingsStatus = 'loading';
      })
      .addCase(
        getDowntimeReasonSupportGroupMappingsThunk.fulfilled,
        (state, { payload }) => {
          state.reasonSupportGroupMappingsStatus = 'succeeded';
          state.reasonSupportGroupMappings = payload;
        },
      )
      .addCase(getDowntimeReasonSupportGroupMappingsThunk.rejected, (state) => {
        state.reasonSupportGroupMappingsStatus = 'failed';
      })
      .addCase(
        createDowntimeReasonSupportGroupMappingThunk.pending,
        (state) => {
          state.reasonSupportGroupMappingsStatus = 'loading';
        },
      )
      .addCase(
        createDowntimeReasonSupportGroupMappingThunk.fulfilled,
        (state) => {
          state.reasonSupportGroupMappingsStatus = 'succeeded';
        },
      )
      .addCase(
        createDowntimeReasonSupportGroupMappingThunk.rejected,
        (state) => {
          state.reasonSupportGroupMappingsStatus = 'failed';
        },
      )
      .addCase(
        updateDowntimeReasonSupportGroupMappingThunk.pending,
        (state) => {
          state.reasonSupportGroupMappingsStatus = 'loading';
        },
      )
      .addCase(
        updateDowntimeReasonSupportGroupMappingThunk.fulfilled,
        (state) => {
          state.reasonSupportGroupMappingsStatus = 'succeeded';
        },
      )
      .addCase(
        updateDowntimeReasonSupportGroupMappingThunk.rejected,
        (state) => {
          state.reasonSupportGroupMappingsStatus = 'failed';
        },
      )
      .addCase(
        deleteDowntimeReasonSupportGroupMappingThunk.pending,
        (state) => {
          state.reasonSupportGroupMappingsStatus = 'loading';
        },
      )
      .addCase(
        deleteDowntimeReasonSupportGroupMappingThunk.fulfilled,
        (state) => {
          state.reasonSupportGroupMappingsStatus = 'succeeded';
        },
      )
      .addCase(
        deleteDowntimeReasonSupportGroupMappingThunk.rejected,
        (state) => {
          state.reasonSupportGroupMappingsStatus = 'failed';
        },
      )
      .addCase(getEscalationAssignmentsThunk.pending, (state) => {
        state.escalationAssignmentsStatus = 'loading';
      })
      .addCase(
        getEscalationAssignmentsThunk.fulfilled,
        (state, { payload }) => {
          state.escalationAssignmentsStatus = 'succeeded';
          state.escalationAssignments = payload;
        },
      )
      .addCase(getEscalationAssignmentsThunk.rejected, (state) => {
        state.escalationAssignmentsStatus = 'failed';
      });
  },
});

/**
 * Selector to determine whether issues or reasons are loading
 * @param state
 */
export const selectLoadingDowntimesOrReasons = (state: RootState) =>
  state.downtime.downtimesStatus === 'loading' ||
  state.downtime.reasonsStatus === 'loading';

/**
 * Selector to determine whether issues are loading
 * @param state
 */
export const selectLoadingDowntimes = (state: RootState) =>
  state.downtime.downtimesStatus === 'loading';

/**
 * Selector to determine whether current issues are loading
 * @param state
 */
export const selectLoadingDowntimesCurrent = (state: RootState) =>
  state.downtime.downtimeStatusCurrent === 'loading';

/**
 * Base selector for reasons
 * @param state
 */
export const selectReasons = (state: RootState) => state.downtime.reasons;

/**
 * Creates a memoized selector that filters downtime reasons by a specific inventory center ID.
 * This selector builds upon the base `selectReasons` selector.
 * @param inventoryCenterId - The ID of the inventory center to filter the reasons by.
 * @returns A memoized selector function that returns filtered downtime reasons.
 */
export const makeSelectReasonsByInventoryCenterId = (
  inventoryCenterId: string,
) =>
  createSelector([selectReasons], (reasons) =>
    reasons.filter((r) => r.inventoryCenterId === inventoryCenterId),
  );

/**
 * Creates a memoized selector that retrieves a specific downtime reason by ID
 * after filtering by inventory center ID.
 * @param {string} inventoryCenterId - The ID of the inventory center to filter the reasons by.
 * @param {string} downtimeReasonId - The ID of the downtime reason to retrieve.
 * @returns A memoized selector function that returns a specific DowntimeReason or undefined.
 */
export const makeSelectDowntimeReasonById = (
  inventoryCenterId: string,
  downtimeReasonId: string,
) => {
  const selectReasonsByInventoryCenter =
    makeSelectReasonsByInventoryCenterId(inventoryCenterId);
  return createSelector([selectReasonsByInventoryCenter], (reasons) =>
    reasons.find((r) => r.id === downtimeReasonId),
  );
};

/**
 * Selector to determine whether reasons are loading
 * @param state
 */
export const selectLoadingReasons = (state: RootState) =>
  state.downtime.reasonsStatus === 'loading';

/**
 * Base selector to select reason support group mappings
 * @param state
 */
export const selectReasonSupportGroupMappings = (state: RootState) =>
  state.downtime.reasonSupportGroupMappings;

/**
 * Creates a memoized selector that filters reason support group mappings by a specific inventory center ID.
 * This selector builds upon the base `selectReasonSupportGroupMappings` selector.
 * @param inventoryCenterId - The ID of the inventory center to filter the mappings by.
 * @returns A memoized selector function that returns filtered reason support group mappings.
 */
export const selectReasonSupportGroupMappingsByInventoryCenterId = (
  inventoryCenterId: string,
) =>
  createSelector(
    [selectReasonSupportGroupMappings],
    (reasonSupportGroupMappings) =>
      reasonSupportGroupMappings.filter(
        (r) => r.downtimeReason.inventoryCenterId === inventoryCenterId,
      ),
  );

/**
 * Creates a memoized selector that filters downtime reasons not mapped to support groups by a specific inventory center ID.
 * This selector filters out downtime reasons that are not associated with any mappings provided as part of the
 * `mappings` array. The result includes only those downtime reasons that are relevant to the given inventory center
 * and are not listed in the `mappings` array by their `downtimeReasonId`.
 *
 * @param {string} inventoryCenterId - The ID of the inventory center used to filter the downtime reasons. This ensures
 * that only downtime reasons associated with this specific center are considered.
 * @param {DowntimeReasonSupportGroupMapping[]} mappings - An array of mappings that associate downtime reasons
 * with support groups. This is used to exclude reasons that are already mapped.
 * @returns Returns a memoized selector function that computes and returns an array of downtime reasons
 * that are associated with the specified inventory center ID but not mapped to any support group in the provided mappings.
 */
export const selectDowntimeReasonsNotMapped = (
  inventoryCenterId: string,
  mappings: DowntimeReasonSupportGroupMapping[],
): ((state: RootState) => DowntimeReason[]) =>
  createSelector([selectReasons], (reasons) =>
    reasons.filter(
      (reason) =>
        reason.inventoryCenterId === inventoryCenterId &&
        !mappings.map((m) => m.downtimeReasonId).includes(reason.id),
    ),
  );

/**
 * Selector to determine whether reason support group mappings are loading
 * @param state
 */
export const selectLoadingReasonSupportGroupMappings = (state: RootState) =>
  state.downtime.reasonSupportGroupMappingsStatus === 'loading';

/**
 * Selector to determine whether escalation assignments are loading
 * @param state
 */
export const selectLoadingEscalationAssignments = (state: RootState) =>
  state.downtime.escalationAssignmentsStatus === 'loading';

/**
 * Selector to select Reasons
 * @param state
 */
export const selectDowntimeReasonIds = (state: RootState) =>
  state.downtime.reasons;

export default downtimeSliceReducer.reducer;
