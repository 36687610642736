import { BadgeScanSelection, badgeScanSelections, FormatColor } from './types';
import { green, orange, red } from '@mui/material/colors';
import { TFunction } from 'i18next';
import { Sorting } from '@devexpress/dx-react-grid';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { styled } from '@mui/material/styles';

/**
 * Gets the css color for a conditional format color. Returns undefined if None.
 * @param color
 */
export const conditionalFormatColorToColor = (
  color: FormatColor | undefined
): string | undefined => {
  switch (color) {
    case 'Green':
      return green[500];
    case 'Yellow':
      return orange[500];
    case 'Red':
      return red[500];
    default:
      return undefined;
  }
};

/**
 * Gets the css contrast color for a condition format color.
 * @param formatColor
 */
export const conditionalFormatContrastColor = (
  formatColor: FormatColor | undefined
): string => {
  return formatColor === 'Red' || formatColor === 'Green' ? 'white' : 'inherit';
};

/**
 * Converts a badge scan selection to it corresponding string key
 * @param badgeScanSelection
 */
export const badgeScanSelectionToStringKey = (
  badgeScanSelection: BadgeScanSelection
) => {
  switch (badgeScanSelection) {
    case 'badgeId':
      return 'badgeId';
    case 'employeeId':
      return 'employeeId';
  }
};

export type ScanSelection = {
  label: string;
  value: BadgeScanSelection;
};

/**
 * Gets options for Badge Scan selections
 * @param t
 */
export const getBadgeScanSelections = (t: TFunction): ScanSelection[] => {
  return badgeScanSelections.map((x) => ({
    label: t(badgeScanSelectionToStringKey(x)),
    value: x,
  }));
};

/**
 * Url of sharepoint document that contains help document .
 */
export const TABLE_HELP_URL =
  'https://marvinonline.sharepoint.com/:w:/s/RapidSolutionsTeam-Warroad/EUqc0xwZpRpGsNaMINEb3bQBZGrtRCh9NZOhcbkO74pNtw';

/**
 * Sorts an array of data objects based on a specified sorting criteria.
 *
 * This generic function is designed to sort any array of objects (`data`) according to
 * one or more sorting conditions provided in the `sorting` array. Each sorting condition
 * specifies the column name to sort by and the direction (ascending or descending).
 * The `getColumnValue` function is used to extract the value from a data object based on
 * the column name, allowing for custom value retrieval logic (e.g., handling nested properties).
 *
 * The function supports sorting by strings, numbers, and booleans. String comparisons are
 * performed using locale-sensitive comparison, numbers are sorted numerically, and booleans
 * are converted to numbers for comparison. If the `sorting` array contains multiple conditions,
 * the data is sorted in the order those conditions are specified, similar to SQL ORDER BY clause.
 *
 * @template TDataSource The data type of the objects in the data array. It must be an object type.
 * @param {TDataSource[]} data The array of data objects to sort.
 * @param {Sorting[]} sorting An array of sorting conditions, where each condition specifies
 *        the column name (`columnName`) and the direction (`asc` for ascending, `desc` for descending).
 * @param {(row: TDataSource, columnName: string) => any} getColumnValue A function that retrieves
 *        the value from a data object for a given column name. This allows for custom logic in value retrieval.
 * @returns {TDataSource[]} A new array containing the sorted data objects. The original data array is not mutated.
 */
export const sortData = <TDataSource extends object>(
  data: TDataSource[],
  sorting: Sorting[],
  getColumnValue: (row: TDataSource, columnName: string) => any
): TDataSource[] => {
  const sortedData = [...data];
  const compareRows = (a: TDataSource, b: TDataSource) => {
    for (const sort of sorting) {
      const { columnName, direction } = sort;

      const valueA = getColumnValue(a, columnName);
      const valueB = getColumnValue(b, columnName);

      let comparisonResult = 0;
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        comparisonResult = valueA.localeCompare(valueB);
      } else if (typeof valueA == 'number' && typeof valueB === 'number') {
        comparisonResult = valueA - valueB;
      } else if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
        comparisonResult = Number(valueA) - Number(valueB);
      }

      if (comparisonResult !== 0) {
        return direction === 'asc' ? comparisonResult : -comparisonResult;
      }
    }

    return 0;
  };
  sortedData.sort(compareRows);
  return sortedData;
};

/**
 * Styled component for TableFilterRow Cell
 */
export const StyledTableFilterRowCell = styled(TableFilterRow.Cell)({
  height: '46.84px',
});
