import axiosInstance from '../../api';
import {
  Email,
  DevMCellLoginInfo,
  DevMCellLogoutInfo,
  MCellLoginLoggedInReport,
} from './types';
import { ManufacturingLocation } from '../manufacturingLocation/types';

/**
 * Sends an email using the provided email object.
 *
 * @param {Email} email - The email object containing the necessary information.
 */
export const sendEmail = async (email: Email) => {
  await axiosInstance.post('/api/Developer/sendEmail', email);
};

/**
 * API call to create manual MCell logout
 * @remarks (Development use only)
 * @param manualMCellLogout
 */
export const createDevMCellLogout = async (
  manualMCellLogout: DevMCellLogoutInfo,
): Promise<DevMCellLoginInfo> => {
  const response = await axiosInstance.post(
    `/api/MCellEmployee/devLogout`,
    manualMCellLogout,
  );
  return response.data;
};

/**
 * API call to create manual MCell Login
 * @remarks (Development use only)
 * @param manualMCellLogin
 */
export const createDevMCellLogin = async (
  manualMCellLogin: DevMCellLoginInfo,
): Promise<DevMCellLoginInfo> => {
  const response = await axiosInstance.post(
    `/api/MCellEmployee/devLogin`,
    manualMCellLogin,
  );
  return response.data;
};

/**
 * Get manual MCell Login Logged In Report
 * @param filter
 */
export const getMCellLoginLoggedInReport = async (
  filter: ManufacturingLocation,
): Promise<MCellLoginLoggedInReport[]> => {
  const response = await axiosInstance.get(
    '/api/MCellEmployee/mCellLoginLoggedInReport',
    {
      params: filter,
    },
  );
  return response.data;
};
