import axiosInstance from '../../api';
import { Asset, AssetCreate, AssetInfoUpdate, AssetQuery } from './types';

/**
 * Api call to create a new Asset
 * @param assetCreate
 */
export const createAsset = async (assetCreate: AssetCreate): Promise<Asset> => {
  const response = await axiosInstance.post('/api/Machine/asset', assetCreate);
  return response.data;
};

/**
 * Api call to update all Asset fields (except assetId)
 * @param assetInfoUpdate
 */
export const updateAsset = async (
  assetInfoUpdate: AssetInfoUpdate
): Promise<Asset> => {
  const response = await axiosInstance.post(
    `/api/Machine/asset/${assetInfoUpdate.id}`,
    assetInfoUpdate
  );
  return response.data;
};

/**
 * API call to get a list of Assets that match filter criteria
 * @param filter
 */
export const getAssets = async (filter: AssetQuery): Promise<Asset[]> => {
  const response = await axiosInstance.get('/api/Machine/asset', {
    params: filter,
  });
  return response.data;
};

/**
 * Api call to delete an Asset by id
 * @param id
 */
export const deleteAsset = async (id: string): Promise<string> => {
  const response = await axiosInstance.delete(`/api/Machine/asset/${id}`);
  return response.data;
};
