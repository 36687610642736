import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunkWithError } from '../../redux/utils';
import {
  sendEmail,
  createDevMCellLogin,
  createDevMCellLogout,
  getMCellLoginLoggedInReport,
} from './api';
import { LoadingStatus } from '../../api/app.types';
import { RootState } from '../../store';
import { MCellLoginLoggedInReport } from './types';

export type DevState = {
  sendEmailStatus: LoadingStatus;
  devMCellLoginStatus: LoadingStatus;
  devMCellLogoutStatus: LoadingStatus;
  mCellEmployeesLoginLoggedIn: MCellLoginLoggedInReport[];
  mCellEmployeesLoginLoggedInStatus: LoadingStatus;
};

export const initialState: DevState = {
  sendEmailStatus: 'idle',
  devMCellLoginStatus: 'idle',
  devMCellLogoutStatus: 'idle',
  mCellEmployeesLoginLoggedIn: [],
  mCellEmployeesLoginLoggedInStatus: 'idle',
};

/**
 * Thunk for sending email
 */
export const sendEmailThunk = createAsyncThunkWithError(
  'dev/sendEmailThunk',
  sendEmail,
  'errorSendingEmail',
);

/**
 * Thunk for Manual MCell Login
 * @remarks (Development use only)
 */
export const createManualMCellLoginThunk = createAsyncThunkWithError(
  'mCellEmployee/createManualMCellLoginThunk',
  createDevMCellLogin,
  'errorCreatingManualMCellLoginThunk',
);

/**
 * Thunk for Manual MCell Logout
 * @remarks (Development use only)
 */
export const createManualMCellLogoutThunk = createAsyncThunkWithError(
  'mCellEmployee/createManualMCellLogoutThunk',
  createDevMCellLogout,
  'errorCreatingManualMCellLogoutThunk',
);

/**
 * Thunk for fetching mCell Login LoggedIn MCell Employee Report
 */
export const getMCellLoginLoggedInReportThunk = createAsyncThunkWithError(
  'mCellEmployee/getMCellLoginLoggedInReportThunk',
  getMCellLoginLoggedInReport,
  'errorFetchingMCellLoginLoggedInReport',
);

const devSlice = createSlice({
  name: 'dev',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendEmailThunk.pending, (state) => {
        state.sendEmailStatus = 'loading';
      })
      .addCase(sendEmailThunk.fulfilled, (state) => {
        state.sendEmailStatus = 'succeeded';
      })
      .addCase(sendEmailThunk.rejected, (state) => {
        state.sendEmailStatus = 'failed';
      })
      .addCase(createManualMCellLoginThunk.pending, (state) => {
        state.devMCellLoginStatus = 'loading';
      })
      .addCase(createManualMCellLoginThunk.fulfilled, (state) => {
        state.devMCellLoginStatus = 'succeeded';
      })
      .addCase(createManualMCellLoginThunk.rejected, (state) => {
        state.devMCellLoginStatus = 'failed';
      })
      .addCase(createManualMCellLogoutThunk.pending, (state) => {
        state.devMCellLogoutStatus = 'loading';
      })
      .addCase(createManualMCellLogoutThunk.fulfilled, (state) => {
        state.devMCellLogoutStatus = 'succeeded';
      })
      .addCase(createManualMCellLogoutThunk.rejected, (state) => {
        state.devMCellLogoutStatus = 'failed';
      })
      .addCase(getMCellLoginLoggedInReportThunk.pending, (state) => {
        state.mCellEmployeesLoginLoggedInStatus = 'loading';
      })
      .addCase(
        getMCellLoginLoggedInReportThunk.fulfilled,
        (state, { payload }) => {
          state.mCellEmployeesLoginLoggedInStatus = 'succeeded';
          state.mCellEmployeesLoginLoggedIn = payload;
        },
      )
      .addCase(getMCellLoginLoggedInReportThunk.rejected, (state) => {
        state.mCellEmployeesLoginLoggedInStatus = 'failed';
      });
  },
});

/**
 * Selector function to check if the email sending process is currently loading.
 * @param state
 */
export const selectLoadingSendEmail = (state: RootState) =>
  state.dev.sendEmailStatus === 'loading';

/**
 * Selector to select MCell Login Logged In Employees
 * @param state
 */
export const selectLoginLoggedInReport = (state: RootState) =>
  state.dev.mCellEmployeesLoginLoggedIn;

/**
 * Selector to determine whether MCell Login last updated date is loading
 * @param state
 */
export const selectLoadingLoginLoggedInReport = (state: RootState) =>
  state.dev.mCellEmployeesLoginLoggedInStatus === 'loading';

export default devSlice.reducer;
