import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Page from '../../common/Page';
import { useTranslation } from 'react-i18next';
import {
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
} from '@mui/material';
import BackNext from '../../common/BackNext';
import { EMPTY_GUID, isEmptyOrSpaces } from '../../../utils/string-utils';
import LocationChip from '../../settings/components/LocationChip';
import EnhancedSelect from '../../common/EnhancedSelect';
import ManufacturingLocationSelectionDialog from '../../manufacturingLocation/components/ManufacturingLocationSelectionDialog';
import { ManufacturingLocation } from '../../manufacturingLocation/types';
import FileUpload from '../../common/FileUpload';
import { RootState, useAppDispatch } from '../../../store';
import { unwrapResult } from '@reduxjs/toolkit';
import { useManufacturingLocation } from '../../profile/utils';
import {
  createHomeLinkGroupOptions,
  getHomeLinkOptions,
  HOME_LINK_IMAGE_MAX_FILE_SIZE_BYTES,
  HOME_LINK_MAX_FILE_SIZE_BYTES,
} from '../utils';
import HomeLinkConfigCreateSummary from './HomeLinkConfigCreateSummary';
import { HomeLinkCreate, HomeLinkType } from '../types';
import {
  createHomeLinkThunk,
  getHomeLinkGroupsThunk,
  selectLoadingHomeLinks,
} from '../homeSlice';
import { useNavigation } from '../../../utils/navigation';
import { useSelector } from 'react-redux';
import { getInventoryCenterIdsByManufacturingLocationThunk } from '../../manufacturingLocation/manufacturingLocationSlice';

const PREFIX = 'HomeLinkConfigCreatePage';

const styles = {
  backNext: `${PREFIX}-backNext`,
  titleTextField: `${PREFIX}-titleTextField`,
  linkTextField: `${PREFIX}-linkTextField`,
  fileUpload: `${PREFIX}-fileUpload`,
  error: `${PREFIX}-error`,
  errorSelect: `${PREFIX}-errorSelect`,
};

const StyledPage = styled(Page)(({ theme }) => ({
  [`& .${styles.backNext}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${styles.titleTextField}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${styles.linkTextField}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${styles.fileUpload}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${styles.error}`]: {
    color: 'red',
    fontWeight: 'bold',
  },

  [`& .${styles.errorSelect}`]: {
    borderBottom: '2px solid red',
    border: 'red',
  },
}));

/**
 * Component that renders the wizard for creating links on the Home Page
 * @constructor
 */

const HomeLinkConfigCreatePage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { navigateToHomeConfiguration } = useNavigation();
  const [activeStep, setActiveStep] = useState(0);
  const { inventoryCenter, mCell, mCellCollection } =
    useManufacturingLocation();
  const [mCellId, setMCellId] = useState(mCell ? mCell.id : EMPTY_GUID);
  const [inventoryCenterId, setInventoryCenterId] = useState(
    inventoryCenter ? inventoryCenter.id : EMPTY_GUID,
  );
  const [mCellCollectionId, setMCellCollectionId] = useState(
    mCellCollection ? mCellCollection.id : EMPTY_GUID,
  );
  const [title, setTitle] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const [linkType, setLinkType] = useState<HomeLinkType>('Link');
  const [images, setImages] = useState<File[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [isLocationDialogOpen, setIsLocationDialogOpen] = useState(false);
  const [sortSequence, setSortSequence] = useState<number>(0);
  const [homeLinkGroupId, setHomeLinkGroupId] = useState<string>('');
  const file = files.length > 0 ? files[0] : undefined;
  const image = images.length > 0 ? images[0] : undefined;
  const loading = useSelector(selectLoadingHomeLinks);
  const disableUrlNext = isEmptyOrSpaces(linkUrl);
  const disableFileNext = files.length === 0;
  const disableTitleNext = isEmptyOrSpaces(title);
  const linkTypeOptions = getHomeLinkOptions(t);
  const inputProps = {
    min: 0,
    step: 1,
  };
  const homeLinkGroups = useSelector(
    (state: RootState) => state.home.homeLinkGroups,
  );
  const homeLinkGroupOptions = createHomeLinkGroupOptions(homeLinkGroups, t);
  const groupName = homeLinkGroups.find((s) => s.id === homeLinkGroupId)?.name;

  /**
   * Effect to fetch home link groups
   */
  useEffect(() => {
    const manufacturingLocation: ManufacturingLocation = {
      inventoryCenterId,
      mCellId,
      mCellCollectionId,
    };
    dispatch(
      getInventoryCenterIdsByManufacturingLocationThunk(manufacturingLocation),
    ).then((result) => {
      dispatch(getHomeLinkGroupsThunk(result.payload as string[]));
    });
  }, [dispatch, inventoryCenterId, mCellId, mCellCollectionId]);

  /**
   * Called when a user changes the type of link that will be created
   * @param value
   */
  function handleOptionChange(value: HomeLinkType) {
    setLinkType(value);
  }

  /**
   * Called when the back button on the wizard is clicked. Goes to previous
   * step
   */
  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  /**
   * Called when the next button on the wizard is clicked. Goes to the next
   * step
   */
  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  /**
   * Called when location chip is clicked
   */
  function handleLocationClick() {
    setIsLocationDialogOpen(true);
  }

  /**
   * Called when a user inputs a value for sort sequence
   * @param event
   */
  function handleValueChange(event: any) {
    if (!isNaN(Number(event.target.value))) {
      setSortSequence(Number(event.target.value));
    }
  }

  /**
   * Called when manufacturing location dialog confirmation is clicked.
   * Sets the manufacturing location in the component state and closes
   * the dialog
   * @param manufacturingLocation
   */
  function handleConfirmManufacturingLocation(
    manufacturingLocation: ManufacturingLocation,
  ) {
    setMCellId(manufacturingLocation.mCellId);
    setInventoryCenterId(manufacturingLocation.inventoryCenterId);
    setMCellCollectionId(manufacturingLocation.mCellCollectionId);
    setIsLocationDialogOpen(false);
  }

  /**
   * Called when manufacturing location dialog cancel is clicked.
   * Closes the dialog
   */
  function handleLocationDialogCancel() {
    setIsLocationDialogOpen(false);
  }

  /**
   * Called when user clicks the final save button. Dispatches call
   * to create link on the server
   */
  function handleSaveLink() {
    const homeLinkCreate: HomeLinkCreate = {
      inventoryCenterId,
      mCellId,
      mCellCollectionId,
      title,
      linkType,
      linkUrl,
      file,
      image,
      sortSequence,
      homeLinkGroupId,
    };
    dispatch(createHomeLinkThunk(homeLinkCreate))
      .then(unwrapResult)
      .then(navigateToHomeConfiguration);
  }

  /**
   * Called when a user changes the home link group
   * @param value
   */
  function handleHomeLinkGroupChange(value: string) {
    setHomeLinkGroupId(value);
  }

  return (
    <StyledPage title={t('createHomeLink')} loading={loading}>
      <Stepper
        activeStep={activeStep}
        orientation={'vertical'}
        sx={{ padding: theme.spacing(3) }}
      >
        <Step>
          <StepLabel>{t('audience')}</StepLabel>
          <StepContent>
            <LocationChip
              onClick={handleLocationClick}
              mCellId={mCellId}
              inventoryCenterId={inventoryCenterId}
              mCellCollectionId={mCellCollectionId}
            />
            <BackNext
              onBack={handleBack}
              onNext={handleNext}
              containerClass={styles.backNext}
              backDisabled={true}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{t('title')}</StepLabel>
          <StepContent>
            <TextField
              id={'title'}
              label={t('title')}
              onChange={(event) => setTitle(event.target.value)}
              value={title}
              autoFocus
              autoComplete={'off'}
              variant='standard'
              fullWidth
              required
            />
            <BackNext
              onBack={handleBack}
              onNext={handleNext}
              containerClass={styles.backNext}
              nextDisabled={disableTitleNext}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{t('iconImage')}</StepLabel>
          <StepContent>
            <FileUpload
              initialFiles={images}
              className={styles.fileUpload}
              onChange={setImages}
              maxFileSizeBytes={HOME_LINK_IMAGE_MAX_FILE_SIZE_BYTES}
              accept={
                'image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp'
              }
              single
            />
            <BackNext
              onBack={handleBack}
              onNext={handleNext}
              containerClass={styles.backNext}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{t('linkOrFile')}</StepLabel>
          <StepContent>
            <EnhancedSelect
              label={t('selection')}
              id={'selection'}
              data={linkTypeOptions}
              value={linkType}
              onChange={handleOptionChange}
            />
            {linkType === 'Link' && (
              <>
                <TextField
                  id={'url'}
                  label={t('url')}
                  onChange={(event) => setLinkUrl(event.target.value)}
                  value={linkUrl}
                  autoFocus
                  variant='standard'
                  fullWidth
                  required
                  className={disableUrlNext ? styles.errorSelect : ''}
                />
                {disableUrlNext && (
                  <span className={styles.error}>{t('invalidUrlAddress')}</span>
                )}
                <BackNext
                  onBack={handleBack}
                  onNext={handleNext}
                  containerClass={styles.backNext}
                  nextDisabled={disableUrlNext}
                />
              </>
            )}
            {linkType === 'File' && (
              <>
                <FileUpload
                  initialFiles={files}
                  className={styles.fileUpload}
                  onChange={setFiles}
                  maxFileSizeBytes={HOME_LINK_MAX_FILE_SIZE_BYTES}
                />
                <BackNext
                  onBack={handleBack}
                  onNext={handleNext}
                  containerClass={styles.backNext}
                  nextDisabled={disableFileNext}
                />
              </>
            )}
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{t('sortSequence')}</StepLabel>
          <StepContent>
            <TextField
              type={'number'}
              id={'sortSequence'}
              label={t('sortSequence')}
              onChange={(event) => handleValueChange(event)}
              value={sortSequence}
              inputProps={inputProps}
              autoFocus
              variant='standard'
              fullWidth
              required
            />
            <BackNext
              onBack={handleBack}
              onNext={handleNext}
              containerClass={styles.backNext}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{t('group')}</StepLabel>
          <StepContent>
            <EnhancedSelect
              label={t('selectHomeLinkGroup')}
              id={'homeLinkGroup'}
              data={homeLinkGroupOptions}
              value={homeLinkGroupId}
              onChange={handleHomeLinkGroupChange}
            />
            <BackNext
              onBack={handleBack}
              onNext={handleNext}
              containerClass={styles.backNext}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{t('summary')}</StepLabel>
          <StepContent>
            <HomeLinkConfigCreateSummary
              location={{ inventoryCenterId, mCellId, mCellCollectionId }}
              title={title}
              image={image}
              file={file}
              url={linkUrl}
              sortSequence={sortSequence}
              group={groupName}
            />
            <BackNext
              onBack={handleBack}
              onNext={handleSaveLink}
              containerClass={styles.backNext}
              nextLabel={t('save')}
              nextDisabled={loading}
            />
          </StepContent>
        </Step>
      </Stepper>
      <ManufacturingLocationSelectionDialog
        initialManufacturingLocation={{
          mCellId,
          inventoryCenterId,
          mCellCollectionId,
        }}
        open={isLocationDialogOpen}
        onConfirm={handleConfirmManufacturingLocation}
        onCancel={handleLocationDialogCancel}
      />
    </StyledPage>
  );
};

export default HomeLinkConfigCreatePage;
