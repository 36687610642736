import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../store';
import { getHomeLinksThunk, selectHomeLinks } from '../homeSlice';
import HomeLinksConfigListTable from './HomeLinksConfigListTable';
import { useSelector } from 'react-redux';
import { useNavigation } from '../../../utils/navigation';
import { HomeLinkDto } from '../types';

type Props = {
  mCellId: string;
  inventoryCenterId: string;
  mCellCollectionId: string;
};

/**
 * Component Page for managing Home Link Config List
 * @param mCellId
 * @param inventoryCenterId
 * @param mCellCollectionId
 * @constructor
 */
const HomeLinkConfigListPage = ({
  mCellId,
  inventoryCenterId,
  mCellCollectionId,
}: Props) => {
  const dispatch = useAppDispatch();
  const homeLinks = useSelector(selectHomeLinks);
  const { navigateToHomeLinkDetailEdit } = useNavigation();

  /**
   * Effect to fetch data on page load and when location changes
   */
  useEffect(() => {
    const manufacturingLocation = {
      inventoryCenterId,
      mCellId,
      mCellCollectionId,
    };
    dispatch(getHomeLinksThunk(manufacturingLocation));
  }, [inventoryCenterId, mCellId, mCellCollectionId, dispatch]);

  /**
   * Called when Link row is clicked. Navigates to the Home Link Edit page
   * @param homeLink
   */
  function handleRowClick(homeLink: HomeLinkDto) {
    navigateToHomeLinkDetailEdit(homeLink.id);
  }

  return (
    <HomeLinksConfigListTable
      homeLinks={homeLinks}
      onRowClick={handleRowClick}
    />
  );
};

export default HomeLinkConfigListPage;
