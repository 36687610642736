import React from 'react';
import { styled } from '@mui/material/styles';
import { HomeLinkDto } from '../types';
import { Tooltip, Typography } from '@mui/material';
import { FileLinkIcon, LinkIcon } from '../icons';
import { grey } from '@mui/material/colors';
import { getUrlHostname } from '../../../utils/string-utils';

const PREFIX = 'LinkTiles';

const styles = {
  anchor: `${PREFIX}-anchor`,
  tile: `${PREFIX}-tile`,
  imageArea: `${PREFIX}-imageArea`,
  image: `${PREFIX}-image`,
  textArea: `${PREFIX}-textArea`,
  text: `${PREFIX}-text`,
};

const StyledLinkTileDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',

  [`& .${styles.anchor}`]: {
    textDecoration: 'none',
    color: 'inherit',
    cursor: 'pointer',
  },

  [`& .${styles.tile}`]: {
    width: 280,
    height: 60,
    border: `1px solid ${theme.palette.divider}`,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 4,
    display: 'flex',
    background: grey[100],
    transition: 'box-shadow 0.2s ease, transform 0.2s ease',
  },

  [`& .${styles.tile}:hover`]: {
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transform: 'translateY(-3px)',
  },

  [`& .${styles.imageArea}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    maxWidth: 60,
    flexBasis: 60,
    flexShrink: 0,
    height: `100%`,
    color: theme.palette.secondary.light,
    background: grey[300],
  },

  [`& .${styles.image}`]: {
    maxWidth: `100%`,
    maxHeight: `100%`,
  },

  [`& .${styles.textArea}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 0,
    paddingLeft: 4,
    paddingRight: 4,
  },

  [`& .${styles.text}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

type Props = {
  homeLinks: HomeLinkDto[];
  className?: string;
};

/**
 * Renders home links in tile arrangement
 * @param homeLinks
 * @param className
 * @constructor
 */
const LinkTiles = ({ homeLinks, className }: Props) => {
  return (
    <StyledLinkTileDiv className={className}>
      {homeLinks.map((x) => (
        <a
          key={x.id}
          className={styles.anchor}
          href={x.linkType === 'Link' ? x.linkUrl : x.fileUrl}
          target={'_blank'}
          rel={'noreferrer'}
        >
          <Tooltip title={x.title} enterDelay={1000} placement={'top'}>
            <div className={styles.tile}>
              <div className={styles.imageArea}>
                {x.imageUrl ? (
                  <img
                    className={styles.image}
                    src={x.imageUrl}
                    alt={x.title}
                  />
                ) : x.linkType === 'Link' ? (
                  <LinkIcon style={{ width: `100%`, height: `100%` }} />
                ) : (
                  <FileLinkIcon style={{ width: `100%`, height: `100%` }} />
                )}
              </div>
              <div className={styles.textArea}>
                <Typography variant={'subtitle2'} className={styles.text}>
                  {x.title}
                </Typography>
                <Typography variant={'caption'} className={styles.text}>
                  {x.linkType === 'Link'
                    ? getUrlHostname(x.linkUrl)
                    : x.filename}
                </Typography>
              </div>
            </div>
          </Tooltip>
        </a>
      ))}
    </StyledLinkTileDiv>
  );
};

export default LinkTiles;
