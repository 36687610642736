import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ManufacturingLocationSelection from './ManufacturingLocationSelection';
import { useManufacturingLocations } from '../utils';
import { useDispatch } from 'react-redux';
import { setManufacturingLocationsThunk } from '../manufacturingLocationSlice';
import { ManufacturingLocation } from '../types';
import GenericDialog from '../../common/GenericDialog';
import { EMPTY_GUID } from '../../../utils/string-utils';
import { AppDispatch } from '../../../store';

type Props = {
  initialManufacturingLocation: ManufacturingLocation;
  open: boolean;
  onConfirm: (manufacturingLocation: ManufacturingLocation) => void;
  onCancel: () => void;
  confirmDisabled?: boolean;
  disableMCellCollection?: boolean;
  includeRollupCells?: boolean;
  removeInventoryCenterAllOption?: boolean;
  removeMCellAllOption?: Boolean;
};

/**
 * Renders ManufacturingLocationSelection component in a dialog
 * @param initialManufacturingLocation
 * @param open
 * @param onConfirm
 * @param onCancel
 * @param confirmDisabled
 * @param disableMCellCollection
 * @param includeRollupCells
 * @param removeInventoryCenterAllOption
 * @param removeMCellAllOption
 * @constructor
 */
const ManufacturingLocationSelectionDialog = ({
  initialManufacturingLocation,
  open,
  onConfirm,
  onCancel,
  confirmDisabled,
  disableMCellCollection,
  includeRollupCells,
  removeInventoryCenterAllOption,
  removeMCellAllOption,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [manufacturingLocation, setManufacturingLocation] = useState(
    initialManufacturingLocation,
  );
  const [componentId, setComponentId] = useState(EMPTY_GUID);
  const { mCells, inventoryCenters, mCellCollections } =
    useManufacturingLocations(includeRollupCells);

  /**
   * Effect that sets the initial manufacturing location of the dialog
   */
  useEffect(() => {
    setManufacturingLocation(initialManufacturingLocation);
  }, [initialManufacturingLocation]);

  /**
   * Effect that refreshes manufacturing location data in the store when
   * user opens the dialog
   */
  useEffect(() => {
    if (open) dispatch(setManufacturingLocationsThunk());
  }, [dispatch, open]);

  function handleChange(
    manufacturingLocation: ManufacturingLocation,
    componentId: string,
  ) {
    setManufacturingLocation(manufacturingLocation);
    setComponentId(componentId);
  }

  return (
    <GenericDialog
      title={t('setManufacturingLocation')}
      open={open}
      onConfirm={() =>
        onConfirm({
          inventoryCenterId: manufacturingLocation.inventoryCenterId,
          mCellCollectionId: manufacturingLocation.mCellCollectionId,
          mCellId:
            componentId === EMPTY_GUID
              ? manufacturingLocation.mCellId
              : componentId,
        })
      }
      onCancel={onCancel}
      confirmDisabled={confirmDisabled}
    >
      <ManufacturingLocationSelection
        mCells={mCells}
        inventoryCenters={inventoryCenters}
        mCellCollections={disableMCellCollection ? undefined : mCellCollections}
        initialManufacturingLocation={initialManufacturingLocation}
        onChange={handleChange}
        removeInventoryCenterAllOption={removeInventoryCenterAllOption}
        removeMCellAllOption={removeMCellAllOption}
      />
    </GenericDialog>
  );
};

export default ManufacturingLocationSelectionDialog;
