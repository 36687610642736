export type HomeLinkDto = {
  id: string;
  inventoryCenterId: string;
  mCellId: string;
  mCellCollectionId: string;
  title: string;
  linkType: HomeLinkType;
  imageName?: string;
  imageUrl?: string;
  linkUrl: string;
  filename?: string;
  fileUrl?: string;
  lastUpdatedByName: string;
  lastUpdatedById: string;
  sortSequence: number;
  updatedDate: string;
  groupName?: string;
  homeLinkGroupId?: string;
};

export type HomeLinkCreate = {
  inventoryCenterId: string;
  mCellId: string;
  mCellCollectionId: string;
  title: string;
  linkType: HomeLinkType;
  linkUrl: string;
  file?: File;
  image?: File;
  sortSequence: number;
  homeLinkGroupId?: string;
};

export type HomeLinkUpdate = {
  inventoryCenterId: string;
  mCellId: string;
  mCellCollectionId: string;
  title: string;
  linkUrl: string;
  sortSequence: number;
  homeLinkGroupId?: string;
};

export type HomeLinkInfo = Partial<HomeLinkDto>;

export const homeLinkTypes = ['File', 'Link'] as const;

export type HomeLinkType = typeof homeLinkTypes[number];

export type HomeLinkGroupDto = {
  id: string;
  name: string;
  sortSequence: number;
  inventoryCenterId: string;
};

export type HomeLinkGroupCreate = {
  name: string;
  sortSequence: number;
  inventoryCenterId: string;
};

export type HomeLinkGroupUpdate = {
  name: string;
  sortSequence: number;
};
