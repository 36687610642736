import { useSelector } from 'react-redux';
import {
  InventoryCenter,
  ManufacturingLocation,
  MCell,
  MCellCollection,
  PlanningDepartment,
  TicketSystem,
  ticketSystems,
  WorkCenterProductionLine,
} from './types';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { EMPTY_GUID, emptyGuidOption } from '../../utils/string-utils';
import { OptionValue } from '../common/EnhancedSelect';
import { usePersistedState } from '../app/utils';
import { FILTER_INVENTORY_CENTER_ID } from '../profile/types';
import {
  selectFilteredInventoryCenters,
  selectFilteredMCellCollections,
  selectFilteredMCells,
} from './manufacturingLocationSlice';
import { JobStation } from '../resourceTracking/types';
import { JobFunction } from '../mCellEmployee/types';
import { useMemo } from 'react';

export const DEFAULT_INVENTORY_CENTER_ID =
  'b55dcaad-749d-4877-98db-0f09f43626f3';
const DEFAULT_INVENTORY_CENTER_NAME = '01 | Warroad';

/**
 * Formats MCell to `CODE | DESCRIPTION`
 * @param mCell
 */
export const formatMCellDescription = (mCell: MCell | undefined) => {
  if (mCell && mCell.isRollup) {
    return `🧻 ${mCell.code} | ${mCell.description}`;
  }
  return mCell ? `⏺️ ${mCell.code} | ${mCell.description}` : '';
};

/**
 * Gets the formatted MCell description given an mCellId
 * @param mCellId
 * @param mCells
 */
export const getMCellDescription = (
  mCellId: string,
  mCells: MCell[],
): string => {
  const mCell = mCells.find((m) => m.id === mCellId);
  if (!mCell) return '';
  return formatMCellDescription(mCell);
};

/**
 * Formats MCell to `CODE | DESCRIPTION`
 * @param mCell
 */
export const formatMCellShortDescription = (mCell: MCell): string =>
  `${mCell.code} | ${mCell.description}`;

/**
 * Formats MCell to `CODE | DESCRIPTION (INVENTORY_CENTER_DISPLAY_NAME)`
 * @param mCell
 */
export const formatMCellLongDescription = (mCell: MCell): string =>
  `${mCell.code} | ${mCell.description} (${formatInventoryCenterDisplayName(
    mCell.inventoryCenter,
  )})`;

/**
 * Formats Inventory Center to `DISPLAY_NAME` if available, otherwise `DESCRIPTION`
 * @param inventoryCenter
 */
export const formatInventoryCenterDisplayName = (
  inventoryCenter: InventoryCenter,
): string => {
  return `${
    inventoryCenter.displayName
      ? inventoryCenter.displayName
      : inventoryCenter.description
  }`;
};

/**
 * Formats Inventory Center to `CODE | DISPLAY_NAME` if available, otherwise `CODE | DESCRIPTION
 * @param inventoryCenter
 */
export const formatInventoryCenterDisplayNameWithCode = (
  inventoryCenter: InventoryCenter,
): string => {
  return `${inventoryCenter.code} | ${formatInventoryCenterDisplayName(
    inventoryCenter,
  )}`;
};

/**
 * Formats Planning Department to `DESCRIPTION`
 * @param department
 */
export const formatDepartmentDescription = (
  department: PlanningDepartment,
): string => `${department.description}`;

/**
 * Formats Planning Department to `CODE | DESCRIPTION`
 * @param department
 */
export const formatDepartmentDescriptionWithCode = (
  department: PlanningDepartment,
): string => `${department.code} | ${department.description}`;

/**
 * Formats Work Center Production Line to display either both workCenterProductionLine.workCenter
 * and workCenterProductionLine.productionLine as `WORKCENTER | PRODUCTIONLINE`,
 * if either doesn't exist N/A will be a placeholder`, i.e. `WORKCENTER | N/A`
 * @param workCenterProductionLine
 */
export const formatWorkCenterProductionLineCodes = (
  workCenterProductionLine: WorkCenterProductionLine,
): string =>
  `${workCenterProductionLine.workCenter?.code ?? 'N/A'} | ${
    workCenterProductionLine.productionLine?.code ?? 'N/A'
  }`;

/**
 * Hook for getting MCell options for select
 * @param inventoryCenterId - filters options by Inventory Center
 * @param includeRollupCells - includes options for Rollup Cells
 * @param includeAnyOption - includes option for any MCell
 */
export const useMCellOptions = (
  inventoryCenterId?: string,
  includeRollupCells: boolean = false,
  includeAnyOption: boolean = false,
) => {
  const { t } = useTranslation();
  const mCells = useSelector(
    selectFilteredMCells(includeRollupCells, inventoryCenterId),
  );

  return includeAnyOption
    ? [emptyGuidOption(t('any')), ...getMCellOptions(mCells)]
    : getMCellOptions(mCells);
};

/**
 * Converts a list of MCells to a list of MCell options for select
 * @param mCells - list of MCells to convert
 */
export const getMCellOptions = (mCells: MCell[]): OptionValue[] =>
  mCells.map((m) => ({
    label: formatMCellDescription(m),
    value: m.id,
  }));

type ManufacturingLocationHookResult = {
  mCells: MCell[];
  inventoryCenters: InventoryCenter[];
  mCellCollections: MCellCollection[];
};

/**
 * Hook for getting Manufacturing Locations
 * @param includeRollupCells - includes Rollup Cells
 * @param includeAllMCellCollections - includes all MCell Collections, even the private MCell Collections of other users
 * @returns ManufacturingLocationHookResult - mCells, inventoryCenters, mCellCollections
 */
export const useManufacturingLocations = (
  includeRollupCells = false,
  includeAllMCellCollections = false,
): ManufacturingLocationHookResult => {
  /**
   * Memoizes the creation of a selector for MCells based on the `includeRollupCells` flag.
   * This is necessary because `selectFilteredMCells` creates a new selector instance based on the
   * input parameter. Memoizing the selector creation with `useMemo` ensures that the selector
   * does not change on every render unless `includeRollupCells` changes. This stable selector
   * reference prevents unnecessary re-subscriptions and state recalculations in `useSelector`,
   * enhancing performance, especially when the input parameter changes infrequently.
   *
   * @param {boolean} includeRollupCells - Controls whether rollup cells are included.
   */
  const selectMCells = useMemo(
    () => selectFilteredMCells(includeRollupCells),
    [includeRollupCells],
  );

  /**
   * Memoizes the creation of a selector for MCell collections based on the `includeAllMCellCollections` flag.
   * Similar to `selectMCells`, this memoization is used to stabilize the selector's reference,
   * preventing `useSelector` from unnecessary operations if the `includeAllMCellCollections` parameter
   * does not change. It optimizes component performance by avoiding needless re-rendering and
   * ensures efficient updating of component state only when necessary.
   *
   * @param {boolean} includeAllMCellCollections - Determines whether to include all MCell collections,
   * including those that are private to other users, which might change based on user interactions
   * or permissions.
   */
  const selectMCellCollections = useMemo(
    () => selectFilteredMCellCollections(includeAllMCellCollections),
    [includeAllMCellCollections],
  );

  /**
   * Retrieves the list of enabled inventory centers from the Redux store. The selector used here,
   * `selectFilteredInventoryCenters`, is statically defined and memoized using `createSelector`. It filters
   * inventory centers based on their 'enabled' status.
   *
   * Because `selectFilteredInventoryCenters` does not depend on any external parameters or props, and its definition
   * is static (not recalculated on each render), there is no need to wrap it with `useMemo` in the component.
   * The lack of dynamic inputs means that the reference to `selectFilteredInventoryCenters` remains constant,
   * and it will only recompute its result when its specific part of the Redux state (`inventoryCenters`) changes.
   *
   * This setup ensures that component updates are efficient and occur only when the relevant slice of state changes,
   * maintaining optimal performance without unnecessary re-renders. This approach simplifies the component's
   * integration with Redux, focusing on rendering logic rather than state management mechanics.
   */
  const inventoryCenters = useSelector(selectFilteredInventoryCenters);

  const mCells = useSelector(selectMCells);
  const mCellCollections = useSelector(selectMCellCollections);

  return {
    mCells,
    inventoryCenters,
    mCellCollections,
  };
};

/**
 * Hook for current selection in Inventory Center filters across the app
 * @param inventoryCenterId
 */
export const useFilterInventoryCenterId = (inventoryCenterId?: string) => {
  return usePersistedState(
    FILTER_INVENTORY_CENTER_ID,
    inventoryCenterId !== undefined
      ? inventoryCenterId
      : DEFAULT_INVENTORY_CENTER_ID,
  );
};

/**
 * Convert Inventory Center list to Inventory Center options for select.
 * Returns default inventory centers if empty list is passed.
 * component
 * @param inventoryCenters
 */
export const getInventoryCenterOptions = (
  inventoryCenters: InventoryCenter[],
): OptionValue[] => {
  return inventoryCenters.length > 0
    ? inventoryCenters.map((i) => ({
        label: formatInventoryCenterDisplayName(i),
        value: i.id,
      }))
    : [
        {
          label: DEFAULT_INVENTORY_CENTER_NAME,
          value: DEFAULT_INVENTORY_CENTER_ID,
        },
      ];
};

/**
 * Gets the `All` option for an Inventory Center select.
 * @param t
 */
export const getInventoryCenterOptionAll = (t: TFunction): OptionValue => ({
  label: t('all'),
  value: EMPTY_GUID,
});

/**
 * Hook for converting a manufacturing location to its display string
 * @param manufacturingLocation - manufacturing location to convert
 * @param includeRollupCells - includes Rollup Cells
 * @param includeAllMCellCollections - includes all MCell Collections, even the private MCell Collections of other users
 */
export const useManufacturingLocationString = (
  manufacturingLocation: ManufacturingLocation,
  includeRollupCells: boolean = false,
  includeAllMCellCollections: boolean = false,
): string => {
  const { t } = useTranslation();
  const { mCells, inventoryCenters, mCellCollections } =
    useManufacturingLocations(includeRollupCells, includeAllMCellCollections);
  const mCell = mCells.find((m) => m.id === manufacturingLocation.mCellId);
  const inventoryCenter = inventoryCenters.find(
    (i) => i.id === manufacturingLocation.inventoryCenterId,
  );
  const mCellCollection = mCellCollections.find(
    (mcc) => mcc.id === manufacturingLocation.mCellCollectionId,
  );
  const mCellCollectionName = mCellCollection
    ? mCellCollection.name
    : !includeAllMCellCollections &&
        manufacturingLocation.mCellCollectionId !== EMPTY_GUID
      ? t('privateWorkCellGroup')
      : '';
  const inventoryCenterName = inventoryCenter
    ? formatInventoryCenterDisplayName(inventoryCenter)
    : t('all');
  const mCellName = mCell ? ` | ${mCell.description}` : '';
  return mCellCollectionName || `${inventoryCenterName}${mCellName}`;
};

/**
 * Gets the string key of a TicketSystem
 * @param ticketSystem
 */
export const getTicketSystemStringKey = (
  ticketSystem?: TicketSystem,
): string => {
  switch (ticketSystem) {
    case 'Email':
      return 'email';
    case 'Jira':
      return 'jira';
    case undefined:
      return '';
  }
};

/**
 * Gets the select options of a TicketSystem
 * @param t
 */
export const getTicketSystemOptions = (
  t: TFunction,
): { label: string; value: TicketSystem }[] => {
  return ticketSystems.map((s) => ({
    label: t(getTicketSystemStringKey(s)),
    value: s,
  }));
};

export const sampleJobStations: JobStation[] = [
  {
    id: '896a77e6-e3b2-4b5d-ab42-154afce429dd',
    title: 'Glazing Prep',
    description: 'Pull glass in order load line with glass.',
    mCell: {} as MCell,
    mCellId: 'c4f5f9d9-570e-43bf-9034-086fbc2f7d8f',
    jobFunction: {} as JobFunction,
    jobFunctionId: '7fe9c496-d06f-417c-a90c-c9462f5937f9',
    stationId: '',
    stationSequence: '4',
    positionCount: 1,
    crewingRateMaximum: 1,
    ageRestriction: 'None',
    weightRestriction: 'None',
    jobStationPersonalProtectiveEquipments: [],
    jobStationDangerousEquipments: [],
    mCellEmployeeRoles: [],
    mCellEmployeeRoleIds: [],
  },
  {
    id: '3e9208e2-caa6-4704-9dd0-447960cd2ad1',
    title: 'Sash Assembly',
    description: 'Prep metal parts, clip screens, apply metal to glass.',
    mCell: {} as MCell,
    mCellId: 'c4f5f9d9-570e-43bf-9034-086fbc2f7d8f',
    jobFunction: {} as JobFunction,
    jobFunctionId: '3f2ac04d-552a-4700-8371-3d313bdbea76',
    stationId: '',
    stationSequence: '5',
    positionCount: 1,
    crewingRateMaximum: 1,
    ageRestriction: 'None',
    weightRestriction: 'None',
    jobStationPersonalProtectiveEquipments: [],
    jobStationDangerousEquipments: [],
    mCellEmployeeRoles: [],
    mCellEmployeeRoleIds: [],
  },
  {
    id: '3035abc5-10ec-4853-81f6-6f4a7058e3a6',
    title: 'Final Assembly',
    description: 'Install sash in frame, inspect, scan move to cart.',
    mCell: {} as MCell,
    mCellId: 'c4f5f9d9-570e-43bf-9034-086fbc2f7d8f',
    jobFunction: {} as JobFunction,
    jobFunctionId: '8ce37ade-53ff-4669-af12-67fea11db60c',
    stationId: '',
    stationSequence: '6',
    positionCount: 1,
    crewingRateMaximum: 1,
    ageRestriction: 'None',
    weightRestriction: 'None',
    jobStationPersonalProtectiveEquipments: [],
    jobStationDangerousEquipments: [],
    mCellEmployeeRoles: [],
    mCellEmployeeRoleIds: [],
  },
  {
    id: '4b635acd-31e5-4838-990f-750da977afeb',
    title: 'Part Cutting',
    description: 'Pick, cut, prep, frame part.',
    mCell: {} as MCell,
    mCellId: 'c4f5f9d9-570e-43bf-9034-086fbc2f7d8f',
    jobFunction: {} as JobFunction,
    jobFunctionId: '544ac665-2126-4c5c-8dff-78315953a8d4',
    stationId: '',
    stationSequence: '1',
    positionCount: 1,
    crewingRateMaximum: 1,
    ageRestriction: 'None',
    weightRestriction: 'None',
    jobStationPersonalProtectiveEquipments: [],
    jobStationDangerousEquipments: [],
    mCellEmployeeRoles: [],
    mCellEmployeeRoleIds: [],
  },
  {
    id: '14efed86-e749-4c7d-98f6-cba7b1ab0efa',
    title: 'Assembly',
    description: 'Assemble, nail frame cut punch install track.',
    mCell: {} as MCell,
    mCellId: 'c4f5f9d9-570e-43bf-9034-086fbc2f7d8f',
    jobFunction: {} as JobFunction,
    jobFunctionId: '64f7d0da-88fd-4b5c-b4d4-97420333842f',
    stationId: '',
    stationSequence: '2',
    positionCount: 1,
    crewingRateMaximum: 1,
    ageRestriction: 'None',
    weightRestriction: 'None',
    jobStationPersonalProtectiveEquipments: [],
    jobStationDangerousEquipments: [],
    mCellEmployeeRoles: [],
    mCellEmployeeRoleIds: [],
  },
];
