import { SvgIcon } from '@mui/material';
import { mdiFormatListBulleted, mdiContentSaveOutline } from '@mdi/js';
import React from 'react';

/**
 * ListIcon
 * @param style
 * @param props
 * @constructor
 */
export const ListIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiFormatListBulleted} />
    </SvgIcon>
  );
};

/**
 * SaveIcon
 * @param style
 * @param props
 * @constructor
 */
export const SaveIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiContentSaveOutline} />
    </SvgIcon>
  );
};
