import React from 'react';
import { useManufacturingLocationString } from '../utils';
import { ManufacturingLocation } from '../types';

type Props = {
  location: ManufacturingLocation;
  includeRollupCells?: boolean;
  includeAllMCellCollections?: boolean;
};

/**
 * Component for rendering the string format of a Manufacturing Location
 * @param location - manufacturing location to convert
 * @param includeRollupCells - includes Rollup Cells
 * @param includeAllMCellCollections - includes all MCell Collections, even the private MCell Collections of other users
 * @constructor
 */
const ManufacturingLocationString = ({
  location,
  includeRollupCells,
  includeAllMCellCollections,
}: Props) => {
  const manufacturingLocationString = useManufacturingLocationString(
    location,
    includeRollupCells,
    includeAllMCellCollections
  );

  return <span>{manufacturingLocationString}</span>;
};

export default ManufacturingLocationString;
