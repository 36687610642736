import { useSelector } from 'react-redux';
import {
  CcDataDimension,
  CcWidgetMeta,
  CcWidgetMove,
  Dashboard,
  DashboardWidgetType,
} from './types';
import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors';
import { Duration } from '../../utils/time';
import { RootState } from '../../store';
import { MCellAlert, MCellAlertLevel } from '../mCellAlert/types';

export const analyticsDashboardEmbedUrl =
  'https://app.powerbi.com/dashboardEmbed';

/**
 * Get a dashboard by id from the store. Otherwise, return null
 * @param dashboardId
 */
export const useDashboard = (dashboardId: string): Dashboard | null => {
  const dashboards = useSelector(
    (state: RootState) => state.dashboard.dashboards
  );
  const dashboard = dashboards.find((d) => d.id === dashboardId);

  return dashboard ? dashboard : null;
};

/**
 * Returns the parsed Widget Meta from a Widget Meta String
 * @param widget
 */
export const getWidgetMeta = (
  widget: DashboardWidgetType
): CcWidgetMeta | null => {
  let parsedMeta;
  if (widget.metaString) {
    parsedMeta = JSON.parse(widget.metaString);
  } else if (widget.defaultMetaString) {
    parsedMeta = JSON.parse(widget.defaultMetaString);
  } else return null;

  return parsedMeta ? { ...parsedMeta, metric: widget.metric } : null;
};

/**
 * Converts a dashboard layout string to an array of the proportions
 * Eg. `1-1` --> [1, 1]
 * @param layout
 */
export const layoutStringToProportionArray = (
  layout: string
): Array<number> => {
  const segments = layout.split('-');
  return segments.map((s) => parseInt(s));
};

/**
 * Converts a dashboard layout string to an array of the grid widths based
 * on a total grid width of 12. Eg. `1-1` --> [6, 6]
 * @param layout
 */
export const layoutStringToGridWidths = (layout: string): Array<number> => {
  const layoutProportions = layoutStringToProportionArray(layout);
  const totalProportion = layoutProportions.reduce((t, v) => t + v, 0);
  return layoutProportions.map((l) => (l / totalProportion) * 12);
};

/**
 * Gets the key of the string representation of a Dimension
 * @param dimension
 */
export const dimensionToStringKey = (dimension: CcDataDimension): string => {
  switch (dimension) {
    case 'DowntimeReason':
      return 'byReason';
    case 'MCell':
      return 'byWorkCell';
    case 'SupportGroup':
      return 'bySupportGroup';
  }
};

/**
 * Gets the key of the string representation of a Time Range
 * @param timeRange
 */
export const timeRangeToStringKey = (timeRange: Duration): string => {
  switch (timeRange) {
    case 'Custom':
      return 'custom';
    case 'P1D':
      return 'pastDay';
    case 'P1M':
      return 'pastMonth';
    case 'P7D':
      return 'pastWeek';
    case 'P1Y':
      return 'pastYear';
  }
};

/**
 * List of colors used in the widget charts
 */
export const chartColors = [
  red[500],
  deepPurple[500],
  orange[500],
  lightBlue[500],
  lightGreen[500],
  blueGrey[500],
  brown[500],
  purple[500],
  indigo[500],
  teal[500],
  cyan[500],
  green[500],
  lime[500],
  pink[500],
  amber[500],
  blue[500],
  yellow[500],
  deepOrange[500],
];

/**
 * Gets the chart color corresponding to an index
 * @param index
 */
export const getColor = (index: number): string => {
  return chartColors[index % chartColors.length];
};

/**
 * Computes the new positions of all widgets when a widget is moved from one
 * position to another.
 * @param widgets
 * @param widgetMove
 */
export const reIndexWidget = (
  widgets: Array<DashboardWidgetType>,
  widgetMove: CcWidgetMove
): Array<DashboardWidgetType> => {
  const widgetIndex = widgets.findIndex((w) => w.id === widgetMove.widgetId);
  if (widgetIndex === -1) {
    return widgets;
  }

  return widgets.map((w) => {
    // item being moved
    if (w.id === widgetMove.widgetId) {
      return {
        ...w,
        columnIndex: widgetMove.destinationColumnIndex,
        rowIndex: widgetMove.destinationRowIndex,
      };
    }
    // moving between columns
    if (widgetMove.sourceColumnIndex !== widgetMove.destinationColumnIndex) {
      // move up source column widgets positioned after source row
      if (
        w.columnIndex === widgetMove.sourceColumnIndex &&
        w.rowIndex > widgetMove.sourceRowIndex
      ) {
        return {
          ...w,
          rowIndex: w.rowIndex - 1,
        };
      }
      // move down destination column widgets positioned at and after destination row
      if (
        w.columnIndex === widgetMove.destinationColumnIndex &&
        w.rowIndex >= widgetMove.destinationRowIndex
      ) {
        return {
          ...w,
          rowIndex: w.rowIndex + 1,
        };
      }
      // leave the rest of the widgets alone
      return w;
    }

    // moving forward within the same column
    else if (widgetMove.destinationRowIndex > widgetMove.sourceRowIndex) {
      if (
        w.columnIndex === widgetMove.sourceColumnIndex &&
        w.rowIndex > widgetMove.sourceRowIndex &&
        w.rowIndex <= widgetMove.destinationRowIndex
      ) {
        return {
          ...w,
          rowIndex: w.rowIndex - 1,
        };
      }
      return w;
    }
    // moving backward within the same column
    else if (widgetMove.destinationRowIndex < widgetMove.sourceRowIndex) {
      if (
        w.columnIndex === widgetMove.sourceColumnIndex &&
        w.rowIndex < widgetMove.sourceRowIndex &&
        w.rowIndex >= widgetMove.destinationRowIndex
      ) {
        return {
          ...w,
          rowIndex: w.rowIndex + 1,
        };
      }
      return w;
    }
    // no movement
    else {
      return w;
    }
  });
};

/**
 * Sort compare function for sorting MCell Alerts
 * @param a
 * @param b
 */
export const compareAlerts = (a: MCellAlert, b: MCellAlert): number => {
  const alertLevelOrder: MCellAlertLevel[] = ['Elevated', 'High'];
  return (
    alertLevelOrder.findIndex((l) => l === a.level) -
    alertLevelOrder.findIndex((l) => l === b.level)
  );
};
