import ErrorBoundary from '../common/ErrorBoundary';
import { Route, Routes } from 'react-router-dom';
import React from 'react';
import HomeContainerPage from './components/HomeContainerPage';
import HomeLinkConfigListPageContainer from './components/HomeLinkConfigListPageContainer';
import { getRelativeNavigationPaths } from '../../utils/navigation';
import HomeLinkConfigCreatePage from './components/HomeLinkConfigCreatePage';
import NotFoundPage from '../common/NotFoundPage';
import HomeLinkConfigDetailEdit from './components/HomeLinkConfigDetailEdit';
import { hasPermission } from '../../auth';

/**
 * Root component for Home sub application
 * @constructor
 */
const Home = () => {
  const { homeLinkCreatePath, homeConfigurationPath, homeLinkDetailEditPath } =
    getRelativeNavigationPaths();
  const canConfig = hasPermission(['Home.Config']);

  return (
    <ErrorBoundary>
      <Routes>
        <Route index element={<HomeContainerPage />} />
        {canConfig && (
          <Route
            path={homeLinkCreatePath}
            element={<HomeLinkConfigCreatePage />}
          />
        )}
        {canConfig && (
          <Route
            path={homeConfigurationPath}
            element={<HomeLinkConfigListPageContainer />}
          />
        )}
        {canConfig && (
          <Route
            path={homeLinkDetailEditPath}
            element={<HomeLinkConfigDetailEdit />}
          />
        )}
        <Route path={'*'} element={<NotFoundPage />} />
      </Routes>
    </ErrorBoundary>
  );
};

export default Home;
