import { Column } from '@devexpress/dx-react-grid';
import { TFunction } from 'i18next';

/**
 * Converts 'yes'/'no' string keys to boolean
 * @param yesNo
 */
export const yesNoToBool = (yesNo: string) => {
  return yesNo === 'yes';
};

/**
 * Converts boolean to 'yes'/'no' string keys
 * @param bool
 */
export const boolToYesNo = (bool: boolean) => {
  return bool ? 'yes' : 'no';
};

/**
 * Returns true when a string is empty or whitespace, otherwise returns false
 * @param str
 */
export const isEmptyOrSpaces = (str: string | null | undefined) => {
  return (
    str === undefined || str === null || String(str).match(/^\s*$/) !== null
  );
};

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

/**
 * Returns and Empty Guid select option with specified label
 * @param label
 */
export const emptyGuidOption = (label: string) => ({
  label,
  value: EMPTY_GUID,
});

/**
 * Returns messages for paging component of React-Grid
 * @param t
 */
export const getPagingMessages = (t: TFunction) => ({
  showAll: t('all'),
  rowsPerPage: t('rowsPerPage'),
  info: t('pageInfo'),
});

/**
 * Returns messages for table component of React-Grid
 * @param t
 */
export const getTableMessages = (t: TFunction) => ({
  noData: t('noData'),
});

/**
 * Trims text and add ellipses when threshold number of characters is reached.
 * @param text
 * @param threshold
 */
export const trimText = (text: string, threshold: number) => {
  if (text.length <= threshold) return text;
  return text.substr(0, threshold).concat('...');
};

/**
 * Returns true when a string contains only a non-negative number
 * @param str
 */
export const isNonNegativeNumber = (str: string | null | undefined) => {
  return (
    str !== undefined &&
    str !== null &&
    /^([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/.test(str)
  );
};

/**
 * Returns true when a string contains only a positive integer
 * @param str
 * @constructor
 */
export const isPositiveInteger = (str: string | null | undefined) => {
  return str !== undefined && str !== null && /^\+?[1-9]\d*$/.test(str);
};

/**
 * Splits text into words while combining word combinations not greater than minWordSize
 * @param text
 * @param minWordSize
 */
export const splitText = (text: string, minWordSize: number): string[] => {
  const words = text.split(/\s/g);
  const combinedWords: string[] = [];
  for (let i = 0; i < words.length; i++) {
    const currentWord = words[i];
    if (combinedWords.length === 0) {
      combinedWords.push(currentWord);
      continue;
    }
    const previousWord = combinedWords[combinedWords.length - 1];
    const comboWord = `${previousWord} ${currentWord}`;

    if (comboWord.length <= minWordSize) {
      combinedWords[combinedWords.length - 1] = comboWord;
    } else {
      combinedWords.push(currentWord);
    }
  }
  return combinedWords;
};

/**
 * Generates CSV content from rows and headers
 * @param rows
 * @param allColumns
 */
export const generateCsvContent = (
  rows: any[],
  allColumns: Column[]
): string => {
  const csvRows = [];
  const headers = allColumns.map((column) => column.title);
  csvRows.push(headers.join(','));
  for (const row of rows) {
    const values = allColumns.map((column) => stringifyValue(row[column.name]));
    csvRows.push(values.join(','));
  }
  return csvRows.join('\n');
};

/**
 * Converts value to string and adds quotes if needed
 * ensures that the values are properly formatted for csv
 * @param value
 */
const stringifyValue = (value: any): string => {
  if (value === null || value === undefined) {
    return '';
  }
  const stringValue = String(value);
  return `"${stringValue.replace(/"/g, '""')}"`;
};

/**
 * Extracts numeric and non-numeric value from a string
 * @param value
 */
export const extractNumericValue = (
  value: string
): { numeric: number; text: string } => {
  const numericMatches = value.match(/\d+/g);
  const numeric = numericMatches ? parseInt(numericMatches.join(''), 10) : 0;

  const textMatches = value.match(/[a-zA-Z]+/g);
  const text = textMatches ? textMatches.join('') : '';

  return { numeric, text };
};

/**
 * Enhanced select 'None' option text
 * @param t
 */
export const selectNoneOption = (t: TFunction): string =>
  `-- ${t('selectNone')} --`;

/**
 * Extracts hostname from a url
 * @param url
 */
export const getUrlHostname = (url: string) => {
  const urlObj = new URL(url);
  return urlObj.hostname;
};
