import React from 'react';
import {
  Badge,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  SxProps,
} from '@mui/material';
import { CellBoardMCellMessageDetailDto } from '../types';
import { isEmptyOrSpaces } from '../../../utils/string-utils';
import { MessageIcon } from '../icons';

type Props = {
  mCellMessages: CellBoardMCellMessageDetailDto[];
  onClick: (mCellMessage: CellBoardMCellMessageDetailDto) => void;
  className?: string;
  sx?: SxProps;
  iconColor?: string;
};

/**
 * Renders a Cell Board MCell Message icon button and menu
 * @param mCellMessages
 * @param onClick
 * @param className
 * @param sx
 * @param iconColor
 * @constructor
 */
const MCellMessageMenu = ({
  mCellMessages,
  onClick,
  className,
  sx,
  iconColor,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const unreadMCellMessageCount = mCellMessages.filter((m) =>
    isEmptyOrSpaces(m.readDate),
  ).length;

  /**
   * called when menu button is clicked. Opens the menu
   * @param event
   */
  function handleMessagesClick(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    if (mCellMessages.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  }

  /**
   * Called when user request menu close. Closes the menu
   */
  function handleMenuClose(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    setAnchorEl(null);
  }

  /**
   * Called when a message in the menu is clicked. Notifies parent component
   * @param event
   * @param mCellMessage
   */
  function handleMessageClick(
    event: React.MouseEvent<HTMLElement>,
    mCellMessage: CellBoardMCellMessageDetailDto,
  ) {
    event.stopPropagation();
    setAnchorEl(null);
    onClick(mCellMessage);
  }

  return (
    <>
      <IconButton
        onClick={handleMessagesClick}
        className={className}
        sx={sx}
        disabled={mCellMessages.length === 0}
        color={iconColor as any}
        size='large'
      >
        <Badge
          overlap={'rectangular'}
          badgeContent={unreadMCellMessageCount}
          color={'error'}
          invisible={unreadMCellMessageCount === 0}
        >
          <MessageIcon />
        </Badge>
      </IconButton>
      <Menu open={isMenuOpen} anchorEl={anchorEl} onClose={handleMenuClose}>
        {mCellMessages.map((m) => (
          <MenuItem key={m.id} onClick={(e) => handleMessageClick(e, m)}>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontWeight: isEmptyOrSpaces(m.readDate) ? 'bold' : 'normal',
                },
              }}
              sx={{
                maxWidth: 400,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {m?.cellBoardMessage?.subject ?? '...'}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MCellMessageMenu;
