import {
  FactoryAutomationOverheadDoorSignalModeConfig,
  FactoryAutomationOverheadDoorSignalModeInfo,
} from './types';
import axiosInstance from '../../api';

/**
 * API call to delete Overhead Door Signal Mode Config
 * @param id
 */
export const deleteOverheadDoorSignalModeConfig = async (id: string) => {
  await axiosInstance.delete(
    `/api/FactoryAutomation/overheadDoorSignalMode/config/${id}`
  );
};

/**
 * API call to create Overhead Door Signal Mode config
 * @param info
 */
export const createOverheadDoorSignalModeConfig = async (
  info: FactoryAutomationOverheadDoorSignalModeInfo
): Promise<FactoryAutomationOverheadDoorSignalModeConfig> => {
  const response = await axiosInstance.post<FactoryAutomationOverheadDoorSignalModeConfig>(
    '/api/FactoryAutomation/overheadDoorSignalMode/config',
    info
  );
  return response.data;
};

/**
 * API call to get Overhead Door Signal Mode configs data
 *  * @param inventoryCenterId
 */
export const getOverheadDoorSignalModeConfigs = async (
  inventoryCenterId: string
): Promise<FactoryAutomationOverheadDoorSignalModeConfig[]> => {
  const response = await axiosInstance.get(
    '/api/FactoryAutomation/overheadDoorSignalMode/configs',
    {
      params: inventoryCenterId,
    }
  );
  return response.data;
};

/**
 * API call to set an overhead door signal mode
 * @param info
 */
export const setOverheadDoorSignalMode = async (
  info: FactoryAutomationOverheadDoorSignalModeConfig
): Promise<FactoryAutomationOverheadDoorSignalModeConfig> => {
  const response = await axiosInstance.post<FactoryAutomationOverheadDoorSignalModeConfig>(
    `/api/FactoryAutomation/overheadDoorSignalMode/config/${info.id}`,
    {
      inventoryCenterId: info.inventoryCenterId,
      mCellCollectionId: info.mCellCollectionId,
      mode: info.mode,
    }
  );
  return response.data;
};
