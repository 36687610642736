import React, { useEffect, useState } from 'react';
import { useQueryParams, useUpdateQueryParams } from '../app/utils';
import { hasPermission } from '../../auth';
import { TabConfig, UseTabManagerOutput } from './types';
import NotFoundPage from './NotFoundPage';

/**
 * Manages tabs(also supports an optional query parameter to handle nested tabs) and their content while handling permissions.
 * @param tabConfigs
 * @param queryParamKey - optional query parameter to store the active tab. Defaults to 'tab'.
 */
export const useTabManager = (
  tabConfigs: TabConfig[],
  queryParamKey = 'tab',
): UseTabManagerOutput => {
  const queryParams = useQueryParams<{ [key: string]: string | undefined }>();
  const updateQueryParams = useUpdateQueryParams<{
    [key: string]: string | undefined;
  }>();

  // Filter tabs based on permissions and any extra checks
  const filteredTabs = tabConfigs.filter((tab) => {
    if (!tab.permission) {
      return true;
    }
    const permissions = Array.isArray(tab.permission)
      ? tab.permission
      : [tab.permission];
    return hasPermission(permissions);
  });

  // Initialize activeTabKey with a state to prevent undefined errors
  const [activeTabKey, setActiveTabKey] = useState(() => {
    return queryParams[queryParamKey] || filteredTabs[0]?.key;
  });

  /**
   * Effect to set the active tab key to the first tab key if initial query param not set
   */
  useEffect(() => {
    if (activeTabKey === undefined && filteredTabs.length > 0) {
      const newActiveTabKey = filteredTabs[0].key;
      setActiveTabKey(newActiveTabKey);
      updateQueryParams({ [queryParamKey]: newActiveTabKey });
    }
  }, [filteredTabs, activeTabKey, queryParamKey, updateQueryParams]);

  /**
   * Handles tab change. Updates query params using the custom parameter name.
   * @param _
   * @param newValue
   */
  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    setActiveTabKey(newValue);
    updateQueryParams({ [queryParamKey]: newValue });
  };

  /**
   * Renders the content of the active tab. If tab not found, renders NotFoundPage
   */
  const renderTabContent = (): JSX.Element => {
    const currentTab = activeTabKey
      ? filteredTabs.find((tab) => tab.key === activeTabKey)
      : filteredTabs[0];
    if (currentTab) {
      return currentTab.component;
    }
    return <NotFoundPage />;
  };

  return { activeTabKey, handleTabChange, filteredTabs, renderTabContent };
};
