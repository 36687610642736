/**
 * Clamps a number between inclusive min and max
 * @param value
 * @param min
 * @param max
 */
export const clamp = (value: number, min: number, max: number) => {
  return Math.min(max, Math.max(min, value));
};

/**
 * toFixed, but does not convert whole numbers, and removes trailing zeros
 * @param value
 * @param precision
 */
export function customToFixed(value: number, precision: number): string {
  if (Math.floor(value) === value) {
    return value.toString();
  }
  const fixed = value.toFixed(precision);
  return parseFloat(fixed).toString();
}
