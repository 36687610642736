import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { config } from './config';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: config.applicationInsights.instrumentationKey,
    extensions: [],
    extensionConfig: {},
  },
});
if (process.env.NODE_ENV === 'production') {
  appInsights.loadAppInsights();
}
export { appInsights };
