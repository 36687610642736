import { createAction, createSlice } from '@reduxjs/toolkit';
import {
  FactoryAutomationOverheadDoorSignalModeConfig,
  FactoryAutomationOverheadDoorSignalModeInfo,
} from './types';
import { LoadingStatus } from '../../api/app.types';
import { createAsyncThunkWithError } from '../../redux/utils';
import {
  createOverheadDoorSignalModeConfig,
  deleteOverheadDoorSignalModeConfig,
  getOverheadDoorSignalModeConfigs,
  setOverheadDoorSignalMode,
} from './api';
import { RootState } from '../../store';

export type FactoryAutomationState = {
  overheadDoorSignalModeConfigs: FactoryAutomationOverheadDoorSignalModeConfig[];
  overheadDoorSignalModeConfigsStatus: LoadingStatus;
};

export const initialState: FactoryAutomationState = {
  overheadDoorSignalModeConfigs: [],
  overheadDoorSignalModeConfigsStatus: 'idle',
};

/**
 * Action to update an overhead door signal mode in the store
 */
export const updateOverheadDoorSignalModeAction = createAction<FactoryAutomationOverheadDoorSignalModeConfig>(
  'factoryAutomation/updateOverheadDoorSignalModeAction'
);

/**
 * Action to add an overhead door signal mode in the store
 */
export const addOverheadDoorSignalModeAction = createAction<FactoryAutomationOverheadDoorSignalModeInfo>(
  'factoryAutomation/addOverheadDoorSignalModeAction'
);

/**
 * Action to delete an Overhead Door Signal Mode from the store
 */
export const deleteOverheadDoorSignalModeAction = createAction<string>(
  'factoryAutomation/deleteOverheadDoorSignalModeAction'
);

/**
 * Thunk for deleting an OverheadDoor Signal Mode Config
 */
export const deleteOverheadDoorSignalModeConfigThunk = createAsyncThunkWithError(
  'factoryAutomation/deleteOverheadDoorSignalModeConfigThunk',
  async (id: string, { dispatch }) => {
    dispatch(deleteOverheadDoorSignalModeAction(id));
    return await deleteOverheadDoorSignalModeConfig(id);
  },
  'errorDeletingOverheadDoorSignalModeConfig'
);

/**
 * Thunk for fetching overhead door signal mode configs
 */
export const getOverheadDoorSignalModeConfigsThunk = createAsyncThunkWithError(
  'factoryAutomation/getOverheadDoorSignalModeConfigsThunk',
  getOverheadDoorSignalModeConfigs,
  'errorFetchingOverheadDoorSignalModes'
);

/**
 * Thunk for creating an OverheadDoorSignalModeConfig
 */
export const createOverheadDoorSignalModeConfigThunk = createAsyncThunkWithError(
  'factoryAutomation/createOverheadDoorSignalModeConfigThunk',
  async (info: FactoryAutomationOverheadDoorSignalModeInfo, { dispatch }) => {
    dispatch(addOverheadDoorSignalModeAction(info));
    await createOverheadDoorSignalModeConfig(info);
  },
  'errorCreatingOverheadDoorSignalModeConfig'
);

/**
 * Thunk for setting an overhead door signal mode on the server while optimistically
 * updating the local store
 */
export const setOverheadDoorSignalModeThunk = createAsyncThunkWithError(
  'factoryAutomation/setOverheadDoorSignalModeThunk',
  async (info: FactoryAutomationOverheadDoorSignalModeConfig, { dispatch }) => {
    dispatch(updateOverheadDoorSignalModeAction(info));
    return await setOverheadDoorSignalMode(info);
  },
  'errorSettingOverheadDoorSignalMode'
);

export const factoryAutomationSlice = createSlice({
  name: 'factoryAutomation',
  initialState,
  reducers: {
    addOverheadDoorSignalModeAction: (state, action) => {
      state.overheadDoorSignalModeConfigs.push(action.payload);
    },
    updateOverheadDoorSignalModeAction: (state, action) => {
      const updateIndex = state.overheadDoorSignalModeConfigs.findIndex(
        (o) => o.id === action.payload.id
      );
      if (updateIndex > -1) {
        state.overheadDoorSignalModeConfigs[updateIndex] = action.payload;
      }
    },
    deleteOverheadDoorSignalModeAction: (state, action) => {
      const index = state.overheadDoorSignalModeConfigs.findIndex(
        (o) => o.id === action.payload
      );
      if (index > -1) {
        state.overheadDoorSignalModeConfigs.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOverheadDoorSignalModeConfigsThunk.pending, (state) => {
        state.overheadDoorSignalModeConfigsStatus = 'loading';
      })
      .addCase(
        getOverheadDoorSignalModeConfigsThunk.fulfilled,
        (state, { payload }) => {
          state.overheadDoorSignalModeConfigsStatus = 'succeeded';
          state.overheadDoorSignalModeConfigs = payload;
        }
      )
      .addCase(getOverheadDoorSignalModeConfigsThunk.rejected, (state) => {
        state.overheadDoorSignalModeConfigsStatus = 'failed';
      })
      .addCase(setOverheadDoorSignalModeThunk.pending, (state) => {
        state.overheadDoorSignalModeConfigsStatus = 'loading';
      })
      .addCase(setOverheadDoorSignalModeThunk.fulfilled, (state) => {
        state.overheadDoorSignalModeConfigsStatus = 'succeeded';
      })
      .addCase(setOverheadDoorSignalModeThunk.rejected, (state) => {
        state.overheadDoorSignalModeConfigsStatus = 'failed';
      })
      .addCase(createOverheadDoorSignalModeConfigThunk.pending, (state) => {
        state.overheadDoorSignalModeConfigsStatus = 'loading';
      })
      .addCase(createOverheadDoorSignalModeConfigThunk.fulfilled, (state) => {
        state.overheadDoorSignalModeConfigsStatus = 'succeeded';
      })
      .addCase(createOverheadDoorSignalModeConfigThunk.rejected, (state) => {
        state.overheadDoorSignalModeConfigsStatus = 'failed';
      })
      .addCase(deleteOverheadDoorSignalModeConfigThunk.pending, (state) => {
        state.overheadDoorSignalModeConfigsStatus = 'loading';
      })
      .addCase(deleteOverheadDoorSignalModeConfigThunk.fulfilled, (state) => {
        state.overheadDoorSignalModeConfigsStatus = 'succeeded';
      })
      .addCase(deleteOverheadDoorSignalModeConfigThunk.rejected, (state) => {
        state.overheadDoorSignalModeConfigsStatus = 'failed';
      });
  },
});

/**
 * Selector to retrieve Overhead Door Signal Mode Configs from the store
 * @param state
 */
export const selectOverheadDoorSignalModeConfigs = (state: RootState) =>
  state.factoryAutomation.overheadDoorSignalModeConfigs;

/**
 * Selector to determine whether overhead door signal mode configs are loading
 * @param state
 */
export const selectLoadingOverheadDoorSignalModeConfigs = (state: RootState) =>
  state.factoryAutomation.overheadDoorSignalModeConfigsStatus === 'loading';

export default factoryAutomationSlice.reducer;
