import { SvgIcon } from '@mui/material';
import {
  mdiAlertCircle,
  mdiCheckCircle,
  mdiInformation,
  mdiAlert,
  mdiClose,
  mdiContactlessPaymentCircle,
  mdiDotsHorizontal,
  mdiCloseCircle,
  mdiDelete,
  mdiPaperclip,
  mdiCloudUpload,
  mdiFullscreenExit,
  mdiFullscreen,
  mdiArrowUp,
  mdiArrowDown,
  mdiCheckBold,
  mdiChevronUp,
  mdiChevronDown,
  mdiArrowLeft,
  mdiCalendarRange,
  mdiPencil,
  mdiMenu,
  mdiPlus,
  mdiCog,
  mdiHelpCircleOutline,
  mdiRecycle,
  mdiAccountQuestion,
  mdiRobotIndustrial,
  mdiPipeWrench,
  mdiAccountVoice,
  mdiCalendarOutline,
} from '@mdi/js';
import React from 'react';

/**
 * AddIcon
 * @param style
 * @param props
 * @constructor
 */
export const AddIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiPlus} />
    </SvgIcon>
  );
};

/**
 * ArrowBackIcon
 * @param style
 * @param props
 * @constructor
 */
export const ArrowBackIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiArrowLeft} />
    </SvgIcon>
  );
};

/**
 * ArrowDownwardIcon
 * @param style
 * @param props
 * @constructor
 */
export const ArrowDownwardIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiArrowDown} />
    </SvgIcon>
  );
};

/**
 * ArrowUpwardIcon
 * @param style
 * @param props
 * @constructor
 */
export const ArrowUpwardIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiArrowUp} />
    </SvgIcon>
  );
};

/**
 * AttachFileIcon
 * @param style
 * @param props
 * @constructor
 */
export const AttachFileIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiPaperclip} />
    </SvgIcon>
  );
};

/**
 * CancelIcon
 * @param style
 * @param props
 * @constructor
 */
export const CancelIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiCloseCircle} />
    </SvgIcon>
  );
};

/**
 * Check bold icon
 * @param style
 * @param props
 * @constructor
 */
export const CheckBoldIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiCheckBold} />
    </SvgIcon>
  );
};

/**
 * CloseIcon
 * @param style
 * @param props
 * @constructor
 */
export const CloseIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiClose} />
    </SvgIcon>
  );
};

/**
 * DateRangeIcon
 * @param style
 * @param props
 * @constructor
 */
export const DateRangeIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiCalendarRange} />
    </SvgIcon>
  );
};

/**
 * DeleteIcon
 * @param style
 * @param props
 * @constructor
 */
export const DeleteIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiDelete} />
    </SvgIcon>
  );
};

/**
 * EditIcon
 * @param style
 * @param props
 * @constructor
 */
export const EditIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiPencil} />
    </SvgIcon>
  );
};

/**
 * ErrorIcon
 * @param style
 * @param props
 * @constructor
 */
export const ErrorIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiAlertCircle} />
    </SvgIcon>
  );
};

/**
 * ExpandLessIcon
 * @param style
 * @param props
 * @constructor
 */
export const ExpandLessIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiChevronUp} />
    </SvgIcon>
  );
};

/**
 * ExpandMoreIcon
 * @param style
 * @param props
 * @constructor
 */
export const ExpandMoreIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiChevronDown} />
    </SvgIcon>
  );
};

/**
 * FullscreenExitIcon
 * @param style
 * @param props
 * @constructor
 */
export const FullscreenExitIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiFullscreenExit} />
    </SvgIcon>
  );
};

/**
 * FullscreenIcon
 * @param style
 * @param props
 * @constructor
 */
export const FullscreenIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiFullscreen} />
    </SvgIcon>
  );
};

/**
 * Help Circle Icon
 * @param style
 * @param props
 * @constructor
 */
export const HelpCircleIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiHelpCircleOutline} />
    </SvgIcon>
  );
};

/**
 * InfoIcon
 * @param style
 * @param props
 * @constructor
 */
export const InfoIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiInformation} />
    </SvgIcon>
  );
};

/**
 * MenuIcon
 * @param style
 * @param props
 * @constructor
 */
export const MenuIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiMenu} />
    </SvgIcon>
  );
};

/**
 * MoreIcon
 * @param style
 * @param props
 * @constructor
 */
export const MoreIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiDotsHorizontal} />
    </SvgIcon>
  );
};

/**
 * UploadIcon
 * @param style
 * @param props
 * @constructor
 */
export const UploadIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiCloudUpload} />
    </SvgIcon>
  );
};

/**
 * ScanIcon
 * @param style
 * @param props
 * @constructor
 */
export const ScanIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiContactlessPaymentCircle} />
    </SvgIcon>
  );
};

/**
 * Settings Icon
 * @param style
 * @param props
 * @constructor
 */
export const SettingsIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiCog} />
    </SvgIcon>
  );
};

/**
 * SuccessIcon
 * @param style
 * @param props
 * @constructor
 */
export const SuccessIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiCheckCircle} />
    </SvgIcon>
  );
};

/**
 * WarningIcon
 * @param style
 * @param props
 * @constructor
 */
export const WarningIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiAlert} />
    </SvgIcon>
  );
};

/**
 * ContinuousImprovementIcon
 * @param style
 * @param props
 * @constructor
 */
export const ImprovementIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiRecycle} />
    </SvgIcon>
  );
};

/**
 * EquipmentConcernIcon
 * @param style
 * @param props
 * @constructor
 */
export const EquipmentIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiRobotIndustrial} />
    </SvgIcon>
  );
};

/**
 * ConcernIcon
 * @param style
 * @param props
 * @constructor
 */
export const ConcernIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiAccountQuestion} />
    </SvgIcon>
  );
};

/**
 * MissingToolIcon
 * @param style
 * @param props
 * @constructor
 */
export const MissingToolIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiPipeWrench} />
    </SvgIcon>
  );
};

/**
 * OpenDiscussionIcon
 * @param style
 * @param props
 * @constructor
 */
export const OpenDiscussionIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiAccountVoice} />
    </SvgIcon>
  );
};

/**
 * CalendarIcon
 * @param style
 * @param props
 * @constructor
 */
export const CalendarIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiCalendarOutline} />
    </SvgIcon>
  );
};
