/**
 * Groups an array of objects by a key's value in the objects
 * @param xs
 * @param key
 */
export function groupBy<T, K extends keyof T>(xs: T[], key: K): any {
  return xs.reduce((rv, x) => {
    // @ts-ignore
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}
