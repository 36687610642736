import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

/**
 * Renders a loading animation
 * @constructor
 */
const Loading = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
      }}
    >
      <CircularProgress />
      <Typography sx={{ ml: 2 }} variant={'subtitle1'}>
        {t('keepCalm')}
      </Typography>
    </div>
  );
};

export default Loading;
