import React, { createContext, useContext, useState } from 'react';
import { AlertLevel } from '../app/types';
import { ErrorMessage } from '../app/utils';

/**
 * AlertContextType is a type definition for the context of the AlertProvider component.
 * It includes the current alert, a function to set the alert, and a function to clear the alert.
 *
 * @typedef {Object} AlertContextType
 *
 * @property {{ message: string; level: AlertLevel } | null} alert - The current alert. It is an object with a message and a level, or null if there is no alert.
 *
 * @property {(message: string, level?: AlertLevel) => void} setAlert - A function that sets the alert. It takes a message and an optional level. If no level is provided, it defaults to 'error'.
 *
 * @property {() => void} clearAlert - A function that clears the alert.
 */
type AlertContextType = {
  alert: { message: ErrorMessage; level: AlertLevel } | null;
  setAlert: (message: ErrorMessage, level?: AlertLevel) => void;
  clearAlert: () => void;
};

/**
 * AlertContext is a React context that provides a way to pass alert data down through the component tree without having to pass props down manually at every level.
 * It is created with a default value of undefined.
 * @type {React.Context<AlertContextType | undefined>}
 */
const AlertContext: React.Context<AlertContextType | undefined> = createContext<
  AlertContextType | undefined
>(undefined);

/**
 * AlertProviderProps is a type definition for the props of the AlertProvider component.
 * It has a single prop, children, which is of type ReactNode.
 * @typedef {Object} AlertProviderProps
 * @property {React.ReactNode} children - The child components of the AlertProvider.
 */
type AlertProviderProps = {
  children: React.ReactNode;
};

/**
 * AlertProvider is a React component that provides the AlertContext to its child components.
 * It maintains the alert state and provides functions to set and clear the alert.
 * @param {AlertProviderProps} props - The props of the AlertProvider component.
 * @returns {JSX.Element} A JSX element that provides the AlertContext to its child components.
 */
const AlertProvider = ({ children }: AlertProviderProps): JSX.Element => {
  const [alert, setAlertState] = useState<{
    message: ErrorMessage;
    level: AlertLevel;
  } | null>(null);

  /**
   * setAlert is a function that sets the alert state to a given message and level.
   * If no level is provided, it defaults to 'error'.
   * @param {string} message - The message of the alert.
   * @param {AlertLevel} [level='error'] - The level of the alert.
   */
  const setAlert = (message: ErrorMessage, level: AlertLevel = 'error') => {
    setAlertState({ message, level });
  };

  /**
   * clearAlert is a function that clears the alert state by setting it to null.
   */
  const clearAlert = () => setAlertState(null);

  return (
    <AlertContext.Provider value={{ alert, setAlert, clearAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

/**
 * useAlert is a custom hook that provides the AlertContext.
 * It throws an error if it is used outside of an AlertProvider.
 * @returns {AlertContextType} The AlertContext.
 */
const useAlert = (): AlertContextType => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};

export { AlertProvider, useAlert };
