import React, {
  ComponentType,
  CSSProperties,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ChangeSet,
  Column,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  RowDetailState,
  SelectionState,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  DragDropProvider,
  ExportPanel,
  Grid,
  PagingPanel,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  TableEditColumn,
  TableEditRow,
  TableFilterRow,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import {
  generateCsvContent,
  getPagingMessages,
  getTableMessages,
  isEmptyOrSpaces,
  isNonNegativeNumber,
  isPositiveInteger,
} from '../../utils/string-utils';
import GridRow from './GridRow';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZES } from '../../utils/table';
import { useTranslation } from 'react-i18next';
import { usePersistedState } from '../app/utils';
import { UserSettingKey } from '../profile/types';
import { useTableEditColumnWidth } from '../settings/utils';
import { BooleanTypeProvider } from './GridDataFormatters';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import { GridTableToolbarHelpPlugin } from './GridTableToolbarHelpPlugin';
import { Box, SxProps } from '@mui/material';
import CellProps = TableFilterRow.CellProps;
import { ErrorContext } from './ErrorContext';

type ExporterRef = {
  exportGrid: () => void;
};

type GridTableDefaultEditSettings = {
  onCommitChanges: (changeSet: ChangeSet) => void;
  editDisabledColumns?: string[];
  showAddCommand?: boolean;
  showEditCommand?: boolean;
  showDeleteCommand?: boolean;
};

type GridTableSelectionSettings = {
  onSelectionChange: (rowIds: string[]) => void;
  hideSelectAll?: boolean;
  disableSelectByRowClick?: boolean;
  hideSelectionColumn?: boolean;
};

type GridTableFilteringSettings = {
  showFilterSelector?: boolean;
  cellComponent?: ComponentType<CellProps>;
};

type Props = {
  columns: Column[];
  rows: any[];
  onRowClick?: (row: any) => void;
  editSettings?: GridTableDefaultEditSettings;
  getRowId?: (row: any) => string;
  className?: string;
  sx?: SxProps;
  styles?: {
    tableComponent?: CSSProperties;
  };
  persistedSettings?: {
    sortingSettingKey?: UserSettingKey;
    hiddenColumnsSettingKey?: UserSettingKey;
    pageSizeSettingKey?: UserSettingKey;
    columnOrderSettingKey?: UserSettingKey;
    columnWidthsSettingKey?: UserSettingKey;
    defaultHiddenColumns?: string[];
    defaultColumnOrder?: string[];
    defaultColumnWidths?: {
      columnName: string;
      width: number | string;
      align?: string;
    }[];
    defaultSorting?: Sorting[];
  };
  providers?: React.ReactNode;
  isCompact?: boolean;
  booleanColumns?: string[];
  rowDetailComponent?: FunctionComponent<{ row: any }>;
  expandedRowIds?: Array<string | number>;
  defaultExpandedRowIds?: Array<string | number>;
  onExpandedRowIdsChange?: (rowIds: Array<string | number>) => void;
  rowDetailCellClassName?: string;
  cellComponent?: FunctionComponent<Table.DataCellProps>;
  integratedSortingColumnExtensions?: IntegratedSorting.ColumnExtension[];
  enableExport?: boolean;
  exportFileName?: string;
  onExport?: (data: any) => void;
  additionalExportColumns?: AdditionalExportColumn[];
  onExportGetCellValue?: (row: any, columnName: string) => any;
  disableHelp?: boolean;
  selectionSettings?: GridTableSelectionSettings;
  filteringSettings?: GridTableFilteringSettings;
};

/**
 * Encapsulates the default functionality as well as
 * provides extended functionality for the GridTable component.
 * @param columns
 * @param rows
 * @param onRowClick
 * @param editSettings
 * @param getRowId
 * @param className
 * @param sx
 * @param styles
 * @param providers
 * @param persistedSettings
 * @param isCompact
 * @param booleanColumns
 * @param rowDetailComponent
 * @param expandedRowIds
 * @param defaultExpandedRowIds
 * @param onExpandedRowIdsChange
 * @param rowDetailCellClassName
 * @param cellComponent
 * @param integratedSortingColumnExtensions
 * @param enableExport
 * @param additionalExportColumns
 * @param exportFileName
 * @param onExport
 * @param onExportGetCellValue
 * @param disableHelp
 * @param selectionSettings
 * @param filteringSettings
 * @constructor
 */
const GridTableDefault = ({
  columns,
  rows,
  onRowClick,
  editSettings,
  getRowId,
  onExportGetCellValue,
  className,
  sx,
  styles,
  providers,
  persistedSettings,
  isCompact,
  booleanColumns,
  rowDetailComponent,
  expandedRowIds,
  defaultExpandedRowIds,
  onExpandedRowIdsChange,
  rowDetailCellClassName,
  cellComponent,
  integratedSortingColumnExtensions,
  enableExport,
  exportFileName,
  onExport,
  additionalExportColumns,
  disableHelp,
  selectionSettings,
  filteringSettings,
}: Props) => {
  const { t } = useTranslation();
  const [selection, setSelection] = useState<Array<string | number>>([]);
  const [sorting, setSorting] = usePersistedState<Sorting[]>(
    persistedSettings?.sortingSettingKey,
    persistedSettings?.defaultSorting || [],
  );
  const [hiddenColumns, setHiddenColumns] = usePersistedState<Array<string>>(
    persistedSettings?.hiddenColumnsSettingKey,
    persistedSettings?.defaultHiddenColumns || [],
  );
  const [pageSize, setPageSize] = usePersistedState(
    persistedSettings?.pageSizeSettingKey,
    DEFAULT_PAGE_SIZE,
  );
  const [columnOrder, setColumnOrder] = usePersistedState(
    persistedSettings?.columnOrderSettingKey,
    persistedSettings?.defaultColumnOrder || columns.map((c) => c.name),
  );
  const [columnWidths, setColumnWidths] = usePersistedState(
    persistedSettings?.columnWidthsSettingKey,
    persistedSettings?.defaultColumnWidths ??
      columns.map((c) => ({ columnName: c.name, width: 250 })),
  );
  const [contextValidationError, setContextValidationError] = useState(false);
  const exportEnabled = enableExport || exportFileName || onExport;

  const exporterRef = useRef<ExporterRef>(null);
  const startExport = useCallback(() => {
    if (exporterRef.current) {
      exporterRef.current.exportGrid();
    }
  }, [exporterRef]);
  const editController = useEditController(columns, editSettings);

  /**
   * Exports table to csv file
   * optionally calls two custom exporters:
   * onExport Callback function - generates data using the backend
   * additionalExportColumns - adds additional columns to the export that are not visible in the table
   * @param workbook
   */
  function onSaveExport(workbook: any) {
    if (onExport) {
      setTimeout(() => onExport(rows), 0);
    } else {
      if (additionalExportColumns) {
        const allColumns = [...columns];
        const sortedExportColumns = [...additionalExportColumns].sort(
          (a, b) => a.position - b.position,
        );
        for (const column of sortedExportColumns) {
          allColumns.splice(column.position, 0, column);
        }
        const modifiedRows = rows.map((row: any) => {
          const modifiedRow: any = { ...row };
          for (const column of additionalExportColumns) {
            if (column.getCellValue) {
              modifiedRow[column.name] = column.getCellValue(row, column.name);
            }
          }
          return modifiedRow;
        });
        const csvContent = generateCsvContent(modifiedRows, allColumns);
        saveCsvContent(csvContent);
      } else {
        workbook.csv.writeBuffer().then((buffer: any) => {
          saveAs(
            new Blob([buffer], { type: 'text/csv;charset=utf-8' }),
            exportFileName ?? 'DataGrid.csv',
          );
        });
      }
    }
  }

  /**
   * Saves csv content to file
   * @param csvContent
   */
  const saveCsvContent = (csvContent: string) => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, exportFileName ?? 'DataGrid.csv');
  };

  /**
   * Updates state and parent with list of selected row ids
   * @param rowIds
   */
  function handleSelectionChange(rowIds: Array<string | number>) {
    setSelection(rowIds);
    selectionSettings?.onSelectionChange(rowIds as string[]);
  }

  return (
    <ErrorContext.Provider
      value={{ contextValidationError, setContextValidationError }}
    >
      <Box className={className} sx={sx}>
        <Grid columns={columns} rows={rows} getRowId={getRowId}>
          {filteringSettings && <FilteringState defaultFilters={[]} />}
          {filteringSettings && <IntegratedFiltering />}
          {selectionSettings && (
            <SelectionState
              selection={selection}
              onSelectionChange={handleSelectionChange}
            />
          )}
          {selectionSettings && <IntegratedSelection />}
          {editController.editingEnabled && (
            <EditingState
              editingRowIds={editController.editingRowIds}
              onEditingRowIdsChange={editController.onEditingRowIdsChange}
              addedRows={editController.addedRows}
              onCommitChanges={editController.onCommitChanges}
              onAddedRowsChange={editController.onAddedRowsChange}
              onRowChangesChange={editController.onRowChangesChange}
              columnExtensions={editController.columnExtensions}
            />
          )}
          {persistedSettings?.sortingSettingKey && (
            <SortingState sorting={sorting} onSortingChange={setSorting} />
          )}
          {persistedSettings?.sortingSettingKey && (
            <IntegratedSorting
              columnExtensions={integratedSortingColumnExtensions ?? undefined}
            />
          )}
          {persistedSettings?.pageSizeSettingKey && (
            <PagingState
              defaultCurrentPage={0}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
          )}
          {persistedSettings?.pageSizeSettingKey && <IntegratedPaging />}
          {booleanColumns && <BooleanTypeProvider for={booleanColumns} />}
          {providers}
          {persistedSettings?.columnOrderSettingKey && <DragDropProvider />}
          {rowDetailComponent && (
            <RowDetailState
              expandedRowIds={expandedRowIds}
              defaultExpandedRowIds={defaultExpandedRowIds}
              onExpandedRowIdsChange={onExpandedRowIdsChange}
            />
          )}
          <Table
            messages={getTableMessages(t)}
            tableComponent={
              styles?.tableComponent
                ? (props) => (
                    <Table.Table {...props} style={styles.tableComponent} />
                  )
                : Table.Table
            }
            rowComponent={
              onRowClick
                ? (props) => <GridRow onClick={onRowClick} {...props} />
                : Table.Row
            }
            cellComponent={
              cellComponent
                ? cellComponent
                : isCompact
                  ? (props) => <Table.Cell {...props} style={{ padding: 4 }} />
                  : Table.Cell
            }
          />

          {persistedSettings?.columnOrderSettingKey && (
            <TableColumnReordering
              order={columnOrder}
              onOrderChange={setColumnOrder}
            />
          )}
          {persistedSettings?.columnWidthsSettingKey && (
            <TableColumnResizing
              columnWidths={columnWidths}
              onColumnWidthsChange={setColumnWidths}
            />
          )}
          <TableHeaderRow
            showSortingControls={
              persistedSettings?.sortingSettingKey !== undefined
            }
            cellComponent={
              isCompact
                ? (props) => (
                    <TableHeaderRow.Cell {...props} style={{ padding: 4 }} />
                  )
                : TableHeaderRow.Cell
            }
          />
          {filteringSettings && (
            <TableFilterRow
              showFilterSelector={filteringSettings.showFilterSelector}
              cellComponent={filteringSettings.cellComponent}
            />
          )}
          {selectionSettings && (
            <TableSelection
              showSelectAll={!selectionSettings.hideSelectAll}
              selectByRowClick={!selectionSettings.disableSelectByRowClick}
              showSelectionColumn={!selectionSettings.hideSelectionColumn}
            />
          )}
          {editController.editingEnabled && <TableEditRow />}
          {editController.editingEnabled && (
            <TableEditColumn
              showAddCommand={editController.showAddCommand}
              showEditCommand={editController.showEditCommand}
              showDeleteCommand={editController.showDeleteCommand}
              width={editController.editColumnWidth}
              cellComponent={(props) => (
                <EditColumnCell
                  errors={editController.errors}
                  contextValidationError={contextValidationError}
                  {...props}
                />
              )}
            />
          )}
          {persistedSettings?.hiddenColumnsSettingKey && (
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumns}
              onHiddenColumnNamesChange={setHiddenColumns}
            />
          )}
          {rowDetailComponent && (
            <TableRowDetail
              contentComponent={rowDetailComponent}
              cellComponent={(props) => (
                <TableRowDetail.Cell
                  {...props}
                  className={rowDetailCellClassName}
                />
              )}
            />
          )}
          {persistedSettings?.pageSizeSettingKey && (
            <PagingPanel
              pageSizes={DEFAULT_PAGE_SIZES}
              messages={getPagingMessages(t) as any}
            />
          )}
          {(persistedSettings?.hiddenColumnsSettingKey ||
            exportEnabled ||
            !disableHelp) && <Toolbar />}
          {!disableHelp && <GridTableToolbarHelpPlugin />}
          {persistedSettings?.hiddenColumnsSettingKey && <ColumnChooser />}
          {exportEnabled && <ExportPanel startExport={startExport} />}
        </Grid>
        {exportEnabled && (
          <GridExporter
            ref={exporterRef}
            columns={columns}
            rows={rows}
            getCellValue={onExportGetCellValue}
            onSave={onSaveExport}
          />
        )}
      </Box>
    </ErrorContext.Provider>
  );
};

/**
 * Hook that controls editing rows in the table
 * @param columns
 * @param editSettings
 */
const useEditController = (
  columns: ColumnWithValidationRules[],
  editSettings?: GridTableDefaultEditSettings,
) => {
  const editColumnWidth = useTableEditColumnWidth();
  const [editingRowIds, setEditingRowIds] = useState<(string | number)[]>([]);
  const [addedRows, setAddedRows] = useState<any[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});

  /**
   * Effect that sets errors for rows that have validation rules when component mounts
   */
  useEffect(() => {
    const updateErrors = () => {
      const newErrors: { [key: number]: boolean } = {};
      addedRows.forEach((row, index) => {
        newErrors[index] = columns.some((column) => {
          if (
            column.isRequired &&
            (row[column.name] === undefined || row[column.name] === null) &&
            !(
              column.initialValue !== undefined &&
              column.initialValue === row[column.name]
            )
          ) {
            return true;
          }
          if (
            column.mustBePositiveInteger &&
            !isPositiveInteger(row[column.name])
          ) {
            return true;
          }
          if (
            column.mustBeNonNegativeNumber &&
            !isNonNegativeNumber(row[column.name])
          ) {
            return true;
          }
          return false;
        });
      });
      setErrors(newErrors);
    };

    updateErrors();
  }, [addedRows, columns]);

  /**
   * Gets rows to add. Initializes each cell in a new row, so we can validate
   * newly created rows
   * @param rowChanges
   */
  const getAddedRows = (rowChanges: { [key: string]: any }) =>
    Object.entries(rowChanges).map(([_, row]) => {
      columns.forEach((column) => {
        if (row[column.name] === undefined) {
          row[column.name] = undefined; // Initialize new cells
        }
        if (
          row[column.name] === undefined &&
          column.initialValue !== undefined
        ) {
          row[column.name] = column.initialValue; // Initialize with initial value if specified
        }
      });
      return row;
    });

  /**
   * Returns true if any cell in the row fails its validation rules
   * @param row
   */
  const rowValidator = (row: any) => (column: ColumnWithValidationRules) => {
    if (row[column.name] === undefined) {
      return false; // Cell has not been changed, skip
    } else if (
      column.isRequired &&
      (row[column.name] === null || isEmptyOrSpaces(row[column.name]))
    ) {
      return true;
    } else if (
      column.mustBePositiveInteger &&
      !isPositiveInteger(row[column.name])
    ) {
      return true;
    } else if (
      column.mustBeNonNegativeNumber &&
      !isNonNegativeNumber(row[column.name])
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Checks row changes for validation errors
   * @param rowChanges
   */
  const getErrors = (rowChanges: { [key: string]: any }) =>
    Object.entries(rowChanges).reduce((acc, [rowId, row]) => {
      return {
        ...acc,
        [rowId]: columns.some(rowValidator(row)),
      };
    }, {});

  /**
   * Called when row is being added. Sets validation errors
   * @param rowChanges
   */
  const onAddedRowsChange = (rowChanges: { [key: string]: any }) => {
    setAddedRows(getAddedRows(rowChanges));
    setErrors(getErrors(rowChanges));
  };

  /**
   * Called when row is being edited. Sets validation errors
   * @param rowChanges
   */
  const onRowChangesChange = (rowChanges: { [key: string]: any }) => {
    setErrors(getErrors(rowChanges));
  };

  const editingEnabled = editSettings !== undefined;
  const onCommitChanges = editSettings?.onCommitChanges
    ? editSettings.onCommitChanges
    : () => {};
  const columnExtensions = editSettings?.editDisabledColumns?.map((c) => ({
    columnName: c,
    editingEnabled: false,
  }));
  const showAddCommand =
    editSettings?.showAddCommand &&
    editingRowIds.length === 0 &&
    addedRows.length === 0;
  const showEditCommand =
    editSettings?.showEditCommand && addedRows.length === 0;
  const showDeleteCommand = editSettings?.showDeleteCommand;

  return {
    editingEnabled,
    onCommitChanges,
    columnExtensions,
    editingRowIds,
    onEditingRowIdsChange: setEditingRowIds,
    showAddCommand,
    showEditCommand,
    showDeleteCommand,
    addedRows,
    onAddedRowsChange,
    onRowChangesChange,
    errors,
    editColumnWidth,
  };
};

export type AdditionalExportColumn = Column & {
  position: number;
};

export type ColumnWithValidationRules = Column & {
  isRequired?: boolean;
  mustBePositiveInteger?: boolean;
  mustBeNonNegativeNumber?: boolean;
  initialValue?: number | string;
};

type EditColumnCellProps = TableEditColumn.CellProps & {
  errors: { [key: string]: boolean };
  contextValidationError?: boolean;
};

/**
 * Renders the Table Edit Column to ensure that save button is disabled when
 * there are validation errors in an edit row
 * @param errors
 * @param contextValidationError
 * @param props
 * @constructor
 */
const EditColumnCell = ({
  errors,
  contextValidationError,
  ...props
}: EditColumnCellProps) => {
  const { children } = props;
  return (
    <TableEditColumn.Cell {...props}>
      {React.Children.map(children, (child: any) =>
        child?.props.id === 'commit'
          ? React.cloneElement(child, {
              disabled:
                errors[props.tableRow.rowId as any] || contextValidationError,
            })
          : child,
      )}
    </TableEditColumn.Cell>
  );
};

export default GridTableDefault;
