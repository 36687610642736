import React, { ReactNode } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import EnhancedButton from './EnhancedButton';

type Props = {
  title: string;
  loading?: boolean;
  open: boolean;
  onConfirm?: () => any;
  onCancel: () => any;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmDisabled?: boolean;
  children: ReactNode;
  allowOverflowY?: boolean;
  errorText?: string;
  maxWidth?: DialogProps['maxWidth'];
};

/**
 * Renders a generic dialog
 * @param title
 * @param open
 * @param onConfirm
 * @param onCancel
 * @param confirmLabel
 * @param cancelLabel
 * @param confirmDisabled
 * @param children
 * @param allowOverflowY
 * @param errorText
 * @param loading
 * @param maxWidth
 * @constructor
 */
const GenericDialog = ({
  title,
  open,
  onConfirm,
  onCancel,
  confirmLabel,
  cancelLabel,
  confirmDisabled,
  children,
  allowOverflowY,
  errorText,
  loading,
  maxWidth,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullScreen={isSmall}
      fullWidth
      maxWidth={maxWidth}
      sx={{ overflowY: allowOverflowY ? 'unset' : undefined }}
    >
      <DialogTitle>
        {title}
        {loading && (
          <CircularProgress sx={{ float: 'right' }} color={'primary'} />
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {errorText && (
        <DialogContent sx={{ color: 'red' }}>{errorText}</DialogContent>
      )}
      <DialogActions sx={{ p: theme.spacing(3) }}>
        <Button size={'large'} color={'secondary'} onClick={onCancel}>
          {cancelLabel || t('cancel')}
        </Button>
        {onConfirm && (
          <EnhancedButton
            color={'primary'}
            onClick={onConfirm}
            disabled={confirmDisabled}
          >
            {confirmLabel || t('save')}
          </EnhancedButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GenericDialog;
