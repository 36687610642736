import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import GenericDialog from './GenericDialog';
import EnhancedSelect, { OptionValue } from './EnhancedSelect';
import {
  getDateTimeRepeatOptionsExcludingDaily,
  getDayOfMonthOptions,
  getDayOfWeekOptions,
  getOccurrenceSummaryString,
} from '../../utils/date';
import EnhancedMultiSelect from './EnhancedMultiSelect';
import { TimePickerWrapper } from './TimePickerWrapper';
import { useTranslation } from 'react-i18next';
import { DayOfWeek, DayOfMonth } from '../../utils/date.types';
import { Typography } from '@mui/material';

const PREFIX = 'OccurrenceDialog';

const classes = {
  control: `${PREFIX}-control`,
  container: `${PREFIX}-container`,
};

const StyledOccurrenceDialogDiv = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: theme.spacing(4),

  [`& .${classes.control}`]: {
    flex: '1 1 200px',
    minWidth: '200px',
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  open: boolean;
  value: any;
  onClose: (value?: any) => void;
};

/**
 * Renders a dialog that allows user to edit occurrence settings
 * @param open
 * @param value
 * @param onClose
 * @constructor
 */
const OccurrenceDialog = ({ open, value, onClose }: Props) => {
  const { t } = useTranslation();
  const [occurrenceType, setOccurrenceType] = useState(
    value.occurrenceType ?? 'None',
  );
  const [occurrenceDaysOfWeek, setOccurrenceDaysOfWeek] = useState<
    OptionValue<DayOfWeek>[]
  >([]);
  const [occurrenceDaysOfMonth, setOccurrenceDaysOfMonth] = useState<
    OptionValue<DayOfMonth>[]
  >([]);
  const [occurrenceTime, setOccurrenceTime] = useState(value.occurrenceTime);
  const [isComplete, setIsComplete] = useState(false);

  /**
   * Effect that sets the initial occurrence values
   */
  useEffect(() => {
    if (value.occurrenceDaysOfWeek) {
      setOccurrenceDaysOfWeek(
        value.occurrenceDaysOfWeek.map((day: DayOfWeek) => ({
          label: t(day),
          value: day,
        })),
      );
    }

    if (value.occurrenceDaysOfMonth) {
      setOccurrenceDaysOfMonth(
        value.occurrenceDaysOfMonth.map((day: DayOfMonth) => ({
          label: day.toString(),
          value: day,
        })),
      );
    }
  }, [value, t]);

  /**
   * Effect that determines if the dialog is complete
   */
  useEffect(() => {
    setIsComplete(
      occurrenceType !== 'None' &&
        (occurrenceType !== 'Weekly' || occurrenceDaysOfWeek.length > 0) &&
        (occurrenceType !== 'Monthly' || occurrenceDaysOfMonth.length > 0) &&
        !!occurrenceTime,
    );
  }, [
    occurrenceType,
    occurrenceDaysOfWeek,
    occurrenceDaysOfMonth,
    occurrenceTime,
  ]);

  /**
   * Handles the save button click event
   */
  const handleSave = () => {
    if (occurrenceType === 'None' || !occurrenceTime) {
      return;
    }

    const updatedValue: any = {
      occurrenceType,
      occurrenceTime,
    };

    if (occurrenceType === 'Weekly') {
      updatedValue.occurrenceDaysOfWeek = occurrenceDaysOfWeek.map(
        (d) => d.value,
      );
    } else if (occurrenceType === 'Monthly') {
      updatedValue.occurrenceDaysOfMonth = occurrenceDaysOfMonth.map(
        (d) => d.value,
      );
    }

    onClose(updatedValue);
  };

  const summaryString = getOccurrenceSummaryString(
    t,
    occurrenceType,
    occurrenceDaysOfWeek.map((d) => d.value),
    occurrenceDaysOfMonth.map((d) => d.value),
    occurrenceTime,
  );

  return (
    <GenericDialog
      title={t('Edit Occurrence')}
      open={open}
      onConfirm={handleSave}
      onCancel={() => onClose()}
      confirmLabel={t('Save')}
      cancelLabel={t('Cancel')}
      confirmDisabled={!isComplete}
    >
      <StyledOccurrenceDialogDiv className={classes.container}>
        <EnhancedSelect
          label={t('repeat')}
          data={getDateTimeRepeatOptionsExcludingDaily(t)}
          value={occurrenceType}
          onChange={(newType) => {
            setOccurrenceType(newType);
            setOccurrenceDaysOfWeek([]);
            setOccurrenceDaysOfMonth([]);
          }}
          containerClass={classes.control}
        />
        <TimePickerWrapper
          label={t('occurrenceTime')}
          value={occurrenceTime}
          onChange={setOccurrenceTime}
          className={classes.control}
        />
      </StyledOccurrenceDialogDiv>
      {occurrenceType === 'Weekly' && (
        <EnhancedMultiSelect
          label={t('daysOfWeek')}
          data={getDayOfWeekOptions(t)}
          value={occurrenceDaysOfWeek}
          onChange={setOccurrenceDaysOfWeek}
          containerClass={classes.control}
          tipDisabled
        />
      )}
      {occurrenceType === 'Monthly' && (
        <EnhancedMultiSelect
          label={t('daysOfMonth')}
          data={getDayOfMonthOptions()}
          value={occurrenceDaysOfMonth}
          onChange={setOccurrenceDaysOfMonth}
          containerClass={classes.control}
          tipDisabled
        />
      )}
      {summaryString && <Typography sx={{ mt: 2 }}>{summaryString}</Typography>}
    </GenericDialog>
  );
};

export default OccurrenceDialog;
