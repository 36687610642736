import React from 'react';
import useFullscreen from '../../utils/fullscreen';
import EditIconButton from './EditIconButton';
import { useTranslation } from 'react-i18next';
import { FullscreenExitIcon, FullscreenIcon } from './icons';
import { SxProps } from '@mui/material';

type Props = {
  className?: string;
  sx?: SxProps;
};

/**
 * Button for entering and exiting fullscreen mode
 * @param className
 * @param sx
 * @constructor
 */
const FullscreenButton = ({ className, sx }: Props) => {
  const { t } = useTranslation();
  const [isFullscreen, setIsFullscreen] = useFullscreen();

  /**
   * Toggles fullscreen mode
   */
  function handleClick() {
    setIsFullscreen(!isFullscreen);
  }

  return (
    <EditIconButton
      id={'fullscreenButton'}
      sx={sx}
      className={className}
      onClick={handleClick}
      tooltip={isFullscreen ? t('exitFullscreen') : t('fullscreen')}
      icon={isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
    />
  );
};

export default FullscreenButton;
